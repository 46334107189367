










export default {
  name: 'PublishingPlanner',

  data() {
    return {};
  },
};
