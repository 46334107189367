





































import { Component, Vue, Prop } from 'vue-property-decorator';
import YouTubeIcon from '@/audience/components/social/YouTubeIcon.vue';
import InstagramIcon from '@/audience/components/social/InstagramIcon.vue';
import FacebookIcon from '@/audience/components/social/FacebookIcon.vue';
import TwitterIcon from '@/audience/components/social/TwitterIcon.vue';
import TikTokIcon from '@/audience/components/social/TikTokIcon.vue';
import LinkedInIcon from '@/audience/components/social/LinkedInIcon.vue';
import PinterestIcon from '@/audience/components/social/PinterestIcon.vue';
import { PlatformLabels } from '@/audience/data/socialPlatformChartConfig';

@Component({
  name: 'ViewHeader',
})
export default class ViewHeader extends Vue {
  @Prop({ type: Object, required: true }) config!: HeaderConfig;
  @Prop({ type: Array, required: true }) contextOptions!: { label: string; value: string }[];
  @Prop({ type: String, required: true }) context!: string;

  onContextChange($event: Event) {
    const { value } = $event.target as HTMLInputElement;
    this.$emit('onContextChange', value);
  }

  get iconComponent() {
    switch (this.config.platformLabel) {
      case PlatformLabels.YOUTUBE:
        return YouTubeIcon;
      case PlatformLabels.INSTAGRAM:
        return InstagramIcon;
      case PlatformLabels.FACEBOOK:
        return FacebookIcon;
      case PlatformLabels.TWITTER:
        return TwitterIcon;
      case PlatformLabels.TIKTOK:
        return TikTokIcon;
      case PlatformLabels.LINKEDIN:
        return LinkedInIcon;
      case PlatformLabels.PINTEREST:
        return PinterestIcon;
      default:
        return null;
    }
  }
}

interface HeaderConfig {
  heading: string;
  showSubHeading?: boolean;
  subHeading?: string;
  platformLabel?: PlatformLabels;
}
