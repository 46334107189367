









































import { Component, Vue, Prop } from 'vue-property-decorator';
import { Getter, namespace } from 'vuex-class';

import moment from 'moment';

import { getTotalPrice, SubscriptionData, currencyFormatOptions } from '@/utils/ecomm';
import { PlansData, ProductHandle } from '@/types/ecomm';
import { Subscription } from '@/types/account/AccountData';

// Export or move if used elsewhere
enum ProductTitle {
  AUDIENCE = 'Audience only',
  DISCOVER = 'Audience and Discover',
  CREATE_AND_PUBLISH = 'Audience and Create&Publish',
  FULL_SUITE = 'Full Suite',
  FULL_SUITE_FREE_TRIAL = 'Full Suite - Free Trial',
}

const EcommStore = namespace('EcommStore');

@Component({
  name: 'CurrentSubscriptionData',
})
export default class CurrentSubscriptionData extends Vue {
  @Prop({ type: Boolean, default: false }) locked!: boolean;

  @Getter currentSubscriptionData!: SubscriptionData;
  @Getter maxBrands!: number;
  @Getter rawSubscription!: Subscription;
  @EcommStore.Getter plans!: PlansData;

  get subscriptionTitle(): ProductTitle | 'No product match' {
    const productHandle = this.currentSubscriptionData.productHandle;
    switch (productHandle) {
      case ProductHandle.AUDIENCE:
        return ProductTitle.AUDIENCE;
      case ProductHandle.FULL_SUITE_FREE_TRIAL:
        return ProductTitle.FULL_SUITE_FREE_TRIAL;
      case ProductHandle.DISCOVER_MONTHLY:
        return ProductTitle.DISCOVER;
      case ProductHandle.CREATE_MONTHLY:
        return ProductTitle.CREATE_AND_PUBLISH;
      case ProductHandle.SUITE_MONTHLY:
        return ProductTitle.FULL_SUITE;
      default:
        return 'No product match';
    }
  }

  get numberOfBrandsText() {
    if (this.maxBrands > 1) {
      // 0 or 1+ brands
      return `${this.maxBrands} brands`;
    }
    // 1 brand
    return `1 brand`;
  }

  get amountBilledPerMonth() {
    if (!this.plans) return null;
    return getTotalPrice(this.plans, this.currentSubscriptionData, false);
  }
  // What is user billed for as text
  get billedText() {
    const getAmountForPayingProduct = () => {
      if (!this.amountBilledPerMonth) return 'No billing information found.';
      const _billedPerMonth: string = new Intl.NumberFormat(undefined, currencyFormatOptions).format(
        Number(this.amountBilledPerMonth)
      );
      return `${_billedPerMonth} per month`;
    };

    const productHandle = this.currentSubscriptionData.productHandle;
    switch (productHandle) {
      case ProductHandle.AUDIENCE:
        return 'Audience is always free!';
      case ProductHandle.FULL_SUITE_FREE_TRIAL:
        return 'Free trial';
      case ProductHandle.DISCOVER_MONTHLY:
      case ProductHandle.CREATE_MONTHLY:
      case ProductHandle.SUITE_MONTHLY:
        return getAmountForPayingProduct();
      default:
        return 'No billing information found.';
    }
  }

  get isNoCost() {
    return [ProductHandle.AUDIENCE, ProductHandle.FULL_SUITE_FREE_TRIAL].includes(
      this.currentSubscriptionData.productHandle
    );
  }
  // When does plan renew as text
  get renewsAtText() {
    const productHandle = this.currentSubscriptionData.productHandle;
    switch (productHandle) {
      case ProductHandle.AUDIENCE:
        return '';
      case ProductHandle.FULL_SUITE_FREE_TRIAL:
        if (this.rawSubscription?.expiresAt) {
          return `Your trial expires on ${moment(this.rawSubscription.expiresAt).format('MMM D, YYYY')}`;
        }
        return '';
      case ProductHandle.DISCOVER_MONTHLY:
      case ProductHandle.CREATE_MONTHLY:
      case ProductHandle.SUITE_MONTHLY:
        if (this.rawSubscription?.nextAssessmentAt) {
          return `Your plan renews on ${moment(this.rawSubscription.nextAssessmentAt).format('MMM D, YYYY')}`;
        }
        return '';
      default:
        return '';
    }
  }
}
