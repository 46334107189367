<template>
  <div class="audience-container">
    <div class="sidebar-wrapper">
      <AudienceSidebar :checkout="true" />
    </div>
    <div class="content-container">
      <!-- Critical message - shown when there is an error from Chargify -->
      <AlertDisplay :alertMessage="alertMessage" class="alertMessage" v-if="displayAlert" />
      <div class="form">
        <div class="form-content">
          <div class="form-header">
            <router-link to="/">
              <img alt="logo" src="@/assets/FuturiCC.svg" height="61" />
            </router-link>
          </div>
          <div class="form-title">
            <h2>Grow your social audience faster</h2>
            <div class="bullets">
              <ul>
                <li>All of your social, podcast, and video audiences in one place</li>
                <li>Grow faster by seeing what’s engaging your audience</li>
                <li>Get alerts when your audience spikes</li>
                <li>Uncover hidden opportunities</li>
              </ul>
            </div>
          </div>
          <h3 class="form-subtitle">
            Sign up now
            <span>Already a member? <router-link to="/audience/login">Sign in</router-link> </span>
          </h3>
          <form v-if="editable" v-on:submit.prevent="onSubmit" novalidate ref="form">
            <div class="row">
              <div class="col">
                <Input
                  :value="form.firstName"
                  :inputHandler="handleInputChange"
                  maxLength="20"
                  class="form-input"
                  label="First name"
                  name="firstName"
                  :error="$v.form.firstName.$error ? 'First name' + errorMessages.required : ''"
                />
              </div>
              <div class="col">
                <Input
                  :value="form.lastName"
                  :inputHandler="handleInputChange"
                  maxLength="20"
                  class="form-input form-lastname"
                  label="Last name"
                  name="lastName"
                  :error="$v.form.lastName.$error ? 'Last name' + errorMessages.required : ''"
                />
              </div>
            </div>

            <div class="row">
              <Input
                :value="form.emailAddress"
                :inputHandler="handleInputChange"
                maxLength="100"
                class="form-input"
                label="Email address"
                name="emailAddress"
                type="email"
                :error="
                  $v.form.emailAddress.$error
                    ? !$v.form.emailAddress.email
                      ? errorMessages.invalidEmail
                      : 'Email address' + errorMessages.required
                    : ''
                "
              />
            </div>
            <div class="row">
              <Input
                :value="form.audienceProfileName"
                :inputHandler="handleInputChange"
                maxLength="100"
                class="form-input"
                label="Profile display name"
                name="audienceProfileName"
                type="text"
                assistive-text="The public facing name for your brand/company will be used in the Content Cloud Community."
                :error="$v.form.audienceProfileName.$error ? 'Profile display name' + errorMessages.required : ''"
              />
            </div>

            <div class="complete-purchase">
              <Button
                :loading="formIsLoading"
                @click="createAccount"
                buttonType="primary"
                class="form-submit button-complete"
                onClickEventHandler="click"
                type="button"
              >
                Create Your Account
              </Button>

              <p class="confirmation">
                By signing up, you agree to our
                <a href="#" target="_blank">terms & conditions</a>
                and
                <a href="#" target="_blank">privacy policy</a>.
              </p>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';

import { required } from 'vuelidate/lib/validators';
import {
  validateEmail as $valEmail,
  validateName,
  noSpecialCharacters,
  validateProfileName,
} from '@/plugins/Validations';
import errorMessages from '@/utils/errorMessages.json';

import AlertDisplay from '@/components/common/AlertDisplay.vue';
import AudienceSidebar from '@/components/ecomm/AudienceSidebar.vue';
import Button from '@/components/common/buttons/Button.vue';
import ChargifyService from '@/services/ChargifyService';
import Input from '@/components/common/form/Input.vue';

export default Vue.extend({
  name: 'audience-checkout',

  components: {
    AlertDisplay,
    AudienceSidebar,
    Button,
    Input,
  },

  data() {
    return {
      alertMessage: {},
      chargify: null,
      displayAlert: false,
      editable: true,
      errorMessages, // Input error messages
      firstRun: true,
      cardError: '',
      offerId: null,
      form: {
        emailAddress: '',
        firstName: '',
        lastName: '',
        audienceProfileName: '',
      },
      formIsLoading: false, // is form submitting?
    };
  },

  validations: {
    form: {
      emailAddress: { required, $valEmail },
      firstName: { required, validateName, noSpecialCharacters },
      lastName: { required, validateName, noSpecialCharacters },
      audienceProfileName: { required, validateProfileName },
    },
  },

  methods: {
    handleInputChange(e) {
      this.form[e.target.name] = e.target.value;
    },
    async createAccount() {
      this.$v.form.$touch();

      if (!this.$v.form.$invalid) {
        // prevent from running twice
        if (this.formIsLoading) {
          return;
        }

        // show loading icon
        this.formIsLoading = true;

        // hide alert
        this.displayAlert = false;

        // generate payload
        let payload = this.getAudiencePayload();

        const emailExists = await this.checkEmail(payload.emailAddress);

        if (typeof emailExists !== 'boolean' || emailExists?.errors) {
          this.alertMessage = {
            type: 'critical',
            header: `We couldn't set up your subscription`,
            message: 'There was a server error while processing your request. Please try again in a few minutes.',
          };
          this.displayAlert = true;
          return;
        } else if (emailExists) {
          //redirect
          await this.$router.push({
            name: 'Audience Login',
            params: {
              email: payload.emailAddress,
              state: 'emailExists',
            },
          });
          return;
        }

        // create subscription on Chargify
        const service = new ChargifyService();
        const response = await service.createAudience(payload);

        // show errors if any
        if (typeof response === 'string' || response?.errors) {
          this.alertMessage = {
            type: 'critical',
            header: "We couldn't set up your subscription",
            message: response?.errors?.join('<br /> \n') || 'There was a server error while processing your request',
          };
          this.displayAlert = true;
          this.formIsLoading = false;
        } else {
          // show invoice details
          this.$emit('success', this.form);
        }
      }
    },

    getAudiencePayload() {
      const { audienceProfileName, emailAddress, firstName, lastName } = this.form;
      const offerId = this.offerId;

      return {
        audienceProfileName,
        emailAddress,
        firstName,
        lastName,
        offerId,
      };
    },

    async checkEmail(emailAddress) {
      const service = new ChargifyService();
      return await service.getCustomerByEmail(emailAddress);
    },
  },
  async beforeMount() {
    this.$data.isLoading = false;
    // Store offer ID
    this.offerId = this.$route.query.offer || undefined;
  },
});
</script>

<style lang="scss" scoped>
.audience-container {
  display: flex;
  flex-direction: row;
  height: 100vh;

  @media (max-width: 768px) {
    flex-direction: column;
  }

  .content-container {
    background: var(--neutral-white);
    align-items: center;
    display: flex;
    flex-direction: column;
    padding: 68px;
    width: 50%;

    @media (max-width: 768px) {
      padding: 32px 24px;
      width: 100%;
    }
  }

  .sidebar-wrapper {
    background: var(--accent-background);
    height: 100%;
    order: 1;
    width: 50%;

    @media (max-width: 768px) {
      height: auto;
      order: 0;
      width: 100%;
    }
  }
}

.row {
  margin: 0;
  position: relative;
}

.col {
  padding: 0;

  &:first-child {
    margin-right: 16px;
  }
  @media (max-width: 768px) {
    flex: none;
  }
}

.bullets {
  margin-bottom: 64px;

  ul {
    margin: 0;
    padding: 0;
  }

  li {
    background: url('@/assets/audience/arrow.svg') no-repeat;
    background-position: left center;
    font-size: var(--fm-font-size-20);
    line-height: var(--fm-line-spacing-28);
    list-style: none;
    margin-top: 16px;
    padding-left: 36px;
  }
}

.form {
  max-width: 592px;
  margin: 0 auto;
  position: static;

  @media (max-width: 768px) {
    padding: 48px 24px 24px;
    width: 100%;
  }
}

.form-header {
  margin: 0 0 64px;

  @media (max-width: 1024px) {
    margin: 0 0 64px;
  }

  @media (max-width: 768px) {
    display: none;
  }
}

.form-content {
  width: 592px;

  @media (max-width: 1400px) {
    width: 100%;
  }

  @media (max-width: 1024px) {
    max-width: 100%;
    width: 100%;
  }
}

.form-title {
  margin-bottom: 32px;

  h2 {
    font-size: var(--fm-font-size-36);
    line-height: var(--fm-line-spacing-44);
    margin-bottom: 0;
  }

  @media (max-width: 1024px) {
    display: none;
  }
}

.form-subtitle {
  align-items: baseline;
  color: var(--neutral-grey-1-text);
  display: flex;
  font-family: Roboto;
  font-size: var(--fm-font-size-24);
  font-style: normal;
  font-weight: 500;
  justify-content: space-between;
  line-height: var(--fm-line-spacing-32);
  margin-bottom: 24px;

  span {
    align-self: right;
    font-size: var(--fm-font-size-16);
    font-weight: 400;
    line-height: var(--fm-line-spacing-24);

    a {
      color: var(--accent-links);
      text-decoration: underline;
    }
  }

  @media (max-width: 1024px) {
    flex-direction: column;
  }
}

.form-input {
  margin-bottom: 32px;
}

.sign-in {
  position: absolute;
  font-size: 14px;
  top: 0;
  right: 0;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
}

.confirmation {
  margin-top: 16px;

  a {
    color: var(--accent-links);
    text-decoration: underline;
  }
}

.alertMessage {
  margin: 0 15px 32px;
  width: 100%;
}

.custom-button {
  margin-bottom: 8px;
  width: 100%;
}
</style>
