<template>
  <div>
    <header class="title-header">
      <div class="sub-header">
        <fm-text tag="h2">{{ headerText }}</fm-text>
        <fm-text variant="disclaimer">
          Generated {{ dataLoadedFrom }} -
          <fm-link href="#" @click="reloadData">Refresh Data</fm-link>
        </fm-text>
      </div>
      <div class="group-by-wrapper">
        <label>Group by</label>
        <fm-select
          class="group-by-input"
          :options.prop="dimensionOptions"
          value="show"
          @input="setDimension"
        ></fm-select>
      </div>
      <fm-button-secondary
        :disabled="!this.tableRows || !this.tableRows.length"
        @click="showAnalyticsDownloadModal = true"
      >
        <span class="material-icons">file_download</span>
        Export CSV
      </fm-button-secondary>
      <analytics-download-modal
        v-if="showAnalyticsDownloadModal"
        @on:cancel="showAnalyticsDownloadModal = false"
        @on:submit-request="submitCsvRequest"
      />
    </header>
    <div class="card-container" v-if="!hasNoResults && !isLoading">
      <div class="card-wrapper">
        <div class="card">
          <fm-heading
            ><h1>{{ totals.country_count }}</h1></fm-heading
          >
          <fm-heading><h3 class="card-label">countries</h3></fm-heading>
        </div>
      </div>
      <div class="card-wrapper">
        <div class="card">
          <fm-heading
            ><h1>{{ totals.downloads }}</h1></fm-heading
          >
          <fm-heading><h3 class="card-label">downloads</h3></fm-heading>
        </div>
      </div>
      <div class="card-wrapper">
        <div class="card">
          <fm-heading
            ><h1>{{ totals.unique_reach }}</h1></fm-heading
          >
          <fm-heading><h3 class="card-label">users reached</h3></fm-heading>
        </div>
      </div>
    </div>
    <div v-if="isLoading" class="loading">
      <loading-progress
        :progress="0"
        :indeterminate="true"
        :hide-background="true"
        size="64"
        rotate
        fillDuration="5"
        rotationDuration="1"
      />
      <fm-text>Loading</fm-text>
    </div>
    <no-results v-if="hasNoResults" />
    <div class="table-container" v-if="!hasNoResults && !isLoading">
      <div class="table">
        <table>
          <thead>
            <table-row>
              <table-header-cell
                v-for="tableHeader in tableHeaders"
                :key="tableHeader.key"
                :isActive="tableHeader.isActive"
                :isSortable="!!tableHeader.onClick"
                :isDesc="tableHeader.arrowUp"
                @click.native="!!tableHeader.onClick && handleSortUpdate(tableHeader.key)"
              >
                {{ tableHeader.label }}
              </table-header-cell>
              <table-header-cell class="help-table-header">
                <i class="material-icons help-icon" role="button" @click="showGlossary">help</i>
              </table-header-cell>
            </table-row>
          </thead>
          <tbody>
            <table-row v-for="(tableRow, index) in tableRows" :key="index" classes="stripped">
              <table-cell v-if="dimension === 'episode'" class="cell">
                <div class="episode-id">ID #{{ tableRow.aw_episode_id }}</div>
                <div class="episode-title">{{ tableRow.episode }}</div>
                <div class="episode-show">{{ tableRow.show }}</div>
              </table-cell>
              <table-cell v-if="dimension === 'show'" class="cell">
                {{ tableRow.show }}
              </table-cell>
              <table-cell class="cell">
                {{ tableRow.country }}
              </table-cell>
              <table-cell v-if="dimension === 'episode'" class="cell">
                {{ tableRow.published }}
              </table-cell>
              <table-cell class="cell">
                {{ tableRow.downloads }}
              </table-cell>
              <table-cell class="cell">
                {{ tableRow.percentTotal }}
              </table-cell>
              <table-cell class="cell">
                {{ tableRow.unique_reach }}
              </table-cell>
              <table-cell class="cell">
                {{ tableRow.ltr }}
              </table-cell>
              <table-cell aria-hidden="true" />
            </table-row>
          </tbody>
        </table>
      </div>
    </div>
    <div class="pagination-wrapper" v-show="totalRecords && !this.isLoading">
      <div class="pagination-totals">
        Viewing {{ (page - 1) * perPage + 1 }} to {{ Math.min(page * perPage, totalRecords) }} of {{ totalRecords }}
      </div>
      <div>
        <fm-pagination
          :totalItems.prop="totalRecords"
          ref="pagination"
          :perPage.prop="perPage"
          @fm-page-update="changePage"
        />
      </div>
      <div>&nbsp;</div>
    </div>
    <analytics-glossary ref="glossary"></analytics-glossary>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

import AnalyticsDownloadModal from '@/createandpublish/components/analytics/podcasts/AnalyticsDownloadModal';
import AnalyticsGlossary from '@/createandpublish/components/analytics/podcasts/AnalyticsGlossary.vue';
import NoResults from '@/createandpublish/components/analytics/podcasts/NoResults';
import TableRow from '@/createandpublish/components/common/table/TableRow.vue';
import TableHeaderCell from '@/createandpublish/components/common/table/TableHeaderCell.vue';
import TableCell from '@/createandpublish/components/common/table/TableCell.vue';
import Moment from 'moment';

export default {
  name: 'CountryAnalytics',
  components: {
    AnalyticsDownloadModal,
    AnalyticsGlossary,
    NoResults,
    TableRow,
    TableHeaderCell,
    TableCell,
  },
  props: {
    selectedCountry: null,
  },
  data: () => ({
    showAnalyticsDownloadModal: false,
    selectedGroupByOptions: [],
    shouldDisplayGlossary: false,
    perPage: 100,
    page: 1,
    startPage: 1,
    sortBy: null,
    isDesc: false.valueOf,
    dataLoadedAt: null,
    dataLoadedFrom: null,
    timeSinceLoad: null,
    isLoading: false,
    headerText: 'Downloads by country',
    dimension: 'show',
    dimensionOptions: [
      { label: 'Shows', value: 'show' },
      { label: 'Episodes', value: 'episode' },
    ],
  }),
  computed: {
    ...mapState('CreateAndPublishStore/analytics', ['countryAnalytics', 'allEpisodeNamesById', 'dateRange']),
    ...mapGetters('CreateAndPublishStore/analytics', ['countryAnalyticsTableData', 'showFilter']),
    totalRecords() {
      const { episode_count, show_count } = this.countryAnalytics?.totals;
      let count = 0;
      if (this.dimension === 'episode') {
        count = episode_count;
      } else if (this.dimension === 'show') {
        count = show_count;
      }
      return count;
    },
    totalPages() {
      return Math.ceil(this.totalRecords / 100);
    },
    totals() {
      if (!this.countryAnalytics) return {};
      return this.countryAnalytics.totals;
    },
    filterOptions() {
      const { selectedCountry: countries_list } = this.$props;
      const { sortBy, isDesc, page, dimension } = this;
      return {
        countries_list,
        dimension,
        sort: sortBy,
        order: isDesc ? 'desc' : 'asc',
        page,
      };
    },
    tableHeaders() {
      return this.buildTableHeaders({ sortHandler: this.handleSortUpdate, dimension: this.dimension });
    },
    tableRows() {
      return this.buildTableData(this.countryAnalyticsTableData);
    },
    hasNoResults() {
      return !this.isLoading && this.tableRows && this.tableRows.length === 0;
    },
  },
  methods: {
    buildTableHeaders({ sortHandler, dimension }) {
      return [
        ...(dimension === 'episode' ? [{ key: 'aw_episode_id', label: 'Episode' }] : []),
        ...(dimension === 'show' ? [{ key: 'show', label: 'Show name' }] : []),
        ...(dimension === 'episode' ? [{ key: 'published', label: 'Published' }] : []),
        {
          key: 'country',
          label: 'Country',
          isActive: this.sortBy === 'country',
          arrowUp: !this.isDesc && this.sortBy === 'country',
          onClick: () => sortHandler('country'),
        },
        {
          key: 'downloads',
          label: 'Downloads',
          isActive: this.sortBy === 'downloads',
          arrowUp: !this.isDesc && this.sortBy === 'downloads',
          onClick: () => sortHandler('downloads'),
        },
        { key: 'percentTotal', label: '% Total' },
        {
          key: 'unique_reach',
          label: 'Unique users',
          isActive: this.sortBy === 'unique_reach',
          arrowUp: !this.isDesc && this.sortBy === 'unique_reach',
          onClick: () => sortHandler('unique_reach'),
        },
        {
          key: 'ltr',
          label: 'LTR',
          isActive: this.sortBy === 'ltr',
          arrowUp: !this.isDesc && this.sortBy === 'ltr',
          onClick: () => sortHandler('ltr'),
        },
      ];
    },
    buildTableData(analyticsRows) {
      if (!analyticsRows) return;
      return analyticsRows.map((row) => {
        const { publish_date } = row;
        const date = publish_date && new Moment(publish_date);
        return {
          ...row,
          published: date && date.format('MM/DD/YYYY'),
          percentTotal: `${row.percentTotal} %`,
          ltr: `${(row.ltr * 100).toFixed(0)}%`,
        };
      });
    },
    async submitCsvRequest(emailOptions) {
      await this.$store.dispatch('CreateAndPublishStore/analytics/requestCountryAnalyticsCsv', {
        ...this.filterOptions,
        ...emailOptions,
      });
      this.showAnalyticsDownloadModal = false;
    },
    changePage(e) {
      const _page = e.detail.value;
      if (!isNaN(_page) && _page <= this.totalPages && _page > 0) {
        this.page = +_page;
      }
    },
    handleSortUpdate(fieldName) {
      if (this.sortBy === fieldName) {
        this.isDesc = !this.isDesc;
      } else {
        this.isDesc = false;
      }
      this.sortBy = fieldName;
    },
    resetPagination() {
      this.$refs.pagination._setPage(1);
    },
    showGlossary() {
      this.$refs.glossary.toggleGlossary();
    },
    initDataTimer() {
      setInterval(() => {
        this.timeSinceLoad = Date.now();
      }, 1000);
      this.dataLoadedAt = new Date();
    },
    async reloadData() {
      this.getData();
    },
    async getData(filterOptions = this.filterOptions) {
      this.toggleLoader();
      this.initDataTimer();
      await this.$store.dispatch('CreateAndPublishStore/analytics/getCountryAnalytics', filterOptions);
      this.toggleLoader();
    },
    setDimension(e) {
      this.dimension = e.target.value.toLowerCase();
    },
    toggleLoader() {
      this.isLoading = !this.isLoading;
    },
  },
  watch: {
    timeSinceLoad() {
      this.dataLoadedFrom = Moment(this.dataLoadedAt).fromNow();
    },
    filterOptions() {
      this.getData(this.filterOptions);
    },
    dateRange() {
      this.getData(this.filterOptions);
    },
    showFilter() {
      this.getData(this.filterOptions);
    },
  },
  mounted() {
    if (this.dateRange.start && this.dateRange.end) {
      this.getData();
    }
  },
};
</script>

<style lang="scss" scoped>
table {
  width: 100%;
}

.loading {
  position: static; // Override unscoped CSS
  margin: auto;
  display: flex;
  flex-flow: column;
  align-items: center;
  margin-top: 64px;
}

header {
  display: flex;
  justify-content: space-between;
}

.group-by-wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  align-items: center;
  margin-right: 1rem;

  label {
    font-weight: 500;
  }

  & > * {
    padding-left: 1em;
  }

  .group-by-input {
    transform: translateY(12px);
  }
}

.title-header {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  @media (max-width: 991px) {
    width: 100%;
    flex-flow: row wrap;
  }

  .sub-header {
    display: flex;
    justify-content: center;
    align-items: baseline;

    @media (max-width: 991px) {
      width: 100%;
      flex-flow: row wrap;
      margin-bottom: 1rem;
    }

    :first-child {
      margin: 0 1rem 0 0;
    }

    fm-link::part(anchor) {
      font-size: var(--fm-font-size-14);
    }
  }

  .group-by-wrapper {
    @media (max-width: 991px) {
      width: 100%;
      justify-content: center;
    }
  }

  fm-button-secondary {
    @media (max-width: 991px) {
      margin: auto;
    }
  }

  p {
    margin: 0;
    font-size: 80%;
  }
}
.card-container {
  display: flex;
  justify-content: space-between;
  padding: 1.5em 0;
  align-content: stretch;

  @media (max-width: 991px) {
    flex-flow: row wrap;
  }

  .card-wrapper {
    width: 32%;
    display: flex;

    @media (max-width: 991px) {
      max-width: 50%;
      width: 50%;
      padding: 1rem;
    }

    @media (max-width: 575px) {
      max-width: 100%;
      width: 100%;
      padding: 1rem;
    }

    .card {
      box-shadow: 0 1px 7px #22204633;
      border-radius: 0;
      padding: 2rem;
      width: 100%;
      margin-right: 1rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      @media (max-width: 575px) {
        margin-right: 0;
      }

      h1 {
        font-size: 48px;
        color: #222046;
        text-align: center;
      }

      h3 {
        font-size: 24px;
        font-weight: bold;
        text-align: center;
        color: #222046;
        text-align: center;
        width: 100%;
      }
    }
  }

  .card-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2em 2em;
    align-self: stretch;

    .card-label {
      word-wrap: break-word;
      text-align: center;
    }
  }
}

.table-container {
  display: flex;
  justify-content: space-between;
  overflow-x: auto;

  .help-table-header {
    padding-left: 0;
  }

  .help-icon {
    color: var(--accent-links);
    cursor: pointer;
  }

  .table {
    flex-grow: 1;

    .cell {
      vertical-align: middle;
    }

    .episode-id {
      color: var(--neutral-grey-2-lighter-text);
      line-height: 16px;
    }

    .episode-title {
      line-height: 24px;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .episode-show {
      font-size: 14px;
      color: var(--neutral-grey-2-lighter-text);
      line-height: 21px;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.pagination-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 575px) {
    flex-flow: column;
  }

  .pagination-totals {
    font-weight: bolder;

    @media (max-width: 575px) {
      margin: 1rem 0;
    }
  }
}
</style>
