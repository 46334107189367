

















































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { Getter, namespace } from 'vuex-class';
import { Campaign } from '@/types/Campaign';
import { Brand } from '@/types/Brand';
import { ImageAsset } from '@/types/createandpublish/mediaLibraries/ImageLibrary';
import ImageCardLayout from '@/createandpublish/components/imageLibrary/ImageCardLayout.vue';
import CampaignCircle from '@/components/brands/CampaignCircle.vue';
import CampaignMenu from '@/components/brands/CampaignMenu.vue';
import { formatDateShortYear } from '@/createandpublish/core/filters';

const createAndPublishStore = namespace('CreateAndPublishStore');
const imageLibraryModule = namespace('CreateAndPublishStore/imageLibrary');

Component.registerHooks(['created']);

@Component({
  name: 'ImageCard',
  components: {
    ImageCardLayout,
    CampaignCircle,
    CampaignMenu,
  },
  filters: {
    formatDateShortYear,
  },
})
export default class ImageCard extends Vue {
  @Prop({ type: Object, required: true }) readonly imageData!: ImageAsset;

  @Getter selectedBrand;
  @createAndPublishStore.Getter readonly timeZone!: string;
  @imageLibraryModule.Action deleteImageAsset;

  campaignMenuValue: number[] = [];
  preventCampaignChangeEmit = false;
  @Watch('campaignMenuValue')
  onCampaignMenuValueChange(campaignIds: number[]) {
    if (this.preventCampaignChangeEmit) return;
    this.$emit('update-asset-campaigns', { id: this.imageData.id, campaignIds });
  }

  get imageCampaigns(): Campaign[] {
    return (this.selectedBrand as Brand)?.campaigns
      .filter((campaign) => campaign.active)
      .filter((campaign) => this.imageData.campaigns?.includes(campaign.id));
  }

  get actionBarProps() {
    return {
      firstAction: {
        useSlot: true,
      },
      secondAction: {
        icon: 'share',
        hoverText: 'Create social post',
        onClick: () => {
          alert('Social posting is not currently implemented.');
        },
      },
      menuOptions: [
        {
          label: 'Edit image details',
          onClick: () => {
            this.$emit('open-image-drawer', this.imageData);
          },
        },
        {
          label: 'Delete image',
          onClick: async () => {
            await this.deleteImageAsset(this.imageData.id);
          },
        },
      ],
    };
  }

  onAddNewCampaign() {
    this.$emit('add-new-campaign');
  }

  async created() {
    this.preventCampaignChangeEmit = true;
    this.campaignMenuValue = this.imageData.campaigns;
    await this.$nextTick();
    this.preventCampaignChangeEmit = false;
  }
}
