import HttpClient from '@/services/common/HttpClient';
import DiscoverRSSFeedsModel from '@/models/DiscoverRSSFeedsModel';
import { RSSFeed } from '@/types/RSSFeed';

export default class DiscoverSettingsService {
  private http: HttpClient = HttpClient.getInstance();
  private endpoint = `${process.env.VUE_APP_API_URL}/discover`;

  /*
   * Discover Settings
   */
  public async getFeeds(brandId: number): Promise<DiscoverRSSFeedsModel> {
    return this.http.get<DiscoverRSSFeedsModel>(`${process.env.VUE_APP_API_URL}/brands/${brandId}/feeds`);
  }

  public async createFeed(brandId: number, rssFeed: Partial<RSSFeed>) {
    return this.http.post<RSSFeed>(`${process.env.VUE_APP_API_URL}/brands/${brandId}/feeds`, rssFeed);
  }

  public async editFeed(brandId: number, rssFeed: Partial<RSSFeed>) {
    return this.http.put<RSSFeed>(`${process.env.VUE_APP_API_URL}/brands/${brandId}/feeds/${rssFeed.id}`, rssFeed);
  }

  public async deleteFeed(brandId: number, id: number) {
    return this.http.delete<DiscoverRSSFeedsModel>(`${process.env.VUE_APP_API_URL}/brands/${brandId}/feeds/${id}`);
  }

  public async saveShortcuts(
    brandId: number,
    data: { categoryIds: number[] | null[]; dma?: number; age?: string; gender?: string }
  ) {
    return this.http.put(`${process.env.VUE_APP_API_URL}/discover/${brandId}/settings/shortcuts`, data);
  }
}
