<template>
  <td class="table__cell" :style="customStyling">
    <slot />
  </td>
</template>

<script>
export default {
  computed: {
    customStyling() {
      return [
        {
          'max-width': `${this.maxWidth}`,
          'min-width': `${this.minWidth}`,
          padding: `${this.paddingY}px ${this.paddingX}px`,
        },
      ];
    },
  },

  props: {
    maxWidth: { default: '100%', type: String },
    minWidth: { default: '', type: String },
    width: { default: '100%', type: String },
    paddingY: {
      type: Number, // in px
      default: () => 15,
    },
    paddingX: {
      type: Number, // in px
      default: () => 16,
    },
  },
};
</script>

<style lang="css" scoped>
.table__cell {
  align-items: center;
  display: table-cell;
  flex-grow: 1;
  justify-content: flex-start;
  padding: 15px 16px;
}

div.show-details .table__cell {
  padding: 0 16px;
}
</style>
