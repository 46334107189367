









































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import Button from '@/components/common/buttons/Button.vue';
import RoundedCard from '@/components/common/cards/RoundedCard.vue';

@Component({
  components: {
    Button,
    RoundedCard,
  },
})
export default class SettingsCard extends Vue {
  @Prop({ required: false }) private title?: string;
  @Prop({ required: false }) private icon?: string; // MUI icon name
  @Prop({ required: false }) private iconType?: string; // md or fa
  @Prop({ required: false }) private qa?: string; // md or fa
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  @Prop({ required: false }) private clickEvent?: (e: PointerEvent) => void;
  @Action toggleSettingsActive;
  @Action toggleTrendingActive;
  @Action toggleFollowingActive;
  @Getter selectedBrand;
}
