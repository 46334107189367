
























import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({
  name: 'InstagramIcon',
})
export default class InstagramIcon extends Vue {
  @Prop({ type: String, required: false, default: '#BB00AD' }) fill!: string;
  @Prop({ type: Number, required: false, default: 40 }) height!: number;
  @Prop({ type: Number, required: false, default: 39 }) width!: number;
  @Prop({ type: String, required: false, default: 'Instagram logo' }) title!: string;
}
