

































import { Component, Prop, Vue } from 'vue-property-decorator';
import ErrorMessage from '@/components/common/form/ErrorMessage.vue';
import Label from './Label.vue';

@Component({
  components: {
    ErrorMessage,
    Label,
  },
})
export default class Select extends Vue {
  @Prop({ required: false }) private name?: string;
  @Prop({ required: false }) private selectedValue?: number;
  @Prop({ required: false }) private assistiveText?: string;
  @Prop({ required: false }) private ariaLabel?: string;
  @Prop({ required: false }) private placeholder?: string;
  @Prop({ required: false }) private inputEventHandler;
  @Prop({ required: false }) private options;
  @Prop({ default: 'id', required: false }) private optionKey?: string;
  @Prop({ default: 'value', required: false }) private optionValue?: string;
  @Prop({ default: '', required: false }) error?: string;
  @Prop({ default: '', required: false }) label?: string;
  @Prop({ default: '', required: false }) link?: string;
  @Prop({ default: false, required: false }) disabled?: boolean;
  @Prop({ default: () => [], required: false }) disabledOptions?: string[];
}
