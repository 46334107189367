








import { Component, Vue, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import BaseLineChart from '@/audience/components/base-charts/BaseLineChart.vue';
import ChartTooltip from '@/audience/components/base-charts/ChartTooltip.vue';
import NoChartDataBanner from '@/audience/components/base-charts/NoChartDataBanner.vue';
import { DatasetBackgroundColors } from '@/audience/data/socialPlatformChartConfig';
import { chartHasNonZeroData, makeDate } from '@/audience/core/utils/chart';
import moment from 'moment-timezone';
import type { ChartConfig, TooltipConfig, SocialAnalytics, ChartDataByPlatform } from '@/types/audience';
import type { ChartData, ChartOptions, ChartDataset } from 'chart.js';

const audienceModule = namespace('AudienceAnalyticsStore');

@Component({
  name: 'PlatformLineChart',
  components: {
    BaseLineChart,
    ChartTooltip,
    NoChartDataBanner,
  },
})
export default class PlatformLineChart extends Vue {
  @Prop({ type: String, required: true }) platformLabel!: string;
  @Prop({ type: String, required: true }) platformColor!: string;
  @Prop({ type: String, required: true }) context!: string;
  @Prop({ type: Object, required: true }) platformData!: ChartDataByPlatform;

  @audienceModule.Getter('extendedSocialAnalytics') socialAnalytics!: SocialAnalytics;
  @audienceModule.Getter('timeZone') timeZone!: string;

  get chartDates() {
    return Object.keys(this.socialAnalytics).map((date) => moment.tz(makeDate(date), this.timeZone));
  }

  get chartLabels() {
    return this.chartDates.map((date) => date.format('M[/]DD'));
  }

  get chartDatasets() {
    switch (this.context) {
      case 'audience':
        return [
          {
            label: this.platformData.audience[0].label,
            backgroundColor: this.platformColor,
            borderColor: '#fff',
            data: this.platformData.audience[0].data,
            fill: 'origin',
            spanGaps: true,
          },
        ];
      case 'impressions':
        if (!this.platformData.impressions) return [];

        return this.platformData.impressions.map((metric, i) => ({
          label: metric.label,
          backgroundColor: DatasetBackgroundColors[i],
          borderColor: '#fff',
          data: metric.data,
          fill: 'origin',
          spanGaps: true,
        }));
      case 'engagement':
        if (!this.platformData.engagement) return [];

        return this.platformData.engagement.map((metric, i) => ({
          label: metric.label,
          backgroundColor: DatasetBackgroundColors[i],
          borderColor: '#fff',
          data: metric.data,
          fill: 'origin',
          spanGaps: true,
        }));
      default:
        return [];
    }
  }

  get chartHasData() {
    return chartHasNonZeroData(this.chartDatasets as ChartDataset[]);
  }

  get chartConfig(): ChartConfig {
    return {
      chartId: `${this.platformLabel}-line-chart`,
      datasetIdKey: `${this.platformLabel}-line-chart`,
      plugins: [],
      cssClasses: '',
      styles: {},
    };
  }

  get chartOptions(): ChartOptions {
    const min = Math.min(...this.chartDatasets[0].data) - 1;
    return {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        y: {
          stacked: true,
          suggestedMin: min < 0 ? 0 : min,
          suggestedMax: Math.max(...this.chartDatasets[0].data) + 1,
          ticks: {
            stepSize: 1,
          },
        },
      },
      plugins: {
        filler: {
          propagate: false,
        },
        legend: {
          display: false,
          labels: {
            boxWidth: 5,
            usePointStyle: true,
            pointStyle: 'circle',
          },
          title: {
            font: {
              family: 'Roboto',
              size: 12,
              weight: '400',
            },
            color: '#222046',
          },
          onClick: (evt) => {
            evt.native?.stopPropagation();
          },
        },
        tooltip: {
          enabled: false,
          external: async (context) => {
            const tooltipEl = (this.$refs.tooltip as Vue).$el;

            const tooltipModel = context.tooltip;

            const style: Partial<CSSStyleDeclaration> = {};

            // Hide if no tooltip
            if (tooltipModel.opacity === 0) {
              style.opacity = '0';
              style.display = 'none';
              this.tooltipConfig.style = style;
              return;
            }

            // Set data
            const chartData = context.chart.data as ChartData<'line'>;
            const {
              dataIndex,
              datasetIndex,
              element,
              label: xAxisLabel,
              dataset: { backgroundColor, borderColor, label: metricLabel },
            } = tooltipModel.dataPoints[0];

            const headerLabel = this.chartDates[dataIndex].format('MMM DD[,] YYYY').toUpperCase();

            const primaryValueText = chartData.datasets[datasetIndex].data[dataIndex]?.toLocaleString() as string;

            const percentOfTotalText = (
              ((chartData.datasets[datasetIndex].data[dataIndex] as number) / this.chartTotalsByDate[xAxisLabel]) *
              100
            ).toFixed(0);

            const primaryBackgroundColor =
              typeof backgroundColor === 'function' ? element.options.backgroundColor : backgroundColor;

            // Display, position, other styles
            this.tooltipConfig.style.display = 'block';
            await this.$nextTick();
            const { width, height } = tooltipEl.getBoundingClientRect();

            style.opacity = '1';
            style.position = 'absolute';
            const leftPos = window.pageXOffset + tooltipModel.caretX - width / 2;
            const topPos = window.pageYOffset + tooltipModel.caretY - height - 8;
            style.left = leftPos + 'px';
            style.top = topPos + 'px';
            style.pointerEvents = 'none';

            const showSecondaryTooltipColumn = chartData.datasets.length > 1 ?? false;

            const tooltipConfig: TooltipConfig = {
              header: {
                label: headerLabel,
                usePlatformIcon: false,
              },
              topRow: {
                primary: {
                  label: metricLabel || '',
                  value: primaryValueText,
                  backgroundColor: primaryBackgroundColor as string,
                  borderColor: borderColor as string,
                },
                ...(showSecondaryTooltipColumn
                  ? {
                      secondary: {
                        label: '% of total',
                        value: percentOfTotalText === 'NaN' ? '0' : percentOfTotalText,
                      },
                    }
                  : {}),
              },
              style,
            };
            this.tooltipConfig = tooltipConfig;

            // Figure out if tooltip is overflowing to right of viewport
            // and push left if so.
            await this.$nextTick();
            const maxRightOffset = document.body.clientWidth - 16; // 16px of padding
            const { right: newRight } = tooltipEl.getBoundingClientRect();
            const diff = maxRightOffset - newRight;
            const shiftLeftPx = diff < 0 ? Math.abs(diff) : 0;
            style.left = leftPos - shiftLeftPx + 'px';
          },
        },
      },
      elements: {
        line: {
          tension: 0.000001,
        },
        point: {
          hitRadius: 10,
          pointStyle: 'line',
        },
      },
    };
  }

  tooltipConfig: TooltipConfig = {
    topRow: {
      primary: {
        label: '',
        value: '',
      },
    },
    style: {
      display: 'none',
      opacity: '0',
    },
  };

  get chartTotalsByDate() {
    return this.chartLabels.reduce((acc, dateLabel, labelIndex) => {
      acc[dateLabel] = 0;
      this.chartDatasets.forEach(({ data }) => {
        acc[dateLabel] += data[labelIndex] || 0;
      });
      return acc;
    }, {});
  }

  get chartData(): ChartData {
    return {
      labels: this.chartLabels,
      datasets: this.chartDatasets,
    };
  }
}
