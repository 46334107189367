







































import { Component, Vue } from 'vue-property-decorator';
import Button from '@/components/common/buttons/Button.vue';
import SearchBar from '@/components/common/form/SearchBar.vue';
import Select from '@/components/common/form/Select.vue';
import Tabs from '@/components/common/tabs/Tabs.vue';

@Component({
  components: {
    Button,
    SearchBar,
    Select,
    Tabs,
  },
  data: () => {
    return {
      tabs: [
        { name: 'Podcasts', active: true, label: 'Podcast', id: '1' },
        { name: 'Audio', active: false, label: 'Audio', id: '2' },
        { name: 'Video', active: false, label: 'Video', id: '3' },
        { name: 'Images', active: false, label: 'Image', id: '4' },
      ],
      campaignOptions: [{ name: 'Test campaign 1', id: 1 }],
    };
  },
})
export default class ContentLibrary extends Vue {}
