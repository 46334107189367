<template>
  <div class="color-picker">
    <ColorPicker
      v-model="selectedColor"
      :disable-alpha="disableAlpha"
      :preset-colors="presetColors"
      @input="$emit('update', selectedColor)"
    />
  </div>
</template>

<script>
import { Sketch as ColorPicker } from 'vue-color';

export default {
  components: {
    ColorPicker,
  },
  props: {
    disableAlpha: {
      type: Boolean,
      default: () => false,
    },
    presetColors: {
      type: Array,
      default: () => [],
    },
    color: Object,
  },
  data() {
    return {
      selectedColor: this.color,
    };
  },
};
</script>

<style lang="scss">
// Color picker overrides
.vc-sketch-presets,
.vc-sketch-color-wrap {
  display: none;
}

.color-picker {
  .vc-sketch {
    border-radius: 0.5rem;
    width: 263px;
    padding: 1rem 1rem 1.5rem;
    &::before {
      content: '';
      width: 1.5rem;
      height: 1.5rem;
      transform-origin: center;
      transform: translate(50%, -50%) rotate(45deg);
      position: absolute;
      top: 50%;
      right: 100%;
      background: #fff;
      box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.15), 0 8px 16px rgba(0, 0, 0, 0.15);
    }
    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      width: 1rem;
      height: 8rem; // arbitrary, just needs to cover the square
      background: #fff;
      z-index: 1;
      transform: translateY(-50%);
    }
  }

  .vc-sketch-saturation-wrap {
    border-radius: 0.5rem;
    border: 1px solid #dadae0;
  }

  .vc-sketch-sliders .vc-hue {
    border-radius: 1rem;
  }

  .vc-editable-input {
    display: flex;
    flex-direction: column-reverse;
  }

  .vc-sketch-field .vc-input__input {
    font-size: 1rem;
    font-family: var(--fm-font-family-roboto);
    border-radius: 0.5rem;
    border: 2px solid #dadae0;
    box-shadow: none;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

  .vc-sketch-field .vc-input__label {
    font-weight: bold;
    font-family: var(--fm-font-family-roboto);
    text-align: left;
    font-size: 0.75rem;
  }

  .vc-sketch-sliders {
    padding: 1rem 0;
  }

  .vc-hue-picker,
  .vc-saturation-circle {
    background: transparent;
    width: 0.5rem;
    height: 0.5rem;
    outline: 0.25rem solid #fff;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    display: block;
    box-shadow: 0 0 1px 5px rgba(0, 0, 0, 0.2);
    margin: 0;
  }
}
</style>
