<template>
  <blank-card id="shortcuts-card">
    <div class="card-header" slot="head">
      <h5 class="your-shortcuts-title">your trending shortcuts</h5>
    </div>
    <div slot="list-wrapper">
      <div v-for="(shortcut, index) in shortcuts" :key="shortcut.key" class="content-wrapper" slot="list">
        <div class="content" slot="list-item" v-if="shortcut.displayName">
          <h5 class="shortcut-number">{{ index + 1 }}</h5>
          <h6 class="shortcut-title">{{ shortcut.displayName }}</h6>
        </div>
      </div>
    </div>
  </blank-card>
</template>

<script>
import BlankCard from '@/components/common/cards/BlankCard.vue';
import DiscoverCategoryService from '@/services/DiscoverCategoryService';

export default {
  components: { BlankCard },
  data: () => {
    return {
      shortcuts: [],
    };
  },
  name: 'your-shortcuts-card',
  props: ['shortcutSelections'],
  watch: {
    shortcutSelections: async function (newShortcutSelections) {
      if (newShortcutSelections.categoryIds.length < 1) {
        this.shortcuts = [];
        return;
      }

      try {
        const response = await new DiscoverCategoryService().fetchShortcutsPreview(newShortcutSelections);
        this.shortcuts = response;
      } catch (err) {
        console.error(err);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.template {
  font-family: Roboto;
  width: 39%;
  height: 25.7%;
}
#shortcuts-card {
  display: flex;
  flex-direction: column;
  padding: 0px;
  background: var(--neutral-white);
  box-shadow: 0px 1px 7px rgba(34, 32, 70, 0.2);
}
.card-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  background: var(--neutral-white);
  border-bottom: 1px solid var(--neutral-grey-3-borders);
  height: 66px;
}
.your-shortcuts-title {
  color: var(--neutral-grey-2-lighter-text);
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 8%;
  position: relative;
  text-transform: uppercase;
  top: 0.4rem;
}
.contact-link {
  margin-left: 4px;
  letter-spacing: 0.38px;
  font-weight: 500;
  font-family: Roboto;

  &:hover {
    cursor: pointer;
  }
}
.contact-bubble {
  margin-right: 4px;

  &:hover {
    cursor: pointer;
  }
}
.content-wrapper {
  display: flex;
  justify-content: center;
  &:last-child .content {
    border-bottom: none;
  }
}
.content {
  display: flex;
  align-self: center;
  justify-content: flex-start;
  width: 95%;
  height: 60px;
  align-items: center;
  background: var(--neutral-white);
  padding: 0 1rem;
  border-bottom: 1px solid var(--neutral-grey-3-borders);
}
.shortcut-number {
  font-weight: 700;
  font-family: Roboto;
  font-size: 20px;
  color: var(--neutral-grey-1-text);
  position: relative;
  top: 0.2rem;
}
.shortcut-title {
  font-weight: 400;
  font-family: Roboto;
  font-size: 16px;
  color: var(--neutral-grey-1-text);
  position: relative;
  top: 0.2rem;
  margin-left: 32px;
}
</style>
