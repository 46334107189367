












import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import Hls from 'hls.js';

const createAndPublishStore = namespace('CreateAndPublishStore');

Component.registerHooks(['mounted', 'beforeDestroy']);

@Component({
  name: 'MinHLSAudioPlayer',
})
export default class MiniHLSAudioPlayer extends Vue {
  @Prop({ type: String, required: true }) readonly audioSrc!: string;
  @Prop({ type: Boolean, default: false }) readonly controls!: boolean;
  @createAndPublishStore.Getter('station_name') readonly stationName!: string;

  audioElement: HTMLMediaElement | null = null;
  hlsPlayer: Hls | null = null;

  @Watch('audioSrc')
  onAudioSrcChange() {
    this.setupHLSAndPlay();
  }

  setupHLSAndPlay() {
    this.audioElement = this.$refs.player as HTMLMediaElement;
    if (Hls.isSupported()) {
      this.hlsPlayer = new Hls();
      if (this.hlsPlayer?.media) {
        // Detach media from HLS if it's currently bound to any.
        this.hlsPlayer.detachMedia();
      }
      // bind them together
      this.hlsPlayer.attachMedia(this.audioElement);
      // MEDIA_ATTACHED event is fired by hls object once MediaSource is ready
      this.hlsPlayer.on(Hls.Events.MEDIA_ATTACHED, () => {
        this.hlsPlayer?.loadSource(this.audioSrc);
        this.audioElement?.play();
      });
    }
  }

  mounted() {
    this.setupHLSAndPlay();
  }

  beforeDestroy() {
    if (this.hlsPlayer?.media) {
      // Detach media from HLS if it's currently bound to any.
      this.hlsPlayer.detachMedia();
    }
  }
}
