





















import { Component, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';

import { productMap } from '@/constants/seeded/products';
import { maybePluralize } from '@/utils';

// enums
import { ProductKey } from '@/types/ecomm';
import { ProductIds } from '@/types/account/AccountData';

import type { SubscriptionData } from '@/utils/ecomm';

@Component<CurrentSubscriptionInfoCard>({
  name: 'CurrentSubscriptionInfoCard',
})
export default class CurrentSubscriptionInfoCard extends Vue {
  @Getter currentSubscriptionData!: SubscriptionData;

  get productMap() {
    return Object.fromEntries(productMap.map((product) => [product.id, product]));
  }

  get productImageSrc() {
    switch (this.currentSubscriptionData?.productKey) {
      case ProductKey.AUDIENCE:
        return productMap.find((product) => product.id === ProductIds.AUDIENCE)?.logoAppCard;
      case ProductKey.DISCOVER:
        return productMap.find((product) => product.id === ProductIds.DISCOVER)?.logoAppCard;
      case ProductKey.CREATE_AND_PUBLISH:
        return productMap.find((product) => product.id === ProductIds.CREATE_AND_PUBLISH)?.logoAppCard;
      case ProductKey.SUITE:
      case ProductKey.SUITE_FREE_TRIAL:
        return 'FuturiCC.svg';
      default:
        return '';
    }
  }

  get brandsManagedCount() {
    const { audienceBrandQuantity, discoverBrandQuantity, createBrandQuantity } = this.currentSubscriptionData;
    return Math.max(audienceBrandQuantity, discoverBrandQuantity, createBrandQuantity);
  }

  get pricingTier() {
    return this.brandsManagedCount > 1 ? 'Enterprise Pricing' : 'Content Creator Pricing';
  }

  get title() {
    switch (this.currentSubscriptionData.productKey) {
      case ProductKey.AUDIENCE:
        return this.productMap[ProductIds.AUDIENCE].referenceName;
      case ProductKey.DISCOVER:
        return this.productMap[ProductIds.DISCOVER].referenceName;
      case ProductKey.CREATE_AND_PUBLISH:
        return this.productMap[ProductIds.CREATE_AND_PUBLISH].referenceName;
      case ProductKey.SUITE:
        return 'Full Suite';
      case ProductKey.SUITE_FREE_TRIAL:
        return 'Full Suite - Free Trial';
      default:
        return '';
    }
  }

  get seats() {
    const { audienceSeatQuantity, discoverSeatQuantity, createSeatQuantity } = this.currentSubscriptionData;

    const audienceString = `${this.pluralize(
      audienceSeatQuantity,
      `${this.productMap[ProductIds.AUDIENCE].referenceName} user`
    )}`;
    const discoverString = `${this.pluralize(
      discoverSeatQuantity,
      `${this.productMap[ProductIds.DISCOVER].referenceName} user`
    )}`;
    const createString = `${this.pluralize(
      createSeatQuantity,
      `${this.productMap[ProductIds.CREATE_AND_PUBLISH].referenceName} user`
    )}`;

    return [
      audienceString,
      ...(this.showDiscoverSeats ? [discoverString] : []),
      ...(this.showCreateSeats ? [createString] : []),
    ];
  }

  get showDiscoverSeats() {
    switch (this.currentSubscriptionData.productKey) {
      case ProductKey.AUDIENCE:
      case ProductKey.CREATE_AND_PUBLISH:
        return false;
      case ProductKey.DISCOVER:
      case ProductKey.SUITE:
      case ProductKey.SUITE_FREE_TRIAL:
        return true;
      default:
        return false;
    }
  }

  get showCreateSeats() {
    switch (this.currentSubscriptionData.productKey) {
      case ProductKey.AUDIENCE:
      case ProductKey.DISCOVER:
        return false;
      case ProductKey.CREATE_AND_PUBLISH:
      case ProductKey.SUITE:
        return true;
      default:
        return false;
    }
  }

  pluralize(count: number, singularNoun: string) {
    return maybePluralize(count, singularNoun);
  }
}
