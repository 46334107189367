




import { Component, Vue, Prop } from 'vue-property-decorator';
import { AudioEvent } from '@/types/createandpublish/mediaLibraries';

@Component({
  name: 'TableInlineMenu',
})
export default class TableInlineMenu extends Vue {
  @Prop({ default: {}, required: true }) readonly audioData!: AudioEvent;

  get menuOptions() {
    return [
      {
        label: 'Edit details',
        onClick: () => undefined,
      },
      {
        label: 'Download',
        onClick: () => undefined,
      },
      {
        label: 'Delete',
        onClick: () => undefined,
      },
      {
        label: 'Create video',
        onClick: () => undefined,
      },
      {
        label: 'Tag to campaign',
        onClick: () => undefined,
      },
      {
        label: 'Share to social',
        onClick: () => undefined,
      },
    ];
  }
}
