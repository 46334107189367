<template>
  <div>
    <div :class="{ expanded: expanded, noBottomBorder: isLast }" class="settings_card_item__wrapper">
      <!-- Name -->
      <div :class="{ expanded: expanded }" class="settings_card_item__connection-name">
        {{ name }}
      </div>
      <!-- Aggregate item data -->
      <div class="aggregate-item-data" v-if="expansionItemsCount">
        <fm-body variant="DISCLAIMER">
          <template v-if="!expanded">
            <button @click="expanded = true">
              Click for {{ expansionItemsCount }} {{ term }}{{ expansionItemsCount > 1 ? 's' : '' }}
            </button>
          </template>
          <template v-else>
            <button @click="expanded = false">hide {{ term }}{{ expansionItemsCount > 1 ? 's' : '' }}</button>
          </template>
        </fm-body>
      </div>
      <!-- Buttons -->
      <div class="settings_card_item__buttons-wrapper">
        <Button classes="settings_card_item__action" title="Edit">
          <span class="material-icons" @click="onEdit"> edit </span>
        </Button>
        <Button v-if="onRemove" classes="settings_card_item__action" title="Delete">
          <span v-if="expansionItemsCount || allowDeleteOverride" class="material-icons" @click="onRemove">
            clear
          </span>
          <span v-else class="material-icons material-icons-disabled"> clear </span>
        </Button>
        &nbsp;&nbsp;
      </div>
    </div>
    <!-- Expansion -->
    <div v-if="expanded" class="item-expansion">
      <div class="expansionItem" v-for="(item, index) in expansionData" :key="index">
        {{ item }}
      </div>
    </div>
  </div>
</template>
<script>
import Button from '../../common/Button.vue';

export default {
  name: 'SettingsCardItem',
  components: {
    Button,
  },
  data() {
    return {
      expanded: false,
      showConfirm: false,
    };
  },
  computed: {
    expansionItemsCount() {
      return (this.expansionData && this.expansionData.length) || undefined;
    },
  },
  props: {
    expansionData: Array,
    isLast: Boolean,
    name: String,
    onEdit: Function,
    onRemove: Function || undefined,
    allowDeleteOverride: { type: Boolean, default: false, required: false },
    term: String,
  },
};
</script>
<style lang="scss" scoped>
div.settings_card_item__wrapper.expanded {
  margin: 0;
  padding: 16px;
}
div.settings_card_item__wrapper.noBottomBorder {
  border-bottom: 0 !important;
}
div.settings_card_item__wrapper {
  align-items: center;
  background: var(--neutral-white) 0% 0% no-repeat padding-box;
  border-bottom: 1px solid var(--neutral-grey-3-borders);
  display: flex;
  height: 48px;
  justify-content: space-between;
  margin: 0 16px;

  .settings_card_item__connection-name {
    color: var(--neutral-grey-1-text);
    font-size: 14px;
    letter-spacing: 0px;
    text-align: left;
    width: 70%;
  }
  .settings_card_item__connection-name.expanded {
    font-weight: 600;
  }
  div.settings_card_item__buttons-wrapper {
    display: flex;
    justify-content: flex-end;
    margin-right: -6px;
  }
  div.settings_card_item__buttons-wrapper span.material-icons {
    color: var(--accent-links);
    font-size: 18px;
  }
}

.material-icons-disabled {
  opacity: 0.25;
}

.settings_card_item__action {
  margin-left: 16px !important;
  padding: 6px !important;
}

.aggregate-item-data {
  text-align: right;
  width: 30%;
  button {
    color: var(--accent-links);
    text-decoration: underline;
  }
}

.item-expansion {
  background-color: var(--neutral-grey-4-light-bg);
}

.expansionItem {
  border-bottom: 1px solid var(--neutral-grey-3-borders);
  margin-left: 64px;
  margin-right: 16px;
  padding: 16px 0;
}
.expansionItem:last-of-type {
  margin: 0;
  padding: 16px 16px 16px 64px;
}
</style>
