





















import { Component, Vue, Prop } from 'vue-property-decorator';
import { Getter } from 'vuex-class';

import CurrentSubscriptionData from '@/components/subscriptions/CurrentSubscriptionData.vue';
import SubscriptionProduct from '@/components/subscriptions/SubscriptionProduct.vue';
import ViewSubtitle from '@/components/common/view/viewSubtitle/ViewSubtitle.vue';

import type { Product } from '@/types/Product';

@Component({
  name: 'SubscriptionsTab',
  components: {
    CurrentSubscriptionData,
    SubscriptionProduct,
    ViewSubtitle,
  },
})
export default class SubscriptionsTab extends Vue {
  @Prop({ type: Boolean, default: false }) locked!: boolean;

  @Getter('sortedPriorityAccountProductsAll') accountProducts!: Product[];

  changeToBillingTab() {
    this.$emit('showBillingAndPaymentTab', 1);
  }
}
