












































































































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import TrendingStoryDrawerTabs from '@/components/discover/drawer/TrendingStoryDrawerTabs.vue';
import DiscoverStoryService from '@/services/DiscoverStoryService';
import Loading from '@/components/common/Loading.vue';
import Drawer from '@/components/common/drawer/Drawer.vue';
import Button from '@/components/common/buttons/Button.vue';
import CampaignCircle from '@/components/common/campaigns/CampaignCircle.vue';
import CampaignMenu from '@/components/common/campaigns/CampaignMenu.vue';
import EventHub from '@/components/discover/EventHub.vue';
import { Story, GenderClass } from '@/types/discover/Story';
import { Campaign } from '@/types/Campaign';
import { Brand } from '@/types/Brand';

@Component({
  components: {
    TrendingStoryDrawerTabs,
    Loading,
    Drawer,
    Button,
    CampaignMenu,
    CampaignCircle,
  },
})
export default class TrendingDrawer extends Vue {
  @Getter selectedBrand!: Brand;
  @Prop() drawerStatus!: Record<string, unknown>;
  @Prop() visible!: boolean;

  story: Story | null = null;
  engagementData: EngagementData | null = null;
  loaderVisible = true;
  campaignIds: number[] = [];
  selectedCampaigns: Campaign[] = [];

  reset() {
    this.loaderVisible = true;
    this.story = null;
    this.engagementData = null;
    this.campaignIds = [];
  }

  async loadStoryById(storyId) {
    const response = (await new DiscoverStoryService().getOne(storyId, this.selectedBrand.id)) as Story;
    this.story = response;
    this.populateEngagement();
    this.campaignIds = this.story.campaign_ids;
    this.loaderVisible = false;
    this.getSelectedCampaigns();
  }

  formatDate(timestamp) {
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const d = new Date(timestamp);
    const t = new Date(timestamp).toLocaleTimeString('en', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
      timeZone: 'UTC',
    });
    return `${months[d.getMonth()]} ${d.getDate()}, ${d.getFullYear()} ${t}`;
  }

  formatDomain(domain: string) {
    const prot = /^(https?|ftp):\/\//;
    return domain.replace(prot, '').replace('/', '');
  }

  getGenderClass(gender: GenderClass) {
    return gender === 'neutral' ? 'Males and Females' : `${gender}s`;
  }

  triggerShare() {
    console.log('click');
  }

  populateEngagement() {
    if (this.story) {
      this.engagementData = {
        gender: this.story.gender_class,
        age: this.story.age,
        sources: this.story.articles.length,
        social: this.story.history[this.story.history.length - 1].social,
      };
    }
  }

  //maintain tagged state until data refreshes
  campaignData() {
    const refreshPayload = {
      storyId: this.story?.storyId,
      campaign_ids: this.selectedCampaigns.map((_) => _.id),
    };
    this.$emit('refreshData', refreshPayload);
  }

  async tagCampaign(value) {
    if (value.length !== this.selectedCampaigns.length) {
      this.updateCampaigns(value);
    }
  }

  async updateCampaigns(value) {
    const taggedCampaignIds = value;
    const legacyCampaignIds = this.selectedCampaigns.map((_) => _.id);
    const addCampaignIds = taggedCampaignIds.filter((id) => !legacyCampaignIds.includes(id));
    const removeCampaignIds = legacyCampaignIds.filter((id) => !taggedCampaignIds.includes(id));

    if (removeCampaignIds.length) {
      this.selectedCampaigns = this.selectedCampaigns.filter((campaign) => removeCampaignIds[0] !== campaign.id);
      this.campaignData();
    }

    if (addCampaignIds.length) {
      this.selectedCampaigns.push(
        this.selectedBrand.campaigns.filter((campaign) => addCampaignIds[0] === campaign.id)[0]
      );
      this.campaignData();
    }
  }

  onAddNewCampaign() {
    //close single story drawer
    this.$root.$emit('bv::toggle::collapse', 'sidebar-trendingStory');

    //pass story data to add to campaign drawer
    EventHub.$emit('addCampaignDrawerData', {
      storyId: this.story?.storyId,
      campaigns: this.selectedCampaigns,
      campaignIds: this.selectedCampaigns.map((_) => _.id),
      availableCampaigns: this.selectedBrand.campaigns,
      dataType: 'trending',
    });
  }

  getSelectedCampaigns() {
    this.selectedCampaigns = (this.selectedBrand as Brand).campaigns.filter(
      (campaign) => campaign.id && this.campaignIds.includes(campaign.id)
    );
  }
}

interface EngagementData {
  gender: GenderClass;
  age: string;
  sources: number;
  social: number;
}
