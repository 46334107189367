


























































































import Vue from 'vue';
import { mapGetters } from 'vuex';
import { RSSFeed } from '@/types/discover/DiscoverRSSFeed';
import BlankCard from '@/components/common/cards/BlankCard.vue';
import Button from '@/components/common/buttons/Button.vue';
import DiscoverSettingsService from '@/services/DiscoverSettingsService';

export default Vue.extend({
  components: {
    BlankCard,
    Button,
  },
  name: 'rss-feeds-card',
  props: {
    feedData: Array,
  },
  data() {
    return {
      removeVisible: false,
      removing: -1,
    };
  },
  computed: {
    ...mapGetters(['selectedBrand']),
  },
  methods: {
    editFeed(feed: RSSFeed) {
      this.$emit('edit', feed);
      this.$root.$emit('bv::toggle::collapse', 'discover-rss-feed-drawer');
    },
    cancelRemove() {
      this.removeVisible = false;
    },
    confirmRemove(index) {
      this.removing = index;
      this.removeVisible = true;
    },
    async removeFeed(feed) {
      this.removing = -1;
      let response = {};
      response = await new DiscoverSettingsService()
        .deleteFeed(this.selectedBrand.id, feed.id)
        .then((res) => res)
        .catch((err) => err.response);
      await response;
      this.$emit('refreshData', '');
    },
  },
});
