<template>
  <div class="image-upload-and-preview">
    <combo-image-selector-modal
      v-if="isComboImageSelectorModalOpen"
      :allowLicensedImages="allowLicensedImages"
      :minWidth="minWidth"
      :minHeight="minHeight"
      :maxWidth="maxWidth"
      :maxHeight="maxHeight"
      @close="closeComboModal"
      @standard-image-selected="handleStandardImageSelected"
      @licensed-image-selected="handleLicensedImageSelected"
    />

    <crop-image-upload
      v-if="isCropImageUploadModalOpen"
      :imageUrl="imageSrc"
      :aspectRatio="cropAspectRatio"
      :autoCropArea="1"
      @close="handleImageCropped"
    />

    <input type="file" ref="fileInput" hidden accept="image/*" @input="onFileInputChange" />

    <fm-text class="title">{{ title }}</fm-text>
    <fm-text class="assistive-text" variant="disclaimer">
      {{ assistiveText }}
    </fm-text>
    <div v-if="imageData.url">
      <div class="image-preview-container" :style="`width: ${width}; height: ${height}`">
        <img class="image-preview" :src="imageSrc" alt="Image preview" />
      </div>
      <fm-button-tertiary @click="clearImage" style="margin-right: 24px">Clear image</fm-button-tertiary>
      <fm-button-tertiary v-if="isCropAllowed" :loading="isLoading" @click="showCropModal"
        >Edit crop</fm-button-tertiary
      >
    </div>

    <div
      v-else
      class="upload-box"
      :style="`width: ${width}; height: ${height}`"
      @drop.prevent="handleDrop"
      @dragover.prevent="inspectDragEvent"
    >
      <i class="material-icons publish-icon">publish</i>
      <fm-button-secondary :loading="isLoading" @click="onChooseImage">Choose image</fm-button-secondary>
      <fm-text>or drag from your desktop</fm-text>
    </div>
  </div>
</template>

<script>
import ComboImageSelectorModal from '@/createandpublish/components/ComboImageSelectorModal';
import CropImageUpload from '@/createandpublish/components/CropImageUpload';
import { createNamespacedHelpers } from 'vuex';
const { mapGetters } = createNamespacedHelpers('CreateAndPublishStore');
import CreateAndPublishService from '@/services/CreateAndPublishService';

export default {
  props: {
    title: String,
    assistiveText: String,
    width: String,
    height: String,
    minWidth: Number,
    minHeight: Number,
    maxWidth: Number,
    maxHeight: Number,
    value: Object,
    cropAspectRatio: Number,
    acceptNewUploadsOnly: Boolean,
    allowLicensedImages: { type: Boolean, default: false },
    imageType: String,
  },

  computed: {
    ...mapGetters(['station', 'mediaUrl']),

    isCropAllowed() {
      return !this.imageData.licensedImageId;
    },

    imageSrc() {
      const location = 'https://post.ccdev.futurimedia.com';

      if (!this.imageData.url.includes(location)) {
        return `${location}${this.imageData.url}`;
      }

      return this.imageData.url;
    },
  },

  data: () => ({
    imageData: {
      licensedImageId: undefined,
      url: undefined, // Standard image. Also the image displayed in the preview.
      licensedImageDownloadUrl: undefined, // Large version of licensed image.
    },
    isCropImageUploadModalOpen: false,
    isLoading: false,
    isComboImageSelectorModalOpen: false,
  }),

  methods: {
    handleDrop(e) {
      const dt = e.dataTransfer;
      if (!this.canDrop(dt.items)) return;
      const file = dt.files[0];
      this.uploadImageFile(file);
    },

    inspectDragEvent(e) {
      const dt = e.dataTransfer;
      dt.dropEffect = this.canDrop(dt.items) ? 'copy' : 'none';
    },

    /**
     * Tests whether or not a user is dragging a single image over the drop-zone.
     * @param {DataTransferItemList} items list of items being dragged
     * @returns {Boolean} true if user is allowed to drop content
     */
    canDrop(items) {
      const re = new RegExp(/^image\//);
      return !!items && items.length === 1 && re.test(items[0].type);
    },

    onChooseImage() {
      if (!this.acceptNewUploadsOnly) {
        this.openComboModal();
        return;
      }

      this.$refs.fileInput && this.$refs.fileInput.click();
    },

    onFileInputChange(e) {
      const { files } = e.target;
      if (files.length) {
        console.log('files', files[0]);
        this.uploadImageFile(files[0]);
      }
    },

    clearImage() {
      this.imageData = {
        licensedImageId: undefined,
        url: undefined,
        licensedImageDownloadUrl: undefined,
      };
    },

    async uploadImageFile(file) {
      const formData = new FormData();
      formData.append('file', file);
      try {
        this.isLoading = true;
        const response = await new CreateAndPublishService().uploadImage(formData, this.imageType);
        this.imageData.url = response.location; // This is a URL of the image
      } catch (e) {
        console.error(e);
      } finally {
        this.isLoading = false;
      }
    },

    openComboModal() {
      this.isComboImageSelectorModalOpen = true;
    },

    closeComboModal() {
      this.isComboImageSelectorModalOpen = false;
    },

    async handleStandardImageSelected(url) {
      this.imageData = {
        licensedImageId: undefined,
        url,
        licensedImageDownloadUrl: undefined,
      };
      this.closeComboModal();
      this.isLoading = true;
      await this.handleImageCropped({
        // Generate .cropped file on s3
        cropPercentages: { left: 0, top: 0, right: 0, bottom: 0 },
      });
      this.isLoading = false;
    },

    handleLicensedImageSelected({ id, previewUrl, mainUrl }) {
      this.imageData = {
        licensedImageId: id,
        url: previewUrl,
        licensedImageDownloadUrl: mainUrl,
      };
      this.closeComboModal();
    },

    showCropModal() {
      this.isCropImageUploadModalOpen = true;
    },

    async handleImageCropped(data) {
      this.isCropImageUploadModalOpen = false;

      if (!data) return;

      const { cropPercentages } = data;

      this.isLoading = true;

      // Send crop data to server and get back location of new cropped image
      const resp = await this.$store.dispatch('CreateAndPublishStore/cropImage', {
        image: this.imageData.url,
        croppings: cropPercentages,
        square: !(this.cropAspectRatio && this.cropAspectRatio !== 1),
      });
      if (resp && resp.status_code === 201 && resp.location) {
        this.imageData.url = resp.location;
      }

      this.isLoading = false;
    },
  },

  watch: {
    imageData(newValue) {
      this.$emit('input', newValue);
    },

    value: {
      handler: function (newValue) {
        this.imageData = newValue;
      },
      immediate: true,
      deep: true,
    },
  },

  components: {
    CropImageUpload,
    ComboImageSelectorModal,
  },
};
</script>

<style scoped lang="scss">
.image-upload-and-preview {
  padding: 1em 0 2em;
}

.title {
  margin-bottom: 8px;

  &::part(tag) {
    font-weight: 500;
  }
}

.assistive-text {
  margin-bottom: 8px;
}

.upload-box {
  border: 2px dashed var(--accent-links);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  & > * {
    padding: 0.5em 0;
  }
}

.image-preview-container {
  margin-bottom: 1em;
}

.image-preview {
  height: 100%;
  width: 100%;
  object-fit: contain;
}
.publish-icon {
  color: var(--neutral-grey-2-lighter-text);
}
</style>
