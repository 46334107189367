import PodcastAnalyticsRoute from '@/createandpublish/views/analytics/podcasts/AnalyticsRoute';
import PodcastAnalyticsShows from '@/createandpublish/views/analytics/podcasts/AnalyticsShows';
import PodcastAnalyticsEpisodes from '@/createandpublish/views/analytics/podcasts/AnalyticsEpisodes';
import PodcastAnalyticsCountries from '@/createandpublish/views/analytics/podcasts/AnalyticsCountries';
import PodcastAnalyticsPlatforms from '@/createandpublish/views/analytics/podcasts/AnalyticsPlatforms';
import PodcastAnalyticsDate from '@/createandpublish/views/analytics/podcasts/AnalyticsDate';
import store from '@/store';

export default {
  path: 'podcasts',
  name: 'Create.Analytics.Podcasts',
  component: PodcastAnalyticsRoute,
  redirect: 'podcasts/date',
  beforeEnter(_to, _from, next) {
    store.commit('CreateAndPublishStore/analytics/CLEAR_ALL_ANALYTICS_DATA');
    next();
  },
  children: [
    {
      path: 'date',
      name: 'Create.Analytics.Podcasts.Date',
      component: PodcastAnalyticsDate,
      meta: {
        title: 'Analytics | Create&Publish',
        sidebar_active_item: 'Analytics',
      },
    },
    {
      path: 'shows',
      name: 'Create.Analytics.Podcasts.Shows',
      component: PodcastAnalyticsShows,
      meta: {
        title: 'Analytics | Create&Publish',
        sidebar_active_item: 'Analytics',
      },
    },
    {
      path: 'episodes',
      name: 'Create.Analytics.Podcasts.Episodes',
      component: PodcastAnalyticsEpisodes,
      meta: {
        title: 'Analytics | Create&Publish',
        sidebar_active_item: 'Analytics',
      },
    },
    {
      path: 'countries',
      name: 'Create.Analytics.Podcasts.Countries',
      component: PodcastAnalyticsCountries,
      meta: {
        title: 'Analytics | Create&Publish',
        sidebar_active_item: 'Analytics',
      },
    },
    {
      path: 'platforms',
      name: 'Create.Analytics.Podcasts.Platforms',
      component: PodcastAnalyticsPlatforms,
      meta: {
        title: 'Analytics | Create&Publish',
        sidebar_active_item: 'Analytics',
      },
    },
  ],
};
