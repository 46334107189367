












































































































































































































































































import { Component, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import AlertDisplay from '../../components/common/AlertDisplay.vue';
import Select from '../../components/common/form/Select.vue';
import Button from '../../components/common/buttons/Button.vue';
import AddYoutubeConnectionDrawer from '../components/integrations/AddYoutubeConnectionDrawer.vue';
import AddGlobalTagDrawer from '../components/integrations/AddGlobalTagDrawer.vue';
import EditTrackingURLsDrawer from '../components/trackingURLs/EditTrackingURLsDrawer.vue';
import ConfirmationModal from '../ConfirmationModal.vue';
import { createNamespacedHelpers } from 'vuex';
const { mapGetters, mapState, mapActions } = createNamespacedHelpers('CreateAndPublishStore');
const { mapActions: mapActionsShowSetup } = createNamespacedHelpers('CreateAndPublishStore/showSetup');
import { Show } from '../../types/Show';
import { Brand } from '@/types/Brand';

interface TrackingUrl {
  showId: number | null;
  trackingUrl: string;
}

interface ActiveTag {
  id: number;
  name: string;
  resource_uri: string;
}

@Component({
  name: 'PodcastSettingsTab',
  components: {
    AlertDisplay,
    Select,
    Button,
    AddYoutubeConnectionDrawer,
    ConfirmationModal,
    AddGlobalTagDrawer,
    EditTrackingURLsDrawer,
  },
  computed: {
    ...mapState(['socialMediaConnections']),
    ...mapGetters(['unfilteredShows', 'stationTrackingPrefixList', 'playlistCategories']),
  },
  methods: {
    ...mapActions(['setStationTrackingPrefixList', 'setShowTrackingPrefixList', 'deletePlaylistCategory']),
    ...mapActionsShowSetup(['deleteSocialMediaConnection']),
  },
})
export default class PodcastSettingsTab extends Vue {
  @Getter readonly selectedBrand!: Brand;

  socialMediaConnections;
  unfilteredShows;
  deleteSocialMediaConnection;
  playlistCategories;
  deletePlaylistCategory;
  stationTrackingPrefixList;
  setStationTrackingPrefixList;
  setShowTrackingPrefixList;

  destinations = [
    { filename: 'rss-feed.svg', title: 'RSS' },
    { filename: 'apple.svg', title: 'Apple iTunes' },
    { filename: 'google-play.svg', title: 'Google Play' },
    { filename: 'spotify.svg', title: 'Spotify' },
    { filename: 'iheart-radio.svg', title: 'iHeart Radio' },
    { filename: 'stitcher.svg', title: 'Stitcher' },
    { filename: 'tune-in.svg', title: 'Tune In' },
    { filename: 'radio-com.svg', title: 'Radio' },
    { filename: 'pocketcasts.svg', title: 'Pocketcasts' },
    { filename: 'pandora.svg', title: 'Pandora' },
    { filename: 'amazon-echo.svg', title: 'Amazon Echo' },
    { filename: 'google-home.svg', title: 'Google Home' },
    { filename: 'wordpress.svg', title: 'WordPress' },
    { filename: 'deezer.svg', title: 'Deezer' },
    { filename: 'radio-public.svg', title: 'Radio Public' },
    { filename: 'code.svg', title: 'Widget embed' },
    { filename: 'youtube.svg', title: 'YouTube' },
  ];

  alertMessage = {
    type: 'info',
    header: `You don't have any podcasts yet!`,
    message: 'Set up a show to manage publishing destinations.',
    links: [
      {
        route: `/create/${this.selectedBrand?.id}/podcasts/shows/edit/new/basic-info`,
        message: 'Create a new show',
      },
    ],
  };

  selectedShow = null;
  selectedYouTube = [];
  isLoading = false;
  activeSmc = null;
  isDeleteYouTubeModalOpen = false;
  activeTag = {} as ActiveTag | null;
  isDeleteTagModalOpen = false;
  activeTrackingShow: null | Show = null;
  activeTrackingUrl = '';
  isTrackingUrlDeleteModalOpen = false;
  isEditingTrackingUrl = false;

  get showOptions(): { id: number; value: string }[] {
    return this.unfilteredShows.map((item) => ({ id: item.id, value: item.title }));
  }

  get isYouTubeDrawerOpen() {
    return this.$route.query && this.$route.query.newConnection === 'v_youtube';
  }

  get isGlobalTagDrawerOpen() {
    return this.$route.query && this.$route.query.newConnection === 'tag';
  }

  get isTrackingUrlDrawerOpen() {
    return this.$route.query && this.$route.query.newConnection === 'tracking_url';
  }

  get showsWithTrackingUrls() {
    return this.unfilteredShows.filter((item) => item.tracking_prefixes.length);
  }

  get trackingUrls(): TrackingUrl[] {
    const trackingUrls: TrackingUrl[] = [];

    trackingUrls.push(...this.stationTrackingPrefixList.map((item) => ({ showId: null, trackingUrl: item })));
    this.showsWithTrackingUrls.forEach((show) => {
      trackingUrls.push(...show.tracking_prefixes.map((item) => ({ showId: show.id, trackingUrl: item })));
    });

    return trackingUrls;
  }

  routeToPublishingDestinations() {
    this.$router.push(
      `/create/${this.selectedBrand?.id}/podcasts/shows/edit/${this.selectedShow}/publishing-destinations`
    );
  }

  getSmcsByType(type: string): [] {
    return this.socialMediaConnections.filter((item) => item.type === type);
  }

  closeDrawers() {
    this.activeSmc = null;
    this.$router.replace({ query: {} });
  }

  toggleDrawer(type: string) {
    this.$router.replace({
      query: {
        newConnection: type,
        step: '1',
      },
    });
  }

  editSmc(smc) {
    this.activeSmc = smc;
    this.toggleDrawer(smc.type);
  }

  deleteSmc(smc) {
    this.activeSmc = smc;
    this.isDeleteYouTubeModalOpen = true;
  }

  addYouTubeConnection() {
    this.activeSmc = null;
    this.toggleDrawer('v_youtube');
  }

  addTrackingUrl() {
    this.activeTrackingShow = null;
    this.isEditingTrackingUrl = false;
    this.toggleDrawer('tracking_url');
  }

  editTrackingUrl(showId) {
    const show = this.unfilteredShows.find((item) => item.id === Number(showId));
    this.activeTrackingShow = show ? show : null;
    this.isEditingTrackingUrl = true;
    this.toggleDrawer('tracking_url');
  }

  deleteTrackingUrl(showId: string | number, trackingUrl: string) {
    const show = this.unfilteredShows.find((item) => item.id === Number(showId));
    this.activeTrackingShow = show ? show : null;
    this.activeTrackingUrl = trackingUrl;
    this.isTrackingUrlDeleteModalOpen = true;
  }

  async confirmDeleteTrackingUrl() {
    this.isLoading = true;
    if (this.activeTrackingShow) {
      const trackingPrefixes: string[] = this.activeTrackingShow.tracking_prefixes;
      const index = trackingPrefixes.indexOf(this.activeTrackingUrl);
      const urls = [...this.activeTrackingShow.tracking_prefixes];
      urls.splice(index, 1);
      await this.setShowTrackingPrefixList({ showId: this.activeTrackingShow.id, prefixObj: { prefix: urls } });
    } else {
      const index = this.stationTrackingPrefixList.indexOf(this.activeTrackingUrl);
      const urls = [...this.stationTrackingPrefixList];
      urls.splice(index, 1);
      await this.setStationTrackingPrefixList({ prefix: urls });
    }

    this.isTrackingUrlDeleteModalOpen = false;
    this.isLoading = false;
  }

  async confirmDeleteSmc() {
    this.isLoading = true;
    await this.deleteSocialMediaConnection(this.activeSmc);
    this.isLoading = false;
    this.isDeleteYouTubeModalOpen = false;
  }

  openTagDrawer(tag = null) {
    this.activeTag = tag;
    this.toggleDrawer('tag');
  }

  toggleDeleteTagModal(tag) {
    this.activeTag = tag;
    this.isDeleteTagModalOpen = true;
  }

  async confirmDeleteGlobalTag() {
    this.isLoading = true;
    if (this.activeTag) {
      await this.deletePlaylistCategory(this.activeTag.id);
    }
    this.isLoading = false;
    this.isDeleteTagModalOpen = false;
  }
}
