<template>
  <fm-drawer :visible="true">
    <div slot="content" class="add-connection__wrapper">
      <close-button class="close-button" @click="$emit('onClose')" />

      <div class="add-connection__heading">
        <fm-text tag="h1">
          {{ headerText }}
        </fm-text>
      </div>

      <div class="add-connection__steps">
        <step-bubbles :currentStep="currentStep" :totalSteps="3" />
      </div>

      <div class="add-connection__content">
        <!-- Step 1 Basic info-->
        <div v-if="currentStep === 1">
          <fm-text tag="h2"> Add basic info </fm-text>
          <fm-text tag="p" style="margin-top: 16px; margin-bottom: 24px">
            These will help new listeners find your feed on the platforms it's submitted to.
          </fm-text>

          <fm-input
            label="Title"
            assistiveText="The name of your show or show feature."
            v-model-wc="$v.form.title.$model"
            :error="$v.form.title.$error ? 'Required' : ''"
            :key="'title'"
          />

          <fm-input label="Subtitle (optional)" v-model-wc="form.subtitle" :key="'subtitle'" />

          <fm-textarea
            label="Description"
            assistiveText="The description of the content posted to this feed."
            :rows="4"
            v-model-wc="$v.form.description.$model"
            :error="$v.form.description.$error ? 'Required' : ''"
            :key="'description'"
          />

          <fm-select
            label="Episode order"
            assistiveText="Episodic feeds are sorted newest to oldest, by publish date. Serial feeds are sorted by season and episode number."
            v-model-wc="form.episodicOrSerial"
            :options.prop="episodeOrderOptions"
            :key="'show_format'"
          />

          <fm-select
            label="Language"
            v-model-wc="form.language"
            :options.prop="languageOptions"
            :key="'language_override'"
          />

          <fm-input
            label="Author"
            assistiveText="This can be a company or an individual."
            v-model-wc="$v.form.author.$model"
            :error="$v.form.author.$error ? 'Required' : ''"
            :key="'author'"
          />

          <fm-input
            label="Primary contact email"
            assistiveText="This will be published in your RSS feeds. It may be used to verify your account, and will be visible to podcast aggregators and possibly other users."
            v-model-wc="$v.form.email.$model"
            :error="$v.form.email.$error ? 'Required' : ''"
            :key="'email'"
          />

          <fm-input
            label="Website (optional)"
            assistiveText="To increase discoverability, make sure your show name is unique and not in use by anyone else."
            v-model-wc="form.website"
            :key="'website'"
          />
        </div>

        <!-- Step 2 Display details-->
        <div v-if="currentStep === 2">
          <fm-text tag="h2" style="margin-bottom: 32px"> Display details </fm-text>

          <image-upload-and-preview
            v-model="squareCoverArt"
            title="Square cover art"
            assistive-text="Minimum size 1400 x 1400px. Maximum size 3000 x 3000px."
            width="261px"
            height="261px"
            :acceptNewUploadsOnly="true"
            image-type="square_image"
          />

          <div v-for="(categorySelect, i) in categorySelects" :key="categorySelect.key" class="form-group">
            <fm-select
              :options.prop="categoryOptions"
              placeholder="Choose a category"
              :value.prop="categorySelect.category"
              :label="categorySelect.label"
              @input="(e) => updateCategoryByKey(categorySelect.key, e)"
              :error.prop="getErrorMessageForCategorySelect(i)"
            />
            <div
              style="display: flex; align-items: center"
              v-if="categorySelect.category && getSubCategoriesForCategory(categorySelect.category).length"
            >
              <i class="material-icons">subdirectory_arrow_right</i>
              <div class="form-group" style="flex: 1">
                <fm-select
                  :options.prop="getSubCategoriesForCategory(categorySelect.category)"
                  placeholder="Category name"
                  :value.prop="categorySelect.subCategory"
                  :label="categorySelect.subCategoryLabel"
                  @input="(e) => updateSubCategoryByKey(categorySelect.key, e)"
                />
              </div>
            </div>
          </div>

          <fm-select
            label="Content rating"
            :options.prop="explicitOptions"
            v-model-wc="form.explicit"
            assistiveText=""
            :key="'rss_explicit'"
          />
        </div>

        <div v-if="currentStep === 3">
          <fm-text tag="h2" style="margin-bottom: 32px"> Adjust settings </fm-text>

          <fm-text tag="h3" style="margin-bottom: 24px"> iHeart settings </fm-text>

          <fm-input
            assistiveText="Setting this will allow you to drive listeners from social channels to the iHeart page that hosts this feed's content."
            label="iHeart show ID (optional)"
            v-model-wc="form.iHeartShowId"
            :key="'iheart_show_id'"
          />

          <fm-select
            label="iHeart aggregate feed (optional)"
            assistiveText="If you've added an iHeart show ID above, select 'Yes' if this feed is an aggregate of several shows or features."
            v-model-wc="form.isAggregate"
            :options.prop="yesNoOptions"
            :key="'is_aggregate'"
          />

          <hr />

          <fm-text tag="h3" style="margin-bottom: 24px"> Podcast Connect settings </fm-text>
          <fm-input
            label="Podcast Connect authorization (optional)"
            assistiveText="Add your Podcast Connect authorization code here."
            v-model-wc="form.podcastConnectAuth"
            :key="'itunes_auth'"
          />

          <hr />

          <fm-text tag="h3" style="margin-bottom: 24px"> Publishing and miscellaneous settings </fm-text>

          <fm-select
            label="Contains live reads"
            assistiveText="A live read is an advertisement delivered natively within the content of a show."
            v-model-wc="form.hasLiveReads"
            :options.prop="yesNoOptions"
            :key="'has_live_read'"
          />

          <fm-select
            label="Active status"
            assistiveText="If no new content will be published to this feed, select 'Inactive.'"
            v-model-wc="form.status"
            :options.prop="activeOptions"
            :key="'status'"
          />

          <fm-checkbox
            label="Publish to this destination by default"
            :options.prop="defaultOptions"
            v-model-wc="defaultValue"
            :key="'default_on_post'"
          />
        </div>
      </div>

      <div class="add-connection__actions">
        <fm-button-primary
          class="next-button"
          :success="status === 'success'"
          :disabled="!canContinue"
          @click="onSubmit"
        >
          {{ currentStep !== 3 ? 'Continue' : 'Save publishing destination' }}
        </fm-button-primary>

        <fm-button-secondary v-if="currentStep > 1" class="back-button" @click="onBackClicked">
          Back
        </fm-button-secondary>

        <!-- Cancel Button -->
        <fm-button-secondary class="cancel-button" @click="$emit('onClose')"> Cancel </fm-button-secondary>
      </div>
    </div>
  </fm-drawer>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
const { mapGetters } = createNamespacedHelpers('CreateAndPublishStore');
const { mapActions: mapActionsSetupShow, mapState: mapStateShowSetup } = createNamespacedHelpers(
  'CreateAndPublishStore/showSetup'
);
import { required } from 'vuelidate/lib/validators';
import languages from '@/createandpublish/core/data/ISO_639';
import categories from '@/createandpublish/core/data/iTunesCategories';
import ImageUploadAndPreview from '@/createandpublish/components/ImageUploadAndPreview';
import StepBubbles from '@/createandpublish/components/integrations/StepBubbles';
import CloseButton from '@/createandpublish/components/CloseButton';

export default {
  name: 'AddRSSConnectionDrawer',

  components: {
    StepBubbles,
    CloseButton,
    ImageUploadAndPreview,
  },

  props: {
    editingSMC: { type: Object, default: null },
  },

  data() {
    return {
      defaultValue: [],
      iHeartValue: '',
      isLoading: false,
      status: null,
      form: {
        title: '',
        subtitle: '',
        description: '',
        episodicOrSerial: 'episodic',
        language: 'en',
        author: '',
        email: '',
        website: '',
        explicit: 'clean',
        iHeartShowId: '',
        isAggregate: 'no',
        podcastConnectAuth: '',
        hasLiveReads: 'no',
        status: 'active',
      },
      squareCoverArt: {
        url: '',
      },
      categorySelects: [
        {
          key: 'primaryCategory',
          label: 'Main category',
          category: null,
          subCategoryLabel: 'Main subcategory (optional)',
          subCategory: null,
          required: true,
        },
        {
          key: 'secondaryCategory',
          label: 'Category 2 (optional)',
          category: null,
          subCategoryLabel: 'Subcategory 2 (optional)',
          subCategory: null,
        },
        {
          key: 'tertiaryCategory',
          label: 'Category 3 (optional)',
          category: null,
          subCategoryLabel: 'Subcategory 3 (optional)',
          subCategory: null,
        },
      ],
    };
  },

  validations: {
    form: {
      title: { required },
      description: { required },
      author: { required },
      email: { required },
    },
    squareCoverArt: {
      url: { required },
    },
    categorySelects: {
      0: {
        category: { required },
      },
    },
  },

  computed: {
    ...mapGetters({
      station: 'station_name',
    }),
    ...mapGetters(['allSocialMediaConnections', 'stationUrl']),
    ...mapStateShowSetup(['draftShow']),

    isEditing() {
      return this.editingSMC !== null;
    },

    headerText() {
      return this.isEditing ? 'Edit RSS feed' : 'Create a new RSS feed';
    },

    isStepOneComplete() {
      return (
        !this.$v.form.title.$invalid &&
        !this.$v.form.description.$invalid &&
        !this.$v.form.author.$invalid &&
        !this.$v.form.email.$invalid
      );
    },

    isStepTwoComplete() {
      return !this.$v.squareCoverArt.url.$invalid && !this.$v.categorySelects[0].category.$invalid;
    },

    // Submit/Next is disabled
    canContinue() {
      if (this.currentStep === 1 && !this.isStepOneComplete) return false;
      if (this.currentStep === 2 && !this.isStepTwoComplete) return false;
      if (this.isLoading) return false;

      return true;
    },

    currentStep() {
      const _stepQuery = (this.$route.query && this.$route.query.step) || undefined;
      if (_stepQuery) {
        return Number(_stepQuery);
      }
      return undefined;
    },

    imageUrlOrigin() {
      return 'https://post.futurimedia.com';
    },

    episodeOrderOptions() {
      return [
        {
          value: 'serial',
          label: 'Serial (oldest episode listed first)',
        },
        {
          value: 'episodic',
          label: 'Episodic (newest episode listed first)',
        },
      ];
    },

    languageOptions: () => {
      return Object.values(languages).map((lang) => ({
        value: lang['639-1'],
        // done to prevent things like "English, English"
        label: `${lang.name}${lang.nativeName && lang.name !== lang.nativeName ? `, ${lang.nativeName}` : ''}`,
      }));
    },

    explicitOptions() {
      return [
        { value: 'clean', label: 'Clean' },
        { value: 'explicit', label: 'Explicit' },
      ];
    },

    yesNoOptions() {
      return [
        { label: 'No', value: 'no' },
        { label: 'Yes', value: 'yes' },
      ];
    },

    activeOptions() {
      return [
        { label: 'Active', value: 'active' },
        { label: 'Inactive', value: 'inactive' },
      ];
    },

    categoryOptions() {
      return Object.keys(categories).map((category) => ({
        value: category,
        label: category,
      }));
    },

    // Default options
    defaultOptions() {
      return [
        {
          label: 'Publish to this destination by default',
          value: 'post',
        },
      ];
    },
  },

  methods: {
    ...mapActionsSetupShow(['addSocialMediaConnection', 'editSocialMediaConnection']),

    updateCategoryByKey(key, { target: { value } }) {
      const target = this.categorySelects.find((x) => x.key === key);
      target.category = value;
    },

    updateSubCategoryByKey(key, { target: { value } }) {
      const target = this.categorySelects.find((x) => x.key === key);
      target.subCategory = value;
    },

    getSubCategoriesForCategory(category) {
      return categories[category].map((subCategory) => ({
        value: subCategory,
        label: subCategory,
      }));
    },

    processCategorySelections() {
      return this.categorySelects
        .filter(({ category }) => category)
        .map(({ category, subCategory }) => category && `${category}${(subCategory && `:${subCategory}`) || ''}`)
        .join(',');
    },

    unProcessCategorySelections(processedCategories) {
      // The API returns categories as a string in the form of 'cat1:subCat1,cat2:subCat2'
      return processedCategories.split(',').map((category) => {
        const val = category.trim().split(':');
        return {
          primary: val[0] || null,
          sub: val[1] || null,
        };
      });
    },

    getErrorMessageForCategorySelect(index) {
      const target = this.$v.categorySelects[index];
      if (!target || !target.category.$error) return '';
      if (!target.category.required) return 'You must select this category';
    },

    onBackClicked() {
      if (this.currentStep === 2) {
        this.$router.replace({
          query: {
            newConnection: 'rss',
            step: 1,
          },
        });
      }
      if (this.currentStep === 3) {
        this.$router.replace({
          query: {
            newConnection: 'rss',
            step: 2,
          },
        });
      }
    },

    async onSubmit() {
      if (this.currentStep === 3) {
        await this.onSaveClicked();
      }
      if (this.currentStep === 2) {
        // Go step 2
        this.$router.replace({
          query: {
            newConnection: 'rss',
            step: 3,
          },
        });
      }
      if (this.currentStep === 1) {
        // Go step 2
        this.$router.replace({
          query: {
            newConnection: 'rss',
            step: 2,
          },
        });
      }
    },

    async onSaveClicked() {
      this.isLoading = true;
      const _default_on_post = this.defaultValue.includes('post');
      const _categories = this.processCategorySelections();
      const _image = this.squareCoverArt.url;

      // if (_image && !_image.includes(this.imageUrlOrigin)) {
      //   _image = `${this.imageUrlOrigin}${_image}`;
      // }

      const payloadFromForm = {
        default_on_post: _default_on_post,
        itunes_categories: _categories,
        imageurl: _image,
        title: this.form.title,
        subtitle: this.form.subtitle,
        name: this.form.title,
        description: this.form.description,
        show_format: this.form.episodicOrSerial,
        language_override: this.form.language,
        author: this.form.author,
        email: this.form.email,
        podcast_link: this.form.website,
        rss_explicit: this.form.explicit === 'explicit',
        iheart_show_id: this.form.iHeartShowId,
        iHeartMedia: !!this.form.iHeartShowId,
        is_aggregate: this.form.isAggregate === 'yes',
        itunes_auth: this.form.podcastConnectAuth,
        has_live_read: this.form.hasLiveReads === 'yes',
        status: this.form.status === 'active',
      };

      if (!this.isEditing) {
        await this.addSocialMediaConnection({
          type: 'rss',
          ...payloadFromForm,
        });
      } else {
        await this.editSocialMediaConnection({
          id: this.editingSMC.id,
          ...payloadFromForm,
        });
      }
      this.isLoading = false;
      this.$emit('onClose');
    },
  },

  mounted() {
    if (this.isEditing) {
      const {
        title,
        subtitle,
        description,
        show_format,
        language_override,
        author,
        email,
        podcast_link,
        rss_explicit,
        iheart_show_id,
        is_aggregate,
        itunes_auth,
        has_live_read,
        status,
        default_on_post,
        itunes_categories,
      } = this.editingSMC;

      const { imageurl } = this.editingSMC;

      this.form = {
        title,
        subtitle,
        description,
        episodicOrSerial: show_format || 'episodic',
        language: language_override || 'en',
        author,
        email,
        website: podcast_link,
        explicit: rss_explicit ? 'explicit' : 'clean',
        iHeartShowId: iheart_show_id,
        isAggregate: is_aggregate ? 'yes' : 'no',
        podcastConnectAuth: itunes_auth,
        hasLiveReads: has_live_read ? 'yes' : 'no',
        status: status ? 'active' : 'inactive',
      };

      // if (imageurl && !imageurl.includes(this.imageUrlOrigin)) {
      //   imageurl = `${this.imageUrlOrigin}${imageurl}`;
      // }

      if (default_on_post) {
        this.defaultValue.push('post');
      }

      this.squareCoverArt = {
        url: imageurl,
      };

      const categories = this.unProcessCategorySelections(itunes_categories);
      categories.forEach((category, i) => {
        if (i < 3) {
          this.$set(this.categorySelects[i], 'category', category.primary);
          this.$set(this.categorySelects[i], 'subCategory', category.sub);
        }
      });
    } else if (this.$route.path.includes('/shows/edit')) {
      // Pre-populate fields from the current draft show
      const {
        title,
        author,
        email,
        description,
        podcast_link: website,
        square_image,
        show_format: episodicOrSerial,
        rss_explicit,
        itunes_categories,
        language_override: language,
      } = this.draftShow;

      const explicit = rss_explicit === 'true' ? 'explicit' : 'clean';
      const categories = this.unProcessCategorySelections(itunes_categories);
      categories.forEach((category, i) => {
        if (i < 3) {
          this.$set(this.categorySelects[i], 'category', category.primary);
          this.$set(this.categorySelects[i], 'subCategory', category.sub);
        }
      });

      this.form = { ...this.form, title, author, email, description, website, episodicOrSerial, explicit, language };
      this.squareCoverArt.url = square_image;
    }
  },
};
</script>

<style lang="scss" scoped>
// Styles for this component in /src/styles/integrations/integrations.scss
// and imported in /src/views/Integrations.vue
</style>
