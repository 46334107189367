<template>
  <fm-drawer :visible="true">
    <div slot="content" class="widget-config-panel">
      <close-button class="close-button" @click="onClose" />

      <div class="widget-config-panel__heading">
        <fm-text tag="h1"> Get widget embed code </fm-text>
      </div>

      <div class="widget-config-panel__form">
        <fm-radio label="Version" v-model-wc="form.version" :options.prop="versionOptions" />

        <fm-checkbox
          label="Display in iframe"
          v-model-wc="form.useIFrame"
          :options.prop="yesOptionForCheckbox"
          assistiveText="Some website management systems require that all third-party widgets be embedded with an iframe. Check this field if that applies to you."
        />

        <fm-radio
          label="Theme"
          v-model-wc="form.theme"
          :options.prop="themeOptions"
          assistiveText="If your website has a dark theme, choose dark."
        />

        <fm-input label="Total number of episodes to display" v-model-wc="form.limit" />

        <fm-checkbox
          v-if="!isV2"
          label="Open in pop-up player"
          v-model-wc="form.usePopup"
          :options.prop="yesOptionForCheckbox"
          assistiveText="Select this if you want a new player window to pop up when a user clicks play on an episode."
        />

        <fm-radio v-if="isV2" label="Image to display" v-model-wc="form.images" :options.prop="imagesOptions" />

        <fm-checkbox
          v-if="!isV2"
          label="Paginate episode playlist"
          v-model-wc="form.isPaginated"
          :options.prop="yesOptionForCheckbox"
        />

        <div v-if="!isV2 && form.isPaginated.includes('true')" class="pagination-subgroup">
          <i class="material-icons" aria-hidden="true">subdirectory_arrow_right</i>

          <fm-input label="Number of episodes per page" v-model-wc="form.perPage" />
        </div>

        <fm-checkbox
          v-if="!isV2"
          label="Hide tag cloud"
          v-model-wc="form.hideTagCloud"
          :options.prop="yesOptionForCheckbox"
        />
      </div>

      <div class="widget-config-panel__separator">
        <hr />
      </div>

      <div class="widget-config-panel__embed-code">
        <fm-textarea label="Widget embed code" v-model-wc="codeToDisplay" rows="6" ref="textArea" />
      </div>

      <div class="widget-config-panel__actions">
        <fm-button-primary class="action-button" @click="handleCopyEmbedCodeToClipboard" :success="isCopiedToClipboard">
          Copy to clipboard
        </fm-button-primary>
        <fm-button-secondary class="action-button" @click="onClose"> Close </fm-button-secondary>
      </div>
    </div>
  </fm-drawer>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
const { mapGetters } = createNamespacedHelpers('CreateAndPublishStore');
import CloseButton from '@/createandpublish/components/CloseButton';

export default {
  name: 'widget-config-panel',

  props: {
    smc: { type: Object, required: true },
  },

  data() {
    return {
      form: {
        version: 'v1',
        useIFrame: [],
        theme: 'light',
        images: 'episode',
        limit: '7',
        isPaginated: [],
        perPage: '7',
        usePopup: [],
        hideTagCloud: [],
      },
      isCopiedToClipboard: false,
    };
  },

  computed: {
    ...mapGetters(['station']),

    isV2() {
      return this.form.version === 'v2';
    },

    actionAfterPlaylistFinished() {
      return this.form.usePopup.includes('true') ? 'popup' : 'none';
    },

    configString() {
      let options = '';
      // Options that apply to both versions
      // options += `station=${this.station.toLowerCase()}`;
      options += `station=whls`;
      options += `&zone=${this.smc.id}`;
      options += `&theme=${this.form.theme}`;
      options += `&limit=${this.form.limit}`;
      if (!this.isV2) {
        // Classic only options.
        options += `&next=${this.actionAfterPlaylistFinished}`;
        options += this.form.isPaginated.includes('true') ? `&perPage=${this.form.perPage}` : '';
        options += this.form.hideTagCloud.includes('true') ? '&mods=nocategorieslist' : '';
      } else {
        // V2 only options.
        options += '&version=2';
        options += this.form.images === 'episode' ? '&v2_mods=episode_images' : '';
      }
      return options;
    },

    widgetSrc() {
      return '//post.futurimedia.com/futuri-post-widget' + (this.form.useIFrame.includes('true') ? '.html' : '.js');
    },

    iFrameEmbedCode() {
      return `<iframe src="${this.widgetSrc}?${this.configString}" width="700" height="467"></iframe>`;
    },

    standardEmbedCode() {
      return (
        `<div class="futuri-widget" data-config="${this.configString}"></div>\n\n` +
        `<script type="text/javascript" src="${this.widgetSrc}" defer><\\/script>`
      ); // eslint-disable-line no-useless-escape
    },

    codeToDisplay() {
      return this.form.useIFrame.includes('true') ? this.iFrameEmbedCode : this.standardEmbedCode;
    },

    versionOptions() {
      return [
        { label: 'Classic', value: 'v1' },
        { label: 'Compact', value: 'v2' },
      ];
    },
    themeOptions() {
      return [
        { label: 'Light', value: 'light' },
        { label: 'Dark', value: 'dark' },
      ];
    },
    imagesOptions() {
      return [
        { label: 'Episode', value: 'episode' },
        { label: 'Show', value: 'show' },
      ];
    },
    yesOptionForCheckbox() {
      return [{ label: 'Yes', value: 'true' }];
    },
  },

  methods: {
    onClose() {
      this.$emit('close');
    },

    handleCopyEmbedCodeToClipboard() {
      this.$refs.textArea.shadowRoot.querySelector('.fm-textarea__input').select();
      navigator.clipboard.writeText(this.codeToDisplay);
      this.isCopiedToClipboard = true;
    },
  },

  async mounted() {
    await window.customElements.whenDefined('fm-textarea');
    await this.$nextTick();
    const textArea = this.$refs.textArea.shadowRoot.querySelector('.fm-textarea__input');
    textArea.setAttribute('readonly', 'true');
  },

  components: {
    CloseButton,
  },
};
</script>

<style lang="scss" scoped>
.widget-config-panel {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  padding: 28px 24px 48px 24px;
  box-sizing: border-box;
  position: relative;

  .close-button {
    position: absolute;
    top: 16px;
    right: 16px;
  }

  &__heading {
    margin-bottom: 48px;
  }

  &__form {
    .pagination-subgroup {
      display: flex;
      align-items: center;
      margin-left: 24px;
      transform: translateY(-12px);

      i {
        margin-right: 16px;
      }
    }
  }

  &__separator {
    margin-bottom: 36px;

    hr {
      margin: 0;
      height: 2px;
    }
  }

  &__actions {
    display: flex;
    flex-direction: column;
    margin: auto 40px 0 40px;

    .action-button {
      margin-bottom: 24px;
    }

    .action-button::part(button) {
      width: 100%;
    }
  }
}
</style>
