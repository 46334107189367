<template>
  <fm-drawer :visible="true">
    <div slot="content" class="add-connection__wrapper">
      <close-button class="close-button" @click="$emit('onClose')" />
      <div class="add-connection__heading">
        <fm-text tag="h1">{{ tag ? 'Edit' : 'Add' }} Tag</fm-text>
      </div>
      <fm-input v-model-wc="editingTag.name" label="Tag name" @input="updateTagName" :error="errorMessage" />
      <div class="add-connection__actions">
        <fm-button-primary class="next-button" :disabled="isLoading || editingTag.name.trim() === ''" @click="onSubmit"
          >Save changes</fm-button-primary
        >
        <fm-button-secondary class="cancel-button" @click="$emit('onClose')">Cancel</fm-button-secondary>
      </div>
    </div>
  </fm-drawer>
</template>
<script>
import { createNamespacedHelpers } from 'vuex';
const { mapActions } = createNamespacedHelpers('CreateAndPublishStore');
import CloseButton from '@/createandpublish/components/CloseButton';

export default {
  components: {
    CloseButton,
  },
  props: {
    tag: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      editingTag: {
        name: '',
      },
      isLoading: false,
      errorMessage: '',
    };
  },
  methods: {
    ...mapActions(['createPlaylistCategory', 'updatePlaylistCategory']),
    updateTagName(e) {
      this.editingTag.name = e.target.value;
    },
    async onSubmit() {
      if (this.editingTag.name.length > 50) {
        this.errorMessage = 'Tag names must be less than 50 characters long';
        return;
      }

      this.isLoading = true;

      if (this.tag) {
        await this.updatePlaylistCategory(this.editingTag);
      } else {
        await this.createPlaylistCategory(this.editingTag);
      }

      this.isLoading = false;
      this.$emit('onClose');
    },
  },
  mounted() {
    if (this.tag) {
      this.editingTag = { ...this.tag };
    }
  },
};
</script>

<style lang="scss" scoped>
.add-connection {
  &__actions {
    margin: auto 0 0;
  }
}
</style>
