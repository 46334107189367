import { ISO8601DateTime } from '../Common';
import { ProductHandle } from '../ecomm';

export type AccountData = {
  activeBrands: number;
  activeCampaigns: number;
  maxBrands: number;
  allProducts: AvailableProduct[];
  products: Product[];
  // subscription data
  currentPeriodEndsAt: ISO8601DateTime | null;
  expiresAt: ISO8601DateTime | null;
  intervalUnit: BillingInterval | null;
  nextAssessmentAt: ISO8601DateTime | null;
  productPriceInCents: number | null;
  subscriptionStatus: SubscriptionStatus;
  subscription: Subscription;
  trialEndedAt: ISO8601DateTime | null;
  trialIntervalUnit: BillingInterval | null;
  trialStartedAt: ISO8601DateTime | null;
};

export interface Subscription {
  createdBy: number | null;
  currentPeriodEndsAt: ISO8601DateTime | null;
  defaultPaymentProfileId: number | null;
  expiresAt: ISO8601DateTime | null;
  intervalUnit: BillingInterval | null;
  nextAssessmentAt: ISO8601DateTime | null;
  productPriceInCents: string | null;
  state: SubscriptionStatus;
  subscriptionId: number;
  trialEndedAt: ISO8601DateTime | null;
  trialIntervalUnit: BillingInterval | null;
  trialStartedAt: ISO8601DateTime | null;
  productHandle: ProductHandle;
}

export type Payment = {
  amount: number;
  cardNumberLastFour: number;
  cardType: string | 'VISA';
  date: string;
  id: number;
};

export type Product = {
  active: boolean;
  currentSeats: number;
  description: string;
  id: number;
  maxSeats: number;
  name: string;
};

export interface AvailableProduct extends Omit<Product, 'currentSeats' | 'maxSeats' | 'active'> {
  paymentProcessorHandle: string;
}

// A status that matches Chargify Subscription states
export type SubscriptionStatus =
  | 'active'
  | 'canceled'
  | 'expired'
  | 'on_hold'
  | 'past_due'
  | 'soft_failure'
  | 'suspended'
  | 'trial_ended'
  | 'trialing'
  | 'unpaid';

export type BillingInterval = 'day' | 'month' | 'year';

export interface ProductSeats {
  available: number;
  filled: number;
  max: number;
}

export enum ProductIds {
  DISCOVER = 1,
  CREATE_AND_PUBLISH = 2,
  AUDIENCE = 3,
}
