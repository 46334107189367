import HttpClient from '@/services/common/HttpClient';
import DiscoverStorySearchModel from '@/models/DiscoverStorySearchModel';
import DiscoverStoryByIdModel from '@/models/DiscoverStoryByIdModel';
import DiscoverCategoriesModel from '@/models/DiscoverCategoriesModel';
import DiscoverTagStoriesModel from '@/models/DiscoverTagStoriesModel';

export default class DiscoverStoryService {
  private http: HttpClient = HttpClient.getInstance();
  private endpoint = `${process.env.VUE_APP_API_URL}/discover`;

  /*
   * Search Stories
   */
  public getStories(category: number | string, brandId: number | string) {
    return this.http.get<DiscoverStorySearchModel[] | { errors: string[] }>(
      `${this.endpoint}/stories?shortcut_id=${category}&brandId=${brandId}`
    );
  }

  public async getOne(storyId: number, brandId: number): Promise<DiscoverStoryByIdModel> {
    return this.http.get<DiscoverStoryByIdModel>(`${this.endpoint}/stories/${storyId}?brandId=${brandId}`);
  }

  public async getSearch(searchTerms: string, category: number, brandId: number): Promise<DiscoverStorySearchModel> {
    return this.http.get<DiscoverStorySearchModel>(
      `${this.endpoint}/story_search?terms=${searchTerms}&shortcut_id=${category}&brandId=${brandId}`
    );
  }

  public async getCategories(): Promise<DiscoverCategoriesModel> {
    return this.http.get<DiscoverCategoriesModel>(`${this.endpoint}/categories`);
  }

  public async tagStoriesToCampaign(
    brandId: number,
    campaign: number,
    storyId: number
  ): Promise<DiscoverTagStoriesModel> {
    return this.http.post<DiscoverTagStoriesModel>(
      `${process.env.VUE_APP_API_URL}/brands/${brandId}/campaigns/${campaign}/story/${storyId}`,
      ''
    );
  }

  public async untagCampaignFromStory(
    brandId: number,
    campaign: number,
    storyId: number
  ): Promise<DiscoverTagStoriesModel> {
    return this.http.delete<DiscoverTagStoriesModel>(
      `${process.env.VUE_APP_API_URL}/brands/${brandId}/campaigns/${campaign}/story/${storyId}`
    );
  }
}
