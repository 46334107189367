
















import { Component, Vue } from 'vue-property-decorator';

@Component({
  name: 'ImageCardLayout',
})
export default class ImageCardLayout extends Vue {}
