<template>
  <Drawer
    :title="isCreateMode ? 'Add feed' : 'Edit feed'"
    :qa="$getComponent('AddEditRSSFeed', 'Drawer', 'add-edit-feed')"
    id="discover-rss-feed-drawer"
    @cancel="onCancel"
    :submit="submit"
    :loading="isLoading"
    :vh100="true"
    hideEventFunction="triggerReset"
    @triggerReset="reset"
  >
    <div slot="content">
      <div class="content">
        <AlertDisplay
          id="rss-feeds-alert"
          :alertMessage="alertMessage"
          class="alertMessage"
          v-if="displayAlert"
          :drawer="true"
          :data-qa="$getComponent('AddEditRSSFeeds', 'Component', 'alertDisplay')"
        />
        <div class="component">
          <Input
            id="rss-feed-name-input"
            label="Feed name"
            name="feedName"
            :inputHandler="handleInputChange"
            :value="currentFeed.feedName"
            maxLength="100"
            :data-qa="$getComponent('AddEditRSSFeed', 'TextInput', 'feedName')"
            :error="
              $v.currentFeed.feedName.$error
                ? $v.currentFeed.feedName.required
                  ? errorMessages.ano
                  : 'Feed name' + errorMessages.required
                : ''
            "
          />
          <Input
            id="rss-url-input"
            label="RSS URL"
            name="feedUrl"
            :inputHandler="handleInputChange"
            :value="currentFeed.feedUrl"
            maxLength="100"
            :data-qa="$getComponent('AddEditUser', 'TextInput', 'feedUrl')"
            :error="
              $v.currentFeed.feedUrl.$error
                ? $v.currentFeed.feedUrl.required
                  ? errorMessages.vurl
                  : 'Feed Url' + errorMessages.required
                : ''
            "
          />
        </div>
      </div>
    </div>
  </Drawer>
</template>

<script>
import Vue from 'vue';
import { mapGetters } from 'vuex';
import { alphaNumericOnly as $ano, validUrl as $vurl } from '@/plugins/Validations';
import { required } from 'vuelidate/lib/validators';
import Drawer from '@/components/common/drawer/Drawer.vue';
import DiscoverSettingsService from '@/services/DiscoverSettingsService';
import Input from '@/components/common/form/Input.vue';
import errorMessages from '@/utils/errorMessages.json';
import AlertDisplay from '@/components/common/AlertDisplay.vue';
export default Vue.extend({
  name: 'add-edit-rss-feed',

  components: {
    Drawer,
    Input,
    AlertDisplay,
  },
  data() {
    return {
      isLoading: false,
      currentFeed: {
        id: this.feedData.id || null,
        feedName: this.feedData.feedName,
        feedUrl: this.feedData.feedUrl,
        brandId: this.feedData.brandId,
        active: this.feedData.active,
      },
      // Inline errors
      alertMessage: {},
      // alert block
      errorMessages: errorMessages,
    };
  },
  validations: {
    currentFeed: {
      id: Number,
      feedName: {
        required,
        $ano,
      },
      feedUrl: {
        required,
        $vurl,
      },
    },
  },

  computed: {
    isCreateMode() {
      return !this.feedData.id;
    },

    submit() {
      return {
        qa: this.$getComponent('AddEditRSSFeed', 'Button', 'saveFeed'),
        buttonText: 'Save Feed',
        onClick: (hide) => this.savefeedData(hide),
      };
    },
    form() {
      return {
        feedData: this.feedData,
      };
    },

    displayAlert() {
      return !!this.alertMessage.type;
    },

    ...mapGetters(['selectedBrand']),

    brandId() {
      return this.selectedBrand.id;
    },
  },

  methods: {
    ...mapGetters(['fetchBrands']),

    onCancel(hide) {
      if (this.isCreateMode) {
        this.currentFeed.feedName = '';
        this.currentFeed.feedUrl = '';
      }
      this.alertMessage = {};
      this.$emit('cancel');
      hide();
    },

    handleInputChange(e) {
      this.currentFeed[e.target.name] = e.target.value;
    },

    reset() {
      this.$emit('reset');
    },

    async savefeedData(hide) {
      this.alertMessage = {};
      this.isLoading = true;
      this.currentFeed.feedName = this.currentFeed.feedName ? this.currentFeed.feedName.trim() : '';
      this.currentFeed.feedUrl = this.currentFeed.feedUrl ? this.currentFeed.feedUrl : '';
      this.$v.currentFeed.feedName.$touch();
      this.$v.currentFeed.feedUrl.$touch();

      if (this.$v.currentFeed.feedName.$invalid || this.$v.currentFeed.feedUrl.$invalid) {
        this.isLoading = false;
      } else {
        let response = {};

        if (this.isCreateMode) {
          this.currentFeed.active = true;
          response = await new DiscoverSettingsService()
            .createFeed(this.brandId, this.currentFeed)
            .then((res) => res)
            .catch((err) => err.response);
          // this.$emit('refreshData');
        } else {
          response = await new DiscoverSettingsService()
            .editFeed(this.brandId, this.currentFeed)
            .then((res) => res)
            .catch((err) => err.response);
          // this.$emit('refreshData');
        }

        this.$store.dispatch('fetchBrands');

        // Temp, local dev only
        if (process.env.NODE_ENV === 'development') {
          console.log('Payload sent to BE', JSON.parse(JSON.stringify(this.currentFeed)));
          console.log('Respones from BE', response);
        }

        if (!response.id) {
          const errors = response.errors;

          this.alertMessage = {
            type: 'critical',
            header: "We couldn't save your changes.",
            message: 'Please correct the errors below and submit the form again.',
            links: errors.map((err) => ({ message: Vue.prototype.$errorPrettified(err) })),
          };

          setTimeout(() => {
            this.isLoading = false;
          }, 500);
          return;
        } else {
          this.isLoading = false;
          this.$emit('refreshData');
          this.onCancel(hide);
          setTimeout(() => {
            this.$emit('reset');
          }, 500);
        }
      }
    },
  },
  props: {
    feedData: {
      type: Object,
      default: () => {
        return {
          feedName: '',
          id: null,
          feedUrl: '',
          active: true,
        };
      },
    },
    rssFeeds: Array,
  },
  watch: {
    feedData: function (newFeedData) {
      // this.$v.currentFeed.$reset();
      this.currentFeed = JSON.parse(JSON.stringify(newFeedData));
      if (!this.currentFeed.brandId) {
        this.brandId;
      }
    },
  },
});
</script>

<style lang="scss" scoped>
.component {
  margin-bottom: 32px;

  .form-input:first-child {
    margin-bottom: 32px;
  }
}

.alertMessage {
  margin-bottom: 32px;
}

.topmargin {
  margin-top: 8px;
}
</style>
