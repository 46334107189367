<template>
  <div
    :class="parentClasses"
    :tabindex="tabIndex"
    @keydown.space.enter.prevent="keyboardEventHandler"
    :data-focus="$attrs['data-focus'] || ''"
  >
    <input
      class="base-checkbox__input"
      type="checkbox"
      tabindex="-1"
      :checked="shouldBeChecked"
      v-bind="$attrs"
      :value="inputValue"
      @change="changeEventHandler"
      @input="$emit('onInputValueCheckedChange', $event)"
    />
    <span class="material-icons base-checkbox__check" aria-hidden="true"> check </span>
    <slot name="label">
      <label class="base-checkbox__label" :for="$attrs.id || ''">
        {{ label }}
      </label>
    </slot>
  </div>
</template>

<script>
/* NOTE: The purpose of this component is to mimic the fm-checkbox
component from the futuri-ui-kit package. Please ONLY use this component if
a design implements a checkbox input that does not comply with the designed
usage of fm-checkbox. The fm-checkbox component should always be preferred
over this one. This is only intended to save a developer from having to do
gymnastics with fm-checkbox. */

export default {
  name: 'base-checkbox',
  inheritAttrs: false,
  // Attributes set on this component will not be applied to it's
  // first child element. They will instead be set on the input element.
  props: {
    value: {
      // used only to make v-model works with a group of this component
      type: Array,
      required: true,
    },
    inputValue: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: false,
      default: '',
    },
    hasError: {
      type: Boolean,
      required: false,
      default: false,
    },
    tabIndex: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  computed: {
    shouldBeChecked() {
      return this.value.includes(this.inputValue);
    },
    isDisabled() {
      return this.$attrs.disabled || false;
    },
    parentClasses() {
      return [
        'base-checkbox',
        {
          disabled: this.isDisabled,
          error: this.hasError,
        },
      ];
    },
  },
  methods: {
    keyboardEventHandler(e) {
      const target = e.target;
      const input = target.firstElementChild;
      if (input instanceof HTMLInputElement && input.value) {
        input.click(); // causes this.inputEventHandler to be called
      }
    },
    changeEventHandler(e) {
      e.preventDefault();
      const value = e.target.value;
      const isChecked = e.target.checked;
      const valueToEmit = [...this.value];
      if (isChecked) {
        valueToEmit.push(value);
      } else {
        valueToEmit.splice(valueToEmit.indexOf(value), 1);
      }
      this.$emit('input', valueToEmit);
    },
  },
};
</script>

<style lang="scss" scoped>
.base-checkbox {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 6px 0;
  box-sizing: border-box;
  position: relative;

  &:focus {
    outline: 4px solid #00c6c680;
  }

  &:focus:not(.focus-visible) {
    outline: none;
  }

  &:focus-visible {
    outline: 4px solid #00c6c680;
  }

  &__input {
    appearance: none;
    display: inline-block;
    width: 24px;
    height: 24px;
    flex: 0 0 auto;
    box-sizing: border-box;
    background: var(--neutral-white) 0% 0% no-repeat padding-box;
    border: 2px solid var(--neutral-grey-1-text);
    border-radius: 4px;
    margin: 0 8px 0 0;
    outline: none;

    &:checked {
      + .base-checkbox__check {
        transform: translate(0, -50%) scale(1);
        transition: all 250ms cubic-bezier(0.25, 0.25, 0.56, 2);
      }
    }
  }

  &__check {
    position: absolute;
    top: 50%;
    left: 0;
    border-radius: 4px;
    color: var(--neutral-white);
    background-color: var(--brand-dark);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25px;
    height: 25px;
    transform: translate(0, -50%) scale(0);
    transform-origin: center;
    transition: all 250ms ease-in;
    pointer-events: none;
  }

  &__label {
    display: inline-block;
    font: var(--fm-font-style-normal) normal var(--fm-font-weight-normal) var(--fm-font-size-16) /
      var(--fm-line-spacing-24) var(--fm-font-family-roboto);
    letter-spacing: var(--fm-character-spacing-0);
    color: var(--neutral-grey-1-text);
    text-align: left;
    cursor: default !important;
  }

  &.disabled {
    .base-checkbox__input {
      border: 2px solid var(--neutral-grey-4-light-bg);
      opacity: 0.5;
    }

    .base-checkbox__label {
      color: var(--neutral-grey-1-text);
      opacity: 0.5;
    }
  }

  &.error {
    .base-checkbox__input {
      border: 2px solid var(--notification--error);
    }
  }
}
</style>
