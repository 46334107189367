<template>
  <div class="progress">
    <div
      class="progress-bar progress-bar-info"
      :style="`width: ${progress}%`"
      :aria-valuenow="progress"
      aria-valuemin="0"
      aria-valuemax="100"
    >
      <span>{{ progress }}%</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EventUploadProgressBar',

  props: {
    uploadProgress: {
      type: Number,
      default: 0,
    },
    encodeProgress: {
      type: Number,
      default: 0,
    },
  },

  computed: {
    progress() {
      const uploadProgress = this.encodeProgress ? 100 : this.uploadProgress;
      return Math.floor((uploadProgress + this.encodeProgress) / 2);
    },
  },
};
</script>
