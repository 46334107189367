

























import { Component, Vue } from 'vue-property-decorator';
import CommunityIcon from '@/audience/components/nav-icons/Community.vue';

@Component({
  name: 'CreatorCommunity',
  components: {
    CommunityIcon,
  },
})
export default class CreatorCommunity extends Vue {}
