<template>
  <div class="widget-config-form" ref="configPanel">
    <div v-if="variant === 'side-panel'" class="widget-config-panel__heading">
      <fm-text tag="h1"> Share &amp; Embed </fm-text>
    </div>
    <div class="widget-config-panel__form">
      <fm-radio
        label="Widget Type"
        v-model-wc="form.widgetType"
        :options.prop="widgetTypeOptions"
        :displayLtr="true"
        @input="$emit('reset-preview-width')"
      />

      <fm-radio label="Mode" v-model-wc="form.theme" :options.prop="themeOptions" :displayLtr="true" />

      <ColorPickers v-show="form.theme === 'custom'" :colors="form.colors" @update="form.colors = $event" />

      <fm-radio label="Auto Play" v-model-wc="form.autoPlay" :options.prop="computedAutoPlayOptions" />
    </div>

    <div class="widget-config-panel__form">
      <fm-checkbox
        label="Preferences"
        :options.prop="preferencesOptions"
        v-model-wc="form.preferences"
        class="flex-row"
      />
    </div>

    <div
      v-show="form.preferences.includes('gallery') && form.widgetType === 'classic'"
      class="widget-config-panel__form"
    >
      <fm-input
        label="How many episodes should we include?"
        v-model-wc="form.galleryLimitProxy"
        @blur="setDefaultGalleryLimit()"
      ></fm-input>
    </div>

    <div class="widget-config-panel__separator">
      <hr />
    </div>

    <fm-radio
      label="Display in iframe"
      v-model-wc="form.displayInIframe"
      :options.prop="iFrameOptions"
      assistiveText="Some website management systems require that all third-party widgets be embedded with an iframe. Check this field if that applies to you."
      :displayLtr="true"
    />

    <div class="widget-config-panel__embed-code">
      <fm-textarea label="Widget embed code" v-model-wc="codeToDisplay" rows="6" ref="textArea" />
    </div>

    <div class="widget-config-panel__actions">
      <fm-button-primary class="action-button" @click="handleCopyEmbedCodeToClipboard" :success="isCopiedToClipboard">
        Copy to clipboard
      </fm-button-primary>

      <fm-button-secondary class="action-button" @click="onClose"> Close </fm-button-secondary>

      <div class="instructions">
        <fm-text tag="h2" as="h4">How to use</fm-text>
        <ol class="instructions__list">
          <li v-for="(instruction, index) in computedInstructions" :key="index" class="instructions__list-item">
            {{ instruction.text }}
            <template v-if="instruction.note">
              <fm-text tag="h3" as="h5">NOTE:</fm-text>
              {{ instruction.note }}
            </template>
          </li>
        </ol>
      </div>
    </div>
  </div>
</template>
<script>
import { createNamespacedHelpers } from 'vuex';
const { mapGetters } = createNamespacedHelpers('CreateAndPublishStore');
import ColorPickers from '@/createandpublish/components/Forms/ColorPickers.vue';

export default {
  name: 'widget-config-form',

  props: {
    smc: { type: Object, required: true },
    variant: {
      type: String,
      default: 'side-panel',
    },
    episodeId: { type: Number, required: false },
  },

  data() {
    return {
      form: {
        version: '3',
        widgetType: 'classic',
        colors: {
          background: { hex: '#222046' },
          text: { hex: '#ffffff' },
          accent: { hex: '#532aa2' },
        },
        autoPlay: 'next',
        theme: 'light',
        limit: '7',
        isPaginated: [],
        preferences: [],
        galleryLimit: 7,
        galleryLimitProxy: 7, // proxy to prevent rerender of widget when the gallery limit is updated
        displayInIframe: 'withoutIframe',
      },
      drawer: {
        width: 748,
        miniMinWidth: 368,
        classicMinWidth: 0,
      },
      isCopiedToClipboard: false,
      widgetTypeOptions: [
        { label: 'Mini', value: 'mini' },
        { label: 'Classic', value: 'classic' },
      ],
      autoplayOptions: [
        { label: 'Next Episode', value: 'next' },
        { label: 'Live Stream', value: 'live' },
        { label: 'None', value: 'none' },
      ],
      iFrameOptions: [
        { label: 'No', value: 'withoutIframe' },
        { label: 'Yes', value: 'withIframe' },
      ],
      instructionsForScript: [
        {
          text: `Place the element <div ...><\/div> where you want the widget to appear.`, //eslint-disable-line
        },
        {
          text: `Place the element <script ...><\/script> at the end of the body of your page.`, //eslint-disable-line
          note: `If you would like to place multiple widgets on a single page, you only need to put the
            <script ...><\/script> element on the page once.`, //eslint-disable-line
        },
        {
          text: `To reconfigure the widget, return here, make your changes, and replace the old code on your website
            with the newly generated code.`,
        },
      ],
      instructionsForIFrame: [
        {
          text: `Place the element <iframe ...><\/iframe> where you want the widget to appear.`, //eslint-disable-line
        },
        {
          text: `To reconfigure the widget, return here, make your changes, and replace the old code on your website
            with the newly generated code.`,
        },
      ],
      isResizing: false,
    };
  },

  computed: {
    ...mapGetters(['station']),

    isV2() {
      return this.form.version === 'v2';
    },

    actionAfterPlaylistFinished() {
      return 'none';
    },

    configString() {
      let options = '';
      // Options that apply to both versions
      options += `version=${this.form.version}`;
      options += `&station=${this.station.toLowerCase()}`;
      options += `&mode=${this.form.widgetType}`;
      options += `&zone=${this.smc.id}`;
      options += `&theme=${this.form.theme}`;

      // Custom theme colors
      if (this.form.theme === 'custom') {
        options += `&background=${this.form.colors.background.hex.replace('#', '')}`;
        options += `&text=${this.form.colors.text.hex.replace('#', '')}`;
        options += `&accent=${this.form.colors.accent.hex.replace('#', '')}`;
      }

      // Preferences
      options += this.form.preferences.includes('albumart') ? `&albumart=true` : '';
      options += this.form.preferences.includes('visualization') ? `&visualization=true` : '';

      // Classic specific preferences
      if (this.form.widgetType === 'classic') {
        options += this.form.preferences.includes('hashtags') ? `&hashtags=true` : '';
        options += this.form.preferences.includes('shownotes') ? `&shownotes=true` : '';
        if (this.form.preferences.includes('gallery')) {
          options += `&gallery=true&limit=${this.form.galleryLimit}`;
        }
      }

      options += `&autoplay=${this.form.autoPlay}`;

      if (this.isV2) {
        // V2 only options.
        options += '&version=2';
      }

      if (this.forSingleTrack) {
        options += `&id=${this.episodeId}&limit=1`;
      }
      this.$emit('on-embed-code-update', options);
      return options;
    },

    widgetSrc() {
      return '//static.ccdev.futurimedia.com/static/futuri-post-widget.js';
    },

    computedIFrameDimensions() {
      const getComputedHeight = () => {
        let height = 0;
        // classic
        if (this.form.widgetType === 'classic') {
          height = 181;
          if (this.form.preferences.includes('gallery')) {
            height += 339;
          }
          if (this.form.preferences.includes('albumart')) {
            height += 210;
          } else if (!this.form.preferences.includes('albumart')) {
            if (this.form.preferences.includes('visualization')) {
              height += 15;
            }
            if (this.form.preferences.includes('hashtags')) {
              height += 72;
            }
            if (this.form.preferences.includes('shownotes')) {
              height += 74;
            }
          }
        }

        // mini
        if (this.form.widgetType === 'mini') {
          height = 91;
        }
        return height;
      };

      const getComputedWidth = () => {
        const { widgetType } = this.form;
        let width = 700;
        if (widgetType === 'mini') {
          width = 571;
        }
        if (widgetType === 'classic') {
          width = 700;
        }
        return width;
      };

      return `width="${getComputedWidth()}" height="${getComputedHeight()}"`;
    },

    iFrameEmbedCode() {
      return `<iframe allow="clipboard-write self *" src="//static.ccdev.futurimedia.com/static/futuri-post-widget.html?${this.configString}" ${this.computedIFrameDimensions}></iframe>`;
    },

    standardEmbedCode() {
      return (
        `<div class="futuri-widget" data-config="${this.configString}"></div>\n\n` +
        `<script type="text/javascript" src="${this.widgetSrc}" defer><\/script>` //eslint-disable-line
      ); // eslint-disable-line no-useless-escape
    },

    codeToDisplay() {
      return this.form.displayInIframe === 'withIframe' ? this.iFrameEmbedCode : this.standardEmbedCode;
    },

    versionOptions() {
      return [
        { label: 'Classic', value: 'v1' },
        { label: 'Compact', value: 'v2' },
      ];
    },

    themeOptions() {
      return [
        { label: 'Light', value: 'light' },
        { label: 'Dark', value: 'dark' },
        { label: 'Custom', value: 'custom' },
      ];
    },

    yesOptionForCheckbox() {
      return [{ label: 'Yes', value: 'true' }];
    },

    preferencesOptions() {
      const options = [
        { label: 'Album Art', value: 'albumart' },
        { label: 'Visualization', value: 'visualization' },
      ];

      if (this.form.widgetType === 'classic') {
        options.push(
          ...[
            { label: 'Hashtags', value: 'hashtags' },
            { label: 'Show Notes', value: 'shownotes' },
          ]
        );
      }

      if (!this.forSingleTrack && this.form.widgetType === 'classic') {
        options.push(...[{ label: 'Gallery', value: 'gallery' }]);
      }

      return options;
    },

    forSingleTrack() {
      if (this.variant === 'side-panel') {
        return false;
      }
      return true;
    },

    computedAutoPlayOptions() {
      if (this.forSingleTrack) {
        return [
          { label: 'Live Stream', value: 'live' },
          { label: 'None', value: 'none' },
        ];
      }

      return [
        { label: 'Next Episode', value: 'next' },
        { label: 'Live Stream', value: 'live' },
        { label: 'None', value: 'none' },
      ];
    },
    computedInstructions() {
      if (this.form.displayInIframe === 'withoutIframe') {
        return this.instructionsForScript;
      } else {
        return this.instructionsForIFrame;
      }
    },
  },

  methods: {
    onClose() {
      this.$emit('close');
    },

    handleCopyEmbedCodeToClipboard() {
      this.$refs.textArea.shadowRoot.querySelector('.fm-textarea__input').select();
      navigator.clipboard.writeText(this.codeToDisplay);
      this.isCopiedToClipboard = true;
    },

    setDefaultGalleryLimit() {
      if (this.form.galleryLimitProxy === '0' || this.form.galleryLimitProxy === '') {
        this.form.galleryLimitProxy = '1';
      }
    },
  },

  watch: {
    // configString(val) {
    //   this.$emit('refresh-widget');
    // },
    // /**
    //  * hard prevents non-numeric and 0 values
    //  * from being used
    //  */
    // 'form.galleryLimitProxy'(val) {
    //   this.form.galleryLimitProxy = val.replace(/\D/g, '');
    //   if (this.form.galleryLimitProxy !== this.form.galleryLimit) {
    //     this.form.galleryLimit = this.form.galleryLimitProxy;
    //   }
    // },
    // 'form.widgetType'() {
    //   if (!this.form.galleryLimit || this.form.galleryLimit.length === 0) {
    //     this.form.galleryLimitProxy = 7;
    //     this.form.galleryLimit = 7;
    //   }
    // },
    // 'form.displayInIframe'(_value) {
    //   this.$emit('on-display-in-iframe-change', _value);
    // },
  },

  async mounted() {
    // await window.customElements.whenDefined('fm-textarea');
    // await this.$nextTick();
    // const textArea = this.$refs.textArea.shadowRoot.querySelector('.fm-textarea__input');
    // textArea.setAttribute('readonly', 'true');
  },

  components: {
    ColorPickers,
  },
};
</script>

<style lang="scss" scoped>
.widget-config-panel-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: auto;
}

.widget-config-panel {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  min-height: 100%;
  width: 100%;
  padding: 28px 24px 48px 24px;
  // background-color: #FCFCFC;

  .close-button {
    position: fixed;
    top: 16px;
    right: 16px;
  }

  &__heading {
    margin-bottom: 48px;
  }

  &__form {
    .pagination-subgroup {
      display: flex;
      align-items: center;
      margin-left: 24px;
      transform: translateY(-12px);

      i {
        margin-right: 16px;
      }
    }
  }

  &__separator {
    margin-bottom: 36px;

    hr {
      margin: 0;
      height: 2px;
    }
  }

  &__actions {
    display: flex;
    flex-direction: column;

    .action-button {
      margin-bottom: 24px;
    }

    .action-button::part(button) {
      width: 100%;
    }
  }
}

.instructions {
  font-family: var(--fm-font-family-roboto);
  margin-bottom: 1rem;
  &__list {
    padding-top: 1rem;
  }
  &__list-item {
    margin-bottom: 0.5rem;
  }
}

.iframe-wrapper {
  &--no-events {
    pointer-events: none;
  }
}
</style>
