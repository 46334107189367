




















































import { Component, Vue, Watch } from 'vue-property-decorator';
import { Getter, namespace } from 'vuex-class';

import AlertDisplay from '@/components/common/AlertDisplay.vue';
import Breadcrumb from '../components/common/navigation/breadcrumb/Breadcrumb.vue';
import ViewTitle from '../components/common/view/viewTitle/ViewTitle.vue';
import Tabs from '@/components/common/tabs/Tabs.vue';
import BillingAndPaymentTab from '@/components/subscriptions/BillingAndPaymentTab.vue';
import SubscriptionsTab from '@/components/subscriptions/SubscriptionsTab.vue';
import Loading from '@/components/common/Loading.vue';

import { isCreditCardExpired } from '@/utils/ecomm/chargify';

import { PageData } from '@/store/modules/EcommStore';
import type { SubscriptionStatus, Subscription } from '@/types/account/AccountData';
import { Invoice, PaymentProfile, PlansData, ProductHandle } from '@/types/ecomm';
import type { AlertDisplay as AlertDisplayData, AlertMessage } from '@/types/AlertMessage';

const EcommStore = namespace('EcommStore');

@Component({
  name: 'Subscriptions',
  components: {
    AlertDisplay,
    Breadcrumb,
    ViewTitle,
    Tabs,
    BillingAndPaymentTab,
    SubscriptionsTab,
    Loading,
  },
})
export default class Subscriptions extends Vue {
  @Getter subscriptionStatus!: SubscriptionStatus;
  @Getter('rawSubscription') subscription!: Subscription;
  @EcommStore.Action getPlans!: () => Promise<void>;
  @EcommStore.Getter plans?: PlansData;
  @EcommStore.Action getInvoices!: (pageData: PageData) => Promise<void>;
  @EcommStore.Getter invoices!: Invoice[];
  @EcommStore.Action getPaymentProfiles!: () => Promise<void>;
  @EcommStore.Getter paymentProfiles!: PaymentProfile[];

  isLoading = false;

  currentAlert: AlertDisplayData | null = null;

  newCardAlert: AlertDisplayData = {
    type: 'success',
    header: 'Update payment info',
    message: 'Your credit card has been successfully added.',
  };

  breadcrumbItems = [
    { text: 'Account Overview', to: { name: 'Dashboard' } },
    { text: 'Subscription & billing', active: true },
  ];
  activeTab = 0;

  get isSubscriptionLocked() {
    return !['active', 'trialing'].includes(this.subscription.state);
  }

  get isFullSuiteTrialExpired() {
    const { productHandle } = this.subscription;
    if (productHandle === ProductHandle.FULL_SUITE_FREE_TRIAL && this.isSubscriptionLocked) {
      return true;
    }
    return false;
  }

  get isDefaultCardExpired() {
    const defaultPaymentProfileId = this.subscription.defaultPaymentProfileId;
    if (defaultPaymentProfileId && isNaN(defaultPaymentProfileId)) return false;

    const defaultPaymentProfile = this.paymentProfiles.find(({ id }) => id === defaultPaymentProfileId);
    if (!defaultPaymentProfile) return false;

    return isCreditCardExpired(defaultPaymentProfile);
  }

  get accountLockedAlert(): AlertDisplayData {
    const { isDefaultCardExpired, isFullSuiteTrialExpired } = this;
    let message = '';
    const links: AlertMessage[] = [];
    if (isFullSuiteTrialExpired) {
      message =
        'Your Full Suite - Free Trial has expired. Please review and update your subscription to restore access.';
      links.push({
        message: 'Update your subscription',
        route: '/manage-subscriptions',
      });
    } else if (isDefaultCardExpired) {
      message = 'The default credit card for your subscription has expired. Please update your payment info.';
      links.push({
        message: 'Update credit card',
        route: '/subscriptions',
        eventName: 'showBillingAndPaymentTab',
      });
    } else {
      message = 'Your subscription is not active. Please review and update your subscription to restore access.';
      links.push({
        message: 'Update your subscription',
        route: '/manage-subscriptions',
      });
    }

    return {
      type: 'warning',
      header: 'Your subscription is on hold',
      message,
      links,
    };
  }

  @Watch('isSubscriptionLocked', { immediate: true })
  onIsLockedChange(newVal) {
    if (newVal) {
      this.currentAlert = this.accountLockedAlert;
    } else {
      this.currentAlert = null;
    }
  }

  changeTabTo(tabIndex: number) {
    this.activeTab = tabIndex;
  }

  onAlertUpdate(alert: AlertDisplayData) {
    this.currentAlert = alert;
  }

  async getData() {
    const promises: Promise<unknown>[] = [];
    if (!this.plans) {
      promises.push(this.getPlans());
    }
    if (!this.paymentProfiles.length) {
      promises.push(this.getPaymentProfiles());
    }
    if (!this.invoices.length) {
      const pageData = { page: 1, size: 10, sortBy: 'issue_date', order: '-' } as PageData;
      promises.push(this.getInvoices(pageData));
    }
    if (promises.length) {
      this.isLoading = true;
      try {
        await Promise.all(promises);
      } catch (e) {
        console.error(e);
      } finally {
        this.isLoading = false;
      }
    }
  }

  onContactUsClick() {
    window.fcWidget?.open();
  }

  mounted() {
    const { query } = this.$route;
    if (query.tab === 'billing') {
      this.changeTabTo(1);
    }
    if (query.showNewCardAlert === '1') {
      this.currentAlert = this.newCardAlert;
    }
    this.getData();
  }
}
