
































































import { Component, Vue } from 'vue-property-decorator';
import { Getter, namespace } from 'vuex-class';
import AlertDisplay from '@/components/common/AlertDisplay.vue';

import type { AlertDisplay as AlertDisplayData } from '@/types/AlertMessage';
import type { Brand } from '@/types/Brand';
import type { ActiveSocialAccount } from '@/types/audience';

const AudienceStore = namespace('AudienceAnalyticsStore');

@Component({
  components: {
    AlertDisplay,
  },
})
export default class CreateSection extends Vue {
  @Getter selectedBrand!: Brand;
  @AudienceStore.Getter activeSocialAccounts!: ActiveSocialAccount[];

  get showConnectAlert() {
    return this.activeSocialAccounts.length === 0;
  }

  connectAlertMessage: AlertDisplayData = {
    type: 'info',
    header: 'Connect your social accounts',
    message: "You'll need to connect your social accounts & profiles before you can share content to social channels.",
    links: [
      {
        message: 'Learn more',
        route: '/404',
      },
    ],
  };
}
