



































































import { Component, Vue, Prop } from 'vue-property-decorator';
import { Getter, namespace } from 'vuex-class';

import CardIcon from '@/components/subscriptions/CardIcon.vue';

import moment from 'moment-timezone';
import { getErrorsFromResponse } from '@/utils';

import type { MakePaymentProfileDefaultPayload, PaymentProfile } from '@/types/ecomm';
import type { Subscription } from '@/types/account/AccountData';
import type { AlertDisplay } from '@/types/AlertMessage';

const EcommStore = namespace('EcommStore');

@Component({
  name: 'CreditPaymentCardListItem',
  components: {
    CardIcon,
  },
})
export default class CreditPaymentCardListItem extends Vue {
  @Prop({ type: Object, required: true }) card!: PaymentProfile;

  @Getter('rawSubscription') subscription?: Subscription;
  @EcommStore.Action updateSubscriptionDefaultPaymentProfile!: (
    payload: MakePaymentProfileDefaultPayload
  ) => Promise<void>;
  @EcommStore.Action deletePaymentProfile!: (paymentProfileId: PaymentProfile['id']) => Promise<void>;
  @EcommStore.Getter paymentProfiles!: PaymentProfile[];

  get isDefault() {
    return this.subscription?.defaultPaymentProfileId === this.card.id;
  }

  showConfirmButtons = false;
  isMakeDefaultButtonLoading = false;
  isDeleteButtonLoading = false;

  onRemove() {
    this.showConfirmButtons = true;
  }

  onCancelRemove() {
    this.showConfirmButtons = false;
  }

  async onConfirmRemove() {
    if (this.isDeleteButtonLoading) return;

    this.isDeleteButtonLoading = true;
    try {
      await this.deletePaymentProfile(this.card.id);
    } catch (e) {
      console.error(e);
      const header = 'Remove credit card';
      const defaultMessage = `An error occurred. The card ending in ${this.lastFour} was not deleted.`;
      this.setAndEmitErrorAlert(e, header, defaultMessage);
    } finally {
      this.isDeleteButtonLoading = false;
    }
  }

  async onMakeDefault() {
    if (this.isMakeDefaultButtonLoading) return;
    if (!this.subscription) return;

    this.isMakeDefaultButtonLoading = true;
    const { id: paymentProfileId } = this.card;
    const { subscriptionId } = this.subscription;

    try {
      await this.updateSubscriptionDefaultPaymentProfile({ subscriptionId, paymentProfileId });
    } catch (e) {
      console.error(e);
      const header = 'Default card update';
      const defaultMessage = `An error occurred. The card ending in ${this.lastFour} was not set as the default.`;
      this.setAndEmitErrorAlert(e, header, defaultMessage);
    } finally {
      this.isMakeDefaultButtonLoading = false;
    }
  }

  setAndEmitErrorAlert(error: unknown, header: string, defaultMessage = 'An unknown error occurred.') {
    const errors = getErrorsFromResponse(error);
    const alertMessage: AlertDisplay = {
      type: 'critical',
      header: header,
      message: errors ? errors[0] : defaultMessage,
    };
    this.$emit('onAlertUpdate', alertMessage);
  }

  get lastFour() {
    const { masked_card_number } = this.card;
    return masked_card_number.split('-').pop() ?? '';
  }

  get expirationDate() {
    const { expiration_month, expiration_year } = this.card;
    return moment(new Date(expiration_year, expiration_month - 1, 1)).format('MM[/]YYYY');
  }

  get isExpired() {
    const { expiration_month, expiration_year } = this.card;

    const thisMonth = moment(new Date()).startOf('month').unix;
    const cardExpiration = moment(new Date(expiration_year, expiration_month - 1, 1)).unix;

    return cardExpiration > thisMonth;
  }
}
