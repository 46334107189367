





















import { Vue } from 'vue-property-decorator';

const Tabs = Vue.extend({
  props: {
    titles: Array,
    noBoxShadow: Boolean,
    noBorder: Boolean,
    activeTabIndex: {
      type: Number,
      default: () => 0,
    },
  },
  data() {
    return {
      tabs: [],
    };
  },
  methods: {
    onClickEvent(index) {
      if (this.$props.titles[index].clickEvent) {
        this.$emit(this.$props.titles[index].clickEvent);
      }
      this.$emit('onChange', index);
    },
  },
});

export default Tabs;
