






import { Component, Vue, Prop } from 'vue-property-decorator';

import DoughnutChartPlaceholder from '@/assets/audience/dashboard-doughnut-chart/doughnut-chart-placeholder.png';
import LineChartPlaceholder from '@/assets/audience/dashboard-doughnut-chart/stacked-line-chart-placeholder.png';

@Component({
  name: 'NewUserNoPlatformsPlaceholderDesktop',
})
export default class NewUserNoPlatformsPlaceholderDesktop extends Vue {
  @Prop({ type: String, required: true, default: 'doughnut' }) chartType!: 'doughnut' | 'line';

  get placeholderImgSrc(): string {
    return this.chartType === 'doughnut' ? DoughnutChartPlaceholder : LineChartPlaceholder;
  }

  get imageClasses() {
    return ['chart-image', { doughnut: this.chartType === 'doughnut' }];
  }
}
