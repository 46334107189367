<template>
  <modal id="profile-email-modal">
    <div class="modal-head" slot="head">
      <div class="modal-header-top">
        <h2 class="h3">Update your email address</h2>
        <img
          @click="
            () => {
              resetEmailForm();
              $bvModal.hide('modal-center');
            }
          "
          class="modal-close"
          :src="require('@/assets/close-icon.svg')"
        />
      </div>
      <p class="p modal-description">
        This change will take effect immediately and you'll need to log in again with your new email address.
      </p>
      <p class="p modal-description">
        <ErrorMessage :error="inlineResetErrorMessage[0]" v-if="inlineResetErrorMessage" />
      </p>
    </div>

    <!-- email modal -->
    <div slot="form">
      <div class="email-form">
        <Input
          id="email-form-input"
          :inputHandler="() => {}"
          :placeholder="profileData.email"
          label="Current email address"
          :disabled="true"
        />
        <Input
          id="new-email-form-input"
          name="newEmail"
          :inputHandler="handleModalInputChange"
          :value="emailChangeForm.newEmail"
          :error="
            $v.emailChangeForm.newEmail.$error
              ? $v.emailChangeForm.newEmail.required
                ? errorMessages.invalidEmail
                : 'New email' + errorMessages.required + '.'
              : ''
          "
          label="New email address"
        />
        <Input
          id="confirm-email-form-input"
          name="confirmNewEmail"
          :inputHandler="handleModalInputChange"
          :value="emailChangeForm.confirmNewEmail"
          :error="confirmEmailError"
          label="Confirm new email address"
        />
        <Input
          id="password-form-input"
          name="password"
          :inputHandler="handleModalInputChange"
          type="password"
          label="Enter current password"
          :value="emailChangeForm.password"
          :error="
            $v.emailChangeForm.password.$error
              ? !$v.emailChangeForm.password
                ? ''
                : 'Password' + errorMessages.required
              : inlinePasswordErrorMessage
              ? inlinePasswordErrorMessage.message
              : ''
          "
        />
        <div class="form-submission">
          <a
            class="update-link cancel-link"
            to="#"
            @click="
              () => {
                resetEmailForm();
                $bvModal.hide('modal-center');
              }
            "
            >Cancel</a
          >
          <Button
            id="update-email-button"
            :loading="buttonLoadingStatus"
            onClickEventHandler="onClick"
            @onClick="saveEmailChange"
            buttonType="primary"
            >Save changes</Button
          >
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import Vue from 'vue';
import { required, sameAs } from 'vuelidate/lib/validators';
import { validateEmail as $valEmail } from '@/plugins/Validations';
import Input from '@/components/common/form/Input.vue';
import Button from '@/components/common/buttons/Button.vue';
import Modal from '@/components/common/Modal.vue';
import ErrorMessage from '@/components/common/form/ErrorMessage.vue';
import errorMessages from '@/utils/errorMessages.json';
import AuthService from '@/services/AuthService';
import MyProfileService from '@/services/MyProfileService';
import UserService from '@/services/UserService';
import { mapActions } from 'vuex';

export default Vue.extend({
  name: 'change-email-modal',
  components: { Input, Button, Modal, ErrorMessage },
  props: {
    profileData: {
      type: Object,
    },
  },
  data() {
    return {
      buttonLoadingStatus: false,
      emailChangeForm: {
        email: '',
        confirmNewEmail: '',
        newEmail: '',
        password: '',
      },
      errorMessages: errorMessages,
      inlinePasswordErrorMessage: '',
      inlineResetErrorMessage: '',
    };
  },
  validations: {
    emailChangeForm: {
      confirmNewEmail: {
        required,
        sameAs: sameAs('newEmail'),
      },
      newEmail: {
        required,
        $valEmail,
      },
      password: {
        required,
      },
    },
  },
  methods: {
    ...mapActions(['logOut']),
    handleModalInputChange(e) {
      this.emailChangeForm[e.target.name] = e.target.value;
    },

    async saveEmailChange() {
      this.buttonLoadingStatus = true;

      // touch the form to check validity
      this.$v.emailChangeForm.$touch();

      if (
        !this.$v.emailChangeForm.$invalid &&
        this.emailChangeForm.newEmail === this.emailChangeForm.confirmNewEmail &&
        this.profileData.email !== this.emailChangeForm.newEmail
      ) {
        //confirm password
        const { password } = this.emailChangeForm;
        const credentials = {
          emailAddress: this.profileData.email,
          password,
        };
        // Authenticate
        const res = await new AuthService().verifyPassword(credentials);

        if (res.errors) {
          this.inlinePasswordErrorMessage = {
            index: this.editing,
            message: 'Your password is incorrect. Please try again or reset your password.',
          };
        } else {
          //clear password error message on sucessful validation
          this.inlinePasswordErrorMessage = {
            index: this.editing,
            message: '',
          };

          const myProfileService = new MyProfileService();
          const userData = await myProfileService.getMyProfileUserData({ email: this.profileData.email });

          if (userData.errors) {
            this.inlineResetErrorMessage = {
              index: this.editing,
              message: Vue.prototype.$errorPrettified(userData.errors),
            };
          }

          const payload = { ...userData };
          payload.email = this.emailChangeForm.newEmail;
          payload.brands = userData.brands.map(({ id }) => id) || [];
          payload.products = userData.products.map(({ id }) => id) || [];

          const response = await new UserService()
            .edit(payload)
            .then((res) => res)
            .catch(() => {
              this.inlineResetErrorMessage = response.errors.map((err) => Vue.prototype.$errorPrettified(err));
            });

          if (!response.errors) {
            this.resetEmailForm();
            this.$bvModal.hide('modal-center');
            this.logOut();
            this.$router.replace({ path: '/login' });
          } else {
            this.inlineResetErrorMessage = response.errors.map((err) => Vue.prototype.$errorPrettified(err));
          }
        }
      }
      this.buttonLoadingStatus = false;
    },

    resetEmailForm() {
      this.$v.emailChangeForm.$reset();
      this.inlineResetErrorMessage = '';
      this.emailChangeForm = {
        email: '',
        confirmNewEmail: '',
        newEmail: '',
        password: '',
      };
    },
  },
  computed: {
    confirmEmailError() {
      if (!this.$v.emailChangeForm.confirmNewEmail.$dirty) {
        return '';
      }
      if (!this.$v.emailChangeForm.confirmNewEmail.required) {
        return `Confirm new email${errorMessages.required}.`;
      }
      if (!this.$v.emailChangeForm.confirmNewEmail.sameAs && this.emailChangeForm.confirmNewEmail) {
        return `Email addresses${errorMessages.match}`;
      }
      return '';
    },
  },
});
</script>

<style scoped lang="scss">
.email-form {
  padding: 0 2rem 2rem 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .form-input {
    margin-bottom: 2rem;
  }
}
.cancel-link {
  text-decoration: underline;

  &:hover {
    cursor: pointer;
  }
}
.modal-head {
  padding-bottom: 2rem;
}
.modal-header-top {
  display: flex;
  justify-content: space-between;
  padding: 2rem;
}
.modal-close {
  position: relative;
  bottom: 32px;
  left: 16px;

  &:hover {
    cursor: pointer;
  }
}
.modal-description {
  padding: 0 2rem;
}
.form-submission {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.update-links {
  margin-top: 36px;
  display: flex;
}
.update-link {
  color: var(--accent-links);
  margin-right: 40px;
}
#email-form-input {
  margin-bottom: 2rem;
}
</style>
