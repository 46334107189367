import store from '../../../store';

import EditShowRoute from '@/createandpublish/views/new-show/EditShowRoute.vue';
import NewShowBase from '@/createandpublish/views/new-show/NewShowBase';
import NewShowBasicInfo from '@/createandpublish/views/new-show/NewShowBasicInfo.vue';
import NewShowDisplayDetails from '@/createandpublish/views/new-show/NewShowDisplayDetails';
import NewShowPublishingDestinations from '@/createandpublish/views/new-show/NewShowPublishingDestinations';

const setErrorMsg = (details) => {
  store.commit('CreateAndPublishStore/SET_MESSAGE', {
    name: 'Show Setup',
    details: details || 'Unable to talk to server',
    type: 'error',
  });
};
const pathRequiresBrandId = { PathRequiresBrandId: true };
const requiresBrandAccess = { RequiresBrandAccess: true };

export default {
  path: '/create/:brandId/podcasts/shows/edit',
  component: EditShowRoute,
  async beforeEnter(_to, _from, next) {
    // Clean up state
    await store.dispatch('CreateAndPublishStore/clearActiveShow');
    store.commit('CreateAndPublishStore/showSetup/CLEAR_DRAFT_SHOW');
    store.commit('CreateAndPublishStore/showSetup/SET_MAX_STEP_COMPLETED', 0);

    // Gets required data
    const promises = [
      store.dispatch('CreateAndPublishStore/getPlaylistCategories'),
      store.dispatch('CreateAndPublishStore/getSocialMediaConnections'),
    ];
    try {
      await Promise.all(promises);
      next();
    } catch (err) {
      console.log('err', err);
    }
  },
  meta: {
    sidebar_active_item: 'Shows',
    ...pathRequiresBrandId,
    ...requiresBrandAccess,
  },
  children: [
    {
      path: ':showId',
      component: NewShowBase,
      async beforeEnter(to, _from, next) {
        const {
          params: { showId },
        } = to;

        if (showId && !isNaN(showId)) {
          // Existing show, so fetch data
          try {
            await store.dispatch('CreateAndPublishStore/fetchShowData', showId); // sets activeShow
            if (!store.getters['CreateAndPublishStore/activeShow']) {
              throw new Error(`Unable to fetch data for Show ID: ${showId}. Can't edit show.`);
            }
            store.commit('CreateAndPublishStore/showSetup/SET_MAX_STEP_COMPLETED', 2);
          } catch (e) {
            setErrorMsg(e.message);
            // next({ name: 'show-list' });
          }
        }

        // populate showSetup data
        await store.dispatch('CreateAndPublishStore/showSetup/ensureDraftShow');

        next();
      },
      children: [
        {
          path: '/',
          redirect: 'basic-info',
        },
        {
          path: 'basic-info',
          name: 'basic-info',
          component: NewShowBasicInfo,
          meta: {
            next: 'display-details',
            step: 1,
          },
        },
        {
          path: 'display-details',
          name: 'display-details',
          component: NewShowDisplayDetails,
          meta: {
            next: 'publishing-destinations',
            previous: 'basic-info',
            step: 2,
          },
        },
        {
          path: 'publishing-destinations',
          name: 'publishing-destinations',
          component: NewShowPublishingDestinations,
          meta: {
            previous: 'display-details',
            step: 3,
          },
        },
      ],
    },
  ],
};
