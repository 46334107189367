
































import Vue, { PropType } from 'vue';
import moment from 'moment-timezone';
import type { PlansData } from '@/types/ecomm/PricesResponse';
import { getTotalPrice, SubscriptionData } from '@/utils/ecomm';

export default Vue.extend({
  name: 'subscription-total',

  props: {
    plans: {
      type: Object as PropType<PlansData>,
      required: true,
    },
    data: {
      type: Object as PropType<SubscriptionData>,
    },
  },

  data() {
    return {
      formIsLoading: false, // is form submitting?
    };
  },

  computed: {
    date(): string {
      const date = moment().add(1, 'month');
      return `Charged today and monthly on the ${date.format('Do')}`;
    },
    taxPercentage(): string {
      // TODO: calculate tax based on State & Chargify
      // return `(Chicago, 5.25%)`;
      return ``;
    },
    subtotal(): string {
      const { plans, data: subscriptionData } = this;
      const subtotal = getTotalPrice(plans, subscriptionData);

      return subtotal;
    },
    // tax(): number {
    //   // TODO: figure out tax rate from address + chargify
    //   const tax = 0;
    //   return tax;
    // },
    total(): string {
      return this.subtotal;
    },
  },
});
