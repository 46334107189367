






















import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class Loading extends Vue {
  @Prop({ required: false }) private isLoading?: boolean;
  @Prop({ required: false }) private isSmall?: boolean;
  @Prop({ required: false }) private button?: boolean;
  @Prop({ required: false }) private styles?: Record<string, unknown>;

  get style() {
    let result = '';
    if (this.styles) {
      Object.keys(this.styles).forEach((key) => {
        result += `${key}: ${this.styles && this.styles[key]};`;
      });
    }
    return result;
  }
}
