import HttpClient from '@/services/common/HttpClient';
import { Feature } from '@/types/features/BEFeature';

export default class FeaturesService {
  private http: HttpClient = HttpClient.getInstance();
  private endpoint = '/feature';

  public async getAll(): Promise<Feature[]> {
    return await this.http.get(`${this.endpoint}`);
  }
}
