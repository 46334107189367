import { RouteConfig } from 'vue-router';
import AddPaymentMethod from '@/views/ecomm/AddPaymentMethod.vue';
import Checkout from '@/views/ecomm/Checkout.vue';
import Dashboard from '@/views/Dashboard.vue';
import ForgotPassword from '@/views/auth/ForgotPassword.vue';
import Login from '@/views/Login.vue';
import ManageBrands from '@/views/ManageBrands.vue';
import ManageCampaigns from '@/views/ManageCampaigns.vue';
import ManageSubscriptions from '@/views/ManageSubscriptions.vue';
import MyProfile from '@/views/MyProfile.vue';
import NotFound from '@/views/NotFound.vue';
import PasswordHelp from '@/views/PasswordHelp.vue';
import store from '@/store';
import Subscriptions from '@/views/Subscriptions.vue';
import UserConfirmationNewPassword from '@/views/auth/UserConfirmationNewPassword.vue';
import Users from '@/views/Users.vue';
import {
  adminOnly,
  allowAnonymousMeta,
  preventAuthenticatedMeta,
  preventDisabled,
  requiresMultiBrandAccount,
  requiresSingleBrandAccount,
  requiresValidSubscription,
} from '@/constants/router/permissions';

import { hideLayoutMeta } from '@/constants/router/layout';

export default [
  {
    path: '/',
    name: 'Dashboard',
    component: Dashboard,
    meta: { title: 'Account Overview' },
  },

  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: { ...allowAnonymousMeta, ...preventAuthenticatedMeta, ...hideLayoutMeta, title: 'Log In' },
  },
  {
    path: '/checkout/login',
    name: 'Subscription Login',
    component: Login,
    meta: { ...allowAnonymousMeta, ...preventAuthenticatedMeta, ...hideLayoutMeta, title: 'Log In', flow: 'checkout' },
  },
  {
    path: '/audience/login',
    name: 'Audience Login',
    component: Login,
    meta: { ...allowAnonymousMeta, ...preventAuthenticatedMeta, ...hideLayoutMeta, title: 'Log In', flow: 'audience' },
  },
  // Forgot password
  {
    path: '/forgot-password',
    name: 'Forgot Password',
    component: ForgotPassword,
    meta: { ...allowAnonymousMeta, ...preventAuthenticatedMeta },
  },
  // User confirm new password (user activation)
  {
    path: '/confirmation',
    name: 'Confirm New Password',
    component: UserConfirmationNewPassword,
    meta: { ...allowAnonymousMeta, ...hideLayoutMeta },
    beforeEnter: async (to, from, next) => {
      await store.dispatch('logOut'); // Log user out if resetting password
      next();
    },
  },
  // Checkout process
  {
    path: '/checkout',
    name: 'Checkout',
    component: Checkout,
    meta: { ...allowAnonymousMeta, ...preventAuthenticatedMeta },
  },
  // Checkout process free trial
  {
    path: '/checkout/free-trial',
    name: 'Checkout Free Trial',
    component: Checkout,
    meta: { ...allowAnonymousMeta, ...preventAuthenticatedMeta },
    props: {
      trial: true,
    },
  },
  // Checkout process audience
  {
    path: '/signupforaudience',
    name: 'Checkout Audience',
    component: Checkout,
    meta: { ...allowAnonymousMeta, ...preventAuthenticatedMeta, flow: 'audience' },
  },
  {
    path: '/profile',
    name: 'Profile',
    component: MyProfile,
    meta: { title: 'Profile' },
    beforeEnter: async (to, from, next) => {
      await store.dispatch('fetchAccount');
      await store.dispatch('currentUser/requestUser').then(() => {
        next();
      });
    },
  },
  {
    path: '/users',
    name: 'Users',
    component: Users,
    beforeEnter: async (to, from, next) => {
      await store.dispatch('fetchAccount');
      await store.dispatch('fetchBrands');
      await store.dispatch('fetchUsers');
      next();
    },
    meta: { ...requiresValidSubscription, ...adminOnly, ...preventDisabled, title: 'Manage Users' },
  },
  {
    path: '/subscriptions',
    name: 'Subscriptions',
    component: Subscriptions,
    meta: { ...adminOnly, ...preventDisabled, title: 'Manage Subscriptions', feature: 'ecomm' },
  },
  {
    path: '/subscriptions/add-payment',
    name: 'Subscription.AddPaymentMethod',
    component: AddPaymentMethod,
    meta: { ...adminOnly, ...preventDisabled, title: 'Add Payment Method' },
  },
  {
    path: '/manage-subscription',
    name: 'Manage Subscription',
    component: ManageSubscriptions,
    meta: { ...adminOnly, ...preventDisabled, title: 'Manage Subscriptions', feature: 'ecomm' },
  },
  {
    path: '/billing',
    name: 'Billing',
    component: NotFound,
    meta: { ...adminOnly, ...preventDisabled, title: 'Manage Billing' },
  },
  {
    path: '/billing-history',
    name: 'Billing History',
    component: NotFound,
    meta: { ...adminOnly, ...preventDisabled, title: 'Billing History' },
  },
  {
    path: '/campaigns',
    name: 'Campaigns',
    component: ManageCampaigns,
    meta: {
      ...requiresValidSubscription,
      ...requiresSingleBrandAccount,
      ...adminOnly,
      ...preventDisabled,
      title: 'Manage Campaigns',
      feature: 'campaigns',
    },
  },
  {
    path: '/brands',
    name: 'Brands',
    component: ManageBrands,
    meta: {
      ...requiresValidSubscription,
      ...adminOnly,
      ...requiresMultiBrandAccount,
      ...preventDisabled,
      // might need to make this title dynamic if campaign feature is turned off
      title: 'Manage Brands & Campaigns',
    },
  },
  {
    path: '/account-overview',
    name: 'Account Overview',
    component: NotFound,
    meta: { title: 'Account Overview' },
  },

  {
    path: '/reset-password',
    name: 'Password Help',
    component: PasswordHelp,
    meta: { ...allowAnonymousMeta, ...hideLayoutMeta, title: 'Password Help' },
    beforeEnter: (to, from, next) => {
      store.dispatch('logOut'); // Log user out if resetting password
      next();
    },
  },
] as RouteConfig[];
