<template functional>
  <div class="card-layout">
    <div class="image-container">
      <slot name="image"></slot>
    </div>

    <div class="main-content-container">
      <slot name="content"></slot>
    </div>

    <div class="action-bar-container">
      <slot name="actionBar"></slot>
    </div>

    <div class="meta-container">
      <slot name="meta"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'card-layout',
};
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
}

.card-layout {
  display: grid;
  grid-template-areas:
    'main image'
    'meta meta'
    'action action';
  grid-template-columns: minmax(0, 1fr) 91px;
  grid-template-rows: 110px 41px 56px;
  height: 207px;
  width: 100%;
  padding: 0;

  .image-container {
    grid-area: image;
    padding: 16px 8px;
    height: 75px;
    width: 75px;
    box-sizing: content-box;
  }

  .meta-container {
    grid-area: meta;
    height: 41px;
    width: 100%;
    padding: 8px 16px;
    border-top: 1px solid var(--neutral-grey-3-borders);
  }

  .main-content-container {
    grid-area: main;
    height: 100%;
    width: 100%;
    overflow: hidden;
  }

  .action-bar-container {
    grid-area: action;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    width: 100%;
  }
}

@media screen and (min-width: 992px) {
  .card-layout {
    grid-template-areas:
      'image main action'
      'meta meta action';
    grid-template-columns: 166px minmax(0, 1fr) 56px;
    grid-template-rows: 166px 56px;
    height: 222px;

    .image-container {
      padding: 0;
      border-top-left-radius: 16px;
      height: 166px;
      width: 166px;
      overflow: hidden;

      div {
        img {
          height: 166px;
        }
      }
    }

    .meta-container {
      height: 100%;
      padding: 16px;
    }

    .action-bar-container {
      border-bottom-left-radius: unset;
      border-top-right-radius: 16px;
      border-left: 1px solid var(--neutral-grey-3-borders);
    }
  }
}
</style>
