<!-- Layout toggles, sorting, dataset info -->
<template>
  <div class="table_info__wrapper">
    <div class="table_info__results">
      {{ numberOfElements | formatNumber }} {{ elementName || 'result' }}{{ isPlural }}
    </div>
    <div class="table_info__configs">
      <div class="table_info__sort" v-if="currentSort">
        <span>Sort by</span>
        <fm-select
          :options.prop="sortOptions"
          :value.prop="currentSort"
          @input="(e) => $emit('onSortChange', e.target.value)"
          style="height: 50px"
        />
      </div>
      <div class="table_info__order" v-if="currentOrder">
        <span>Order</span>
        <fm-select
          :options.prop="orderOptions"
          :value.prop="currentOrder"
          @input="(e) => $emit('onOrderChange', e.target.value)"
          style="height: 50px"
        />
      </div>
      <div class="table_info__layout" v-if="currentLayout">
        <div class="grid-view" :id="this.$data.gridIsVisible ? 'active-grid-button' : ''">
          <Button
            :disabled="false"
            status=""
            :loading="false"
            onClickEventHandler="click"
            :class="`grid ${currentLayout === 'grid' ? 'selected' : ''}`"
            @click="$emit('onLayoutChange')"
            :data-qa="$getComponent('Tableinfo', 'Button', 'gridView')"
          >
            <span class="material-icons"> grid_view </span>
            Grid View
          </Button>
        </div>
        <div class="list-view">
          <Button
            :disabled="false"
            status=""
            :loading="false"
            onClickEventHandler="click"
            :class="`list ${currentLayout === 'list' ? 'selected' : ''}`"
            @click="$emit('onLayoutChange')"
            :data-qa="$getComponent('Tableinfo', 'Button', 'listView')"
          >
            <span class="material-icons"> view_list </span>
            List View
          </Button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Button from '@/components/common/buttons/Button.vue';
export default {
  name: 'TableInfo',
  components: {
    Button,
  },
  data() {
    return {};
  },

  computed: {
    isPlural() {
      if (this.numberOfElements === 1) {
        return '';
      }
      return 's';
    },
  },
  props: {
    // Dataset info
    elementName: String,
    numberOfElements: Number,
    // Sorting
    currentSort: String,
    onSortChange: Function,
    sortOptions: Array,
    // Order
    currentOrder: String,
    orderOptions: Array,
    onOrderChange: Function,
    // Layout
    currentLayout: String,
    layoutOptions: Array,
    onLayoutChange: Function,
  },

  filters: {
    /**
     * Pretty-prints numbers. Adds commas where necessary and strips the decimal place if it's there.
     * @param {Number|String} v some number to format
     * @returns {String} formatted number
     */
    formatNumber(v) {
      return `${('' + v).replace(/\B(?=(\d{3})+(?!\d))/g, ',').replace(/\.\d{2}$/, '')}`;
    },
  },
};
</script>
<style lang="scss" scoped>
.table_info__wrapper {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
  width: 100%;
}
.table_info__results {
  color: var(--neutral-grey-1-text);
  font-size: 34px;
  letter-spacing: 0px;
  text-align: left;
}
.table_info__configs {
  display: flex;
}
.table_info__sort,
.table_info__order,
.table_info__layout {
  align-items: center;
  display: flex;
  margin-left: 25px;
  span {
    margin-right: 10px;
  }

  .grid-view .custom-button {
    border-radius: 8px 0px 0px 8px;
    color: var(--accent-links);
    font-family: 'Roboto';
    font-weight: 500;
    font-size: 16px;
    height: 48px;
    line-height: 24px;
    outline: 0;
    padding: 12px 16px;
    width: 100%;
  }

  .list-view .custom-button {
    border-radius: 0px 8px 8px 0px;
    color: var(--accent-links);
    font-family: 'Roboto';
    font-weight: 500;
    font-size: 16px;
    height: 48px;
    line-height: 24px;
    outline: 0;
    padding: 12px 16px;
    width: 100%;
  }

  button.custom-button:focus,
  button.custom-button:hover,
  button.custom-button:active {
    background: var(--neutral-grey-1-text);
    color: var(--neutral-white);
  }

  button.custom-button.selected {
    background-color: var(--neutral-grey-1-text);
    color: var(--neutral-white);
  }

  button.custom-button .material-icons {
    color: var(--accent-links);
  }

  button.custom-button:focus .material-icons,
  button.custom-button:hover .material-icons,
  button.custom-button:active .material-icons,
  button.custom-button.selected .material-icons {
    color: var(--neutral-white);
  }

  button.custom-button:not[active],
  button.custom-button:not[focus] {
    color: var(--neutral-white);
  }
}
</style>
