



















import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({
  name: 'DashboardIcon',
})
export default class DashboardIcon extends Vue {
  @Prop({ type: String, required: false, default: '#222046' }) fill!: string;
  @Prop({ type: Number, required: false, default: 18 }) height!: number;
  @Prop({ type: Number, required: false, default: 19 }) width!: number;
  @Prop({ type: String, required: false, default: 'Dashboard' }) title!: string;
}
