








import { Component, Vue, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import BaseBarChart from '@/audience/components/base-charts/BaseBarChart.vue';
import ChartTooltip from '@/audience/components/base-charts/ChartTooltip.vue';
import NoChartDataBanner from '@/audience/components/base-charts/NoChartDataBanner.vue';
import { barGroupLabelPlugin } from '@/audience/data/chart-plugins';
import { DatasetBackgroundColors } from '@/audience/data/socialPlatformChartConfig';
import { chartHasNonZeroData } from '@/audience/core/utils/chart';
import moment from 'moment-timezone';
import type { ChartConfig, TooltipConfig, SocialAnalytics, ChartDataByPlatform } from '@/types/audience';
import type { ChartData, ChartOptions, TooltipModel, Color, ChartDataset } from 'chart.js';
import type { BarGroupLabelPluginOptions } from '@/audience/data/chart-plugins/barGroupLabelPlugin';

const audienceModule = namespace('AudienceAnalyticsStore');

@Component({
  name: 'PlatformBarChart',
  components: {
    BaseBarChart,
    ChartTooltip,
    NoChartDataBanner,
  },
})
export default class PlatformBarChart extends Vue {
  @Prop({ type: String, required: true }) platformLabel!: string;
  @Prop({ type: String, required: true }) platformColor!: string;
  @Prop({ type: String, required: true }) context!: string;
  @Prop({ type: Object, required: true }) platformData!: ChartDataByPlatform;

  @audienceModule.Getter('extendedSocialAnalytics') socialAnalytics!: SocialAnalytics;
  @audienceModule.Getter('timeZone') timeZone!: string;

  get chartLabels(): string[] {
    switch (this.context) {
      case 'audience':
        return this.platformData.audience.map((metric) => metric.label);
      case 'impressions':
        if (!this.platformData.impressions) return [];
        return this.platformData.impressions.map((metric) => metric.label);
      case 'engagement':
        if (!this.platformData.engagement) return [];
        return this.platformData.engagement.map((metric) => metric.label);
      default:
        return [];
    }
  }

  get chartDatasets() {
    const socialAnalyticsKeys = Object.keys(this.socialAnalytics);
    const socialAnalyticsLength = socialAnalyticsKeys.length;
    const startDate = socialAnalyticsKeys[0];
    const endDate = socialAnalyticsKeys[socialAnalyticsLength - 1];

    const baseDataset = [
      {
        type: 'bar',
        barThickness: 24,
        label: startDate,
        backgroundColor: function (color) {
          return `${DatasetBackgroundColors[color.dataIndex]}${Math.floor(255 * 0.4).toString(16)}`;
        } as unknown as Color,
        data: [] as number[],
      },
      {
        type: 'bar',
        barThickness: 24,
        label: endDate,
        backgroundColor: function (color) {
          return DatasetBackgroundColors[color.dataIndex];
        } as unknown as Color,
        data: [] as number[],
      },
    ];

    switch (this.context) {
      case 'audience':
        return this.platformData.audience.reduce((acc, { data }) => {
          acc[0].backgroundColor = `${this.platformColor}${Math.floor(255 * 0.4).toString(16)}`;
          acc[0].data.push(data[0] || 0);
          acc[1].backgroundColor = this.platformColor;
          acc[1].data.push(data[data.length - 1] || 0);
          return acc;
        }, baseDataset);
      case 'impressions':
        if (!this.platformData.impressions) return baseDataset;
        return this.platformData.impressions.reduce((acc, { data }) => {
          acc[0].data.push(data[0] || 0);
          acc[1].data.push(data[data.length - 1] || 0);
          return acc;
        }, baseDataset);
      case 'engagement':
        if (!this.platformData.engagement) return baseDataset;
        return this.platformData.engagement.reduce((acc, { data }) => {
          acc[0].data.push(data[0] || 0);
          acc[1].data.push(data[data.length - 1] || 0);
          return acc;
        }, baseDataset);
      default:
        return [];
    }
  }

  get chartHasData() {
    return chartHasNonZeroData(this.chartDatasets as ChartDataset[]);
  }

  get chartConfig(): ChartConfig {
    return {
      chartId: `${this.platformLabel}-bar-chart`,
      datasetIdKey: `${this.platformLabel}-bar-chart`,
      plugins: [barGroupLabelPlugin],
      cssClasses: '',
      styles: {},
    };
  }

  get chartOptions(): ChartOptions {
    const barGroupLabelPlugin = this.platformData[this.context]
      ? { barGroupLabelPlugin: this.barGroupLabelPluginOptions }
      : {};
    return {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        xAxis: {
          display: false,
        },
      },
      plugins: {
        ...barGroupLabelPlugin,
        legend: {
          display: false,
        },
        tooltip: {
          enabled: false,
          external: async (context) => {
            const tooltipEl = (this.$refs.tooltip as Vue).$el;

            const tooltipModel = context.tooltip as TooltipModel<'bar'>;

            const style: Partial<CSSStyleDeclaration> = {};

            // Hide if no tooltip
            if (tooltipModel.opacity === 0) {
              style.opacity = '0';
              style.display = 'none';
              this.tooltipConfig.style = style;
              return;
            }

            // Set data
            const chartData = context.chart.data as ChartData<'bar'>;
            const {
              dataIndex,
              datasetIndex,
              element,
              dataset: { backgroundColor, borderColor, label: metricLabel },
            } = tooltipModel.dataPoints[0];

            const headerLabel = moment.tz(metricLabel, this.timeZone).format('MMM DD[,] YYYY').toUpperCase();

            const primaryValueLabel = this.chartLabels[dataIndex];
            const primaryValueText = chartData.datasets[datasetIndex].data[dataIndex]?.toLocaleString() as string;
            const percentOfTotalText = (
              ((chartData.datasets[datasetIndex].data[dataIndex] as number) /
                this.chartTotalsByDate[metricLabel as string]) *
              100
            ).toFixed(0);
            const primaryBackgroundColor =
              typeof backgroundColor === 'function' ? element.options.backgroundColor : backgroundColor;

            // Display, position, other styles
            this.tooltipConfig.style.display = 'block';
            await this.$nextTick();
            const { width, height } = tooltipEl.getBoundingClientRect();

            style.opacity = '1';
            style.position = 'absolute';
            const leftPos = window.pageXOffset + tooltipModel.caretX - width / 2;
            const topPos = window.pageYOffset + tooltipModel.caretY - height - 8;
            style.left = leftPos + 'px';
            style.top = topPos + 'px';
            style.pointerEvents = 'none';

            const showSecondaryTooltipColumn = chartData.datasets?.[0]?.data?.length > 1 ?? false;

            const tooltipConfig: TooltipConfig = {
              header: {
                label: headerLabel,
                usePlatformIcon: false,
              },
              topRow: {
                primary: {
                  label: primaryValueLabel,
                  value: primaryValueText,
                  backgroundColor: primaryBackgroundColor as string,
                  borderColor: borderColor as string,
                },
                ...(showSecondaryTooltipColumn
                  ? {
                      secondary: {
                        label: '% of total',
                        value: percentOfTotalText,
                      },
                    }
                  : {}),
              },
              style,
            };
            this.tooltipConfig = tooltipConfig;

            // Figure out if tooltip is overflowing to right of viewport
            // and push left if so.
            await this.$nextTick();
            const maxRightOffset = document.body.clientWidth - 16; // 16px of padding
            const { right: newRight } = tooltipEl.getBoundingClientRect();
            const diff = maxRightOffset - newRight;
            const shiftLeftPx = diff < 0 ? Math.abs(diff) : 0;
            style.left = leftPos - shiftLeftPx + 'px';
          },
        },
      },
      elements: {},
      layout: {
        padding: {
          bottom: 90,
        },
      },
    };
  }

  tooltipConfig: TooltipConfig = {
    topRow: {
      primary: {
        label: '',
        value: '',
      },
    },
    style: {
      display: 'none',
      opacity: '0',
    },
  };

  get chartTotalsByDate() {
    return this.chartDatasets.reduce((acc, dataset) => {
      acc[dataset.label] = dataset.data.reduce((total, value) => {
        if (isNaN(value)) return total;
        total += value;
        return total;
      }, 0);
      return acc;
    }, {});
  }

  get chartData(): ChartData {
    return {
      labels: this.chartLabels,
      datasets: this.chartDatasets as ChartDataset[],
    };
  }

  get barGroupLabelPluginOptions(): BarGroupLabelPluginOptions {
    const getPercentChange = (metricIndex: number) => {
      const data = this.platformData[this.context][metricIndex].data;
      const previous = data[0] as number;
      const current = data[data.length - 1] as number;
      if (isNaN(current) || isNaN(previous) || current === 0 || previous === 0) return 0;
      const diff = current - previous;
      return diff / Math.abs(previous);
    };
    const defaultStats = { label: '', value: NaN, percentChange: 0 };
    const stats =
      this.platformData[this.context]?.map((metric, i) => {
        return {
          label: metric.label,
          value: metric.data[metric.data.length - 1],
          percentChange: getPercentChange(i),
        };
      }) ?? defaultStats;

    return {
      statLabels: {
        stats,
      },
    };
  }
}
