import { render, staticRenderFns } from "./TrendingCardList.vue?vue&type=template&id=d162cb4c&scoped=true&"
import script from "./TrendingCardList.vue?vue&type=script&lang=ts&"
export * from "./TrendingCardList.vue?vue&type=script&lang=ts&"
import style0 from "./TrendingCardList.vue?vue&type=style&index=0&id=d162cb4c&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d162cb4c",
  null
  
)

export default component.exports