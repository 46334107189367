
















import { Vue } from 'vue-property-decorator';

export default Vue.extend({
  name: 'button-radio',
  props: {
    ariaDescribedby: {
      type: String,
      required: false,
    },
    options: {
      type: Array,
      required: true,
    },

    value: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      radioGroupValue: '',
    };
  },

  methods: {
    onInput(e) {
      this.$emit('input', e);
    },
  },

  watch: {
    value: {
      handler(newVal) {
        this.radioGroupValue = newVal;
      },
      immediate: true,
    },
  },
});
