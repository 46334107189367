







import { Component, Vue, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import BaseBarChart from '@/audience/components/base-charts/BaseBarChart.vue';
import ChartTooltip from '@/audience/components/base-charts/ChartTooltip.vue';

import { barGroupLabelPlugin } from '@/audience/data/chart-plugins';

import moment from 'moment-timezone';

import { SocialRankingInterval } from 'content-cloud-types/dist/types/audience/SocialRanking';
import { LeaderboardsByInterval, SocialRankingContext } from '@/types/audience';

import type { ChartConfig, LeaderboardRankData, TooltipConfig } from '@/types/audience';
import type { ChartData, ChartOptions, TooltipModel } from 'chart.js';
import type { BarGroupLabelPluginOptions } from '@/audience/data/chart-plugins/barGroupLabelPlugin';
import type { AudienceLeaderboardContent } from 'content-cloud-types/dist/types/audience/AudienceLeaderboardTypes';

const audienceModule = namespace('AudienceAnalyticsStore');

@Component({
  name: 'LeaderboardCompareBarChart',
  components: {
    BaseBarChart,
    ChartTooltip,
  },
})
export default class LeaderboardCompareBarChart extends Vue {
  @Prop({ type: String, required: true }) rankingInterval!: SocialRankingInterval;
  @Prop({ type: Object, required: true }) rankingData!: LeaderboardRankData;

  @audienceModule.Getter timeZone!: string;
  @audienceModule.Getter leaderboards!: AudienceLeaderboardContent;
  @audienceModule.Getter leaderboardRankings!: LeaderboardsByInterval;

  get chartConfig(): ChartConfig {
    return {
      chartId: 'leaderboard-compare-bar-chart',
      datasetIdKey: 'leaderboard-compare-bar-chart',
      plugins: [barGroupLabelPlugin],
      cssClasses: '',
      styles: {
        width: '100%',
        height: '100%',
      },
    };
  }

  get chartOptions(): ChartOptions {
    return {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        xAxis: {
          display: false,
        },
      },
      plugins: {
        barGroupLabelPlugin: this.groupLabelPluginOptions,
        legend: {
          display: false,
        },
        tooltip: {
          enabled: false,
          external: async (context) => {
            const tooltipEl = (this.$refs.tooltip as Vue).$el;

            const tooltipModel = context.tooltip as TooltipModel<'bar'>;

            const style: Partial<CSSStyleDeclaration> = {};

            // Hide if no tooltip
            if (tooltipModel.opacity === 0) {
              style.opacity = '0';
              style.display = 'none';
              this.tooltipConfig.style = style;
              return;
            }

            // Set data
            const chartData = context.chart.data as ChartData<'bar'>;
            const {
              dataIndex,
              datasetIndex,
              element,
              dataset: { backgroundColor, borderColor, label: metricLabel },
            } = tooltipModel.dataPoints[0];

            const headerLabel = this.displayedDateRange[datasetIndex]?.toUpperCase();

            const primaryValueLabel = metricLabel ?? '';
            const primaryValueText = chartData.datasets[datasetIndex].data[dataIndex]?.toLocaleString() as string;

            const primaryBackgroundColor =
              typeof backgroundColor === 'function' ? element.options.backgroundColor : backgroundColor;

            // Display, position, other styles
            this.tooltipConfig.style.display = 'block';
            await this.$nextTick();
            const { width, height } = tooltipEl.getBoundingClientRect();

            style.opacity = '1';
            style.position = 'absolute';
            style.left = window.pageXOffset + tooltipModel.caretX - width / 2 + 'px';
            style.top = window.pageYOffset + tooltipModel.caretY - height - 8 + 'px';
            style.pointerEvents = 'none';

            const tooltipConfig: TooltipConfig = {
              header: {
                label: headerLabel,
                usePlatformIcon: false,
              },
              topRow: {
                primary: {
                  label: primaryValueLabel,
                  value: primaryValueText,
                  backgroundColor: primaryBackgroundColor as string,
                  borderColor: borderColor as string,
                },
              },
              style,
            };
            this.tooltipConfig = tooltipConfig;
          },
        },
      },
      elements: {},
      layout: {
        padding: {
          bottom: 55,
        },
      },
    };
  }

  tooltipConfig: TooltipConfig = {
    topRow: {
      primary: {
        label: '',
        value: '',
      },
    },
    style: {
      display: 'none',
      opacity: '0',
    },
  };

  get displayedDateRange() {
    const asOfDate = this.leaderboards.rankings[this.rankingInterval]?.asOfDate;

    const emptyState = { previous: '', current: '' };
    if (!asOfDate) return emptyState;

    const current = moment(asOfDate).tz(this.timeZone);
    const previous = current.clone().subtract(1, this.rankingInterval as moment.unitOfTime.DurationConstructor);

    return [previous.format('MMM DD[,] YYYY'), current.format('MMM DD[,] YYYY')];
  }

  get chartData(): ChartData {
    const totalReachRankData: LeaderboardRankData | undefined | null = this.leaderboardRankings[this.rankingInterval]?.[
      SocialRankingContext.total_reach
    ].find((rankingData) => rankingData.profile?.brandId === this.rankingData.profile?.brandId);

    const previousValue = totalReachRankData?.value.previous ?? NaN;
    const currentValue = totalReachRankData?.value.current ?? NaN;

    return {
      labels: [this.rankingData.profile?.profileName ?? ''],
      datasets: [
        {
          type: 'bar',
          barThickness: 24,
          label: 'Followers',
          backgroundColor: `#0174B7${Math.floor(255 * 0.4).toString(16)}`,
          data: [previousValue],
        },
        {
          type: 'bar',
          barThickness: 24,
          label: 'Followers',
          backgroundColor: '#0174B7',
          data: [currentValue],
        },
      ],
    };
  }

  get groupLabelPluginOptions(): BarGroupLabelPluginOptions {
    const getPercentChange = (dataIndex: number) => {
      const previous = this.chartData.datasets[0].data[dataIndex] as number;
      const current = this.chartData.datasets[1].data[dataIndex] as number;
      if (previous === 0) return 0;
      const diff = current - previous;
      return diff / Math.abs(previous);
    };
    return {
      briefStatLabels: {
        stats: [
          {
            label: 'All channels',
            percentChange: getPercentChange(0),
          },
        ],
      },
    };
  }
}
