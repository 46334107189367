



















import { Component, Prop, Vue } from 'vue-property-decorator';
import RoundedCard from '@/components/common/cards/RoundedCard.vue';

@Component({
  components: {
    RoundedCard,
  },
})
export default class AccountCard extends Vue {
  @Prop({ required: false }) private title?: string;
  @Prop({ required: false }) private icon?: string; // MUI icon name
  @Prop({ required: false }) private iconType?: string; // md or fa
}
