<template>
  <div id="wrapper" :class="wrapperClass">
    <div class="sidebar-wrapper" v-if="isAudienceFlow">
      <AudienceSidebar />
    </div>
    <div class="form">
      <div class="form-header">
        <router-link to="/">
          <img alt="logo" src="@/assets/FuturiCC.svg" height="58" />
        </router-link>
      </div>
      <div class="form-content" v-if="showExpiredMessage">
        <!-- Title -->
        <div class="form-title">
          <h2>Your invitation link has expired</h2>
        </div>
        <p class="form-subtitle">Please request a new one from your account administrator.</p>
      </div>
      <div class="form-content" v-else>
        <!-- Error message -->
        <AlertDisplay :alertMessage="alertMessageError" class="alertMessage" :drawer="true" v-if="showErrorMessage" />
        <!-- Title -->
        <div class="form-title">
          <h2>{{ formTitleText }}</h2>
          <span v-if="isAudienceFlow">Already a member? <router-link to="/audience/login">Sign in</router-link> </span>
        </div>
        <!-- Subtitle -->
        <p class="form-subtitle" v-if="!isAudienceFlow">
          Choose a password to activate your new account. You’ll be able to log in and start using Futuri Content Cloud
          today.
        </p>
        <form v-on:submit.prevent="$emit('onSubmit')">
          <Input
            v-if="isAudienceFlow && this.$route.query.un"
            :data-qa="$getComponent('UserConfirmationNewPassword', 'TextInput', 'emailAddress')"
            :inputHandler="handleInputChange"
            :maxLength="100"
            class="form-input"
            label="Email address"
            name="emailAddress"
            :placeholder="this.$route.query.un"
            :disabled="true"
          />
          <Input
            :data-qa="$getComponent('UserConfirmationNewPassword', 'TextInput', 'newPassword')"
            :error="passwordError"
            :inputHandler="handleInputChange"
            :value="form.password"
            assistiveText="10 characters or more, with at least one lowercase letter, one uppercase letter, and one special character."
            class="form-input"
            label="Password"
            name="password"
            type="password"
          />
          <Input
            :data-qa="$getComponent('UserConfirmationNewPassword', 'TextInput', 'confirmNewPassword')"
            :error="confirmPasswordError"
            :inputHandler="handleInputChange"
            :value="form.confirmPassword"
            class="form-input"
            label="Confirm password"
            name="confirmPassword"
            type="password"
          />
          <Button
            :data-qa="$getComponent('UserConfirmationNewPassword', 'Button', 'submit')"
            :loading="formIsLoading"
            @click="onSubmit"
            buttonType="primary"
            class="form-submit"
            onClickEventHandler="click"
            type="submit"
          >
            {{ buttonText }}
          </Button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { required, sameAs } from 'vuelidate/lib/validators';

import AlertDisplay from '@/components/common/AlertDisplay.vue';
import AudienceSidebar from '@/components/ecomm/AudienceSidebar.vue';
import Button from '@/components/common/buttons/Button.vue';
import Input from '@/components/common/form/Input.vue';
import UserService from '@/services/UserService';
import errorMessages from '@/utils/errorMessages.json';
import { validatePassword } from '@/plugins/Validations';

export default Vue.extend({
  name: 'user-confirmation-new-password',

  metaInfo: {
    htmlAttrs: { class: 'app-responsive' },
    meta: [{ name: 'viewport', content: `width=device-width, initial-scale=1` }],
  },

  components: {
    AlertDisplay,
    AudienceSidebar,
    Button,
    Input,
  },

  data() {
    return {
      // Banner for API request errors
      alertMessageError: {
        header: "We couldn't save your changes.",
        type: 'critical',
        message: 'Please try again in a few minutes.',
      },
      flow: 'default',
      form: {
        password: '',
        confirmPassword: '',
      },
      // Flags to display error/success banners
      showErrorMessage: false,
      // If submitting form
      formIsLoading: false,
      showExpiredMessage: false,
    };
  },

  computed: {
    passwordError() {
      if (!this.$v.form.password.$dirty) {
        return '';
      }
      if (!this.$v.form.password.required) {
        return `New password${errorMessages.required}`;
      }
      if (!this.$v.form.password.validatePassword) {
        return 'Passwords must contain 10 or more characters, with at least one lowercase letter, one uppercase letter, and one of these special characters: ^ $ * . [ ] { } ( ) ? " ! @ # % & / \\ , > < \' : ; | _ ~ `';
      }
      return '';
    },

    confirmPasswordError() {
      if (!this.$v.form.confirmPassword.$dirty) {
        return '';
      }
      if (!this.$v.form.confirmPassword.required) {
        return `Confirm new password${errorMessages.required}`;
      }
      if (!this.$v.form.confirmPassword.sameAs && this.form.confirmPassword && this.form.password) {
        return 'Passwords must match.';
      }
      return '';
    },

    isAudienceFlow() {
      return this.flow === 'audience';
    },

    wrapperClass() {
      return this.flow === 'default' ? 'wrapper' : `wrapper-${this.flow}`;
    },

    formTitleText() {
      return this.isAudienceFlow ? 'Activate your account' : 'Get started with Content Cloud';
    },

    buttonText() {
      return this.isAudienceFlow ? 'Create your account' : 'Activate account';
    },
  },

  validations: {
    form: {
      password: { required, validatePassword },
      confirmPassword: { required, sameAs: sameAs('password') },
    },
  },

  methods: {
    handleInputChange(e) {
      this.form[e.target.name] = e.target.value;
    },

    async onSubmit() {
      // Code
      const code = this.$route.query.code;
      // Email
      const email = this.$route.query.un;
      // Touch all fields
      this.$v.form.$touch();
      // if form's validated
      if (!this.$v.form.$invalid) {
        // submitting
        this.formIsLoading = true;
        // reset error/success banners
        this.showErrorMessage = false;
        this.showExpiredMessage = false;
        // prep payload
        const payload = {
          code,
          email,
          newPassword: this.form.confirmPassword,
        };

        // request password reset
        const res = await new UserService().userActivationConfirm(payload);

        // if not successful
        if (res.errors) {
          res.errors.includes('Temporary password has expired and must be reset by an administrator.')
            ? (this.showExpiredMessage = true)
            : (this.showErrorMessage = true);
        } else {
          // if successful, redirect to login
          if (this.isAudienceFlow) {
            this.$router.push('/audience/login?user_activated=true');
          } else {
            this.$router.push('/login?user_activated=true');
          }
        }
        // done submitting
        this.formIsLoading = false;
      }
    },
  },
  mounted() {
    if (this.$route.query && this.$route.query.flow) {
      this.flow = this.$route.query.flow;
    }
  },
});
</script>

<style lang="scss" scoped>
#wrapper {
  display: flex;
  height: 100vh;

  //styles shared between flow
  .form-title {
    color: var(--neutral-grey-1-text);
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: normal;
    line-height: 24px;
  }

  .form-input {
    margin-bottom: 32px;
  }
}

.wrapper {
  align-items: flex-start;
  background: linear-gradient(164.21deg, var(--accent-links) 7.88%, #015498 92.07%);
  display: flex;
  height: 100vh;
  justify-content: center;
  padding: 64px;
  width: 100vw;

  .form {
    background: var(--neutral-white);
    box-shadow: 0px 1px 7px rgba(34, 32, 70, 0.2);
    max-width: 592px;
    min-width: 536px;
  }

  .form-header {
    align-items: center;
    background: #f5f5f5;
    display: flex;
    height: 80px;
    justify-content: center;
  }

  .form-content {
    padding: 24px;
  }

  .form-title {
    color: var(--neutral-grey-1-text);
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: normal;
    line-height: 24px;
    margin-bottom: 32px;
  }

  .form-subtitle {
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: normal;
    line-height: 24px;
    margin: 16px 0;
  }

  .form-input {
    margin-bottom: 32px;
  }

  .form-submit {
    width: 100%;
  }

  .form-forgot-password {
    margin-top: 24px;
    text-align: center;
    width: 100%;
  }
}

//audience flow
.wrapper-audience {
  flex-direction: row;

  .form {
    background: var(--neutral-white);
    align-items: center;
    display: flex;
    flex-direction: column;
    padding: 68px;
    width: 50%;

    @media (max-width: 768px) {
      padding: 32px 24px;
      width: 100%;
    }
  }

  .sidebar-wrapper {
    background: var(--accent-background);
    height: 100%;
    order: 1;
    width: 50%;

    @media (max-width: 768px) {
      height: auto;
      order: 0;
      width: 100%;
    }
  }

  .form-header {
    margin-bottom: 64px;
    max-width: 592px;
    width: 100%;

    @media (max-width: 768px) {
      display: none;
    }
  }

  .form-content {
    max-width: 592px;
    margin: 0 auto;
    position: static;

    @media (max-width: 768px) {
      padding: 48px 24px 24px;
      width: 100%;
    }

    @media (max-width: 1400px) {
      width: 100%;
    }

    @media (max-width: 1024px) {
      max-width: 100%;
      width: 100%;
    }
  }

  .form-title {
    align-items: baseline;
    display: flex;
    flex-direction: row;
    font-size: var(--fm-font-size-24);
    justify-content: space-between;
    line-height: var(--fm-line-spacing-32);
    margin-bottom: 64px;

    span {
      align-self: right;
      font-size: var(--fm-font-size-16);
      font-weight: 400;
      line-height: var(--fm-line-spacing-24);

      a {
        color: var(--accent-links);
      }
    }

    @media (max-width: 1024px) {
      flex-direction: column;
    }
  }

  .alertMessage {
    margin: 0 15px 32px;
    width: 100%;
  }

  .custom-button {
    margin-bottom: 8px;
    width: 100%;
  }

  @media (max-width: 768px) {
    flex-direction: column;
  }
}
</style>
