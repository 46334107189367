// Application framework
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

// Futuri-ui-kit
import 'focus-visible';
import '@futuri/futuri-ui-kit/dist/';

// Bootstrap
import { BootstrapVueIcons } from 'bootstrap-vue';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';

// Global app styles
import './assets/global.css';
import './assets/aec.css';
import './assets/portal.css';

// vue-progress-path global stylesheet (loading spinner)
import 'vue-progress-path/dist/vue-progress-path.css';

// Custom directives
import modelWcDirective from './directives/modelWcDirective';
import clickOutside from './directives/click-outside';
import feature from './directives/feature';

// Vue plugins
import VueRouter from 'vue-router';
import Vuelidate from 'vuelidate';
import Components from './plugins/Components.js';
import ErrorPrettified from './plugins/ErrorPrettified.js';
import ToProperCase from './plugins/ToProperCase.js';
import VueProgress from 'vue-progress-path';
import VTooltip from 'v-tooltip';
import VueGtm from '@gtm-support/vue2-gtm';
import Maska from 'maska';
import Meta from 'vue-meta';
import VueMask, { VueMaskDirective } from 'v-mask';
import VueSSE from 'vue-sse';
import { BootstrapVue } from 'bootstrap-vue';

import { getIDConfigForEnvironment } from '@/utils/googleTagManager';
import vuetify from '@/plugins/vuetify';

Vue.use(Vuelidate);
Vue.use(VueProgress);
Vue.use(VTooltip);
Vue.use(Components);
Vue.use(ErrorPrettified);
Vue.use(BootstrapVueIcons);
Vue.use(ToProperCase);
Vue.use(Meta);
Vue.use(VueMask);
Vue.use(Maska);
Vue.use(VueSSE);
Vue.use(VueRouter);
Vue.use(BootstrapVue);

Vue.use(VueGtm, {
  id: getIDConfigForEnvironment(), // Your GTM ID
  queryParams: {
    // Add URL query string when loading gtm.js with GTM ID (required when using custom environments)
    gtm_auth: 'SyKaI3aB8BujLdi38AURNw',
    gtm_preview: 'env-2',
    gtm_cookies_win: 'x',
  },
  enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
  debug: false, // Whether or not display console logs debugs (optional)
  vueRouter: router, // Pass the router instance to automatically sync with router (optional)
});

Vue.directive('model-wc', modelWcDirective);
Vue.directive('click-outside', clickOutside);
Vue.directive('feature', feature);
Vue.directive('mask', VueMaskDirective);

Vue.config.productionTip = false;

if (process.env.NODE_ENV === 'development') {
  console.log('---------------------');
  console.log('environment', process.env);
  console.log('---------------------');
}

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
