

















import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({
  name: 'LeaderboardIcon',
})
export default class LeaderboardIcon extends Vue {
  @Prop({ type: String, required: false, default: '#222046' }) fill!: string;
  @Prop({ type: Number, required: false, default: 21 }) height!: number;
  @Prop({ type: Number, required: false, default: 17 }) width!: number;
  @Prop({ type: String, required: false, default: 'Leaderboard' }) title!: string;
}
