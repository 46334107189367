




import Vue from 'vue';

export default Vue.extend({
  name: 'card-icon',
  components: {},

  props: {
    cardType: {
      type: String,
    },
  },
  data() {
    // use this to return correct icons
    return {
      cardTypes: {
        Visa: 'visa',
        Mastercard: 'mastercard',
        Amex: 'amex',
        Discover: 'discover',
      },
    };
  },

  computed: {},
  methods: {},
});
