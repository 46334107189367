



























import { Component, Vue } from 'vue-property-decorator';
import { required } from 'vuelidate/lib/validators';
import CampaignInput from '@/components/brands/CampaignInput.vue';

@Component({
  name: 'AudioWizardDescription',
  components: {
    CampaignInput,
  },
  validations: {
    form: {
      title: { required },
    },
  },
})
export default class AudioWizardDescription extends Vue {
  form: { title: string; description: string; campaigns: number[] } = {
    title: '',
    description: '',
    campaigns: [],
  };
}
