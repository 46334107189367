import { render, staticRenderFns } from "./LeaderboardRankingListItemPlaceholder.vue?vue&type=template&id=efd1b978&scoped=true&"
import script from "./LeaderboardRankingListItemPlaceholder.vue?vue&type=script&lang=ts&"
export * from "./LeaderboardRankingListItemPlaceholder.vue?vue&type=script&lang=ts&"
import style0 from "./LeaderboardRankingListItemPlaceholder.vue?vue&type=style&index=0&id=efd1b978&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "efd1b978",
  null
  
)

export default component.exports