


































































































// TODO: When time permits, create mixin to be used here and in profileDropdown.vue (desktop version of this menu)
import { Component, Vue } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';

import ProfileDropdownProduct from '@/components/header/components/ProfileDropdownProduct.vue';

import { isCCAdmin } from '@/utils';
import { isFeatureAvailable } from '@/utils/routerHelpers';

import type { Brand } from '@/types/Brand';

const KEYBOARD_KEYS = {
  UP: 'ArrowUp',
  DOWN: 'ArrowDown',
  HOME: 'Home',
  END: 'End',
  ENTER: 'Enter',
  ESCAPE: 'Escape',
  SPACE: ' ',
};

@Component({
  name: 'MobileProfileMenu',
  components: {
    ProfileDropdownProduct,
  },
})
export default class MobileProfileMenu extends Vue {
  @Getter authenticatedUser;
  @Getter isUserMultiBrand!: boolean;
  @Getter selectedBrand!: Brand;
  @Getter sortedPriorityAccountProducts;
  @Action logOut;

  get userFullName() {
    const { firstName, lastName } = this.authenticatedUser;
    return `${firstName} ${lastName}`;
  }

  get getAdminEmail() {
    return this.authenticatedUser?.adminEmail ? `mailto:${this.authenticatedUser?.adminEmail}` : '';
  }

  get isCCAdmin() {
    return isCCAdmin();
  }

  get manageBrandsTitle() {
    return isFeatureAvailable('campaigns') ? 'Manage brands & campaigns' : 'Manage brands';
  }

  onSignOut() {
    this.toggleMenu();
    this.logOut();
    this.$router.replace({ path: '/login' });
  }

  isOpen = false;

  inspectToggleEvent(e: Event) {
    if (e instanceof KeyboardEvent) {
      if (e.key === KEYBOARD_KEYS.ENTER || e.key === KEYBOARD_KEYS.SPACE) {
        this.toggleMenu();
      }
      return;
    }
    e instanceof MouseEvent && this.toggleMenu();
  }

  addCloseEvents() {
    ['click', 'keyup'].forEach((event) => {
      document.addEventListener(event, this.inspectCloseEvents);
    });
  }

  inspectCloseEvents(e: Event) {
    if (e instanceof KeyboardEvent && e.key === KEYBOARD_KEYS.ESCAPE) {
      this.toggleMenu();
      return;
    }

    const path = e.composedPath();

    if (!path.includes(this.$el)) {
      this.toggleMenu();
    }
  }

  removeCloseEvents() {
    ['click', 'keyup'].forEach((event) => {
      document.removeEventListener(event, this.inspectCloseEvents);
    });
  }

  toggleMenu() {
    if (!this.isOpen) {
      this.addCloseEvents();
      this.isOpen = true;
    } else {
      this.removeCloseEvents();
      this.isOpen = false;
    }
  }
}
