<template>
  <Drawer
    :title="isCreateMode ? 'Add user' : 'Edit user'"
    :qa="$getComponent('AddEditUser', 'Drawer', 'add-edit-user')"
    id="add-edit-user-drawer"
    @cancel="onCancel"
    :submit="submit"
    :loading="isLoading"
    :vh100="true"
  >
    <div slot="content">
      <div class="content">
        <AlertDisplay :alertMessage="alertMessage" class="alertMessage" v-if="displayAlert" :drawer="true" />
        <!-- Error resending invitation email -->
        <AlertDisplay
          :alertMessage="resendInvitationAlertMessage"
          class="alertMessage"
          v-if="resendInvitationStatus === 'error'"
          :drawer="true"
        />
        <div>
          <div class="split component" v-if="!this.userSelfEdit">
            <Input
              label="First name"
              class="left"
              name="firstName"
              :maxLength="20"
              :inputHandler="handleInputChange"
              :value="currentUser.firstName"
              :data-qa="$getComponent('AddEditUser', 'TextInput', 'firstName')"
              :error="
                $v.currentUser.firstName.$error
                  ? $v.currentUser.firstName.required
                    ? errorMessages.invalidFirstName
                    : 'First name' + errorMessages.required
                  : ''
              "
            />
            <Input
              label="Last name"
              class="right"
              name="lastName"
              :maxLength="20"
              :inputHandler="handleInputChange"
              :value="currentUser.lastName"
              :data-qa="$getComponent('AddEditUser', 'TextInput', 'lastName')"
              :error="
                $v.currentUser.lastName.$error
                  ? $v.currentUser.lastName.required
                    ? errorMessages.invalidLastName
                    : 'Last name' + errorMessages.required
                  : ''
              "
            />
          </div>
          <Input
            label="Email"
            name="email"
            class="component"
            :maxLength="100"
            :inputHandler="handleInputChange"
            :value="currentUser.email"
            :data-qa="$getComponent('AddEditUser', 'TextInput', 'email')"
            :error="
              $v.currentUser.email.$error
                ? !$v.currentUser.email.email
                  ? errorMessages.invalidEmail
                  : 'Email' + errorMessages.required
                : ''
            "
            v-if="!this.userSelfEdit"
          />
          <!-- Status if editing -->
          <template v-if="!isCreateMode && !this.userSelfEdit && this.isCCAdmin && !this.isPrimaryAccountAdmin">
            <div class="component">
              <Radio
                :options="statusOptions"
                :handleInput="handleRadioStatus"
                :defaultValue="parseInt(currentUser.statusId)"
                :data-qa="$getComponent('AddEditUser', 'Radio', 'status')"
                label="Status"
                :error="$v.currentUser.statusId.$error ? 'Status' + errorMessages.required : ''"
              />
            </div>
            <Label label="User management" />
            <div class="split component topmargin">
              <Button
                :disabled="resendInvitationStatus === 'success'"
                :loading="resendInvitationStatus === 'loading'"
                @click="resendInvitationCode"
                buttonType="secondary"
                onClickEventHandler="click"
                >{{ resendInvitationEmailButtonText }}</Button
              >
              <Button
                :disabled="resetPasswordStatus === 'success'"
                :loading="resetPasswordStatus === 'loading'"
                @click="resetPasswordLink"
                buttonType="secondary"
                onClickEventHandler="click"
                >{{ resetPasswordLinkButtonText }}</Button
              >
            </div>
          </template>
          <FormSpacer v-if="!this.userSelfEdit" />
          <FormSectionText label="ACCESS & PERMISSIONS" />
          <Radio
            class="component"
            :options="roleOptions"
            :handleInput="handleRadioRole"
            :defaultValue="parseInt(currentUser.roleId)"
            :data-qa="$getComponent('AddEditUser', 'Radio', 'userType')"
            label="User type"
            :error="$v.currentUser.roleId.$error ? errorMessages.required : ''"
            v-if="!this.userSelfEdit && !this.isPrimaryAccountAdmin"
          />

          <template v-if="allActiveBrands.length > 1 || isMultiBrandUser">
            <Label class="label-component" label="Brands" />
            <multiselect
              :initialValue="currentUser.brands || []"
              :onInput="onBrandSelect"
              :options="brandOptions"
              label="name"
              placeholder="Select brands"
              trackBy="id"
            />
          </template>
        </div>
        <Label class="label-component" label="Apps" />
        <!-- Product toggles should ideally be generated dynamically, needs refactor -->
        <div class="component fm-toggle-temp">
          <div class="toggle" v-if="productToggleVisibility['Audience']">
            <div>
              <ToggleSwitch
                :value="audienceToggleValue"
                :ariaLabel="audienceAriaLabel"
                onClickEventHandler="onClick"
                :iconName="audienceToggleValue ? 'done' : 'close'"
                @onClick="handleToggle({ value: audienceToggleValue, label: audienceAriaLabel })"
                :data-qa="$getComponent('AddEditUser', 'Radio', 'audience')"
                :disabled="audienceDisabled"
              />
              <span>Audience</span>
            </div>
            <span v-if="this.productData['Audience'].active">
              {{ getSeatsCopy('Audience') }}
              <TextLink
                :to="seatsMaxedCTALink"
                linkClass="subscription-link"
                :isExternalLink="!features.includes('ecomm')"
              >
                Add more
              </TextLink>
            </span>
            <span v-else>
              <TextLink
                :to="seatsMaxedCTALink"
                linkClass="subscription-link"
                :isExternalLink="!features.includes('ecomm')"
              >
                Subscribe now
              </TextLink>
            </span>
          </div>
          <div class="toggle" v-if="productToggleVisibility['Discover']">
            <div>
              <ToggleSwitch
                :value="discoverToggleValue"
                :ariaLabel="discoverAriaLabel"
                onClickEventHandler="onClick"
                :iconName="discoverToggleValue ? 'done' : 'close'"
                @onClick="handleToggle({ value: discoverToggleValue, label: discoverAriaLabel })"
                :data-qa="$getComponent('AddEditUser', 'Radio', 'discover')"
                :disabled="discoverDisabled"
              />
              <span>Discover</span>
            </div>
            <span v-if="this.productData['Discover'].active">
              {{ getSeatsCopy('Discover') }}
              <TextLink
                :to="seatsMaxedCTALink"
                linkClass="subscription-link"
                :isExternalLink="!features.includes('ecomm')"
              >
                Add more
              </TextLink>
            </span>
            <span v-else>
              <TextLink
                :to="seatsMaxedCTALink"
                linkClass="subscription-link"
                :isExternalLink="!features.includes('ecomm')"
              >
                Subscribe now
              </TextLink>
            </span>
          </div>
          <div class="toggle" v-if="productToggleVisibility['Create&Publish']">
            <div>
              <ToggleSwitch
                :value="createToggleValue"
                :ariaLabel="createAriaLabel"
                onClickEventHandler="onClick"
                :iconName="createToggleValue ? 'done' : 'close'"
                @onClick="handleToggle({ value: createToggleValue, label: createAriaLabel })"
                :data-qa="$getComponent('AddEditUser', 'Radio', 'create&publish')"
                :disabled="createDisabled"
              />
              <span>Create&Publish</span>
            </div>
            <span v-if="this.productData['Create&Publish'].active">
              {{ getSeatsCopy('Create&Publish') }}
              <TextLink
                :to="seatsMaxedCTALink"
                linkClass="subscription-link"
                :isExternalLink="!features.includes('ecomm')"
              >
                Add more
              </TextLink>
            </span>
            <span v-else>
              <TextLink :to="seatsMaxedCTALink" class="subscription-link" :isExternalLink="!features.includes('ecomm')">
                Subscribe now
              </TextLink>
            </span>
          </div>
        </div>
      </div>
    </div>
  </Drawer>
</template>

<script>
import Vue from 'vue';
import { mapActions, mapGetters } from 'vuex';

import AlertDisplay from '@/components/common/AlertDisplay.vue';
import Button from '@/components/common/buttons/Button.vue';
import Drawer from '@/components/common/drawer/Drawer.vue';
import FormSectionText from '@/components/common/form/FormSectionText.vue';
import FormSpacer from '@/components/common/form/FormSpacer.vue';
import Input from '@/components/common/form/Input.vue';
import Label from '@/components/common/form/Label.vue';
import TextLink from '@/components/common/TextLink.vue';
import Multiselect from '@/components/common/form/multiselect';
import Radio from '@/components/common/form/Radio.vue';
import ToggleSwitch from '@/components/common/buttons/ToggleSwitch.vue';
import UserService from '@/services/UserService';
import errorMessages from '@/utils/errorMessages.json';
import { validateName as $validateName, validateEmail as $valEmail } from '@/plugins/Validations';
import { required } from 'vuelidate/lib/validators';
import { isMultiBrand, isCCAdmin, maybePluralize, getUniqueProductIds } from '@/utils';
import { productMap } from '@/constants/seeded/products';

export default Vue.extend({
  name: 'add-edit-user',

  components: {
    AlertDisplay,
    Button,
    Drawer,
    FormSectionText,
    FormSpacer,
    Input,
    Label,
    TextLink,
    Multiselect,
    Radio,
    ToggleSwitch,
  },

  data() {
    return {
      resendInvitationAlertMessage: {
        type: 'critical',
        header: "We couldn't resend the invitation",
        message: 'Please try again in a few minutes',
      },
      resetPasswordAlertMessage: {
        type: 'critical',
        header: "We couldn't send the password reset email",
        message: 'Please try again in a few minutes',
      },
      resendInvitationStatus: null, // 'loading' | 'success' | 'error' | null
      resetPasswordStatus: null, // 'loading' | 'success' | 'error' | null
      isLoading: false,
      status: '',
      roleOptions: [
        {
          value: 2,
          label: 'Admin',
          sidetext: 'Manage users, subaccounts, planner, and all content',
        },

        {
          value: 3,
          label: 'Basic user',
          sidetext: 'View and manage all content',
        },
      ],
      statusOptions: [
        {
          value: 1,
          label: 'Active',
        },
        {
          value: 2,
          label: 'Disabled',
        },
      ],
      currentUser: JSON.parse(JSON.stringify(this.userData)),
      //starting point before current edits
      startingUser: JSON.parse(JSON.stringify(this.userData)),
      // Inline errors
      alertMessage: {},
      // alert block
      errorMessages: errorMessages,
      // Refactor values and labels
      audienceToggleValue: this.userData.products.find((product) => product.name === 'Audience') ? true : false,
      discoverToggleValue: this.userData.products.find((product) => product.name === 'Discover') ? true : false,
      createToggleValue: this.userData.products.find((product) => product.name === 'Create&Publish') ? true : false,
      audienceAriaLabel: 'Audience',
      discoverAriaLabel: 'Discover',
      createAriaLabel: 'Create&Publish',
    };
  },
  validations: {
    currentUser: {
      firstName: {
        required,
        $validateName,
      },
      lastName: {
        required,
        $validateName,
      },
      email: {
        required,
        $valEmail,
      },
      statusId: {
        required,
      },
      roleId: {
        required,
      },
      brands: {},
    },
  },

  computed: {
    ...mapGetters([
      'accountProducts',
      'allBrands',
      'allUsers',
      'authenticatedUser',
      'features',
      'getAccount',
      'isUserMultiBrand',
    ]),

    // CTA link if seats are at max
    seatsMaxedCTALink() {
      const ecommFeatureIsEnabled = this.features.includes('ecomm');
      if (ecommFeatureIsEnabled) {
        return '/subscriptions';
      }
      return 'https://content.futuricontentcloud.com/contact';
    },

    // Product toggle visibility map
    productToggleVisibility() {
      const result = {};
      productMap.forEach((seededProduct) => {
        const productIsEnabled =
          this.accountProducts.findIndex((accountProduct) => accountProduct.id === seededProduct.id) !== -1;
        result[seededProduct.referenceName] = productIsEnabled;
      });
      return result;
    },

    // Button text for `Resend invitation email`
    resendInvitationEmailButtonText() {
      if (this.resendInvitationStatus === 'success') {
        return 'Invitation email sent';
      }
      return 'Resend invitation email';
    },

    // Button text for `Reset Password Link`
    resetPasswordLinkButtonText() {
      if (this.resetPasswordStatus === 'success') {
        return 'Password reset email sent';
      }
      return 'Send password reset link';
    },

    // All active brands
    allActiveBrands() {
      return this.allBrands?.filter((brand) => brand.active) || [];
    },

    isCreateMode() {
      return !this.userData.id;
    },

    //is the user an admin and trying to edit their own profile
    userSelfEdit() {
      return this.userData.id && this.isCCAdmin && this.userData.email === this.authenticatedUser.email;
    },

    // Audience product is disabled
    audienceDisabled() {
      if (!this.productData['Audience'].active) {
        return true;
      } else {
        const startingUserHasAudience = this.startingUser.products.find((product) => product.name === 'Audience');
        const seatsAreMaxed = this.startingData['Audience'].currentSeats >= this.startingData['Audience'].maxSeats;
        if (!startingUserHasAudience && seatsAreMaxed) {
          return true;
        }
        return false;
      }
    },

    // Discover product is disabled
    discoverDisabled() {
      if (!this.productData['Discover'].active) {
        return true;
      } else {
        const startingUserHasDiscover = this.startingUser.products.find((product) => product.name === 'Discover');
        const seatsAreMaxed = this.startingData['Discover'].currentSeats >= this.startingData['Discover'].maxSeats;
        if (!startingUserHasDiscover && seatsAreMaxed) {
          return true;
        }
        return false;
      }
    },

    // Create product is disabled
    createDisabled() {
      if (!this.productData['Create&Publish'].active) {
        return true;
      } else {
        const startingUserHasDiscover = this.startingUser.products.find((product) => product.name === 'Create&Publish');
        const seatsAreMaxed =
          this.startingData['Create&Publish'].currentSeats >= this.startingData['Create&Publish'].maxSeats;
        if (!startingUserHasDiscover && seatsAreMaxed) {
          return true;
        }
        return false;
      }
    },

    // Product data
    productData() {
      const result = {};

      this.accountProducts.forEach((availableProduct) => {
        // Find a purchased product or return zeroes for seat counts
        const _availableProduct = this.getAccount.data?.products.find(
          (purchasedProduct) => purchasedProduct.name === availableProduct.name
        );
        result[availableProduct.name] = {
          active: _availableProduct?.active || false,
          currentSeats: _availableProduct?.currentSeats || 0,
          id: _availableProduct?.id || 0,
          maxSeats: _availableProduct?.maxSeats || 0,
          name: _availableProduct?.name || '',
        };
      });

      return result;
    },
    //maintain the starting data to compare available seats on the fly
    startingData() {
      const result = {};

      this.accountProducts.forEach((availableProduct) => {
        // Find a purchased product or return zeroes for seat counts
        const _availableProduct = this.getAccount.data?.products.find(
          (purchasedProduct) => purchasedProduct.name === availableProduct.name
        );
        result[availableProduct.name] = {
          active: _availableProduct?.active || false,
          currentSeats: _availableProduct?.currentSeats || 0,
          id: _availableProduct?.id || 0,
          maxSeats: _availableProduct?.maxSeats || 0,
        };
      });

      return result;
    },

    brandOptions() {
      if (this.allActiveBrands) {
        return [{ id: 0, name: 'All brands' }, ...this.allActiveBrands];
      }
      return []; // fallback, should almost never happen
    },

    isMultiBrandUser() {
      return isMultiBrand();
    },

    submit() {
      return {
        qa: this.$getComponent('AddEditUser', 'Button', 'sendInvite&SaveChanges'),
        buttonText: this.isCreateMode ? 'Send invitation' : 'Save changes',
        onClick: (hide) => this.saveUserData(hide),
      };
    },

    displayAlert() {
      return !!this.alertMessage.type;
    },

    isCCAdmin() {
      return isCCAdmin();
    },

    isPrimaryAccountAdmin() {
      return this.userData.email === this.authenticatedUser.adminEmail;
    },
  },

  methods: {
    ...mapActions(['fetchAccount', 'fetchUsers', 'currentUser/requestUser']),

    //  Resend invitation code
    async resendInvitationCode() {
      const { email, firstName } = this.currentUser;
      const _payload = {
        email,
        firstName,
      };
      // set loading status
      this.resendInvitationStatus = 'loading';
      // api request
      const res = await new UserService().resendInvitationEmail(_payload);
      // if not successful
      if (res.errors) {
        this.resendInvitationStatus = 'error';
        this.resendInvitationAlertMessage.message = res.errors[0];
      } else {
        // if successful
        this.resendInvitationStatus = 'success';
      }
    },

    //send reset password link
    async resetPasswordLink() {
      const payload = { email: this.currentUser.email };

      // set loading status
      this.resetPasswordStatus = 'loading';

      const res = await new UserService().sendResetPasswordLink(payload);

      if (res.errors) {
        this.resetPasswordStatus = 'error';
        this.resetPasswordAlertMessage.message = res.errors[0];
      } else {
        this.resetPasswordStatus = 'success';
      }
    },

    // Get remaining seats by product
    getRemainingSeats(product) {
      const { currentSeats, maxSeats } = this.productData[product];
      return Math.max(maxSeats - currentSeats, 0);
    },

    // Get seats copy
    getSeatsCopy(product) {
      const _remaining = this.getRemainingSeats(product);
      return `${maybePluralize(_remaining, 'seat')} remaining`;
    },

    // On brand select
    onBrandSelect(value) {
      this.currentUser['brands'] = value;
      this.$v.currentUser.brands.$touch;
    },

    onCancel(hide) {
      setTimeout(() => {
        this.$emit('cancel');
        hide();
      }, 500);
    },

    handleInputChange(e) {
      this.currentUser[e.target.name] = e.target.value;
    },
    handleRadioRole(e) {
      this.currentUser.roleId = e;
    },
    handleRadioStatus(e) {
      this.currentUser.statusId = e;
    },
    handleToggle(e) {
      const _ariaLabel = e.label; // To identify toggle
      if (_ariaLabel === 'Audience') {
        this.audienceToggleValue = !this.audienceToggleValue;
      }
      if (_ariaLabel === 'Discover') {
        this.discoverToggleValue = !this.discoverToggleValue;
      }
      if (_ariaLabel === 'Create&Publish') {
        this.createToggleValue = !this.createToggleValue;
      }

      // Add it if it doesn't exist
      if (!e.value) {
        this.productData[`${_ariaLabel}`].currentSeats = this.productData[`${_ariaLabel}`].currentSeats + 1;
        if (!this.currentUser.products.some(() => e.name === _ariaLabel)) {
          this.currentUser.products.push(this.productData[`${_ariaLabel}`]);
        }
      } else {
        // Remove it
        this.productData[`${_ariaLabel}`].currentSeats = this.productData[`${_ariaLabel}`].currentSeats - 1;
        this.currentUser.products = this.currentUser.products.filter((e) => e.name !== _ariaLabel);
      }
    },

    async saveUserData(hide) {
      this.isLoading = true;
      this.$v.currentUser.$touch();

      if (this.$v.currentUser.$invalid) {
        this.isLoading = false;
      } else {
        let response = {};

        // Add initial payload
        const payload = {
          ...this.currentUser,
          firstName: Vue.prototype.$toProperCase(this.currentUser.firstName.trim()),
          lastName: Vue.prototype.$toProperCase(this.currentUser.lastName.trim()),
          email: this.currentUser.email.trim(),
        };

        // Add brands to payload
        if (this.currentUser.brands.some((e) => e.name === 'All brands')) {
          payload.brands = this.allActiveBrands.map((e) => e.id);
        } else {
          if (this.isMultiBrandUser) {
            payload.brands = this.currentUser.brands.map((brand) => brand.id); // add 0 or more
          } else {
            payload.brands = this.allActiveBrands.map((e) => e.id); // add all, which should be 1 only
          }
        }

        // Add products to payload
        payload.products = getUniqueProductIds(this.currentUser.products);

        if (this.isCreateMode) {
          response = await new UserService()
            .create(payload)
            .then((res) => res)
            .catch((err) => err.response);
        } else {
          response = await new UserService()
            .edit(payload)
            .then((res) => res)
            .catch((err) => err.response);
        }

        // Temp, local dev only
        if (process.env.NODE_ENV === 'development') {
          console.log('Payload sent to BE', payload);
          console.log('Respones from BE', response);
        }
        this.isLoading = false;
        if (!response?.id) {
          const errors = response.errors;

          this.alertMessage = {
            type: 'critical',
            header: `We couldn't ${this.isCreateMode ? 'send the invitation' : 'save your changes'}.`,
            message: 'Please try again in a few minutes',
            links: errors.map((err) => ({ message: Vue.prototype.$errorPrettified(err) })),
          };
          this.isLoading = false;
          return;
        } else {
          //update activeUserBrands
          this.$store.dispatch('currentUser/requestUser');
          this.isLoading = false;
          this.fetchAccount();
          this.fetchUsers();
          this.onCancel(hide);
        }
      }
    },
  },

  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    userData: {
      type: Object,
      default: () => {
        // Pull this out and use here and in Users as default/reset state
        return {
          brands: [],
          email: '',
          first_name: '',
          id: 0,
          last_name: '',
          products: [],
          roleId: 2,
          statusId: 1,
        };
      },
    },
    // Functions
    createUser: Function,
    editUser: Function,
  },
  watch: {
    userData: function (newUserData) {
      this.$v.currentUser.$reset();
      // Inline errors
      this.alertMessage = {};
      // Set currentUser
      this.currentUser = JSON.parse(JSON.stringify(newUserData)); // deep clone, otherwise it's mutating userData
    },
  },
});
</script>

<style lang="scss" scoped>
.split {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  margin-bottom: 16px;
  .left {
    width: calc(50% - 12px);
  }
  .right {
    width: calc(50% - 12px);
  }
}

.component {
  margin-bottom: 32px;
}

.topmargin {
  margin-top: 8px;
}

.subscription-link {
  font-size: var(--fm-font-size-14);
  line-height: var(--fm-line-spacing-21);
  text-decoration: underline;
}

.fm-toggle-temp {
  display: flex;
  flex-direction: column;
  .toggle {
    display: flex;
    justify-content: space-between;
    margin: 10px 0px;
    align-items: center;
    div {
      display: flex;
      align-items: center;
      span {
        margin-left: 8px;
      }
    }
  }
}
</style>
