






















import { Vue, Component, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker';
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';
import moment from 'moment-timezone';

const audienceModule = namespace('AudienceAnalyticsStore');

const DATE_FORMAT_STRING = 'YYYY-MM-DD';

@Component({
  name: 'AudienceDateRangeSelector',
  components: {
    VueCtkDateTimePicker,
  },
})
export default class AudienceDateRangeSelector extends Vue {
  @audienceModule.Mutation('SET_DATE_RANGE') setDateRange;

  get timeZone() {
    // Use runtime default timeZone until a brand property is defined.
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  }

  dateRange = {
    start: moment().subtract(30, 'day').tz(this.timeZone).format(DATE_FORMAT_STRING),
    end: moment().tz(this.timeZone).format(DATE_FORMAT_STRING),
  };
  @Watch('dateRange', { immediate: true })
  onDateRangeChange({ start, end }) {
    if (!start || !end) return;
    this.setDateRange({ start, end });
  }

  get customShortcuts() {
    return [
      // { key: 'thisWeek', label: 'This week', value: 'isoWeek' },
      // { key: 'lastWeek', label: 'Last week', value: '-isoWeek' },
      { key: 'last7Days', label: 'Last 7 days', value: 7 },
      { key: 'last14Days', label: 'Last 14 days', value: 14 },
      { key: 'last30Days', label: 'Last 30 days', value: 30 },
      { key: 'last3Months', label: 'Last 3 months', value: this.lastThreeMonths },
      { key: 'last12Months', label: 'Last 12 months', value: this.lastTwelveMonths },
      { key: 'thisMonth', label: 'Month to date', value: this.monthToDate },
      // { key: 'lastMonth', label: 'Last month', value: '-month' },
      { key: 'quarterToDate', label: 'Quarter to date', value: this.quarterToDate },
      // { key: 'thisYear', label: 'This year', value: 'year' },
      // { key: 'lastYear', label: 'Last year', value: '-year' },
    ];
  }

  lastThreeMonths() {
    return {
      start: moment().tz(this.timeZone).subtract(3, 'month'),
      end: moment().tz(this.timeZone),
    };
  }

  lastTwelveMonths() {
    return {
      start: moment().tz(this.timeZone).subtract(12, 'month'),
      end: moment().tz(this.timeZone),
    };
  }

  monthToDate() {
    return {
      start: moment().tz(this.timeZone).startOf('month'),
      end: moment().tz(this.timeZone),
    };
  }

  quarterToDate() {
    return {
      start: moment().tz(this.timeZone).startOf('quarter'),
      end: moment().tz(this.timeZone),
    };
  }

  maxDate = moment().tz(this.timeZone).format(DATE_FORMAT_STRING);

  updateMaxDate() {
    this.maxDate = moment().tz(this.timeZone).format(DATE_FORMAT_STRING);
  }
}
