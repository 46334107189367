




import { Component, Prop, Vue } from 'vue-property-decorator';

import { BreadcrumbProps } from './types';

@Component({})
export default class Breadcrumb extends Vue {
  @Prop({ default: 'NA', required: true }) qa!: BreadcrumbProps['qa'];
  @Prop({ default: [], required: true }) items!: BreadcrumbProps['items'];
}
