// See /documentation/MediaUploads.md for documentation

import { handleAjax } from '@/createandpublish/core/util';
import CreateAndPublishMediaService from '@/services/CreateAndPublishMediaService';
import { Module } from 'vuex';
import { MediaUploadType, MediaUploadMetadata } from '@/types/createandpublish/mediaLibraries';

const MediaService = new CreateAndPublishMediaService();

function generateFormData(uploadObj) {
  const formData = new FormData();
  for (const [key, value] of Object.entries(uploadObj)) {
    if (value == null) continue; // value is nullish

    if (key === 'file' && value instanceof File) {
      formData.append(key, value, value.name);
    } else if (Array.isArray(value)) {
      value.forEach((x) => {
        formData.append(key, x);
      });
    } else {
      // Any other type not a file or an array
      formData.append(key, value as string);
    }
  }

  return formData;
}

export default {
  namespaced: true,

  state: {
    uploadType: undefined,
    mediaUploads: [],
  },

  getters: {
    progress(state) {
      const length = state.mediaUploads.length;
      if (length === 0) {
        // Avoid dividing by 0 below.
        return {
          upload_progress: 0,
          encode_progress: 0,
        };
      }

      const uploadProgressReducer = (acc: number, mediaUpload: MediaUploadMetadata) => {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        return acc + mediaUpload.upload_progress!;
      };
      const encodeProgressReducer = (acc: number, mediaUpload: MediaUploadMetadata) => {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        return acc + mediaUpload.encode_progress!;
      };
      const uploadAverage = state.mediaUploads.reduce(uploadProgressReducer, 0) / length;
      const encodeAverage = state.mediaUploads.reduce(encodeProgressReducer, 0) / length;
      // encode_progress only relevant for audio uploads.
      return {
        upload_progress: uploadAverage,
        encode_progress: encodeAverage,
      };
    },

    isUploading(state) {
      return (
        !!state.mediaUploads.length &&
        state.mediaUploads.some((upload) => !(upload.success && upload.asset_id) && !upload.failed)
      );
    },

    areAllUploadsComplete(state) {
      return (
        !!state.mediaUploads.length &&
        state.mediaUploads.every((upload) => (upload.success && upload.asset_id) || upload.failed)
      );
    },

    /**
     * Returns an array of resource_uris for all successful audio uploads marked as single_use.
     * @param {Object} state vuex injected state
     * @returns {Array<string>}
     */
    singleUseEventUris(state, getters) {
      if (state.uploadType !== 'audio') return [];

      return state.mediaUploads
        .filter((upload) => upload.single_use && upload.asset_id && upload.success)
        .map((upload) => `/${getters.stationName}/api/v1/event/${upload.asset_id}/`);
    },

    /**
     * Returns an array of resource_uris for all successful audio uploads not marked as single_use.
     * That is, they are intended to be added to the audio library.
     * @param {Object} state vuex injected state
     * @returns {Array<string>}
     */
    mediaLibraryEventUris(state, getters) {
      if (state.uploadType !== 'audio') return [];

      return state.mediaUploads
        .filter((upload) => !upload.single_use && upload.asset_id && upload.success)
        .map((upload) => `/${getters.stationName}/api/v1/event/${upload.asset_id}/`);
    },

    /**
     * Returns an array of media uploads that have failed.
     * @param {Object} state vuex injected state
     * @returns {Array<Object>}
     */
    failedMediaUploads(state) {
      return state.mediaUploads
        .filter((upload) => upload.failed)
        .map(({ uuid, title, err_msg }) => ({ uuid, title, err_msg }));
    },

    /**
     * Returns an array of UUIDs and lastUpdated timestamps for uploads that haven't
     * yet received a success or failure message.
     * @param {Object} state vuex injected state
     * @returns {Array<Object>}
     */
    lastUpdatedMediaUploads(state) {
      return state.mediaUploads
        .filter((upload) => !upload.success && !upload.failed)
        .map(({ uuid, lastUpdated }) => ({ uuid, lastUpdated }));
    },

    stationName(_state, _getters, rootState) {
      return rootState.CreateAndPublishStore.settings.station_name;
    },
  },

  mutations: {
    SET_UPLOAD_TYPE(state, uploadType: MediaUploadType) {
      state.uploadType = uploadType;
    },

    CLEAR_UPLOAD_TYPE(state) {
      state.uploadType = undefined;
    },

    SET_NEW_MEDIA_UPLOAD(state, { uuid, ...args }: MediaUploadMetadata) {
      state.mediaUploads.push({
        uuid,
        upload_progress: 0,
        encode_progress: 0, // Only relevant to audio uploads.
        location: '', // resource_uri
        success: 0,
        failed: 0,
        lastUpdated: new Date().getTime(),
        ...args,
      });
    },

    UPDATE_MEDIA_UPLOAD(state, progressEvent: MediaUploadMetadata) {
      const { uuid } = progressEvent;
      const index = state.mediaUploads.findIndex((event) => event.uuid === uuid);
      if (index !== -1) {
        const updatedUploadEvent: MediaUploadMetadata = {
          ...state.mediaUploads[index],
          ...progressEvent,
          lastUpdated: new Date().getTime(),
        };
        state.mediaUploads.splice(index, 1, updatedUploadEvent);
      }
    },

    CLEAR_MEDIA_UPLOAD(state, uuid: string) {
      const index = state.mediaUploads.findIndex((upload) => upload.uuid === uuid);
      if (index !== -1) {
        state.mediaUploads.splice(index, 1);
      }
    },

    CLEAR_ALL_MEDIA_UPLOADS(state) {
      state.mediaUploads = [];
    },
  },

  actions: {
    async uploadMedia({ dispatch, commit, state, rootGetters }, uploadObj: MediaUploadMetadata) {
      // Initialize new upload before ajax call because we will start receiving
      // SSE progress events before a response is received.
      commit('SET_NEW_MEDIA_UPLOAD', uploadObj);
      const uploadType = state.uploadType;
      const formData = generateFormData(uploadObj);
      if (!formData.has('description')) {
        formData.set('description', ''); // Hard-coded for now
      }

      handleAjax({
        request: MediaService.addMediaLibraryItem(uploadType, formData),
        dispatch,
        commit,
        callback(err) {
          const { uuid } = uploadObj;
          if (err) {
            // Update mediaUploads in state with error message, if necessary.
            const index = state.mediaUploads.findIndex((mediaUpload) => mediaUpload.uuid === uuid);
            if (index !== -1) {
              let reason = '';
              if (err instanceof Error) {
                reason = 'An unknown error occurred';
              } else {
                reason = `Server status: ${err.status}`;
              }
              const mediaUpload = state.mediaUploads[index];
              // if failed is already 1, we received a SSE event for this upload, so prefer
              // existing error message. If not, we create one ourselves.
              const err_msg = mediaUpload.failed
                ? mediaUpload.err_msg
                : {
                    service: 'frontend',
                    url: `${process.env.VUE_APP_API_URL}/createandpublish/${rootGetters['selectedBrand'].id}/media/${uploadType}`,
                    reason,
                  };
              const progressEvent: MediaUploadMetadata = {
                uuid,
                failed: 1,
                err_msg,
              };
              commit('UPDATE_MEDIA_UPLOAD', progressEvent);
            }
          }
        },
      });
    },
  },
} as Module<S, R>;

/**
 * Module state
 */
interface S {
  uploadType: MediaUploadType;
  mediaUploads: MediaUploadMetadata[];
}

/**
 * Vuex store rootState
 */
interface R {
  // Define rootState properties here, as necessary.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  CreateAndPublishStore: any;
}
