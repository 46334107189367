<template>
  <div>
    <b-button id="popover-target-1" class="button"> Dev Tools </b-button>
    <b-popover target="popover-target-1" triggers="hover" placement="top">
      <template #title>Dev Tools</template>
      <ul style="padding-left: 40px">
        <template v-for="feature in allFeatures">
          <li :key="feature">
            <button class="feature-button" v-on:click="toggleFeature(feature)">
              {{ features.includes(feature) ? 'O' : 'X' }}
            </button>
            {{ feature }}
          </li>
        </template>
      </ul>
      <div v-if="hasBeenEdited">You've edited the feature flags. Log out & back in to see current DB state.</div>
    </b-popover>
  </div>
</template>

<script>
import { Vue } from 'vue-property-decorator';
import { mapGetters, mapActions } from 'vuex';
const DevTools = Vue.extend({
  data() {
    return {
      // we should move hasBeenEdited to global state because it doesn't persist on refresh
      hasBeenEdited: false,
    };
  },
  computed: {
    ...mapGetters(['features', 'allFeatures']),
  },
  lastUpdate: '',
  methods: {
    ...mapActions(['setFeatures']),
    toggleFeature(toggledFeature) {
      this.hasBeenEdited = true;
      let updatedFeatures = [];
      this.allFeatures.forEach((feature, i) => {
        let enabled = feature === this.features[i] ? !(toggledFeature === feature) : toggledFeature === feature;
        updatedFeatures.push({ name: feature, enabled });
      });
      this.setFeatures(updatedFeatures);
    },
  },
});
export default DevTools;
</script>

<style scoped lang="scss">
#popover-target-1 {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  color: #000;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857143;
  margin-bottom: 0;
  margin-top: 0;
  padding: 6px 12px;
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
  width: 100%;
}

.button {
  margin-left: 16px;
}

.feature-button {
  padding: 2px 4px;
  background-color: lightgrey;
  border-style: solid;
}
</style>
