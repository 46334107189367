



































import { Vue } from 'vue-property-decorator';
export default Vue.extend({
  props: {
    getAdmin: String,
    isAccountOwner: Boolean,
  },
});
