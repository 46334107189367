import Vue from 'vue';
const wm = new WeakMap();

export default {
  bind(el, binding, vnode) {
    const inputHandler = (event) => {
      const resolvePath = (object, path) => {
        const splitPath = path.split(/[\.\[\]\'\"]/)  // eslint-disable-line
        const filteredPath = splitPath.filter((p) => p);
        const length = filteredPath.length;
        const lastInPath = filteredPath.pop();
        const reducedPath = filteredPath.reduce((o, p) => o[p], object);
        return { reducedPath, lastInPath, length };
      };

      const { reducedPath, lastInPath, length } = resolvePath(vnode.context, binding.expression);
      if (length > 1) {
        Vue.set(reducedPath, lastInPath, event.target.value);
      } else {
        // don't use set() on base of VueComponent
        vnode.context[lastInPath] = event.target.value;
      }
    };
    wm.set(el, inputHandler);
    el.value = binding.value;
    el.addEventListener('input', inputHandler);
  },

  componentUpdated(el, binding) {
    el.value = binding.value;
  },

  unbind(el) {
    const inputHandler = wm.get(el);
    el.removeEventListener(el, inputHandler);
  },
};
