




import { Component, Vue } from 'vue-property-decorator';

@Component({
  name: 'SocialStoriesRoute',
})
export default class SocialStoriesRoute extends Vue {}
