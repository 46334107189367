












































import Vue from 'vue';
import moment from 'moment';
import Table from '@/components/common/table/Table.vue';
import TableCell from '@/components/common/table/TableCell.vue';
import TableHeaderCell from '@/components/common/table/TableHeaderCell.vue';
import Pagination from '@/components/common/table/Pagination.vue';
import ChargifyService from '@/services/ChargifyService';
import Loading from '@/components/common/Loading.vue';
import { mapGetters } from 'vuex';
import { Invoice } from '@/types/ecomm/InvoiceResponse';
import { formatCurrency } from '@/utils';

export default Vue.extend({
  name: 'billing-history-table',

  components: {
    Table,
    TableCell,
    TableHeaderCell,
    Pagination,
    Loading,
  },

  props: {},
  computed: {
    ...mapGetters(['authenticatedUser']),
  },
  data() {
    let billingHistoryData: Invoice[] = [];
    return {
      sortBy: 'Date',
      sortDirection: 'desc',
      currentPage: 1,
      currentPageSize: 10,
      paginationOptions: [
        {
          size: 5,
        },
        {
          size: 10,
        },
        {
          size: 15,
        },
      ],
      billingHistoryData,
      totalItems: 0,
      isLoading: false,
    };
  },

  mounted() {
    this.makeInvoiceRequest();
  },
  watch: {
    currentPage() {
      this.makeInvoiceRequest();
    },
    currentPageSize() {
      this.makeInvoiceRequest();
    },
    sortBy() {
      this.makeInvoiceRequest();
    },
    sortDirection() {
      this.makeInvoiceRequest();
    },
  },

  methods: {
    getTotalAmount(invoice: Invoice) {
      return formatCurrency(invoice.total_amount, invoice.currency);
    },
    getMoment(date: string) {
      return moment(date).format('MMM DD, YYYY');
    },
    makeInvoiceRequest() {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;
      const service = new ChargifyService();

      // Preferred implementation
      service
        .getInvoices(this.currentPage, this.currentPageSize, this.getSortBy(), this.sortDirection === 'asc' ? '+' : '-')
        .then((response) => {
          this.billingHistoryData = response.content;
          this.totalItems = response.totalItems;
          this.isLoading = false;
        });
    },

    getSortBy() {
      switch (this.sortBy) {
        case 'Date':
          return 'issue_date';
        default:
          return 'issue_date';
      }
    },

    openPdfInNewTab(url: string) {
      window.open(url, '_blank');
    },

    handleSort(type) {
      if (this.sortBy === type) {
        this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
      } else {
        this.sortBy = type;
        this.sortDirection = 'asc';
      }
    },

    handlePageUpdate(e: number) {
      this.currentPage = e;
    },

    handlePageSizeUpdate(e) {
      this.currentPageSize = parseInt(e.target.value);
    },
  },
});
