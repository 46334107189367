








import Vue from 'vue';
import { Tweet } from 'vue-tweet-embed';
import Loading from '@/components/common/Loading.vue';

export default Vue.extend({
  name: 'TweetCard',
  props: {
    tweetId: String,
  },
  components: {
    Tweet,
    Loading,
  },
});
