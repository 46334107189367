<template>
  <div id="wrapper">
    <Header v-if="!isAudienceFlow" />
    <AudienceCheckout v-if="step === 1 && isAudienceFlow" :data="data" @success="checkoutSuccess" />
    <GetStarted v-if="step === 1 && !isAudienceFlow" :data="data" @success="getStartedSuccess" :trial="trial" />
    <Checkout
      v-if="step === 2 && !isAudienceFlow"
      :emailAddress="emailAddress"
      @success="checkoutSuccess"
      :isTrial="trial"
    />
    <Confirmation
      v-if="step === 3"
      :data="data"
      :trial="trial"
      :audience="isAudienceFlow"
      :isCheckout="!trial && !isAudienceFlow"
      :emailAddress="emailAddress"
    />
  </div>
</template>

<script>
import Vue from 'vue';
import AudienceCheckout from '@/components/ecomm/AudienceCheckout.vue';
import Checkout from '@/components/ecomm/Checkout.vue';
import Confirmation from '@/components/ecomm/Confirmation.vue';
import GetStarted from '@/components/ecomm/GetStarted.vue';
import Header from '@/components/ecomm/Header.vue';

export default Vue.extend({
  name: 'checkout',

  components: {
    AudienceCheckout,
    Header,
    GetStarted,
    Checkout,
    Confirmation,
  },

  props: {
    trial: {
      type: Boolean,
      default: false,
    },
  },

  metaInfo: {
    htmlAttrs: { class: 'app-responsive' },
    meta: [{ name: 'viewport', content: `width=device-width, initial-scale=1` }],
  },

  data() {
    return {
      step: 1,
      data: {},
      emailAddress: '',
    };
  },

  computed: {
    isAudienceFlow() {
      return this.$route.meta?.flow === 'audience';
    },
  },

  methods: {
    getStartedSuccess(data) {
      this.step = 2;
      this.emailAddress = data.emailAddress;
    },
    checkoutSuccess(data) {
      this.step = 3;
      this.data = data;
    },
  },

  beforeMount() {
    const queryParams = this.$route.query;
    if (queryParams.emailAddress) {
      this.step = 2;
      this.emailAddress = queryParams.emailAddress;
    }
  },
});
</script>

<style lang="scss" scoped>
#wrapper {
  background: #fcfcfc;
  min-height: 100%;
  display: flex;
  flex-direction: column;
}
</style>
