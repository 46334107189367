







import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  components: {},
})
export default class ErrorMessage extends Vue {
  @Prop({ default: '', required: true }) error!: string;
}
