<template>
  <a
    v-if="iHeartStatus !== 0"
    :class="['iheart-link', { 'move-down': playlist.video_status || playlist.advanced_video_status }]"
    target="_blank"
    :href="externalUrl"
    :title="displayTitle"
    :aria-disabled="iHeartStatus !== 2"
    :aria-role="iHeartStatus === 2 ? 'link' : 'button'"
    @click="onIconClick"
  >
    <img :src="displayIcon" class="iheart-link__image" alt="Link to episode on i heart" />
  </a>
</template>

<script>
import progressIcon from '@/assets/createandpublish-assets/show-details/iheart-logo-grey.svg';
import failureIcon from '@/assets/createandpublish-assets/show-details/iheart-logo-red.svg';
import successIcon from '@/assets/createandpublish-assets/show-details/iheart-logo.svg';

export default {
  name: 'i-heart-button',

  props: {
    playlist: { type: Object, required: true },
  },

  computed: {
    iHeartStatus() {
      return this.playlist.iheart_status;
    },

    externalUrl() {
      return this.playlist.external_url || '#';
    },

    displayIcon() {
      switch (this.iHeartStatus) {
        case 1:
          return progressIcon;
        case 2:
          return successIcon;
        case 99:
          return failureIcon;
        case 0:
        default:
          return '';
      }
    },

    displayTitle() {
      switch (this.iHeartStatus) {
        case 1:
          return 'Waiting on iHeart to ingest episode';
        case 2:
          return 'Open in iHeart';
        case 99:
          return 'A problem occurred during iHeart ingestion';
        case 0:
        default:
          return '';
      }
    },
  },

  methods: {
    onIconClick(e) {
      if (this.iHeartStatus !== 2) {
        e.preventDefault();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.iheart-link {
  display: block;
  position: relative;
  width: 24px;
  height: 24px;

  &:focus {
    outline: 4px solid #00c6c680 !important;
  }

  &:focus:not(.focus-visible) {
    outline: none !important;
  }

  &:focus-visible {
    outline: 4px solid #00c6c680 !important;
  }

  &.move-down {
    top: 6px;
  }

  &__iamge {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
</style>
