





















































































import { Component, Prop, Vue } from 'vue-property-decorator';
import Tabs from '@/components/common/tabs/Tabs.vue';
import Loading from '@/components/common/Loading.vue';
import TweetCard from '@/components/discover/cards/TweetCard.vue';
import { Story, GenderClass } from '@/types/discover/Story';
import { Line as LineChart } from 'vue-chartjs/legacy';
import type { ChartData, ChartOptions } from 'chart.js';
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
} from 'chart.js';

ChartJS.register(Title, Tooltip, Legend, LineElement, CategoryScale, LinearScale, PointElement);

@Component({
  components: {
    Tabs,
    Loading,
    TweetCard,
    LineChart,
  },
})
export default class TrendingStoryDrawerTabs extends Vue {
  @Prop() engagement!: EngagementData;
  @Prop() story!: Story;

  trendingGrowthData: number[] = [];
  trendingGrowthLabels: string[] = [];

  get chartConfig() {
    return {
      chartId: this.story.storyId.toString(),
      datasetIdKey: this.story.title,
      plugins: [],
      cssClasses: '',
      styles: {
        width: '100%',
        height: 'auto',
      },
    };
  }

  get chartOptions(): ChartOptions {
    return {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        y: {
          // This will probably take some tweaking (at a later date)
          min: 0,
          ticks: {
            stepSize: 1,
          },
        },
      },
    };
  }

  get chartData(): ChartData {
    return {
      labels: this.trendingGrowthLabels,
      datasets: [
        {
          label: 'Growth',
          backgroundColor: '#0174b7',
          borderColor: '#0174b7',
          data: this.trendingGrowthData,
        },
      ],
    };
  }

  renderChart() {
    if (this.trendingGrowthData.length === 0) {
      const lineData = this.story.history;

      for (const point of lineData) {
        this.trendingGrowthData.push(this.formatGrowth(point.social));
        this.trendingGrowthLabels.push(this.formatLabel(point.timestamp));
      }
    }
  }

  formatLabel(timestamp) {
    const time = new Date(timestamp);
    const label = time.toLocaleString('en-US', { hour: '2-digit', minute: '2-digit', hour12: false });
    return label;
  }

  formatGrowth(point) {
    const mPoint = Math.abs(point);
    const million = 100000000;
    const score = (mPoint * 100) / million;
    return score;
  }

  formatSocialReach(socialReach) {
    return socialReach.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
}

interface EngagementData {
  gender: GenderClass;
  age: string;
  sources: number;
  social: number;
}
