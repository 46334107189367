



































































import { Component, Vue, Prop } from 'vue-property-decorator';
import Info from '@/components/ecomm/Info.vue';
import Billing from '@/components/ecomm/Billing.vue';
import SubscriptionSummary from '@/components/ecomm/SubscriptionSummary.vue';
import SubscriptionTotal from '@/components/ecomm/SubscriptionTotal.vue';
import Loading from '@/components/common/Loading.vue';
import ChargifyService from '@/services/ChargifyService';
import Button from '@/components/common/buttons/Button.vue';
import AlertDisplay from '@/components/common/AlertDisplay.vue';

// enums
import { ProductHandle, ProductKey } from '@/types/ecomm';
import type { PlansData, SubscriptionResponse } from '@/types/ecomm';
import { getComponentsPayload, SubscriptionData } from '@/utils/ecomm';

Component.registerHooks(['beforeMount']);

@Component({
  name: 'Checkout',
  components: {
    Info,
    Billing,
    SubscriptionSummary,
    SubscriptionTotal,
    Loading,
    Button,
    AlertDisplay,
  },
})
export default class Checkout extends Vue {
  @Prop({ type: String, required: true }) emailAddress!: string;
  @Prop({ type: Boolean, default: false }) isTrial!: boolean;

  alertMessage: { type: string; header: string; message: string } | null = null;
  subscriptionData = {
    address: '',
    address2: '',
    // change to match current feature flag setup
    app: ProductKey.AUDIENCE,
    audienceProfileName: '',
    brands: 1,
    chargifyJsToken: '',
    city: '',
    companyName: '',
    country: '',
    create: 1,
    discover: 1,
    emailAddress: '',
    firstName: '',
    lastName: '',
    state: '',
    zip: '',
    receipt: null as SubscriptionResponse | null,
  };
  displayAlert = false;
  formIsLoading = false;
  isLoading = true;
  plans: PlansData | null = null;
  showBilling = false;
  showTotal = true;
  showCompletePurchase = false;

  get formTitleText() {
    const baseText = "Let's set up your ";
    const flowType = this.isTrial ? 'free trial' : 'subscription'; // Change if more than 2
    return baseText + flowType;
  }

  infoSuccess(data: Checkout['subscriptionData']) {
    Object.assign(this.subscriptionData, data);
    if (this.isTrial) {
      this.completePurchase();
    } else {
      this.showBilling = true;
    }
  }
  billingSuccess(data: Checkout['subscriptionData']) {
    Object.assign(this.subscriptionData, data);
    this.showCompletePurchase = true;
  }
  updateSubscriptionSummary(data: Checkout['subscriptionData']) {
    Object.assign(this.subscriptionData, data);
  }
  updateSubscriptionTotal(data: Checkout['subscriptionData']) {
    Object.assign(this.subscriptionData, data);
  }
  subscriptionEditState(showTotal: boolean) {
    this.showTotal = showTotal;
  }
  async completePurchase() {
    if (this.formIsLoading) {
      return;
    }

    this.formIsLoading = true;
    this.displayAlert = false;

    let payload;

    if (this.isTrial) {
      payload = this.getTrialPayload();
    } else {
      payload = this.getSubscriptionPayload();
    }

    if (!payload) {
      const header = `We couldn't set up your ${this.isTrial ? 'trial' : 'subscription'}`;
      const message = "We couldn't find a plan that matched your selection.";
      this.setAlertMessage(header, message);
      return;
    }

    const service = new ChargifyService();
    // TODO: Fix HTTP service to not be stupid.
    interface ErrorResponse {
      errors?: string[];
    }
    const response = this.isTrial ? await service.createTrial(payload) : await service.createSubscription(payload);

    // show errors if any
    if (typeof response === 'string' || (response as unknown as ErrorResponse)?.errors) {
      const header = `We couldn't set up your ${this.isTrial ? 'trial' : 'subscription'}`;
      const message = (response as unknown as ErrorResponse)?.errors?.join(`<br /> \n`);
      this.setAlertMessage(header, message);
    } else {
      this.subscriptionData.receipt = response;
      this.$emit('success', this.subscriptionData);
    }
    this.formIsLoading = false;
  }

  setAlertMessage(header?: string, message?: string) {
    const defaultHeader = `We couldn't set up your ${this.isTrial ? 'trial' : 'subscription'}`;
    const defaultMessage = 'There was an error while processing your request';
    this.alertMessage = {
      type: 'critical',
      header: header ?? defaultHeader,
      message: message ?? defaultMessage,
    };
    this.displayAlert = true;
  }

  async getPlansAndPrices() {
    const service = new ChargifyService();
    const plans = await service.getPrices();
    if (!plans) {
      const signUpType = this.isTrial ? 'trial' : 'subscription';
      const header = `We are unable to set up your ${signUpType}`;
      const message = `There was a server error fetching the available ${signUpType}s.`;
      this.setAlertMessage(header, message);
      return null;
    }
    return plans;
  }

  get selectedSubscriptionData(): SubscriptionData {
    let productHandle: ProductHandle;
    const productKey = this.subscriptionData.app;

    switch (productKey) {
      case ProductKey.DISCOVER:
        productHandle = ProductHandle.DISCOVER_MONTHLY;
        break;
      case ProductKey.CREATE_AND_PUBLISH:
        productHandle = ProductHandle.CREATE_MONTHLY;
        break;
      case ProductKey.SUITE:
        productHandle = ProductHandle.SUITE_MONTHLY;
        break;
      case ProductKey.AUDIENCE:
      default:
        productHandle = ProductHandle.AUDIENCE;
        break;
    }

    const { discover: selectedDiscoverSeats, create: selectedCreateSeats, brands } = this.subscriptionData;

    const includeDiscoverComponents = [ProductKey.DISCOVER, ProductKey.SUITE].includes(productKey);
    const includeCreateComponents = [ProductKey.CREATE_AND_PUBLISH, ProductKey.SUITE].includes(productKey);

    const discoverSeatQuantity = includeDiscoverComponents ? selectedDiscoverSeats : 0;
    const createSeatQuantity = includeCreateComponents ? selectedCreateSeats : 0;

    const audienceBrandQuantity = brands;
    const discoverBrandQuantity = includeDiscoverComponents ? brands : 0;
    const createBrandQuantity = includeCreateComponents ? brands : 0;

    return {
      productKey,
      productHandle,
      audienceSeatQuantity: Math.max(3, discoverSeatQuantity, createSeatQuantity),
      discoverSeatQuantity,
      createSeatQuantity,
      audienceBrandQuantity,
      discoverBrandQuantity,
      createBrandQuantity,
    };
  }

  getSubscriptionPayload() {
    const plans = this.plans;
    if (!plans) return null;

    const {
      firstName,
      lastName,
      emailAddress,
      zip,
      state,
      companyName,
      country,
      city,
      address2,
      address,
      chargifyJsToken,
      audienceProfileName,
    } = this.subscriptionData;

    const productKey = this.subscriptionData.app;
    let productHandle: ProductHandle;

    switch (this.subscriptionData.app) {
      case 'audience':
        productHandle = ProductHandle.AUDIENCE;
        break;
      case 'discover':
        productHandle = ProductHandle.DISCOVER_MONTHLY;
        break;
      case 'create':
        productHandle = ProductHandle.CREATE_MONTHLY;
    }

    const productId = plans?.[productKey]?.products?.find((product) => product.handle === productHandle)?.id;
    if (!productId) return null;

    const components = getComponentsPayload(plans, this.selectedSubscriptionData);

    return {
      address,
      address2,
      audienceProfileName,
      chargifyJsToken,
      city,
      companyName,
      components,
      country,
      emailAddress,
      firstName,
      lastName,
      productId,
      state,
      zip,
    };
  }
  getTrialPayload() {
    const id = this.$route.query.offer;
    const offer = id ? { offerId: id } : {};
    const { companyName, emailAddress, firstName, lastName, audienceProfileName } = this.subscriptionData;
    return {
      companyName,
      emailAddress,
      firstName,
      lastName,
      audienceProfileName,
      ...offer,
    };
  }

  async beforeMount() {
    if (!this.isTrial) {
      const priceResponse = await this.getPlansAndPrices();
      this.plans = priceResponse?.plans ?? null;
    }

    this.subscriptionData.emailAddress = this.emailAddress;

    this.isLoading = false;
  }
}
