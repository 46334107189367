import { render, staticRenderFns } from "./SocialAccountsAndProfileTab.vue?vue&type=template&id=9504419a&scoped=true&"
import script from "./SocialAccountsAndProfileTab.vue?vue&type=script&lang=js&"
export * from "./SocialAccountsAndProfileTab.vue?vue&type=script&lang=js&"
import style0 from "./SocialAccountsAndProfileTab.vue?vue&type=style&index=0&id=9504419a&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9504419a",
  null
  
)

export default component.exports