


















import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({
  name: 'TikTokIcon',
})
export default class TikTokIcon extends Vue {
  @Prop({ type: String, required: false, default: '#25F3ED' }) fill!: string;
  @Prop({ type: Number, required: false, default: 40 }) height!: number;
  @Prop({ type: Number, required: false, default: 35 }) width!: number;
  @Prop({ type: String, required: false, default: 'TikTok logo' }) title!: string;
}
