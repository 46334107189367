









































import { Component, Vue, Prop } from 'vue-property-decorator';
import GenericAvatarImage from '@/assets/audience/generic-avatar.svg';
import { formatPercentChange } from '@/audience/data/chart-plugins/util';
import type { LeaderboardRankProfile } from '@/types/audience';

@Component({
  name: 'LeaderboardRankingListItem',
  filters: {
    formatPercentChange,
  },
})
export default class LeaderboardRankingListItem extends Vue {
  @Prop({ type: Object, required: true }) rankData!: LeaderboardRankProfile;
  @Prop({ type: Number, default: NaN }) selectedUserId!: number;

  onShowUserDetails(id: number) {
    this.$emit('onShowUserDetails', id);
  }

  onImgError($event: Event) {
    const imageEl = $event.target as HTMLImageElement;
    imageEl.src = GenericAvatarImage;
  }
}
