











import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { Getter } from 'vuex-class';

import SwipeRevealSubHeading from '@/utils/gestures/subHeader.vue';
import AudienceDateRangeSelector from '@/audience/components/DateRangeSelector.vue';
import BrandsSubHeader from '@/components/brands/BrandsSubHeader.vue';

import type { Brand } from '@/types/Brand';

Component.registerHooks(['mounted', 'beforeDestroy']);

@Component({
  name: 'PortalSubHeader',
  components: {
    AudienceDateRangeSelector,
    BrandsSubHeader,
  },
  mixins: [SwipeRevealSubHeading],
})
export default class PortalSubHeader extends Mixins(SwipeRevealSubHeading) {
  @Prop({ type: Boolean, required: true }) isAudienceRoute!: boolean;
  @Getter('userBrands') brands!: Brand[];

  get showAudienceDateRangePicker() {
    return this.isAudienceRoute && !this.$route.path.includes('leaderboard') && !this.$route.path.includes('community');
  }

  get showBrandSubheader() {
    return this.brands.length > 1;
  }

  get getWrapperClasses() {
    return [
      'sub-header-wrapper',
      {
        audience: this.isAudienceRoute,
        'align-right': !this.showBrandSubheader,
      },
    ];
  }

  setupPulldownListeners() {
    const pulldownHandle = this.$el as HTMLElement;
    this.$setupSwipeEvents(pulldownHandle);
  }

  async tryResizeSubHeadingSwipeHandler() {
    await new Promise((resolve) => setTimeout(resolve, 200));
    this.$resize();
    this.$changeState(1);
  }

  @Watch('$route')
  onRouteChange() {
    if (!this.swipeGestureData?.swipeHandle) return;
    this.$changeState(1); // reset sub-header if it's in down position
  }

  mounted() {
    window.addEventListener('resize', this.tryResizeSubHeadingSwipeHandler);
    this.setupPulldownListeners();
  }

  beforeDestroy() {
    window.removeEventListener('resize', this.tryResizeSubHeadingSwipeHandler);
    this.$teardownSwipeEvents();
  }
}
