<template>
  <img
    class="post-loading-spinner"
    src="@/assets/createandpublish-assets/menu/post-symbol.svg"
    alt="Loading..."
    title="Loading..."
  />
</template>

<script>
export default {
  name: 'LoadingSpinner',
};
</script>

<style lang="scss" scoped>
.post-loading-spinner {
  width: 70px;
  height: 70px;
  margin: 0.5rem;
  -webkit-animation: spin 2s linear infinite;
  -moz-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}
@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
</style>
