import moment from 'moment-timezone';
import store from '@/store';
import Vue from 'vue';

import { handleAjax, handleError } from '@/createandpublish/core/utils/handleAjax';
export { handleAjax, handleError };

import { urlStation, stationBase, stationsApi, STATION_REGEX } from './shared';
export { urlStation, stationBase, stationsApi, STATION_REGEX };

export const currentStation = () => {
  const m = STATION_REGEX.exec(location.pathname || '/');
  return m.length ? m[1] : '';
};

export const defaultDateRange = (dateRange = null) => {
  const DEFAULT_SPAN_HOURS = 5;
  const now = moment();
  const fiveHoursAgo = now.clone().subtract(DEFAULT_SPAN_HOURS, 'hours');

  let range = dateRange;
  if (!range || range.length !== 2) {
    range = [null, null];
  }
  let [start, end] = range;

  if (!start && !end) {
    [start, end] = [fiveHoursAgo, now];
  } else if (end && !start) {
    start = moment(end).subtract(DEFAULT_SPAN_HOURS, 'hours');
  } else if (start && !end) {
    end = moment(start).add(DEFAULT_SPAN_HOURS, 'hours');
  }

  return [start, end];
};

// Group colors
export const getGroupColor = (groupData, cat) => {
  for (const group of groupData.objects) {
    for (const groupCat of group.categories) {
      if (groupCat.name === cat) {
        return group.color;
      }
    }
  }

  return 'color-null';
};

/**
 * Get talk break title (quick hack of a reusable function)
 * @param event (event object)
 * @param category (category the event belongs to)
 * @param showData (shows object array - getter allShows)
 * @returns {*}
 */
export const getTalkBreakTitle = (event, category, showData) => {
  const artist = event.cart.artist ? `${event.cart.artist} - ` : '';
  let title = `${artist}${event.cart.title}`;

  if (title.length > 0) {
    // if it has a title, use it
    return title;
  } else if (category !== 'Talk') {
    // if it has no title and is not a talk break, leave blank
    return title;
  } else {
    // is a talk break with no title
    // interpolate title from show name in show schedule
    if (!showData) return title;
    const timeZone = store.getters.settings.timezone;
    const values = Object.values(showData);
    values.forEach((show) => {
      const eventDate = moment.unix(event.id.replace(/\.*/, '')).tz(timeZone);
      const eventDayOfWeek = eventDate.day();

      const schedule = show.schedule[0];
      const enableDate = schedule.enable_date;
      const disableDate = schedule.disable_date || '2099-01-01'; // in case null, meaning no end date
      const startTime = schedule.start_time;
      const endTime = schedule.end_time;
      const showDaysOfWeek = [
        schedule.occurs_sunday,
        schedule.occurs_monday,
        schedule.occurs_tuesday,
        schedule.occurs_wednesday,
        schedule.occurs_thursday,
        schedule.occurs_friday,
        schedule.occurs_saturday,
      ];

      if (!showDaysOfWeek[eventDayOfWeek]) {
        // if show doesn't occur on selected day, move on
        return;
      }

      if (
        eventDate.unix() <= moment.tz(enableDate, 'YYYY-MM-DD', timeZone).unix() ||
        eventDate.unix() >= moment.tz(disableDate, 'YYYY-MM-DD', timeZone).unix()
      ) {
        // if show is outside date range, move on
        return;
      }

      const t1 = moment.tz(`${eventDate.format('YYYY-MM-DD')}T${startTime}:00`, 'YYYY-MM-DDTHH:mm:ss', timeZone);
      const t2 = moment.tz(`${eventDate.format('YYYY-MM-DD')}T${endTime}:00`, 'YYYY-MM-DDTHH:mm:ss', timeZone);
      if (eventDate.unix() < t1.unix() || eventDate.unix() > t2.unix()) {
        // if outside time range, move on
        return;
      }

      // Congratulations! You passed! Receive thy new name!
      title = `${show.title} Talk Break`;
    });

    if (title.length > 0) {
      return title;
    }

    // You don't belong to any show. You're basic. Shame!
    return 'Talk Break';
  }
};

/**
 * Takes an array of category resource uri strings and parses them into an usable object witih id, name, and resource_uri
 * @param {Array} selectedCategories list of resource_uri strings to parse
 * @param {Array} allCategories all playlist categories belonging to a station
 * @return {Array} Array which contains all the categories in selectedCategories in the format of allCategories
 */
export const parseTagsFromResourceUri = (selectedCategories, allCategories) => {
  const newCatList = [];
  const regexp = /(\/)(\d{1,2})(\/)/;

  selectedCategories.forEach((category) => {
    const id = parseInt(regexp.exec(category)[2], 10);
    const index = allCategories.findIndex((categoryFromAll) => {
      return categoryFromAll.id === id;
    });
    if (index !== -1) {
      newCatList.push(allCategories[index]);
    }
  });
  return newCatList;
};

/**
 * Makes a request, and upon failure, will retry a specified number of times
 * @param {String} url url to send request
 * @param {String} method http request method
 * @param {Number} retries number of times to retry the request
 * @param {Number} timeOut ms interval between requests
 */
export const requestAndRetry = (url, method = 'get', retries = 5, timeOut = 1000) => {
  return new Promise((resolve, reject) => {
    const request = () =>
      Vue.http[method.toLowerCase()](url)
        .then((resp) => {
          resolve(resp.body);
        })
        .catch((err) => {
          retries--;
          if (retries) {
            window.setTimeout(() => {
              request();
            }, timeOut);
          } else {
            reject(err);
          }
        });
    request();
  });
};

const PARSE_STATION_FROM_EMPIRE_COLLECTION_ID_REGEXP =
  /^(air1b?|klove[12]{1}|[A-Za-z]+?(?:hd\d)*?|[A-Za-z0-9_]+?)(?:_?\d+$)/;

/**
 * Parses a station name from an aw_collection_id, which is a value returned by Empire's analytics API
 * @param {String} aw_collection_id ID used by Empire to identify a show.
 * @returns {String} station name if exists, null if not found
 */
export const parseStationFromEmpireCollectionId = (aw_collection_id) => {
  if (isNaN(aw_collection_id)) {
    const result = PARSE_STATION_FROM_EMPIRE_COLLECTION_ID_REGEXP.exec(aw_collection_id);
    if (result && result[1]) {
      return result[1];
    }
    return null;
  }
  return null;
};

export const generateGuid = () => {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (c ^ (window.crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16)
  );
};

export const bestPracticesUrlPrefix = '//d2vsiwkaihpnfc.cloudfront.net/';

export const escapeRegExp = (text) => {
  return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
};

export const getBrand = () => {
  const vuex = localStorage.getItem('vuex');
  const _store = vuex ? JSON.parse(JSON.stringify(vuex)) : null;
  const brand = JSON.parse(_store)?.BrandStore.selectedBrand;
  return brand?.id;
};

export const serverEndpoint = () => {
  console.log('serverEndpoint', serverEndpoint);
  return `${process.env.VUE_APP_API_URL}/createandpublish/`;
};
