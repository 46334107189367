



























































import Vue from 'vue';

import RoundedCard from '@/components/common/cards/RoundedCard.vue';

export default Vue.extend({
  name: 'AnalyticsDashboard',

  components: {
    RoundedCard,
  },
});
