import HttpClient from '@/services/common/HttpClient';

import BrandModel from '@/models/BrandModel';
import { Campaign } from '@/types/Campaign';
export default class BrandService {
  private http: HttpClient = HttpClient.getInstance();
  private endpoint = '/brands';
  private campaignEndpoint = '/campaigns';

  public getAll(): Promise<BrandModel[]> {
    return this.http.get<BrandModel[]>(this.endpoint);
  }
  public create(brand: Partial<BrandModel>) {
    return this.http.post<BrandModel>(`${this.endpoint}`, brand);
  }

  public edit(brand: Partial<BrandModel>) {
    return this.http.put<BrandModel>(`${this.endpoint}/${brand.id}`, brand);
  }

  public createCampaign(campaign: Partial<Campaign>) {
    return this.http.post<Campaign>(`${this.campaignEndpoint}`, campaign);
  }

  public editCampaign(campaign: Partial<Campaign>) {
    return this.http.put<Campaign>(`${this.campaignEndpoint}/${campaign.id}`, campaign);
  }
}
