<template>
  <div class="analytics-download-modal">
    <modal-layout
      :size="modalLayout.size"
      :heading="modalLayout.heading"
      :expanding="modalLayout.expanding"
      @on:close="close"
    >
      <template slot="content">
        <div class="analytics-download-modal__content">
          <div class="analytics-download-modal__content-notice">
            <p>
              Choose whether you'd like to have this report emailed, or downloaded directly when ready.
              <em>Note:</em> email is strongly suggested for reports larger than a weeks worth of data, as these can
              take several minutes or more to generate.
            </p>
          </div>
          <div class="analytics-download-modal__content-form">
            <div class="analytics-download-modal__content-form-group">
              <fm-radio
                label="Receive this report via:"
                v-model-wc="form.downloadOrEmail"
                :options.prop="downloadOrEmailOptions"
                :displayLtr="true"
              />
            </div>

            <div class="analytics-download-modal__content-form-group" v-if="isEmailSelected">
              <fm-input
                label="Enter email address (comma separated if multiple)"
                v-model-wc="$v.form.emailList.$model"
                :error="$v.form.emailList.$error ? 'Must be valid email address' : ''"
              />
            </div>
          </div>
        </div>
      </template>

      <template slot="footer">
        <div class="analytics-download-modal__actions">
          <fm-button-secondary @click="close"> Cancel </fm-button-secondary>
          <fm-button-primary @click="onRequestCsvClicked"> Request CSV </fm-button-primary>
        </div>
      </template>
    </modal-layout>
  </div>
</template>

<script>
import ModalLayout from '@/createandpublish/layouts/ModalLayout.vue';
import { requiredIf } from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex';

/**
 * For use with Vuelidate. Validates string of comma separated emails.
 * @param {String} value string to test against
 * @returns {Boolean} whether or not test passed
 */
const customEmailValidator = (value) => {
  if (!value) return true;
  return /^[\W]*([\w+\-.%]+@[\w\-.]+\.[A-Za-z]{2,4}[\W]*,{1}[\W]*)*([\w+\-.%]+@[\w\-.]+\.[A-Za-z]{2,4})[\W]*$/.test(
    value
  );
};

export default {
  components: {
    ModalLayout,
  },

  data() {
    return {
      modalLayout: {
        heading: 'Request CSV Report',
        size: 'REGULAR',
        expanding: true,
      },
      form: {
        downloadOrEmail: 'email',
        emailList: '',
      },
    };
  },

  validations() {
    return {
      form: {
        emailList: {
          required: requiredIf(() => this.isEmailSelected),
          customEmailValidator,
        },
      },
    };
  },

  computed: {
    ...mapGetters('CreateAndPublishStore', ['email']),

    isEmailSelected() {
      return this.form.downloadOrEmail === 'email';
    },

    canProgress() {
      return !this.$v.$invalid;
    },

    downloadOrEmailOptions() {
      return [
        { label: 'Email', value: 'email' },
        { label: 'Download', value: 'download' },
      ];
    },
  },

  methods: {
    onRequestCsvClicked() {
      this.$v.form.$touch();
      if (this.canProgress) {
        this.form.downloadOrEmail === 'email'
          ? this.$emit('on:submit-request', { email: encodeURIComponent(this.form.emailList) })
          : this.$emit('on:submit-request', {});
      }
    },

    close() {
      this.$emit('on:cancel');
    },
  },

  mounted() {
    if (this.email) {
      this.form.emailList = this.email;
    }
  },
};
</script>

<style lang="scss" scoped>
.analytics-download-modal {
  &__content {
    &-form {
      &-group {
        p {
          margin-bottom: 0;
        }
      }
    }
  }

  .analytics-download-modal__actions {
    display: flex;
    justify-content: flex-end;

    :first-child {
      margin-right: 24px;
    }
  }
}
</style>
