























import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import AudienceNavTitles from '@/audience/data/leftNavTitles';

import type { SidebarItem } from '@/types/SidebarItem';
import type { ActiveSocialAccount } from '@/types/audience';
import type { Route } from 'vue-router';

const audienceModule = namespace('AudienceAnalyticsStore');

@Component({
  name: 'PortalLeftNav',
})
export default class extends Vue {
  @Prop({ type: Boolean, required: true }) isAudienceRoute!: boolean;
  @Prop({ type: Number, required: true }) selectedBrandId!: number;
  @audienceModule.Getter activeSocialAccounts!: ActiveSocialAccount[];

  get audienceLinkedPlatforms() {
    if (!this.isAudienceRoute || !this.activeSocialAccounts) return [];
    return this.activeSocialAccounts.map(({ platform }) => platform);
  }

  /**
   * Sanitizes nav titles for slot attribute "name"
   */
  get indicatorSlotNames() {
    const t = AudienceNavTitles;
    return Object.values(t)
      .filter((title) =>
        [t.youTube, t.instagram, t.facebook, t.twitter, t.tikTok, t.linkedIn, t.pinterest, t.webTraffic].includes(title)
      )
      .map((title) => `${title.toLowerCase().replace(' ', '-')}-indicator`);
  }

  get indicatorTooltipConfig() {
    return {
      content: 'Click here to link your account',
      placement: 'bottom',
      classes: ['nav-indicator-tooltip'],
      delay: { show: 150, hide: 300 },
      html: false,
    };
  }

  get sidebarItems(): SidebarItem[] {
    const moduleName = this.$route.fullPath.split('/')[1];
    switch (moduleName) {
      case 'audience':
        return this.audienceAnalyticsSidebarItems;
      case 'create':
        return this.createAndPublishSidebarItems;
      case 'discover':
        return this.discoverSidebarItems;
      default:
        return [];
    }
  }

  get audienceAnalyticsSidebarItems(): SidebarItem[] {
    return [
      {
        items: [
          {
            iconName: 'dashboard',
            text: AudienceNavTitles.dashboard,
            onClick: () => {
              this.goToRoute(`/audience/${this.selectedBrandId}`);
            },
          },
        ],
      },
      {
        title: 'My Channels',
        items: [
          {
            iconName: 'youtube',
            text: AudienceNavTitles.youTube,
            onClick: () => {
              this.goToRoute(`/audience/${this.selectedBrandId}/youtube`);
            },
          },
          {
            iconName: 'instagram',
            text: AudienceNavTitles.instagram,
            onClick: () => {
              this.goToRoute(`/audience/${this.selectedBrandId}/instagram`);
            },
          },
          {
            iconName: 'facebook',
            text: AudienceNavTitles.facebook,
            onClick: () => {
              this.goToRoute(`/audience/${this.selectedBrandId}/facebook`);
            },
          },
          {
            iconName: 'twitter',
            text: AudienceNavTitles.twitter,
            onClick: () => {
              this.goToRoute(`/audience/${this.selectedBrandId}/twitter`);
            },
          },
          {
            iconName: 'tiktok',
            text: AudienceNavTitles.tikTok,
            onClick: () => {
              this.goToRoute(`/audience/${this.selectedBrandId}/tiktok`);
            },
          },
          {
            iconName: 'linkedin',
            text: AudienceNavTitles.linkedIn,
            onClick: () => {
              this.goToRoute(`/audience/${this.selectedBrandId}/linkedin`);
            },
          },
          {
            iconName: 'pinterest',
            text: AudienceNavTitles.pinterest,
            onClick: () => {
              this.goToRoute(`/audience/${this.selectedBrandId}/pinterest`);
            },
          },
          // {
          //   iconName: 'website',
          //   text: AudienceNavTitles.webTraffic,
          //   onClick: () => {
          //     console.log('Web traffic');
          //   },
          // },
        ],
      },
      {
        title: 'Community',
        items: [
          {
            iconName: 'medal',
            text: AudienceNavTitles.leaderboard,
            onClick: () => {
              this.goToRoute(`/audience/${this.selectedBrandId}/leaderboard`);
            },
          },
          {
            iconName: 'meeting',
            text: AudienceNavTitles.creatorCommunity,
            onClick: () => {
              this.goToRoute(`/audience/${this.selectedBrandId}/community`);
            },
          },
        ],
      },
      // {
      //   items: [
      //     {
      //       iconName: 'gear',
      //       text: AudienceNavTitles.settings,
      //       onClick: () => {
      //         console.log('Settings');
      //       },
      //     },
      //   ],
      // },
    ];
  }

  get discoverSidebarItems(): SidebarItem[] {
    return [
      {
        items: [
          {
            iconName: 'inspect-chart',
            text: 'Trending',
            onClick: () => {
              this.goToRoute(`/discover/${this.selectedBrandId}`);
            },
          },
          {
            iconName: 'rss-feed',
            text: 'Following',
            onClick: () => {
              this.goToRoute(`/discover/${this.selectedBrandId}/following`);
            },
          },
          {
            iconName: 'gear',
            text: 'Settings',
            onClick: () => {
              this.goToRoute(`/discover/${this.selectedBrandId}/settings`);
            },
          },
        ],
      },
    ];
  }

  get createAndPublishSidebarItems(): SidebarItem[] {
    return [
      {
        items: [
          {
            iconName: 'dashboard',
            text: 'Dashboard',
            onClick: () => {
              this.goToRoute(`/create/${this.selectedBrandId}`);
            },
          },
          {
            iconName: 'planner-two',
            text: 'Planner',
            onClick: () => {
              return false;
            },
          },
        ],
      },

      {
        title: 'My Assets',
        items: [
          // Commented out as per https://rtslabs.atlassian.net/browse/FMCC-2028
          // {
          //   iconName: 'create-plus',
          //   text: 'Create New',
          //   onClick: () => {
          //     this.goToRoute(`/create/${this.selectedBrandId}/shows`)
          //   },
          // },
          {
            iconName: 'podcast',
            text: 'Podcasts',
            onClick: () => {
              this.goToRoute(`/create/${this.selectedBrandId}/podcasts`);
            },
          },
          {
            iconName: 'news',
            text: 'Social stories',
            onClick: () => {
              this.goToRoute(`/create/${this.selectedBrandId}/stories/library`);
            },
          },
          {
            iconName: 'audio-library-two',
            text: 'Audio',
            onClick: () => {
              this.goToRoute(`/create/${this.selectedBrandId}/audio-library`);
            },
          },
          {
            iconName: 'video-cam',
            text: 'Video',
            onClick: () => {
              this.goToRoute(`/create/${this.selectedBrandId}/videos`);
            },
          },
          {
            iconName: 'images',
            text: 'Images',
            onClick: () => {
              this.goToRoute(`/create/${this.selectedBrandId}/images`);
            },
          },
        ],
      },
      {
        title: 'Tools & Settings',
        items: [
          {
            iconName: 'analytics',
            text: 'Analytics',
            onClick: () => {
              this.goToRoute(`/create/${this.selectedBrandId}/analytics`);
            },
          },
          {
            iconName: 'tools',
            text: 'Tools',
            onClick: () => {
              this.goToRoute(`/create/${this.selectedBrandId}/tools`);
            },
          },
          {
            iconName: 'gear',
            text: 'Settings',
            onClick: () => {
              this.goToRoute(`/create/${this.selectedBrandId}/settings`);
            },
          },
        ],
      },
    ];
  }

  goToRoute(path: string) {
    if (this.$route.fullPath !== path) {
      this.$router.push({ path });
    }
  }

  goToHelp() {
    window.open('https://futurimedia.freshdesk.com/support/home', '_blank'); // Likely temporary
  }

  sideNavKey = 0;

  @Watch('$route')
  onRouteChange(newRoute: Route, oldRoute: Route) {
    const newModule = newRoute.path.split('/')[1];
    const oldModule = oldRoute.path.split('/')[1];
    if (newModule !== oldModule) {
      // Advance the key if the user navigates to a different module
      // i.e. Audience, Discover, C&P
      this.sideNavKey++;
    }
  }
}
