<template>
  <blank-card id="twitter-auth-card">
    <div class="card-header" slot="head">
      <div class="header-title-section">
        <img class="twitter-logo" :src="require('@/assets/twitter.svg')" />
        <h5 class="twitter-auth-card-title">TWITTER CARDS BEFORE AUTHORIZATION</h5>
      </div>
      <div class="header-authorize-twitter">
        <img class="authorize-twitter-image" :src="require('@/assets/power-24px.svg')" />
        <a class="authorize-twitter-link">Authorize Twitter</a>
      </div>
    </div>
    <div slot="list-wrapper">
      <div class="content-wrapper" slot="list">
        <p class="twitter-auth-description">
          In order to view [hashtags / tweets] in your feed, you need to sign in to Twitter so we can access Twitter's
          database. (You won't see your Twitter feed or any personal information from Twitter inside Content Cloud.)
          <a class="learn-more">Learn more about Twitter Authorization.</a>
        </p>
        <b-button class="authorize-twitter-button">Authorize Twitter</b-button>
      </div>
    </div>
  </blank-card>
</template>

<script>
import BlankCard from '@/components/common/cards/BlankCard.vue';
export default {
  components: { BlankCard },
  name: 'twitter-auth-card',
};
</script>

<style scoped lang="scss">
#twitter-auth-card {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0px;
  background: var(--neutral-white);
  box-shadow: 0px 1px 7px rgba(34, 32, 70, 0.2);
  margin-bottom: 2rem;
  .card-header {
    display: flex;
    background: var(--neutral-white);
    border-bottom: 1px solid var(--neutral-grey-3-borders);
    flex-wrap: wrap;
    padding: 16px;
    padding-bottom: 8px;
    justify-content: space-between;

    .header-title-section {
      display: flex;
      align-items: center;
      .twitter-logo {
        width: 24px;
        margin-right: 8px;
      }
      .twitter-auth-card-title {
        color: var(--neutral-grey-2-lighter-text);
        font-weight: 500;
        font-size: 16px;
        letter-spacing: 8%;
        font-family: Roboto;
        position: relative;
        margin: 0px;
        white-space: nowrap;
      }
    }
    .header-authorize-twitter {
      margin-left: auto;

      .authorize-twitter-image {
        margin-right: 8px;
        padding-left: 12px;
      }
      .authorize-twitter-link {
        color: var(--accent-links);
        font-weight: 500;
        white-space: nowrap;
        &:hover {
          color: var(--accent-links-hover);
          cursor: pointer;
        }
      }
    }
  }

  .content-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 16px;
    .twitter-auth-description {
      font-weight: 400;
      font-size: 16px;
      text-align: center;
      align-self: center;
      line-height: 30px;
      .learn-more {
        color: var(--accent-links);
        text-decoration: underline;

        &:hover {
          cursor: pointer;
          text-decoration: none;
        }
      }
    }

    .authorize-twitter-button {
      width: 177px;
      height: 48px;
      border-radius: 8px;
      padding: 12px, 24px, 12px, 24px;
      background: var(--neutral-white);
      border: 2px solid var(--accent-links);
      color: var(--accent-links);
      align-self: center;
      margin: 0.5rem 0 2rem 0;

      &:hover {
        background: var(--neutral-white);
        color: var(--accent-links-hover);
        border: 2px solid var(--accent-links-hover);
      }
    }
  }
}
</style>
