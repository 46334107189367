import { mapActions, mapGetters } from 'vuex';
import AuthService from '@/services/AuthService.ts';
import errorMessages from '@/utils/errorMessages.json';
import { required } from 'vuelidate/lib/validators';
import { validateEmail as $valEmail } from '@/plugins/Validations';
import { isActiveUser } from '@/utils';

export const loginMixin = {
  data() {
    return {
      form: {
        emailAddress: '',
        password: '',
      },
      formIsLoading: false, // is form submitting?
      // Banner for when a user was successfully activated
      userActivatedMessage: {
        header: 'Account activated',
        type: 'success',
        message: 'Please sign in with your new credentials.',
      },
      // Banner for when an email is already used
      emailExistsMessage: {
        header: 'Welcome Back!',
        type: 'ecommInfo',
        message: 'We found an account matching the information you provided. Please sign in to continue.',
        links: [{ route: '/checkout', message: 'Use a different email address' }],
      },
      emailExistsMessageAudience: {
        header: 'Welcome Back!',
        type: 'ecommInfo',
        message: 'We found an account matching the information you provided. Please sign in to continue.',
        links: [{ route: '/signupforaudience', message: 'Use a different email address' }],
      },
      // Banner for when a user successfully changed password
      changedPasswordMessage: {
        header: 'Password changed',
        type: 'success',
        message: 'Please sign in with your new credentials.',
      },
      // Banner for when a user entered wrong credentials
      wrongCredentialsMessage: {
        header: 'Oops! Something went wrong.',
        type: 'critical',
        message: 'The information you provided does not match our records. Please try again or reset your password.',
      },
      // Banner for when a user entered wrong credentials
      unhandledErrorMessage: {
        header: 'Oops! Something went wrong.',
        type: 'critical',
        message: "We can't log you in right now. Please try again in a few minutes.",
      },
      showWrongCredentialsBanner: false, // Banner when user entered wrong credentials
      showUnhandledErrorBanner: false, // Banner when there was a generic API error (unhandled)
      errorMessages, // Input error messages
      onLoginRedirect: '',
    };
  },

  validations: {
    form: {
      emailAddress: { required, $valEmail },
      password: { required },
    },
  },

  computed: {
    ...mapGetters(['isLoggedIn', 'loginAppUrl', 'selectedBrand', 'isAdmin', 'hasAudienceOnly']),
    // If user was activated successfully and new password is set, display success banner
    emailExistsBanner() {
      return this.$route.params.state === 'emailExists';
    },
    showChangedPasswordBanner() {
      return this.$route.query.password_changed;
    },
    showUserActivatedBanner() {
      return this.$route.query.user_activated;
    },
    isCheckoutFlow() {
      return this.flow === 'checkout';
    },
    isDefaultFlow() {
      return this.flow === 'default';
    },
    isAudienceFlow() {
      return this.flow === 'audience';
    },
    buttonText() {
      return this.isAudienceFlow ? 'Sign in' : 'Sign in to continue';
    },
  },

  methods: {
    ...mapActions(['logIn']),

    handleInputChange(e) {
      this.form[e.target.name] = e.target.value;
    },

    resetBannerStates() {
      // Reset error banners
      this.showWrongCredentialsBanner = false;
      this.showUnhandledErrorBanner = false;
    },

    async onSubmit() {
      if (Object.keys(this.$route.query).length) {
        this.onLoginRedirect = this.$route.query.redirect;
        this.$router.replace({ query: '' }); // Remove query params (indicating success states)
      }
      this.resetBannerStates();
      this.formIsLoading = true;
      this.$v.form.$touch(); // Touch all fields

      if (this.$v.form.$invalid) {
        this.formIsLoading = false;
      } else {
        // Credentials
        const { emailAddress, password } = this.form;
        const credentials = {
          emailAddress,
          password,
        };

        // Authenticate
        const res = await new AuthService().authenticate(credentials);

        // If no errors
        if (res?.authToken) {
          // Store user
          this.logIn(res);

          // Fetch app data
          await this.handleDispatches();

          // Loading state
          this.formIsLoading = false;

          // Redirect
          if (this.onLoginRedirect) {
            this.$router.replace({ path: this.onLoginRedirect });
          } else {
            // Redirect to Audience if that's user's only product
            if (this.hasAudienceOnly) {
              const selectedBrandId = this.$store.getters.selectedBrand?.id;
              this.$router.replace({ path: `/audience/${selectedBrandId}` });
            } else {
              // Redirect to Dashboard if user has access to more than Audience
              this.$router.replace({ path: '/' });
            }
          }
        } else {
          // Loading state
          this.formIsLoading = false;
          if (res?.errors) {
            // Has errors, show 'wrong credentials' banner
            this.showWrongCredentialsBanner = true;
          } else {
            this.showUnhandledErrorBanner = true;
          }
        }
      }
    },
    // Name is a duplicate, functions should probably be merged and arguments passed to distinguish
    async handleDispatches() {
      // If Admin or Superadmin
      if (this.isAdmin && isActiveUser) {
        // If these are needed, it might be a mistake
        await this.$store.dispatch('fetchBrands');
        await this.$store.dispatch('fetchUsers');
      }
      await this.$store.dispatch('fetchAccount');
      await this.$store.dispatch('setInitialSelectedBrand');
    },
    onForgotPassword() {
      this.$router.push({
        path: `/forgot-password?flow=${this.flow}`,
      });
    },
  },

  mounted() {
    this.$store.commit('CLEAR_STORE'); // Reset store as fallback, shouldn't be necessary
    if (this.$route.params.state === 'emailExists' && this.$route.params.email) {
      this.form.emailAddress = this.$route.params.email;
    }

    if (this.$route.path.includes('audience')) {
      this.overrideFlow = true;
    }
  },
};
