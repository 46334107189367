<template>
  <div class="settings_card_header__wrapper">
    <!-- Name -->
    <div class="settings_card_header__name">
      <span>
        {{ name }}
      </span>
    </div>
    <!-- Connect -->
    <div
      v-if="!!(connectTerm || overrideConnectTerm)"
      class="settings_card_header__connect"
      @click="() => onAdd() || true"
    >
      <span class="material-icons"> add </span>
      &nbsp;{{ addNew }}
    </div>
  </div>
</template>
<script>
export default {
  name: 'SettingsCardHeader',

  props: {
    connectTerm: String,
    name: String,
    onAdd: Function,
    overrideConnectTerm: String,
  },

  data() {
    return {};
  },

  computed: {
    addNew() {
      if (this.overrideConnectTerm) {
        return this.overrideConnectTerm;
      }
      return `Connect new ${this.connectTerm}`;
    },
  },
};
</script>
<style lang="scss" scoped>
.settings_card_header__wrapper {
  border-bottom: 1px solid var(--neutral-grey-3-borders);
  display: flex;
  justify-content: space-between;
  padding: 20px 16px;

  .settings_card_header__name {
    display: flex;
    justify-content: flex-start;
  }

  .settings_card_header__name span {
    color: var(--neutral-grey-2-lighter-text);
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 2px;
    text-align: left;
    text-transform: uppercase;
  }
  .settings_card_header__connect {
    align-items: center;
    color: var(--accent-links);
    cursor: pointer;
    display: flex;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.38px;
    text-align: left;
  }
}
</style>
