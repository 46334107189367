




































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import Select from '@/components/common/form/Select.vue';
import IdeaStarterCard from '@/components/discover/cards/IdeaStarterCard.vue';
import IdeaStarterDrawer from '@/components/discover/drawer/IdeaStarterDrawer.vue';
import DiscoverIdeaStarterService from '@/services/DiscoverIdeaStarterService';
import AddEditCampaignDrawer from '@/components/brands/AddEditCampaignDrawer.vue';
import Loading from '@/components/common/Loading.vue';
import EventHub from '@/components/discover/EventHub.vue';

@Component({
  components: {
    Select,
    IdeaStarterCard,
    IdeaStarterDrawer,
    Loading,
    AddEditCampaignDrawer,
  },
  data: () => {
    return {
      searchResults: [],
      ideaResults: [],
      category: '',
      drawerStatus: {
        isOpen: false,
      },
      loaderVisible: false,
      brandId: {
        type: Number,
      },
      campaigns: [],
      interval: null,
    };
  },
})
export default class IdeaStarters extends Vue {
  @Getter selectedBrand;
  @Getter defaultBrandId;
  @Prop({ required: false }) brandsData?: [];

  async loadIdeaStarters() {
    this.$data.loaderVisible = true;
    const response = await new DiscoverIdeaStarterService().getAll(this.selectedBrand.id);
    this.$data.loaderVisible = false;
    if (Array.isArray(response)) {
      //clear search results before re-load to clear temp data
      if (this.$data.ideaResults[0]) {
        this.$data.ideaResults.splice(0);
      }
      this.$data.ideaResults = response;
    }
  }

  async initialLoad() {
    if (this.selectedBrand.id) {
      this.loadIdeaStarters();
    }
    //refresh IdeaStarters
    this.$data.interval = setInterval(this.loadIdeaStarters, 60000);
    this.$once('hook:beforeDestroy', () => {
      clearInterval(this.$data.interval);
    });
  }

  async updateStoryCampaigns(value) {
    const storyId = value.storyId;
    this.$data.ideaResults.map((story, index) => {
      if (story.storyId === storyId) {
        this.$data.ideaResults[index].campaign_ids = [...value.campaign_ids];
      }
    });
  }

  get dataLoading() {
    return this.$data.ideaResults?.length === 0 && this.$data.loaderVisible;
  }

  mounted() {
    this.initialLoad();
  }

  // Drawer Methods
  handleIdeaStarterDrawer() {
    this.$data.loaderVisible = true;
  }

  created() {
    EventHub.$on('tagIdeaFromCampaignDrawer', this.updateStoryCampaigns);
  }
}
