














import { Vue, Prop, Component } from 'vue-property-decorator';
import Button from '@/components/common/buttons/Button.vue';

@Component({
  components: {
    Button,
  },
  data() {
    return {
      // show/hide share post modal
      // @TODO eclincher
      // showSharePostModal: true,
    };
  },
})
export default class SharePostButton extends Vue {
  @Prop({ required: false, default: true }) boxShadow!: boolean;
  @Prop({ required: false }) onShare; // Should be required when all buttons are hooked up
}
