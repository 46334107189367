const POSTGTMID = 'GTM-578FFJT';
const CCGTMID = 'GTM-5D6BSS6';
const allowedCCIDEnvironments = ['uat'];

/**
 *
 * @returns A list of GTM IDs for an environment
 */
export const getIDConfigForEnvironment = () => {
  const res: string[] = [];
  // Always add POST GTM ID
  res.push(POSTGTMID);
  // Add CC GTM ID if in allowed environment
  if (allowedCCIDEnvironments.includes(process.env.VUE_APP_ENV || '')) {
    res.push(CCGTMID);
  }
  return res;
};
