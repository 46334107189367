

































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { Brand } from '@/types/Brand';
import RoundedCard from '@/components/common/cards/RoundedCard.vue';
import SharePostButton from '@/components/discover/SharePostButton.vue';
import CampaignCircle from '@/components/common/campaigns/CampaignCircle.vue';
import CampaignMenu from '@/components/common/campaigns/CampaignMenu.vue';
import EventHub from '@/components/discover/EventHub.vue';
import DiscoverStoryService from '@/services/DiscoverStoryService';

@Component({
  components: {
    CampaignCircle,
    CampaignMenu,
    RoundedCard,
    SharePostButton,
  },
  data() {
    return {
      selectedCampaigns: [],
    };
  },
})
export default class TrendingCardGrid extends Vue {
  @Getter selectedBrand;
  @Prop({ required: false }) private onSharePost; // eclincher share post
  @Prop({ required: false }) private title?: string;
  @Prop({ required: false }) private thumbnail?: string;
  @Prop({ required: false }) private gender?: string;
  @Prop({ required: false }) private age?: string;
  @Prop({ required: false }) private campaignIds;
  @Prop({ required: false }) private storyId?: number;
  @Prop({ required: false, default: () => [] }) private campaigns;

  // eclincher share post modal data
  // @TODO eclincher
  // get sharePostModalData() {
  //   const _campaigns = this.$store.getters.authenticatedUser.campaigns; // campaigns from authenticatedUser
  //   const _content = `${this.title}`; // build content for eclincher
  //   const _tags = _campaigns.filter((cp) => this.campaignIds?.includes(cp.id))?.map((cp2) => cp2.name) || []; // build tags for eclincher
  //   const _picture = this.thumbnail;
  //   return {
  //     tags: _tags.join(','),
  //     content: _content,
  //     picture: _picture,
  //   };
  // }

  openDrawer() {
    console.log('1');
    return this.$emit('fetchSingleStory');
  }

  onAddNewCampaign() {
    //pass story data to add to campaign drawer
    EventHub.$emit('addCampaignDrawerData', {
      storyId: this.$props.storyId,
      campaigns: this.$data.selectedCampaigns,
      campaignIds: this.$data.selectedCampaigns.map((_) => _.id),
      availableCampaigns: this.selectedBrand.campaigns,
      dataType: 'trending',
    });
  }

  async tagCampaign(value) {
    if (value.length !== this.$data.selectedCampaigns.length) {
      this.updateStory(value);
    }
  }

  async updateStory(value) {
    const storyId = this.$props.storyId;
    const taggedCampaignIds = value;
    const legacyCampaignIds = this.$data.selectedCampaigns.map((_) => _.id);
    const addCampaignIds = taggedCampaignIds.filter((id) => !legacyCampaignIds?.includes(id)) || [];
    const removeCampaignIds = legacyCampaignIds.filter((id) => !taggedCampaignIds?.includes(id)) || [];

    if (removeCampaignIds.length > 0) {
      await new DiscoverStoryService().untagCampaignFromStory(this.selectedBrand.id, removeCampaignIds[0], storyId);
      this.$data.selectedCampaigns = this.$data.selectedCampaigns.filter(
        (campaign) => removeCampaignIds[0] !== campaign.id
      );
    }

    if (addCampaignIds.length > 0) {
      await new DiscoverStoryService().tagStoriesToCampaign(this.selectedBrand.id, addCampaignIds[0], storyId);
      const newCampaign = this.selectedBrand.campaigns.filter((campaign) => addCampaignIds[0] === campaign.id)[0];

      if (newCampaign) {
        this.$data.selectedCampaigns.push(
          this.selectedBrand.campaigns.filter((campaign) => addCampaignIds[0] === campaign.id)[0]
        );
      }
    }
  }

  getSelectedCampaigns() {
    this.$data.selectedCampaigns = (this.selectedBrand as Brand).campaigns.filter(
      (campaign) => campaign.id && this.$props.campaignIds?.includes(campaign.id)
    );
  }

  created() {
    this.getSelectedCampaigns();
  }

  // eclincher on share
  onShare() {
    // this.$emit('onSharePost', this.$data.sharePostModalData);
    console.log('onShare');
  }
}
