










































































import { Component, Vue, Prop } from 'vue-property-decorator';
import { Getter } from 'vuex-class';

import ProfileDropdown from './header/profileDropdown.vue';
import MobileProfileMenu from '@/components/MobileProfileMenu.vue';
import DevTools from './DevTools.vue';
import TrialExpirationAlert from '@/components/common/alerts/trialExpirationAlert/index.vue';

@Component({
  components: {
    DevTools,
    ProfileDropdown,
    MobileProfileMenu,
    TrialExpirationAlert,
  },
  data() {
    return {
      notificationCount: 999,
    };
  },
})
export default class PortalHeader extends Vue {
  @Prop({ type: Boolean, required: true }) isAudienceRoute!: boolean;
  @Getter authenticatedUser;
  @Getter showSidebar!: boolean;
  @Getter selectedBrand;
  @Getter isTrial;

  get audienceLink() {
    return this.$route.path.search('audience') === 1;
  }

  get discoverLink() {
    return this.$route.path.search('discover') === 1;
  }

  get createLink() {
    return this.$route.path.search('create') === 1;
  }

  get currentUserInitials() {
    const currentUser = this.authenticatedUser;
    return currentUser ? currentUser.firstName[0] + currentUser.lastName[0] : '';
  }

  get selectedBrandId() {
    return this.selectedBrand?.id;
  }

  get showDevTool() {
    return process.env.NODE_ENV === 'development';
  }

  toggleProfileMenu() {
    this.$root.$emit('bv::toggle::collapse', 'profile-menu');
  }
}
