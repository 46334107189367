








import { Component, Vue, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { PlatformLabels, PlatformColors } from '@/audience/data/socialPlatformChartConfig';
import BaseBarChart from '@/audience/components/base-charts/BaseBarChart.vue';
import ChartTooltip from '@/audience/components/base-charts/ChartTooltip.vue';
import NoChartDataBanner from '@/audience/components/base-charts/NoChartDataBanner.vue';
import { barGroupLabelPlugin } from '@/audience/data/chart-plugins';
import { chartHasNonZeroData } from '@/audience/core/utils/chart';
import moment from 'moment-timezone';
import type { AyrshareProfile, ChartConfig, SocialAnalytics, TooltipConfig } from '@/types/audience';
import type { ChartData, ChartOptions, Color, TooltipModel, ChartDataset } from 'chart.js';
import type { BarGroupLabelPluginOptions } from '@/audience/data/chart-plugins/barGroupLabelPlugin';

const audienceModule = namespace('AudienceAnalyticsStore');

@Component({
  name: 'LeaderboardSelfBarChart',
  components: {
    BaseBarChart,
    ChartTooltip,
    NoChartDataBanner,
  },
})
export default class LeaderboardSelfBarChart extends Vue {
  @Prop({ type: Object, required: true }) socialAnalytics!: SocialAnalytics;
  @Prop({ type: Object, required: true }) profile!: AyrshareProfile;

  @audienceModule.Getter timeZone!: string;

  get activePlatforms(): string[] {
    return this.profile.activeSocialAccounts
      .map(({ platform }) => PlatformLabels[platform.toUpperCase()])
      .filter((platformLabel) => platformLabel !== PlatformLabels.TIKTOK && platformLabel !== PlatformLabels.PINTEREST);
  }

  get chartConfig(): ChartConfig {
    return {
      chartId: 'leaderboard-self-bar-chart',
      datasetIdKey: 'leaderboard-self-bar-chart',
      plugins: [barGroupLabelPlugin],
      cssClasses: '',
      styles: {
        width: '100%',
        height: '100%',
      },
    };
  }

  get chartOptions(): ChartOptions {
    return {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        xAxis: {
          display: false,
        },
      },
      plugins: {
        barGroupLabelPlugin: this.groupLabelPluginOptions,
        legend: {
          display: false,
        },
        tooltip: {
          enabled: false,
          external: async (context) => {
            const tooltipEl = (this.$refs.tooltip as Vue).$el;

            const tooltipModel = context.tooltip as TooltipModel<'bar'>;

            const style: Partial<CSSStyleDeclaration> = {};

            // Hide if no tooltip
            if (tooltipModel.opacity === 0) {
              style.opacity = '0';
              style.display = 'none';
              this.tooltipConfig.style = style;
              return;
            }

            // Set data
            const chartData = context.chart.data as ChartData<'bar'>;
            const {
              dataIndex,
              datasetIndex,
              element,
              dataset: { backgroundColor, borderColor, label: metricLabel },
            } = tooltipModel.dataPoints[0];

            const headerLabel = moment.tz(metricLabel, this.timeZone).format('MMM DD[,] YYYY').toUpperCase();

            const primaryValueLabel = this.chartLabels[dataIndex];
            const primaryValueText = chartData.datasets[datasetIndex].data[dataIndex]?.toLocaleString() as string;
            const percentOfTotalText = (
              ((chartData.datasets[datasetIndex].data[dataIndex] as number) /
                this.chartTotalsByDate[metricLabel as string]) *
              100
            ).toFixed(0);
            const primaryBackgroundColor =
              typeof backgroundColor === 'function' ? element.options.backgroundColor : backgroundColor;

            // Display, position, other styles
            this.tooltipConfig.style.display = 'block';
            await this.$nextTick();
            const { width, height } = tooltipEl.getBoundingClientRect();

            style.opacity = '1';
            style.position = 'absolute';
            style.left = window.pageXOffset + tooltipModel.caretX - width / 2 + 'px';
            style.top = window.pageYOffset + tooltipModel.caretY - height - 8 + 'px';
            style.pointerEvents = 'none';

            const tooltipConfig: TooltipConfig = {
              header: {
                label: headerLabel,
                usePlatformIcon: false,
              },
              topRow: {
                primary: {
                  label: primaryValueLabel,
                  value: primaryValueText,
                  backgroundColor: primaryBackgroundColor as string,
                  borderColor: borderColor as string,
                },
                secondary: {
                  label: '% of total',
                  value: percentOfTotalText,
                },
              },
              style,
            };
            this.tooltipConfig = tooltipConfig;
          },
        },
      },
      elements: {},
      layout: {
        padding: {
          bottom: 90,
        },
      },
    };
  }

  tooltipConfig: TooltipConfig = {
    topRow: {
      primary: {
        label: '',
        value: '',
      },
    },
    style: {
      display: 'none',
      opacity: '0',
    },
  };

  get chartTotalsByDate() {
    return this.chartDatasets.reduce((acc, dataset) => {
      acc[dataset.label] = dataset.data.reduce((total, value) => {
        if (isNaN(value)) return total;
        total += value;
        return total;
      }, 0);
      return acc;
    }, {});
  }

  get chartLabels() {
    return this.activePlatforms.map(() => 'Followers');
  }

  get chartDatasets() {
    const socialAnalyticsKeys = Object.keys(this.socialAnalytics);
    const socialAnalyticsLength = socialAnalyticsKeys.length;
    const startDate = socialAnalyticsKeys[0];
    const endDate = socialAnalyticsKeys[socialAnalyticsLength - 1];
    const alpha = Math.floor(255 * 0.4).toString(16);

    return [
      {
        type: 'bar',
        barThickness: 24,
        label: startDate,
        backgroundColor: ((color) => {
          return PlatformColors[this.activePlatforms[color.dataIndex].toUpperCase()] + alpha;
        }) as unknown as Color,
        data: this.activePlatforms.map(
          (platformLabel) => this.socialAnalytics[startDate]?.[platformLabel]?.followers ?? NaN
        ),
      },
      {
        type: 'bar',
        barThickness: 24,
        label: endDate,
        backgroundColor: ((color) => {
          return PlatformColors[this.activePlatforms[color.dataIndex].toUpperCase()];
        }) as unknown as Color,
        data: this.activePlatforms.map(
          (platformLabel) => this.socialAnalytics[endDate]?.[platformLabel]?.followers ?? NaN
        ),
      },
    ];
  }

  get chartHasData() {
    return chartHasNonZeroData(this.chartDatasets as ChartDataset[]);
  }

  get chartData(): ChartData {
    return {
      labels: this.chartLabels,
      datasets: this.chartDatasets as ChartDataset[],
    };
  }

  get groupLabelPluginOptions(): BarGroupLabelPluginOptions {
    const getPercentChange = (dataIndex: number) => {
      const previous = this.chartData.datasets[0].data[dataIndex] as number;
      const current = this.chartData.datasets[1].data[dataIndex] as number;
      if (previous === 0) return 0;
      const diff = current - previous;
      return diff / Math.abs(previous) ?? 0;
    };
    return {
      platformLabels: {
        platforms: this.activePlatforms.map((platformLabel, i) => {
          return {
            label: platformLabel,
            percentChange: getPercentChange(i),
          };
        }),
      },
    };
  }
}
