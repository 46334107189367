






import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class AssistiveLink extends Vue {
  @Prop({ default: { label: '', onClick: () => undefined }, required: false }) link!: Record<string, unknown>;
}
