
// See /documentation/MediaUploads.md for documentation
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { MediaUploadMetadata } from '@/types/createandpublish/mediaLibraries/Common';

const audioWizardModule = namespace('CreateAndPublishStore/audioWizard');

Component.registerHooks(['beforeRouteLeave']);

@Component({})
export default class UploadProgressMixin extends Vue {
  @audioWizardModule.Action checkEventUploadProgress;

  checker = NaN;
  progress: MediaUploadMetadata | null = null;
  progresses: MediaUploadMetadata[] = [];
  remainingProgressCount = 5;
  duration = 0;

  get shouldRetryProgress() {
    return this.remainingProgressCount > 0;
  }

  /**
   * Used to check the progress of an audio event upload.
   * @param {String} uuid uuid of resource to check upload progress against
   * @param {Function} successCb callback that says what to do when the upload is complete
   * @param {Function} errorCb callback that says what to do when the upload failed
   */
  async checkUploadProgress(uuid, successCb, errorCb) {
    try {
      const progress = await this.checkEventUploadProgress(uuid);
      this.progress = progress;

      if (progress.success === 1 && progress.guid) {
        // It has uploaded & encoded
        this.resetUploadMixinState();
        this.duration = progress.duration;
        successCb();
        return;
      }

      if (!progress.guid) {
        // sometimes server is hasn't finished setting up guid before we check for progress
        // but if we do have guid, file is currently uploading and we don't want to decrement
        this.remainingProgressCount--;
      }

      this.retryOrGiveUp(uuid, successCb, errorCb);
    } catch (_e) {
      this.remainingProgressCount--;

      this.retryOrGiveUp(uuid, successCb, errorCb);
    }
  }

  async checkUploadProgressMulti(uuids, successCb, errorCb) {
    const uuidCount = uuids.length;
    this.progresses = new Array(uuidCount).fill({ success: 0 }, 0, uuidCount);
    let currentIndex = 0;
    while (currentIndex < uuidCount) {
      if (this.remainingProgressCount) {
        await new Promise((resolve) => setTimeout(resolve, 750)); // don't spam server
        try {
          const progress = await this.checkEventUploadProgress(uuids[currentIndex]);
          this.$set(this.progresses, currentIndex, progress);
          if (progress.success === 1 && progress.guid) {
            currentIndex++;
            this.remainingProgressCount = 5;
          }
        } catch (e) {
          this.remainingProgressCount--;
        }
      } else {
        this.resetUploadMixinState();
        errorCb();
        return;
      }
    }

    this.resetUploadMixinState();
    successCb();
  }

  retryOrGiveUp(uuid, successCb, errorCb) {
    if (!this.shouldRetryProgress) {
      this.resetUploadMixinState();
      errorCb();
      return;
    }
    this.checker = window.setTimeout(this.checkUploadProgress, 500, uuid, successCb, errorCb);
  }

  retryOrGiveUpMulti(uuids, successCb, errorCb) {
    if (this.remainingProgressCount <= 0) {
      this.resetUploadMixinState();
      errorCb();
    } else {
      this.checker = window.setTimeout(this.checkUploadProgressMulti, 500, uuids, successCb, errorCb);
    }
  }

  resetUploadMixinState() {
    // duration and progress should be reset on the components that use them
    clearTimeout(this.checker);
    this.remainingProgressCount = 5;
  }

  beforeRouteLeave(_to, _from, next) {
    this.resetUploadMixinState();
    next();
  }
}
