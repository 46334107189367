<template>
  <th class="table__header_cell" :class="{ sortable: isSortable }" :style="customStyling">
    <slot />
    <span class="material-icons" :class="{ active: isActive }" v-if="isSortable">
      {{ icon }}
    </span>
  </th>
</template>

<script>
export default {
  props: {
    isSortable: { default: false, type: Boolean },
    isActive: { default: false, type: Boolean },
    isDesc: { default: true, type: Boolean },
    maxWidth: { default: '100%', type: String },
    minWidth: { default: '', type: String },
    width: { default: '100%', type: String },
  },
  computed: {
    customStyling() {
      return [
        {
          'max-width': `${this.maxWidth}`,
          'min-width': `${this.minWidth}`,
        },
      ];
    },
    icon: function () {
      return !this.isDesc && this.isActive ? 'arrow_drop_up' : 'arrow_drop_down';
    },
  },
};
</script>

<style lang="scss" scoped>
.table__header_cell {
  align-items: center;
  display: table-cell;
  flex-grow: 1;
  justify-content: flex-start;
  padding: 15px 16px;

  &.sortable {
    cursor: pointer;
  }
}

.material-icons {
  vertical-align: bottom;
  font-size: 2rem;
  line-height: 2rem;

  &.active {
    color: #16c9a5;
  }
}
</style>
