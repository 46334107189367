<template>
  <div class="show-details">
    <alert-display v-if="shouldShowAlert" :alert-message="alertMessage" />
    <!-- Header section -->
    <div class="d-flex align-items-center justify-content-between">
      <!-- Title -->
      <h1 class="global__title">{{ showData.title }}</h1>
      <!-- Buttons-->
      <div class="d-flex align-items-center">
        <div class="mr-4" v-if="isAdmin">
          <fm-button-secondary @click="loadEditShowSettings()"> Edit show </fm-button-secondary>
        </div>
        <fm-button-primary @click="loadEditEpisodeAudioView()">
          <span class="material-icons" aria-hidden="true"> add </span>
          &nbsp;Create new episode
        </fm-button-primary>
      </div>
    </div>

    <div>
      <div class="columns small-12 p-0">
        <div class="loading" v-if="isLoading">
          <LoadingSpinner></LoadingSpinner>
          <fm-text tag="p" variant="large">Loading...</fm-text>
        </div>
        <div v-else-if="totaledEpisodeCounts">
          <div class="columns small-12 p-0">
            <!-- Tabs -->
            <fm-tabs class="mb-3">
              <!-- Published -->
              <fm-tab-item id="tab-1" :selected="activeTab === 1" @click="changeActiveTab(1)">
                Published ({{ activeShowCounts.published | formatNumber }})
              </fm-tab-item>
              <!-- Scheduled -->
              <fm-tab-item id="tab-2" :selected="activeTab === 2" @click="changeActiveTab(2)">
                Scheduled to Publish ({{ activeShowCounts.scheduled | formatNumber }})
              </fm-tab-item>
              <fm-tab-item id="tab-3" :selected="activeTab === 3" @click="changeActiveTab(3)">
                Unpublished ({{ activeShowCounts.unpublished | formatNumber }})
              </fm-tab-item>
              <fm-tab-item id="tab-4" :selected="activeTab === 4" @click="changeActiveTab(4)">
                Draft ({{ activeShowCounts.draft | formatNumber }})
              </fm-tab-item>
            </fm-tabs>
            <!-- End of Tabs -->

            <!-- Tab Content -->
            <div class="show-details__tabcontent">
              <fm-tab-panel
                class="show-details__container_body"
                id="pan-1"
                ariaLabel="Published"
                v-if="activeTab === 1"
              >
                <show-details-published
                  @request-playlists="requestPlaylists"
                  :show="showData"
                  :episodes="episodes"
                  :dateType="'Published'"
                >
                </show-details-published>
              </fm-tab-panel>
              <fm-tab-panel
                class="show-details__container_body"
                id="pan-2"
                :ariaLabel="`Scheduled to Publish`"
                v-if="activeTab === 2"
              >
                <show-details-published
                  @request-playlists="requestPlaylists"
                  :show="showData"
                  :episodes="episodes"
                  :dateType="'Scheduled'"
                  :isScheduledTab="true"
                >
                </show-details-published>
              </fm-tab-panel>
              <fm-tab-panel
                class="show-details__container_body"
                id="pan-3"
                ariaLabel="Unpublished"
                v-if="activeTab === 3"
              >
                <show-details-published
                  @request-playlists="requestPlaylists"
                  :show="showData"
                  :episodes="episodes"
                  :dateType="'Created'"
                >
                </show-details-published>
              </fm-tab-panel>
              <fm-tab-panel class="show-details__container_body" id="pan-3" ariaLabel="Draft" v-if="activeTab === 4">
                <show-details-published
                  @request-playlists="requestPlaylists"
                  :show="showData"
                  :episodes="episodes"
                  :dateType="'Created'"
                >
                </show-details-published>
              </fm-tab-panel>
            </div>
            <!-- End of Tab Content -->
          </div>
        </div>
        <!-- if no published episodes -->
        <div class="d-flex flex-column justify-content-center align-items-center" v-else>
          <fm-heading>
            <h2>Nothing to see here</h2>
          </fm-heading>
          <fm-body variant="MEDIUM"> You haven’t created any episodes yet! </fm-body>
          <br />
          <router-link style="text-decoration: none" to="episodes/0/edit/info">
            <fm-button-secondary> Create your first episode </fm-button-secondary>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers, mapGetters as mapRootGetters } from 'vuex';
const { mapGetters } = createNamespacedHelpers('CreateAndPublishStore');
const { mapGetters: mapGettersGetPlaylists } = createNamespacedHelpers('CreateAndPublishStore/getPlaylists');
import ShowDetailsPublished from '../components/ShowDetailsPublished';
import LoadingSpinner from './LoadingSpinner.vue';
import AlertDisplay from '@/components/common/AlertDisplay.vue';

export default {
  name: 'show-details',

  components: {
    ShowDetailsPublished,
    LoadingSpinner,
    AlertDisplay,
  },

  data() {
    return {
      isInEditMode: false,
      editedTitle: '',
      editedDescription: '',
      showEditControls: false,
      activeTab: this.getInitialTab(),
      showData: this.show,
    };
  },

  props: {
    show: {
      type: Object,
      required: true,
    },
    episodes: {
      type: Array,
      default: () => [],
    },
    isLoading: Boolean,
  },

  computed: {
    ...mapRootGetters(['selectedBrand']),
    ...mapGetters(['stationUrl', 'isAdmin', 'isAvess', 'shouldShowAlert', 'alertMessage']),
    ...mapGettersGetPlaylists(['activeShowCounts', 'currentPage']),

    totaledEpisodeCounts() {
      return Object.values(this.activeShowCounts).reduce((acc, count) => acc + count, 0);
    },

    // Easter-egg lottery number generator
    generatedLotteryNumbers() {
      const newLotteryNumbers = new Set();
      // generate 5 unique numbers between 1-69, inclusive
      while (newLotteryNumbers.size !== 5) {
        newLotteryNumbers.add(Math.floor(Math.random() * 69) + 1);
      }
      // generate 1 number between 1-26, inclusive
      const powerball = Math.floor(Math.random() * 26) + 1;

      return [...newLotteryNumbers, powerball].join(', ');
    },
  },

  methods: {
    getInitialTab() {
      if (this.$route.query && this.$route.query.tab) {
        switch (this.$route.query.tab) {
          case 'published':
            return 1;
          case 'scheduled':
            return 2;
          case 'unpublished':
            return 3;
          case 'draft':
            return 4;
          default:
            return 1;
        }
      }
      return 1;
    },

    changeActiveTab(tab) {
      this.activeTab = tab;
      let tabQuery = 'published';
      switch (tab) {
        case 1:
          tabQuery = 'published';
          break;
        case 2:
          tabQuery = 'scheduled';
          break;
        case 3:
          tabQuery = 'unpublished';
          break;
        case 4:
          tabQuery = 'draft';
          break;
        default:
      }
      this.$store.commit('CreateAndPublishStore/getPlaylists/SET_TYPE', tabQuery);
      if (this.currentPage === 1) {
        this.requestPlaylists();
      } else {
        // This will also trigger a request for new playlist data
        this.$store.commit('CreateAndPublishStore/getPlaylists/SET_PAGE', 1);
      }
      this.$router.replace({ query: { tab: tabQuery } });
    },

    loadEditEpisodeAudioView() {
      // save the episodeId to the store
      this.$store.commit('CreateAndPublishStore/draftEpisodes/SET_MAX_STEP_COMPLETED', 0);
      this.$router.push({ path: 'episodes/0/edit/info' });
    },

    loadEditShowSettings() {
      this.$router.push({ path: `/create/${this.selectedBrand?.id}/podcasts/shows/edit/${this.showData.id}` });
    },

    requestPlaylists() {
      this.$emit('request-playlists');
    },
  },

  filters: {
    /**
     * Pretty-prints numbers. Adds commas where necessary and strips the decimal place if it's there.
     * @param {Number|String} v some number to format
     * @returns {String} formatted number
     */
    formatNumber(v) {
      return `${('' + v).replace(/\B(?=(\d{3})+(?!\d))/g, ',').replace(/\.\d{2}$/, '')}`;
    },
  },

  watch: {
    show(data) {
      this.showData = data;
    },
    $route: {
      immediate: true,
      handler() {
        if (this.showData && this.showData.title) {
          document.title =
            `${this.showData.title} | Podcasts | Create&Publish | Futuri Content Cloud` || 'Futuri Content Cloud';
        }
      },
    },
  },
};
</script>

<style lang="scss">
@import '../styles/show_details.scss';

.show-details {
  .loading {
    display: flex;
    flex-flow: column;
    align-items: center;
    margin-top: 64px;
    min-height: 300px;
  }
}
</style>
