






































import Vue from 'vue';

import Button from '@/components/common/buttons/Button.vue';

export default Vue.extend({
  components: {
    Button,
  },

  computed: {
    showBrandTip(): boolean {
      return this.$route.query.code === '2';
    },
  },

  props: {
    getAdmin: String,
    isAccountOwner: Boolean,
  },
});
