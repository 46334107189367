<template>
  <div class="custom" v-click-outside="closeColorPickers">
    <fieldset class="custom__fieldset">
      <legend class="custom__legend">Custom</legend>
      <ul class="custom__list">
        <!-- Background color picker -->
        <li class="custom__list-item custom__color-picker-wrapper">
          <button
            class="custom__button custom__button--background"
            :style="{ backgroundColor: colorValues.background.hex }"
            @click.stop="toggleColorPicker('background')"
          ></button>
          <ColorPicker
            v-show="showBackgroundPicker"
            :disable-alpha="true"
            :preset-colors="[]"
            :color="colorValues.background"
            class="custom__color-picker custom__color-picker--background"
            @update="handleUpdate('background', $event)"
            ref="backgroundPicker"
          />
          Background
        </li>
        <!-- /Background color picker -->

        <!-- Text color picker -->
        <li class="custom__list-item custom__color-picker-wrapper">
          <button
            class="custom__button custom__button--text"
            :style="{ backgroundColor: colorValues.text.hex }"
            @click.stop="toggleColorPicker('text')"
          ></button>
          <ColorPicker
            v-show="showTextPicker"
            :disable-alpha="true"
            :preset-colors="[]"
            :color="colorValues.text"
            class="custom__color-picker custom__color-picker--text"
            @update="handleUpdate('text', $event)"
            ref="textPicker"
          />
          Text
        </li>
        <!-- /Text color picker -->

        <!-- Accent color picker -->
        <li class="custom__list-item custom__color-picker-wrapper">
          <button
            class="custom__button custom__button--accent"
            :style="{ backgroundColor: colorValues.accent.hex }"
            @click.stop="toggleColorPicker('accent')"
          ></button>
          <ColorPicker
            v-show="showAccentPicker"
            :disable-alpha="true"
            :preset-colors="[]"
            :color="colorValues.accent"
            class="custom__color-picker custom__color-picker--accent"
            @update="handleUpdate('accent', $event)"
            ref="accentPicker"
          />
          Accent
        </li>
        <!-- /Accent color picker -->
      </ul>
    </fieldset>
  </div>
</template>

<script>
import ColorPicker from '@/createandpublish/components/Forms/ColorPicker';

export default {
  components: {
    ColorPicker,
  },
  props: {
    colors: {
      type: Object,
      default: () => ({
        background: { hex: '#222046' },
        text: { hex: '#ffffff' },
        accent: { hex: '#532aa2' },
      }),
    },
  },
  data() {
    return {
      showBackgroundPicker: false,
      showTextPicker: false,
      showAccentPicker: false,
      colorValues: this.colors,
    };
  },
  methods: {
    toggleColorPicker(type) {
      const validTypes = ['background', 'text', 'accent'];
      if (!validTypes.includes(type)) {
        throw new Error(`${type} is not a valid custom color type`);
      }

      this.closeColorPickers();

      switch (type) {
        case 'background':
          this.showBackgroundPicker = true;
          break;
        case 'text':
          this.showTextPicker = true;
          break;
        case 'accent':
          this.showAccentPicker = true;
          break;
      }

      document.addEventListener('keydown', (event) => {
        if (event.key === 'Escape') {
          this.closeColorPickers();
        }
      });
    },
    closeColorPickers() {
      this.showBackgroundPicker = false;
      this.showTextPicker = false;
      this.showAccentPicker = false;

      document.removeEventListener('keydown', (event) => {
        if (event.key === 'Escape') {
          this.closeColorPickers();
        }
      });
    },
    handleUpdate(type, data) {
      this.colorValues[type] = data;
      this.$emit('update', this.colorValues);
    },
  },
};
</script>

<style lang="scss" scoped>
.custom {
  font: var(--fm-font-style-normal) normal var(--fm-font-weight-normal) var(--fm-font-size-16) /
    var(--fm-line-spacing-24) var(--fm-font-family-roboto);
  letter-spacing: var(--fm-character-spacing-0);
  color: var(--neutral-grey-1-text);
  text-align: left;
  margin-bottom: 1.5rem;
  &__legend {
    font-family: var(--fm-font-family-roboto);
    font-weight: 500;
    color: var(--neutral-grey-1-text);
    text-align: left;
    letter-spacing: 0px;
    opacity: 1;
    font-size: 1rem;
    line-height: 1.5rem;
  }
  &__fieldset {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
  }
  &__input {
    appearance: none;
    margin: 0;
    display: block;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    border: 2px solid #6e6e7a;
    margin-right: 0.938rem;
    &:checked {
      &::before {
        content: '';
        display: block;
        width: 1.125rem;
        height: 1.125rem;
        background: #73b657;
        border-radius: 50%;
        margin: 1px 0 0 1px;
      }
    }
  }
  &__label {
    flex: 0 0 auto;
    margin-right: 1.5rem;
    font-size: 1rem;
    line-height: 1.5rem;
    color: #000;
    font-weight: normal;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__list {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  &__list-item {
    padding: 0;
    margin: 0;
    margin-bottom: 0.5rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  &__button {
    display: block;
    width: 1.5rem;
    height: 1.5rem;
    border: 1px solid #dadae0;
    border-radius: 2px;
    background: red;
    margin-right: 0.75rem;
    &:focus:not(:active) {
      outline: rgba(0, 198, 198, 0.5) solid 4px !important;
    }
  }
  &__color-picker-wrapper {
    position: relative;
  }
  &__color-picker {
    position: absolute;
    top: 50%;
    left: 2.75rem;
    transform: translateY(-50%);
    z-index: 99999;
  }
}
</style>
