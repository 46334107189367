<template>
  <div class="wrapper">
    <div class="showing">
      {{ fromTo }}
    </div>
    <fm-pagination :totalItems="totalItems" @fm-page-update="onPageUpdate" :perPage="pageSize" ref="pagination" />
    <div class="items-per-page">
      <div>Items per page</div>
      <fm-select :options.prop="pageSizeOptions" :value.prop="pageSize" @input="onPageSizeUpdate" class="dropdown" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'pagination',

  props: {
    onPageSizeUpdate: Function,
    onPageUpdate: Function,
    pageSize: Number,
    pageSizeOptions: Array,
    totalItems: Number,
    currentPage: Number,
  },

  computed: {
    fromTo() {
      const _from = this.getPaginationFrom(this.currentPage, this.pageSize, this.totalItems);
      const _to = this.getPaginationTo(this.currentPage, this.pageSize, this.totalItems);
      return `Viewing ${_from}-${_to} of ${this.totalItems} items`;
    },
  },

  methods: {
    // Get pagination `from` number
    getPaginationFrom(currentPage, pageSize, totalElements) {
      if (currentPage === 1) {
        if (totalElements === 0) return 0;
        return 1;
      }
      return (currentPage - 1) * pageSize + 1;
    },

    // Get pagination `from` number
    getPaginationTo(currentPage, pageSize, totalElements) {
      return Math.min(currentPage * pageSize, totalElements);
    },
  },
  async mounted() {
    await window.customElements.whenDefined('fm-pagination');
    await this.$nextTick();
    if (this.$refs.pagination) {
      this.$refs.pagination._setPage(this.currentPage);
    }
  },
  watch: {
    pageSize: function () {
      this.$refs.pagination._setPage(1);
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 48px 0;
  width: 100%;
  div.showing,
  div.items-per-page {
    align-items: center;
    color: var(--neutral-grey-1-text);
    display: flex;
    font-size: 16px;
    font-weight: bold;
  }
  .dropdown {
    height: 52px;
    margin-left: 15px;
  }
}
</style>
