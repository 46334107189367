<template>
  <div class="receipt">
    <h3 class="form-subtitle">
      <span>Your receipt</span>
    </h3>
    <div class="loading-spinner" v-if="isLoading">
      <Loading :isLoading="true" />
    </div>
    <div v-if="invoice.uid">
      <div class="info">Invoice #{{ invoice.uid }}</div>
      <!-- <div>Receipt #2683-8683</div> -->
      <div class="sep"></div>
      <div class="receipt-summary" v-if="invoice.total_amount">
        <div>
          <h5 class="heading-h5">Amount</h5>
          ${{ formatCurrency(invoice.total_amount) }}
        </div>
        <div>
          <h5 class="heading-h5">Date</h5>
          {{ formatDate(invoice.issue_date) }}
        </div>
        <div>
          <h5 class="heading-h5">Payment Method</h5>
          {{ paymentType }} ending in ••••{{ paymentNumber }}
        </div>
      </div>
      <div class="sep" v-if="invoice.total_amount"></div>
      <h5>Summary</h5>
      <div class="summary">
        <h6>{{ dateRange }}</h6>
        <div class="sep"></div>
        <div class="row">
          <div class="product-description">
            <h6>{{ data.receipt.product.name }}</h6>
            <div v-if="['discover', 'suite'].includes(data.app)">
              <!-- Access to Discover for <strong v-text="`${data.discover} user${data.discover !== 1 ? 's' : ''}`"></strong> -->
              Access to Discover for <strong v-text="`${pluralize(data.discover, 'user')}`"></strong>
            </div>
            <div v-if="['create-and-publish', 'suite'].includes(data.app)">
              Access to Create &amp; Publish for
              <strong v-text="`${pluralize(data.create, 'user')}`"></strong>
            </div>
            <div v-if="this.data.brands">
              <span>{{ pluralize(data.brands, 'brand') }}</span>
            </div>
          </div>
          <div class="amount">${{ formatCurrency(invoice.subtotal_amount) }}</div>
        </div>
        <div class="sep"></div>
        <div class="row">
          <div>
            <span class="summary-label">Subtotal</span>
          </div>
          <div class="amount">${{ formatCurrency(invoice.subtotal_amount) }}</div>
        </div>
        <div class="row">
          <div>
            <span class="summary-label">Tax</span>
            ({{ data.state }}, {{ taxRate }}%)
          </div>
          <div class="amount">${{ formatCurrency(invoice.tax_amount) }}</div>
        </div>
        <div class="row amount-paid">
          <div>
            <span class="summary-label">Amount Paid</span>
          </div>
          <div class="amount">${{ formatCurrency(invoice.paid_amount) }}</div>
        </div>
      </div>
      <div class="sep"></div>
      <a :href="invoice.public_url" target="_blank" rel="noopener noreferrer" class="download-link">
        <svg width="12" height="15" viewBox="0 0 12 15" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M10.6848 2.98742L8.41246 0.715127C8.16871 0.471377 7.83829 0.333252 7.49433 0.333252H1.96663C1.24892 0.33596 0.666626 0.918252 0.666626 1.63596V12.9026C0.666626 13.6203 1.24892 14.2026 1.96663 14.2026H9.76663C10.4843 14.2026 11.0666 13.6203 11.0666 12.9026V3.90825C11.0666 3.56429 10.9285 3.23117 10.6848 2.98742ZM9.661 3.80263H7.59996V1.74159L9.661 3.80263ZM1.96663 12.9026V1.63596H6.29996V4.45263C6.29996 4.81284 6.58975 5.10263 6.94996 5.10263H9.76663V12.9026H1.96663ZM8.74288 9.01075C8.41246 8.68575 7.46996 8.77513 6.99871 8.83471C6.53288 8.55033 6.22142 8.15763 6.00204 7.58075C6.10767 7.14471 6.27558 6.48117 6.14829 6.06409C6.03454 5.3545 5.12454 5.42492 4.99454 5.90429C4.87538 6.34034 4.98371 6.947 5.18413 7.72159C4.91329 8.36888 4.50975 9.23825 4.22538 9.73658C3.68371 10.0155 2.95246 10.4462 2.84413 10.9878C2.75475 11.4158 3.54829 12.4828 4.90517 10.1428C5.51183 9.94242 6.17267 9.69596 6.75767 9.59846C7.26954 9.87471 7.86808 10.0589 8.26892 10.0589C8.95954 10.0589 9.02725 9.29513 8.74288 9.01075ZM3.37767 11.1178C3.51579 10.7468 4.04121 10.3189 4.201 10.1699C3.68642 10.9905 3.37767 11.1368 3.37767 11.1178ZM5.58767 5.95575C5.78808 5.95575 5.76913 6.82513 5.63642 7.06075C5.51725 6.68429 5.51996 5.95575 5.58767 5.95575ZM4.92683 9.65534C5.18954 9.19763 5.41433 8.65325 5.59579 8.17388C5.82058 8.58284 6.10767 8.91054 6.411 9.13533C5.84767 9.25179 5.35746 9.49013 4.92683 9.65534ZM8.491 9.51992C8.491 9.51992 8.35558 9.68242 7.48079 9.30867C8.43142 9.23825 8.5885 9.45492 8.491 9.51992Z"
            fill="#0174B7"
          />
        </svg>
        <span>Download invoice</span>
      </a>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import ChargifyService from '@/services/ChargifyService';
import { capitalize, maybePluralize, formatCurrency as formatCurrencyUtil } from '../../utils';
import Loading from '@/components/common/Loading.vue';

export default Vue.extend({
  name: 'receipt',

  components: {
    Loading,
  },

  props: {
    data: {},
  },

  computed: {
    paymentType() {
      return capitalize(this.data.receipt.credit_card.card_type);
    },

    paymentNumber() {
      const cardNumber = this.data.receipt.credit_card.masked_card_number;
      return cardNumber.substring(cardNumber.length - 4, cardNumber.length);
    },

    dateRange() {
      return `${this.formatDate(this.data.receipt.current_period_started_at)} - ${this.formatDate(
        this.data.receipt.current_period_ends_at
      )}`;
    },

    taxRate() {
      const subTotal = this.invoice.subtotal_amount;
      const tax = this.invoice.tax_amount;
      //6 decimal places for accuracy
      const tax_rate = (((tax / subTotal) * 1000) / 1000).toFixed(6);
      //2 decimal places for display
      return (tax_rate * 100).toFixed(2);
    },
  },

  data() {
    return {
      invoice: {},
      isLoading: false,
    };
  },

  methods: {
    handleInputChange(e) {
      this.form[e.target.name] = e.target.value;
    },

    async getInvoice() {
      this.isLoading = true;
      const service = new ChargifyService();
      const response = await service.getInvoicesById(this.data.receipt.id);
      this.isLoading = false;

      if (typeof response === 'string' || response?.errors) {
        this.alertMessage = {
          type: 'critical',
          header: `We couldn't load your reciept`,
          message: response?.errors?.join(`<br /> \n`) || 'There was a server error while processing your request',
        };
        this.displayAlert = true;
        this.formIsLoading = false;
      } else {
        this.$emit('showAlert');
        this.invoice = response.invoices[0];
      }
    },
    formatCurrency(amount) {
      return formatCurrencyUtil(amount);
    },
    formatDate(timestamp) {
      const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
      const d = new Date(timestamp);
      return `${months[d.getMonth()]} ${d.getDate()}, ${d.getFullYear()}`;
    },

    pluralize(count, noun) {
      return maybePluralize(count, noun);
    },
  },

  mounted() {
    this.getInvoice();
  },
});
</script>

<style lang="scss" scoped>
// missing styles used on responsive and pdf icon
.receipt {
  background: var(--neutral-white);
  box-shadow: 0px 1px 7px 0px rgba(34, 32, 70, 0.2);
  padding: 24px;
  margin-bottom: 32px;
  // min-width: 438px;
}

.form-subtitle {
  align-items: center;
  color: var(--neutral-grey-1-text);
  display: flex;
  font-size: 24px;
  justify-content: space-between;
  margin-bottom: 16px;
}

.sep {
  background-color: var(--neutral-grey-3-borders);
  height: 1px;
  // border-bottom: 1px solid var(--neutral-grey-3-borders);
  margin: 24px 0;
}

.info {
  color: var(--neutral-grey-2-lighter-text);
  font-size: var(--fm-font-size-14);
  line-height: var(--fm-line-spacing-21);

  @media (max-width: 768px) {
    margin-bottom: 8px;
  }
}

.summary {
  background: var(--neutral-grey-4-light-bg);
  display: flex;
  flex-direction: column;
  padding: 24px;

  .row {
    color: var(--neutral-grey-2-lighter-text);
    display: flex;
    font-size: var(--fm-font-size-14);
    line-height: var(--fm-line-spacing-21);
    justify-content: space-between;
    margin-left: 0;
    margin-right: 0;
  }

  &-label {
    color: var(--neutral-grey-1-text);
    font-size: var(--fm-font-size-16);
    line-height: var(--fm-line-spacing-24);
  }

  .amount {
    color: var(--neutral-grey-1-text);
    font-size: var(--fm-font-size-16);
    font-weight: 700;
    line-height: var(--fm-line-spacing-24);
  }

  .product-description {
    max-width: 70%;

    span {
      font-weight: bold;
    }
  }

  .sep {
    margin: 16px 0;
  }
}

h5 {
  color: var(--neutral-grey-2-lighter-text);
  font-size: var(--fm-font-size-16);
  font-weight: 500;
  letter-spacing: 0.08em;
  line-height: var(--fm-line-spacing-16);
  text-transform: uppercase;

  @media (max-width: 768px) {
    margin-bottom: 4px;
  }
}

h6 {
  color: var(--neutral-grey-1-text);
  font-size: var(--fm-font-size-16);
  font-weight: 700;
  line-height: var(--fm-line-spacing-24);
}

.receipt-summary {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  div {
    color: var(--neutral-grey-1-text);
    font-size: var(--fm-font-size-16);
    line-height: var(--fm-line-spacing-24);
    @media (max-width: 768px) {
      margin-bottom: 32px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;
  }
}

.download-link {
  color: var(--accent-links);
  font-size: var(--fm-font-size-16);
  line-height: var(--fm-line-spacing-24);
  letter-spacing: 0.384px;

  span {
    margin-left: 7px;
  }
}

.row.amount-paid {
  margin-top: 16px;

  .amount,
  .summary-label {
    font-size: var(--fm-font-size-20);
    font-weight: 500;
    line-height: var(--fm-line-spacing-28);
  }
}

.loading-spinner {
  display: flex;
  justify-content: center;
}
</style>
