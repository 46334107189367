












import { Component, Vue, Prop } from 'vue-property-decorator';
import { Bar as BarChart } from 'vue-chartjs/legacy';
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js';
import type { ChartData, ChartOptions } from 'chart.js';
import type { ChartConfig } from '@/types/audience';

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale);

@Component({
  name: 'BaseBarChart',
  components: {
    BarChart,
  },
})
export default class BaseBarChart extends Vue {
  @Prop({ type: Object, required: true }) chartConfig!: ChartConfig;
  @Prop({ type: Object, required: true }) chartOptions!: ChartOptions;
  @Prop({ type: Object, required: true }) chartData!: ChartData;
}
