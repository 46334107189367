import { render, staticRenderFns } from "./CampaignInput.vue?vue&type=template&id=521be8cf&scoped=true&"
import script from "./CampaignInput.vue?vue&type=script&lang=ts&"
export * from "./CampaignInput.vue?vue&type=script&lang=ts&"
import style0 from "./CampaignInput.vue?vue&type=style&index=0&id=521be8cf&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "521be8cf",
  null
  
)

export default component.exports