import { render, staticRenderFns } from "./EditVideoDrawer.vue?vue&type=template&id=589a6515&scoped=true&"
import script from "./EditVideoDrawer.vue?vue&type=script&lang=ts&"
export * from "./EditVideoDrawer.vue?vue&type=script&lang=ts&"
import style0 from "./EditVideoDrawer.vue?vue&type=style&index=0&id=589a6515&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "589a6515",
  null
  
)

export default component.exports