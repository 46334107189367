import HttpClient from '@/services/common/HttpClient';
import { Subscription } from '@/types/account/AccountData';

import type {
  PricesResponse,
  SubscriptionRequest,
  SubscriptionResponse,
  TrialRequest,
  AudienceRequest,
  Invoice,
  InvoiceResponse,
  PaymentProfile,
  NewPaymentProfilePayload,
  PaymentProfileResponse,
  UpdateSubscriptionRequest,
} from '@/types/ecomm';

export default class ChargifyService {
  private http: HttpClient = HttpClient.getInstance();
  private baseURL = 'ecomm';

  public getCustomerByEmail(emailAddress: string): Promise<boolean> {
    return this.http.post(`${this.baseURL}/customer/existing`, { email: emailAddress });
  }

  public getPrices(): Promise<PricesResponse> {
    return this.http.get(`${this.baseURL}/prices`);
  }

  public createSubscription(payload: SubscriptionRequest): Promise<SubscriptionResponse> {
    return this.http.post(`${this.baseURL}/subscription`, payload);
  }

  public updateSubscription(id: number, payload: UpdateSubscriptionRequest): Promise<SubscriptionResponse> {
    return this.http.put(`${this.baseURL}/subscription/${id}`, payload);
  }

  public getInvoices(page: number, size: number, sortType = 'issue_date', sortDir = '+'): Promise<InvoiceResponse> {
    return this.http.get(`/billing/invoices?sortBy=${sortDir + sortType}&limit=${size}&offset=${page}`);
  }

  public getInvoicesById(id: number): Promise<Invoice> {
    return this.http.get(`${this.baseURL}/subscription/${id}/invoices`);
  }

  public createTrial(payload: TrialRequest): Promise<SubscriptionResponse> {
    return this.http.post(`${this.baseURL}/trial`, payload);
  }

  public createAudience(payload: AudienceRequest): Promise<SubscriptionResponse> {
    return this.http.post(`${this.baseURL}/audience`, payload);
  }

  public getPaymentProfiles(): Promise<PaymentProfileResponse[]> {
    return this.http.get(`${this.baseURL}/payment`);
  }

  public createPaymentProfile(payload: NewPaymentProfilePayload): Promise<PaymentProfileResponse> {
    return this.http.post(`${this.baseURL}/payment`, payload);
  }

  public updateSubscriptionDefaultPaymentProfile(
    subscriptionId: Subscription['subscriptionId'],
    paymentProfileId: PaymentProfile['id']
  ): Promise<PaymentProfileResponse> {
    return this.http.post(`${this.baseURL}/subscription/${subscriptionId}/updatePayment`, { paymentProfileId });
  }

  public deletePaymentProfile(id: PaymentProfile['id']): Promise<void> {
    return this.http.delete(`${this.baseURL}/payment/${id}`);
  }
}
