<template>
  <div :class="wrapperClass">
    <Header v-if="isCheckoutFlow" />
    <Login :flow="flow" />
  </div>
</template>

<script>
import Vue from 'vue';

import Header from '@/components/ecomm/Header.vue';
import Login from '@/components/Login.vue';

export default Vue.extend({
  name: 'login',
  components: {
    Header,
    Login,
  },
  metaInfo: {
    htmlAttrs: { class: 'app-responsive' },
    meta: [{ name: 'viewport', content: `width=device-width,initial-scale=1` }],
  },
  data() {
    return {
      flow: 'default',
    };
  },
  computed: {
    wrapperClass() {
      return this.flow === 'default' ? 'wrapper' : `wrapper-${this.flow}`;
    },
    isCheckoutFlow() {
      return this.flow === 'checkout';
    },
  },
  mounted() {
    if (this.$route.meta && this.$route.meta.flow) {
      this.flow = this.$route.meta.flow;
    }
  },
});
</script>

<style lang="scss" scoped>
.wrapper {
  align-items: center;
  background: linear-gradient(164.21deg, var(--accent-links) 7.88%, #015498 92.07%);
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 64px;
  width: 100vw;

  &-checkout {
    background: var(--neutral-grey-5-lightest-bg);
  }

  &-audience {
    background: var(--neutral-white);
  }
}
</style>
