












import { Component } from 'vue-property-decorator';
import Vue from 'vue';
import { Getter } from 'vuex-class';
import Button from '@/components/common/buttons/Button.vue';
import ShowsList from '@/createandpublish/views/ShowsList.vue';
import AlertDisplay from '@/components/common/AlertDisplay.vue';

@Component({
  components: {
    Button,
    ShowsList,
    AlertDisplay,
  },
  computed: {
    hasShows() {
      const shows = this.$store.getters['CreateAndPublishStore/unfilteredShows'];
      return Array.isArray(shows) && shows.length > 0;
    },
  },
})
export default class Podcasts extends Vue {
  shouldShowAlert = false;
  alertMessage;
  @Getter selectedBrand;

  showAlert(title) {
    this.shouldShowAlert = false;
    this.alertMessage = {
      type: 'critical',
      header: 'Show Deleted',
      message: `${title} has been deleted`,
    };
    this.shouldShowAlert = true;
    this.$nextTick(() => {
      const alert = this?.$refs?.alert as Vue;
      if (alert) {
        alert.$el.scrollIntoView({ block: 'end', behavior: 'smooth' });
      }
    });
  }

  newShow() {
    this.$router.push(`/create/${this.selectedBrand.id}/podcasts/shows/edit/new`);
  }

  mounted() {
    // this.$store.commit('setPodcastsActive', true);
  }
}
