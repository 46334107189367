<template>
  <div class="step-bubbles" :aria-label="ariaLabel">
    <div class="step" v-for="step in totalSteps" :key="step" aria-hidden="true">
      <div :class="['bubble', { filled: step <= currentStep }]">
        <fm-text class="step-text">
          {{ step }}
        </fm-text>
      </div>
      <div v-if="step < totalSteps" :class="['separator', { filled: step <= currentStep - 1 }]"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'step-bubbles',

  props: {
    currentStep: {
      type: Number,
      required: true,
    },
    totalSteps: {
      type: Number,
      required: true,
    },
  },

  computed: {
    ariaLabel() {
      return `Step ${this.currentStep} of ${this.totalSteps}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.step-bubbles {
  display: flex;

  .step {
    display: flex;
    align-items: center;
    min-width: 48px;
    flex-grow: 1;

    .bubble {
      width: 48px;
      height: 48px;
      flex: 0 0 48px;
      background-color: var(--neutral-white);
      border: 4px solid var(--neutral-grey-3-borders);
      border-radius: 48px;
      display: flex;
      justify-content: center;
      align-items: center;
      box-sizing: border-box;

      .step-text::part(tag) {
        font-weight: medium;
        font-size: 30px;
        line-height: 38px;
      }

      &.filled {
        background-color: var(--brand-dark);
        border: 4px solid var(--brand-dark);

        .step-text::part(tag) {
          color: var(--neutral-white);
        }
      }
    }

    .separator {
      height: 4px;
      background-color: var(--neutral-grey-3-borders);
      flex-grow: 1;
      flex-shrink: 1;

      &.filled {
        background-color: var(--brand-dark);
      }
    }
  }

  :last-child {
    flex-grow: 0;
  }
}
</style>
