<template>
  <div
    class="settings-view"
    :data-qa="$getComponent('Settings', 'Parent', 'settingsPage')"
    v-bind:class="{ expanded: sidebarData }"
  >
    <div class="settings">
      <AlertDisplay
        :alertMessage="alertBrandMessageError"
        class="alertMessage"
        :drawer="true"
        v-if="showBrandErrorMessage"
      />
      <AddEditRSSFeeds
        id="discover-rss-feed-drawer"
        :key="`editFeedData.id-${Date.now()}`"
        :feedData="editFeedData"
        :rssFeeds="this.rssFeeds"
        @refreshData="refreshData"
        @reset="resetDrawerData"
        :data-qa="$getComponent('Settings', 'Drawer', 'addEditRSSFeedsDrawer')"
      />
      <h2 class="headline-h2">Discover settings</h2>
      <p class="headline-text">Customize sources and configurable settings for your Discover feeds.</p>
      <Tabs
        :activeTabIndex="activeTab"
        :titles="[
          { title: 'Trending sources' },
          { title: 'Custom RSS' },
          // , { title: 'Twitter' }
        ]"
        :data-qa="$getComponent('Settings', 'Tabs', 'settingsTabs')"
      >
        <TrendingSettingsTab slot="tab1" id="trending-sources-tab" :brandId="selectedBrand.id" />

        <template slot="tab2">
          <!-- RSS Feeds Tab -->
          <div class="rss-feeds" id="rss-feeds-tab" :data-qa="$getComponent('Settings', 'Tab', 'rssFeedsTab')">
            <div class="rss-feeds-section">
              <div class="categories-description">
                <h3 class="tab-content-section-header">Add RSS feeds <span class="optional-text">(OPTIONAL)</span></h3>
                <p class="tab-content-section-text">
                  Connect RSS feeds to view all your favorite feeds in your Following tab.
                </p>
              </div>
              <div class="rss-feeds-card">
                <rss-feeds-card
                  :feedData="this.rssFeeds"
                  edit="edit"
                  @edit="editFeed"
                  refresh="refreshData"
                  @refreshData="refreshData"
                  :data-qa="$getComponent('Settings', 'Card', 'rssFeedsCard')"
                />
              </div>
            </div>
          </div>
        </template>

        <template slot="tab3">
          <!-- Twitter Tab -->
          <div class="twitter" id="twitter-tab" :data-qa="$getComponent('Settings', 'Tab', 'twitterTab')">
            <div class="twitter-section">
              <div class="categories-description">
                <h3 class="tab-content-section-heade">
                  Connect to Twitter content <span class="optional-text">(OPTIONAL)</span>
                </h3>
                <p class="tab-content-section-text">
                  Connect Twitter for content to be shown in your Trending stories feed.
                </p>
              </div>
              <div class="twitter-cards">
                <div class="twitter-auth-card">
                  <twitter-auth-card :data-qa="$getComponent('Settings', 'Card', 'twitterAuthCard')" />
                  <!-- <twitter-hashtags-card :data-qa="$getComponent('Settings', 'Card', 'twitterHashtagsCard')" />
                <twitter-accounts-card :data-qa="$getComponent('Settings', 'Card', 'twitterAccountsCard')" /> -->
                </div>
              </div>
            </div>
          </div>
        </template>
      </Tabs>
    </div>
  </div>
</template>

<script>
import { Component, Vue } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import Select from '@/components/common/form/Select.vue';
import Tabs from '@/components/common/tabs/Tabs.vue';
import Button from '@/components/common/buttons/Button.vue';
import YourShortcutsCard from '@/components/discover/cards/YourShortcutsCard.vue';
import TwitterAuthCard from '@/components/discover/cards/TwitterAuthCard.vue';
import TwitterHashtagsCard from '@/components/discover/cards/TwitterHashtagsCard.vue';
import TwitterAccountsCard from './cards/TwitterAccountsCard.vue';
import RssFeedsCard from '@/components/discover/cards/RssFeedsCard.vue';
import DiscoverSettingsService from '@/services/DiscoverSettingsService';
import AddEditRSSFeeds from '@/components/discover/drawer/AddEditRSSFeeds.vue';
import CollapsibleSidebar from '@/components/common/CollapsibleSidebar.vue';
import BrandsSubHeader from '@/components/brands/BrandsSubHeader.vue';
import AlertDisplay from '@/components/common/AlertDisplay.vue';
import TrendingSettingsTab from '@/components/discover/settings/TrendingSettingsTab.vue';

@Component({
  components: {
    Select,
    Tabs,
    Button,
    YourShortcutsCard,
    TwitterAuthCard,
    TwitterHashtagsCard,
    TwitterAccountsCard,
    RssFeedsCard,
    AddEditRSSFeeds,
    CollapsibleSidebar,
    BrandsSubHeader,
    AlertDisplay,
    TrendingSettingsTab,
  },
  data: () => {
    return {
      alertBrandMessageError: {
        header: 'Brand selection error',
        type: 'critical',
        message: 'Please select a brand from above',
      },

      showBrandErrorMessage: false,
      sidebarOptions: [
        {
          name: 'Trending',
          image: 'TrendingIcon.svg',
          active: false,
          store: 'DiscoverStore',
          activeLabel: 'trendingActive',
          path: '/discover',
        },
        {
          name: 'Following',
          image: 'Exclude.svg',
          active: false,
          store: 'DiscoverStore',
          activeLabel: 'followingActive',
          path: '/discover/following',
        },
        {
          name: 'Settings',
          image: 'Settings.svg',
          active: true,
          store: 'DiscoverStore',
          activeLabel: 'settingsActive',
          path: '',
        },
      ],
      brandId: 1,
      rssFeeds: [],
      sourcesActive: true,
      feedsActive: false,
      twitterActive: false,
      editFeedData: {},
      toggleTrending: true,
      toggleFollowing: true,
      toggleSettings: true,
      activeTab: 0,
      buttonLoadingStatus: false,
    };
  },
  watch: {
    selectedBrand: function (brand) {
      if (brand && brand.id) {
        this.showBrandErrorMessage = false;
      }
    },
  },
  props: {
    activeTabIndex: {
      type: Number,
      default: () => 0,
    },
  },
})
export default class Settings extends Vue {
  @Action toggleSidebarStatus;
  @Getter selectedBrand;
  sidebarProp = true;
  sidebarData = '';
  async fetchRSSFeeds() {
    this.loaderVisible = true;
    const response = await new DiscoverSettingsService().getFeeds(this.selectedBrand.id);
    this.rssFeeds = response.filter((feed) => feed.active === true);
    this.rssFeeds = this.sortAlphabetically(this.rssFeeds);
  }

  sortAlphabetically(feeds) {
    let temp = feeds;
    temp = temp.sort((a, b) => {
      const textA = a.feedName.toUpperCase();
      const textB = b.feedName.toUpperCase();
      return textA < textB ? -1 : textA > textB ? 1 : 0;
    });

    return temp;
  }

  titleCase(string) {
    return string[0].toUpperCase() + string.slice(1).toLowerCase();
  }

  async mounted() {
    await this.fetchRSSFeeds();
    this.switchTabIndex();
  }

  refreshData() {
    this.fetchRSSFeeds();
  }

  editFeed(feed) {
    this.editFeedData = feed;
    this.$emit('bv::toggle::collapse', 'discover-rss-feed-drawer');
  }

  resetDrawerData() {
    this.editFeedData = {};
  }

  onSidebarClick(value) {
    this.sidebarData = value;
    this.toggleSidebarStatus(this.sidebarData);
  }

  async triggerActionSettings(option) {
    const action = option[0];
    const isActive = option[1];
    this.$data.sidebarOptions.map((sidebarOption) => {
      if (sidebarOption.activeLabel === action && isActive) {
        this.switchRoute(sidebarOption.path);
      }
    });
  }

  switchRoute(newRoute) {
    if (this.$route.path !== newRoute && newRoute !== '') {
      this.$router.push(newRoute);
    }
  }

  switchTabIndex() {
    this.$data.activeTab = this.$route.query.tabIndex
      ? parseInt(this.$route.query.tabIndex)
      : this.$props.activeTabIndex;
  }
}
</script>

<style lang="scss" scoped>
template {
  width: 100%;
}

.settings {
  font-family: 'Roboto';
  padding: 2.1em 2em;
  width: 100%;
  background: var(--neutral-grey-5-lightest-bg);
}

.headline-text {
  font-weight: 400;
  font-size: 20px;
  margin: 24px 0;
}

.twitter-section {
  display: flex;
  padding: 2rem 0 0 0;
}

.rss-feeds-section {
  display: flex;
  padding: 2rem 0 0 0;
}

.optional-text {
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 2px;
  color: var(--neutral-grey-2-lighter-text);
  margin-left: 1rem;
}

.twitter-cards {
  width: 50%;
  display: flex;
  flex-direction: column;
}
.rss-feeds-card {
  width: 50%;
}

.button-text {
  height: 24px;
  width: 104px;
  position: relative;
  left: 2px;
  top: 8px;
  border-radius: nullpx;
}

.contact-link {
  color: var(--accent-links);
  text-decoration: underline;

  &:hover {
    cursor: pointer;
  }
}

.twitter {
  display: flex;
  flex-direction: column;
}

.section-body {
  display: flex;
  flex-direction: row;
  margin: 1.5em 1.5em 0 0;
  min-width: 100%;
}

//sidebar
.settings-view {
  width: '100%';
  transition: 0.5s ease-in-out;
}

.settings-view.expanded {
  padding-left: 286px;
  width: '100%';
}

.categories-description {
  width: 50%;
  padding-right: 77px;
}

.tab-content-section-header {
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
}

.tab-content-section-text {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}
</style>
