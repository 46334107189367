var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('fm-card',{domProps:{"expandWidth":true}},[(_vm.deleteModalIsOpen)?_c('ConfirmationModal',{attrs:{"Status":_vm.deleteShowStatus,"loading":_vm.deletingShow},on:{"close":_vm.onDeleteClose,"confirm":_vm.onDeleteConfirm}}):_vm._e(),_c('card-layout',[_c('div',{staticClass:"shows-list-card-image",attrs:{"slot":"image"},slot:"image"},[(!_vm.show.placeholder)?_c('router-link',{staticClass:"center",attrs:{"to":_vm.showDetailsRoute}},[(_vm.show.square_image.length > 0)?_c('img',{attrs:{"src":_vm.showImage}}):_c('img',{attrs:{"src":require("@/assets/post-logo.svg")}})]):_vm._e()],1),_c('div',{staticClass:"shows-list-card-meta",attrs:{"slot":"meta"},on:{"click":_vm.onCardClick},slot:"meta"},[_c('div',{staticClass:"tags-wrapper"},[_c('tag-list',{attrs:{"tags":_vm.categories,"show-id":_vm.show.id}})],1)]),_c('div',{staticClass:"shows-list-card-content",attrs:{"slot":"content"},slot:"content"},[_c('fm-text',{staticClass:"show-title nowrap",domProps:{"tag":'h2',"as":'h3'}},[_vm._v(" "+_vm._s(_vm.show.title)+" ")]),_c('div',{staticClass:"d-flex flex-column mb-3 pr-3 pl-3"},[_c('router-link',{attrs:{"to":{ path: _vm.showDetailsRoute, query: { tab: 'published' } },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
return [_c('fm-link',{staticClass:"nowrap mb-1",attrs:{"href":href,"external":"","title":_vm.publishedEpisodesText},on:{"click":function($event){$event.stopPropagation();return navigate.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.publishedEpisodesText)+" ")])]}}])}),_c('fm-text',{staticClass:"nowrap",attrs:{"variant":"disclaimer"}},[_vm._v(_vm._s(_vm.lastPublished))])],1),_c('div',{staticClass:"episodeStateBreakdown"},[_c('router-link',{attrs:{"to":{ path: _vm.showDetailsRoute, query: { tab: 'scheduled' } },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
return [_c('fm-link',{staticClass:"nowrap breakdown-link",attrs:{"href":href,"external":"","title":((_vm.show.counters.scheduled) + " scheduled")},on:{"click":function($event){$event.stopPropagation();return navigate.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.show.counters.scheduled)+" scheduled ")])]}}])}),_c('router-link',{attrs:{"to":{ path: _vm.showDetailsRoute, query: { tab: 'draft' } },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
return [_c('fm-link',{staticClass:"nowrap breakdown-link",attrs:{"href":href,"external":"","title":((_vm.show.counters.draft) + " drafts")},on:{"click":function($event){$event.stopPropagation();return navigate.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.show.counters.draft)+" drafts ")])]}}])}),_c('router-link',{attrs:{"to":{ path: _vm.showDetailsRoute, query: { tab: 'unpublished' } },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
return [_c('fm-link',{staticClass:"nowrap breakdown-link",attrs:{"href":href,"external":"","title":((_vm.show.counters.unpublished) + " unpublished")},on:{"click":function($event){$event.stopPropagation();return navigate.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.show.counters.unpublished)+" unpublished ")])]}}])})],1)],1),_c('fm-card-action-bar',{attrs:{"slot":"actionBar"},domProps:{"firstAction":_vm.actionBar.firstAction,"secondAction":_vm.actionBar.secondAction,"menuOptions":_vm.actionBar.menuOptions},slot:"actionBar"})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }