<template>
  <div class="rss-url-modal">
    <fm-modal :visible.prop="true">
      <div slot="controlRow" class="modal-control-row">
        <fm-text tag="h1" as="h3" class="header-text"> RSS Feed URL </fm-text>
        <close-button class="close-button" @click="close" />
      </div>

      <div slot="content" class="modal-content">
        <div class="form-wrapper">
          <fm-checkbox
            assistiveText="This will include any deleted episodes that were published to this RSS feed."
            v-model-wc="includeDeleted"
            :options.prop="includeDeletedOptions"
          />
        </div>
        <div class="rss-url">
          <fm-link external :href.prop="rssUrl" target="_blank">
            {{ rssUrl }}
          </fm-link>
        </div>
      </div>

      <div slot="actionRow" class="modal-action-row">
        <fm-button-primary @click="copyUrlToClipboard" :success.prop="success"> Copy to clipboard </fm-button-primary>
        <fm-button-secondary @click="close"> Close </fm-button-secondary>
      </div>
    </fm-modal>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import CloseButton from '@/createandpublish/components/CloseButton';

export default {
  name: 'rss-url-modal',

  props: {
    smc: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      includeDeleted: [],
      success: false,
    };
  },

  computed: {
    ...mapState(['station']),

    rssUrl() {
      const query = this.includeDeleted.includes('true') ? '?show_deleted=true' : '';
      return `https://post.futurimedia.com/${this.station}/playlist/rss/${this.smc.id}.xml${query}`;
    },

    includeDeletedOptions() {
      return [{ label: 'Include deleted episodes', value: 'true' }];
    },
  },

  methods: {
    close() {
      this.$emit('close');
    },

    copyUrlToClipboard() {
      if (navigator.clipboard) {
        navigator.clipboard.writeText(this.rssUrl);
        this.success = true;
      }
    },
  },

  components: {
    CloseButton,
  },
};
</script>

<style lang="scss" scoped>
.rss-url-modal {
  .modal-content {
    width: 500px;
    height: 150px;
    border: none;
  }
  .modal-action-row {
    width: 500px;
    display: flex;
    justify-content: center;
    margin-top: 24px;

    :first-child {
      margin-right: 16px;
    }
  }
  .modal-control-row {
    display: flex;
    margin-bottom: 24px;
    position: relative;
    width: 500px;

    .close-button {
      position: absolute;
      top: 0;
      right: 0;
    }
  }
}
</style>
