

































































































import { Vue, Component } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import CloseButton from '@/createandpublish/components/CloseButton.vue';
import YouTubeIcon from '@/audience/components/social/YouTubeIcon.vue';
import InstagramIcon from '@/audience/components/social/InstagramIcon.vue';
import FacebookIcon from '@/audience/components/social/FacebookIcon.vue';
import TwitterIcon from '@/audience/components/social/TwitterIcon.vue';
import TikTokIcon from '@/audience/components/social/TikTokIcon.vue';
import LinkedInIcon from '@/audience/components/social/LinkedInIcon.vue';
import PinterestIcon from '@/audience/components/social/PinterestIcon.vue';

const audienceModule = namespace('AudienceAnalyticsStore');

@Component({
  name: 'WelcomeTile',
  components: {
    CloseButton,
    YouTubeIcon,
    InstagramIcon,
    FacebookIcon,
    TwitterIcon,
    TikTokIcon,
    LinkedInIcon,
    PinterestIcon,
  },
})
export default class WelcomeTile extends Vue {
  @audienceModule.Action getAyrShareConnectUrl;

  onClose() {
    this.$emit('onClose');
  }

  async onConnectClick() {
    try {
      const url = await this.getAyrShareConnectUrl();
      const anchorEl = document.createElement('A') as HTMLAnchorElement;
      anchorEl.href = url;
      anchorEl.target = '_blank';
      const body = document.getElementsByTagName('BODY')[0];
      body.appendChild(anchorEl);
      anchorEl.click();
      body.removeChild(anchorEl);
    } catch (e) {
      console.error(e);
    }
  }
}
