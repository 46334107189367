/**
 * Memoizes an image
 * @param {String} imageSrc image location
 * @returns {Function} function that returns a cached HTMLImageElement, otherwise
 * creates it
 */
export function getMemoizedImage(
  imageSrc: string,
  width?: number,
  height?: number,
  style?: Record<string, string>
): () => Promise<HTMLImageElement> {
  const cache: Record<string, HTMLImageElement> = {};
  return async () => {
    if ('image' in cache) {
      return cache.image;
    }
    const image = new Image();
    image.src = imageSrc;
    if (width) {
      image.width = width;
    }
    if (height) {
      image.height = height;
    }
    if (style) {
      for (const [key, value] of Object.entries(style)) {
        image.style[key] = value;
      }
    }
    (await new Promise((resolve) => (image.onload = () => resolve(image)))) as HTMLImageElement;
    cache['image'] = image;
    return image;
  };
}

/**
 * Transforms number readable string, truncating if over (+/-)1,000
 * @param {Number} val percentage to format (e.g. .24 for 24%)
 * @returns {String} formatted value
 */
export function formatPercentChange(val: number): string {
  const absVal = Math.abs(val * 100);
  if (absVal > 999_999) {
    return (absVal / 1_000_000).toFixed(1) + 'M%';
  } else if (absVal > 99_999) {
    return (absVal / 1_000).toFixed(1) + 'K%';
  }
  return Number(absVal.toFixed(0)).toLocaleString() + '%';
}

/**
 * Calculates the percentage difference between an initial and final number
 * @param initialVal
 * @param finalVal
 * @returns percentage difference
 */
export function calculatePercentChange(initialVal: number, finalVal: number): number {
  if (initialVal === 0) return NaN;
  const diff = finalVal - initialVal;
  return diff / initialVal;
}
