<template>
  <div id="wrapper">
    <div class="form">
      <div class="form-header">
        <router-link to="/">
          <img alt="logo" src="@/assets/FuturiCC.svg" height="58" />
        </router-link>
      </div>
      <div class="form-content">
        <!-- Error message -->
        <AlertDisplay :alertMessage="alertMessageError" class="alertMessage" :drawer="true" v-if="showErrorMessage" />
        <AlertDisplay
          :alertMessage="expiredMessageError"
          class="alertMessage"
          :drawer="true"
          v-if="showExpiredMessage"
        />
        <div class="form-title">
          <h2>Reset your password</h2>
        </div>
        <form v-on:submit.prevent="$emit('onSubmit')">
          <Input
            :data-qa="$getComponent('PasswordHelp', 'TextInput', 'newPassword')"
            :error="passwordError"
            :inputHandler="handleInputChange"
            :value="form.password"
            assistiveText="10 characters or more, with at least one lowercase letter, one uppercase letter, and one special character."
            class="form-input"
            label="New password"
            name="password"
            type="password"
          />
          <Input
            :data-qa="$getComponent('PasswordHelp', 'TextInput', 'confirmNewPassword')"
            :error="confirmPasswordError"
            :inputHandler="handleInputChange"
            :value="form.confirmPassword"
            class="form-input"
            label="Confirm new password"
            name="confirmPassword"
            type="password"
          />
          <Button
            :data-qa="$getComponent('PasswordHelp', 'Button', 'submit')"
            :loading="formIsLoading"
            @click="onSubmit"
            buttonType="primary"
            class="form-submit"
            onClickEventHandler="click"
            type="submit"
          >
            Change password
          </Button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';

import AlertDisplay from '@/components/common/AlertDisplay.vue';
import Button from '@/components/common/buttons/Button.vue';
import Input from '@/components/common/form/Input.vue';
import UserService from '@/services/UserService';
import errorMessages from '@/utils/errorMessages.json';
import { required, sameAs } from 'vuelidate/lib/validators';
import { validatePassword } from '@/plugins/Validations';

export default Vue.extend({
  name: 'password-help',

  components: {
    AlertDisplay,
    Button,
    Input,
  },

  data() {
    return {
      // Banner for API request errors
      alertMessageError: {
        header: "We couldn't save your changes.",
        type: 'critical',
        message: 'Please try again in a few minutes.',
      },
      expiredMessageError: {
        header: 'Your reset password link has expired.',
        type: 'warning',

        links: [
          {
            message: 'Request a new password reset link here',
            route: '/forgot-password',
          },
        ],
      },
      form: {
        password: '',
        confirmPassword: '',
      },
      // Flags to display error/success banners
      showErrorMessage: false,
      showExpiredMessage: false,
      // If submitting form
      formIsLoading: false,
    };
  },

  computed: {
    passwordError() {
      if (!this.$v.form.password.$dirty) {
        return '';
      }
      if (!this.$v.form.password.required) {
        return `New password${errorMessages.required}`;
      }
      if (!this.$v.form.password.validatePassword) {
        const _symbols = '^ $ * . [ ] { } ( ) ? " ! @ # % & / \\ , > < \' : ; | _ ~ `';
        return `Passwords must contain 10 or more characters, with at least one 
lowercase letter, one uppercase letter, and one of these special 
characters: ${_symbols}`;
      }
      return '';
    },

    confirmPasswordError() {
      if (!this.$v.form.confirmPassword.$dirty) {
        return '';
      }
      if (!this.$v.form.confirmPassword.required) {
        return `Confirm new password${errorMessages.required}`;
      }
      if (!this.$v.form.confirmPassword.sameAs && this.form.confirmPassword && this.form.password) {
        return 'Passwords must match.';
      }
      return '';
    },
  },

  validations: {
    form: {
      password: { required, validatePassword },
      confirmPassword: { required, sameAs: sameAs('password') },
    },
  },

  methods: {
    handleInputChange(e) {
      this.form[e.target.name] = e.target.value;
    },

    async onSubmit() {
      const code = this.$route.query.code;
      const email = this.$route.query.un;
      this.$v.form.$touch();
      if (!this.$v.form.$invalid) {
        this.formIsLoading = true;
        this.showErrorMessage = false;
        const payload = {
          code,
          email,
          newPassword: this.form.confirmPassword,
        };

        const res = await new UserService().forgotPasswordConfirm(payload);

        if (res.errors) {
          if (res.code === 'ExpiredCodeException') {
            this.showExpiredMessage = true;
          } else {
            this.showErrorMessage = true;
          }
        } else {
          this.$router.push('/login?password_changed=true');
        }
        this.formIsLoading = false;
      }
    },

    onForgotPassword() {
      this.$router.push({ path: '/forgot-password' });
    },
  },
});
</script>

<style lang="scss" scoped>
#wrapper {
  align-items: flex-start;
  background: linear-gradient(164.21deg, var(--accent-links) 7.88%, #015498 92.07%);
  display: flex;
  height: 100vh;
  justify-content: center;
  padding: 64px;
  width: 100vw;

  .form {
    background: var(--neutral-white);
    box-shadow: 0px 1px 7px rgba(34, 32, 70, 0.2);
    max-width: 592px;

    @media (max-width: 768px) {
      padding: 48px 24px 24px;
      min-width: unset;
    }
  }

  .form-header {
    align-items: center;
    background: #f5f5f5;
    display: flex;
    height: 80px;
    justify-content: center;
  }

  .form-content {
    padding: 24px;
  }

  .form-title {
    color: var(--neutral-grey-1-text);
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: normal;
    line-height: 24px;
    margin-bottom: 32px;
  }

  .form-input {
    margin-bottom: 32px;
  }

  .form-submit {
    width: 100%;
  }

  .form-forgot-password {
    margin-top: 24px;
    text-align: center;
    width: 100%;
  }
}
</style>
