




























































































import { Component, Vue, Prop } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { Campaign } from '@/types/Campaign';
import { Brand } from '@/types/Brand';
import { ImageAsset } from '@/types/createandpublish/mediaLibraries/ImageLibrary';
import Drawer from '@/components/common/drawer/Drawer.vue';
import CampaignCircle from '@/components/brands/CampaignCircle.vue';
import CampaignMenu from '@/components/brands/CampaignMenu.vue';
import { formatDateTimeLong } from '@/createandpublish/core/filters';

@Component({
  name: 'EditImageDrawer',
  components: {
    Drawer,
    CampaignCircle,
    CampaignMenu,
  },
  filters: {
    formatFileSize(bytes) {
      const exp = (Math.log(bytes) / Math.log(1024)) | 0;
      const result = (bytes / Math.pow(1024, exp)).toFixed(2);
      // cSpell:ignore KMGTPEZY
      return result + ' ' + (exp == 0 ? 'bytes' : 'KMGTPEZY'[exp - 1] + 'B');
    },
    formatDateTimeLong,
  },
})
export default class EditImageDrawer extends Vue {
  @Prop({ required: true }) readonly imageData!: null | ImageAsset;
  @Prop({ required: false, default: null }) readonly file!: File | null;
  @Getter selectedBrand;

  form: { campaignIds: number[]; title: string; description: string } = {
    campaignIds: [],
    title: '',
    description: '',
  };
  fileSrc = '';
  fileDimensions = '';

  get isNewUpload(): boolean {
    return !!this.file;
  }

  get selectedImageCampaigns(): Campaign[] {
    return (this.selectedBrand as Brand).campaigns.filter(
      (campaign) => campaign.id && this.form.campaignIds.includes(campaign.id)
    );
  }

  get description(): string {
    return this.imageData?.description || '';
  }

  get lastUpdated(): number {
    return this.imageData?.timestamp_updated || NaN;
  }

  get filename(): string {
    if (this.isNewUpload) {
      return (this.file as File).name;
    }
    return this.imageData?.filename || '';
  }

  get filesize(): number {
    if (this.isNewUpload) {
      return this.$options?.filters?.formatFileSize((this.file as File).size) || '0 bytes';
    }
    return this.imageData?.file_size || NaN;
  }

  get dimensions(): string {
    if (this.isNewUpload) {
      return this.fileDimensions || '';
    }
    return this.imageData?.dimension ? this.imageData.dimension + 'px' : '';
  }

  get imageUrl(): string {
    if (this.isNewUpload) {
      return this.fileSrc || '';
    }
    return this.imageData?.source_url || '';
  }

  get drawerSubmitProps() {
    const getPayload = () => {
      const createNew = this.isNewUpload;
      const id = !createNew ? { id: this.imageData?.id } : {};
      const { title, description, campaignIds } = this.form;
      return {
        createNew,
        ...id,
        title,
        description,
        campaignIds,
      };
    };
    return {
      buttonText: 'Save changes',
      onClick: (hide: () => void) => {
        this.$emit('on-save-image', getPayload());
        hide();
      },
      qa: '',
    };
  }

  onCancel(hide: () => void) {
    this.$emit('on-cancel');
    hide();
  }

  resetPanel() {
    this.fileSrc = '';
    this.fileDimensions = '';
    this.form = {
      campaignIds: [],
      title: '',
      description: '',
    };
  }

  onOpen() {
    this.resetPanel();
    if (!this.isNewUpload && this.imageData) {
      const { campaigns, title, description } = this.imageData;
      this.form = {
        campaignIds: campaigns || [],
        title: title || '',
        description: description || '',
      };
    }
    if (this.isNewUpload) {
      const fr = new FileReader();
      fr.onload = () => {
        const result = fr.result as string;
        this.fileSrc = result;
        const image = new Image();
        image.src = result;
        image.onload = () => {
          const { naturalWidth, naturalHeight } = image;
          this.fileDimensions = `${naturalWidth}x${naturalHeight}px`;
        };
      };
      fr.readAsDataURL(this.file as File);
    }
  }

  onAddNewCampaign() {
    this.$emit('on-add-new-campaign');
  }

  onSocialShareClick() {
    this.$emit('on-social-share');
  }
}
