























































import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import moment from 'moment';
import type { GrowthTip } from '@/types/audience';

const audienceModule = namespace('AudienceAnalyticsStore');

@Component({
  name: 'GrowthTips',
  filters: {
    formatFromNow(val: string) {
      return moment(val).fromNow();
    },
  },
})
export default class GrowthTips extends Vue {
  @audienceModule.Getter('growthTips') growthTips!: GrowthTip[];
  @audienceModule.Mutation('DISMISS_GROWTH_TIP') dismissGrowthTip;

  tipIndex = 0;

  incrementTip() {
    this.tipIndex++;
  }

  decrementTip() {
    this.tipIndex--;
  }

  dismissCurrentTip(id: number) {
    this.dismissGrowthTip(id);
  }
}
