<template>
  <div class="new-show">
    <header>
      <fm-text tag="h1" v-if="$route.params.showId === 'new'">Create new show</fm-text>
      <fm-text tag="h1" v-else>Edit Show</fm-text>
      <div>
        <fm-button-secondary class="new-show__header-button" v-if="previousRouteName" @click="previous">
          Previous step
        </fm-button-secondary>
        <fm-button-primary
          class="new-show__header-button"
          v-if="nextRouteName"
          @click="next"
          :disabled.prop="shouldPreventNext"
          >Next step
        </fm-button-primary>
        <fm-button-primary
          class="new-show__header-button"
          v-else
          @click="saveShow(false)"
          :disabled.prop="shouldPreventNext"
        >
          Save show
        </fm-button-primary>
      </div>
    </header>

    <div>
      <fm-tabs>
        <router-link to="basic-info" v-slot="{ href, navigate }" custom>
          <fm-tab-item :href="href" @click="navigate" :selected="currentStep === 1"> 1. Add basic info </fm-tab-item>
        </router-link>

        <router-link to="display-details" v-slot="{ href, navigate }" custom>
          <fm-tab-item
            id="tab-item"
            :href="href"
            @click="navigate"
            :selected="currentStep === 2"
            :disabled="maxStepCompleted < 1"
          >
            2. Add display details
          </fm-tab-item>
        </router-link>

        <router-link to="publishing-destinations" v-slot="{ href, navigate }" custom>
          <fm-tab-item :href="href" @click="navigate" :selected="currentStep === 3" :disabled="maxStepCompleted < 2">
            3. Select publishing destinations
          </fm-tab-item>
        </router-link>
      </fm-tabs>
    </div>

    <main>
      <router-view
        @preventNext="preventNext"
        @allowNext="allowNext"
        @saveAndNav="onSaveAndNav"
        keep-alive
      ></router-view>
    </main>

    <footer>
      <fm-button-primary
        class="new-show__header-button"
        v-if="nextRouteName"
        @click="next"
        :disabled.prop="shouldPreventNext"
        >Next step
      </fm-button-primary>
      <fm-button-primary
        class="new-show__header-button"
        v-else
        @click="saveShow(false)"
        :disabled.prop="shouldPreventNext"
      >
        Save show
      </fm-button-primary>
      <fm-button-secondary class="new-show__header-button" v-if="previousRouteName" @click="previous">
        Previous step
      </fm-button-secondary>
    </footer>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
const { mapActions, mapState } = createNamespacedHelpers('CreateAndPublishStore/showSetup');
const { mapGetters } = createNamespacedHelpers('CreateAndPublishStore');

export default {
  data: () => ({
    shouldPreventNext: false,
  }),
  computed: {
    ...mapState(['draftShow', 'maxStepCompleted']),
    ...mapGetters(['selectedBrand']),

    nextRouteName() {
      return this.$route.meta.next;
    },
    currentStep() {
      return this.$route.meta.step;
    },
    previousRouteName() {
      return this.$route.meta.previous;
    },
    showId() {
      return this.$route.params.showId;
    },
  },
  methods: {
    ...mapActions(['updateShowAndMasterRSS', 'updateDraftShow']),
    next() {
      if (this.shouldPreventNext) return;
      if (this.maxStepCompleted < this.$route.meta.step) {
        this.$store.commit('CreateAndPublishStore/showSetup/SET_MAX_STEP_COMPLETED', this.$route.meta.step);
      }
      if (this.nextRouteName) {
        this.$router.push({ name: this.nextRouteName });
      }
    },
    previous() {
      if (this.previousRouteName) {
        this.$router.push({ name: this.previousRouteName });
      }
    },
    preventNext() {
      this.shouldPreventNext = true;
    },
    allowNext() {
      this.shouldPreventNext = false;
    },
    async saveShow(preventNav = false) {
      const smcs = this.draftShow.social_media_connections || [];
      await this.updateDraftShow({ social_media_connections: smcs.map((x) => x.resource_uri) });
      if (this.showId === 'new') {
        await this.$store.dispatch('CreateAndPublishStore/showSetup/addShow', this.draftShow);
      } else {
        await this.$store.dispatch('CreateAndPublishStore/showSetup/updateShow', this.draftShow);
      }
      if (!preventNav) {
        const showId = this.$store.getters['CreateAndPublishStore/activeShow']?.id;
        await this.$router.push({
          path: `/create/${this.selectedBrand?.id}/podcasts/shows/${showId}`,
        });
      }
    },
    isStepEnabled(stepId) {
      return stepId < this.currentStep || (!this.shouldPreventNext && stepId === this.currentStep + 1);
    },

    async onSaveAndNav(path) {
      // This is needed in order to save the show, when on step 3 when the user tries to use the
      // "Create new X SMC" links that navigate them away.
      await this.saveShow(true);
      this.$router.push({
        path,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.new-show {
  font-family: Roboto;
  font-weight: 500;
  padding: 0 3rem;
  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2em 0;
  }

  footer {
    display: flex;
    flex-direction: row-reverse;
    padding: 36px 0;
    background: var(--off-white);
  }

  &__header-button {
    padding: 0 1em;
  }
}
</style>
