































































import { Component, Prop, Vue } from 'vue-property-decorator';

export let sidebarwidth: number;

@Component({
  props: {
    sidebarOptions: {
      type: Array,
      default: () => [],
    },
    imagePath: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      sidebarOpen: false,
    };
  },
})
export default class CollapsibleSidebar extends Vue {
  @Prop({ required: false }) private sidebarData?: boolean;
  @Prop({ required: false, default: 'sidebar' }) private id?: boolean;
  @Prop({ required: false }) private emitClickEvent?: string;

  isActive = true;

  closed = true;

  sidebarOpen = this.$props.sidebarData;

  getImgUrl(image) {
    return require(`@/assets/${this.$props.imagePath}${image}`);
  }

  setOption = (e) => {
    this.$props.sidebarOptions.forEach((option) => {
      if (e === option) {
        this.$emit('emitClickEvent', [option.activeLabel, true]);
      } else {
        this.$emit('emitClickEvent', [option.activeLabel, false]);
      }
    });
  };

  sidebarToggle() {
    this.sidebarOpen = !this.sidebarOpen;
    this.$emit('childToParent', this.sidebarOpen);
    this.closed = !this.closed;
    this.isActive = !this.isActive;
    this.$emit('clickEvent', this.sidebarOpen);
  }
}
