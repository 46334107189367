










import moment, { MomentInput } from 'moment';

import '../styles.scss';
import { mapGetters } from 'vuex';

export default {
  name: 'trial-expired-user',

  computed: {
    ...mapGetters(['getAdminEmail', 'trialEndedAt']),
    daysRemaining(): number {
      const daysContentRemains = 30;
      const expirationDate = moment(this.trialEndedAt as MomentInput);
      const now = moment();
      return Math.max(daysContentRemains - now.diff(expirationDate, 'days'), 0);
    },
  },
};
