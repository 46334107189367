








import CopyToClipboardFunction from 'vue-copy-to-clipboard';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  components: {
    CopyToClipboardFunction,
  },
  data() {
    return {
      text: this.$props.content,
      buttonText: 'Copy To Clipboard',
    };
  },
  methods: {
    handleCopy() {
      this.$data.buttonText = 'Copied!';
    },
  },
})
export default class CopyToClipboard extends Vue {
  @Prop() content!: string;
}
