import { render, staticRenderFns } from "./linkColumn.vue?vue&type=template&id=2b1f1928&scoped=true&%3Akey=j&v-for=(column%2C%20j)%20in%20prop&"
import script from "./linkColumn.vue?vue&type=script&lang=ts&"
export * from "./linkColumn.vue?vue&type=script&lang=ts&"
import style0 from "./linkColumn.vue?vue&type=style&index=0&id=2b1f1928&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2b1f1928",
  null
  
)

export default component.exports