



























import Vue, { PropType } from 'vue';
import { Multiselect as BaseMultiselect } from 'vue-multiselect';

import ErrorMessage from '../ErrorMessage.vue';

const Multiselect = Vue.extend({
  components: { BaseMultiselect, ErrorMessage },

  props: {
    options: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    onInput: {
      type: Function,
    },
    placeholder: {
      type: String,
      default: () => 'Select option',
    },
    initialValue: {
      type: Array,
      default: () => [],
    },
    error: {
      type: String,
    },
    trackBy: {
      type: String,
      default: () => 'key',
    },
    label: {
      type: String,
      default: () => 'value',
    },
  },

  data() {
    return {
      value: this.initialValue,
    };
  },
});

export default Multiselect;
