




















import { Component, Prop, Vue } from 'vue-property-decorator';
import UserModel from '@/models/UserModel';
import { Action, Getter } from 'vuex-class';

@Component
export default class AllUsers extends Vue {
  @Prop({ required: true }) private user!: UserModel;

  @Getter selectedUsers!: boolean;
  @Action toggleSelectedUser;
}
