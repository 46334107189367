


























import { Component, Prop, Vue } from 'vue-property-decorator';

import AccountCard from '@/components/dashboard/AccountCard.vue';
import Button from '@/components/common/buttons/Button.vue';
import SeatInfoReturningUser from '@/components/dashboard/usersAccountCard/components/SeatInfoReturningUser.vue';
import { Getter } from 'vuex-class';

@Component({
  components: {
    AccountCard,
    Button,
    SeatInfoReturningUser,
  },
})
export default class UserAccountCard extends Vue {
  @Prop({ required: false }) private newUser?: boolean; // md or fa
  @Getter sortedPriorityAccountProducts;

  // Build card copy with available products and seats data
  getNewUserSeatsCopy() {
    const prefix = 'Collaborate with your team by inviting them to create Content Cloud accounts. You have ';
    const suffix = ' available.';
    let seatsString = '';
    if (this.sortedPriorityAccountProducts?.length > 0) {
      const productStrings = this.sortedPriorityAccountProducts.map((product) => {
        const qaData = this.$getComponent('UserAccountCard', 'Text', `${product.name.toLowerCase()}SeatsNewUser`);
        return `<b data-qa=${qaData}>${product.maxSeats - product.currentSeats}</b> ${product.name} seats`;
      });
      seatsString = productStrings.join(', ').replace(/,(?!.*,)/gim, ' and');
    }
    return prefix + seatsString + suffix;
  }
}
