






















import Vue from 'vue';
import { mapGetters } from 'vuex';

import CreateLanding from '@/components/unauthorized/CreateLanding.vue';
import DiscoverLanding from '@/components/unauthorized/DiscoverLanding.vue';
import Generic401Landing from '@/components/unauthorized/Generic401Landing.vue';
import Generic404Landing from '@/components/unauthorized/Generic404Landing.vue';
import GenericLanding from '@/components/unauthorized/GenericLanding.vue';

export default Vue.extend({
  components: {
    CreateLanding,
    DiscoverLanding,
    Generic401Landing,
    Generic404Landing,
    GenericLanding,
  },

  data() {
    return {
      errorType: '',
      path: '',
      prevRoute: null,
      redirectType: '',
    };
  },

  computed: {
    ...mapGetters(['authenticatedUser', 'getAdminEmail']),

    isAuthenticatedUserAccountOwner() {
      return this.authenticatedUser.email === this.getAdminEmail;
    },

    showDiscoverLanding() {
      return this.$data.redirectType !== 401 && this.$route.query?.type === 'discover';
    },

    showCNPLanding() {
      return this.$data.redirectType !== 401 && this.$route.query?.type === 'cnp';
    },
  },

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.$data.prevRoute = vm.$route.params.page;
      vm.$data.path = vm.$route.path;
      vm.$data.redirectType = vm.$route.params.redirectType ? vm.$route.params.redirectType : '';
    });
  },
});
