var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('modal',{attrs:{"id":"profile-email-modal"}},[_c('div',{staticClass:"modal-head",attrs:{"slot":"head"},slot:"head"},[_c('div',{staticClass:"modal-header-top"},[_c('h2',{staticClass:"h3"},[_vm._v("Update your email address")]),_c('img',{staticClass:"modal-close",attrs:{"src":require('@/assets/close-icon.svg')},on:{"click":function () {
            _vm.resetEmailForm();
            _vm.$bvModal.hide('modal-center');
          }}})]),_c('p',{staticClass:"p modal-description"},[_vm._v(" This change will take effect immediately and you'll need to log in again with your new email address. ")]),_c('p',{staticClass:"p modal-description"},[(_vm.inlineResetErrorMessage)?_c('ErrorMessage',{attrs:{"error":_vm.inlineResetErrorMessage[0]}}):_vm._e()],1)]),_c('div',{attrs:{"slot":"form"},slot:"form"},[_c('div',{staticClass:"email-form"},[_c('Input',{attrs:{"id":"email-form-input","inputHandler":function () {},"placeholder":_vm.profileData.email,"label":"Current email address","disabled":true}}),_c('Input',{attrs:{"id":"new-email-form-input","name":"newEmail","inputHandler":_vm.handleModalInputChange,"value":_vm.emailChangeForm.newEmail,"error":_vm.$v.emailChangeForm.newEmail.$error
            ? _vm.$v.emailChangeForm.newEmail.required
              ? _vm.errorMessages.invalidEmail
              : 'New email' + _vm.errorMessages.required + '.'
            : '',"label":"New email address"}}),_c('Input',{attrs:{"id":"confirm-email-form-input","name":"confirmNewEmail","inputHandler":_vm.handleModalInputChange,"value":_vm.emailChangeForm.confirmNewEmail,"error":_vm.confirmEmailError,"label":"Confirm new email address"}}),_c('Input',{attrs:{"id":"password-form-input","name":"password","inputHandler":_vm.handleModalInputChange,"type":"password","label":"Enter current password","value":_vm.emailChangeForm.password,"error":_vm.$v.emailChangeForm.password.$error
            ? !_vm.$v.emailChangeForm.password
              ? ''
              : 'Password' + _vm.errorMessages.required
            : _vm.inlinePasswordErrorMessage
            ? _vm.inlinePasswordErrorMessage.message
            : ''}}),_c('div',{staticClass:"form-submission"},[_c('a',{staticClass:"update-link cancel-link",attrs:{"to":"#"},on:{"click":function () {
              _vm.resetEmailForm();
              _vm.$bvModal.hide('modal-center');
            }}},[_vm._v("Cancel")]),_c('Button',{attrs:{"id":"update-email-button","loading":_vm.buttonLoadingStatus,"onClickEventHandler":"onClick","buttonType":"primary"},on:{"onClick":_vm.saveEmailChange}},[_vm._v("Save changes")])],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }