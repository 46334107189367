import { render, staticRenderFns } from "./TrendingSettingsTab.vue?vue&type=template&id=218c4b63&scoped=true&slot=tab1&%3Adata-qa=%24getComponent('Settings'%2C%20'Tab'%2C%20'trendingSourcesTab')&"
import script from "./TrendingSettingsTab.vue?vue&type=script&lang=ts&"
export * from "./TrendingSettingsTab.vue?vue&type=script&lang=ts&"
import style0 from "./TrendingSettingsTab.vue?vue&type=style&index=0&id=218c4b63&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "218c4b63",
  null
  
)

export default component.exports