




































































import { Component, Vue } from 'vue-property-decorator';

import Button from '@/components/common/buttons/Button.vue';

@Component({
  components: {
    Button,
  },
})
export default class WelcomeHeader extends Vue {
  closeBanner() {
    this.$emit('close-banner');
  }

  toggleFreshChat() {
    const widget = window.fcWidget;
    if (!widget) return;
    if (widget.isOpen()) {
      widget.close();
    } else {
      widget.open();
    }
  }
}
