









































































































import { Component, Vue, Prop } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { Campaign } from '@/types/Campaign';
import { Brand } from '@/types/Brand';
import { VideoAsset } from '@/types/createandpublish/mediaLibraries';
import Drawer from '@/components/common/drawer/Drawer.vue';
import CampaignCircle from '@/components/brands/CampaignCircle.vue';
import CampaignMenu from '@/components/brands/CampaignMenu.vue';
import moment from 'moment';
import 'moment-duration-format';

// These are needed so Vetur shuts up about properties not existing.
interface HTMLCustomVideoElement extends HTMLMediaElement {
  videoWidth: number;
  videoHeight: number;
}

interface DurationWithFormat extends moment.Duration {
  format: (fmt: string, options?: Record<string, unknown>) => string;
}

@Component({
  name: 'EditVideoDrawer',
  components: {
    Drawer,
    CampaignCircle,
    CampaignMenu,
  },
  filters: {
    formatFileSize(bytes) {
      const exp = (Math.log(bytes) / Math.log(1024)) | 0;
      const result = (bytes / Math.pow(1024, exp)).toFixed(2);

      return result + ' ' + (exp == 0 ? 'bytes' : 'KMGTPEZY'[exp - 1] + 'B');
    },
    formatDuration(value) {
      return (moment.duration(value, 'seconds') as DurationWithFormat).format('hh:mm:ss', { trim: false });
    },
  },
})
export default class EditVideoDrawer extends Vue {
  @Prop({ required: true }) readonly videoData!: null | VideoAsset;
  @Prop({ required: false, default: null }) readonly file!: File | null;
  @Getter selectedBrand;

  form: { campaignIds: number[]; title: string; description: string } = {
    campaignIds: [],
    title: '',
    description: '',
  };
  // These next three properties are used to hold values calculated from a File
  // when the user uploads a video from their computer.
  fileSrc = '';
  fileDimensions = '';
  fileDuration = NaN;

  get isNewUpload(): boolean {
    return !!this.file;
  }

  get selectedVideoCampaigns(): Campaign[] {
    return (this.selectedBrand as Brand).campaigns.filter(
      (campaign) => campaign.id && this.form.campaignIds.includes(campaign.id)
    );
  }

  get description(): string {
    return this.videoData?.description || '';
  }

  get uploadedDate(): string {
    if (!this.videoData?.timestamp_created) return '';
    const date = new Date(this.videoData.timestamp_created).toLocaleString('en-US', {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
      hour: 'numeric',
      minute: '2-digit',
    });
    return `uploaded ${date}`;
  }

  get filename(): string {
    if (this.isNewUpload) {
      return (this.file as File).name;
    }
    return this.videoData?.filename || '';
  }

  get filesize(): number {
    if (this.isNewUpload) {
      return this.$options.filters?.formatFileSize((this.file as File).size) || '0 bytes';
    }
    return this.videoData?.file_size || NaN;
  }

  get dimensions(): string {
    if (this.isNewUpload) {
      return this.fileDimensions || '';
    }
    return this.videoData?.dimension ? this.videoData.dimension + 'px' : '';
  }

  get videoUrl(): string {
    if (this.isNewUpload) {
      return this.fileSrc || '';
    }
    return this.videoData?.source_url || '';
  }

  get thumbnail(): string {
    if (this.isNewUpload) return '';
    return this.videoData?.thumbnail_url || '';
  }

  get duration(): string {
    if (this.isNewUpload) {
      return this.$options.filters?.formatDuration(this.fileDuration);
    }
    return this.$options.filters?.formatDuration(this.videoData?.duration);
  }

  get orientation(): string {
    if (this.isNewUpload) return '';
    return this.videoData?.orientation || '';
  }

  get drawerSubmitProps() {
    const getPayload = () => {
      const createNew = this.isNewUpload;
      const id = !createNew ? { id: this.videoData?.id } : {};
      const { title, description, campaignIds } = this.form;
      return {
        createNew,
        ...id,
        title,
        description,
        campaignIds,
      };
    };
    return {
      buttonText: 'Save changes',
      onClick: (hide: () => void) => {
        this.$emit('on-save-video', getPayload());
        hide();
      },
      qa: '',
    };
  }

  onCancel(hide: () => void) {
    this.$emit('on-cancel');
    hide();
  }

  resetPanel() {
    this.fileSrc = '';
    this.fileDimensions = '';
    this.fileDuration = NaN;
    this.form = {
      campaignIds: [],
      title: '',
      description: '',
    };
  }

  onOpen() {
    this.resetPanel();
    if (!this.isNewUpload && this.videoData) {
      const { campaigns, title, description } = this.videoData;
      this.form = {
        campaignIds: campaigns || [],
        title: title || '',
        description: description || '',
      };
    }
    if (this.isNewUpload) {
      const fr = new FileReader();
      fr.onload = () => {
        const result = fr.result as string;
        this.fileSrc = result;
        const videoEl = document.createElement('VIDEO') as HTMLCustomVideoElement;
        videoEl.src = result;
        videoEl.onloadedmetadata = () => {
          const { videoWidth, videoHeight, duration } = videoEl;
          this.fileDimensions = `${videoWidth}x${videoHeight}px`;
          this.fileDuration = duration;
        };
      };
      fr.readAsDataURL(this.file as File);
    }
  }

  onAddNewCampaign() {
    this.$emit('on-add-new-campaign');
  }

  onSocialShareClick() {
    this.$emit('on-social-share');
  }
}
