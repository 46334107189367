var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('RoundedCard',{staticClass:"subscription-product-card",attrs:{"data-qa":_vm.$getComponent('Subscriptions', 'Card', _vm.product.name)}},[_c('i',{staticClass:"material-icons productStateIcon",class:{ errorState: _vm.locked, inactiveState: !_vm.product.active && !_vm.locked }},[_vm._v(_vm._s(_vm.iconType))]),_c('div',{staticClass:"product-content"},[_c('fm-text',{staticClass:"title",attrs:{"variant":"large"}},[_vm._v(" "+_vm._s(_vm.product.name)+" "),_c('img',{staticClass:"productIcon",attrs:{"src":_vm.productIcon}})]),(_vm.product.active)?_c('fm-text',{staticClass:"users"},[_vm._v(" Access to "+_vm._s(_vm.product.name)+" for "),_c('span',{staticClass:"number-of-users"},[_vm._v(_vm._s(_vm.product.maxSeats)+" users")])]):_c('fm-text',{staticClass:"inactive-copy"},[_vm._v(" "+_vm._s(_vm.product.description)+" ")]),_c('div',{staticClass:"buttons"},[(_vm.product.active)?_c('RouterLink',{attrs:{"custom":"","to":"/manage-subscription"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
return [_c('fm-link',{attrs:{"href":href,"external":""},on:{"click":navigate}},[_vm._v(" Add more seats"),_c('i',{staticClass:"material-icons",attrs:{"aria-hidden":"true"}},[_vm._v("chevron_right")])])]}}],null,false,3185827304)}):_c('div',[_c('RouterLink',{attrs:{"custom":"","to":{ path: '/manage-subscription' }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
return [_c('a',{attrs:{"href":href},on:{"click":navigate}},[_c('fm-button-secondary',[_vm._v(" Add to subscription ")])],1)]}}])}),_c('RouterLink',{staticClass:"learn-more",attrs:{"custom":"","to":{ path: '/discover/learn-more' }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
return [_c('fm-link',{attrs:{"href":href,"external":""},on:{"click":navigate}},[_vm._v(" Learn more "),_c('i',{staticClass:"material-icons",attrs:{"aria-hidden":"true"}},[_vm._v("chevron_right")])])]}}])})],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }