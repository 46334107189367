<template>
  <SplitForm>
    <template v-slot:left>
      <div class="description">
        <h3 class="header">Manage social accounts & profiles</h3>
        <p class="text">
          Manage your social connections in <b>eclincher</b>, the top ranked social media management platform included
          in your Content Cloud subscription at no extra cost.
        </p>
        <Button class="button" buttonType="secondary" iconPosition="right" @click="openLink(eclincherLink)">
          <span class="material-icons"> open_in_new </span> Manage social connections in eclincher</Button
        >
        <a class="link">Learn more about eclincher </a>
      </div>
    </template>
    <template v-slot:right>
      <div class="social-accounts">
        <h3 class="label">10 available channels</h3>
        <div class="destinations">
          <!-- TODO: add all images & links-->
          <ul class="destinations__list">
            <!-- On click of item, open new tab to item.link -->
            <li v-for="item in destinations" :key="item.label" class="destinations__list-item">
              <img
                :src="require(`@/assets/createandpublish-assets/social-destinations/${item.img}.png`)"
                class="destinations__image"
                :alt="item.label"
              />
            </li>
          </ul>
        </div>
      </div>
    </template>
  </SplitForm>
</template>

<script>
import { Component, Vue } from 'vue-property-decorator';
import SplitForm from '@/components/common/form/SplitForm.vue';
import Input from '@/components/common/form/Input.vue';
import FormSectionText from '@/components/common/form/FormSectionText.vue';
import FormSpacer from '@/components/common/form/FormSpacer.vue';
import Button from '@/components/common/buttons/Button.vue';
@Component({
  name: 'SocialAccountsAndProfileTab',
  components: {
    SplitForm,
    Input,
    FormSectionText,
    FormSpacer,
    Button,
  },
  data: () => {
    return {
      destinations: [
        { label: 'Facebook', img: 'facebook' },
        { label: 'Instagram', img: 'instagram' },
        { label: 'Google My Business', img: 'google_my_business' },
        { label: 'LinkedIn', img: 'li' },
        { label: 'YouTube', img: 'youtube' },
        { label: 'Twitter', img: 'twitter' },
        { label: 'Pinterest', img: 'pinterest' },
        { label: 'TikTok', img: 'tiktok' },
        { label: 'Google Analytics', img: 'google_analytics' },
        { label: 'Blogger', img: 'blogger' },
      ],
    };
  },
  computed: {
    eclincherLink() {
      // Temporary link
      return 'https://app.eclincher.com/ec/accounts/add';
    },
  },
  methods: {},
  props: {},
})
export default class SocialAccountsAndProfileTab extends Vue {}
</script>

<style lang="scss" scoped>
.description {
  color: black;
}

.header {
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
}
.text {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.link {
  font-weight: normal;
  cursor: pointer;
  text-decoration-line: underline;
  color: var(--accent-links);
}

.button {
  margin: 24px 0 12px 0px;
}

.label {
  letter-spacing: 2px;
  text-transform: uppercase;
  color: var(--neutral-grey-2-lighter-text);
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
}

.social-accounts {
  text-align: center;

  h3 {
    margin-bottom: 24px;
  }
}

.destinations {
  &__list {
    margin: 0;
    padding: 0;
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
    max-width: 464px; // 6 icons + gap
    @media (min-width: 768px) {
      margin: 0 auto;
    }
  }
  &__list-item {
    list-style: none;
    width: 48px;
    height: 48px;
    padding: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__image {
    width: 48px;
    height: 48px;
  }
}
</style>
