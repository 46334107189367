import { RouteConfig } from 'vue-router';

import CreateAndPublish from '@/views/CreateAndPublish.vue';
import CreateDashboard from '@/createandpublish/Dashboard.vue';
import Podcasts from '@/createandpublish/Podcasts.vue';

// import EditShowRoute from '@/createandpublish/views/new-show/EditShowRoute.vue';
// import POSTRouter from '@/createandpublish/core/router';

// Create&Publish
import Planner from '@/createandpublish/views/planner/Planner.vue';
// Discover
import ShowsList from '@/createandpublish/views/ShowsList.vue';
import ShowsRoute from '@/createandpublish/views/ShowsRoute.vue';
import ShowsRouteDetails from '@/createandpublish/views/ShowsRouteDetails.vue';

import EditEpisodeRoutes from '@/createandpublish/core/routes/episode.js';
import AnalyticsHomeRoute from '@/createandpublish/views/analytics/AnalyticsHomeRoute.vue';
import AnalyticsDashboard from '@/createandpublish/views/analytics/AnalyticsDashboard.vue';
import SocialPostsAnalytics from '@/createandpublish/views/analytics/SocialPostAnalytics.vue';
import podcastAnalyticsRoutes from '@/createandpublish/core/routes/analytics';

import ImageLibrary from '@/createandpublish/views/image-library/ImageLibrary.vue';
import VideoLibrary from '@/createandpublish/views/video-library/VideoLibrary.vue';
import AudioLibrary from '@/createandpublish/views/audio-library/AudioLibrary.vue';
import AudioWizardRoutes from '@/createandpublish/core/routes/audioWizard';
// import EditEpisodeRoutes from './routes/episode';
import EditShowRoutes from '@/createandpublish/core/routes/editShow';
import CNPSettings from '@/createandpublish/views/CNPSettings.vue';

import TrackingURLs from '@/createandpublish/views/trackingURLs/TrackingURLs.vue';
import ImportRSSFeed from '@/createandpublish/views/import-content/ImportRSSFeed.vue';

import SocialStoryRoutes from '@/createandpublish/core/routes/socialStories';

import store from '@/store';

import { requiresBrandAccess, requiresProductAccess } from '@/constants/router/permissions';

export default [
  {
    path: '/create/:brandId?',
    component: CreateAndPublish,
    meta: { ...requiresBrandAccess, ...requiresProductAccess },
    beforeEnter: async (to, from, next) => {
      await store.dispatch('CreateAndPublishStore/setEndpoint');
      await store.dispatch('CreateAndPublishStore/verifyAndUpdateStoreForCurrentBrand');
      next();
    },
    children: [
      {
        path: '',
        name: 'Create.Home',
        component: CreateDashboard,
        meta: {
          ...requiresBrandAccess,
          sidebar_active_item: 'Dashboard',
          title: 'Dashboard | Create&Publish',
          feature: 'create-and-publish',
        },
      },
      {
        path: 'podcasts',
        name: 'Create.Podcasts',
        component: Podcasts,
        meta: {
          ...requiresBrandAccess,
          sidebar_active_item: 'Podcasts',
          title: 'Podcasts | Create&Publish',
          feature: 'create-and-publish',
        },
        beforeEnter: (to, from, next) => {
          store.dispatch('CreateAndPublishStore/fetchAllShowsData');
          next();
        },
      },
      SocialStoryRoutes,
      {
        path: 'planner',
        name: 'Planner',
        component: Planner,
        meta: {
          ...requiresBrandAccess,
          title: 'Planner | Create&Publish',
          feature: 'create-and-publish',
        },
      },
      {
        path: 'podcasts/',
        redirect: 'podcasts/shows',
        meta: { title: 'Podcasts | Create&Publish', feature: 'create-and-publish' },
      },
      {
        path: 'audio-library',
        name: 'Create.Audio',
        component: AudioLibrary,
        props: () => ({
          libraryMode: store.getters['CreateAndPublishStore/audioWizard/wizardModes'].LIBRARY,
        }),
        meta: {
          sidebar_active_item: 'Audio',
          title: 'Audio Library | Create&Publish',
          feature: 'create-and-publish',
        },
        beforeEnter(_to, _from, next) {
          store.commit('CreateAndPublishStore/audioWizard/CLEAR_AUDIO_EVENTS');
          next();
        },
      },
      AudioWizardRoutes,
      {
        path: 'podcasts/shows',
        component: ShowsList,
        name: 'Shows',
        meta: {
          title: 'Shows | Podcasts | Create&Publish',
          sidebar_active_item: 'Shows',
          feature: 'create-and-publish',
        },
        beforeEnter(to, from, next) {
          store.dispatch('CreateAndPublishStore/getSocialMediaConnections');
          next();
        },
      },
      {
        path: 'podcasts/shows/:showId',
        component: ShowsRoute,
        meta: {
          sidebar_active_item: 'Shows',
          feature: 'create-and-publish',
        },
        children: [
          {
            path: '/',
            redirect: 'details',
          },
          {
            name: 'show-detail',
            path: 'details',
            component: ShowsRouteDetails,
            meta: {
              title: 'New Show | Podcasts | Create&Publish',
              sidebar_active_item: 'Shows',
              feature: 'create-and-publish',
            },
            beforeEnter(to, from, next) {
              store.dispatch('CreateAndPublishStore/getSocialMediaConnections');
              next();
            },
          },
          {
            path: 'new-episode',
            redirect: 'episodes/0/edit/audio',
          },
          EditEpisodeRoutes,
        ],
      },
      {
        path: 'analytics',
        name: 'Create.Analytics',
        component: AnalyticsHomeRoute,
        redirect: 'analytics/dashboard',
        children: [
          {
            path: 'dashboard',
            name: 'Create.Analytics.Dashboard',
            component: AnalyticsDashboard,
            meta: {
              title: 'Analytics Dashboard | Create&Publish',
              sidebar_active_item: 'Analytics',
              feature: 'create-and-publish',
            },
          },
          {
            path: 'social',
            name: 'Create.Analytics.Social',
            component: SocialPostsAnalytics,
            meta: {
              feature: 'create-and-publish',
            },
          },
          podcastAnalyticsRoutes,
        ],
      },
      {
        path: 'images',
        name: 'Create.Images',
        component: ImageLibrary,
        meta: {
          title: 'Image Library | Create&Publish',
          sidebar_active_item: 'Images',
          feature: 'create-and-publish',
        },
      },
      {
        path: 'videos',
        name: 'Create.Videos',
        component: VideoLibrary,
        meta: {
          title: 'Video Library | Create&Publish',
          sidebar_active_item: 'Video',
          feature: 'create-and-publish',
        },
      },
      {
        path: 'settings',
        component: CNPSettings,
        name: 'Create.Settings',
        meta: {
          title: 'Settings | Create&Publish',
          sidebar_active_item: 'Settings',
          feature: 'create-and-publish',
        },
        beforeEnter(to, from, next) {
          store.dispatch('CreateAndPublishStore/fetchAllShowsData');
          store.dispatch('CreateAndPublishStore/getSocialMediaConnections');
          store.dispatch('CreateAndPublishStore/getPlaylistCategories');
          next();
        },
      },
      {
        path: 'tracking-urls',
        name: 'tracking-urls',
        component: TrackingURLs,
        meta: {
          title: '3rd party Tracking URLs',
          sidebar_active_item: 'Tracking URLs',
          feature: 'create-and-publish',
        },
        async beforeEnter(to, from, next) {
          await store.dispatch('CreateAndPublishStore/fetchAllShowsData');
          next();
        },
      },
      {
        path: 'import-rss-feed',
        name: 'import-rss-feed',
        component: ImportRSSFeed,
        meta: {
          title: 'Import RSS feed',
          sidebar_active_item: 'Import Content',
          feature: 'create-and-publish',
        },
        async beforeEnter(_to, _from, next) {
          const promises: Promise<unknown>[] = [
            store.dispatch('CreateAndPublishStore/fetchAllShowsData'),
            store.dispatch('CreateAndPublishStore/getSocialMediaConnections'),
            store.dispatch('CreateAndPublishStore/clearActiveShow'),
          ];
          await Promise.all(promises);
          next();
        },
      },
      EditShowRoutes,
    ],
  },
] as RouteConfig[];
