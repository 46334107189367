<template>
  <div class="following-view" :data-qa="$getComponent('Following', 'wrapper', 'followingPage')">
    <!-- @TODO eclincher
    <share-post-modal :visible="sharePostModalVisible" :data="sharePostModalData" />
    -->
    <add-campaign-drawer
      :campaignData="campaignData"
      @cancel="handleCampaignDrawerCancel"
      :data-qa="$getComponent('Discover', 'Component', 'discoverDrawer')"
      :storyData="storyData"
    ></add-campaign-drawer>
    <div class="following">
      <RSSStoryDrawer
        :drawerStatus="drawerStatus"
        :visible="!!drawerStatus.type"
        ref="rssStoryDrawer"
        :data-qa="$getComponent('Following', 'Drawer', 'rssStoryDrawer')"
        @refreshData="updateStoryCampaigns"
      />
      <h2 class="headline-h2">
        <span class="material-icons" :data-qa="$getComponent('Following', 'Icon', 'rssFeedIcon')"> rss_feed </span>
        Following
      </h2>
      <Tabs :titles="[{ title: 'RSS feed' }]" :data-qa="$getComponent('Following', 'Tabs', 'followingTabs')">
        <template slot="tab1">
          <div class="rss-feed" :data-qa="$getComponent('Following', 'Tab', 'rssFeedTab')">
            <!-- If nothing is ready, run loading screen -->
            <div class="loading" v-if="loaderVisibleFeedOptions">
              <Loading
                :isLoading="loaderVisibleFeedOptions"
                :data-qa="$getComponent('Following', 'Icon', 'loadingIcon')"
              />
            </div>
            <div v-else>
              <!-- If still loading but we have rss feeds -->
              <div v-if="rssFeeds.length > 0">
                <div class="rss-feed-ui">
                  <SearchBar
                    v-model="searchTerm"
                    placeholder="What are you looking for?"
                    :loading="loaderVisible"
                    @submit="handleNewSearchQuery"
                    @clear="clearSearchTerms"
                    :data-qa="$getComponent('Following', 'SearchBar', 'followingSearch')"
                  />
                  <div class="filter-row">
                    <div class="filters">
                      <Select :options="publishedAfterOptions" :input-event-handler="handlePublishedAfterFilter" />
                      <!-- Sort active first then inactive. Alphabetical sub sort -->
                      <Select
                        option-value="name"
                        :options="rssFeedOptions"
                        :input-event-handler="handleRssFeedFilter"
                      />
                      <Select
                        option-value="name"
                        :options="campaignOptions"
                        :input-event-handler="handleCampaignFilter"
                      />
                    </div>
                    <div
                      @click="$router.push(`/discover/${selectedBrand.id}/settings?tabIndex=1`)"
                      class="settings-button"
                    >
                      <Button
                        buttonType="tertiary icon"
                        :data-qa="$getComponent('Following', 'Button', 'settingsButton')"
                      >
                        <span class="material-icons" :data-qa="$getComponent('Following', 'Icon', 'settingsIcon')">
                          settings
                        </span>
                      </Button>
                    </div>
                  </div>
                </div>
                <!-- If we have/had stories, show them & the pagination-->
                <div v-if="stories.length > 0">
                  <div class="results-header-row">
                    <h3 class="results-count" v-if="totalItems">{{ totalItems }} results</h3>
                    <div class="last-updated" v-if="lastUpdated">Last updated {{ formatLastUpdated(lastUpdated) }}</div>
                  </div>
                  <div class="rss-feed-grid">
                    <RSSSingleFeedCard
                      v-for="(story, index) of stories"
                      :thumbnail="getThumbnail(story.rssItem)"
                      :title="story.rssItem.title"
                      :feedName="getFeedName(story.feedId)"
                      :pubDate="formatPubDate(story.rssItem.pubDate)"
                      :feedId="story.feedId"
                      :story="story"
                      :campaignIds="story.campaign_ids"
                      :tempCampaigns="campaigns"
                      :key="`rssCard-${story.id}`"
                      :storyId="story.id"
                      :cardIndex="index"
                      clickEvent="singleStory"
                      @refreshData="updateStoryCampaigns"
                      @singleStory="
                        $refs.rssStoryDrawer.loadRssStory(story, index, getFeedName(story.feedId), campaigns)
                      "
                    />
                    <!-- If we're updating page, show loader -->
                    <div v-if="loaderVisible" class="loading absolute">
                      <Loading
                        :isLoading="loaderVisible"
                        :data-qa="$getComponent('Following', 'Icon', 'loadingIcon')"
                      />
                    </div>
                  </div>

                  <div class="pagination">
                    <Pagination
                      :totalItems="totalItems"
                      :onPageSizeUpdate="updatePageSize"
                      :onPageUpdate="updatePagination"
                      :pageSizeOptions="pageSizeOptions"
                      :pageSize="pageSize"
                      :currentPage="currentPageNum"
                      :key="totalItems"
                      :disabled="!!loaderVisible"
                    />
                  </div>
                </div>
                <!-- This shows if we have rssfeeds but no stories. We still want to show the search & filter bars -->
                <div v-else class="loading">
                  <div class="rss-feed-no-content mt">
                    <h3>Nothing to see here</h3>
                    <div class="text">
                      We couldn’t find any items from your RSS feeds. Please add more feeds or try again later.
                    </div>
                    <div class="button-row">
                      <Button
                        buttonType="secondary"
                        onClickEventHandler="click"
                        @click="$router.push(`/discover/${selectedBrand.id}/settings?tabIndex=1`)"
                        :data-qa="$getComponent('Following', 'Button', 'followFeedButton')"
                      >
                        <span class="material-icons" :data-qa="$getComponent('Following', 'Icon', 'addIcon')">
                          add
                        </span>
                        Follow new RSS feed
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Not loading & no rss feeds, this shows. -->
              <div v-else class="rss-feed-no-content">
                <h3>Nothing to see here</h3>
                <div class="text">You haven't followed anything yet!</div>
                <div class="button-row">
                  <Button
                    buttonType="secondary"
                    onClickEventHandler="click"
                    @click="$router.push(`/discover/${selectedBrand.id}/settings?tabIndex=1`)"
                    :data-qa="$getComponent('Following', 'Button', 'followFeedButton')"
                  >
                    <span class="material-icons" :data-qa="$getComponent('Following', 'Icon', 'addIcon')"> add </span>
                    Follow new RSS feed
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </template>
      </Tabs>
    </div>
  </div>
</template>

<script>
import { Component, Vue } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import SearchBar from '@/components/common/form/SearchBar.vue';
import Select from '@/components/common/form/Select.vue';
import Tabs from '@/components/common/tabs/Tabs.vue';
import Button from '@/components/common/buttons/Button.vue';
import RSSSingleFeedCard from '@/components/discover/cards/RssSingleFeedCard.vue';
import RSSStoryDrawer from '@/components/discover/drawer/RSSStoryDrawer.vue';
import DiscoverSettingsService from '@/services/DiscoverSettingsService';
import DiscoverFollowingService from '@/services/DiscoverFollowingService';
import Pagination from '@/components/common/table/Pagination.vue';
import Loading from '@/components/common/Loading.vue';
import EventHub from '@/components/discover/EventHub.vue';
import AddCampaignDrawer from '@/components/common/campaigns/AddCampaignDrawer.vue';
import { byName } from '@/utils/sort';
// eclincher
// import SharePostModal from '@/components/eclincher/SharePostModal.vue';

@Component({
  components: {
    AddCampaignDrawer,
    Button,
    Loading,
    Pagination,
    RSSSingleFeedCard,
    RSSStoryDrawer,
    SearchBar,
    Select,
    // SharePostModal,
    Tabs,
  },

  data: () => {
    return {
      filters: [
        { id: 0, name: 'filter 1' },
        { id: 1, name: 'filter 2' },
        { id: 2, name: 'filter 3' },
      ],
      offset: null,
      limit: null,
      defaultLimit: 12,
      // rssFeedStories: [],
      // rssFeedStoriesBatch: [],
      searchTerms: [],
      rssFeeds: [],
      totalItems: 0,
      loaderVisible: true,
      loaderVisibleFeedOptions: true,
      drawerStatus: {
        isOpen: false,
      },
      campaigns: [],
      campaignData: {},
      storyData: {},
      // @TODO eclincher
      // sharePostModalVisible: false,
      // sharePostModalData: {
      //   content: '',
      //   picture: '',
      //   tag: '',
      // },
      stories: [],
      lastUpdated: '',
    };
  },

  methods: {
    // When sharing post, eclincher
    // @TODO eclincher
    // onSharePost(data) {
    //   this.$data.sharePostModalData = {
    //     ...this.$data.sharePostModalData,
    //     ...data,
    //   };
    //   this.$data.sharePostModalVisible = true;
    // },
  },
})
export default class Following extends Vue {
  @Getter selectedBrand;
  @Getter authenticatedUser;
  @Getter selectedBrandCampaigns;
  @Action changeSelectedBrand;

  currentPageNum = 1;
  pageSize = 12;
  offset = 0;
  pageSizeOptions = [
    {
      size: 6,
    },
    {
      size: 12,
    },
    {
      size: 18,
    },
  ];
  searchTerm = '';
  rssFeedFilterId = -1; // init source filter to all
  campaignFilterId = -1; // init campaign filter to all
  publishedAfterFilterOption = -1; // init published at filter to all

  publishedAfterOptions = [
    {
      id: -1,
      value: 'Date published',
    },
    {
      id: 1,
      value: 'Past hour',
    },
    {
      id: 24,
      value: 'Past day',
    },
    {
      id: 168,
      value: 'Past 7 days',
    },
    {
      id: 720,
      value: 'Past 30 days',
    },
  ];

  get rssFeedOptions() {
    const defaultOption = {
      id: -1,
      name: 'Source',
    };

    const filteredRssFeeds = this.rssFeeds
      .filter((feed) => feed.hasItems || feed.active)
      .map((feed) => ({ id: feed.id, name: `${feed.active ? '' : '(inactive) '}${feed.feedName}` }))
      .sort(byName);

    return [defaultOption, ...filteredRssFeeds];
  }

  get campaignOptions() {
    const defaultOption = {
      id: -1,
      name: 'Campaigns',
    };

    const filteredCampaigns = this.selectedBrandCampaigns
      .filter((campaign) => campaign.active)
      .map((campaign) => ({ id: campaign.id, name: campaign.name }))
      .sort(byName);

    return [defaultOption, ...filteredCampaigns];
  }

  async fetchBrandData() {
    //resync selected brand to capture any campaign updates
    // This is duplicate functionality
    // this.authenticatedUser.brands.forEach((brand) => {
    //   if (brand.id === this.selectedBrand.id) {
    //     console.log('5');
    //     this.changeSelectedBrand(brand);
    //   }
    // });

    this.campaignData = this.getCampaignData();
  }

  getCampaignData() {
    return {
      brandId: this.selectedBrand?.id || 0,
      active: true,
      color: '#EE4833',
      name: '',
    };
  }

  handleCampaignDrawerCancel() {
    return {
      brandId: 0,
      active: true,
      color: '#EE4833',
      name: '',
    };
  }

  async fetchRSSFeedStories() {
    this.loaderVisible = true;

    const response = await new DiscoverFollowingService().getRssStories({
      brandId: this.selectedBrand.id,
      offset: this.offset,
      limit: this.pageSize,
      query: this.searchTerm,
      feedId: this.rssFeedFilterId,
      campaignId: this.campaignFilterId,
      publishedAfter: this.publishedAfterFilterOption,
    });

    try {
      this.stories = response.content.stories;
      this.totalItems = response.totalItems;
      this.lastUpdated = response.content.lastUpdated;
    } catch (err) {
      this.resetData();
    } finally {
      this.loaderVisible = false;
    }
  }

  //fetch feeds to get feed names for stories by feedId
  async fetchRSSFeeds() {
    this.loaderVisibleFeedOptions = true;
    const response = await new DiscoverSettingsService().getFeeds(this.selectedBrand.id);

    try {
      this.rssFeeds = response;
    } catch (err) {
      console.log(err);
    } finally {
      this.loaderVisibleFeedOptions = false;
    }
  }

  async initialLoad() {
    //set defaults
    this.limit = this.defaultLimit;
    this.offset = 0;
    await this.fetchRSSFeedStories();
    await this.fetchRSSFeeds();
    this.fetchBrandData();
  }

  clearSearchTerms() {
    this.searchTerm = '';
    this.handleNewSearchQuery();
  }

  mounted() {
    this.initialLoad();
  }

  formatLastUpdated(timestamp) {
    const newDate = new Date().getTime();
    const timeAgo = new Date(timestamp).getTime();
    const seconds = Math.floor((newDate - timeAgo) / 1000);
    let interval = seconds / 31536000;
    interval = seconds / 60;
    if (interval > 1) {
      return Math.floor(interval) + ' minutes ago';
    }
    return Math.floor(seconds) + ' seconds ago';
  }

  formatPubDate(date) {
    if (!date) {
      return '';
    }

    const newDate = new Date(date);
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    return `${months[newDate.getMonth()]} ${newDate.getDate()}, ${newDate.getFullYear()}`;
  }

  getFeedName(id) {
    let feedName = '';
    this.rssFeeds.map((feed) => {
      if (feed.id === id) {
        feedName = feed.feedName;
      }
    });
    return feedName;
  }

  //Campaign ID data for each story comes in from the feed that calls all stories
  //if story was tagged to campaign in another place, like the drawer
  //update the data here to propegate to other components to avoid reloading
  updateStoryCampaigns(value) {
    const storyId = value.storyId;
    this.stories.map((story, index) => {
      if (story.id === storyId) {
        this.stories[index].campaign_ids = [...value.campaign_ids];
      }
    });
  }

  //compensate for possible different rss formats for images
  //will probably need to add to this
  getThumbnail(obj) {
    if (obj.enclosure) {
      return obj.enclosure;
    } else if (obj.media) {
      return obj.media;
    }
  }

  updatePageSize(itemsPerPage) {
    this.pageSize = parseInt(itemsPerPage.target.value);
  }

  updatePagination(newPage) {
    this.currentPageNum = newPage;
    this.offset =
      (this.currentPageNum - 1) * this.pageSize < this.totalItems
        ? (this.currentPageNum - 1) * this.pageSize
        : this.totalItems;
    this.fetchRSSFeedStories();
  }

  resetPagination() {
    this.currentPageNum = 1;
    this.offset = 0;
  }

  resetData() {
    this.pageSize = 12;
    this.offset = 0;
    this.currentPageNum = 1;
  }

  passDataToCampaignDrawer(value) {
    this.storyData = value;
  }

  handleNewSearchQuery() {
    this.resetPagination();
    this.fetchRSSFeedStories();
  }

  handleRssFeedFilter(e) {
    const id = Number(e.target.value);
    this.rssFeedFilterId = id;
    this.handleNewSearchQuery();
  }

  handleCampaignFilter(e) {
    const id = Number(e.target.value);
    this.campaignFilterId = id;
    this.handleNewSearchQuery();
  }

  handlePublishedAfterFilter(e) {
    const id = Number(e.target.value);
    this.publishedAfterFilterOption = id;
    this.handleNewSearchQuery();
  }

  created() {
    //a new campaign was created at the story level
    //update tagged campaigns for that story
    EventHub.$on('tagRSSFromCampaignDrawer', this.updateStoryCampaigns);
    EventHub.$on('addCampaignDrawerData', this.passDataToCampaignDrawer);
    // EventHub.$on('tagTrendingStory', this.updateStoryCampaigns);
  }
}
</script>

<style lang="scss" scoped>
template {
  width: 100%;
}

.mt {
  margin-top: 20px;
}
.following {
  font-family: 'Roboto';
  padding: 2.1em 2em;
  width: 100%;
  background: var(--neutral-grey-5-lightest-bg);
  // used to fill space for footer to move down if following is empty.
  padding-bottom: 200px;
}

.rss-feed-ui {
  .filter-row {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    margin-top: 24px;
  }

  .filters {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    .fm-select {
      margin-right: 16px;
    }
  }

  .settings-button {
    justify-self: flex-end;
  }
}

.results-header-row {
  display: flex;
  flex-direction: row;
  margin-top: 48px;

  .results-count {
    color: var(--neutral-grey-1-text);
    font-size: 30px;
    font-weight: 500;
    line-height: 38px;
    margin-right: 23px;
  }

  .last-updated {
    color: var(--neutral-grey-2-lighter-text);
    font-size: 14px;
    line-height: 21px;
    margin-top: 13px;
  }
}

.rss-feed-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: 1fr;
  grid-column-gap: 24px;
  margin-top: 24px;
  min-height: 100px;
  position: relative;
}

.rss-feed-no-content {
  text-align: center;

  h3 {
    color: var(--neutral-grey-1-text);
    font-size: 30px;
    line-height: 38px;
  }

  .text {
    color: var(--neutral-grey-1-text);
    font-size: 16px;
    line-height: 24px;
    margin-top: 34px;
  }

  .button-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 34px;
  }

  button:first-child {
    margin-right: 24px;
  }
}

.loading {
  padding: 16px;
  display: flex;
  justify-content: center;
  &.absolute {
    position: absolute;
    top: -100px;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.loading i {
  color: var(--neutral-grey-2-lighter-text);
  align-self: center;
  text-align: center;
}

.rss-feed-grid .pagination {
  display: flex;
  justify-content: center;
  padding: 1em 0;
  background-color: var(--neutral-grey-5-lightest-bg);
}

@media only screen and (max-width: 1580px) {
  .rss-feed-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}
</style>
