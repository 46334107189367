import FeaturesService from '@/services/FeaturesService';
import { ProductSelectOption } from '@/types/ecomm/ProductSelectOption';
import { byName } from '../../utils/sort';
import { productMap } from '@/constants/seeded/products';

//use this function to fetch features for pages that do not require user authentication
export const getAvailableProducts = async () => {
  const availableFeatures =
    (await new FeaturesService().getAll())
      .sort(byName)
      .filter((item) => item.enabled === true)
      .map((item) => item.name) || [];

  const allProductsMap = productMap.sort((a, b) => (a.displayPriority < b.displayPriority ? -1 : 1));

  const availableProductOptions = allProductsMap
    .filter((product) => availableFeatures.includes(product.featureName))
    .map((product) => {
      return {
        label: product.referenceName,
        id: product.featureName,
      };
    }) as unknown as ProductSelectOption[];

  //add suite as an option if all products are available
  if (availableProductOptions.length === allProductsMap.length) {
    availableProductOptions.push({
      label: 'Suite',
      id: 'suite',
    });
  }
  return availableProductOptions;
};
