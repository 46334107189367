











































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';

import Select from '@/components/common/form/Select.vue';
import Button from '@/components/common/buttons/Button.vue';

import { productMap } from '@/constants/seeded/products';
import moment from 'moment';

import type { Brand } from '@/types/Brand';
import type { Location } from 'vue-router';
import type { ProductMap } from '@/types/ProductMap';
import type { Product } from '@/types/Product';

Component.registerHooks(['mounted']);

@Component({
  components: {
    Select,
    Button,
  },
})
export default class ProductCard extends Vue {
  @Prop({ type: Object, required: true }) product!: Product;
  @Prop({ type: String, required: false }) to?: string;
  @Prop({ type: String, required: false }) logo?: string;
  @Prop({ type: String, required: false }) lastLogin?: string; // timestamp source here

  @Getter authenticatedUser;
  @Getter defaultBrandId!: Brand['id'];
  @Getter('selectedBrand') currentBrand?: Brand;

  @Action changeSelectedBrand;

  selectedBrandId = NaN;

  get hasBrandAccess() {
    return this.product.active && this.brands && this.brands.length >= 1;
  }

  get isMultiBrand() {
    return this.authenticatedUser.brands.length > 1;
  }

  get brands(): { value: number; label: string }[] {
    return this.authenticatedUser.brands.map((brand) => {
      return { value: brand.id, label: brand.name };
    });
  }

  get brandsCount(): string {
    const plural = this.brands.length === 1 ? '' : 's';
    return `${this.brands.length} brand${plural}`;
  }

  get lastLoginString(): string {
    if (!this.lastLogin) {
      return '';
    }

    return `Last login ${moment(this.lastLogin).fromNow(true)} ago`;
  }

  setSelectedBrand(value: Brand['id']) {
    this.selectedBrandId = value;
  }

  get currentBrandRoute(): Location {
    return { path: `${this.to}/${this.currentBrand?.id}` };
  }

  get selectedBrandRoute(): Location {
    return { path: `${this.to}/${this.selectedBrandId}` };
  }

  get activeRoute(): Location {
    return this.isMultiBrand ? this.selectedBrandRoute : this.currentBrandRoute;
  }

  get learnMoreLinksByProductId(): Record<ProductMap['id'], ProductMap['learn_more_url']> {
    return Object.fromEntries(Object.values(productMap).map((product) => [product.id, product.learn_more_url]));
  }

  async navigateToModule() {
    const currentBrandId = this.currentBrand?.id;
    const isDifferentBrand = currentBrandId !== this.selectedBrandId;

    if (isDifferentBrand) {
      await this.changeSelectedBrand(this.selectedBrandId);
    }

    this.$router.push(this.activeRoute);
  }

  mounted() {
    this.selectedBrandId = this.currentBrand?.id ?? this.defaultBrandId;
  }
}
