








import { Vue } from 'vue-property-decorator';

const ToggleSwitch = Vue.extend({
  props: {
    value: Boolean,
    disabled: Boolean,
    onClickEventHandler: String,
    ariaLabel: String,
    iconName: String,
  },
  methods: {
    onClickEvent() {
      if (this.$props.onClickEventHandler) {
        this.$emit(this.$props.onClickEventHandler);
      }
    },
  },
});

export default ToggleSwitch;
