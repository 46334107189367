









import { Product } from '@/types/account/AccountData';
import Vue from 'vue';
import { mapGetters } from 'vuex';

export default Vue.extend({
  name: 'seat-info-returning-user',

  props: {
    id: {
      type: Number as () => Product['id'],
      default: 0,
    },
  },

  computed: {
    ...mapGetters(['userProductSeats', 'userProductName']),
    seatsData() {
      return this.userProductSeats(this.id);
    },
    name() {
      return this.userProductName(this.id);
    },
  },
});
