<template>
  <fm-drawer :visible="true">
    <div slot="content" class="add-connection__wrapper">
      <close-button class="close-button" @click="$emit('onClose')" />

      <div class="add-connection__heading">
        <fm-text tag="h1">
          {{ headerText }}
        </fm-text>
      </div>

      <div class="add-connection__steps">
        <step-bubbles :currentStep="currentStep" :totalSteps="3" />
      </div>

      <div class="add-connection__content">
        <!-- Step 1 Authorize-->
        <div v-if="currentStep === 1">
          <fm-text tag="h2"> Authorize your YouTube channel </fm-text>
          <fm-text tag="p" style="margin-top: 16px">
            A new window will open. Follow the prompts to authorize your account.
          </fm-text>
          <fm-button-primary @click="onAuthorizeClicked" style="margin-top: 32px">
            {{ authorizeButtonText }}
          </fm-button-primary>
        </div>

        <!-- Step 2 Add Nickname -->
        <div v-if="currentStep === 2">
          <fm-text tag="h1"> Add a nickname for this channel </fm-text>
          <fm-text tag="p" style="margin-top: 16px">
            This is what you’ll see in the publishing destinations list when you create new shows and episodes.
            <br /><br />
            You can also set the default for your videos' privacy setting to control where they can appear and who can
            watch it.
          </fm-text>
          <fm-input
            label="Channel name"
            v-model-wc="$v.form.channelName.$model"
            style="margin-top: 16px"
            :error="$v.form.channelName.$error ? 'Required' : ''"
          />
          <fm-select
            label="Video privacy status"
            v-model-wc="form.privacyStatus"
            :options.prop="privacyStatusOptions"
            style="margin-top: 16px"
          />
        </div>

        <!-- Step 3 Adjust Settings-->
        <div v-if="currentStep === 3">
          <fm-text tag="h2" style="margin-bottom: 32px"> Adjust settings </fm-text>
          <!-- Default on -->
          <fm-checkbox
            label="Publish to this destination by default"
            :options.prop="defaultOptions"
            v-model-wc="defaultValue"
          >
          </fm-checkbox>
        </div>
      </div>

      <div class="add-connection__actions">
        <!-- Submit Button -->
        <fm-button-primary
          class="next-button"
          :success="status === 'success'"
          :disabled="!canContinue"
          @click="onSubmit"
        >
          {{ buttonText }}
        </fm-button-primary>

        <fm-button-secondary v-if="currentStep > 1" class="back-button" @click="onBackClicked">
          Back
        </fm-button-secondary>

        <!-- Cancel Button -->
        <fm-button-secondary class="cancel-button" @click="$emit('onClose')"> Cancel </fm-button-secondary>
      </div>
    </div>
  </fm-drawer>
</template>
<script>
import { createNamespacedHelpers } from 'vuex';
const { mapGetters } = createNamespacedHelpers('CreateAndPublishStore');
const { mapActions: mapActionsShowSetup } = createNamespacedHelpers('CreateAndPublishStore/showSetup');
import { required } from 'vuelidate/lib/validators';
import { v1 } from 'uuid';
import StepBubbles from '@/createandpublish/components/integrations/StepBubbles';
import CloseButton from '@/createandpublish/components/CloseButton';

export default {
  name: 'AddYoutubeConnectionDrawer',

  components: {
    StepBubbles,
    CloseButton,
  },

  props: {
    editingSMC: { type: Object, default: null },
  },

  data() {
    return {
      defaultValue: [],
      isLoading: false,
      status: null,
      isAuthorizedClicked: false,
      form: {
        channelName: '',
        privacyStatus: 'public',
      },
    };
  },

  validations: {
    form: {
      channelName: {
        required,
      },
    },
  },

  computed: {
    ...mapGetters({
      station: 'station_name',
    }),
    ...mapGetters(['allSocialMediaConnections', 'stationUrl']),

    isEditing() {
      return this.editingSMC !== null;
    },

    headerText() {
      return this.isEditing ? 'Edit YouTube account' : 'Create a new YouTube account';
    },

    authorizeButtonText() {
      return this.isEditing ? 'Reauthorize Now' : 'Authorize Now';
    },

    lCustomer() {
      if (this.station) {
        return this.station.toLowerCase();
      }
      return '';
    },

    // Submit/Next is disabled
    canContinue() {
      // User did not authorize YouTube account
      if (this.currentStep === 1 && !this.isAuthorizedClicked) return false;
      // If step 2 and channel name missing
      if (this.currentStep === 2 && this.$v.$invalid) return false;
      // If step 3 and API call is loading
      if (this.isLoading) return false;
      // Anything else
      return true;
    },

    buttonText() {
      switch (this.currentStep) {
        case 1:
          return 'Continue';
        case 2:
          return 'Continue';
        case 3:
        default:
          return 'Save publishing destination';
      }
    },

    currentStep() {
      const _stepQuery = (this.$route.query && this.$route.query.step) || undefined;
      if (_stepQuery) {
        return Number(_stepQuery);
      }
      return undefined;
    },

    defaultOptions() {
      return [
        {
          label: 'Default publish to this destination',
          value: 'post',
        },
      ];
    },

    privacyStatusOptions() {
      return [
        { label: 'Public', value: 'public' },
        { label: 'Private', value: 'private' },
        { label: 'Unlisted', value: 'unlisted' },
      ];
    },
  },

  methods: {
    ...mapActionsShowSetup(['addSocialMediaConnection', 'editSocialMediaConnection']),

    onAuthorizeClicked() {
      this.isAuthorizedClicked = true;
      this.youTubeGuid = v1();
      const url = `${this.stationUrl}api/v1/request_youtube_code?guid=${this.youTubeGuid}`;
      window.open(url, '_blank');
    },

    onBackClicked() {
      if (this.currentStep === 2) {
        this.$router.replace({
          query: {
            newConnection: 'v_youtube',
            step: 1,
          },
        });
      }
      if (this.currentStep === 3) {
        this.$router.replace({
          query: {
            newConnection: 'v_youtube',
            step: 2,
          },
        });
      }
    },

    async onSubmit() {
      switch (this.currentStep) {
        case 1:
          // Go step 2
          this.$router.replace({
            query: {
              newConnection: 'v_youtube',
              step: 2,
            },
          });
          break;
        case 2:
          // Go step 3
          this.$router.replace({
            query: {
              newConnection: 'v_youtube',
              step: 3,
            },
          });
          break;
        case 3:
          await this.onSaveClicked();
          break;
        default:
          break;
      }
    },

    async onSaveClicked() {
      this.isLoading = true;
      const _default_on_post = this.defaultValue.includes('post');
      const payloadFromForm = {
        default_on_post: _default_on_post,
        title: this.form.channelName,
        name: this.youTubeGuid,
        privacy_status: this.form.privacyStatus,
      };

      if (!this.isEditing) {
        await this.addSocialMediaConnection({
          type: 'v_youtube',
          ...payloadFromForm,
        });
      } else {
        await this.editSocialMediaConnection({
          id: this.editingSMC.id,
          ...payloadFromForm,
        });
      }

      this.isLoading = false;
      this.$emit('onClose');
    },
  },

  mounted() {
    if (this.isEditing) {
      const { default_on_post, name, title, privacy_status } = this.editingSMC;
      if (default_on_post) {
        this.defaultValue.push('post');
      }
      this.youTubeGuid = name;
      this.form = {
        channelName: title,
        privacyStatus: privacy_status,
      };
      this.isAuthorizedClicked = true;
    }
  },
};
</script>

<style lang="scss" scoped>
// Styles for this component in /src/styles/integrations/integrations.scss
// and imported in /src/views/Integrations.vue
</style>
