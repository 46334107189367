






















import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
  name: 'BulkActions',
})
export default class BulkActions extends Vue {
  @Prop({ type: Array, default: () => [] }) readonly audioEventIds!: string[];
}
