import HttpClient from '@/services/common/HttpClient';
import UserModel from '@/models/UserModel';
import { AuthenticateRequest } from '@/types/auth/AuthenticateRequest';
import { Feature } from '@/types/features/BEFeature';
export default class AuthService {
  private http: HttpClient = HttpClient.getInstance();
  private endpoint = '/auth';

  public async authenticate(credentials: AuthenticateRequest): Promise<{
    authToken: string;
    currentUser: UserModel;
  }> {
    return await this.http.post(`${this.endpoint}/login`, credentials);
  }

  public async verifyPassword(credentials: AuthenticateRequest): Promise<{
    authToken: string;
    currentUser: UserModel;
  }> {
    return await this.http.post(`${this.endpoint}/verify-password`, credentials);
  }

  public async verifyAvailableFeatures(): Promise<Feature[]> {
    return await this.http.get(`${this.endpoint}/features`);
  }
}
