



















import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({
  name: 'CommunityIcon',
})
export default class CommunityIcon extends Vue {
  @Prop({ type: String, required: false, default: '#222046' }) fill!: string;
  @Prop({ type: Number, required: false, default: 20 }) height!: number;
  @Prop({ type: Number, required: false, default: 19 }) width!: number;
  @Prop({ type: String, required: false, default: 'Creator Community' }) title!: string;
}
