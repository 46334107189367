<template>
  <div class="download-video-modal">
    <button
      v-if="showVideoIcon"
      :class="[
        'episode-video-icon material-icons',
        { inactive: isAnyVideoStillProcessing && !isAnyVideoFailed },
        { failed: isAnyVideoFailed },
        { 'move-up': episode.iheart_status || episode.external_url },
      ]"
      @click="isDownloadVideoModalOpen = true"
      :title="videoSVGTitle"
      aria-label="Download video"
    >
      videocam
    </button>
    <fm-modal v-if="isDownloadVideoModalOpen" :visible="true">
      <template>
        <header slot="controlRow" class="header">
          <fm-text tag="h1" as="h3"> Download video </fm-text>
          <close-button class="header__close-button" @click="close" />
        </header>
      </template>

      <template>
        <section slot="content">
          <fm-text style="margin-bottom: 16px">Select a Video Option to Download</fm-text>
          <fm-text>Note: Some options may be disabled while we finish processing your videos</fm-text>

          <div class="download-video-modal__options">
            <div v-if="episode.video_status !== 0" class="download-video-modal__options__option">
              <fm-button-primary v-if="episode.video_status === 1" disabled title="Video Still Processing"
                >Basic</fm-button-primary
              >
              <a
                v-if="episode.video_status === 2"
                :href="basicVideoUrl"
                type="video/x-matroska"
                download
                @click="close"
              >
                <fm-button-primary>Basic</fm-button-primary>
              </a>
            </div>

            <div v-if="episode.advanced_video_status !== 0" class="download-video-modal__options__option">
              <fm-button-primary v-if="episode.advanced_video_status === 1" disabled title="Video Still Processing"
                >Advanced</fm-button-primary
              >
              <a
                v-if="episode.advanced_video_status === 2"
                :href="advancedVideoUrl"
                type="video/x-matroska"
                download
                @click="close"
              >
                <fm-button-primary>Advanced</fm-button-primary>
              </a>
            </div>
          </div>
          <div>
            <fm-text v-if="episode.video_status === 99">Basic Video Creation Failed</fm-text>
            <fm-text v-if="episode.advanced_video_status === 99">Advanced Video Creation Failed</fm-text>
          </div>
        </section>
      </template>
    </fm-modal>
  </div>
</template>

<script>
import CloseButton from '@/createandpublish/components/CloseButton';

export default {
  components: {
    CloseButton,
  },

  props: {
    episode: Object,
  },

  computed: {
    basicVideoUrl() {
      const stationName = this.$store.getters.settings.station_name;
      const bitrate = this.$store.getters.settings.bitrate;
      return `${this.$store.getters.stationUrl}streamon-perm/${stationName}-${bitrate}-pl-${this.episode.playlist_id}.mp4`;
    },

    advancedVideoUrl() {
      const stationName = this.$store.getters.settings.station_name;
      return `${this.$store.getters.stationUrl}streamon-perm/${stationName.toLowerCase()}-${
        this.episode.playlist_id
      }-advanced.mp4`;
    },

    showVideoIcon() {
      return this.episode.video_status !== 0 || this.episode.advanced_video_status !== 0;
    },

    isAnyVideoStillProcessing() {
      return this.episode.video_status === 1 || this.episode.advanced_video_status === 1;
    },

    isAnyVideoFailed() {
      return this.episode.video_status === 99 || this.episode.advanced_video_status === 99;
    },

    videoSVGTitle() {
      if (this.isAnyVideoFailed) {
        return 'Video processing failed';
      } else if (this.isAnyVideoStillProcessing) {
        return 'Video still processing';
      } else {
        return 'Click to download video';
      }
    },
  },

  data() {
    return {
      isDownloadVideoModalOpen: false,
    };
  },

  methods: {
    close() {
      this.isDownloadVideoModalOpen = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.download-video-modal {
  align-items: center;
  display: flex;

  .episode-video-icon {
    position: relative;
    color: var(--brand-light);
    font-size: 26px;
    cursor: pointer;

    &.move-up {
      top: -6px;
    }

    &.failed {
      color: var(--notification--error);
    }

    &:focus {
      outline: 4px solid #00c6c680 !important;
    }

    &:focus:not(.focus-visible) {
      outline: none !important;
    }

    &:focus-visible {
      outline: 4px solid #00c6c680 !important;
    }
  }

  .header {
    position: relative;
    margin-bottom: 36px;

    &__close-button {
      position: absolute;
      top: 0;
      right: 0;
    }
  }

  &__options {
    display: flex;

    justify-content: space-around;
    align-content: center;
    padding-top: 30px;

    &__option {
      button:disabled {
        opacity: 0.5;
      }
    }
  }
}
</style>
