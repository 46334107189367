










import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class ViewTitle extends Vue {
  @Prop({ default: 'NA', required: true }) title!: string;
  @Prop({ default: 'NA', required: true }) qa!: string;
  @Prop({
    default: false,
    required: false,
  })
  noBorder!: boolean;
}
