<template>
  <fm-modal class="share-url-modal" :visible="true">
    <template>
      <header slot="controlRow" class="header">
        <fm-text tag="h1" as="h3"> Share URL </fm-text>
        <close-button class="header__close-button" @click="close" />
      </header>
    </template>

    <template>
      <section slot="content" class="content">
        <fm-link v-if="url" :href="url" external target="_blank">{{ url }}</fm-link>
        <fm-text v-else>No shortened link is available for this episode.</fm-text>
      </section>
    </template>

    <template>
      <section slot="actionRow" class="actions">
        <fm-button-primary class="copy-button" @click="copyToClipboard" :success="isUrlCopiedToClipboard">
          Copy to Clipboard
        </fm-button-primary>
        <fm-button-secondary @click="close"> Close </fm-button-secondary>
      </section>
    </template>
  </fm-modal>
</template>

<script>
import CloseButton from '@/createandpublish/components/CloseButton';

export default {
  name: 'share-url-modal',

  props: {
    url: String,
  },

  data() {
    return {
      isUrlCopiedToClipboard: false,
    };
  },

  methods: {
    close() {
      this.$emit('on:cancel');
    },

    copyToClipboard() {
      navigator.clipboard.writeText(this.url);
      this.isUrlCopiedToClipboard = true;
    },
  },

  components: {
    CloseButton,
  },
};
</script>

<style lang="scss" scoped>
.share-url-modal {
  .header {
    position: relative;
    margin-bottom: 36px;

    &__close-button {
      position: absolute;
      top: 0;
      right: 0;
    }
  }

  .content {
    margin-bottom: 36px;
    display: flex;
    justify-content: center;
  }

  .actions {
    display: flex;
    justify-content: center;
    align-items: center;

    .copy-button {
      margin-right: 16px;
    }
  }
}
</style>
