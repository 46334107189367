<template>
  <div>
    <div class="settings_card__wrapper">
      <!-- Header -->
      <SettingsCardHeader
        :connectTerm="connectTerm"
        :name="name"
        :onAdd="onAdd"
        :overrideConnectTerm="overrideConnectTerm"
      />
      <SettingsCardItem
        :expansionData="item[expansionDataKey]"
        :isLast="index === data.length - 1"
        :key="index"
        :name="getName(item)"
        :onEdit="() => onEdit(item && item.id)"
        :onRemove="() => onRemove(item && item.id)"
        :term="connectTerm"
        v-for="(item, index) in data"
      />
    </div>
  </div>
</template>
<script>
import SettingsCardHeader from './SettingsCardHeader.vue';
import SettingsCardItem from './SettingsCardItem.vue';

export default {
  name: 'SettingsCard',

  props: {
    connectTerm: String,
    data: Array,
    name: String,
    onAdd: Function,
    onEdit: Function,
    onRemove: Function,
    overrideConnectTerm: String,
  },

  data() {
    return {};
  },

  computed: {
    expansionDataKey() {
      switch (this.name) {
        case 'shows':
          return 'tracking_prefixes';
        default:
          return undefined;
      }
    },
  },

  methods: {
    getName(item) {
      if (item.id) {
        return item.name;
      }
      return `All ${this.name}`;
    },
  },

  components: {
    SettingsCardHeader,
    SettingsCardItem,
  },
};
</script>
<style lang="scss" scoped>
.settings_card__wrapper {
  background: var(--neutral-white) 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 7px #22204633;
  margin-bottom: 24px;
}
</style>
