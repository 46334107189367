<template>
  <div class="settings-view" :data-qa="$getComponent('Settings', 'Parent', 'settingsPage')">
    <div id="settings">
      <header>
        <fm-text tag="h1" class="heading-h1">Settings</fm-text>
      </header>

      <Tabs
        :titles="[{ title: 'Podcast settings' }, { title: 'Social accounts & profiles' }]"
        :data-qa="$getComponent('Settings', 'Tabs', 'settingsTabs')"
      >
        <!-- Trending Sources Tab -->
        <template slot="tab1">
          <!-- Trending Sources Tab -->
          <PodcastSettingsTab
            id="podcast-settings-tab"
            :data-qa="$getComponent('Settings', 'Tab', 'podcastSettings')"
          />
        </template>

        <template slot="tab2">
          <!-- RSS Feeds Tab -->
          <SocialAccountsAndProfileTab
            id="social-accounts-tab"
            :data-qa="$getComponent('Settings', 'Tab', 'socialAccounts')"
          />
        </template>
      </Tabs>
    </div>
  </div>
</template>

<script>
import { Component, Vue } from 'vue-property-decorator';
import Select from '@/components/common/form/Select.vue';
import Tabs from '@/components/common/tabs/Tabs.vue';
import SocialAccountsAndProfileTab from '@/createandpublish/components/SocialAccountsAndProfileTab.vue';
import PodcastSettingsTab from '@/createandpublish/components/PodcastSettingsTab.vue';

@Component({
  name: 'CNPSettings',
  components: {
    Select,
    Tabs,
    SocialAccountsAndProfileTab,
    PodcastSettingsTab,
  },
  data: () => {
    return {};
  },
  props: {},
})
export default class CNPSettings extends Vue {}
</script>

<style lang="scss" scoped>
.settings-view {
  margin: 32px 48px 0 48px;

  .settings {
    font-family: 'Roboto';
    padding: 2.1em 2em;
    width: 100%;
    background: var(--neutral-grey-5-lightest-bg);
  }
}
</style>
