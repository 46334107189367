<template>
  <fm-drawer :visible="true">
    <div slot="content" class="tracking">
      <!-- Heading -->
      <div class="tracking__heading">
        <fm-heading>
          <h1>Edit tracking URLs</h1>
        </fm-heading>
      </div>
      <!-- Subtitle -->
      <div class="tracking__subtitle">
        This will immediately update the tracking URL to any feeds being used for the selected show.
      </div>
      <!-- Tracking URLs list -->
      <div class="tracking__content">
        <fm-body variant="MEDIUM">
          <b>Destination show</b>
        </fm-body>
        <div class="tracking__show-name">
          <fm-body v-if="isEditing" variant="LARGE">
            {{ showName }}
          </fm-body>
          <Select v-else :options="showOptions" :input-event-handler="onShowChange" />
        </div>
        <fm-body variant="MEDIUM">
          <b>Tracking URLs</b>
        </fm-body>
        <div class="tracking_urls__list_wrapper">
          <div :key="index" class="tracking_urls__url-container" v-for="(url, index) in trackingURLs">
            <!-- Tracking URL string -->
            <div class="tracking_urls__url">
              <fm-body variant="MEDIUM">
                <b>{{ url }}</b>
              </fm-body>
            </div>
            <!-- Remove tracking URL -->
            <span class="tracking_urls__remove_url_icon material-icons" @click="removeUrl(index)"> clear </span>
          </div>
          <fm-input
            label="Add New Tracking URL"
            placeholder="Add new Tracking URL"
            :value="form.newURL"
            v-model-wc="$v.form.newURL.$model"
            @keyup.enter="addUrl"
            :error="errorMessage"
          />
          <fm-button-tertiary-small @click="addUrl"> Add URL </fm-button-tertiary-small>
        </div>
        <fm-body variant="DISCLAIMER"> Copy the tracking URL provided by your 3rd party platform. </fm-body>
      </div>
      <div class="tracking__buttons">
        <!-- Edit Button -->
        <fm-button-primary :success="status === 'success'" @click="save"> Save </fm-button-primary>

        <!-- Cancel Button -->
        <fm-button-secondary @click="onCancel"> Cancel </fm-button-secondary>
      </div>
    </div>
  </fm-drawer>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
const { mapActions, mapGetters } = createNamespacedHelpers('CreateAndPublishStore');
import { required, url } from 'vuelidate/lib/validators';
import Select from '../../../components/common/form/Select.vue';

export default {
  name: 'EditTrackingURLsDrawer',
  components: {
    Select,
  },
  data() {
    return {
      loading: false, // State of saving
      form: {
        newURL: '',
      },
      status: null, // Status of saving
      trackingURLs: [],
      activeShow: this.show,
      errorMessage: '',
    };
  },

  validations: {
    form: {
      newURL: {
        url,
        required,
        isUnique(value) {
          if (value === '') return true;
          return !this.trackingURLs.includes(value);
        },
      },
    },
  },

  computed: {
    ...mapGetters(['stationTrackingPrefixList', 'unfilteredShows']),
    showName() {
      if (!this.show) {
        return 'All shows';
      }
      return this.show.title;
    },
    showOptions() {
      return [
        { id: null, value: 'All shows' },
        ...this.unfilteredShows.map((show) => ({ id: show.id, value: show.title })),
      ];
    },
  },

  methods: {
    ...mapActions(['setStationTrackingPrefixList', 'setShowTrackingPrefixList']),
    addUrl() {
      this.$v.form.$touch();
      this.errorMessage = '';
      if (this.$v.$invalid) {
        if (!this.$v.form.newURL.url) {
          this.errorMessage = 'Invalid URL';
        }
        if (!this.$v.form.newURL.isUnique) {
          this.errorMessage = 'That URL already exists';
        }
        return;
      }

      this.trackingURLs.push(this.$v.form.newURL.$model);
      this.form.newURL = '';
      this.$v.$reset();
    },
    async removeUrl(index) {
      this.trackingURLs.splice(index, 1);
    },
    async save() {
      await this.savePrefixList(this.trackingURLs);
      this.form.newURL = '';
    },
    async savePrefixList(urls) {
      this.loading = true;

      try {
        if (!this.activeShow) {
          await this.setStationTrackingPrefixList({ prefix: urls });
        } else {
          await this.setShowTrackingPrefixList({ showId: this.activeShow.id, prefixObj: { prefix: urls } });
        }
        this.status = 'success';
        // Close drawer when done
        this.onCancel();
      } catch (err) {
        this.$store.commit('CreateAndPublishStore/SET_MESSAGE', {
          name: 'Tracking URL',
          details:
            'One or more of the tracking URLs you provided is invalid. Please verify with your provider and try again.',
          type: 'error',
        });
        // Close drawer when done
        this.onCancel();
      } finally {
        this.loading = false;
      }
    },
    onShowChange(e) {
      const showId = Number(e.target.value);
      const show = this.unfilteredShows.find((item) => item.id === showId);
      this.activeShow = show;
      if (show) {
        this.trackingURLs = [...show.tracking_prefixes];
      } else {
        this.trackingURLs = [...this.stationTrackingPrefixList];
      }
    },
  },
  created() {
    if (this.show) {
      this.trackingURLs = [...this.show.tracking_prefixes];
      // this.activeShow = this.show;
    } else {
      this.trackingURLs = [...this.stationTrackingPrefixList];
    }
  },
  props: {
    show: Object,
    isVisible: Boolean,
    onCancel: Function,
    isEditing: Boolean,
  },
};
</script>

<style lang="scss" scoped>
.tracking {
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  height: 100%;
  &__subtitle {
    font-family: 'Roboto', sans-serif;
    margin-bottom: 1rem;
  }
  &__content {
    flex-grow: 1;
    overflow: hidden;
    overflow-y: auto;
    padding: 1rem 1.5rem;
    margin: 0 -1.5rem;
  }
  &__show-name {
    margin-bottom: 1.5rem;
  }
  &__buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 1rem;
  }
}

.tracking_urls {
  // Wraps the list of tracking urls
  &__list_wrapper {
    border-radius: 8px;
    border: 2px solid var(--neutral-grey-3-borders);
    margin-top: 8px;
    padding: 7px 8px;
    width: 100%;
  }
  // Wraps a single tracking url item
  &__url-container {
    border-radius: 18px;
    border: 2px solid var(--neutral-grey-3-borders);
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    padding: 6.5px 12px;
  }
  // Actual tracking url string
  &__url {
    width: 100%;
  }
  // Icon to remove tracking url
  &__remove_url_icon {
    cursor: pointer;
    margin-right: 12px;
    width: 10.5px;
  }
}

.tracking_url__clear {
  height: 40px;
  margin-left: 16px !important;
  padding: 5px 10px !important;
  position: relative;
  top: -1px;
}
</style>
