<template>
  <div class="container" :class="{ audience: audience }">
    <div class="sidebar-wrapper" v-if="audience">
      <AudienceSidebar />
    </div>
    <div class="row" :class="{ trial: trial, audience: audience }">
      <div class="form col">
        <div class="form-header" v-if="audience">
          <router-link to="/">
            <img alt="logo" src="@/assets/FuturiCC.svg" height="61" />
          </router-link>
        </div>
        <!-- Success message - shown when on trial sign up siccess -->
        <AlertDisplay :alertMessage="alertMessageTrial" class="alertMessage" v-if="trial" />
        <!-- Success message - shown when on invitation code has been re-sent -->
        <AlertDisplay :alertMessage="alertMessageResend" class="alertMessage" v-if="showResendAlert" />
        <!-- Success message - shown when checkout sign up success -->
        <AlertDisplay :alertMessage="alertMessageCheckout" class="alertMessage" v-if="isCheckout" />
        <h2>{{ h2Title }}</h2>
        <h3>Ready to get started?</h3>
        <p :class="{ trial: trial }" v-html="cta"></p>
        <!-- <div class="resend-invitation">
          Didn’t get it?
          <span @click="resendInvitationCode" role="button" class="resend-invitation-link" v-if="!isAudienceFlow">
            Resend the verification email
          </span>
        </div> -->
      </div>

      <div class="sidebar col" v-if="!trial && !audience">
        <Receipt :data="data" />
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import Receipt from '@/components/ecomm/Receipt.vue';
import AlertDisplay from '@/components/common/AlertDisplay.vue';
import AudienceSidebar from '@/components/ecomm/AudienceSidebar.vue';

export default Vue.extend({
  name: 'confirmation',

  components: {
    AlertDisplay,
    AudienceSidebar,
    Receipt,
  },

  props: {
    data: {
      type: Object,
      default() {
        return {};
      },
    },
    audience: {
      type: Boolean,
      default: false,
    },
    trial: {
      type: Boolean,
      default: false,
    },
    emailAddress: {
      type: String,
    },
    isCheckout: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      alertMessageTrial: {
        type: 'success',
        header: 'You’re signed up!',
        message: `A confirmation email has been sent to <b>${this.data.emailAddress}</b>.`,
      },
      alertMessageResend: {
        type: 'Success',
        header: '',
        message: `Your invitation was resent.`,
      },
      alertMessageCheckout: {
        type: 'success',
        header: "We've processed your order",
        message: `A confirmation email has been sent to <b>${this.data.emailAddress}`,
      },
      showBilling: false,
      showCompletePurchase: false,
      formIsLoading: false,
      showResendAlert: false,
    };
  },

  computed: {
    h2Title() {
      //todo: add a state for existing users where title would be
      //'Thank you for subscribing!'
      if (this.audience) {
        return `You're in!`;
      } else {
        return 'Welcome to Content Cloud!';
      }
    },

    cta() {
      if (this.trial) {
        return 'Check your email to set up your password and start using your free trial of Content Cloud.';
      } else if (this.audience) {
        return `Thanks for signing up! We sent a verification email to <b>${this.data.emailAddress}</b>. Click the link in the email to set your password and get started.`;
      } else {
        return 'Check your email to set up your password and start using your new Content Cloud subscription.';
      }
    },

    isAudienceFlow() {
      return this.flow === 'audience';
    },
  },
  methods: {
    //  Resend invitation code
    // async resendInvitationCode() {
    //   const { emailAddress, firstName } = this.data;
    //   const _payload = {
    //     emailAddress,
    //     firstName,
    //   };
    //   const _payload = {
    //     emailAddress: 'lisa.meehan+ec90@rtslabs.com',
    //     firstName: 'lisa'
    //   };
    //   // set loading status
    //   this.resendInvitationStatus = 'loading';
    //   // api request
    //   const res = await new UserService().resendInvitationEmail(_payload);
    //   // if not successful
    //   if (res.errors) {
    //     this.resendInvitationStatus = 'error';
    //     this.resendInvitationAlertMessage.message = res.errors[0];
    //   } else {
    //     // if successful
    //     this.resendInvitationStatus = 'success';
    //   }
    //   this.showResendAlert = true;
    // },
  },
});
</script>

<style lang="scss" scoped>
.container {
  background: var(--neutral-white);
  max-width: 1208px;
  padding-bottom: 48px;
  padding-top: 48px;
}

.row {
  justify-content: space-between;
}
.row.trial {
  justify-content: center;
}

.col {
  @media (max-width: 768px) {
    flex: none;
    padding: 0 24px;
  }
}

.form {
  max-width: 622px; // account for padding
  // min-width: 536px;
  margin: 0;

  h2 {
    font-size: var(--fm-font-size-36);
    line-height: var(--fm-line-spacing-44);
    margin-bottom: 24px;
    margin-bottom: 24px;
  }

  h3 {
    margin-bottom: 16px;
  }

  p.trial {
    font-size: 20px;
    line-height: 28px;
  }

  @media (max-width: 768px) {
    margin-bottom: 64px;
  }

  h3 {
    font-size: var(--fm-font-size-24);
    line-height: var(--fm-line-spacing-32);
    margin-bottom: 16px;
  }

  p {
    font-size: var(--fm-font-size-20);
    line-height: var(--fm-line-spacing-28);
  }

  .custom-button {
    margin-top: 24px;

    @media (max-width: 768px) {
      width: 100%;
    }
  }

  @media (max-width: 768px) {
    margin-bottom: 64px;
    max-width: 100%;
  }
}

.audience.container {
  display: flex;
  flex-direction: row;
  height: 100vh;
  max-width: 100%;
  padding: 0;

  @media (max-width: 768px) {
    flex-direction: column;
  }

  .row.audience {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin: 0;
    width: 50%;

    @media (max-width: 768px) {
      padding: 32px 24px;
      width: 100%;
    }
  }

  .form-header {
    margin: 72px 0 64px;

    @media (max-width: 768px) {
      display: none;
    }
  }

  h3 {
    display: none;
  }

  .sidebar-wrapper {
    background: var(--accent-background);
    height: 100%;
    order: 1;
    width: 50%;

    @media (max-width: 768px) {
      height: auto;
      order: 0;
      width: 100%;
    }
  }

  .resend-invitation {
    color: var(--neutral-grey-2-lighter-text);
    font-size: var(--fm-font-size-14);
    line-height: var(--fm-line-spacing-21);
    margin-top: 64px;

    &-link {
      color: var(--accent-links);
    }
  }
}
</style>
