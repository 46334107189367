









import { Component, Vue } from 'vue-property-decorator';

@Component({
  name: 'NoChartDataBanner',
})
export default class NoChartDataBanner extends Vue {}
