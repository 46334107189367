<template>
  <div class="your-info">
    <!-- Subtitle -->
    <h3 class="form-subtitle" v-if="!trial">
      <span>Your information</span>
      <button v-if="!firstRun && !editable" class="editable-toggle" type="button" @click="editable = true">Edit</button>
    </h3>

    <form v-if="editable" v-on:submit.prevent="onSubmit" novalidate>
      <div class="position-relative">
        <router-link to="login" class="sign-in" v-if="!trial">Already have an account? Sign in</router-link>
        <Input
          :value="form.emailAddress"
          :inputHandler="handleInputChange"
          maxLength="100"
          class="form-input"
          label="Email address"
          name="emailAddress"
          type="email"
          :error="
            $v.form.emailAddress.$error
              ? !$v.form.emailAddress.email
                ? errorMessages.invalidEmail
                : 'Email' + errorMessages.required
              : ''
          "
        />
      </div>
      <div class="row">
        <div class="col-sm">
          <Input
            :value="form.firstName"
            :inputHandler="handleInputChange"
            maxLength="20"
            class="form-input"
            label="First name"
            name="firstName"
            :error="$v.form.firstName.$error ? 'First name' + errorMessages.required : ''"
          />
        </div>
        <div class="col-sm">
          <Input
            :value="form.lastName"
            :inputHandler="handleInputChange"
            maxLength="20"
            class="form-input form-lastname"
            label="Last name"
            name="lastName"
            :error="$v.form.lastName.$error ? 'Last name' + errorMessages.required : ''"
          />
        </div>
      </div>
      <!-- Company field -->
      <div class="position-relative" v-if="trial">
        <Input
          :value="form.companyName"
          :inputHandler="handleInputChange"
          maxLength="32"
          class="form-input"
          label="Company name (optional)"
          name="companyName"
        />
      </div>
      <div class="position-relative">
        <Input
          :value="form.audienceProfileName"
          :inputHandler="handleInputChange"
          maxLength="100"
          class="form-input"
          label="Profile display name"
          name="audienceProfileName"
          type="text"
          assistive-text="The public facing name for your brand/company will be used in the Content Cloud Community."
          :error="$v.form.audienceProfileName.$error ? 'Profile display name' + errorMessages.required : ''"
        />
      </div>
      <Button :loading="formIsLoading || externalFormIsLoading" buttonType="primary" class="form-submit" type="submit"
        >{{ submitButtonText }}
      </Button>
      <!-- Trial terms of service and policy copy  -->
      <div class="position-relative" v-if="trial">
        <p class="trial-terms-privacy-copy">
          By starting your free trial, you agree to Futuri’s
          <router-link to="/checkout/free-trial">Terms of Service</router-link> and
          <router-link to="/checkout/free-trial">Privacy Policy</router-link>.
        </p>
      </div>
    </form>

    <div v-else class="personal-information">
      <p class="mb-0">
        {{ `${form.firstName} ${form.lastName}` }}
      </p>
      <p class="email-address" v-text="form.emailAddress"></p>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { required } from 'vuelidate/lib/validators';
import { validateEmail as $valEmail } from '@/plugins/Validations';
import { validateName, noSpecialCharacters, validateProfileName } from '@/plugins/Validations';
import Button from '@/components/common/buttons/Button.vue';
import Input from '@/components/common/form/Input.vue';
import errorMessages from '@/utils/errorMessages.json';

export default Vue.extend({
  name: 'info',

  props: {
    emailAddress: String,
    trial: {
      type: Boolean,
      default: false,
    },
    externalFormIsLoading: {
      type: Boolean,
      default: false,
    },
  },

  components: {
    Button,
    Input,
  },

  data() {
    return {
      firstRun: true,
      editable: true,
      form: {
        emailAddress: this.emailAddress || '',
        firstName: '',
        lastName: '',
        audienceProfileName: '',
      },
      formIsLoading: false, // is form submitting?
      errorMessages, // Input error messages
    };
  },

  computed: {
    submitButtonText() {
      // If trial
      if (this.trial) {
        return 'Begin my free trial';
      } else if (this.firstRun) {
        // If not trial and if first run
        return 'Continue to billing';
      }
      // Default
      return 'Update';
    },
  },

  validations: {
    form: {
      emailAddress: { required, $valEmail },
      firstName: { required, validateName, noSpecialCharacters },
      lastName: { required, validateName, noSpecialCharacters },
      audienceProfileName: { required, validateProfileName },
    },
  },

  methods: {
    handleInputChange(e) {
      this.form[e.target.name] = e.target.value;
    },
    onSubmit() {
      // Touch all fields
      this.$v.form.$touch();

      // if form's validated
      if (!this.$v.form.$invalid) {
        // go to the next step if not trial
        if (!this.trial) {
          this.editable = false;
          this.firstRun = false;
        }

        this.$emit('success', { ...this.form });
        return;
      }
    },
  },

  beforeMount() {
    // populate fields from query params
    const queryParams = this.$route.query;
    if (queryParams.emailAddress) {
      this.$data.form.emailAddress = queryParams.emailAddress;
    }
    if (queryParams.firstName) {
      this.$data.form.firstName = queryParams.firstName;
    }
    if (queryParams.lastName) {
      this.$data.form.lastName = queryParams.lastName;
    }
  },
});
</script>

<style lang="scss" scoped>
.form-subtitle {
  align-items: center;
  display: flex;
  font-size: 24px;
  justify-content: space-between;
  margin-bottom: 16px;

  span {
    line-height: 32px;
  }
}

.form-input {
  margin-bottom: 32px;
}

.form-submit {
  @media (max-width: 768px) {
    // display: none;
    position: absolute;
    bottom: 0;
    left: 15px;
    width: calc(100% - 30px);
  }
}

.sign-in {
  color: var(--accent-links);
  font-size: 14px;
  position: absolute;
  right: 0;
  text-decoration: underline;
  top: 0;

  &:hover {
    text-decoration: none;
  }
}

.editable-toggle {
  background: none transparent;
  border: 0;
  color: var(--accent-links);
  font-size: 1rem;
  overflow: visible;
  padding: 0;
  text-decoration: underline;

  &:hover {
    color: var(--accent-links-hover);
    text-decoration: none;
  }
}

.personal-information {
  font-size: 20px;
}

p.email-address {
  word-wrap: break-word;
}

p.trial-terms-privacy-copy {
  color: var(--neutral-grey-2-lighter-text);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin-top: 32px;
}

p.trial-terms-privacy-copy a {
  color: var(--accent-links);
}
</style>
