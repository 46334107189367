import { productMap } from '@/constants/seeded/products';
import type { AvailableProduct } from '@/types/account/AccountData';
import type { Product } from '@/types/Product';

/**
 * Sort products by priority for UI display
 * @param products List of products
 */
export const getPrioritySortedProducts = (products: Product[], availableProducts: AvailableProduct[] = []) => {
  const fromAvailableProductsToProducts: Product[] = [
    ...availableProducts.map((product) => ({
      ...product,
      currentSeats: 0,
      maxSeats: 0,
      active: false,
    })),
  ];

  const uniqueProductIds: Set<Product['id']> = new Set();

  const uniqueProducts = [...products, ...fromAvailableProductsToProducts].filter((product) => {
    const isDuplicate = uniqueProductIds.has(product.id);
    uniqueProductIds.add(product.id);
    return !isDuplicate;
  });

  // Sort products by display priority
  const sortedProducts = uniqueProducts.sort(function (a, b) {
    // Sort by display priority
    const priority1 = productMap.find((e) => e.id === a.id)?.displayPriority || 100;
    const priority2 = productMap.find((e) => e.id === b.id)?.displayPriority || 100;
    return priority1 - priority2;
  });

  return sortedProducts;
};
