











































































































































import { Action, Getter } from 'vuex-class';
import { Component, Vue } from 'vue-property-decorator';

import AddEditCampaignDrawer from '../components/brands/AddEditCampaignDrawer.vue';
import BoilTable from '../components/common/table/Table.vue';
import Breadcrumb from '../components/common/navigation/breadcrumb/Breadcrumb.vue';
import ViewTitle from '../components/common/view/viewTitle/ViewTitle.vue';
import { Brand } from '@/types/Brand';
import { Campaign } from '@/types/Campaign';
import BrandService from '@/services/BrandService';
import AlertDisplay from '@/components/common/AlertDisplay.vue';
import Loading from '@/components/common/Loading.vue';
import Button from '@/components/common/buttons/Button.vue';
import { byName } from '../utils/sort';

@Component({ components: { Breadcrumb, BoilTable, ViewTitle, AddEditCampaignDrawer, Loading, AlertDisplay, Button } })
export default class Users extends Vue {
  @Getter allBrands!: Brand[];

  @Action fetchBrands;

  // Breadcrumb items
  breadcrumbItems = [
    { text: 'Account Overview', to: { name: 'Dashboard' } },
    { text: 'Manage campaigns', active: true },
  ];

  campaignDrawer = {
    campaignData: {},
  };

  currentDelete = null;

  campaignsListIsLoading = true; // When fetchBrands() is loading

  isLoading = false;

  forcedNoCampaigns = false; // Temp to showcase empty state
  alertMessage = {};
  // For sorting direction
  ascending = true;
  // Let's use the first one for now, to simulate 1 Brand account
  get currentBrand() {
    let result: Brand = {
      active: true,
      campaigns: [],
      id: -1,
      name: '',
    };
    if (this.allBrands) {
      result = this.allBrands[0];
    }
    return result;
  }

  // Current campaign's campaigns
  get currentCampaigns() {
    // Temp to showcase empty state
    if (this.forcedNoCampaigns) {
      return [];
    }
    let result: Campaign[] = [];
    if (this.currentBrand) {
      result = this.currentBrand.campaigns || [];
    }

    return result.sort(byName);
  }

  // Drawer Methods
  openCampaignDrawer(campaignRowItem) {
    // Initial data
    const dataObj: Campaign = {
      brandId: this.currentBrand.id,
      active: true,
      color: '#EE4833',
      name: '',
      id: 0,
    };

    // If editing campaign
    if (campaignRowItem) {
      dataObj.active = campaignRowItem.active;
      dataObj.color = campaignRowItem.color;
      dataObj.name = campaignRowItem.name;
      dataObj.id = campaignRowItem.id;
    }

    this.campaignDrawer.campaignData = dataObj;
  }

  handleCampaignDrawerCancel() {
    this.campaignDrawer.campaignData = {
      brandId: 0,
      active: true,
      color: '#EE4833',
      name: '',
    };
  }

  // Delete methods
  handleConfirmation(id) {
    this.currentDelete = id;
  }

  async handleDelete(campaign: Campaign) {
    const removeCampaign = {
      active: false,
      brandId: campaign.brandId,
      color: campaign.color,
      name: campaign.name,
      id: campaign.id,
    };

    this.isLoading = true;
    const response = await new BrandService()
      .editCampaign(removeCampaign)
      .then((res) => res)
      .catch((err) => err.response);

    if (!response || !response.id) {
      this.alertMessage = {
        type: 'critical',
        header: "We couldn't save your changes.",
        message: 'Please try again in a few minutes',
      };
    }

    this.isLoading = false;
    this.fetchBrands();
  }

  async mounted() {
    await this.fetchBrands();
    this.campaignsListIsLoading = false;
  }
}
