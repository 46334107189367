























import { Component, Vue, Prop } from 'vue-property-decorator';
import StatIncreaseIcon from '@/assets/audience/arrow-up-icon.svg';
import StatDecreaseIcon from '@/assets/audience/arrow-down-icon.svg';
import GenericAvatarImage from '@/assets/audience/generic-avatar.svg';

@Component({
  name: 'LeaderboardCardHeader',
})
export default class LeaderboardCardHeader extends Vue {
  @Prop({ type: String, required: true }) profileName!: string;
  @Prop({ type: String, required: true, default: '' }) profileImageSrc!: string;
  @Prop({ type: Number, required: true }) currentRank!: number;
  @Prop({ type: Number, required: true }) positionChange!: number;

  get isPositionChangePositive() {
    return Math.sign(this.positionChange) !== -1;
  }

  get statIndicatorIcon() {
    return this.isPositionChangePositive ? StatIncreaseIcon : StatDecreaseIcon;
  }

  get displayedPositionChange() {
    return Math.abs(this.positionChange);
  }

  get currentRankString() {
    return isNaN(this.currentRank) ? 'Unranked' : `#${this.currentRank}`;
  }

  onImgError($event: Event) {
    const imageEl = $event.target as HTMLImageElement;
    imageEl.src = GenericAvatarImage;
  }
}
