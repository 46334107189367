<template>
  <div class="editor-metadata-card-container" @click="onClick">
    <div
      :class="categoryColorMap"
      :style="{
        padding: calcPadding,
        width: segment_width + 'px',
        left: segment_offset ? segment_offset + 'px' : '0px',
      }"
    >
      <div class="event-title-span-container outer-wrapper" :style="{ marginLeft: leftMargin }">
        <span class="event-title-span inner-wrapper">{{ event.title }}</span>
      </div>
      <div class="spacer" />
      <div class="event-data-wrapper" :style="{ marginLeft: leftMargin }">
        <div class="outer-wrapper">
          <span class="emd-bold inner-wrapper">{{ categoryName }}</span>
        </div>
        <div class="outer-wrapper">
          <span class="emd-bold inner-wrapper">{{ preferredTimestampProperty | formatDate(timeZone) }}</span>
        </div>

        <div class="outer-wrapper">
          <span class="inner-wrapper">{{ event.duration | shortDuration }}</span>
        </div>
        <div class="outer-wrapper">
          <span class="inner-wrapper">{{ preferredTimestampProperty | formatTime(timeZone) }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment-timezone';
import 'moment-duration-format';
import { formatTime, formatDate } from '@/createandpublish/core/filters';

const SHORT_DURATION_FMT = 'mm:ss';

export default {
  name: 'editor-metadata-card',
  props: {
    event: {
      type: Object,
      required: true,
    },
    eventId: {
      type: String,
    },
    segment_width: {
      type: Number,
      required: true,
    },
    segment_offset: {
      type: Number,
      required: true,
    },
  },
  computed: {
    timeZone() {
      return this.$store.getters['CreateAndPublishStore/timezone'];
    },
    categoryColorMap() {
      const baseClass = { 'metadata-card': true };
      if (!this.categoryName) {
        return baseClass;
      }
      return {
        ...baseClass,
        'metadata-card__teal': this.categoryName.includes('Promo'),
        'metadata-card__green': this.categoryName.includes('Talk'),
        'metadata-card__purple': this.categoryName.includes('Music'),
      };
    },
    preferredTimestampProperty() {
      return this.event.updated_at ? this.event.updated_at : this.event.created_at;
    },
    categoryName() {
      return this.event.cart?.category?.name || 'None';
    },
    spacerOffset() {
      return this.segment_offset + this.segment_width + 28 + 'px';
    },
    leftMargin() {
      return this.segment_offset > 0 ? '0px' : -1 * this.segment_offset + 'px';
    },
    calcPadding() {
      return this.segment_width > 32 ? '16px' : this.segment_width / 2 + 'px';
    },
  },
  methods: {
    onClick() {
      this.$emit('seekToAudio');
    },
  },
  filters: {
    shortDuration: (seconds) => {
      // we always want to show at least 00:01 because otherwise it looks super weird to have 00:00 when less than a second duration
      const floatSeconds = parseFloat(seconds) > 1 ? parseFloat(seconds) : 1;
      return moment.duration(floatSeconds, 'seconds').format(SHORT_DURATION_FMT, { trim: false });
    },
    formatDate: formatDate,
    formatTime: formatTime,
  },
};
</script>

<style lang="scss">
.editor-metadata-card-container {
  cursor: pointer;
}
.metadata-card {
  user-select: none;
  position: absolute;
  height: 200px;
  top: 0;
  border: 2px solid rgba(0, 0, 0, 0.25);
  word-wrap: break-word !important;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &__blue {
    background-color: #0174b7;
  }
  &__green {
    background-color: #30780c;
  }
  &__teal {
    background-color: #2b8282;
  }
  &__purple {
    background-color: #222046;
  }
}
.editor-metadata-card.selected {
  border: 4px solid #16c9a5;
}

.editor-metadata-card .editor-metadata-tooltip::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}
.spacer {
  height: 44px;
}
.event-title-span-container {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  color: white;
  font: 20px/28px Roboto;
}

.event-title-span {
  left: 0;
  color: white;
}

.event-data-wrapper {
  display: grid;
  grid-template-columns: repeat(2, minmax(1px, 10em));
  height: 50px;
  div {
    font: 14px/21px Roboto;
    color: white;
    width: 100%;
  }
}

.emd-bold {
  font-weight: 600;
}

.outer-wrapper {
  overflow: hidden;
  height: 1.2em;
  line-height: 1.2em;
}
.outer-wrapper::before {
  content: '';
  display: inline-block;
}
.inner-wrapper {
  display: inline-block;
  white-space: nowrap;
}
</style>
