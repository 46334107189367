


























import { Component, Vue } from 'vue-property-decorator';
export default {
  props: ['prop'],
};

@Component
export class FooterLinkColumn extends Vue {}
