<script>
// See /documentation/MediaUploads.md for documentation
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { v4 as uuidv4 } from 'uuid';

const MAX_MILLIS_SINCE_LAST_UPDATE = 1000 * 60 * 2; // two minutes
const MODULE_NAMESPACE = 'CreateAndPublishStore/mediaUploads';
const mediaUploadsModule = namespace(MODULE_NAMESPACE);

function prepareAudioUploadPayload(file, metaData) {
  const uuid = uuidv4();
  const single_use = !!metaData.single_use;
  const title = metaData.title || file.name;
  const audio_type = metaData.audio_type || 'UPLOAD-Talk';
  const campaigns = metaData.campaignIds || [];

  return {
    uuid,
    title,
    file,
    audio_type,
    single_use,
    campaigns,
  };
}

function prepareImageUploadPayload(file, metaData) {
  const uuid = uuidv4();
  const title = metaData.title || file.name;
  const description = metaData.description || file.name;
  const campaigns = metaData.campaignIds || [];

  return {
    uuid,
    title,
    file,
    description,
    campaigns,
  };
}

function prepareVideoUploadPayload(file, metaData) {
  const uuid = uuidv4();
  const title = metaData.title || file.name;
  const description = metaData.description || file.name;
  const campaigns = metaData.campaignIds || [];

  return {
    uuid,
    title,
    file,
    description,
    campaigns,
  };
}

@Component({})
export default class UploadMediaMixin extends Vue {
  unwatch = null;
  lastUpdatedTimer = null;

  @mediaUploadsModule.Getter progress;
  @mediaUploadsModule.Getter isUploading;
  @mediaUploadsModule.Getter lastUpdatedMediaUploads;
  @mediaUploadsModule.Getter areAllUploadsComplete;
  @mediaUploadsModule.Getter singleUseEventUris;
  @mediaUploadsModule.Getter mediaLibraryEventUris;
  @mediaUploadsModule.Getter failedMediaUploads;

  @mediaUploadsModule.State uploadType;

  @mediaUploadsModule.Action('uploadMedia') uploadFileWithModule; // renamed to avoid potential conflicts

  startUploadWithFile(file, metaData = {}) {
    if (!file || !(file instanceof File)) {
      throw new TypeError(`[Upload Service] Expected to receive file, received ${file}.`);
    }

    if (typeof this.unwatch !== 'function') {
      throw new Error('[Upload Service] startUploadWithFile called before initMediaUploadService');
    }

    let payload;
    switch (this.uploadType) {
      case 'audio':
        payload = prepareAudioUploadPayload(file, metaData);
        break;
      case 'image':
        payload = prepareImageUploadPayload(file, metaData);
        break;
      case 'video':
        payload = prepareVideoUploadPayload(file, metaData);
        break;
      default:
        // This shouldn't happen, since we verify type in the init call,
        // but putting this here in case something really janky occurs.
        throw new Error(
          "[Upload Service] Couldn't identify upload type when startUploadWithFile was called. Likely that mixin state was mutated improperly."
        );
    }

    this.uploadFileWithModule(payload);
  }

  initMediaUploadService(uploadType, onCompleteCb) {
    if (typeof onCompleteCb !== 'function') {
      throw new TypeError(`[Upload Service] Expected function, received ${typeof onCompleteCb}`);
    }

    if (!uploadType || !['audio', 'image', 'video'].includes(uploadType)) {
      throw new TypeError(
        `[Upload Service] Expected to receive a valid upload type, got ${uploadType || typeof uploadType}`
      );
    }

    this.$store.commit(`${MODULE_NAMESPACE}/SET_UPLOAD_TYPE`, uploadType);

    this.unwatch = this.$watch('areAllUploadsComplete', async () => {
      if (this.areAllUploadsComplete) {
        if (this.failedMediaUploads.length) {
          // if we have errors, inform user
          let errDesc = '';
          this.failedMediaUploads.forEach(({ title, err_msg }, i) => {
            const addSpace = i > 0 ? ' ' : '';
            errDesc += `${addSpace}"${title}" failed to upload: ${err_msg.reason}.`;
          });
          this.$store.commit('SET_MESSAGE', {
            name: 'Upload',
            details: errDesc,
            type: 'error',
          });
        }

        try {
          await onCompleteCb();
        } catch (e) {
          console.error(
            '[Upload Service] Callback provided to initUploadService failed. Throwing to consuming component.'
          );
          throw e;
        } finally {
          this.resetMediaUploadService();
        }
      }
    });

    this.lastUpdatedTimer = setInterval(() => {
      const now = new Date().getTime();
      this.lastUpdatedMediaUploads.forEach(({ uuid, lastUpdated }) => {
        const allotedTime = lastUpdated + MAX_MILLIS_SINCE_LAST_UPDATE;
        if (now > allotedTime) {
          const err_msg = {
            service: 'frontend',
            reason: 'stopped receiving updates from server',
          };
          const progressEvent = {
            uuid,
            failed: 1,
            err_msg,
          };
          this.$store.commit(`${MODULE_NAMESPACE}/UPDATE_MEDIA_UPLOAD`, progressEvent);
        }
      });
    }, 1000);
  }

  resetMediaUploadService() {
    try {
      this.unwatch();
    } catch {
      // Would only fail if unwatch was already reset. Ignore.
    }
    this.unwatch = null;
    clearInterval(this.lastUpdatedTimer);
    this.$store.commit(`${MODULE_NAMESPACE}/CLEAR_UPLOAD_TYPE`);
    this.$store.commit(`${MODULE_NAMESPACE}/CLEAR_ALL_MEDIA_UPLOADS`);
  }
}
</script>
