var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"new-show"},[_c('header',[(_vm.$route.params.showId === 'new')?_c('fm-text',{attrs:{"tag":"h1"}},[_vm._v("Create new show")]):_c('fm-text',{attrs:{"tag":"h1"}},[_vm._v("Edit Show")]),_c('div',[(_vm.previousRouteName)?_c('fm-button-secondary',{staticClass:"new-show__header-button",on:{"click":_vm.previous}},[_vm._v(" Previous step ")]):_vm._e(),(_vm.nextRouteName)?_c('fm-button-primary',{staticClass:"new-show__header-button",domProps:{"disabled":_vm.shouldPreventNext},on:{"click":_vm.next}},[_vm._v("Next step ")]):_c('fm-button-primary',{staticClass:"new-show__header-button",domProps:{"disabled":_vm.shouldPreventNext},on:{"click":function($event){return _vm.saveShow(false)}}},[_vm._v(" Save show ")])],1)],1),_c('div',[_c('fm-tabs',[_c('router-link',{attrs:{"to":"basic-info","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
return [_c('fm-tab-item',{attrs:{"href":href,"selected":_vm.currentStep === 1},on:{"click":navigate}},[_vm._v(" 1. Add basic info ")])]}}])}),_c('router-link',{attrs:{"to":"display-details","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
return [_c('fm-tab-item',{attrs:{"id":"tab-item","href":href,"selected":_vm.currentStep === 2,"disabled":_vm.maxStepCompleted < 1},on:{"click":navigate}},[_vm._v(" 2. Add display details ")])]}}])}),_c('router-link',{attrs:{"to":"publishing-destinations","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
return [_c('fm-tab-item',{attrs:{"href":href,"selected":_vm.currentStep === 3,"disabled":_vm.maxStepCompleted < 2},on:{"click":navigate}},[_vm._v(" 3. Select publishing destinations ")])]}}])})],1)],1),_c('main',[_c('router-view',{attrs:{"keep-alive":""},on:{"preventNext":_vm.preventNext,"allowNext":_vm.allowNext,"saveAndNav":_vm.onSaveAndNav}})],1),_c('footer',[(_vm.nextRouteName)?_c('fm-button-primary',{staticClass:"new-show__header-button",domProps:{"disabled":_vm.shouldPreventNext},on:{"click":_vm.next}},[_vm._v("Next step ")]):_c('fm-button-primary',{staticClass:"new-show__header-button",domProps:{"disabled":_vm.shouldPreventNext},on:{"click":function($event){return _vm.saveShow(false)}}},[_vm._v(" Save show ")]),(_vm.previousRouteName)?_c('fm-button-secondary',{staticClass:"new-show__header-button",on:{"click":_vm.previous}},[_vm._v(" Previous step ")]):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }