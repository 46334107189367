


























import Vue from 'vue';

export default Vue.extend({
  name: 'table-header-cell',
  props: {
    active: Boolean,
    maxWidth: { default: '100%', type: String },
    minWidth: { default: '', type: String },
    onClick: Function,
    qa: String,
    sortDirection: String,
    type: String,
    width: { default: '100%', type: String },
    disabled: { default: false, type: Boolean },
  },
  computed: {
    getSortChevron() {
      if (this.active && this.sortDirection === 'asc') {
        return `arrow_drop_up`;
      }
      return `arrow_drop_down`;
    },
    customStyling() {
      return [
        {
          'max-width': `${this.maxWidth}`,
          'min-width': `${this.minWidth}`,
          width: `${this.width}`,
          opacity: this.disabled ? 0.8 : 1,
        },
      ];
    },
  },
  methods: {},
});
