


















































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { Getter, namespace } from 'vuex-class';
import { Campaign } from '@/types/Campaign';
import { Brand } from '@/types/Brand';
import { VideoAsset } from '@/types/createandpublish/mediaLibraries';
import VideoCardLayout from '@/createandpublish/components/videoLibrary/VideoCardLayout.vue';
import CampaignCircle from '@/components/brands/CampaignCircle.vue';
import CampaignMenu from '@/components/brands/CampaignMenu.vue';
import { formatDateShortYear } from '@/createandpublish/core/filters';

const createAndPublishStore = namespace('CreateAndPublishStore');
const videoLibraryModule = namespace('CreateAndPublishStore/videoLibrary');

@Component({
  name: 'VideoCard',
  components: {
    VideoCardLayout,
    CampaignCircle,
    CampaignMenu,
  },
  filters: {
    formatDateShortYear,
  },
})
export default class VideoCard extends Vue {
  @Prop() readonly videoData!: VideoAsset;
  @Getter selectedBrand;
  @createAndPublishStore.Getter readonly timeZone!: string;
  @videoLibraryModule.Action deleteVideoAsset;

  campaignMenuValue: number[] = [];
  preventCampaignChangeEmit = false;
  @Watch('campaignMenuValue')
  onCampaignMenuValueChange(campaignIds: number[]) {
    if (this.preventCampaignChangeEmit) return;
    this.$emit('update-asset-campaigns', { id: this.videoData.id, campaignIds });
  }

  get videoCampaigns(): Campaign[] {
    return (this.selectedBrand as Brand)?.campaigns
      .filter((campaign) => campaign.active)
      .filter((campaign) => this.videoData.campaigns?.includes(campaign.id));
  }

  get actionBarProps() {
    return {
      firstAction: {
        useSlot: true,
      },
      secondAction: {
        icon: 'share',
        hoverText: 'Create social post',
        onClick: () => {
          alert('create social post not implemented');
        },
      },
      menuOptions: [
        {
          label: 'Edit video details',
          onClick: () => {
            this.$emit('open-video-drawer', this.videoData);
          },
        },
        {
          label: 'Delete video',
          onClick: async () => {
            await this.deleteVideoAsset(this.videoData.id);
          },
        },
      ],
    };
  }

  onAddNewCampaign() {
    this.$emit('add-new-campaign');
  }

  async created() {
    this.preventCampaignChangeEmit = true;
    this.campaignMenuValue = this.videoData.campaigns;
    await this.$nextTick();
    this.preventCampaignChangeEmit = false;
  }
}
