import store from '@/store';
import type { AuthenticatedUser } from '@/models/UserModel';
import type { Brand } from '@/types/Brand';
import { roleIdMap, statusIdMap } from '@/utils/thirdPartySupport';

const run = () => {
  const {
    id: userId,
    firstName,
    lastName,
    email,
    phone,
    roleId,
    statusId,
  } = store.getters['authenticatedUser'] as AuthenticatedUser;
  const { id: brandId } = store.getters['selectedBrand'] as Brand;

  const uid = `CC:${userId}:${email}`;
  const userVars = {
    firstName,
    lastName,
    email,
    phone,
    brandId,
    role: roleIdMap[roleId],
    status: statusIdMap[statusId],
  };
  window.FS.identify(uid, userVars);
};

export default run;
