






























import { Getter } from 'vuex-class';
import { Component, Vue, Watch } from 'vue-property-decorator';

import PortalHeader from '@/components/PortalHeader.vue';
import PortalSubHeader from '@/components/PortalSubHeader.vue';
import PortalLeftNav from '@/components/PortalLeftNav.vue';
import PortalFooter from '@/components/PortalFooter.vue';

import type { Brand } from '@/types/Brand';
import type { Route } from 'vue-router';

@Component({
  components: {
    PortalHeader,
    PortalSubHeader,
    PortalLeftNav,
    PortalFooter,
  },
  metaInfo: {
    title: 'Futuri Content Cloud',
    meta: [{ name: 'viewport', content: `width=${window.innerWidth < 1024 ? 1024 : window.innerWidth}` }],
  },
})
export default class App extends Vue {
  @Getter isLoggedIn!: boolean;
  @Getter showSidebar!: boolean;
  @Getter collapseSidebar!: boolean;
  @Getter selectedBrand!: Brand;
  @Getter('userBrands') brands!: Brand[];

  get showSubHeader(): boolean {
    const isInViableModule = ['create', 'discover', 'audience'].includes(this.$route.path.split('/')[1]);
    const hasMoreThanOneBrand = this.brands.length > 1;
    const needsAudienceDatePicker =
      this.isAudienceRoute && !this.$route.path.includes('leaderboard') && !this.$route.path.includes('community');

    if (needsAudienceDatePicker) {
      return true;
    }
    return isInViableModule && hasMoreThanOneBrand;
  }

  get subHeaderClasses() {
    return this.collapseSidebar ? 'main collapsed' : 'main';
  }

  get viewClasses() {
    return [
      'view',
      {
        'view--overflowed': this.showLeftNav,
        'collapse-subheader': !this.showSubHeader,
      },
    ];
  }

  get isAudienceRoute(): boolean {
    return this.$route.path.includes('audience');
  }

  get selectedBrandId() {
    return this.selectedBrand?.id || null;
  }

  get showLeftNav() {
    return ['create', 'discover', 'audience'].includes(this.$route.path.split('/')[1]);
  }

  routerViewKey = 0;

  @Watch('$route')
  onRouteChange(newRoute: Route, oldRoute: Route) {
    if (oldRoute.params.brandId != newRoute.params.brandId) {
      // Loose equality because sometimes it's a string and sometimes a number. Too lazy to find out what's doing that.
      this.routerViewKey++;
    }
  }
}
