


















import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({
  name: 'PinterestIcon',
})
export default class PinterestIcon extends Vue {
  @Prop({ type: String, required: false, default: '#FF5A44' }) fill!: string;
  @Prop({ type: Number, required: false, default: 41 }) height!: number;
  @Prop({ type: Number, required: false, default: 40 }) width!: number;
  @Prop({ type: String, required: false, default: 'Pinterest logo' }) title!: string;
}
