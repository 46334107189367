<template>
  <div class="wrapper">
    <div class="material-icons icon" aria-label="Show tags">local_offer</div>

    <div class="list-wrapper" ref="wrapper">
      <div v-if="isEmpty" class="no-tags">
        <router-link
          :to="`/create/${selectedBrand.id}/podcasts/shows/edit/${showId}/display-details?anchor=tags`"
          v-slot="{ href, navigate }"
          custom
        >
          <fm-link :href="href" external @click.stop="navigate"> Add tags </fm-link>
        </router-link>
      </div>

      <div v-else class="tag-list" ref="tagList" :style="{ minWidth: tagListWidth }">
        <template v-for="(tag, i) in tags">
          <router-link
            :to="`/create/${selectedBrand.id}/podcasts/shows/edit/${showId}/display-details?anchor=tags`"
            v-slot="{ href, navigate }"
            custom
            :key="i"
          >
            <fm-link
              class="link"
              :class="{ invisible: i > hideAfterIndex }"
              :aria-hidden="i > hideAfterIndex"
              ref="links"
              :href="href"
              external
              @click.stop="navigate"
            >
              {{ tag }}
            </fm-link>
          </router-link>
        </template>
      </div>

      <div v-if="!hideMoreText" class="more-text" ref="moreText">
        <fm-text v-if="hideAfterIndex >= 0" variant="disclaimer"> + {{ numLinksHidden }} more </fm-text>
        <fm-text v-else class="no-fit" variant="disclaimer"> {{ numLinksHidden }} tags </fm-text>
      </div>
    </div>
  </div>
</template>

<script>
import { debounce } from 'lodash';
import { mapGetters } from 'vuex';

export default {
  name: 'tag-list',

  props: {
    tags: { type: Array, default: () => [] },
    showId: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      totalPx: NaN,
      moreTextPx: NaN,
      numLinksHidden: 0,
      linksPx: [],
      resizeObserver: null,
    };
  },

  computed: {
    ...mapGetters(['selectedBrand']),
    isEmpty() {
      return this.tags.length === 0;
    },

    totalAvailableTagsPx() {
      if (isNaN(this.totalPx)) {
        return NaN;
      }
      if (this.hideMoreText) {
        return this.totalPx;
      }
      return this.totalPx - this.moreTextPx;
    },

    allTagsPx() {
      return this.linksPx.reduce((acc, cur) => acc + cur, 0);
    },

    hideMoreText() {
      if (this.isEmpty || isNaN(this.totalPx)) return true;
      return this.allTagsPx < this.totalPx;
    },

    hideAfterIndex() {
      return this.tags.length - 1 - this.numLinksHidden;
    },

    tagListWidth() {
      let total = 0;
      this.linksPx.forEach((px, i) => {
        if (i <= this.hideAfterIndex) {
          total += px;
        }
      });
      return `${total}px`;
    },
  },

  methods: {
    async init() {
      await window.customElements.whenDefined('fm-link');
      await window.customElements.whenDefined('fm-text');
      await this.$nextTick();
      if (!window.ResizeObserver) {
        // Support is mostly limited by Safari, which started at 13.1
        // Since this looks bad w/o support, we just set totalPx to 0
        // so it just displays the num of tags.
        this.totalPx = 0;
        this.numLinksHidden = this.tags.length;
        return;
      }
      await this.handleResizeEvent();
      this.debouncedHandleResize = debounce(this.handleResizeEvent, 30);
      this.resizeObserver = new ResizeObserver(this.debouncedHandleResize);
      this.resizeObserver.observe(this.$refs.wrapper);
    },

    async handleResizeEvent() {
      if (!this.$refs.links || !this.$refs.links.length) return;
      // Resized, so reset tracked values
      this.linksPx = [];
      this.$refs.links.forEach((el) => {
        this.linksPx.push(el.clientWidth);
      });
      this.totalPx = this.$refs.wrapper.clientWidth;
      await this.$nextTick();
      if (!this.hideMoreText) {
        this.moreTextPx = this.$refs.moreText.clientWidth;
        await this.$nextTick();
      }

      // Get list of hidden items
      const hiddenLinks = this.$refs.links.filter((el) => {
        return el.offsetLeft + el.offsetWidth > this.$refs.wrapper.offsetLeft + this.totalAvailableTagsPx;
      });

      // Set reactive properties
      this.numLinksHidden = hiddenLinks.length;
    },
  },

  watch: {
    async tags() {
      await this.init();
    },
  },

  async mounted() {
    await this.init();
  },

  beforeDestroy() {
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }
  },
};
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
}

.wrapper {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  width: 100%;
  min-width: 0;
  overflow: hidden;

  .icon {
    color: var(--neutral-grey-2-lighter-text);
    user-select: none;
    width: 36px;
    height: 24px;
    margin-left: 0;
    flex-shrink: 0;
  }

  .list-wrapper {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    width: calc(100% - 36px);
    min-width: 0;

    .tag-list {
      display: flex;
      flex-wrap: nowrap;
      overflow: hidden;
      flex-basis: 0;

      .link {
        padding-right: 8px;

        &::part(anchor) {
          white-space: nowrap;
        }

        &.invisible,
        &.invisible::part(anchor) {
          visibility: hidden;
          opacity: 0;
        }
      }
    }

    .more-text {
      display: flex;
      align-items: center;
      flex: 1 0 1;

      fm-text::part(tag) {
        white-space: nowrap;
      }

      .no-fit {
        min-width: 65px;
      }
    }
  }
}
</style>
