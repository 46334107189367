import SocialStoryService from '@/services/CreateAndPublishSocialStoriesService';
import { handleAjax } from '@/createandpublish/core/utils/handleAjax';

import type { Module } from 'vuex';
import type { RootState } from '@/types/store';
import type { SocialStoryDetails as SocialStory } from 'content-cloud-types/dist/types/createandpublish/SqlModelInterfaces';

const StoryService = new SocialStoryService();

const socialStoryModule: Module<SocialStoriesState, RootState> = {
  namespaced: true,

  state: {
    stories: [],
  },

  getters: {
    stories(state) {
      return state.stories;
    },
  },

  mutations: {
    SET_STORIES(state, stories: SocialStory[]) {
      state.stories = stories;
    },

    CLEAR_STORIES(state) {
      state.stories = [];
    },
  },

  actions: {
    getStories({ dispatch, commit }) {
      return handleAjax({
        request: StoryService.getStories(),
        dispatch,
        commit,
        mutation: 'SET_STORIES',
        callback(err) {
          if (err) {
            throw err;
          }
        },
      });
    },
  },
};

export default socialStoryModule;
export interface SocialStoriesState {
  stories: SocialStory[];
}
