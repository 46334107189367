import HttpClient from '@/services/common/HttpClient';
import DiscoverIdeaStarterModel from '@/models/DiscoverIdeaStarterModel';
import DiscoverIdeaStarterByIdModel from '@/models/DiscoverIdeaStarterByIdModel';
import DiscoverTagStoriesModel from '@/models/DiscoverTagStoriesModel';

export default class DiscoverStoryService {
  private http: HttpClient = HttpClient.getInstance();
  private endpoint = `${process.env.VUE_APP_API_URL}/discover`;

  /*
   * Starter Ideas
   */
  public async getAll(brandId: number): Promise<DiscoverIdeaStarterModel> {
    return this.http.get<DiscoverIdeaStarterModel>(`${this.endpoint}/ideas?limit=20&brandId=${brandId}`);
  }

  /*
   * Starter Idea by Id
   */
  public async getOne(getStoryId: number, brandId: number): Promise<DiscoverIdeaStarterByIdModel> {
    return this.http.get<DiscoverIdeaStarterByIdModel>(`${this.endpoint}/ideas/${getStoryId}?brandId=${brandId}`);
  }

  public async tagStoriesToCampaign(
    brandId: number,
    campaign: number,
    storyId: number
  ): Promise<DiscoverTagStoriesModel> {
    return this.http.post<DiscoverTagStoriesModel>(
      `${process.env.VUE_APP_API_URL}/brands/${brandId}/campaigns/${campaign}/ideas/${storyId}`,
      ''
    );
  }

  public async untagCampaignFromStory(
    brandId: number,
    campaign: number,
    storyId: number
  ): Promise<DiscoverTagStoriesModel> {
    return this.http.delete<DiscoverTagStoriesModel>(
      `${process.env.VUE_APP_API_URL}/brands/${brandId}/campaigns/${campaign}/ideas/${storyId}`
    );
  }
}
