




























import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import BlankCard from '@/components/common/cards/BlankCard.vue';
import CreditPaymentCardListItem from '@/components/subscriptions/CreditPaymentCardListItem.vue';
import type { AlertDisplay } from '@/types/AlertMessage';
import type { PaymentProfile } from '@/types/ecomm';

const EcommStore = namespace('EcommStore');

@Component({
  name: 'CreditPaymentCard',
  components: {
    BlankCard,
    CreditPaymentCardListItem,
  },
})
export default class CreditPaymentCard extends Vue {
  @EcommStore.Getter paymentProfiles!: PaymentProfile[];

  onAlertUpdate(alert: AlertDisplay) {
    this.$emit('onAlertUpdate', alert);
  }
}
