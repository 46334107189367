



































































































































































































































































































































































import { Component, Vue } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';

// import Pageable from '../services/common/Pageable';
import AddEditUserDrawer from '../components/users/AddEditUserDrawer.vue';
import AllUsers from '../components/users/AllUsers.vue';
import BoilTable from '../components/common/table/Table.vue';
import Breadcrumb from '../components/common/navigation/breadcrumb/Breadcrumb.vue';
import Pagination from '@/components/common/table/Pagination.vue';
import SearchBar from '@/components/common/form/SearchBar.vue';
import Select from '@/components/common/form/Select.vue';
import TableCell from '@/components/common/table/TableCell.vue';
import TableHeaderCell from '@/components/common/table/TableHeaderCell.vue';
import TableInfo from '../components/common/table/TableInfo.vue';
import UserCard from '../components/users/UserCard.vue';
import UserModel from '@/models/UserModel';
import ViewTitle from '../components/common/view/viewTitle/ViewTitle.vue';
import { AccountState } from '@/types/account/AccountState';
import { Brand } from '@/types/Brand';
import { Product } from '@/types/Product';
import Button from '@/components/common/buttons/Button.vue';
import { maybePluralize } from '@/utils';
import { UserStatusId } from '@/constants/enums/userStatuses';
import SeatInfo from '@/components/users/SeatInfo.vue';
import SeatInfoViewTitle from '@/components/users/SeatInfoViewTitle.vue';
import UserProducts from '@/components/users/manageUsersTable/UserProducts.vue';
import { Feature } from '@/types/features/FEFeature';

@Component({
  components: {
    AddEditUserDrawer,
    AllUsers,
    BoilTable,
    Breadcrumb,
    Button,
    Pagination,
    SearchBar,
    SeatInfo,
    SeatInfoViewTitle,
    Select,
    TableCell,
    TableHeaderCell,
    TableInfo,
    UserCard,
    UserProducts,
    ViewTitle,
  },
})
export default class Users extends Vue {
  @Getter allBrands!: Brand[];
  @Getter allUsers!: UserModel[];
  @Getter allUsersSelected!: boolean;
  @Getter features!: Feature[];
  @Getter getAccount!: AccountState;
  @Getter isUserMultiBrand!: boolean;
  @Getter selectedUsers!: boolean;
  @Getter sortedPriorityAccountProducts;
  @Getter userProducts!: Product[];
  @Getter windowWidthXS!: boolean;

  @Action createUser;
  @Action editUser;
  @Action fetchUsers;
  @Action toggleAllSelectedUsers;
  @Action toggleSelectedUser;

  // Breadcrumb items
  breadcrumbItems = [
    { text: 'Account Overview', to: { name: 'Dashboard' } },
    { text: 'Manage users', active: true },
  ];

  // Searchbar
  searchBarValue = '';
  searchFilterValue = '';

  // Drawer state
  userDrawer = {
    userData: {
      brands: [{ id: 0, name: 'All brands' }],
      email: '',
      firstName: '',
      id: 0,
      lastName: '',
      products: [],
      roleId: 2,
      statusId: 1,
    },
  };
  currentFilters = {
    status: 'all',
    brand: 'all',
    app: 'all',
  };
  menuOptions = [
    {
      label: 'Edit User',
      onClick: () => {
        console.log('Edit User menuOption selected');
      },
    },
    {
      label: 'Disable User',
      onClick: () => {
        console.log('Disable User menuOption selected');
      },
    },
    {
      label: 'Resend invitation email',
      onClick: () => {
        console.log('Resend invitation emai menuOption selected');
      },
    },
    {
      label: 'Send password reset link',
      onClick: () => {
        console.log('Send password reset link menuOption selected');
      },
    },
    {
      label: 'View Content Cloud as user',
      onClick: () => {
        console.log('View Content Cloud as user menuOption selected');
      },
    },
  ];
  // role & status options will become a fetch request onm ount
  roleOptions = [
    // {
    //   id: 1,
    //   value: 'SUPERADMIN',
    // },
    {
      id: 2,
      value: 'ADMIN',
    },
    {
      id: 3,
      value: 'USER',
    },
  ];

  statusOptions = [
    {
      id: 1,
      value: 'ACTIVE',
    },
    {
      id: 2,
      value: 'DISABLED',
    },
  ];

  confirmationType = 'deactivate';
  showConfirmationModal = false;

  currentPageNum = 1;
  pageSize = 6;

  offset = 0;

  userNav = 'allUsers';
  showSortFilter = false;
  selectAllCb = false;
  pageSizeOptions = [
    {
      size: 6,
    },
    {
      size: 12,
    },
    {
      size: 18,
    },
  ];

  get selectOptions() {
    return {
      app: [
        {
          label: 'All apps',
          value: 'all',
        },
        ...this.sortedPriorityAccountProducts?.map((product) => {
          return {
            label: product.name,
            value: product.id.toString(),
          };
        }),
      ],

      status: [
        {
          label: 'All statuses',
          value: 'all',
        },
        {
          label: 'Active',
          value: UserStatusId['Active'],
        },
        {
          label: 'Disabled',
          value: UserStatusId['Disabled'],
        },
      ],

      sort: [
        {
          label: 'Name, A-Z',
          value: 'name,asc',
        },
        {
          label: 'Name, Z-A',
          value: 'name,des',
        },
      ],
    };
  }

  get selectBrandOptions() {
    const activeBrands = this.allBrands.filter((brand) => brand.active);
    const brandOptions = activeBrands.map((brand) => {
      return {
        label: brand.name,
        value: brand.id.toString(),
      };
    });

    // add an all option to the array
    brandOptions.unshift({
      label: 'All brands',
      value: 'all',
    });

    return brandOptions;
  }

  // Computed value for number of users
  get numberOfUsers() {
    return maybePluralize(this.filteredUsers?.length || 0, 'User', 's');
  }

  get sortedUsers() {
    return this.filteredUsers.sort((a, b) =>
      a[this.sortBy] < b[this.sortBy] ? (this.sortDirection === 'asc' ? -1 : 1) : this.sortDirection === 'asc' ? 1 : -1
    );
  }

  get filteredUsers() {
    const filterByStatus = (users) => {
      if (this.currentFilters.status === 'all') {
        return users;
      } else {
        return users.filter((user) => {
          return user.statusId === parseInt(this.currentFilters.status);
        });
      }
    };

    const filterByAppAccess = (users) => {
      if (this.currentFilters.app === 'all') {
        return users;
      } else {
        return users.filter((user) => {
          return user.products.map((product) => product.id).includes(parseInt(this.currentFilters.app));
        });
      }
    };

    const filterByBrand = (users) => {
      if (this.currentFilters.brand === 'all') {
        return users;
      } else {
        return users.filter((user) => {
          return user.brands.map((brand) => brand.id).includes(parseInt(this.currentFilters.brand));
        });
      }
    };

    const filterBySearch = (users) => {
      if (this.searchFilterValue) {
        return users?.filter((user) => {
          return [user.email, user.firstName, user.lastName].some((e) =>
            e?.toLowerCase().includes(this.searchFilterValue?.toLowerCase())
          );
        });
      }
      return users;
    };

    return filterBySearch(filterByBrand(filterByAppAccess(filterByStatus(this.allUsers))));
  }

  get currentUsersToDisplay() {
    const _from = this.getPaginationFrom(this.currentPageNum, this.pageSize, this.numberOfUsers) - 1;
    const paginatedUsers = this.filteredUsers.slice(_from, _from + this.pageSize);
    return paginatedUsers;
  }

  // For pagination
  get totalUsers() {
    return this.filteredUsers?.length || 0;
  }

  get totalUsersAfterFilter() {
    return this.filteredUsers.length;
  }

  // Initial fetch call
  // Called before entering route
  // loadUsers() {
  //   const page = new Pageable(this.sortBy, this.sortDirection, this.currentPageNum + 1, this.pageSize, this.offset); // This currentPage +1 hack is because the current test api's first page is 1 instead of 0
  //   this.fetchUsers(page);
  // }

  // Needs to be a global utility function - GK
  toProperCase(txt) {
    return txt?.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }

  getUserRole(id) {
    const value = this.roleOptions.find((role) => role.id === id)?.value;
    return this.toProperCase(value);
  }

  getUserStatus(id) {
    const value = this.statusOptions.find((status) => status.id === id)?.value;
    return this.toProperCase(value);
  }

  showConfirmation(type: string): void {
    if (type === 'delete') {
      this.confirmationType = 'delete';
    } else {
      this.confirmationType = 'deactivate';
    }

    this.showConfirmationModal = true;
  }

  getModalPrimaryButtonText() {
    return 'Yes, ' + (this.confirmationType === 'delete' ? 'delete' : 'deactivate') + ' this user';
  }

  deleteOrDeactivateUser() {
    this.toggleAllSelectedUsers();
    this.showConfirmationModal = false;
    this.fetchUsers();
  }

  // On upgrade CTA
  onUpgrade(): void {
    const ecommFeatureIsEnabled = this.features.includes('ecomm');
    if (ecommFeatureIsEnabled) {
      this.$router.push('/subscriptions');
    } else {
      window.open('https://content.futuricontentcloud.com/contact', '_blank');
    }
  }

  // Pagination
  updatePagination(newPage) {
    this.currentPageNum = newPage;
  }

  updatePageSize(itemsPerPage) {
    this.pageSize = parseInt(itemsPerPage.target.value);
  }

  getPaginationFrom(currentPage, pageSize, totalElements) {
    if (currentPage === 1) {
      if (totalElements === 0) return 0;
      return 1;
    }
    return (currentPage - 1) * pageSize + 1;
  }
  // Filter
  handleFilterUpdate(type, response) {
    this.updatePagination(1);
    this.currentFilters[type] = response.target.value;
  }

  // searchbar
  handleSearch() {
    this.updatePagination(1);
    this.searchFilterValue = this.searchBarValue;
  }

  clearSearch() {
    this.searchBarValue = '';
    this.searchFilterValue = '';
  }

  sortBy = 'lastName';
  sortDirection = 'asc';

  // Sort
  sortTable(fieldName: string) {
    if (this.sortBy === fieldName) {
      this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    } else {
      this.sortBy = fieldName;
      this.sortDirection = 'asc';
    }

    if (process.env.NODE_ENV === 'development') {
      console.log('sort table by:', fieldName, 'direction:', this.sortDirection);
    }
  }

  // Add/Edit User drawer methods
  handleUserDrawerCancel() {
    this.userDrawer.userData = {
      brands: [],
      email: '',
      firstName: '',
      id: 0,
      lastName: '',
      products: [],
      roleId: 2,
      statusId: 1,
    };
  }

  openUserDrawer(userRowItem) {
    // Initial data
    // Pull this out into 1 reusable constant
    const dataObj = {
      brands: [{ id: 0, name: 'All brands' }],
      email: '',
      firstName: '',
      id: 0,
      lastName: '',
      products: [],
      roleId: 2,
      statusId: 1,
    };

    // If editing user
    if (userRowItem) {
      dataObj.brands = userRowItem.brands;
      dataObj.email = userRowItem.email;
      dataObj.firstName = userRowItem.firstName;
      dataObj.id = userRowItem.id;
      dataObj.lastName = userRowItem.lastName;
      dataObj.products = userRowItem.products;
      dataObj.roleId = userRowItem.roleId;
      dataObj.statusId = userRowItem.statusId;
    }

    this.userDrawer.userData = dataObj;
  }

  // Get brands cell berviage when collapsed
  getCollapsedBrandsVerbiage(numberOfBrands = 0) {
    const _isPlural = numberOfBrands !== 1;
    return `${numberOfBrands} brand${_isPlural ? 's' : ''}`;
  }

  // Return brand names for expanded cell content
  getBrandNames(brands: Brand[]) {
    return brands.map((brand) => brand.name);
  }
}
