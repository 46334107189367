import DiscoverCategoriesModel from '@/models/DiscoverCategoriesModel';
import DiscoverCategoryService from '@/services/DiscoverCategoryService';
import DiscoveryCollapsibleSidebar from '@/models/DiscoverCollapsibleSidebarModel';
import { DiscoverState } from '../../types/discover/DiscoverState';
import { DiscoverData } from '@/types/discover/DiscoverData';
import type { ActionTree, GetterTree, Module } from 'vuex';
import type { RootState } from '@/types/store';
import DiscoverStoryService from '@/services/DiscoverStoryService';
import DiscoveryStorySearch from '@/models/DiscoverStorySearchModel';
import DiscoerShortcutModel from '@/models/DiscoverShortcutModel';
import { isNotNullOrUndefined, isValidErrorFormat } from '@/utils/typeGuards';
import DiscoverStoryManager, {
  DiscoverStoryFeedType,
  DiscoverStoryManagerData,
  DiscoverStoryManagerUpdateEventData,
  DiscoverStorySearchableFilters,
} from '@/services/DiscoverStoryManager';

export const initialState = {
  data: null,
  welcomeBannerActive: true,
  categories: undefined,
  category: undefined,
  shortcuts: [],
  trendingStories: [],
  updatedStories: [],

  storyManagerUpdateEventData: { latestTimestamp: -Infinity, shortcutIds: [] },
};

const state: DiscoverState = initialState;

const getters = {
  allCategories: (state) => state.categories,
  isWelcomeBannerActive: (state) => state.welcomeBannerActive,
  trendingStories: (state) => state.trendingStories,
  updatedStories: (state) => state.updatedStories,
  shortcuts: (state) => state.shortcuts,
  category: (state) => state.category,
  searchStories: (state) => (term) => {
    return state.trendingStories.filter((story) => story.title.startsWith(term));
  },

  storyManagerHasData: (state: DiscoverState) => () => {
    const data = state.storyManagerData;

    console.info(`DiscoverStore: storyManagerHasData: data: `, data);

    const res = !!data?.searchEngine && !!data?.storyMap;

    console.info(`DiscoverStore: storyManagerHasData: res: `, res);

    return res;
  },

  storyManagerHasShortcutId: (state: DiscoverState) => (shortcutId: number) => {
    console.info(`DiscoverStore: storyManagerHasShortcutId: shortcutId: `, shortcutId);

    const data = state.storyManagerData;
    console.info(`DiscoverStore: storyManagerHasShortcutId: data: `, data);

    const res = DiscoverStoryManager.hasShortcutId(data, shortcutId);
    console.info(`DiscoverStore: storyManagerHasShortcutId: res: `, res);

    return res;
  },

  // TODO: implement autoSuggest the same way, if we gain an autocomplete dropdown

  storyManagerSearch: (state: DiscoverState) => async (query: string) => {
    const data = state.storyManagerData;
    if (!data?.searchEngine || !data?.storyMap) {
      return [];
    }

    const results = await DiscoverStoryManager.searchStories(data, query);

    return results;
  },

  /// Filter results from the story manager, allows fast switching of selected category
  storyManagerFilterStories:
    (state: DiscoverState) => async (feedType: DiscoverStoryFeedType, filters?: DiscoverStorySearchableFilters) => {
      const data = state.storyManagerData;
      console.info(`DiscoverStore: storyManagerFilterStories: data: `, data);

      if (!data?.searchEngine || !data?.storyMap) {
        return [];
      }

      console.info(`DiscoverStore: storyManagerFilterStories: filters: `, filters);

      const results = DiscoverStoryManager.filterStories(data, feedType, filters);

      return results;
    },

  /// This getter is used to watch changes to the story manager, it will be updated if new stories are added for any shortcut
  storyManagerUpdateEventData: (state: DiscoverState) => state.storyManagerUpdateEventData,
} as GetterTree<DiscoverState, RootState>;

const actions = {
  async fetchStories({ state, commit, dispatch, rootState }) {
    if (!state.category) return;
    const response = await new DiscoverStoryService().getStories(
      state.category,
      rootState.BrandStore.selectedBrand.id as number
    );

    // const stories = isError(response) ? [] : response;

    // This handles the type a little better it seems
    const stories = Array.isArray(response) ? response : [];

    commit('setTrendingStories', stories);

    // if (stories?.length) {
    //   await dispatch('updateTrendingStoriesSearchIndex', stories);
    // }

    if (stories?.length) {
      setTimeout(async () => {
        const shortcutId = state.category;

        const primaryShortcut = state.shortcuts?.find((s) => s.id == shortcutId);
        const shortcuts = primaryShortcut ? [primaryShortcut] : undefined;

        await DiscoverStoryManager.loadStories(
          state.storyManagerData,
          stories,
          DiscoverStoryFeedType.trending,
          {
            shortcuts,
            primaryShortcut,
          },
          async (data: DiscoverStoryManagerData) => {
            commit('setDiscoverStoryManagerData', data);
          }
        );
      }, 0);
    }
  },
  async fetchUpdates({ state, commit, rootState }) {
    if (!state.category) return;
    const response = await new DiscoverStoryService().getStories(
      state.category,
      rootState.BrandStore.selectedBrand.id as number
    );
    console.log(response, 'UPDaTES');
    if (isError(response)) commit('setUpdatedStories', []);
    else commit('setUpdatedStories', response);

    // Update story manager with the new stories

    const stories = Array.isArray(response) ? response : [];

    console.info(`DiscoverStore: fetchUpdates: stories: `, stories);

    if (stories?.length) {
      setTimeout(async () => {
        const shortcutId = state.category;

        const primaryShortcut = state.shortcuts?.find((s) => s.id == shortcutId);
        const shortcuts = primaryShortcut ? [primaryShortcut] : undefined;

        await DiscoverStoryManager.loadStories(
          state.storyManagerData,
          stories,
          DiscoverStoryFeedType.trending,
          {
            shortcuts,
            primaryShortcut,
          },
          async (data: DiscoverStoryManagerData) => {
            commit('setDiscoverStoryManagerData', data);
          }
        );
      }, 0);
    }
  },
  applyUpdatedStories({ state, commit }) {
    commit('setTrendingStories', state.updatedStories);
    commit('setUpdatedStories', []);
  },
  async searchStories({ state, commit, rootState }, term) {
    console.log(term, 'searchStories');
    if (!state.category) return;
    const response = await new DiscoverStoryService().getSearch(
      term,
      state.category,
      rootState.BrandStore.selectedBrand.id as number
    );
    commit('setTrendingStories', response);
  },
  async fetchShortcuts({ state, commit, rootState }) {
    const response = await new DiscoverCategoryService().getShortcuts(rootState.BrandStore.selectedBrand.id as number);
    commit('setShortcuts', response);
    if (!state.category) {
      commit('setCategory', response[0].id);
    }
  },
  setCategory({ commit }, id) {
    commit('setCategory', id);
  },
  //don't know the mechanism for determining when a user will no longer get
  //the welcome banner, so I'm setting this to true by default for now
  toggleWelcomeBanner({ commit }, activeState) {
    commit('setWelcomeBannerActive', activeState);
  },
  toggleTrendingActive({ commit }, isActive) {
    commit('setTrendingActive', isActive);
  },
  toggleFollowingActive({ commit }, isActive) {
    commit('setFollowingActive', isActive);
  },
  toggleSettingsActive({ commit }, isActive) {
    commit('setSettingsActive', isActive);
  },
  toggleSidebarStatus({ commit }, sidebarData) {
    commit('setSidebarStatus', sidebarData);
  },

  setDiscoverStoryManagerData({ commit }, data: DiscoverStoryManagerData) {
    commit('setDiscoverStoryManagerData', data);
  },

  async setDiscoverStoryManagerUpdateEventData({ state, commit }, updateEvent: DiscoverStoryManagerUpdateEventData) {
    const previousTimestamp = state.storyManagerUpdateEventData?.latestTimestamp ?? -Infinity;
    console.info(`DiscoverStory: setStoryManagerUpdateEventData: previousTimestamp: `, previousTimestamp);

    const { latestTimestamp, shortcutIds } = updateEvent;
    console.info(`DiscoverStory: setStoryManagerUpdateEventData: latestTimestamp: `, latestTimestamp);

    // TODO: check that we have shortcutIds provided

    if (previousTimestamp !== -Infinity && latestTimestamp > previousTimestamp) {
      commit('setStoryManagerUpdateEventData', updateEvent);
    }
  },
} as ActionTree<DiscoverState, RootState>;

const mutations = {
  setCategories: (state, categories: DiscoverCategoriesModel) => (state.categories = categories),
  setCategory: (state, category: number) => (state.category = category),
  setSidebarStatus: (state, sidebarClosedProp: DiscoveryCollapsibleSidebar) =>
    (state.sidebarClosedProp = sidebarClosedProp),
  setTrendingActive: (state, trendingActive: DiscoverData) => (state.trendingActive = trendingActive),
  setFollowingActive: (state, followingActive: DiscoverData) => (state.followingActive = followingActive),
  setSettingsActive: (state, settingsActive: DiscoverData) => (state.settingsActive = settingsActive),
  setShortcuts: (state, shortcuts: DiscoerShortcutModel[]) => (state.shortcuts = shortcuts),
  setTrendingStories: (state, stories: DiscoveryStorySearch[]) => (state.trendingStories = stories),
  setUpdatedStories: (state, updatedStories: DiscoveryStorySearch[]) => (state.updatedStories = updatedStories),
  setWelcomeBannerActive: (state, welcomeBannerActive: DiscoverData) =>
    (state.welcomeBannerActive = welcomeBannerActive),

  // These two implement story manager interation with the store
  setDiscoverStoryManagerData: (state: DiscoverState, data: DiscoverStoryManagerData) =>
    (state.storyManagerData = data),
  setStoryManagerUpdateEventData: (state: DiscoverState, updateData: DiscoverStoryManagerUpdateEventData) =>
    (state.storyManagerUpdateEventData = updateData),
};

const module: Module<DiscoverState, RootState> = {
  state,
  getters,
  mutations,
  actions,
};

const moduleName = 'discover';

export default module;

export { moduleName };

function isError(response): response is Error {
  if (response instanceof Error) return true;
  else return false;
}

interface Error {
  errors: string[];
}
