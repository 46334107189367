<template>
  <fm-modal class="download-notice-modal" :visible="true">
    <template>
      <header slot="controlRow" class="header">
        <fm-text tag="h1" as="h3"> Download audio </fm-text>
        <close-button class="header__close-button" @click="close" />
      </header>
    </template>

    <template>
      <section slot="content" class="content">
        <fm-text>
          Download will start momentarily. Please note, large files may take several minutes to complete.
        </fm-text>
      </section>
    </template>

    <template>
      <section slot="actionRow" class="actions">
        <fm-button-primary @click="close"> Close </fm-button-primary>
      </section>
    </template>
  </fm-modal>
</template>

<script>
import CloseButton from '@/createandpublish/components/CloseButton';

export default {
  name: 'download-notice-modal',

  methods: {
    close() {
      this.$emit('on:cancel');
    },
  },

  components: {
    CloseButton,
  },
};
</script>

<style lang="scss" scoped>
.download-notice-modal {
  &::part(modal) {
    max-width: 500px;
  }

  .header {
    position: relative;
    margin-bottom: 36px;

    &__close-button {
      position: absolute;
      top: 0;
      right: 0;
    }
  }

  .content {
    margin-bottom: 36px;
  }

  .actions {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
