<template>
  <transition name="app-fade" mode="out-in">
    <router-view keep-alive></router-view>
  </transition>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
