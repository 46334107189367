import SocialStoriesRoute from '@/createandpublish/views/social-stories/SocialStoriesRoute.vue';
import Library from '@/createandpublish/views/social-stories/Library.vue';

import { requiresBrandAccess, requiresProductAccess } from '@/constants/router/permissions';

import type { RouteConfig } from 'vue-router';

export default {
  path: 'stories',
  component: SocialStoriesRoute,
  redirect: 'stories/library',
  meta: {
    ...requiresBrandAccess,
    ...requiresProductAccess,
  },
  children: [
    {
      path: 'library',
      name: 'SocialStories.Library',
      component: Library,
      meta: {
        sidebar_active_item: 'Social stories',
        title: 'Social Stories | Create&Publish',
      },
    },
  ],
} as RouteConfig;
