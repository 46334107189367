import { AccountData } from '@/types/account/AccountData';
import { AccountState } from '@/types/account/AccountState';
import AccountService from '@/services/AccountService';
import type { Product } from '@/types/Product';
import type { ProductSeats } from '@/types/account/AccountData';
import { ProductHandle, ProductKey } from '@/types/ecomm';
import { ProductIds } from '@/types/account/AccountData';
import type { Module, GetterTree } from 'vuex';
import { getPrioritySortedProducts } from '@/utils/products';
import { removeDisabledProducts } from '@/utils/products/removeDisabledProducts';
import { SubscriptionData } from '@/utils/ecomm';
import type { RootState } from '@/types/store';

export const initialState: AccountState = {
  data: null,
  error: null,
  isLoading: false,
};

const mutationNames = {
  setData: 'setData',
  setIsLoading: 'setIsLoading',
  setError: 'setError',
  setDefaultPaymentProfile: 'setDefaultPaymentProfile',
};

const mutations = {
  [mutationNames.setError]: (state: AccountState, error: string | null) => (state.error = error),
  [mutationNames.setIsLoading]: (state: AccountState, isLoading: boolean) => (state.isLoading = isLoading),
  [mutationNames.setData]: (state: AccountState, data: AccountData | null) => {
    state.data = data;
  },
  [mutationNames.setDefaultPaymentProfile]: (state: AccountState, { paymentProfileId }) => {
    if (!state.data) return;
    state.data.subscription.defaultPaymentProfileId = paymentProfileId;
  },
};

const isMultiBrand = (maxBrands: number): boolean => {
  return maxBrands !== 1;
};

const actions = {
  async fetchAccount({ commit, rootGetters }) {
    commit(mutationNames.setIsLoading, true);
    commit(mutationNames.setError, null);
    try {
      const res = await new AccountService().getAccount();

      commit(mutationNames.setIsLoading, false);
      commit(mutationNames.setData, {
        ...res,
        products: removeDisabledProducts(res.products, rootGetters.features),
      });
    } catch (e) {
      commit(mutationNames.setIsLoading, false);
      if (e instanceof Error) {
        commit(mutationNames.setError, e.toString());
      }
    }
  },
};

const getters = {
  // Subscription related
  isTrial: ({ data }) => ['trialing', 'trial_ended'].includes(data?.subscriptionStatus || ''),
  subscriptionStatus: ({ data }) => data?.subscriptionStatus,
  trialEndedAt: ({ data }) => data?.trialEndedAt,
  trialHasExpired: ({ data }) => ['trial_ended'].includes(data?.subscriptionStatus || ''),
  trialStartedAt: ({ data }) => data?.trialStartedAt,
  currentPeriodEndsAt: ({ data }) => data?.currentPeriodEndsAt,
  subscriptionBillingInterval: ({ data }) => data?.subscription?.intervalUnit as 'day' | 'month' | 'year',
  maxBrands: ({ data }) => data?.maxBrands ?? 1,
  rawSubscription: ({ data }) => data?.subscription,
  subscriptionId: ({ data }) => data?.subscription.subscriptionId,
  subscriptionProductHandle: ({ data }) => data?.subscription.productHandle ?? ProductHandle.AUDIENCE,
  subscriptionProductKey: (_, getters): ProductKey => {
    switch (getters.subscriptionProductHandle) {
      case ProductHandle.DISCOVER_MONTHLY:
        return ProductKey.DISCOVER;
      case ProductHandle.CREATE_MONTHLY:
        return ProductKey.CREATE_AND_PUBLISH;
      case ProductHandle.SUITE_MONTHLY:
        return ProductKey.SUITE;
      case ProductHandle.FULL_SUITE_FREE_TRIAL:
        return ProductKey.SUITE_FREE_TRIAL;
      case ProductHandle.AUDIENCE:
      default:
        return ProductKey.AUDIENCE;
    }
  },
  currentSubscriptionData: (state, getters): SubscriptionData | null => {
    if (!state.data) return null;

    const audienceSeatQuantity = (getters.productSeats[ProductIds.AUDIENCE] as ProductSeats)?.max ?? 0;
    const discoverSeatQuantity = (getters.productSeats[ProductIds.DISCOVER] as ProductSeats)?.max ?? 0;
    const createSeatQuantity = (getters.productSeats[ProductIds.CREATE_AND_PUBLISH] as ProductSeats)?.max ?? 0;
    const brandsManagedCount = getters.maxBrands as number;

    const hasDiscover = [ProductKey.DISCOVER, ProductKey.SUITE, ProductKey.SUITE_FREE_TRIAL].includes(
      getters.subscriptionProductKey
    );
    const hasCreate = [ProductKey.CREATE_AND_PUBLISH, ProductKey.SUITE].includes(getters.subscriptionProductKey);

    const { subscriptionProductHandle: productHandle, subscriptionProductKey: productKey } = getters;

    return {
      productKey: productKey,
      productHandle: productHandle,
      audienceSeatQuantity: audienceSeatQuantity || 1,
      discoverSeatQuantity: discoverSeatQuantity || 0,
      createSeatQuantity: createSeatQuantity || 0,
      audienceBrandQuantity: audienceSeatQuantity ? brandsManagedCount : 1,
      discoverBrandQuantity: hasDiscover ? brandsManagedCount : 0,
      createBrandQuantity: hasCreate ? brandsManagedCount : 0,
    };
  },
  // End Subscription related
  error: ({ error }) => error,
  getAccount: (state) => state,
  // Account products
  accountProducts: ({ data }) => data?.products || [],
  // Account products sorted by priority
  sortedPriorityAccountProducts: ({ data }) => getPrioritySortedProducts(data?.products || []),
  sortedPriorityAccountProductsAll: ({ data }) =>
    getPrioritySortedProducts(data?.products || [], data?.allProducts || []),
  isLoading: ({ isLoading }) => isLoading,
  isUserMultiBrand: ({ data }) => (data ? isMultiBrand(data.maxBrands) : undefined),
  userProductName: (state) => (id: Product['id']) => {
    const products = state.data?.products ?? [];
    if (products?.length > 0) {
      return products.find((product) => product.id === id)?.name || 'NA';
    }
    return 'NA';
  },
  productSeats: (state): Record<Product['id'], ProductSeats> => {
    const entries =
      state.data?.products?.map((product: Product) => {
        return [
          product.id,
          {
            available: product.maxSeats - product.currentSeats,
            filled: product.currentSeats,
            max: product.maxSeats,
          },
        ];
      }) ?? [];

    return Object.fromEntries(entries);
  },
  userProductSeats: (state) => (id: Product['id']) => {
    const products = state.data?.products ?? [];
    if (products?.length > 0) {
      const currentProduct = products.find((product) => product.id === id);
      if (currentProduct?.id) {
        return {
          available: currentProduct.maxSeats - currentProduct.currentSeats,
          filled: currentProduct.currentSeats,
          max: currentProduct.maxSeats,
        };
      }
    }
    // If product not found
    return {
      available: 'NA',
      filled: 'NA',
      max: 'NA',
    };
  },
} as GetterTree<AccountState, RootState>;

const {
  isLoading: { name: ACCOUNT_IS_LOADING },
  getAccount: { name: ACCOUNT_DATA },
  isUserMultiBrand: { name: ACCOUNT_IS_MULTI_BRAND },
  error: { name: ACCOUNT_ERROR },
} = getters;

const getterNames = { ACCOUNT_IS_LOADING, ACCOUNT_DATA, ACCOUNT_ERROR, ACCOUNT_IS_MULTI_BRAND };

const {
  fetchAccount: { name: ACCOUNT_FETCH },
} = actions;
const actionNames = { ACCOUNT_FETCH };

const module: Module<AccountState, RootState> = {
  state: { ...initialState },
  mutations,
  actions,
  getters,
};

export interface R {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

export default module;

export { actionNames, getterNames };
