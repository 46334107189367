





















































import Vue from 'vue';
export default Vue.extend({
  name: 'pagination-base',

  data() {
    return {
      currentPage: this.defaultPage,
    };
  },
  methods: {
    _setPage(pageNumber: number) {
      this.currentPage = pageNumber;
      this.onPageUpdate(pageNumber);
    },
  },
  computed: {
    totalPages(): number {
      return Math.ceil(this.totalItems / this.perPage);
    },

    displayPages() {
      const Arr: number[] = [];
      if (this.currentPage <= 3) {
        const upperLimit = this.totalPages < 5 ? this.totalPages : 5;
        for (let i = 1; i <= upperLimit; i++) {
          Arr.push(i);
        }
      } else {
        const pagesRemaining = this.totalPages - this.currentPage;
        const lowerLimit = pagesRemaining < 2 ? this.totalPages - 3 : this.currentPage - 1;
        const upperLimit = pagesRemaining < 2 ? this.currentPage + pagesRemaining : this.currentPage + 1;
        for (let i = lowerLimit; i <= upperLimit; i++) {
          Arr.push(i);
        }
      }
      return Arr;
    },
  },
  props: {
    totalItems: Number,
    perPage: Number,
    onPageUpdate: Function,
    defaultPage: {
      type: Number,
      default: () => 1,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
});
