<template>
  <div id="analytics-route">
    <header>
      <fm-text tag="h1" class="heading-h1">Analytics</fm-text>
      <div class="date-range-wrapper">
        <label>Date range</label>
        <vue-ctk-date-time-picker
          v-model="dateRange"
          range
          auto-close
          right
          color="#0174b7"
          formatted="MMM D YYYY"
          :max-date="maxDate"
          :label="null"
          noClearButton
        ></vue-ctk-date-time-picker>
      </div>
      <div class="show-filter-wrapper">
        <label>Show filter</label>
        <fm-multiselect v-model-wc="showFilter" :options.prop="showOptions" placeholder="Search by show" />
      </div>
    </header>
    <nav class="analytics-tabs">
      <fm-tabs>
        <fm-tab-item
          id="date"
          :selected="$router.history.current.path === `/create/${selectedBrand.id}/analytics/podcasts/date`"
          @click="showTab"
          >By date</fm-tab-item
        >
        <fm-tab-item
          id="shows"
          :selected="$router.history.current.path === `/create/${selectedBrand.id}/analytics/podcasts/shows`"
          @click="showTab"
          >By show</fm-tab-item
        >
        <fm-tab-item
          id="episodes"
          :selected="$router.history.current.path === `/create/${selectedBrand.id}/analytics/podcasts/episodes`"
          @click="showTab"
          >By episode</fm-tab-item
        >
        <fm-tab-item
          id="countries"
          :selected="$router.history.current.path === `/create/${selectedBrand.id}/analytics/podcasts/countries`"
          @click="showTab"
          >By country</fm-tab-item
        >
        <fm-tab-item
          id="platforms"
          :selected="$router.history.current.path === `/create/${selectedBrand.id}/analytics/podcasts/platforms`"
          @click="showTab"
          >By platform</fm-tab-item
        >
      </fm-tabs>
    </nav>
    <div class="analytics-route-header filter-bar-wrapper">
      <div class="filters-wrapper">
        <fm-select
          @input="handlePlatformsUpdate"
          :value.prop="selectedPlatform"
          :options.prop="platformsFilter"
          placeholder="Platform"
          v-if="$router.history.current.path === `/create/${selectedBrand.id}/analytics/podcasts/platforms`"
        />
        <fm-select
          @input="handleCountriesUpdate"
          :options.prop="countriesFilter"
          :value.prop="selectedCountry"
          placeholder="Country"
          v-if="
            $router.history.current.path === `/create/${selectedBrand.id}/analytics/podcasts/countries` ||
            $router.history.current.path === `/create/${selectedBrand.id}/analytics/podcasts/date`
          "
        />
      </div>
      <div class="filter-pills">
        <fm-pill
          backgroundColor="#0174b7"
          fontColor="#ffffff"
          v-if="selectedCountry && selectedCountry !== 'ALL'"
          withCloseIcon
          @dismiss-pill="removeFilter('country')"
        >
          {{ selectedCountry }} &nbsp;
        </fm-pill>
        <fm-pill
          backgroundColor="#0174b7"
          fontColor="#ffffff"
          v-if="selectedPlatform && selectedPlatform !== 'ALL'"
          withCloseIcon
          @dismiss-pill="removeFilter('platform')"
        >
          {{ selectedPlatform }}&nbsp;
        </fm-pill>
      </div>
    </div>

    <main class="analytics-route-contents">
      <div class="analytics-route-active-page-wrapper">
        <router-view :selected-platform="selectedPlatform" :selected-country="selectedCountry" />
      </div>
    </main>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex';
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker';
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';
import moment from 'moment';

export default {
  name: 'AnalyticsRoute',
  components: {
    VueCtkDateTimePicker,
  },

  data() {
    const timeZone = this.$store.getters['CreateAndPublishStore/timeZone'];
    return {
      dateRange: {
        start: moment().tz(timeZone).format('YYYY-MM-DD'),
        end: moment().tz(timeZone).format('YYYY-MM-DD'),
      },
      maxDate: moment().tz(timeZone).format('YYYY-MM-DD'),
      selectedCountry: null,
      selectedPlatform: null,
      showFilter: [],
    };
  },

  computed: {
    ...mapGetters('CreateAndPublishStore/analytics', ['allShowsList']),
    ...mapGetters(['selectedBrand']),
    ...mapState('CreateAndPublishStore/analytics', [
      'platformAnalytics',
      'platformsFilter',
      'countryAnalytics',
      'countriesFilter',
    ]),
    filterOptions() {
      return {
        showIds: this.showFilter,
        // platform_list: this.selectedPlatform,
        countries_list: this.selectedCountry,
        start_date: this.dateRange.start,
        end_date: this.dateRange.end,
      };
    },
    showOptions() {
      return this.allShowsList.map((show) => {
        return {
          label: show.name,
          value: show.id,
        };
      });
    },
  },

  methods: {
    ...mapActions('CreateAndPublishStore/analytics', [
      'getAllShowsById',
      'getCountryAnalytics',
      'getPlatformAnalytics',
    ]),
    handleCountriesUpdate({ target: { value } }) {
      this.selectedCountry = value;
    },
    handlePlatformsUpdate({ target: { value } }) {
      this.selectedPlatform = value;
    },
    removeFilter(type) {
      if (type === 'country') {
        this.selectedCountry = null;
      } else if (type === 'platform') {
        this.selectedPlatform = null;
      }
    },
    showTab(e) {
      this.selectedCountry = null;
      this.selectedPlatform = null;
      this.$router.push(e.target.id);
    },
    updateData() {
      this.getAllShowsById();
    },
  },

  mounted() {
    this.updateData();
    this.$store.commit('CreateAndPublishStore/analytics/SET_DATERANGE', this.dateRange);
  },

  watch: {
    dateRange: {
      handler() {
        // Date-picker appends time to value on input. This strips it out.
        const dateRegExp = /^\d{4}-\d{2}-\d{2}/;
        const start = this.dateRange.start?.match(dateRegExp)?.shift();
        const end = this.dateRange.end?.match(dateRegExp)?.shift();
        const dateRange = { start, end };
        if (start && end) {
          this.$store.commit('CreateAndPublishStore/analytics/SET_DATERANGE', dateRange);
        }
      },
      deep: true,
    },
    showFilter: {
      handler(showFilter) {
        this.$store.commit('CreateAndPublishStore/analytics/SET_SHOW_FILTER', [...showFilter]);
      },
    },
  },
};
</script>

<style lang="scss">
#undefined-input {
  padding-top: 0;
}

table.b-table {
  margin: 0;
}

/** Overriding global Foundation styles that break things **/
ul.pagination {
  margin: initial !important;
  padding: initial !important;
  list-style-type: none !important;
}
</style>

<style lang="scss">
#analytics-route {
  font-family: Roboto;

  .date-time-picker {
    .field-input {
      height: 52px;
      border-radius: 8px;
      box-shadow: none;
      transition-property: none;

      &:hover {
        border: 2px solid var(--neutral-grey-1-text);
      }
    }
  }

  .vue-progress-path .progress {
    stroke: var(--brand-dark);
  }
}
</style>

<style lang="scss" scoped>
header {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 0 0 2em;

  .show-filter-wrapper {
    display: flex;
    align-items: baseline;

    label {
      margin-right: 16px;
    }

    fm-multiselect {
      width: 350px;
    }
  }

  .date-range-wrapper {
    display: flex;
    align-items: baseline;

    label {
      width: 140px;
    }
  }

  label {
    font-weight: 500;
  }
}

#analytics-route {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  margin: 32px 48px 0 48px;

  .analytics-route-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-flow: column;

    .analytics-route-header-filters {
      display: flex;
      &-for-date-time {
        width: 350px;
      }
      &-for-show {
        margin-left: 10px;
        width: 300px;
      }
    }
  }

  .filters-wrapper {
    display: flex;
    flex-wrap: wrap;
    padding: 2.25em 0 0.5em;
    width: 100%;

    & > * {
      flex-grow: 1;
      max-width: 25%;
      padding: 0 0 0 1em;

      @media (max-width: 991px) {
        max-width: 100%;
      }
    }

    *:first-child {
      padding-left: 0;
    }
  }

  .filter-pills {
    width: 100%;
  }

  .analytics-route-contents {
    width: 100%;
    height: 100%;
    display: flex;

    .navigation {
      background: #fafafa;
      flex: 1;

      .navigation-links {
        list-style: none;
        margin: 0;
        padding: 24px 12px;

        li {
          margin-bottom: 4px;

          a.navigation-link {
            color: #646464;
            display: block;
            // margin: 2px;
            padding: 12px;
            border-radius: 2.5px;
            &:hover {
              background: #e7e7e7;
              text-decoration: none;
            }

            transition: background 0.2s ease-in;
          }

          &.router-link-exact-active {
            a.navigation-link {
              background: #dddddd !important;
            }
          }
        }
      }
    }

    .analytics-route-active-page-wrapper {
      flex: 4;
      padding: 2rem 0;

      .analytics-route-loading-and-error-container {
        width: 100%;
        background: #ffffff;
        text-align: center;
        padding: 45px;
      }
    }
  }
}
</style>
