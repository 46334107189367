import { handleAjax, escapeRegExp } from '@/createandpublish/core/util';
import CreateAndPublishMediaService from '@/services/CreateAndPublishMediaService';
import {
  SegmentObserverData,
  SegmentObserverOutputResult,
  TwistedWaveSessionData,
  AudioEventHelperForTW,
  TwistedWaveSegment,
  Playlist,
  AudioEvent,
  PostAudioEvent,
} from '@/types/createandpublish';
import { Module } from 'vuex';

const MediaService = new CreateAndPublishMediaService();

const basePlaylist: Omit<Playlist, 'events' | 'show'> = {
  description: 'Generated for TwistedWave multi-segment editor',
  name: 'TwistedWave',
  permanent: 0,
  goto_live: false,
  tags: [],
  categories: [],
};

export default {
  namespaced: true,

  state: {
    audioEvents: [],
    stackEvents: [],
    tempPlaylist: null,
    tempPlaylistId: NaN,
    twSessionData: null,
    twSegmentObserverData: null,
    audioMixerResult: null,
  },

  getters: {
    audioEvents(state) {
      return state.audioEvents;
    },
    stackEvents(state) {
      return state.stackEvents;
    },
    stackEventResourceUris(state, _getters, _rootState, rootGetters) {
      const stationName = rootGetters['CreateAndPublishStore/station_name'];
      return state.stackEvents.map((event) => `/${stationName}/api/v1/event/${event.id}/`);
    },
    wizardModes() {
      return {
        LIBRARY: 'library', // When in the Audio Library view, but not in any wizard.
        EPISODE_WIZARD: 'episode_wizard', // When in the Podcasts view, on step 2 of the episode wizard.
        TRACK_WIZARD: 'track_wizard', // When in the Audio Library view, when combining tracks.
      };
    },
    tempPlaylist(state) {
      return state.tempPlaylist;
    },
    tempPlaylistId(state) {
      return state.tempPlaylistId;
    },
    twSessionData(state) {
      return state.twSessionData;
    },
    twSegmentObserverData(state) {
      return state.twSegmentObserverData;
    },
    audioMixerResult(state) {
      return state.audioMixerResult;
    },
  },

  mutations: {
    SET_AUDIO_EVENTS(state, audioEvents: AudioEvent[]) {
      state.audioEvents = audioEvents.filter((e) => e.title);
    },

    CLEAR_AUDIO_EVENTS(state) {
      state.audioEvents = [];
    },

    SET_STACK_EVENTS(state, stackEvents: AudioEvent[]) {
      state.stackEvents = stackEvents;
    },

    ADD_STACK_EVENT(state, stackEvent: AudioEvent) {
      if (!state.stackEvents.find((event) => event.id === stackEvent.id)) {
        state.stackEvents.push(stackEvent);
      }
    },

    REMOVE_STACK_EVENT(state, stackEvent: AudioEvent) {
      const index = state.stackEvents.findIndex((event) => stackEvent.id === event.id);
      if (index !== -1) {
        state.stackEvents.splice(index, 1);
      }
    },

    CLEAR_STACK_EVENTS(state) {
      state.stackEvents = [];
    },

    SET_TEMP_PLAYLIST(state, tempPlaylist: Playlist) {
      state.tempPlaylist = tempPlaylist;
    },

    CLEAR_TEMP_PLAYLIST(state) {
      state.tempPlaylist = null;
    },

    SET_TEMP_PLAYLIST_ID(state, tempPlaylistId: number) {
      state.tempPlaylistId = tempPlaylistId;
    },

    CLEAR_TEMP_PLAYLIST_ID(state) {
      state.tempPlaylistId = NaN;
    },

    SET_TW_SESSION(state, twSessionData: TwistedWaveSessionData) {
      state.twSessionData = twSessionData;
    },

    CLEAR_TW_SESSION(state) {
      state.twSessionData = null;
    },

    SET_SEGMENT_OBSERVER_DATA(state) {
      if (!state.tempPlaylist || !state.twSessionData) {
        throw new Error('[audioWizard] [SET_SEGMENT_OBSERVER_DATA] tempPlaylist and twSessionData must be set.');
      }

      const playlist = state.tempPlaylist;
      const { document_id } = state.twSessionData;
      const eventsList: AudioEventHelperForTW[] = [];
      let eventsBegin = 0;
      const sampleRate = 44100;

      const playlistEvents = playlist.events as PostAudioEvent[];

      playlistEvents.forEach((event) => {
        const numSamples = event.duration * sampleRate;
        eventsList.push({
          id: event.id,
          title: event.cart?.title || 'No title',
          created_at: event.cart.timestamp_created,
          updated_at: event.cart.timestamp_updated,
          duration: event.duration,
          cart: event.cart,
          begin_sample: eventsBegin,
          end_sample: eventsBegin + numSamples,
          deleted: false,
        });
        eventsBegin += numSamples;
      });

      const twSegmentObserverData = {
        id: playlist.id,
        events: eventsList,
        duration: playlist.duration,
        document_id: document_id,
        tw_segment_list: [],
        tw_segment_positions: [],
        tw_sample_rate: 0,
        selectionIndex: null,
        selectionRange: null,
      } as SegmentObserverData;
      state.twSegmentObserverData = twSegmentObserverData;
    },

    UPDATE_SEGMENT_OBSERVER(state, observerState: SegmentObserverData) {
      state.twSegmentObserverData = {
        ...state.twSegmentObserverData,
        ...observerState,
      };
    },

    CLEAR_SEGMENT_OBSERVER_DATA(state) {
      state.twSegmentObserverData = null;
    },

    SET_OBSERVER_EVENTS(state, events: AudioEventHelperForTW[]) {
      if (!state.twSegmentObserverData) {
        throw new Error('[audioWizard] [SET_OBSERVER_EVENTS] twSegmentObserverData must be set.');
      }
      state.twSegmentObserverData.events = events;
    },

    SET_AUDIO_MIXER_RESULT(state, audioMixerResult) {
      state.audioMixerResult = audioMixerResult;
    },

    CLEAR_AUDIO_MIXER_RESULT(state) {
      state.audioMixerResult = null;
    },
  },

  actions: {
    async getAudioEvents({ dispatch, commit }) {
      const mediaType = 'audio';
      await handleAjax({
        request: MediaService.getMediaLibraryItems(mediaType),
        dispatch,
        commit,
        mutation: 'SET_AUDIO_EVENTS',
      });
    },

    async initAudioMixer({ dispatch, commit, getters }, eventResourceUris: string[]) {
      try {
        await dispatch('generateTempPlaylistFromEvents', eventResourceUris);
        await new Promise((resolve) => {
          window.requestAnimationFrame(resolve);
        });
        await dispatch('initTwistedWaveSession', getters.tempPlaylist.id);
        commit('SET_SEGMENT_OBSERVER_DATA');
      } catch (e) {
        // TODO: Handle error.
        console.error(e);
      }
    },

    async generateTempPlaylistFromEvents({ dispatch, commit, getters, rootGetters }, events) {
      const playlist: Playlist = {
        ...basePlaylist,
        events,
        show: rootGetters['CreateAndPublishStore/activeShow'].resource_uri,
      };
      await handleAjax({
        request: MediaService.generateTemPlaylist(playlist),
        dispatch,
        commit,
        mutation: 'SET_TEMP_PLAYLIST_ID',
        modify(data) {
          // Pull the ID out of the resource_uri and cast it to a Number
          return parseInt(data.location.split('/')[5]);
        },
        async callback(err) {
          if (!err) {
            const id = getters.tempPlaylistId;
            const playlist = await dispatch('CreateAndPublishStore/draftEpisodes/getEpisodeById', id, { root: true });
            commit('SET_TEMP_PLAYLIST', playlist);
          }
        },
      });
    },

    async initTwistedWaveSession({ dispatch, commit }, playlistId: number) {
      await handleAjax({
        request: MediaService.initTwistedWaveSession(playlistId),
        dispatch,
        commit,
        mutation: 'SET_TW_SESSION',
      });
    },

    async submitAudioMixerOutput({ dispatch, commit, getters }) {
      const payload = getters.twSegmentObserverData;
      return handleAjax({
        request: MediaService.submitAudioMixerOutput(payload),
        dispatch,
        commit,
        mutation: 'SET_AUDIO_MIXER_RESULT',
      });
    },

    updateSegmentObserver({ commit }, observerState: SegmentObserverData) {
      commit('UPDATE_SEGMENT_OBSERVER', observerState);
    },

    // Sync the events with the segments we're getting back from the segment listener
    syncSegmentsAndEvents({ getters, commit }) {
      const oldEvents: AudioEventHelperForTW[] = [...getters.twSegmentObserverData.events];
      const newEvents: AudioEventHelperForTW[] = [];
      const sampleRate = getters.twSegmentObserverData.tw_sample_rate;
      let samplePosition = 0;
      getters.twSegmentObserverData.tw_segment_list.forEach((seg: TwistedWaveSegment, index) => {
        const numSamplesInSeg = seg.end - seg.begin;
        const newBegin = samplePosition;
        const newDuration = Number((numSamplesInSeg / sampleRate).toFixed(2));
        const newEnd = samplePosition + numSamplesInSeg;
        samplePosition += numSamplesInSeg;
        // We have enumerated duplicate segments which will normally mess up the matching so this match fixes that
        const titleRegex = new RegExp(`(${escapeRegExp(seg.name.replace(/\s\([A-Z]+\)$/, ''))})(\\s\\([A-Z]+\\))?$`);
        const updatedEvent = oldEvents.find((event) => event.title.match(titleRegex)) as AudioEventHelperForTW;
        newEvents[index] = {
          ...updatedEvent,
          begin_sample: newBegin,
          end_sample: newEnd,
          duration: newDuration,
          deleted: false,
          title: seg.name,
        };
      });

      // We want to keep metadata around for events that we aren't displaying in case someone hits undo
      oldEvents.forEach((e) => {
        if (!newEvents.find((event) => event.title === e.title)) {
          const tempEvent = { ...e };
          tempEvent.deleted = true;
          newEvents.push(tempEvent);
        }
      });

      commit('SET_OBSERVER_EVENTS', newEvents);
    },

    checkEventUploadProgress({ dispatch, commit }, uuid: string) {
      const encodedUuid = encodeURIComponent(uuid);
      return handleAjax({
        request: MediaService.checkEventUploadProgress(encodedUuid),
        dispatch,
        commit,
      });
    },

    resetAudioMixer({ commit }) {
      commit('CLEAR_TEMP_PLAYLIST');
      commit('CLEAR_TEMP_PLAYLIST_ID');
      commit('CLEAR_TW_SESSION');
      commit('CLEAR_SEGMENT_OBSERVER_DATA');
      commit('CLEAR_AUDIO_MIXER_RESULT');
    },
  },
} as Module<S, R>;

/**
 * Module state
 */
interface S {
  audioEvents: AudioEvent[];
  stackEvents: AudioEvent[];
  tempPlaylist: Playlist | null;
  tempPlaylistId: number;
  twSessionData: TwistedWaveSessionData | null;
  twSegmentObserverData: SegmentObserverData | null;
  audioMixerResult: SegmentObserverOutputResult | null;
}
/**
 * Vuex store rootState
 */
interface R {
  // Define rootState properties here, as necessary.
  [key: string]: unknown;
}
