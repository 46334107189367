
















import { Component, Prop, Vue } from 'vue-property-decorator';

import ErrorMessage from './ErrorMessage.vue';
import Label from './Label.vue';

@Component({
  components: {
    ErrorMessage,
    Label,
  },
})
export default class Checkbox extends Vue {
  // Props
  @Prop({ default: '', required: false }) ariaLabel?: string;
  @Prop({ default: '', required: false }) assistiveText?: string;
  @Prop({ default: '', required: false }) error?: string;
  @Prop({ default: '', required: false }) label?: string;
  @Prop({ default: '', required: false }) link?: {
    label: string;
    onClick: () => undefined;
  };
  @Prop({ default: '', required: false }) checked?: boolean;
  @Prop({ default: '', required: false }) value?: string;
  @Prop({ required: false }) checkEvent!: (event: Event) => void;
  @Prop({ default: false, required: false }) disabled?: boolean;

  // Computed
  get _ariaLabel(): string {
    // Maybe it shouldn't be undefined
    return this.ariaLabel || this.label || '';
  }

  // Methods
  hasError(x?: string) {
    typeof x === 'string' && x.length > 0;
  }

  checkEventHandler() {
    if (this.$props.checkEvent) {
      return this.$emit(this.$props.checkEvent, this.$props.value);
    }
  }
}
