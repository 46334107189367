import setupFreshChat from '@/utils/freshChat';
import setupFullStory from '@/utils/fullStory';
import setupTwistedWave from '@/utils/twistedWave';

export default () => {
  try {
    setupFreshChat();
    setupFullStory();
    setupTwistedWave();
  } catch (e) {
    console.warn('Error during third party support setup:', e);
  }
};

// Making the following maps as helpers to make human readable values for CX and Support

export const roleIdMap = {
  1: 'Super Admin',
  2: 'Admin',
  3: 'Standard',
};

export const statusIdMap = {
  1: 'Active',
  2: 'Disabled',
};
