










































import { Component, Vue, Prop } from 'vue-property-decorator';
import { TooltipConfig } from '@/types/audience';

@Component({
  name: 'ChartTooltip',
})
export default class ChartTooltip extends Vue {
  @Prop({ type: Object, required: true }) config!: TooltipConfig;

  get primaryDataStyle() {
    const style: Partial<CSSStyleDeclaration> = {};
    const backgroundColor = this.config.topRow.primary.backgroundColor;
    const borderColor = this.config.topRow.primary.borderColor;
    if (backgroundColor) {
      style.backgroundColor = backgroundColor;
    }
    if (borderColor) {
      style.borderColor = borderColor;
    }
    return style;
  }
}
