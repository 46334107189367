import { createNamespacedHelpers } from 'vuex';
const { mapGetters, mapActions } = createNamespacedHelpers('CreateAndPublishStore');
const { mapState: mapStateDraftEpisodes, mapActions: mapActionsDraftEpisodes } = createNamespacedHelpers(
  'CreateAndPublishStore/draftEpisodes'
);

export const episodeMoreMenuMixin = {
  data() {
    return {
      isEmbedAudioModalOpen: false,
      isDownloadNoticeModalOpen: false,
      isConfirmModalOpen: false,
      isUnpublishModalOpen: false,
      isShareUrlModalOpen: false,
      showDownloadAudioMenu: false,
      showDownloadVideoMenu: false,
    };
  },

  computed: {
    ...mapStateDraftEpisodes(['currentDraftEpisodeId']),

    ...mapGetters(['isAvess']),
    menuOptions() {
      const isDrafts = this.$route.query.tab === 'drafts';
      const isUnpublished = this.$route.query.tab === 'unpublished';
      const isScheduled = this.$route.query.tab === 'scheduled';
      return [
        ...(!isDrafts && !isUnpublished
          ? [
              {
                label: isScheduled ? 'Unschedule' : 'Unpublish',
                onClick: () => this.handleUnpublishModalOpen(),
              },
            ]
          : []),
        {
          label: 'Delete',
          onClick: () => this.openDeleteModal(),
        },
        {
          label: 'Edit',
          children: [
            {
              label: 'Info',
              onClick: () => this.edit('info'),
            },
            {
              label: 'Audio',
              onClick: () => this.edit('audio'),
            },
            {
              label: 'Publish',
              onClick: () => this.edit('publish'),
            },
          ],
        },
        {
          label: 'Duplicate',
          onClick: () => this.duplicate(),
        },
        {
          label: 'Download Audio',
          children: [
            {
              label: 'AAC',
              onClick: () => this.handleDownloadNoticeModalOpen('aac'),
            },
            {
              label: 'MP3',
              onClick: () => this.handleDownloadNoticeModalOpen('mp3'),
            },
          ],
        },
        // Conditionally adds in Create Video if no video has been created for this episode
        ...(this.showCreateVideoOption
          ? [
              {
                label: 'Create Video',
                onClick: () => this.edit('publish'),
              },
            ]
          : []),
        // Conditionally adds in Video download menu item with conditional children
        ...(this.showVideoOptions
          ? [
              {
                label: 'Video Download',
                children: [
                  ...(this.basicVideoStatus === 2
                    ? [
                        {
                          label: 'Basic Video',
                          href: this.basicVideoUrl,
                        },
                      ]
                    : []),
                  ...(this.advancedVideoStatus === 2
                    ? [
                        {
                          label: 'Advanced Video',
                          href: this.advancedVideoUrl,
                        },
                      ]
                    : []),
                ],
              },
            ]
          : []),
        {
          label: 'Embed Code',
          onClick: () => this.toggleEmbedModal(),
        },
        {
          label: 'Sharing URL',
          onClick: () => this.toggleSharingUrlModal(),
        },
      ];
    },

    audioUrl() {
      return `${this.$store.getters.mediaUrl}/${this.$store.getters.station}/playlist/dl/${this.episode.playlist_id}`;
    },

    basicVideoUrl() {
      const stationName = this.$store.getters.settings.station_name;
      const bitrate = this.$store.getters.settings.bitrate;
      return `${this.$store.getters.stationUrl}streamon-perm/${stationName}-${bitrate}-pl-${this.episode.playlist_id}.mp4`;
    },

    advancedVideoUrl() {
      const stationName = this.$store.getters.settings.station_name;
      return `${this.$store.getters.stationUrl}streamon-perm/${stationName.toLowerCase()}-${
        this.episode.playlist_id
      }-advanced.mp4`;
    },

    /**
     * video_status codes:
     * 0 = no video
     * 1 = job to create the video has been initiated but is still in processing
     * 2 = video is created and can be downloaded
     * 98 = (only set from frontend) tried forever and didn't get success or failure, tell user to try again later
     * 99 = video creation failed
     */
    basicVideoStatus() {
      return this.episode.video_status;
    },

    advancedVideoStatus() {
      return this.episode.advanced_video_status;
    },

    showVideoOptions() {
      return this.episode.video_status === 2 || this.episode.advanced_video_status === 2;
    },

    showCreateVideoOption() {
      if (this.basicVideoStatus === 0 && this.episode.duration < 1800) return true;
      if (this.advancedVideoStatus === 0 && this.episode.duration < 600) return true;
      return false;
    },

    isInactive() {
      if (this.episode.draft || this.episode.unpublished) {
        return true;
      } else {
        return false;
      }
    },

    isScheduled() {
      return (
        !this.episode.unpublished && this.episode.execute_at && this.episode.execute_at > new Date().getTime() / 1000
      );
    },
  },

  methods: {
    ...mapActions(['unpublishSocialMediaConnection', 'setPlaylistType']),
    ...mapActionsDraftEpisodes(['duplicateEpisode']),

    async unpublish() {
      this.isUnpublishModalOpen = false;
      try {
        await this.unschedule();

        this.unpublishSocialMediaConnection({ playlistId: this.episode.playlist_id });

        const epData = [
          {
            playlist_id: this.episode.playlist_id,
            unpublished: true,
          },
        ];
        this.setPlaylistType({ settings: epData });
      } catch (err) {
        console.error(err);
        this.$store.commit('SET_MESSAGE', {
          name: 'Unpublish Error',
          details: `Could not unpublish episode. Please try again.`,
          type: 'error',
        });
      }
    },

    openDeleteModal() {
      this.isConfirmModalOpen = true;
    },

    async deletePlaylist() {
      this.isConfirmModalOpen = false;
      try {
        await this.unschedule();
        this.$store.dispatch('CreateAndPublishStore/deletePlaylist', this.episode.playlist_id);
      } catch (err) {
        this.$store.commit('SET_MESSAGE', {
          name: 'Delete Error',
          details: `Could not delete episode. Please try again.`,
          type: 'error',
        });
      }
    },

    async edit(page) {
      await this.duplicateEpisode(this.episode.playlist_id);
      const component = page === 'audio' ? (this.isAvess ? 'review' : page) : page;
      this.$router.push({
        path: `episodes/${this.episode.playlist_id}/edit/${component}`,
        query: {
          editingEpisode: this.episode.playlist_id,
        },
      });
    },

    async duplicate() {
      await this.duplicateEpisode(this.episode.playlist_id);
      this.$router.push({ path: `episodes/${this.currentDraftEpisodeId}/edit/info` });
    },

    async unschedule() {
      if (this.isScheduled) {
        await this.$store.dispatch('draftEpisodes/deletePlaylistSchedule', { playlist_id: this.episode.playlist_id });
      }
    },

    handleDownloadNoticeModalOpen(type) {
      this.isDownloadNoticeModalOpen = true;
      const { bitrate, station_name } = this.$store.getters['CreateAndPublishStore/settings'];
      const { playlist_id } = this.episode;
      const payload = {
        station: station_name.toLowerCase(),
        bitrate,
        playlists: [playlist_id],
        request_download: true,
      };
      this.$store.dispatch('CreateAndPublishStore/stationEvents/cachePlaylist', payload);
      this.$store.commit('CreateAndPublishStore/ADD_PLAYLIST_ID_FOR_DOWNLOAD', {
        playlistId: playlist_id,
        type,
      });
    },

    handleDownloadNoticeModalCancel() {
      this.isDownloadNoticeModalOpen = false;
    },

    handleConfirmModalOpen() {
      this.isConfirmModalOpen = true;
    },

    handleUnpublishModalOpen() {
      if (this.isInactive) {
        return;
      }
      this.isUnpublishModalOpen = true;
    },

    toggleEmbedModal() {
      this.isEmbedAudioModalOpen = !this.isEmbedAudioModalOpen;
    },

    toggleSharingUrlModal() {
      this.isShareUrlModalOpen = !this.isShareUrlModalOpen;
    },
  },
};
