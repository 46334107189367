













import { Component, Vue } from 'vue-property-decorator';

@Component({
  name: 'LeaderboardCardHeaderPlaceholder',
})
export default class LeaderboardCardHeaderPlaceholder extends Vue {}
