<template>
  <div>
    <transition name="app-fade" mode="out-in">
      <router-view v-if="hasShowData" class="show-route-container" keep-alive> </router-view>
    </transition>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'ShowsRoute',

  computed: {
    ...mapGetters('CreateAndPublishStore', ['activeShow']),

    showId() {
      return this.$route.params.showId;
    },

    hasShowData() {
      return Object.keys(this.activeShow).length !== 0;
    },

    hasStaleShowData() {
      return this.activeShow.id !== Number(this.showId);
    },

    hasValidShowId() {
      return !isNaN(this.showId);
    },
  },

  methods: {
    fetchData() {
      this.$store.dispatch('CreateAndPublishStore/clearActiveShow');
      // this.$store.dispatch('CreateAndPublishStore/clearActiveEpisodes');
      this.$store.dispatch('CreateAndPublishStore/setActiveShow', this.showId);
      this.$store.dispatch('CreateAndPublishStore/fetchShowData', this.showId);
    },
  },

  created() {
    this.fetchData();
  },

  watch: {
    $route() {
      if (this.hasValidShowId && (!this.hasShowData || this.hasStaleShowData)) {
        this.fetchData();
      }
    },
  },
};
</script>
