










import moment, { MomentInput } from 'moment';
import { mapGetters } from 'vuex';
import Vue from 'vue';

import '../styles.scss';
import Button from '@/components/common/buttons/Button.vue';

export default Vue.extend({
  name: 'trial-active-admin',

  components: {
    Button,
  },

  computed: {
    ...mapGetters(['currentPeriodEndsAt', 'features']),
    daysRemaining(): number {
      const expirationDate = moment(this.currentPeriodEndsAt as MomentInput);
      const now = moment();
      return expirationDate.diff(now, 'days');
    },
  },

  methods: {
    // On upgrade CTA
    onUpgrade(): void {
      const ecommFeatureIsEnabled = this.features.includes('ecomm');
      if (ecommFeatureIsEnabled) {
        this.$router.push('/subscriptions');
      } else {
        window.open('https://content.futuricontentcloud.com/contact', '_blank');
      }
    },
  },
});
