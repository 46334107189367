import type { RouteConfig } from 'vue-router';

import Discover from '@/views/Discover.vue';
import Following from '@/components/discover/Following.vue';
import DiscoverSettings from '@/components/discover/Settings.vue';
import { requiresBrandAccess, requiresProductAccess } from '@/constants/router/permissions';

import store from '@/store';
import { nextTick } from 'vue/types/umd';

export default [
  {
    path: '/discover/:brandId?',
    name: 'Discover',
    component: Discover,
    meta: {
      ...requiresBrandAccess,
      sidebar_active_item: 'Trending',
      ...requiresProductAccess,
      title: 'Trending | Discover',
      feature: 'discover',
    },
    beforeEnter: async (_to, _from, next) => {
      console.info(`discover route: calling buildTrendingStorySearch`);
      // No reason to wait here
      // eslint-disable-next-line
      store.dispatch('buildTrendingStorySearch').catch(() => {});

      return next();
    },
  },
  {
    path: '/discover/:brandId/following',
    name: 'Discover Following',
    component: Following,
    meta: {
      ...requiresBrandAccess,
      sidebar_active_item: 'Following',
      ...requiresProductAccess,
      title: 'Following | Discover',
      feature: 'discover',
    },
  },
  {
    path: '/discover/:brandId/settings',
    name: 'Discover Settings',
    component: DiscoverSettings,
    meta: {
      ...requiresBrandAccess,
      sidebar_active_item: 'Settings',
      ...requiresProductAccess,
      title: 'Settings | Discover',
      feature: 'discover',
    },
  },
] as RouteConfig[];
