









import Vue from 'vue';
import { mapGetters } from 'vuex';

import { Product } from '@/types/account/AccountData';

export default Vue.extend({
  name: 'seat-info-view-title',

  props: {
    id: {
      type: Number as () => Product['id'],
      default: 0,
    },
  },

  data() {
    return {
      actionLink: {
        text: 'Upgrade to add more',
      },
    };
  },

  computed: {
    ...mapGetters(['sortedPriorityAccountProducts', 'features']),
  },

  methods: {
    onUpgrade(): void {
      this.$router.push('/subscriptions');
    },

    // Build copy with available products and seats data
    getSeatsCopy() {
      const prefix = ''; // To keep flexible
      const suffix = ' remaining.';
      let seatsString = '';
      if (this.sortedPriorityAccountProducts?.length > 0) {
        const productStrings = this.sortedPriorityAccountProducts.map((product) => {
          const seats = product.maxSeats - product.currentSeats;
          return `<b>${seats}</b> ${product.name} user${seats === 1 ? '' : 's'}`;
        });
        seatsString = productStrings.join(', ').replace(/,(?!.*,)/gim, ' and');
      }
      return prefix + seatsString + suffix;
    },
  },
});
