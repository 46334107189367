<template>
  <img class="image-item" :alt="alt || ''" v-lazyload :data-src="source" />
</template>

<script>
import lazyLoadDirective from '../directives/lazyLoadDirective';

export default {
  props: {
    source: String,
    alt: String,
  },
  directives: {
    lazyload: lazyLoadDirective,
  },
};
</script>

<style lang="scss" scoped></style>
