




















import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({
  name: 'YouTubeIcon',
})
export default class YouTubeIcon extends Vue {
  @Prop({ type: String, required: false, default: '#FF0000' }) fill!: string;
  @Prop({ type: Number, required: false, default: 29 }) height!: number;
  @Prop({ type: Number, required: false, default: 40 }) width!: number;
  @Prop({ type: String, required: false, default: 'YouTube logo' }) title!: string;
}
