

















































































// import { mixins } from 'vue-class-component';
import { Component, Vue } from 'vue-property-decorator';
import { Action } from 'vuex-class';

import SettingsCard from '@/components/discover/cards/SettingsCard.vue';
import KnowledgeBase from '@/components/discover/cards/KnowledgeBase.vue';
import Button from '@/components/common/buttons/Button.vue';

@Component({
  components: {
    SettingsCard,
    KnowledgeBase,
    Button,
  },
  data() {
    return {
      welcomeBannerActive: false,
      trendingActive: false,
    };
  },
})
export default class WelcomeBanner extends Vue {
  @Action toggleWelcomeBanner;

  closeBanner() {
    this.toggleWelcomeBanner(false);
  }
}
