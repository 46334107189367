<template>
  <div :class="flow">
    <div class="audience-sidebar-wrapper" v-if="isAudienceFlow">
      <AudienceSidebar />
    </div>
    <div class="form">
      <div class="form-header" v-if="isDefaultFlow || isAudienceFlow">
        <router-link to="/">
          <img alt="logo" src="@/assets/FuturiCC.svg" height="61" />
        </router-link>
      </div>
      <div class="form-content">
        <div class="form-header" v-if="isCheckoutFlow">Let’s set up your subscription</div>
        <!-- Success message -- user was activated -->
        <AlertDisplay
          v-if="showUserActivatedBanner"
          :alertMessage="userActivatedMessage"
          class="alertMessage"
          :drawer="true"
        />
        <!-- Email Exists -->
        <AlertDisplay
          v-if="emailExistsBanner && !showWrongCredentialsBanner && !showUnhandledErrorBanner"
          :alertMessage="flow === 'default' ? emailExistsMessage : emailExistsMessageAudience"
          class="alertMessage"
          :drawer="true"
        />
        <!-- Success message -- user successfully changed password -->
        <AlertDisplay
          v-if="showChangedPasswordBanner"
          :alertMessage="changedPasswordMessage"
          class="alertMessage"
          :drawer="true"
        />
        <!-- Error message -- wrong credentials -->
        <AlertDisplay
          v-if="showWrongCredentialsBanner"
          :alertMessage="wrongCredentialsMessage"
          class="alertMessage"
          :drawer="true"
        />
        <!-- Error message -- unhandled error -->
        <AlertDisplay
          v-if="showUnhandledErrorBanner"
          :alertMessage="unhandledErrorMessage"
          class="alertMessage"
          :drawer="true"
        />
        <div class="form-title" v-if="isDefaultFlow">Sign in to Content Cloud</div>
        <div class="form-title" v-if="isCheckoutFlow">Your information</div>
        <div class="form-title" v-if="isAudienceFlow">
          Sign in to Content Cloud
          <span> Need an account? <router-link to="/signupforaudience">Sign up now</router-link> </span>
        </div>
        <Input
          :data-qa="$getComponent('Login', 'TextInput', 'emailAddress')"
          :inputHandler="handleInputChange"
          :value="form.emailAddress"
          :maxLength="100"
          class="form-input"
          label="Email address"
          name="emailAddress"
          placeholder="name@example.com"
          :disabled="emailExistsBanner"
          :error="
            $v.form.emailAddress.$error
              ? !$v.form.emailAddress.email
                ? errorMessages.invalidEmail
                : 'Email' + errorMessages.required
              : ''
          "
          @keyup.native.enter="onSubmit"
        />

        <fm-link @click="onForgotPassword" class="form-forgot-password" v-if="isAudienceFlow"
          >Forgot your password?</fm-link
        >
        <input type="password" style="display: none" />
        <Input
          :data-qa="$getComponent('Login', 'TextInput', 'password')"
          :inputHandler="handleInputChange"
          :value="form.password"
          class="form-input form-input-password"
          label="Password"
          name="password"
          type="password"
          @keyup.native.enter="onSubmit"
          :error="$v.form.password.$error ? 'Password' + errorMessages.required : ''"
        />

        <div class="form-password-hint">
          10 characters or more, with at least one lowercase letter, one uppercase letter, and one special character.
        </div>
        <fm-link @click="onForgotPassword" class="form-forgot-password" v-if="isCheckoutFlow"
          >Forgot your password?</fm-link
        >

        <div class="form-submit-buttons">
          <Button
            buttonType="primary"
            onClickEventHandler="submit"
            @submit.prevent="onSubmit"
            :data-qa="$getComponent('Login', 'Button', 'submit')"
            class="form-submit"
            :loading="formIsLoading"
          >
            {{ buttonText }}
          </Button>
          <fm-link @click="onForgotPassword" class="form-forgot-password" v-if="!isAudienceFlow && !isCheckoutFlow"
            >Forgot your password?</fm-link
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';

import AlertDisplay from '@/components/common/AlertDisplay.vue';
import Button from '@/components/common/buttons/Button.vue';
import Input from '@/components/common/form/Input.vue';
import AudienceSidebar from '@/components/ecomm/AudienceSidebar.vue';

import { loginMixin } from '@/mixins/loginMixin';

export default Vue.extend({
  name: 'subscription-login',
  components: {
    AlertDisplay,
    AudienceSidebar,
    Button,
    Input,
  },
  props: {
    flow: {
      type: String,
      default: 'default',
    },
  },
  mixins: [loginMixin],
});
</script>

<style lang="scss" scoped>
.form-password-hint {
  color: var(--neutral-grey-2-lighter-text);
  font-size: var(--fm-font-size-14);
  line-height: var(--fm-line-spacing-21);
}

.default {
  .form {
    background: var(--neutral-white);
    box-shadow: 0px 1px 7px rgba(34, 32, 70, 0.2);
    max-width: 592px;
    min-width: 536px;

    @media (max-width: 768px) {
      padding: 48px 24px 24px;
      min-width: unset;
    }
  }

  .form-header {
    align-items: center;
    background: #f5f5f5;
    display: flex;
    height: 80px;
    justify-content: center;
  }

  .form-content {
    padding: 24px;
  }

  .form-title {
    color: var(--neutral-grey-1-text);
    font-family: Roboto;
    font-size: 30px;
    font-style: normal;
    font-weight: 500;
    line-height: 38px;
    margin-bottom: 32px;
  }

  .form-input {
    margin-bottom: 32px;

    &.form-input-password {
      margin-bottom: 0;
    }
  }
  .form-submit-buttons {
    align-items: center;
    display: flex;
    flex-direction: column;
  }
  .form-submit {
    width: 100%;
  }

  .form-password-hint {
    margin-bottom: 32px;
  }

  .form-forgot-password {
    margin-top: 24px;
    text-align: center;
  }
}

.checkout {
  .form {
    background: var(--neutral-white);
    display: flex;
    padding: 64px;
    justify-content: center;

    @media (max-width: 768px) {
      padding: 48px 24px 24px;
      min-width: unset;
    }
  }

  .form-header {
    font-size: var(--fm-font-size-36);
    font-weight: 500;
    line-height: var(--fm-line-spacing-44);
    margin-bottom: 32px;
    max-width: 592px;
    min-width: 536px;

    @media (max-width: 768px) {
      max-width: 100%;
      min-width: 100%;
      width: 100%;
    }
  }

  .form-content {
    max-width: 592px;
    width: 592px;

    @media (max-width: 768px) {
      max-width: 100%;
      width: 100%;
    }
  }

  .form-title {
    color: var(--neutral-grey-1-text);
    font-family: Roboto;
    font-size: var(--fm-font-size-24);
    font-style: normal;
    font-weight: 500;
    line-height: var(--fm-line-spacing-32);
    margin-bottom: 24px;
  }

  .form-input {
    margin-top: 32px;
  }

  .form-forgot-password {
    font-size: var(--fm-font-size-14);
    margin-bottom: 32px;
  }

  .custom-button {
    @media (max-width: 768px) {
      width: 100%;
    }
  }
}

.audience {
  display: flex;
  flex-direction: row;
  height: 100vh;

  @media (max-width: 1024px) {
    flex-direction: column;
  }

  .form {
    align-items: center;
    display: flex;
    flex-direction: column;
    padding: 68px;
    width: 50%;

    @media (max-width: 1024px) {
      padding: 48px 24px 24px;
      width: 100%;
    }
  }

  .form-header {
    max-width: 592px;
    margin-bottom: 64px;
    width: 592px;

    @media (max-width: 1024px) {
      display: none;
    }
  }

  .form-content {
    max-width: 592px;
    width: 592px;

    @media (max-width: 1024px) {
      max-width: 100%;
      width: 100%;
    }
  }

  .form-title {
    align-items: baseline;
    color: var(--neutral-grey-1-text);
    display: flex;
    font-family: Roboto;
    font-size: var(--fm-font-size-24);
    font-style: normal;
    font-weight: 500;
    justify-content: space-between;
    line-height: var(--fm-line-spacing-32);
    margin-bottom: 24px;

    span {
      align-self: right;
      font-size: var(--fm-font-size-16);
      font-weight: 400;
      line-height: var(--fm-line-spacing-24);

      a {
        color: var(--accent-links);
        text-decoration: underline;
      }
    }

    @media (max-width: 1024px) {
      flex-direction: column;
    }
  }

  .form-input {
    margin-top: 32px;
  }

  .form-input-password {
    margin-top: 0;
  }

  .form-password-hint {
    margin-bottom: 32px;
  }

  .form-forgot-password {
    float: right;
    margin: 32px 0 -20px 0;
    position: relative;
    z-index: 2;
  }

  .custom-button {
    width: 100%;
    @media (max-width: 768px) {
      margin-bottom: 8px;
      width: 100%;
    }
  }

  &-sidebar-wrapper {
    background: var(--accent-background);
    height: 100vh;
    order: 1;
    width: 50%;

    @media (max-width: 1024px) {
      min-height: 95vh;
      order: 0;
      width: 100%;
    }

    @media (max-width: 767px) {
      order: 0;
      min-height: 590px;
    }

    @media (min-width: 540px) and (max-width: 720px) {
      order: 0;
      min-height: 740px;
    }

    @media (max-width: 320px) {
      order: 0;
      min-height: 510px;
    }
  }
}
</style>
