import { render, staticRenderFns } from "./AudienceRoute.vue?vue&type=template&id=3d33a27c&scoped=true&"
import script from "./AudienceRoute.vue?vue&type=script&lang=ts&"
export * from "./AudienceRoute.vue?vue&type=script&lang=ts&"
import style0 from "./AudienceRoute.vue?vue&type=style&index=0&id=3d33a27c&prod&lang=scss&scoped=true&"
import style1 from "./AudienceRoute.vue?vue&type=style&index=1&id=3d33a27c&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3d33a27c",
  null
  
)

export default component.exports