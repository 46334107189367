
















import { Component, Vue } from 'vue-property-decorator';

import FormSpacer from '@/components/common/form/FormSpacer.vue';
import SplitForm from '@/components/common/form/SplitForm.vue';
import CreditPaymentCard from '@/components/subscriptions/CreditPaymentCard.vue';
import BillingHistoryTable from '@/components/subscriptions/BillingHistoryTable.vue';
import type { AlertDisplay } from '@/types/AlertMessage';

@Component({
  name: 'BillingAndPaymentTab',
  components: {
    SplitForm,
    FormSpacer,
    CreditPaymentCard,
    BillingHistoryTable,
  },
})
export default class BillingAndPaymentTab extends Vue {
  onAlertUpdate(alert: AlertDisplay) {
    this.$emit('onAlertUpdate', alert);
  }
}
