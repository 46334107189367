


































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';

import AccountCard from '@/components/dashboard/AccountCard.vue';
import Button from '@/components/common/buttons/Button.vue';
import { AccountState } from '@/types/account/AccountState';
import { isMultiBrand } from '@/utils';
import { isFeatureAvailable } from '@/utils/routerHelpers';

@Component({
  components: {
    AccountCard,
    Button,
  },
})
export default class BrandsCampaignsCard extends Vue {
  @Prop({ type: Boolean, required: false }) newUser?: boolean;
  @Getter getAccount!: AccountState;

  get maxBrands() {
    return this.getAccount?.data?.maxBrands || 0;
  }

  get activeBrands() {
    return this.getAccount?.data?.activeBrands || 0;
  }

  get activeCampaigns() {
    return `${this.getAccount?.data?.activeCampaigns ?? 0} total campaigns`;
  }

  get isMultiBrand() {
    return isMultiBrand();
  }

  get title() {
    if (this.isMultiBrand) {
      return isFeatureAvailable('campaigns') ? 'brands & campaigns' : 'brands';
    } else {
      return 'campaigns';
    }
  }

  get paragraphText() {
    return isFeatureAvailable('campaigns') ? 'and create campaigns for each brand ' : '';
  }

  get brandsCopy() {
    return `${this.activeBrands} of ${this.maxBrands}`;
  }
}
