<template>
  <tr class="table__row" :class="[classes]">
    <slot />
  </tr>
</template>

<script>
export default {
  name: 'TableRow',
  props: ['classes'],
};
</script>

<style lang="scss" scoped>
.table__row {
  align-items: center;
  display: table-row;
  // padding: 15px 24px;
  border-top: 1px solid var(--neutral-grey-3-borders);
}
.table__row:last-of-type {
  border-bottom: 1px solid var(--neutral-grey-3-borders);
}
.table__row.stripped:nth-child(odd) {
  background: rgb(245, 245, 245);
}
</style>
