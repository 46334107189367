<template>
  <div class="confirm-modal">
    <fm-modal :visible.prop="true">
      <div slot="controlRow" class="modal-control-row">
        <h3>{{ headerText }}</h3>
        <button class="control-row-close-button" @click="close">
          <span class="material-icons">close</span>
        </button>
      </div>
      <div v-if="!useContentSlot" slot="content">
        <p>{{ contentText }}</p>
      </div>
      <div v-else slot="content">
        <slot></slot>
      </div>
      <div slot="error" class="modal-error">
        {{ error }}
      </div>
      <div slot="actionRow" class="modal-action-row">
        <div class="button-container">
          <fm-button-secondary @click="close">
            {{ cancelText }}
          </fm-button-secondary>
        </div>
        <div class="button-container">
          <fm-button-primary @click="confirm" :loading="loading" :success="status === 'success'">
            {{ confirmText }}
          </fm-button-primary>
        </div>
      </div>
    </fm-modal>
  </div>
</template>
<script>
export default {
  name: 'confirm-modal',
  props: {
    cancelText: {
      type: String,
      default: 'Cancel',
    },
    confirmText: {
      type: String,
      default: 'Confirm',
    },
    contentText: {
      type: String,
      default: 'Are you sure you want to remove this item?',
    },
    error: {
      type: String,
      default: 'asdasd',
    },
    headerText: {
      type: String,
      default: 'Are you sure?',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    useContentSlot: {
      type: Boolean,
      default: false,
    },
    status: {
      type: String,
      default: '',
    },
  },
  methods: {
    close() {
      this.$emit('close');
    },
    confirm() {
      this.$emit('confirm');
    },
  },
};
</script>
<style lang="scss" scoped>
.confirm-modal {
  .modal-error {
    color: red;
    text-align: center;
  }
  .modal-action-row {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    overflow-y: hidden;
    .button-container {
      padding: 4px;
    }
  }
  .modal-control-row {
    display: flex;
    justify-content: space-between;
    white-space: pre-wrap;
    width: 100%;
    button {
      height: 1.75rem;
      border: none;
      background: none;
      box-shadow: none;
      color: var(--neutral-grey-1-text);
    }
  }
}
</style>
