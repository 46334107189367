























import { Component, Vue, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import LeaderboardCardHeader from '@/audience/components/leaderboard/LeaderboardCardHeader.vue';
import LeaderboardCardHeaderPlaceholder from '@/audience/components/leaderboard/LeaderboardCardHeaderPlaceholder.vue';
import LeaderboardSelfBarChart from '@/audience/components/leaderboard/LeaderboardSelfBarChart.vue';
import type { AyrshareProfile, SocialAnalytics, LeaderboardRankData } from '@/types/audience';

const audienceModule = namespace('AudienceAnalyticsStore');

@Component({
  name: 'LeaderboardSelfCard',
  components: {
    LeaderboardCardHeader,
    LeaderboardSelfBarChart,
    LeaderboardCardHeaderPlaceholder,
  },
})
export default class LeaderboardSelfCard extends Vue {
  @Prop({ type: Object, required: true }) socialAnalytics!: SocialAnalytics;
  @Prop({ type: Object, required: false }) rankingData?: LeaderboardRankData;

  @audienceModule.Getter profile!: AyrshareProfile;
  @audienceModule.Getter isProfileOptedIntoLeaderboard!: boolean;

  get headerProps() {
    const { profileName, image } = this.profile;

    let currentRank = NaN;
    let positionChange = NaN;

    if (this.rankingData) {
      currentRank = this.rankingData.rank.current ?? NaN;
      positionChange = this.rankingData.rank.rankDifference ?? NaN;
    }

    return {
      profileName,
      profileImageSrc: image ?? '',
      currentRank,
      positionChange,
    };
  }
}
