import { ProductMap } from '@/types/ProductMap';

/**
 * New products need to be added here
 * @key referenceName For semantic reference only; use `name` from BE for anything else displayed in FE
 * @key logoAppCard Used for App cards on Dashboard and Manage Subscription
 * @key logoHeader Used for each product in profile dropdown, in header
 * @key displayPriority If displaying a list of products, use this value to order them
 * @key url Use for any FE redirection to an app
 * @key featureName This maps to `GET /features`
 */
export const productMap: ProductMap[] = [
  {
    displayPriority: 2,
    featureName: 'discover',
    id: 1,
    logoAppCard: 'discover/discover-logo.svg',
    logoHeader: 'discover.svg',
    referenceName: 'Discover',
    url: '/discover',
    learn_more_url: 'https://futuricontentcloud.com/tools/',
  },
  {
    displayPriority: 3,
    featureName: 'create-and-publish',
    id: 2,
    logoAppCard: 'create-publish-logo.svg',
    logoHeader: 'createpublish.svg',
    referenceName: 'Create&Publish',
    url: '/create',
    learn_more_url: 'https://futuricontentcloud.com/tools/',
  },
  {
    displayPriority: 1,
    featureName: 'audience',
    id: 3,
    logoAppCard: 'audience/app-cards/audience.svg',
    logoHeader: 'audience/profile-dropdown/audience.svg',
    referenceName: 'Audience',
    url: '/audience',
    learn_more_url: 'https://futuricontentcloud.com/tools/#audience',
  },
  {
    displayPriority: 4,
    featureName: 'full-suite-7-day-trial',
    id: 4,
    logoAppCard: 'FuturiCC.svg',
    logoHeader: 'FuturiCC.svg',
    referenceName: 'Full Suite - 7 Day Trial',
    url: '/audience',
    learn_more_url: 'https://futuricontentcloud.com/tools/',
  },
];
