















































































































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import { Getter, namespace } from 'vuex-class';

import CurrentSubscriptionInfoCard from '@/components/ecomm/manageSubscription/CurrentSubscriptionInfoCard.vue';
import CurrentSubscriptionPriceCard from '@/components/ecomm/manageSubscription/CurrentSubscriptionPriceCard.vue';
import Breadcrumb from '../components/common/navigation/breadcrumb/Breadcrumb.vue';
import ViewTitle from '../components/common/view/viewTitle/ViewTitle.vue';
import FormSpacer from '@/components/common/form/FormSpacer.vue';
import ButtonRadio from '@/components/common/buttons/ButtonRadio.vue';
import Select from '@/components/common/form/Select.vue';
import Checkbox from '../components/common/form/Checkbox.vue';
import Loading from '@/components/common/Loading.vue';

import { getPricePointByHandles, getPricePointHandle, getTotalPrice, getSubscriptionPayload } from '@/utils/ecomm';
import { productMap } from '@/constants/seeded/products';
import { maybePluralize } from '@/utils';

import moment from 'moment-timezone';

// enums
import { ProductKey, ProductHandle, ComponentHandle, PricePointHandle } from '@/types/ecomm';
import { ProductIds } from '@/types/account/AccountData';

import type { PlansData } from '@/types/ecomm';
import type { SubscriptionData } from '@/utils/ecomm';
import { Feature } from '@/types/features/FEFeature';

const EcommStore = namespace('EcommStore');

Component.registerHooks(['mounted']);

@Component({
  name: 'ManageSubscriptions',
  components: {
    CurrentSubscriptionInfoCard,
    CurrentSubscriptionPriceCard,
    Breadcrumb,
    ViewTitle,
    FormSpacer,
    ButtonRadio,
    Select,
    Checkbox,
    Loading,
  },
})
export default class ManageSubscriptions extends Vue {
  @Getter features!: Feature[];
  @Getter currentSubscriptionData!: SubscriptionData;
  @Getter('subscriptionId') currentSubscriptionId!: number;

  @EcommStore.Action getPlans!: () => Promise<void>;
  @EcommStore.Getter plans?: PlansData;
  @EcommStore.Action updateSubscription;

  isLoading = false;
  isUpgradeButtonLoading = false;
  breadcrumbItems = [
    { text: 'Account Overview', to: { name: 'Dashboard' } },
    { text: 'Subscription & billing', to: { name: 'Subscriptions' } },
    { text: 'Update subscription', active: true },
  ];
  applicationOptions = [
    {
      label: 'Audience',
      id: 3,
      subtext: '(always included, always free)',
      value: ProductKey.AUDIENCE,
      isDisabled: true,
      isChecked: true,
    },
    { label: 'Discover', id: 1, value: ProductKey.DISCOVER, isDisabled: false, isChecked: false },
    { label: 'Create & Publish', id: 2, value: ProductKey.CREATE_AND_PUBLISH, isDisabled: false, isChecked: false },
  ];
  multiBrandOptions = [
    { text: 'Yes', value: 'yes' },
    { text: 'No', value: 'no' },
  ];
  multiBrandToggle = 'yes';
  audienceSeatQuantity = '1';
  discoverSeatQuantity = '1';
  createSeatQuantity = '1';
  brandsManagedCount = '1';

  get brandCountOptions() {
    return new Array(30).fill(undefined).map((_, idx) => ({
      label: (idx + 1).toString(),
      value: (idx + 1).toString(),
    }));
  }

  onProductCheckEvent(value: string) {
    const option = this.applicationOptions.find((option) => option.value === value);
    if (option) {
      option.isChecked = !option.isChecked;
    }
  }

  toggleMultiBrand(newVal: string) {
    this.multiBrandToggle = newVal;
  }

  get updatedRenewalDateString(): string {
    // TODO: Use timezone when API supports it.
    const date = moment().add(1, 'month');
    return `Charged today and monthly on the ${date.format('Do')}`;
  }

  get displayDiscoverFormItems() {
    return (
      this.availableProductsList.includes(ProductIds.DISCOVER) &&
      !!this.plans &&
      !!this.discoverSeatOptions.length &&
      (this.applicationOptions.find((option) => option.id === ProductIds.DISCOVER)?.isChecked ?? false)
    );
  }

  get displayCreateFormItems() {
    return (
      this.availableProductsList.includes(ProductIds.CREATE_AND_PUBLISH) &&
      !!this.plans &&
      !!this.createSeatOptions.length &&
      (this.applicationOptions.find((option) => option.id === ProductIds.CREATE_AND_PUBLISH)?.isChecked ?? false)
    );
  }

  get availableProductsList() {
    const discoverOption = this.features.includes('discover') ? [ProductIds.DISCOVER] : [];
    const createOption = this.features.includes('create-and-publish') ? [ProductIds.CREATE_AND_PUBLISH] : [];

    return [ProductIds.AUDIENCE, ...discoverOption, ...createOption];
  }
  get selectedAppOptions() {
    return this.applicationOptions.filter((option) => option.isChecked);
  }
  get selectedUserCounts() {
    const { audienceSeatQuantity, discoverSeatQuantity, createSeatQuantity, selectedProductKey: pk } = this;
    const includeDiscover = [ProductKey.DISCOVER, ProductKey.SUITE].includes(pk);
    const includeCreate = [ProductKey.CREATE_AND_PUBLISH, ProductKey.SUITE].includes(pk);

    return {
      audience: Number(audienceSeatQuantity) || 0,
      discover: includeDiscover ? Number(discoverSeatQuantity) || 0 : 0,
      create: includeCreate ? Number(createSeatQuantity) || 0 : 0,
    };
  }
  get selectedBrandCounts() {
    const { brandsManagedCount, selectedProductKey: pk } = this;
    const includeDiscover = [ProductKey.DISCOVER, ProductKey.SUITE].includes(pk);
    const includeCreate = [ProductKey.CREATE_AND_PUBLISH, ProductKey.SUITE].includes(pk);
    const isMultiBrandSelected = this.multiBrandToggle === 'yes';

    const numBrands = isMultiBrandSelected ? brandsManagedCount : '1';

    return {
      audience: Number(numBrands) || 0,
      discover: includeDiscover ? Number(numBrands) || 0 : 0,
      create: includeCreate ? Number(numBrands) || 0 : 0,
    };
  }
  get isSuiteSelected() {
    const platformIds = this.selectedAppOptions.map(({ id }) => id);
    const isDiscoverSelected = platformIds.includes(ProductIds.DISCOVER);
    const isCreateSelected = platformIds.includes(ProductIds.CREATE_AND_PUBLISH);

    return isDiscoverSelected && isCreateSelected;
  }

  get selectedProductKey() {
    if (this.isSuiteSelected) return ProductKey.SUITE;

    const selectedApps = this.selectedAppOptions
      .filter((option) => option.value !== ProductKey.AUDIENCE)
      .map((option) => option.value) as ProductKey[];

    if (selectedApps.includes(ProductKey.DISCOVER)) return ProductKey.DISCOVER;
    if (selectedApps.includes(ProductKey.CREATE_AND_PUBLISH)) return ProductKey.CREATE_AND_PUBLISH;
    return ProductKey.AUDIENCE;
  }

  get productHandle(): ProductHandle {
    if (this.isSuiteSelected) {
      return ProductHandle.SUITE_MONTHLY;
    }

    const productKey = this.selectedProductKey;

    const isDiscover = productKey === ProductKey.DISCOVER;
    const isCreate = productKey === ProductKey.CREATE_AND_PUBLISH;

    if (isDiscover) {
      return ProductHandle.DISCOVER_MONTHLY;
    }
    if (isCreate) {
      return ProductHandle.CREATE_MONTHLY;
    }

    return ProductHandle.AUDIENCE;
  }

  get audienceSeatOptions() {
    const generatedOptions = new Array(10).fill(undefined).map((_, idx) => ({
      label: ((idx + 1) * 3).toString(),
      value: ((idx + 1) * 3).toString(),
    }));
    const initialOptions = [
      { label: '1', value: '1' },
      { label: '2', value: '2' },
    ];
    return [...initialOptions, ...generatedOptions];
  }
  get discoverSeatOptions() {
    if (!this.plans) return [];

    const productKey = this.isSuiteSelected ? ProductKey.SUITE : ProductKey.DISCOVER;
    const productHandle = this.isSuiteSelected ? ProductHandle.SUITE_MONTHLY : ProductHandle.DISCOVER_MONTHLY;
    const pricePointHandle = getPricePointHandle(productHandle) as PricePointHandle;

    const pricePoint = getPricePointByHandles(this.plans, productKey, ComponentHandle.DISCOVER_SEATS, pricePointHandle);

    return (
      pricePoint?.prices.map((price) => ({
        label: price.endingQuantity?.toString() ?? '1',
        value: price.endingQuantity?.toString() ?? '1',
      })) ?? []
    );
  }
  get createSeatOptions() {
    if (!this.plans) return [];
    const productKey = this.isSuiteSelected ? ProductKey.SUITE : ProductKey.CREATE_AND_PUBLISH;
    const productHandle = this.isSuiteSelected ? ProductHandle.SUITE_MONTHLY : ProductHandle.CREATE_MONTHLY;
    const pricePointHandle = getPricePointHandle(productHandle) as PricePointHandle;

    const pricePoint = getPricePointByHandles(this.plans, productKey, ComponentHandle.CREATE_SEATS, pricePointHandle);

    return (
      pricePoint?.prices.map((price) => ({
        label: price.endingQuantity?.toString() ?? '1',
        value: price.endingQuantity?.toString() ?? '1',
      })) ?? []
    );
  }

  get selectedProductImageSrc() {
    switch (this.selectedProductKey) {
      case ProductKey.AUDIENCE:
        return productMap.find((product) => product.id === ProductIds.AUDIENCE)?.logoAppCard;
      case ProductKey.DISCOVER:
        return productMap.find((product) => product.id === ProductIds.DISCOVER)?.logoAppCard;
      case ProductKey.CREATE_AND_PUBLISH:
        return productMap.find((product) => product.id === ProductIds.CREATE_AND_PUBLISH)?.logoAppCard;
      case ProductKey.SUITE:
        return 'FuturiCC.svg';
      default:
        return '';
    }
  }

  get pricingTierText() {
    if (this.multiBrandToggle === 'yes') {
      return 'Enterprise Pricing';
    }
    return 'Content Creator Pricing';
  }

  get updatedSubscriptionData(): SubscriptionData {
    const { selectedUserCounts, selectedBrandCounts, selectedProductKey: productKey, productHandle } = this;

    const {
      audience: audienceSeatQuantity,
      discover: discoverSeatQuantity,
      create: createSeatQuantity,
    } = selectedUserCounts;

    const {
      audience: audienceBrandQuantity,
      discover: discoverBrandQuantity,
      create: createBrandQuantity,
    } = selectedBrandCounts;

    return {
      productKey,
      productHandle,
      audienceSeatQuantity,
      discoverSeatQuantity,
      createSeatQuantity,
      audienceBrandQuantity,
      discoverBrandQuantity,
      createBrandQuantity,
    };
  }

  get updatedPrice() {
    return this.calculateTotalCost(this.updatedSubscriptionData);
  }

  calculateTotalCost(data: SubscriptionData): string {
    if (!this.plans) return '';
    return getTotalPrice(this.plans, data);
  }

  get subscriptionPayload() {
    if (!this.plans) return null;
    return getSubscriptionPayload(this.plans, this.updatedSubscriptionData);
  }

  newSubData;
  async onUpgrade() {
    if (this.isUpgradeButtonLoading) return;

    this.isUpgradeButtonLoading = true;
    const { currentSubscriptionId: subscriptionId, subscriptionPayload: subscriptionData } = this;
    try {
      this.newSubData = await this.updateSubscription({ subscriptionId, subscriptionData });
    } catch (e) {
      // TODO: Show error banner
      console.error(e);
    } finally {
      this.isUpgradeButtonLoading = false;
    }
  }

  setToCurrentPlan() {
    const isDiscoverChecked = ['discover', 'suite'].includes(this.currentSubscriptionData.productKey);
    const isCnpChecked = ['create', 'suite'].includes(this.currentSubscriptionData.productKey);
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    this.applicationOptions.find(({ id }) => id === ProductIds.DISCOVER)!.isChecked = isDiscoverChecked;
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    this.applicationOptions.find(({ id }) => id === ProductIds.CREATE_AND_PUBLISH)!.isChecked = isCnpChecked;

    const {
      audienceSeatQuantity,
      discoverSeatQuantity,
      createSeatQuantity,
      audienceBrandQuantity,
      discoverBrandQuantity,
      createBrandQuantity,
    } = this.currentSubscriptionData;
    const brandsManagedCount = Math.max(audienceBrandQuantity, discoverBrandQuantity, createBrandQuantity);

    const multiBrandToggle = brandsManagedCount > 1 ? 'yes' : 'no';

    this.audienceSeatQuantity = audienceSeatQuantity.toString();
    this.discoverSeatQuantity = isDiscoverChecked ? discoverSeatQuantity.toString() : '1';
    this.createSeatQuantity = isCnpChecked ? createSeatQuantity.toString() : '1';
    this.brandsManagedCount = brandsManagedCount.toString();
    this.multiBrandToggle = multiBrandToggle;
  }

  pluralize(count, singular) {
    return maybePluralize(parseInt(count), singular);
  }

  @Watch('paymentToggle')
  onPaymentToggleChange() {
    this.resetSeatCounts();
  }

  @Watch('selectedAppOptions')
  onSelectedAppOptionsChange() {
    this.resetSeatCounts();
  }

  @Watch('discoverSeatOptions')
  onDiscoverSeatOptionsChange() {
    this.resetSeatCounts();
  }

  @Watch('createSeatOptions')
  onCreateSeatOptionsChange() {
    this.resetSeatCounts();
  }

  async resetSeatCounts() {
    await this.$nextTick();
    if (this.discoverSeatOptions.length) {
      if (!this.discoverSeatOptions.find((option) => option.value === this.discoverSeatQuantity)) {
        this.discoverSeatQuantity = this.discoverSeatOptions[0]?.value ?? '1';
      }
    }
    if (this.createSeatOptions.length) {
      if (!this.createSeatOptions.find((option) => option.value === this.createSeatQuantity)) {
        this.createSeatQuantity = this.createSeatOptions[0]?.value ?? '1';
      }
    }
  }

  async mounted() {
    try {
      if (!this.plans) {
        this.isLoading = true;
        await this.getPlans();
      }
      await this.$nextTick();
      this.setToCurrentPlan();
    } catch (e) {
      console.error(e);
    } finally {
      this.isLoading = false;
    }
  }
}
