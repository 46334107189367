














import { Component, Prop, Vue } from 'vue-property-decorator';
import CreateDashboard from '@/createandpublish/Dashboard.vue';
import Podcasts from '@/createandpublish/Podcasts.vue';
import EditShowRoute from '@/createandpublish/views/new-show/EditShowRoute.vue';
import Breadcrumb from '@/components/common/navigation/breadcrumb/Breadcrumb.vue';
import MessageBar from '@/createandpublish/components/MessageBar.vue';

import { Action, Getter } from 'vuex-class';

@Component({
  components: {
    CreateDashboard,
    Podcasts,
    EditShowRoute,
    Breadcrumb,
    MessageBar,
  },
})
export default class CreateAndPublish extends Vue {
  @Action toggleSidebarStatus;
  @Action toggleWelcomeBanner;
  @Getter selectedBrand;
  @Prop({ required: false, default: 'sidebar' }) private id?: boolean;

  // @TODO If adding more breadcrumbs to routes in C&P, logic should be reworked
  get breadcrumbItems(): Array<Record<string, unknown>> {
    let items;

    switch (true) {
      // New show
      case this.$route.path.includes(`/podcasts/shows/edit/new`):
        items = this.createShowBreadcrumbItems;
        break;

      // Edit show
      case this.$route.path.includes(`/podcasts/shows/edit/${this.$route.params.showId}`):
        items = this.editShowBreadcrumbItems;
        break;

      // Show details
      case this.$route.path.includes(`/podcasts/shows/${this.$route.params.showId}/details`):
        items = this.showDetailsBreadcrumbItems;
        break;

      // Social Post Analytics (eclincher)
      case this.$route.path.includes(`social-post-analytics`):
        items = this.socialPostAnalyticsBreadcrumbItems;
        break;

      // Episode edit flow
      case this.$route.path.includes(`episodes/${this.$route.params.episodeId}/edit`):
        items = this.editEpisodeBreadcrumbItems;
        break;
    }

    return items;
  }

  get createShowBreadcrumbItems(): Array<Record<string, unknown>> {
    return [
      { text: 'Podcasts', to: { name: 'Create.Podcasts' } },
      { text: 'Create new show', active: true },
    ];
  }

  get editShowBreadcrumbItems(): Array<Record<string, unknown>> {
    return [
      { text: 'Podcasts', to: { name: 'Create.Podcasts' } },
      { text: this.showName, to: { name: `show-detail` } },
      { text: 'Edit', active: true },
    ];
  }

  get showDetailsBreadcrumbItems(): Array<Record<string, unknown>> {
    return [
      { text: 'Podcasts', to: { name: 'Create.Podcasts' } },
      { text: this.showName, active: true },
    ];
  }

  get editEpisodeBreadcrumbItems(): Array<Record<string, unknown>> {
    if (this.$route.params.episodeId === '0') {
      return [
        { text: 'Podcasts', to: { name: 'Create.Podcasts' } },
        { text: this.showName, to: { name: `show-detail` } },
        { text: 'Create new episode', active: true },
      ];
    }

    return [
      { text: 'Podcasts', to: { name: 'Create.Podcasts' } },
      { text: this.showName, to: { name: `show-detail` } },
      { text: this.episodeName, to: { name: 'edit-episode-information' } },
    ];
  }

  // Breadcrumb items for Social Post Analytics (eclincher)
  get socialPostAnalyticsBreadcrumbItems(): Array<Record<string, unknown>> {
    return [
      { text: 'Analytics', to: { name: 'Analytics' } },
      { text: 'Social post analytics', active: true },
    ];
  }

  get showName(): string | null {
    const showId = this.$route.params.showId;
    if (!showId) return null;
    const unfilteredShows = this.$root.$store.getters['CreateAndPublishStore/unfilteredShows'];
    const show = unfilteredShows.find((s) => s.id == showId);
    return show?.title;
  }

  get episodeName(): string | null {
    const currentDraftEpisode = this.$root.$store.getters['CreateAndPublishStore/draftEpisodes/currentDraftEpisode'];
    return currentDraftEpisode.name;
  }

  get breadcrumbType(): string {
    return this.$route.path.includes('/shows/edit') ? 'CreateShowBreadcrumb' : 'ShowDetailsBreadcrumb';
  }

  get shouldShowBreadcrumbs(): boolean {
    return (
      this.$route.path.includes(`/create/${this.selectedBrand.id}/podcasts/shows/edit/${this.$route.params.showId}`) ||
      this.$route.path.includes(
        `/create/${this.selectedBrand.id}/podcasts/shows/${this.$route.params.showId}/details`
      ) ||
      this.$route.path.includes('/social-post-analytics') ||
      this.$route.path.includes(`episodes/${this.$route.params.episodeId}/edit`)
    );
  }

  mounted() {
    this.toggleWelcomeBanner(true);
  }
}
