import { helpers } from 'vuelidate/lib/validators';
import errorMessages from '@/utils/errorMessages.json';

function isAToZChar(c: string): boolean {
  return c.toLowerCase().match(/[a-z]/) != null;
}

function isHyphen(c: string): boolean {
  return c === '-';
}

function isSpace(c: string): boolean {
  return c === ' ';
}

function isApostrophe(c: string): boolean {
  return c === "'";
}

function characters(str: string): string[] {
  return str.trim().split('');
}

export const noSpecialCharacters = (inputtxt: string): boolean => {
  return characters(inputtxt).every(
    (char) => isAToZChar(char) || isHyphen(char) || isSpace(char) || isApostrophe(char)
  );
};

//matches backend email validation
export const validateEmail = (inputtxt: string): boolean => {
  return (
    inputtxt.match(/^(?!.{101})([a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+)@([a-zA-Z0-9_\-.]+)\.(?:[a-zA-Z0-9-]{2,5})$/) != null
  );
};

export const alphaNumericOnly = (inputtxt) => {
  if (!inputtxt) {
    return false;
  }
  return inputtxt.match(/^[A-Za-z0-9'-( )]+$/) != null;
};

export const phone = helpers.regex('alpha', /[0-9 -]{12}/);

export const validateName = (name: string) => {
  // const validRegex = /^[A-Z][a-z]{2,19}$/; // old regex
  const validRegex = /^[A-Za-z \-']*$/;
  if (name && name.match(validRegex) && name.length >= 3 && name.length <= 20) {
    // my regex doesn't eval length properly
    return true;
  }
  return false;
};

export const validUrl = (inputtxt) => {
  // Validates URL
  if (inputtxt) {
    return (
      inputtxt
        .toLowerCase()
        .match(/(http(s)?:\/\/.)(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/) != null
    );
  }
};

// Validate password
export const validatePassword = (value: string) => {
  return (
    // These symbols should be escaped
    //eslint-disable-next-line
    value?.match(/(?=.*[a-z])(?=.*[A-Z])(?=.*[\^\$\*\.\[\]\{\}\(\)\?\"\!\@\#\%\&\/\\\,\>\<\'\:\;\|\_\~\`]).{10,}$/) ||
    false
  );
};

// Validate brand name
export const validateBrandName = (value) => {
  const pattern = new RegExp('^[A-Za-z0-9 !@#$%^&*?()\\s\\-]*$');
  return value && pattern.test(value);
};

// Validate campaign name
export const validateCampaignName = (value) => {
  const pattern = new RegExp('^[A-Za-z0-9 !@#$%^&*?()\\s\\-]*$');
  return value && pattern.test(value);
};

// Validate profile name
export const validateProfileName = (value) => {
  const pattern = new RegExp('^[A-Za-z0-9 !@#$%^&*?()\\s\\-]{3,25}$');
  return value && pattern.test(value);
};

/**
 * Given a campaign name, this function validates the campaign name and returns an error message.
 *
 * @param campaignName
 * @returns
 */
export const getCampaignNameValidationErrorMessage = (campaignName: string): string => {
  if (!campaignName || campaignName.trim().length === 0) {
    return `Campaign name${errorMessages.required}`;
  }

  if (!validateCampaignName(campaignName)) {
    return errorMessages.invalidCampaignName;
  }

  return '';
};
