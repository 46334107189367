<template>
  <!-- <div id="wrapper"> -->
  <div id="wrapper" :class="wrapperClass">
    <div class="sidebar-wrapper" v-if="isAudienceFlow">
      <AudienceSidebar />
    </div>
    <div class="form">
      <!-- Header -->
      <div class="form-header">
        <router-link to="/">
          <img alt="logo" src="@/assets/FuturiCC.svg" height="58" />
        </router-link>
      </div>
      <!-- Success message -->
      <div class="form-content" v-if="showSuccessMessage">
        <AlertDisplay :alertMessage="alertMessageSuccess" class="alertMessage" :drawer="true" />
        <p>
          Didn’t receive it? <fm-link @click="onResendLink">Resend the link</fm-link> or
          <fm-link @click="onCancel">return to sign in</fm-link>.
        </p>
      </div>
      <!-- Content -->
      <div class="form-content" v-else>
        <!-- Error message -->
        <AlertDisplay :alertMessage="alertMessageError" class="alertMessage" :drawer="true" v-if="showErrorMessage" />
        <div class="form-title">Forgot your password?</div>
        <div class="form-description">
          Enter your email address below and we’ll email you a link to reset your password.
        </div>
        <form v-on:submit.prevent="$emit('onSubmit')">
          <Input
            :data-qa="$getComponent('Login', 'TextInput', 'emailAddress')"
            :inputHandler="handleInputChange"
            :value="form.emailAddress"
            class="form-input"
            label="Email address"
            name="emailAddress"
            placeholder="name@example.com"
            :error="
              $v.form.emailAddress.$error
                ? !$v.form.emailAddress.email
                  ? errorMessages.invalidEmail
                  : 'Email address' + errorMessages.required
                : ''
            "
          />
          <Button
            :data-qa="$getComponent('Login', 'Button', 'submit')"
            :loading="formIsLoading"
            @click="onSubmit"
            buttonType="primary"
            class="form-submit mb-2"
            onClickEventHandler="click"
            type="submit"
          >
            Send link
          </Button>
        </form>
        <fm-link @click="onCancel" :data-qa="$getComponent('Login', 'Button', 'submit')" class="form-cancel">
          Cancel
        </fm-link>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { required } from 'vuelidate/lib/validators';
import { validateEmail as $valEmail } from '@/plugins/Validations';
import AlertDisplay from '@/components/common/AlertDisplay.vue';
import AudienceSidebar from '@/components/ecomm/AudienceSidebar.vue';
import Button from '@/components/common/buttons/Button.vue';
import Input from '@/components/common/form/Input.vue';
import errorMessages from '@/utils/errorMessages.json';
import UserService from '@/services/UserService';

export default Vue.extend({
  name: 'login',

  metaInfo: {
    htmlAttrs: { class: 'app-responsive' },
    meta: [{ name: 'viewport', content: `width=device-width, initial-scale=1` }],
  },

  components: {
    AlertDisplay,
    AudienceSidebar,
    Button,
    Input,
  },

  data() {
    return {
      // Banner for API request success
      alertMessageSuccess: {
        header: 'Reset password link sent',
        type: 'success',
        message: 'Please check your inbox. We’ve sent a reset password link to the email address you’ve provided.',
      },
      // Banner for API request errors
      alertMessageError: {
        header: 'Opps! Something went wrong.',
        type: 'critical',
        message: 'Please try again in a few minutes.',
      },
      errorMessages, // Input error messages
      flow: 'default',
      form: {
        emailAddress: '',
      },
      // If submitting form
      formIsLoading: false,
      // Flags to display error/success banners
      showSuccessMessage: false,
      showErrorMessage: false,
    };
  },

  validations: {
    form: {
      emailAddress: { required, $valEmail },
    },
  },

  computed: {
    isAudienceFlow() {
      return this.flow === 'audience';
    },
    wrapperClass() {
      return this.flow === 'default' ? 'wrapper' : `wrapper-${this.flow}`;
    },
  },

  methods: {
    handleInputChange(e) {
      this.form[e.target.name] = e.target.value;
    },

    onCancel() {
      if (this.isAudienceFlow) {
        this.$router.push({ path: '/audience/login' });
      } else {
        this.$router.push({ path: '/login' });
      }
    },

    onResendLink() {
      this.showSuccessMessage = false;
      this.showErrorMessage = false;
    },

    async onSubmit() {
      // Touch all fields
      this.$v.form.$touch();
      // if form's validated
      if (!this.$v.form.$invalid) {
        // submitting
        this.formIsLoading = true;
        // reset error/success banners
        this.showErrorMessage = false;
        this.showSuccessMessage = false;
        // request password reset
        const res = await new UserService().forgotPasswordReset(this.form.emailAddress);
        // if not successful
        if (res.errors) {
          this.showErrorMessage = true;
        } else {
          // if successful
          this.showSuccessMessage = true;
        }
        // done submitting
        this.formIsLoading = false;
      }
    },
  },

  mounted() {
    if (this.$route.query && this.$route.query.flow) {
      this.flow = this.$route.query.flow;
    }
  },
});
</script>

<style lang="scss" scoped>
#wrapper {
  display: flex;
  height: 100vh;

  //styles shared between flow
  .form-title {
    color: var(--neutral-grey-1-text);
    font-family: Roboto;
    font-size: 30px;
    font-style: normal;
    font-weight: 500;
    line-height: 38px;
    margin-bottom: 16px;
  }

  .form-description {
    color: var(--neutral-grey-1-text);
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: normal;
    line-height: 24px;
    margin-bottom: 32px;
  }

  .form-cancel {
    margin-top: 10px;
    text-align: center;
    width: 100%;
  }

  .form-input {
    margin-bottom: 32px;
  }
}

//default flow
.wrapper {
  align-items: flex-start;
  background: linear-gradient(164.21deg, var(--accent-links) 7.88%, #015498 92.07%);
  justify-content: center;
  padding: 64px;
  width: 100vw;

  .alertMessage {
    margin-bottom: 24px;
  }

  .form {
    background: var(--neutral-white);
    box-shadow: 0px 1px 7px rgba(34, 32, 70, 0.2);
    width: 536px;
  }

  .form-header {
    align-items: center;
    background: #f5f5f5;
    display: flex;
    height: 80px;
    justify-content: center;
  }

  .form-content {
    font-family: Roboto;
    padding: 24px;
    p {
      margin-bottom: 0;
      position: relative;
      top: -5px;
    }
  }

  .form-submit {
    width: 100%;
  }
}

//audience flow
.wrapper-audience {
  flex-direction: row;

  .form {
    background: var(--neutral-white);
    align-items: center;
    display: flex;
    flex-direction: column;
    padding: 68px;
    width: 50%;

    @media (max-width: 768px) {
      padding: 32px 24px;
      width: 100%;
    }
  }

  .sidebar-wrapper {
    background: var(--accent-background);
    height: 100%;
    order: 1;
    width: 50%;

    @media (max-width: 768px) {
      height: auto;
      order: 0;
      width: 100%;
    }
  }

  .form-header {
    margin-bottom: 64px;
    max-width: 592px;
    width: 100%;

    @media (max-width: 768px) {
      display: none;
    }
  }

  .form-content {
    max-width: 592px;
    margin: 0 auto;
    position: static;

    @media (max-width: 768px) {
      padding: 48px 24px 24px;
      width: 100%;
    }

    @media (max-width: 1400px) {
      width: 100%;
    }

    @media (max-width: 1024px) {
      max-width: 100%;
      width: 100%;
    }
  }

  .form-title {
    font-size: var(--fm-font-size-24);
    line-height: var(--fm-line-spacing-32);
    margin-bottom: 24px;
  }

  .alertMessage {
    margin: 0 15px 32px;
    width: 100%;
  }

  .custom-button {
    margin-bottom: 8px;
    width: 100%;
  }

  @media (max-width: 768px) {
    flex-direction: column;
  }
}
</style>
