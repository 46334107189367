import { Feature } from '@/types/features/FEFeature';
import { Product } from '@/types/Product';
import { getProductFeatureNameById } from './getProductFeatureNameById';

/**
 *
 * @param products List of products with an ID
 * @param features List of features from BE
 * @returns List of products that intersect with enabled products in `RootStore.features`
 */
export const removeDisabledProducts = (products: Product[] = [], features: Feature[] = []) => {
  return products.filter((product) => {
    const productFeatureName = getProductFeatureNameById(product.id);
    const productIsEnabled = features.some((feature) => feature === productFeatureName);
    return productIsEnabled;
  });
};
