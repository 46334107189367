<template>
  <Drawer
    :title="isCreateMode ? 'Add campaign' : 'Edit campaign'"
    :qa="$getComponent('AddEditCampaigns', 'Drawer', 'addEditCampaigns')"
    id="add-campaign-drawer"
    @cancel="onCancel"
    :submit="submit"
    :key="currentCampaign.id"
    :vh100="true"
    :loading="isLoading"
  >
    <div slot="content">
      <div class="drawer-div">
        <AlertDisplay :alertMessage="alertMessage" class="alertMessage" v-if="displayAlert" :drawer="true" />
        <b-form-group class="drawer-group">
          <Input
            :data-qa="$getComponent('AddEditCampaigns', 'TextInput', 'campaignName')"
            :inputHandler="handleInputChange"
            :value="currentCampaign.name"
            @keyup.native.enter="submit.onClick"
            label="Campaign name"
            name="campaignName"
            type="text"
            :maxLength="32"
            :showWordCount="true"
            :error="campaignNameErrorMessage.message"
          />
        </b-form-group>
        <b-form-group class="drawer-group">
          <h6 style="font-weight: 700">Campaign color</h6>
          <CampaignsColorPicker
            :data-qa="$getComponent('AddEditCampaigns', 'Select', 'colorInput')"
            :defaultColor="currentCampaign.color"
            @updateColor="setSelectedColor"
            class="colorPicker"
          />
        </b-form-group>
      </div>
    </div>
  </Drawer>
</template>

<script>
import { Vue } from 'vue-property-decorator';
import { mapActions, mapGetters } from 'vuex';
import CampaignsColorPicker from '@/components/common/form/CampaignsColorPicker.vue';
import Drawer from '@/components/common/drawer/Drawer.vue';
import AlertDisplay from '../../common/AlertDisplay.vue';
import BrandService from '@/services/BrandService';
import EventHub from '@/components/discover/EventHub.vue';
import { getCampaignNameValidationErrorMessage } from '@/plugins/Validations';
import Input from '@/components/common/form/Input.vue';

export default {
  name: 'add-campaign-drawer',
  components: {
    Drawer,
    AlertDisplay,
    CampaignsColorPicker,
    Input,
  },

  data() {
    return {
      currentCampaign: {
        active: this.campaignData.active,
        brandId: this.campaignData.brandId,
        color: this.campaignData.color,
        id: this.campaignData.id,
        name: this.campaignData.name,
      },
      isLoading: false,
      campaignNameErrorMessage: { message: '' },
      alertMessage: {},
    };
  },

  computed: {
    ...mapGetters(['selectedBrand']),
    isCreateMode() {
      return !this.campaignData.id;
    },

    submit() {
      if (this.currentCampaign.name?.length >= 32) {
        return;
      }
      return {
        qa: Vue.prototype.$getComponent('AddEditUser', 'Button', 'createCampaign&SaveChanges'),
        buttonText: this.isCreateMode ? 'Create campaign' : 'Save changes',
        onClick: (hide) => this.saveBrandData(hide),
      };
    },

    displayAlert() {
      return !!this.alertMessage.type;
    },
  },

  methods: {
    ...mapActions(['setInitialSelectedBrand']),
    handleInputChange(event) {
      this.campaignNameErrorMessage = { message: '' };
      this.currentCampaign.name = event.target.value;
    },
    setSelectedColor(color) {
      this.currentCampaign.color = color;
    },

    resetDrawerData() {
      this.currentCampaign = {
        brandId: this.campaignData.brandId,
        active: true,
        color: '#EE4833',
        name: '',
        id: 0,
      };
      this.isLoading = false;
      this.campaignNameErrorMessage = { message: '' };
      this.alertMessage = {};
    },

    async saveBrandData(hide) {
      const campaignNameErrorMessage = getCampaignNameValidationErrorMessage(this.currentCampaign.name);
      if (campaignNameErrorMessage) {
        this.campaignNameErrorMessage = {
          message: campaignNameErrorMessage,
        };
        return;
      }

      this.isLoading = true;

      let response = {};

      const payload = {
        ...this.currentCampaign,
        name: this.currentCampaign.name.trim(),
      };

      if (this.currentCampaign.id) {
        // Save
        response = await new BrandService()
          .editCampaign(payload)
          .then((res) => res)
          .catch((err) => err.response);
      } else {
        // Create

        response = await new BrandService()
          .createCampaign(payload)
          .then((res) => res)
          .catch((err) => err.response);
      }

      // BE validates campaign name is unique
      if (!response.id) {
        const errors = response.errors;
        this.alertMessage = {
          type: 'critical',
          header: "We couldn't save your changes.",
          message: 'Please correct the errors below and submit the form again.',
          links: errors.map((err) => ({ message: Vue.prototype.$errorPrettified(err) })),
        };

        setTimeout(() => {
          this.isLoading = false;
        }, 500);
        return;
      } else {
        this.isLoading = false;
        //update authenticated user and selectedBrand data
        await this.$store.dispatch('currentUser/requestUser');
        await this.setInitialSelectedBrand(this.selectedBrand.id);
        this.tagStoryToCampaign(response, this.$props.storyData);
        this.onCancel(hide);
      }
    },

    //tag stories to campaign
    async tagStoryToCampaign(campaignData, storyData) {
      this.getCampaignData();
      //push the new campaign into campaigns for story id
      this.$set(this.$data.emitCampaigns, this.$data.emitCampaigns?.length, campaignData);

      const updateStory = {
        storyId: storyData.storyId,
        campaigns: this.selectedBrand.campaigns,
        campaign_ids: this.parseIds(this.$data.emitCampaigns),
        legacy_campaign_ids: this.$props.storyData.campaignIds,
        updateStoryTags: false,
        newCampaign: true,
      };

      if (storyData.dataType === 'trending') {
        EventHub.$emit('tagTrendingStory', updateStory);
      } else if (storyData.dataType === 'idea') {
        EventHub.$emit('tagIdeaFromCampaignDrawer', updateStory);
      } else {
        EventHub.$emit('tagRSSFromCampaignDrawer', updateStory);
      }
    },

    getCampaignData() {
      this.$data.emitCampaigns = [];
      if (this.$props.storyData.campaignIds) {
        this.$props.storyData.campaignIds.map((campaign) => {
          this.selectedBrand.campaigns.map((available) => {
            if (!available.checked) {
              if (campaign === available.id) {
                this.$data.emitCampaigns.push(available);
              }
            }
          });
        });
      }
    },

    parseIds(campaigns) {
      return campaigns.map((_) => _.id);
    },

    onCancel(hide) {
      this.resetDrawerData();
      this.$emit('cancel');
      hide();
    },
  },
  props: {
    campaignData: {
      type: Object,
      default: () => {
        return {
          brandId: 0,
          active: true,
          color: '#EE4833',
          name: '',
          id: 0,
        };
      },
    },
    visisble: {
      type: Boolean,
      default: false,
    },
    storyData: {},
    fetchBrands: Function,
  },
  watch: {
    campaignData: {
      handler() {
        this.currentCampaign = {
          active: this.campaignData.active,
          brandId: this.campaignData.brandId,
          color: this.campaignData.color,
          id: this.campaignData.id,
          name: this.campaignData.name,
        };

        this.campaignNameErrorMessage = { message: '' };
        this.alertMessage = {};
      },
    },
  },
};
</script>

<style scoped lang="scss">
.drawer-group {
  // border: solid red 2px;
}

.alertMessage {
  margin-bottom: 2rem;
}

.colorPicker {
  margin-left: -50px;
  margin-top: -20px;
}
.inlineErrorMessage {
  padding: 5px 0px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  display: flex;
  color: var(--brand-topicpulse---dark);
  align-items: center;
  margin-top: -20px;
  .icon {
    color: var(--brand-topicpulse---dark);
    padding: 2px;
    margin-right: 5px;
  }
}
</style>
