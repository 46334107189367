import store from '../../store';

export const basicVideoStatusUpdate = (msgServer) => {
  msgServer.subscribe('basic_video_status', ({ playlist_id, video_status }) => {
    store.commit('CreateAndPublishStore/SET_VIDEO_STATUS', {
      playlistId: playlist_id,
      video_status: video_status,
    });
  });
};

export const advancedVideoStatusUpdate = (msgServer) => {
  msgServer.subscribe('advanced_video_status', ({ playlist_id, video_status }) => {
    store.commit('CreateAndPublishStore/SET_VIDEO_STATUS', {
      playlistId: playlist_id,
      advanced_video_status: video_status,
    });

    if (video_status === 2) {
      store.commit('CreateAndPublishStore/UPDATE_ADVANCED_VIDEO_STATUS', { playlist_id, isCreated: true });
    } else if (video_status === 99) {
      store.commit('CreateAndPublishStore/UPDATE_ADVANCED_VIDEO_STATUS', { playlist_id, isFailed: true });
    }
  });
};

export const playlistComplete = (msgServer) => {
  msgServer.subscribe('playlist_complete', (data) => {
    console.log(data);
  });
};

export const uploadProgressChange = (msgServer) => {
  msgServer.subscribe('upload_progress_change', (data) => {
    console.log(data);
  });
};

export const advancedVideoEditUrl = (msgServer) => {
  msgServer.subscribe('advanced_video_edit_url', ({ playlist_id, url }) => {
    store.commit('CreateAndPublishStore/SET_ADVANCED_VIDEO_EDIT_URL', { playlist_id, url });
  });
};

export const downloadPlaylist = (msgServer) => {
  msgServer.subscribe('playlist_download_ready', ({ playlist_id, urls }) => {
    store.dispatch('CreateAndPublishStore/downloadPlaylist', { playlist_id, urls });
  });
};

export const analyticsCsvGenerationComplete = (msgServer) => {
  msgServer.subscribe('csv_analytics_complete', ({ job_id, status }) => {
    if (status === 'error') {
      store.dispatch('CreateAndPublishStore/analytics/showCsvError', job_id);
    }
    if (status === 'completed') {
      store.dispatch('CreateAndPublishStore/analytics/downloadCsvByJobId', job_id);
    }
  });
};

export const iHeartIngestionStatus = (msgServer) => {
  msgServer.subscribe('iheart_ingestion_status', ({ playlist_id, iheart_status, url }) => {
    // we delay this because the call to get_playlists.json is often pending when this event comes through
    setTimeout(() => {
      store.commit('CreateAndPublishStore/UPDATE_IHEART_INGESTION_STATUS', {
        playlist_id,
        iheart_status,
        external_url: url || '',
      });
    }, 5000);
  });
};

export const eventUploadProgress = (msgServer) => {
  ['upload_progress', 'encode_progress', 'upload_error', 'worker_encode', 'upload_complete'].forEach((eventName) => {
    msgServer.subscribe(eventName, (progressEvent) => {
      store.commit('CreateAndPublishStore/mediaUploads/UPDATE_MEDIA_UPLOAD', progressEvent);
    });
  });
};

// Bulk download for stacks in Audio Library
export const bulkDownloadStacks = (msgServer) => {
  msgServer.subscribe('bulk_mp3_zip_complete', (payload) => {
    store.commit('CreateAndPublishStore/BULK_DOWNLOAD_STACKS', { ...payload, status: 'complete' });
  });
};

// Bulk download error for stacks in Audio Library
export const bulkDownloadStacksError = (msgServer) => {
  msgServer.subscribe('bulk_media_zip_failed', (payload) => {
    store.commit('CreateAndPublishStore/BULK_DOWNLOAD_STACKS', { ...payload, status: 'error' });
  });
};
