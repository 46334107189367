<template>
  <div class="shows-container">
    <div class="shows">
      <div class="clear"></div>
      <br />
      <template v-if="hasShows">
        <!-- Search Bar -->
        <div class="shows__search">
          <fm-search-bar @fm-search="onSearchSubmit" @input="onSearchInput" :value="searchFilter" />
        </div>

        <!-- Layout info and toggles -->
        <div class="shows__info-toggles">
          <div class="shows__number-of-shows">{{ filteredShows.length }} shows</div>
          <div style="display: flex">
            <div class="shows__options">
              <span>Sort by</span>
              <fm-select
                :options.prop="sortOptions"
                :value.prop="currentSort"
                @input="onSortChange"
                style="height: 50px"
              />
            </div>
            <div class="shows__options">
              <fm-button-context :options.prop="layoutOptions" :value="currentLayout" @click="onLayoutChange">
                <span slot="grid" class="button-interior grid" :class="{ selected: currentLayout === 'grid' }">
                  Grid
                </span>
                <span slot="list" class="button-interior list" :class="{ selected: currentLayout === 'list' }">
                  List
                </span>
              </fm-button-context>
            </div>
          </div>
        </div>

        <div class="loader" v-if="isLoading">
          <LoadingSpinner></LoadingSpinner>
          Loading...
        </div>
        <!-- Shows -->
        <Shows
          :shows="paginatedFilteredShows"
          :layout="currentLayout"
          v-if="!isLoading"
          @show-delete="$emit('show-delete', $event)"
        />

        <!-- Pagination -->
        <div class="clear"></div>
        <br />
        <div class="shows__pagination">
          <div class="shows__pagination-showing">
            {{ paginationTextFromTo }}
          </div>
          <fm-pagination
            ref="pagination"
            :totalItems="filteredShows.length"
            @fm-page-update="changePage"
            :perPage="pageSize"
          />
          <div class="shows__pagination-itemsPerPage">
            <div>Items per page</div>
            <fm-select
              :options.prop="pageSizeOptions"
              :value.prop="pageSize"
              @input="onPageSizeChange"
              style="height: 52px; margin-left: 15px"
            />
          </div>
        </div>
      </template>
      <template v-else>
        <div class="empty">
          <h2 class="empty__heading">Nothing to see here</h2>
          <p class="empty__body">You haven't created any shows yet!</p>
          <Button
            class="empty__button"
            button-type="secondary"
            on-click-event-handler="on-click"
            @on-click="goToCreateShow"
          >
            Create your first show
          </Button>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex';
import { sortBy } from 'lodash';

import { Shows } from '../components/showsList';
import LoadingSpinner from '../components/LoadingSpinner.vue';
import Button from '@/components/common/buttons/Button.vue';

export default {
  name: 'shows-list',
  components: {
    Shows,
    LoadingSpinner,
    Button,
  },
  data() {
    return {
      // Sorted configs
      currentLayout: 'grid',
      currentPage: 1,
      currentSort: 'last_playlist_date',
      highlightedShow: 0,
      pageSize: 12,
      searchFilter: '',
      isLoading: false,
      // Unsorted options
      sortOptions: [
        {
          value: 'title',
          label: 'Show title',
        },
        {
          value: 'last_playlist_date',
          label: 'Recently updated',
        },
      ],
      layoutOptions: ['grid', 'list'],
      pageSizeOptions: [
        {
          value: 12,
          label: '12',
        },
        {
          value: 24,
          label: '24',
        },
      ],
    };
  },

  computed: {
    ...mapState(['selectedStationOption']),
    ...mapGetters('CreateAndPublishStore', [
      'station',
      'unfilteredShows',
      'versionInfo',
      'isAvess',
      'showsAvailable',
      'isAdmin',
    ]),
    ...mapGetters(['selectedBrand']),

    start() {
      return (this.currentPage - 1) * this.pageSize;
    },
    end() {
      return this.currentPage * this.pageSize;
    },

    paginationTextFromTo() {
      const currentPage = Number(this.currentPage);
      const pageSize = Number(this.pageSize);
      const _from = this.getPaginationFrom(
        currentPage,
        pageSize,
        (this.filteredShows && this.filteredShows.length) || 0
      );
      const _to = this.getPaginationTo(currentPage, pageSize, (this.filteredShows && this.filteredShows.length) || 0);

      return `Viewing ${_from}-${_to}
            of ${this.filteredShows.length} items`;
    },

    squareImageUrl: () => {
      return '@/assets/createandpublish-assets/shows/post-logo.png';
    },

    canCreateShow() {
      if (this.isAvess) {
        return false;
      }
      return (
        this.selectedStationOption &&
        (this.selectedStationOption.currentUserIsAdmin || this.selectedStationOption.showEditor)
      );
    },

    maxPage() {
      if (!this.isAvess) {
        return Math.ceil(this.filteredShows.length / this.pageSize) || 1;
      }
      return Math.ceil((this.filteredShows.length + this.showsAvailable) / this.pageSize) || 1;
    },

    paginatedFilteredShows() {
      return this.filteredShows.slice(this.start, this.end);
    },

    filteredShows() {
      let _unfilteredShows = [...this.unfilteredShows];

      // Sort
      if (this.currentSort) {
        if (this.currentSort === 'title') {
          _unfilteredShows = sortBy(_unfilteredShows, [(show) => show.title?.toLowerCase()], ['asc']);
        }
        if (this.currentSort === 'last_playlist_date') {
          _unfilteredShows.sort(function compare(a, b) {
            // Handle cases where one or both shows don't have published episodes.
            if (!a.last_playlist_date && !b.last_playlist_date) return 0;
            if (!a.last_playlist_date) return 1;
            if (!b.last_playlist_date) return -1;
            // Handle cases where both shows have published episodes.
            const dateA = new Date(a.last_playlist_date);
            const dateB = new Date(b.last_playlist_date);
            return dateB - dateA;
          });
        }
      }

      // If not admin
      if (!this.isAdmin) {
        // Filter out placeholders for non-admins
        _unfilteredShows = _unfilteredShows.filter((show) => !show.placeholder);
      }
      return _unfilteredShows.filter((show) => {
        const matchesTitle = show.title.toLowerCase().includes(this.searchFilter.toLowerCase());
        const matchesDescription = show.description.toLowerCase().includes(this.searchFilter.toLowerCase());
        return matchesTitle || matchesDescription;
      });
    },

    hasShows() {
      return Array.isArray(this.unfilteredShows) && this.unfilteredShows.length;
    },
  },

  methods: {
    ...mapActions('CreateAndPublishStore', ['fetchAllShowsData']),

    // On sort change
    onSortChange(e) {
      this.currentSort = e.target.value;
    },

    // On layout change
    onLayoutChange() {
      if (this.currentLayout === 'grid') {
        this.currentLayout = 'list';
        localStorage.setItem('show-layout', 'list');
      } else {
        this.currentLayout = 'grid';
        localStorage.setItem('show-layout', 'grid');
      }
    },

    // On pageSize change
    onPageSizeChange(e) {
      this.pageSize = e.target.value;
      this.changePage({ detail: { value: 1 } });
      this.$refs.pagination.currentPage = 1; // need to force prop value to set button active state
    },

    // Get pagination `from` number
    getPaginationFrom(currentPage, pageSize, totalElements) {
      if (currentPage === 1) {
        if (totalElements === 0) return 0;
        return 1;
      }
      return (currentPage - 1) * pageSize + 1;
    },

    // Get pagination `from` number
    getPaginationTo(currentPage, pageSize, totalElements) {
      return Math.min(currentPage * pageSize, totalElements);
    },

    onSearchInput(e) {
      // allow single spaces but not double
      const trimmedResults = e.target.value.replace(/\s{2,}/g, ' ');

      // set the search filter
      this.searchFilter = trimmedResults.trim();
      // Reset page on search
      this.currentPage = 1;
    },

    // Search filter onSubmit
    onSearchSubmit(e) {
      this.searchFilter = e.target.value.trim();
    },

    showEditControls(showId) {
      this.highlightedShow = showId;
    },

    changePage(e) {
      const _page = e.detail && e.detail.value;
      if (!isNaN(_page) && _page <= this.maxPage && _page > 0) {
        this.currentPage = Number(_page);
      }
    },

    handleNewShowClicked() {
      this.$store.commit('showSetup/SET_MAX_STEP_COMPLETED', 0);
      this.$router.push({
        path: '/shows/edit/new',
      });
    },

    handleFooterNavigationClick(path) {
      this.$router.push({
        path,
      });
    },

    goToCreateShow() {
      this.$router.push(`/create/${this.selectedBrand?.id}/podcasts/shows/edit/new`);
    },
  },

  async created() {
    this.currentLayout = localStorage.getItem('show-layout') ? localStorage.getItem('show-layout') : 'grid';
  },
};
</script>

<style lang="scss" scoped>
@import '../styles/shows_list.scss';
// Why is this here? - GK
.loader {
  display: flex;
  flex-flow: column;
  align-items: center;
}

.button-interior {
  font-family: Roboto;
}

.empty {
  padding: 6rem 0 8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  &__heading {
    margin-bottom: 2.125rem;
  }
  &__body {
    margin-bottom: 2.125rem;
  }
  &__button {
    margin: 0 auto;
  }
}
</style>
