



















import Vue from 'vue';

import { Product } from '@/types/Product';
import { mapGetters } from 'vuex';
import { getProductByIdByAttribute, getProductUrlById } from '@/utils/products';

export default Vue.extend({
  name: 'profile-dropdown-product',

  props: {
    id: {
      type: Number as () => Product['id'],
      default: 0,
    },
    name: {
      type: String as () => Product['name'],
      default: '',
    },
  },

  computed: {
    ...mapGetters([
      'getAccount',
      'getAdminEmail',
      'authenticatedUser',
      'selectedBrand',
      'userProducts',
      'isAdmin',
      'userBrands',
    ]),

    mailAdminHref() {
      return `mailto: ${this.getAdminEmail}`;
    },

    noAccessCopy() {
      return 'No Access to ' + this.name;
    },

    // Path to product logo
    logo() {
      return getProductByIdByAttribute(this.id, 'logoHeader');
    },

    /**
     * Drives `locked` product icon. `true` if product is enabled and user has at least 1 brand available.
     * Name has `product` but no `brand` in it because this is driving product items.
     */
    isProductActive() {
      let hasProductAccess = false;
      let hasBrandAccess = false;
      // If user has access to the specific product
      this.userProducts?.forEach((product) => {
        if (product.id === this.id) {
          hasProductAccess = true;
        }
      });
      // If user has access to at least 1 brand
      if (this.userBrands?.length > 0) {
        hasBrandAccess = true;
      }
      return hasProductAccess && hasBrandAccess;
    },

    // Get product route path
    getProductRoute() {
      return `${getProductUrlById(this.id)}/${this.selectedBrand.id}`;
    },
  },
});
