


















import { Component, Vue, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import AudioLibrary from '@/createandpublish/views/audio-library/AudioLibrary.vue';
import AudioStack from '@/createandpublish/components/AudioLibrary/AudioStack.vue';
import AudioPlayerBar from '@/createandpublish/components/AudioLibrary/AudioPlayerBar.vue';
import { AudioLibraryMode, AudioEvent } from '@/types/createandpublish/mediaLibraries/AudioLibrary';

const createAndPublishStore = namespace('CreateAndPublishStore');
const audioWizardModule = namespace('CreateAndPublishStore/audioWizard');

@Component({
  name: 'AudioWizardLibrary',
  components: {
    AudioLibrary,
    AudioStack,
    AudioPlayerBar,
  },
})
export default class AudioWizardLibrary extends Vue {
  @Prop({ type: String, required: true }) readonly wizardMode!: AudioLibraryMode;

  @createAndPublishStore.Getter('station_name') readonly stationName!: string;
  @audioWizardModule.Getter readonly stackEvents!: AudioEvent[];
  @audioWizardModule.Getter('wizardModes') readonly modes!: Record<string, AudioLibraryMode>;

  get isAudioPlayerBarOpen() {
    return (
      (this.wizardMode === this.modes.EPISODE_WIZARD || this.wizardMode === this.modes.TRACK_WIZARD) &&
      !!this.stackEvents.length
    );
  }

  get audioBarSrcList() {
    const prefix = `//postv3.futurimedia.com/${this.stationName}`;
    // const prefix = `//${process.env?.VUE_APP_API_URL}/${this.stationName}`;
    const query = '?version=3&Please=SirCanIHaveSomeMore';
    return this.stackEvents.map((audioEvent) => {
      return {
        title: audioEvent.title,
        src: `${prefix}/event/${audioEvent.id}.m3u8${query}`,
      };
    });
  }
}
