























import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import AudioCard from '@/createandpublish/components/AudioLibrary/AudioCard.vue';
import MiniHLSAudioPlayer from '@/createandpublish/components/AudioLibrary/MiniHLSAudioPlayer.vue';
import { AudioEvent } from '@/types/createandpublish/mediaLibraries/AudioLibrary';
import { eventBus, busEvents, eventSources } from '@/createandpublish/core/eventBus/audioWizardEventBus';

Component.registerHooks(['created', 'beforeDestroy']);

const createAndPublishStore = namespace('CreateAndPublishStore');
const audioWizardModule = namespace('CreateAndPublishStore/audioWizard');

@Component({
  name: 'AudioStack',
  components: {
    MiniHLSAudioPlayer,
    AudioCard,
  },
})
export default class AudioStack extends Vue {
  @createAndPublishStore.Getter('station_name') stationName;
  @audioWizardModule.Getter stackEvents;

  onRemoveEvent(event: AudioEvent) {
    eventBus.$emit(busEvents.REMOVE_STACK_EVENT, { source: eventSources.AUDIO_STACK, event });
  }

  currentlyPlayingEventId = '';

  onPlayMedia(eventId: string): void {
    if (this.currentlyPlayingEventId === eventId) {
      // Currently playing button was click, so stop it.
      this.currentlyPlayingEventId = '';
      return;
    }
    // Lets other players know to stop playing.
    eventBus.$emit(busEvents.AUDIO_PLAY_EVENT, eventSources.AUDIO_STACK);
    this.currentlyPlayingEventId = eventId;
  }

  get nowPlayingSrc() {
    if (!this.currentlyPlayingEventId) return undefined;

    const prefix = `//${process.env?.VUE_APP_API_URL}/${this.stationName}`;
    const endpoint = `/event/${this.currentlyPlayingEventId}.m3u8`;
    const query = '?version=3&Please=SirCanIHaveSomeMore';
    return `${prefix}${endpoint}${query}`;
  }

  audioPlayEventHandler(source) {
    if (source !== eventSources.AUDIO_STACK) {
      this.currentlyPlayingEventId = '';
    }
  }

  setupEventBusListeners() {
    eventBus.$on(busEvents.AUDIO_PLAY_EVENT, this.audioPlayEventHandler);
  }

  removeEventBusListeners() {
    eventBus.$off(busEvents.AUDIO_PLAY_EVENT, this.audioPlayEventHandler);
  }

  created() {
    this.setupEventBusListeners();
  }

  beforeDestroy() {
    this.removeEventBusListeners();
  }
}
