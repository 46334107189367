import Dashboard from '@/audience/views/Dashboard.vue';
import YouTube from '@/audience/views/YouTube.vue';
import Instagram from '@/audience/views/Instagram.vue';
import Facebook from '@/audience/views/Facebook.vue';
import Twitter from '@/audience/views/Twitter.vue';
import TikTok from '@/audience/views/TikTok.vue';
import LinkedIn from '@/audience/views/LinkedIn.vue';
import Pinterest from '@/audience/views/Pinterest.vue';
import Leaderboard from '@/audience/views/Leaderboard.vue';
import CreatorCommunity from '@/audience/views/CreatorCommunity.vue';
import AudienceNavTitles from '@/audience/data/leftNavTitles';
import type { RouteConfig } from 'vue-router';

export default [
  {
    path: '',
    name: 'Audience.Dashboard',
    component: Dashboard,
    meta: {
      sidebar_active_item: AudienceNavTitles.dashboard,
      title: 'Audience Analytics',
    },
  },
  {
    path: 'youtube',
    name: 'Audience.YouTube',
    component: YouTube,
    meta: {
      sidebar_active_item: AudienceNavTitles.youTube,
      title: 'Audience Analytics | YouTube',
    },
  },
  {
    path: 'instagram',
    name: 'Audience.Instagram',
    component: Instagram,
    meta: {
      sidebar_active_item: AudienceNavTitles.instagram,
      title: 'Audience Analytics | Instagram',
    },
  },
  {
    path: 'facebook',
    name: 'Audience.Facebook',
    component: Facebook,
    meta: {
      sidebar_active_item: AudienceNavTitles.facebook,
      title: 'Audience Analytics | Facebook',
    },
  },
  {
    path: 'twitter',
    name: 'Audience.Twitter',
    component: Twitter,
    meta: {
      sidebar_active_item: AudienceNavTitles.twitter,
      title: 'Audience Analytics | Twitter',
    },
  },
  {
    path: 'tiktok',
    name: 'Audience.TikTok',
    component: TikTok,
    meta: {
      sidebar_active_item: AudienceNavTitles.tikTok,
      title: 'Audience Analytics | TikTok',
    },
  },
  {
    path: 'linkedin',
    name: 'Audience.LinkedIn',
    component: LinkedIn,
    meta: {
      sidebar_active_item: AudienceNavTitles.linkedIn,
      title: 'Audience Analytics | LinkedIn',
    },
  },
  {
    path: 'pinterest',
    name: 'Audience.Pinterest',
    component: Pinterest,
    meta: {
      sidebar_active_item: AudienceNavTitles.pinterest,
      title: 'Audience Analytics | Pinterest',
    },
  },
  // {
  //   path: 'web',
  //   name: 'Audience.WebTraffic',
  //   component: null,
  //   meta: {
  //     sidebar_active_item: AudienceNavTitles.webTraffic,
  //     title: 'Audience Analytics | Web traffic',
  //   },
  // },
  {
    path: 'leaderboard',
    name: 'Audience.Leaderboard',
    component: Leaderboard,
    meta: {
      sidebar_active_item: AudienceNavTitles.leaderboard,
      title: 'Audience Analytics | Leaderboard',
    },
  },
  {
    path: 'community',
    name: 'Audience.CreatorCommunity',
    component: CreatorCommunity,
    meta: {
      sidebar_active_item: AudienceNavTitles.creatorCommunity,
      title: 'Audience Analytics | Creator Community',
    },
  },
  // {
  //   path: 'settings',
  //   name: 'Audience.Settings',
  //   component: null,
  //   meta: {
  //     sidebar_active_item: AudienceNavTitles.settings,
  //     title: 'Audience Analytics | Settings',
  //   },
  // },
] as RouteConfig[];
