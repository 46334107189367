import CreateAndPublishMediaService from '@/services/CreateAndPublishMediaService';
import { handleAjax } from '@/createandpublish/core/utils/handleAjax';
import { Module } from 'vuex';
import { ImageAsset } from '@/types/createandpublish/mediaLibraries';

const MediaService = new CreateAndPublishMediaService();

export default {
  namespaced: true,

  state: {
    imageAssets: [],
  },

  getters: {
    imageAssets(state) {
      return state.imageAssets;
    },
  },

  mutations: {
    SET_IMAGE_ASSETS(state, imageAssets: ImageAsset[]) {
      state.imageAssets = imageAssets;
    },

    CLEAR_IMAGE_ASSETS(state) {
      state.imageAssets = [];
    },

    UPDATE_IMAGE_ASSET(state, { assetId, metadata }) {
      const index = state.imageAssets.findIndex((asset) => asset.id === assetId);
      if (index !== -1) {
        const updatedAsset = {
          ...state.imageAssets[index],
          ...metadata,
        };
        state.imageAssets.splice(index, 1, updatedAsset);
      }
    },

    DELETE_IMAGE_ASSET(state, assetId: number) {
      const index = state.imageAssets.findIndex((asset) => asset.id === assetId);
      if (index !== -1) {
        state.imageAssets.splice(index, 1);
      }
    },
  },

  actions: {
    async getImageAssets({ dispatch, commit }) {
      const mediaType = 'image';
      await handleAjax({
        request: MediaService.getMediaLibraryItems(mediaType),
        dispatch,
        commit,
        mutation: 'SET_IMAGE_ASSETS',
      });
    },

    async updateImageAsset({ dispatch, commit }, imageAsset: ImageAsset) {
      const mediaType = 'image';
      await handleAjax({
        request: MediaService.updateMediaLibraryItem(mediaType, imageAsset),
        dispatch,
        commit,
        mutation: 'UPDATE_IMAGE_ASSET',
      });
    },

    async deleteImageAsset({ dispatch, commit }, assetId: number) {
      const mediaType = 'image';
      await handleAjax({
        request: MediaService.deleteMediaLibraryItem(mediaType, assetId),
        dispatch,
        commit,
        mutation: 'DELETE_IMAGE_ASSET',
        modify() {
          return assetId;
        },
      });
    },
  },
} as Module<S, R>;

/**
 * Module state
 */
export interface S {
  imageAssets: ImageAsset[];
}
/**
 * Vuex store rootState
 */
export interface R {
  // Define rootState properties here, as necessary.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}
