var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"import_rss_feed__container"},[_c('fm-heading',[_c('h1',{staticClass:"global__title"},[_vm._v("Import RSS feed")])]),_c('div',{staticClass:"global__subtitle"},[_vm._v(" You can import content from other podcasting platforms directly into a show in POST. For RSS feeds with a large amount of content, this process may take several hours to complete. You may close POST once the process has been initiated. ")]),_c('div',{staticClass:"global__separator"}),_c('div',{staticClass:"import_rss_feed__form-section"},[_c('div',{staticClass:"import_rss_feed__form-section-title"},[_c('h3',[_vm._v("Source RSS feed")]),_c('fm-body',{attrs:{"variant":"MEDIUM"}},[_vm._v(" Copy the URL for the RSS feed from your old podcasting platform. ")])],1),_c('div',{staticClass:"import_rss_feed__form-section-inputs"},[_c('fm-input',{directives:[{name:"model-wc",rawName:"v-model-wc",value:(_vm.$v.existingRSSUrl.$model),expression:"$v.existingRSSUrl.$model"}],attrs:{"error":_vm.existingRSSUrlError,"label":"Old RSS feed URL"}})],1)]),_c('div',{staticClass:"global__separator"}),_c('div',{staticClass:"import_rss_feed__form-section"},[_c('div',{staticClass:"import_rss_feed__form-section-title"},[_c('h3',[_vm._v("Destination in POST")]),_c('fm-body',{attrs:{"variant":"MEDIUM"}},[_vm._v(" Select which show and RSS feed to import your content into. You’ll need to have these set up in POST before importing your content. ")])],1),_c('div',{staticClass:"import_rss_feed__form-section-inputs"},[_c('fm-select',{directives:[{name:"model-wc",rawName:"v-model-wc",value:(_vm.$v.selectedShow.$model),expression:"$v.selectedShow.$model"}],attrs:{"error":_vm.selectedShowError,"label":"Destination show","placeholder":""},domProps:{"options":_vm.showOptions,"link":{
          label: 'Set up a new show',
          onClick: function () {
            this$1.$router.push(_vm.showSettingsRoute);
          },
        }},on:{"input":_vm.fetchSelectedShow}}),(_vm.selectedShowData && _vm.selectedShowData.id)?_c('div',{staticClass:"import_rss_feed__destination-feed"},[_c('span',{staticClass:"material-icons"},[_vm._v(" subdirectory_arrow_right ")]),_c('fm-multiselect',{directives:[{name:"model-wc",rawName:"v-model-wc",value:(_vm.$v.selectedTargetRSSFeeds.$model),expression:"$v.selectedTargetRSSFeeds.$model"}],staticStyle:{"width":"100%"},attrs:{"error":_vm.selectedTargetRSSFeedsError,"label":"Destination RSS feed","placeholder":"Select one or more feeds"},domProps:{"options":_vm.selectedShowRSSFeedsOptions,"link":{
            label: 'Set up a new feed for this show',
            onClick: function () {
              this$1.$router.push(_vm.showSettingsRoute);
            },
          }}})],1):_vm._e()],1)]),_c('div',{staticClass:"global__separator"}),_c('div',{staticClass:"import_rss_feed__submit"},[_c('fm-button-primary',{attrs:{"disabled":!_vm.canSubmit || _vm.isBusy},on:{"click":_vm.onSubmitClicked}},[_vm._v(" Begin import ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }