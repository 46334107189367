<template>
  <!-- Container -->
  <div id="shows-list-wrapper" :class="layout">
    <template v-if="layout === 'grid'">
      <ShowsListGridCard
        :key="show.id"
        :show="show"
        v-for="show in shows"
        @show-delete="$emit('show-delete', $event)"
      />
    </template>

    <template v-if="layout === 'list'">
      <table style="width: 100%; margin: 0">
        <table-row>
          <table-header-cell> Image </table-header-cell>
          <table-header-cell> Title </table-header-cell>
          <table-header-cell> Published </table-header-cell>
          <table-header-cell> </table-header-cell>
        </table-row>
        <ShowsListListCard
          v-for="(show, index) in shows"
          :key="show.id"
          :show="show"
          :class="{ 'bg-gray': ++index % 2 === 0 }"
          @show-delete="$emit('show-delete', $event)"
        />
      </table>
    </template>
  </div>
</template>
<script>
import TableHeaderCell from '../common/table/TableHeaderCell.vue';
import TableRow from '../common/table/TableRow.vue';
import ShowsListGridCard from './components/ShowsListGridCard';
import ShowsListListCard from './components/ShowsListListCard';

export default {
  components: {
    ShowsListGridCard,
    ShowsListListCard,
    TableRow,
    TableHeaderCell,
  },
  name: 'Shows',
  data() {
    return {};
  },
  props: {
    layout: String,
    shows: Array,
  },
};
</script>
<style lang="scss" scoped>
#shows-list-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}
#shows-list-wrapper.grid {
  display: grid;
  grid-auto-rows: max-content;
  align-content: space-around;
  flex-direction: row;
  grid-column-gap: 25px;
  grid-row-gap: 20px;
  grid-template-columns: repeat(2, 1fr);
}
#shows-list-wrapper.list {
  flex-direction: column;
}
.bg-gray {
  background: #f5f5f5;
}
</style>
