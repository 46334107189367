















import { Component, Vue, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

const audienceModule = namespace('AudienceAnalyticsStore');

@Component({
  name: 'NoProfileConnected',
})
export default class NoProfileConnected extends Vue {
  @Prop({ type: String, required: true }) platformLabel!: string;

  @audienceModule.Action('getAyrShareConnectUrl') getAyrShareConnectUrl;

  isButtonLoading = false;

  async onConnectClick() {
    this.isButtonLoading = true;
    try {
      const url = await this.getAyrShareConnectUrl();
      const anchorEl = document.createElement('A') as HTMLAnchorElement;
      anchorEl.href = url;
      anchorEl.target = '_blank';
      const body = document.getElementsByTagName('BODY')[0];
      body.appendChild(anchorEl);
      anchorEl.click();
      body.removeChild(anchorEl);
    } catch (e) {
      console.error(e);
    } finally {
      this.isButtonLoading = false;
    }
  }
}
