












































































































































































import { Component, Vue } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { Brand } from '@/types/Brand';
import BoilTable from '@/components/common/table/Table.vue';
import Breadcrumb from '@/components/common/navigation/breadcrumb/Breadcrumb.vue';
import Pagination from '@/components/common/table/PaginationBase.vue';
import TableInfo from '@/components/common/table/TableInfo.vue';
import ViewTitle from '@/components/common/view/viewTitle/ViewTitle.vue';
import BrandsAndCampaignsDrawer from '../components/brands/BrandsAndCampaignsDrawer.vue';
import { AccountState } from '@/types/account/AccountState';
import AlertDisplay from '@/components/common/AlertDisplay.vue';
import TableHeaderCell from '@/components/common/table/TableHeaderCell.vue';
import TableCell from '@/components/common/table/TableCell.vue';
import Select from '@/components/common/form/Select.vue';
import Button from '@/components/common/buttons/Button.vue';
import { maybePluralize } from '@/utils';
import { isFeatureAvailable } from '@/utils/routerHelpers';
import SearchBar from '@/components/common/form/SearchBar.vue';
import { Feature } from '@/types/features/FEFeature';

@Component({
  components: {
    AlertDisplay,
    BoilTable,
    BrandsAndCampaignsDrawer,
    Breadcrumb,
    Button,
    Pagination,
    SearchBar,
    Select,
    TableCell,
    TableHeaderCell,
    TableInfo,
    ViewTitle,
  },
})
export default class Users extends Vue {
  @Getter allBrands!: Brand[];
  @Getter getAccount!: AccountState;
  @Getter features!: Feature[];
  @Action fetchAccount;
  @Action fetchBrands;
  @Action editBrand;
  @Action createBrand;
  // Alert message
  displayAlert = false;
  alertMessage = {};

  // Breadcrumb items
  breadcrumbItems = [
    { text: 'Account Overview', to: { name: 'Dashboard' } },
    { text: this.pageTitle, active: true },
  ];

  // Drawer states
  defaultDrawerState = {
    active: true,
    campaigns: [],
    id: null,
    name: '',
  };
  drawerStatus = {
    isOpen: false,
    data: this.defaultDrawerState,
  };
  statusOptions = [
    {
      key: 'all',
      value: 'All status',
    },
    {
      key: 'active',
      value: 'Active',
    },
    {
      key: 'disabled',
      value: 'Disabled',
    },
  ];

  currentStatusFilter: string | number | boolean = 'all';
  sortBy = 'name';
  sortDirection = 'asc';
  currentPageNum = 1;
  pageSize = 10;

  // Filters
  searchBarQuery = '';
  searchFilter = '';

  // This seems a bit lenthy for an implementation - GK
  get sortedBrands() {
    if (this.sortBy === 'active') {
      return this.allBrands.sort((a, b) => {
        if (a.active === b.active) {
          return 0;
        }
        if (this.sortDirection === 'asc') {
          return a.active ? -1 : 1;
        } else {
          return a.active ? 1 : -1;
        }
      });
    }
    return this.allBrands.sort((a, b) =>
      a[this.sortBy].toLowerCase() < b[this.sortBy].toLowerCase()
        ? this.sortDirection === 'asc'
          ? -1
          : 1
        : this.sortDirection === 'asc'
        ? 1
        : -1
    );
  }

  get pageTitle() {
    return isFeatureAvailable('campaigns') ? 'Manage brands & campaigns' : 'Manage brands';
  }

  get isCampaignsAvailable() {
    return isFeatureAvailable('campaigns');
  }

  get filteredBrands() {
    let filteredBrands = this.sortedBrands;

    // Filter by status
    if (this.currentStatusFilter !== 'all') {
      const desiredBrandState = this.currentStatusFilter === 'active';
      filteredBrands = filteredBrands.filter((brand) => {
        return desiredBrandState === brand.active;
      });
    }

    //  Filter by name
    if (this.searchFilter) {
      filteredBrands = filteredBrands.filter((brand) => {
        return [brand.name].some((e) => e?.toLowerCase().includes(this.searchFilter?.toLowerCase()));
      });
    }

    return filteredBrands;
  }

  get canAddBrand() {
    const maxBrands = this.getAccount.data?.maxBrands;
    const currentActiveBrandsLength = this.allBrands.filter((brand) => brand.active).length;
    if (maxBrands) {
      if (currentActiveBrandsLength >= maxBrands) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }

  get availableBrandsText() {
    return `${this.getAccount.data?.activeBrands} of ${this.getAccount.data?.maxBrands}`;
  }

  // Prettify item count (brands in this case)
  get itemCountString() {
    if (this.filteredBrands?.length > 0) {
      const isPlural = this.allBrands.length > 1;
      return `${this.allBrands.length} total brand${isPlural ? 's' : ''}`; // Lowercase in mocks - GK
    }
    return `0 brands`;
  }

  // CTA link if brands are at max
  get brandsMaxedCTALink() {
    const ecommFeatureIsEnabled = this.features.includes('ecomm');
    if (ecommFeatureIsEnabled) {
      return '/subscriptions';
    }
    return 'https://content.futuricontentcloud.com/contact';
  }

  // Methods

  // Maybe plural
  getMaybePlural(count, noun) {
    return maybePluralize(count, noun);
  }

  // Table Methods: Sorting
  sortTable(fieldName: string) {
    if (this.sortBy === fieldName) {
      this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    } else {
      this.sortBy = fieldName;
      this.sortDirection = 'asc';
    }

    if (process.env.NODE_ENV === 'development') {
      console.log('sort table by:', fieldName, 'direction:', this.sortDirection);
    }
  }

  // Open Add Brand drawer
  handleAddBrandDrawer() {
    this.drawerStatus = {
      data: this.defaultDrawerState,
      isOpen: true,
    };
  }

  // Open Edit Brand drawer
  handleEditBrandDrawer(brand) {
    this.drawerStatus = { isOpen: true, data: brand };
  }

  // On close drawer
  handleCloseBrandDrawer() {
    this.drawerStatus = {
      data: this.defaultDrawerState,
      isOpen: false,
    };
  }

  // Searchbar Methods (Needs some work, not 100% functioning correctly yet)
  onSearchbarSubmit() {
    this.searchFilter = this.searchBarQuery;
  }
  clearSearchbar() {
    this.searchBarQuery = '';
    this.searchFilter = '';
  }

  onFilterChange(e) {
    this.currentStatusFilter = e.target.value;
  }

  // On upgrade CTA
  onUpgrade(): void {
    const ecommFeatureIsEnabled = this.features.includes('ecomm');
    if (ecommFeatureIsEnabled) {
      this.$router.push('/subscriptions');
    } else {
      window.open('https://content.futuricontentcloud.com/contact', '_blank');
    }
  }

  mounted() {
    this.fetchBrands();
    this.fetchAccount();
  }
}
