

























import { Component, Vue, Prop } from 'vue-property-decorator';
import { PlatformLabels } from '@/audience/data/socialPlatformChartConfig';
import YouTubeIcon from '@/audience/components/social/YouTubeIcon.vue';
import InstagramIcon from '@/audience/components/social/InstagramIcon.vue';
import FacebookIcon from '@/audience/components/social/FacebookIcon.vue';
import TwitterIcon from '@/audience/components/social/TwitterIcon.vue';
import TikTokIcon from '@/audience/components/social/TikTokIcon.vue';
import LinkedInIcon from '@/audience/components/social/LinkedInIcon.vue';
import PinterestIcon from '@/audience/components/social/PinterestIcon.vue';

import type { AudienceActiveAccount } from 'content-cloud-types/dist/types/audience/ProfileData';

@Component({
  name: 'LeaderboardCompareDrawer',
  filters: {
    formatChannelHandle(val: string) {
      if (val.charAt(0) !== '@') return `@${val}`;
      return val;
    },
  },
})
export default class LeaderboardCompareDrawer extends Vue {
  @Prop({ type: Array, required: true }) activeSocialAccounts!: AudienceActiveAccount[];

  get drawerData() {
    return Object.values(PlatformLabels).map((label) => {
      const accountData = this.activeSocialAccounts.find(({ platform }) => platform === label.toLowerCase());
      return {
        label,
        icon: this.getIconComponent(label),
        handle: accountData?.platformUsername ?? '(handle not available)',
        externalUrl: accountData?.url,
        isLinked: accountData?.linked ?? false,
      };
    });
  }

  getIconComponent(platformTitle) {
    switch (platformTitle) {
      case PlatformLabels.YOUTUBE:
        return YouTubeIcon;
      case PlatformLabels.INSTAGRAM:
        return InstagramIcon;
      case PlatformLabels.FACEBOOK:
        return FacebookIcon;
      case PlatformLabels.TWITTER:
        return TwitterIcon;
      case PlatformLabels.TIKTOK:
        return TikTokIcon;
      case PlatformLabels.LINKEDIN:
        return LinkedInIcon;
      case PlatformLabels.PINTEREST:
        return PinterestIcon;
      default:
        return null;
    }
  }
}
