




























































































import moment from 'moment';
import { Component, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { Getter, namespace } from 'vuex-class';
import { Campaign } from '@/types/Campaign';
import { AudioLibraryMode, AudioEvent } from '@/types/createandpublish/mediaLibraries/AudioLibrary';
import AudioLibraryTable from '@/createandpublish/components/AudioLibrary/Table.vue';
import Pagination from '@/createandpublish/components/common/Pagination.vue';
import BulkActions from '@/createandpublish/components/AudioLibrary/AudioLibraryBulkActions.vue';
import UploadMediaMixin from '@/createandpublish/mixins/UploadMediaMixin.vue';
import { eventBus, busEvents } from '@/createandpublish/core/eventBus/audioWizardEventBus';

const audioWizardModule = namespace('CreateAndPublishStore/audioWizard');

Component.registerHooks(['beforeDestroy', 'created']);

@Component({
  name: 'AudioLibrary',
  components: {
    Pagination,
    AudioLibraryTable,
    BulkActions,
  },
})
export default class AudioLibrary extends mixins(UploadMediaMixin) {
  @Prop({ type: String, required: true }) readonly libraryMode!: AudioLibraryMode;

  @Getter selectedBrand;
  @audioWizardModule.Getter('wizardModes') readonly modes!: Record<string, AudioLibraryMode>;
  @audioWizardModule.Action getAudioEvents;
  @audioWizardModule.Getter readonly audioEvents!: AudioEvent[];
  @audioWizardModule.Getter readonly stackEvents!: AudioEvent[];
  @audioWizardModule.Mutation('ADD_STACK_EVENT') addStackEvent;
  @audioWizardModule.Mutation('REMOVE_STACK_EVENT') removeStackEvent;
  @audioWizardModule.Mutation('SET_STACK_EVENTS') setStackEvents;

  isLoading = true;
  form = {
    searchTerm: '',
    campaignFilterValue: '',
  };
  lastUpdated: { timerId: number | null; date: Date | null; text: string } = {
    timerId: null, // ID returned by setInterval
    date: null, // Date of most recent update.
    text: '',
  };
  audioDrawer: { audioData: AudioEvent | null; file: File | null } = {
    audioData: null,
    file: null,
  };

  get activeCampaigns(): Campaign[] {
    return this.selectedBrand?.campaigns?.filter((campaign) => campaign.active) || [];
  }

  get campaignOptions() {
    return this.activeCampaigns.map((campaign) => ({ label: campaign.name, value: String(campaign.id) }));
  }

  onAddNewCampaign() {
    console.log("Oh noes. You can't add campaigns");
  }

  get appliedFilterPillData() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const filterPills: any[] = [];
    const isAppliedFilter = (value) => {
      if ((!Array.isArray(value) && (value === 0 || value)) || (Array.isArray(value) && value.length > 0)) {
        return true;
      }
      return false;
    };

    for (const [key, value] of Object.entries(this.form)) {
      if (isAppliedFilter(value)) {
        if (key === 'searchTerm') {
          filterPills.push({ title: 'Search', key, value });
        }
        if (key === 'campaignFilterValue') {
          filterPills.push({ title: 'Campaign', key, value });
        }
      }
    }
    return filterPills;
  }

  get filteredAudioEvents(): AudioEvent[] {
    let results = this.audioEvents;
    const searchTerm = this.form.searchTerm.trim().toLowerCase();
    if (this.form.campaignFilterValue) {
      results = results.filter((item) => item.campaign_ids?.includes(parseInt(this.form.campaignFilterValue)));
    }
    return results.filter(
      (item) =>
        item.title.toLowerCase().includes(searchTerm) ||
        item.description.toLowerCase().includes(searchTerm) ||
        item.filename.toLowerCase().includes(searchTerm)
    );
  }

  get paginatedAudioEvents(): AudioEvent[] {
    return this.filteredAudioEvents.slice(this.pageStartIndex, this.pageEndIndex);
  }

  clearFilter(key: string) {
    // TODO: Make more robust.
    this.form[key] = '';
  }

  clearAllFilters() {
    // TODO: Make more robust.
    this.form = {
      searchTerm: '',
      campaignFilterValue: '',
    };
  }

  onOpenAudioWizard(): void {
    this.$router.push('audio-wizard');
  }

  onUploadNewClicked() {
    const input = this.$refs.fileInput as HTMLButtonElement | undefined;
    input && input.click();
  }

  onFileInputChange(event: Event) {
    const target = event.target as HTMLInputElement;
    const file = (target.files as FileList).item(0);
    if (!file) return;
    this.audioDrawer.file = file;
    // this.onOpenAudioDrawer();
    this.submitAudioUpload({ title: 'upload', description: 'from library' });
  }

  submitAudioUpload({ title, description }) {
    const uploadType = 'audio';
    const file = this.audioDrawer.file;
    this.initMediaUploadService(uploadType, this.onUploadComplete);

    const metaData = {
      title,
      description,
    };

    this.startUploadWithFile(file, metaData);
  }

  onUploadComplete() {
    console.log('Success!');
  }

  onOpenAudioDrawer(audioData: AudioEvent | null = null) {
    this.audioDrawer.audioData = audioData;
    this.$root.$emit('bv::toggle::collapse', 'edit-audio-drawer');
  }

  onCloseAudioDrawer() {
    this.audioDrawer.audioData = null;
    this.audioDrawer.file = null;
  }

  onAudioDrawerSave({ createNew, title, description }) {
    if (createNew) {
      this.submitAudioUpload({ title, description });
    } else {
      console.log('UPDATE STUFF');
    }
  }

  setLastUpdatedTimer(date: Date = new Date()) {
    if (this.lastUpdated.timerId) {
      clearInterval(this.lastUpdated.timerId);
    }
    const getUpdatedText = (date: Date): string => {
      return `Last updated ${moment(date).fromNow()}`;
    };
    this.$set(this.lastUpdated, 'date', date);
    this.$set(this.lastUpdated, 'text', getUpdatedText(date));
    this.lastUpdated.timerId = window.setInterval(() => {
      this.$set(this.lastUpdated, 'text', getUpdatedText(date));
    }, 10000);
  }

  // Pagination
  currentPage = 1;
  pageSize = 10;
  pageSizeOptions = [
    { label: '10', value: '10' },
    { label: '25', value: '25' },
    { label: '50', value: '50' },
  ];
  get maxPage() {
    return Math.ceil(this.filteredAudioEvents.length / this.pageSize) || 1;
  }
  get pageStartIndex() {
    return (this.currentPage - 1) * this.pageSize;
  }
  get pageEndIndex() {
    return this.currentPage * this.pageSize;
  }
  onPageSizeChange(e) {
    if (e.target.value) {
      this.pageSize = parseInt(e.target.value);
    }
  }
  changePage(e) {
    const _page = e.detail && e.detail.value;
    if (!isNaN(_page) && _page <= this.maxPage && _page > 0) {
      this.currentPage = parseInt(_page);
    }
  }

  get isBulkActionsOpen() {
    return this.libraryMode === this.modes.LIBRARY && !!this.stackEvents.length;
  }

  onBulkDeselectAll() {
    // TODO
  }

  onBulkDelete() {
    // TODO
  }

  onBulkDownload() {
    // TODO
  }

  get showCreateWithBuilderButton() {
    return this.libraryMode === this.modes.LIBRARY;
  }

  async fetchAudioData() {
    this.isLoading = true;
    await this.getAudioEvents();
    this.setLastUpdatedTimer();
    this.isLoading = false;
  }

  setupEventBusListeners() {
    eventBus.$on(busEvents.ADD_STACK_EVENT, this.onAddStackEvent);
    eventBus.$on(busEvents.REMOVE_STACK_EVENT, this.onRemoveStackEvent);
    eventBus.$on(busEvents.SET_STACK_EVENTS, this.onSetStackEvents);
  }

  removeEventBusListeners() {
    eventBus.$off(busEvents.ADD_STACK_EVENT, this.onAddStackEvent);
    eventBus.$off(busEvents.REMOVE_STACK_EVENT, this.onRemoveStackEvent);
    eventBus.$off(busEvents.SET_STACK_EVENTS, this.onSetStackEvents);
  }

  onAddStackEvent({ event }) {
    this.addStackEvent(event);
  }
  onRemoveStackEvent({ event }) {
    this.removeStackEvent(event);
  }
  onSetStackEvents({ events }) {
    this.setStackEvents(events);
  }

  created() {
    this.fetchAudioData();
    this.setupEventBusListeners();
  }

  beforeDestroy() {
    this.removeEventBusListeners();
    const { timerId } = this.lastUpdated;
    if (timerId) {
      clearInterval(timerId);
    }
  }
}
