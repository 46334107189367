<template functional>
  <button class="custom_button" :title="props.title || ''" :class="[props.classes]">
    <slot />
  </button>
</template>

<script>
export default {
  props: ['classes', 'title'],
};
</script>

<style lang="scss" scoped>
.custom_button {
  all: initial;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  // min-width: 96px;     // Disabled
  // height: 48px;        // Disabled
  opacity: 1;
  border-radius: 8px;
  box-shadow: 0px 1px 3px #22204633;
  padding: 13px 24px;

  border: none;

  font-size: var(--fm-font-size-16);
  font-style: var(--fm-font-style-normal);
  font-weight: 400;
  font-family: var(--fm-font-family-roboto);
  letter-spacing: var(--fm-character-spacing-0-38);
  line-height: var(--fm-line-spacing-24);
  // @NOTE: Design does not want text to wrap.
  // We don't have a max-width set, if this becomes
  // an issue then we'll need to update both. - Trevor Kirpaul
  white-space: nowrap;
  box-sizing: border-box;
}

.custom_button-disabled {
  background: var(--neutral-grey-4-light-bg) 0% 0% no-repeat padding-box;
}

.custom_button .fm-icon-check-circle {
  margin-right: 16px;
}

.custom_button:hover:not(.custom_button-disabled) {
  box-shadow: 0px 1px 8px #22204633;
  cursor: pointer;
}

.custom_button:disabled,
.custom_button-loading:hover {
  cursor: not-allowed;
}

.custom_button:focus {
  box-shadow: none;
  outline: 4px solid #00c6c680 !important;
}

// @TODO: Remove when :focus-visible is supported
// Hide outline when focused via click
.custom_button:focus:not(.focus-visible) {
  outline: none !important;
}

// this will work when :focus-visible is supported
// we'll be able to remove :not(.focus-visible)
// when this works
.custom_button:focus-visible {
  box-shadow: none;
  outline: 4px solid #00c6c680 !important;
}

.custom_button:active:not(.custom_button-disabled) {
  box-shadow: none;
  border-color: var(--accent-links-hover);
}

.custom_button:active > .custom_button__slot_label {
  text-decoration: underline;
}

.material-icons {
  font-family: 'Material Icons';
  font-size: var(--fm-font-size-24);
}
</style>
