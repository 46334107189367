<template>
  <div class="confirm-modal">
    <fm-modal :visible.prop="true">
      <div slot="controlRow" class="modal-control-row">
        <fm-text tag="h1" as="h3" ref="firstHeader" tabindex="-1" class="header-text">
          {{ headerText }}
        </fm-text>
        <close-button class="close-button" @click="close" />
      </div>

      <div v-if="!useContentSlot" slot="content">
        <fm-text>{{ contentText }}</fm-text>
      </div>
      <div v-else slot="content">
        <slot>{{ contentText }}</slot>
      </div>

      <div slot="actionRow" class="modal-action-row">
        <fm-button-secondary @click="close">
          {{ cancelText }}
        </fm-button-secondary>
        <fm-button-primary @click="confirm">
          {{ confirmText }}
        </fm-button-primary>
      </div>
    </fm-modal>
  </div>
</template>

<script>
import CloseButton from '@/createandpublish/components/CloseButton';

export default {
  name: 'confirm-modal-v3',

  props: {
    headerText: {
      type: String,
      default: 'Are you sure?',
    },
    cancelText: {
      type: String,
      default: 'Cancel',
    },
    confirmText: {
      type: String,
      default: 'Confirm',
    },
    contentText: {
      type: String,
      default: 'Are you sure you want to remove this item?',
    },
    useContentSlot: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    close() {
      this.$emit('close');
    },

    confirm() {
      this.$emit('confirm');
    },
  },

  async mounted() {
    await window.customElements.whenDefined('fm-text');
    await this.$nextTick();
    this.$refs.firstHeader && this.$refs.firstHeader.focus();
  },

  components: {
    CloseButton,
  },
};
</script>

<style lang="scss" scoped>
.confirm-modal {
  .modal-action-row {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 24px;

    :first-child {
      margin-right: 16px;
    }
  }
  .modal-control-row {
    width: 100%;
    display: flex;
    margin-bottom: 24px;
    position: relative;

    .header-text {
      outline: none !important;
    }

    .close-button {
      position: absolute;
      top: 0;
      right: 0;
    }
  }
}
</style>
