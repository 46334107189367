import store from '@/store';

export default {
  bind(el, binding) {
    const noValue = !binding.value;
    const featureIsEnabled = store.getters.features.includes(binding.value);
    let enabled = noValue || featureIsEnabled;

    if (enabled) {
      return (el.hidden = false);
    }
    return (el.hidden = true);
  },
};
