var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Drawer',{attrs:{"title":_vm.isCreateMode ? 'Add feed' : 'Edit feed',"qa":_vm.$getComponent('AddEditRSSFeed', 'Drawer', 'add-edit-feed'),"id":"discover-rss-feed-drawer","submit":_vm.submit,"loading":_vm.isLoading,"vh100":true,"hideEventFunction":"triggerReset"},on:{"cancel":_vm.onCancel,"triggerReset":_vm.reset}},[_c('div',{attrs:{"slot":"content"},slot:"content"},[_c('div',{staticClass:"content"},[(_vm.displayAlert)?_c('AlertDisplay',{staticClass:"alertMessage",attrs:{"id":"rss-feeds-alert","alertMessage":_vm.alertMessage,"drawer":true,"data-qa":_vm.$getComponent('AddEditRSSFeeds', 'Component', 'alertDisplay')}}):_vm._e(),_c('div',{staticClass:"component"},[_c('Input',{attrs:{"id":"rss-feed-name-input","label":"Feed name","name":"feedName","inputHandler":_vm.handleInputChange,"value":_vm.currentFeed.feedName,"maxLength":"100","data-qa":_vm.$getComponent('AddEditRSSFeed', 'TextInput', 'feedName'),"error":_vm.$v.currentFeed.feedName.$error
              ? _vm.$v.currentFeed.feedName.required
                ? _vm.errorMessages.ano
                : 'Feed name' + _vm.errorMessages.required
              : ''}}),_c('Input',{attrs:{"id":"rss-url-input","label":"RSS URL","name":"feedUrl","inputHandler":_vm.handleInputChange,"value":_vm.currentFeed.feedUrl,"maxLength":"100","data-qa":_vm.$getComponent('AddEditUser', 'TextInput', 'feedUrl'),"error":_vm.$v.currentFeed.feedUrl.$error
              ? _vm.$v.currentFeed.feedUrl.required
                ? _vm.errorMessages.vurl
                : 'Feed Url' + _vm.errorMessages.required
              : ''}})],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }