<template>
  <div>
    <fm-inline-menu :menuOptions.prop="menuOptions"></fm-inline-menu>

    <embed-audio-modal v-if="isEmbedAudioModalOpen" :episodeId="episode.playlist_id" @on:cancel="toggleEmbedModal" />
    <download-notice-modal v-if="isDownloadNoticeModalOpen" @on:cancel="handleDownloadNoticeModalCancel" />
    <share-url-modal v-if="isShareUrlModalOpen" :url="episode.short_url" @on:cancel="toggleSharingUrlModal" />
    <confirm-modal-v-3
      v-if="isConfirmModalOpen"
      contentText="Are you sure you want to delete this episode?"
      confirmText="Delete"
      cancelText="Cancel"
      @close="isConfirmModalOpen = false"
      @confirm="deletePlaylist"
    />
    <confirm-modal-v-3
      v-if="isUnpublishModalOpen"
      :contentText="
        isScheduled
          ? 'Note: If unscheduled, this will be moved to the Unpublished tab.'
          : 'Note: Posts to Facebook and Twitter need to be removed on those platforms directly.'
      "
      :confirmText="isScheduled ? 'Unschedule' : 'Unpublish'"
      cancelText="Cancel"
      @close="isUnpublishModalOpen = false"
      @confirm="unpublish"
    />
  </div>
</template>

<script>
import EmbedAudioModal from '../components/EmbedAudioModal';
import DownloadNoticeModal from './DownloadNoticeModal';
import ShareUrlModal from './ShareUrlModal';
import ConfirmModalV3 from './ConfirmModalV3.vue';

import { episodeMoreMenuMixin } from '@/createandpublish/core/mixins/episodeMoreMenuMixin';

export default {
  name: 'shows-details-burger-menu',

  components: {
    EmbedAudioModal,
    DownloadNoticeModal,
    ShareUrlModal,
    ConfirmModalV3,
  },

  props: {
    episode: { type: Object, required: true },
  },

  mixins: [
    episodeMoreMenuMixin, // Also used in ShowDetailsGridCard.vue
  ],
};
</script>
