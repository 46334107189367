






































































































































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { Brand } from '@/types/Brand';
import CopyToClipboard from '@/components/discover/CopyToClipboard.vue';
import Drawer from '@/components/common/drawer/Drawer.vue';
import DiscoverIdeaStarterService from '@/services/DiscoverIdeaStarterService';
import DiscoverStoryService from '@/services/DiscoverStoryService';
import Loading from '@/components/common/Loading.vue';
import Button from '@/components/common/buttons/Button.vue';
// import CampaignCircle from '@/components/common/campaigns/CampaignCircle.vue';
// import CampaignMenu from '@/components/common/campaigns/CampaignMenu.vue';
import EventHub from '@/components/discover/EventHub.vue';

@Component({
  components: {
    Button,
    // CampaignCircle,
    // CampaignMenu,
    CopyToClipboard,
    Drawer,
    Loading,
  },

  data: () => {
    return {
      idea: {},
      story: {},
      type: '',
      loaderVisible: true,
      campaignIds: [],
      passToCardCampaign: [],
      campaigns: [],
      selectedCampaigns: [],
      // @TODO eclincher
      // sharePostModalVisible: false,
    };
  },

  computed: {
    // Builds data to be passed to eclincher Share Post modal
    // @TODO eclincher
    // sharePostModalData() {
    //   // build content for eclincher
    //   const _content = this.$data.idea.title;
    //   // build tags for eclincher
    //   const _tags =
    //     this['selectedBrandCampaigns']
    //       ?.filter((cp) => this.$data.idea.campaign_ids?.includes(cp.id))
    //       ?.map((cp2) => cp2.name) || [];
    //   // build picture for eclincher
    //   const _picture = this.$data.idea.thumbnail;
    //   return {
    //     tags: _tags.join(','),
    //     content: _content,
    //     picture: _picture,
    //   };
    // },
  },
})
export default class IdeaStarterDrawer extends Vue {
  @Getter selectedBrand;
  @Prop() visible!: boolean;

  reset() {
    this.$data.loaderVisible = true;
    this.$data.idea = {};
    this.$data.campaignIds = [];
    this.$data.passToCardCampaign = [];
    this.$data.selectedCampaigns = [];
  }

  async loadIdeaStarterById(ideaId, type) {
    this.$data.type = type;
    const response = await new DiscoverIdeaStarterService().getOne(ideaId, this.selectedBrand.id);
    this.$data.idea = response;
    this.loadStoryById(this.$data.idea.story_ids[0]);
    //account for any new campaigns not showing in data yet
    this.$data.campaignIds = this.$data.idea.campaign_ids;
    this.getSelectedCampaigns();
  }

  async loadStoryById(story_id) {
    const response = await new DiscoverStoryService().getOne(story_id, this.selectedBrand.id);
    this.$data.story = response;
    this.$data.loaderVisible = false;
  }

  formatDate(timestamp) {
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const d = new Date(timestamp);
    const t = new Date(timestamp).toLocaleTimeString('en', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
      timeZone: 'UTC',
    });
    return `${months[d.getMonth()]} ${d.getDate()}, ${d.getFullYear()} ${t}`;
  }

  getGenderClass(gender) {
    return gender === 'neutral' ? 'Males and Females' : `${gender}s`;
  }

  formatDomain(domain) {
    const prot = /^(https?|ftp):\/\//;
    return domain.replace(prot, '').replace('/', '');
  }

  formatCopyContent(headline, content) {
    return `${headline}\n${content.replace(/<\/?[^>]+(>|$)/g, '')}`;
  }

  triggerShare() {
    console.log('click');
  }

  //maintain tagged state until data refreshes
  campaignData() {
    const refreshPayload = {
      storyId: this.$data.idea.storyId,
      campaign_ids: this.$data.selectedCampaigns.map((_) => _.id),
    };
    this.$emit('refreshData', refreshPayload);
  }

  async tagCampaign(value) {
    if (value.length !== this.$data.selectedCampaigns.length) {
      this.updateCampaigns(value);
    }
  }

  async updateCampaigns(value) {
    const taggedCampaignIds = value;
    const legacyCampaignIds = this.$data.selectedCampaigns.map((_) => _.id);
    const addCampaignIds = taggedCampaignIds.filter((id) => !legacyCampaignIds.includes(id));
    const removeCampaignIds = legacyCampaignIds.filter((id) => !taggedCampaignIds.includes(id));

    if (removeCampaignIds.length) {
      this.$data.selectedCampaigns = this.$data.selectedCampaigns.filter(
        (campaign) => removeCampaignIds[0] !== campaign.id
      );
      this.campaignData();
    }

    if (addCampaignIds.length) {
      this.$data.selectedCampaigns.push(
        this.selectedBrand.campaigns.filter((campaign) => addCampaignIds[0] === campaign.id)[0]
      );
      this.campaignData();
    }
  }

  onAddNewCampaign() {
    //close single story drawer
    this.$root.$emit('bv::toggle::collapse', 'sidebar-ideaStarter');

    //pass story data to add to campaign drawer
    EventHub.$emit('addCampaignDrawerData', {
      storyId: this.$data.idea.storyId,
      campaigns: this.$data.selectedCampaigns,
      campaignIds: this.$data.selectedCampaigns.map((_) => _.id),
      availableCampaigns: this.selectedBrand.campaigns,
      dataType: 'idea',
    });
  }

  getSelectedCampaigns() {
    this.$data.selectedCampaigns = (this.selectedBrand as Brand).campaigns.filter(
      (campaign) => campaign.id && this.$data.campaignIds.includes(campaign.id)
    );
  }
}
