<template>
  <div :class="['brands-sub-head', { audience: isAudienceRoute }]">
    <div class="brands-text">You are managing</div>
    <div class="mobile-brands-text">
      <fm-text v-if="!userBrands.length" variant="large"> No brands are available </fm-text>
      <fm-text v-else variant="large">
        You are managing
        <span style="font-weight: 500; font-size: 20px; margin-left: 4px">
          {{ selectedBrand.name }}
        </span>
      </fm-text>
    </div>
    <div v-if="userBrands.length > 1" class="mobile-brands-drop-down">
      <fm-select
        label="You are managing"
        :value="selectedBrand.id"
        :options.prop="mobileDropdownBrandOptions"
        @input="onMobileDropdownInput"
      />
    </div>
    <div class="brands-drop-down">
      <!-- If user has no brands -->
      <h2 v-if="!userBrands.length">No brands are available</h2>
      <!-- If user has brands and has selected one -->
      <h2 v-else-if="selectedBrand.name">
        {{ selectedBrand.name }}
        <span class="down-arrow material-icons" v-if="userBrands.length > 1"> expand_more </span>
      </h2>
      <h2 v-else>Select a brand</h2>
      <div class="brands-drop-down-module" v-if="userBrands.length > 1">
        <div class="brands-manage-brands">
          <a href="/brands" :data-qa="$getComponent('BrandsSubHeader', 'Link', 'manageBrands')"
            >Manage Brands <span class="material-icons">open_in_new</span></a
          >
        </div>
        <div class="brands-manage-search">
          <SearchBar
            :reactive="true"
            placeholder="Search"
            :show-button="false"
            :data-qa="$getComponent('BrandsSubHeader', 'SearchBar', 'brandsSearch')"
            @clear="clearSearchTerm"
            @submit="handleSubmit"
          />
        </div>
        <ul>
          <li
            v-for="(brand, index) of filteredBrands"
            :key="index"
            @click="selectBrand(index)"
            :data-qa="$getComponent('BrandsSubHeader', 'List', 'brandsList')"
          >
            {{ brand.name }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { Vue } from 'vue-property-decorator';
import { mapActions, mapGetters } from 'vuex';
import SearchBar from '@/components/common/form/SearchBar.vue';
import { handleDispatches } from '@/utils';

const BrandsSubHeader = Vue.extend({
  components: {
    SearchBar,
  },

  props: {
    onClickEvent: {
      type: String,
    },
  },

  data() {
    return {
      doesUserHaveAccess: false,
      path: '',
      pathName: '',
      searchTerm: '',
    };
  },

  computed: {
    ...mapGetters(['selectedBrand', 'userBrands', 'authenticatedUser']),
    filteredBrands() {
      if (this.searchTerm) {
        return this.userBrands.filter((b) => b.name.toLowerCase().includes(this.searchTerm.toLowerCase()));
      } else {
        return this.userBrands;
      }
    },

    mobileDropdownBrandOptions() {
      return this.userBrands.map((brand) => ({
        label: brand.name,
        value: brand.id,
      }));
    },

    isAudienceRoute() {
      return this.$route.path.includes('audience');
    },
  },

  methods: {
    ...mapActions(['changeSelectedBrand']),
    onMobileDropdownInput(event) {
      const { value } = event.target;
      const index = this.filteredBrands.findIndex((brand) => brand.id === Number(value));
      this.selectBrand(index);
    },

    async selectBrand(index) {
      const currentBrandId = this.$route.params.brandId;
      const isNewBrand = Number(currentBrandId) !== this.filteredBrands[index].id;

      // If selected brand is different than the existing one
      if (isNewBrand) {
        // Change brand in store
        this.changeSelectedBrand(this.filteredBrands[index]);

        // Dispatch beforeEnter API calls
        await handleDispatches(this.$route.path);

        // navigate to new route
        this.$router.push({
          params: {
            brandId: this.selectedBrand.id,
          },
        });
      }
    },

    handleSubmit(value) {
      this.searchTerm = value;
    },

    clearSearchTerm() {
      this.searchTerm = '';
    },
  },
});
export default BrandsSubHeader;
</script>

<style scoped lang="scss">
.brands-sub-head {
  display: flex;
  flex-direction: row;
  font-family: 'Roboto';
  background: var(--neutral-white);

  .mobile-brands-drop-down,
  .mobile-brands-text {
    display: none;
  }

  .brands-drop-down {
    position: relative;
    cursor: default;

    .brands-drop-down-module {
      left: 0;
      position: absolute;
      top: 42px;
      z-index: 2;
      width: 100%;
    }

    ul,
    li {
      list-style: none;
      margin: 0;
      padding: 0;
    }

    ul {
      margin: 0 -22px;
    }

    li {
      padding: 0 22px;
      color: var(--neutral-grey-1-text);
      cursor: pointer;
      line-height: 40px;
      &:hover {
        background: #f9f9f9;
      }
    }

    h2 {
      border: 0;
      font-size: 30px;
      font-weight: 700;
      line-height: 38px;
      padding: 0 15px;
    }

    .brands-drop-down-module {
      background: var(--neutral-white);
      border-radius: 8px;
      box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.2);
      color: var(--accent-links);
      display: none;
      font-size: 16px;
      padding: 34px 22px;
      width: 439px;
    }
  }

  .brands-drop-down:hover {
    .brands-drop-down-module {
      display: block;
    }

    .down-arrow {
      transform: scaleY(-1);
    }
  }
}

.brands-text {
  font-size: 16px;
  line-height: 24px;
  // margin-right: 15px;
  margin-top: 7px;
}

.brands-manage-search {
  margin: 20px 0;
}

.brands-manage-brands,
.brands-manage-brands a {
  color: var(--accent-links);
  font-weight: 500;
  letter-spacing: 0.384px;
}

@media (max-width: 767px) {
  // Medium and small screens, scoped to Audience for now
  .brands-sub-head.audience {
    display: block;
    width: 100%;

    .mobile-brands-drop-down,
    .mobile-brands-text {
      display: block;
      width: 100%;
    }

    .mobile-brands-text {
      margin-bottom: 16px;
    }

    .brands-text,
    .brands-drop-down {
      display: none;
    }
  }
}
</style>
