<template>
  <b-modal id="modal-center" size="lg" header-border-variant="white" hide-footer hide-header centered>
    <div id="header">
      <slot class="slot-header" name="head"></slot>
    </div>
    <div id="form">
      <slot class="slot-form" name="form"></slot>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: 'modal',
};
</script>
