














import { Component, Vue, Prop } from 'vue-property-decorator';
import LeaderboardCardHeader from '@/audience/components/leaderboard/LeaderboardCardHeader.vue';
import LeaderboardCompareBarChart from '@/audience/components/leaderboard/LeaderboardCompareBarChart.vue';
import LeaderboardCompareDrawer from '@/audience/components/leaderboard/LeaderboardCompareDrawer.vue';
import CloseButton from '@/createandpublish/components/CloseButton.vue';
import { SocialRankingInterval } from 'content-cloud-types/dist/types/audience/SocialRanking';
import type { LeaderboardRankData } from '@/types/audience';

@Component({
  name: 'LeaderboardCompareCard',
  components: {
    CloseButton,
    LeaderboardCardHeader,
    LeaderboardCompareBarChart,
    LeaderboardCompareDrawer,
  },
})
export default class LeaderboardCompareCard extends Vue {
  @Prop({ type: String, required: true }) rankingInterval!: SocialRankingInterval;
  @Prop({ type: Object, required: true }) rankingData!: LeaderboardRankData;

  get headerProps() {
    const profile = this.rankingData.profile;
    if (!profile) return null;
    const { profileName, image } = profile;

    const currentRank = this.rankingData.rank.current ?? NaN;
    const rankDifference = this.rankingData.rank.rankDifference ?? NaN;

    return {
      profileName,
      profileImageSrc: image ?? '',
      currentRank,
      positionChange: rankDifference,
    };
  }

  get activeSocialAccounts() {
    return this.rankingData.profile?.activeSocialAccounts ?? [];
  }

  onClose() {
    this.$emit('onClose');
  }
}
