
























































import { Vue } from 'vue-property-decorator';
import { mapActions, mapGetters } from 'vuex';

import ProfileDropdownProduct from './components/ProfileDropdownProduct.vue';
import { isCCAdmin } from '../../utils';
import { isFeatureAvailable } from '@/utils/routerHelpers';
const ProfileDropdown = Vue.extend({
  components: {
    ProfileDropdownProduct,
  },

  data() {
    return {
      hideProfileMenu: true,
    };
  },

  computed: {
    ...mapGetters(['authenticatedUser', 'isUserMultiBrand', 'selectedBrand', 'sortedPriorityAccountProducts']),
    getAdminEmail() {
      return this.authenticatedUser?.adminEmail ? `mailto:${this.authenticatedUser?.adminEmail}` : '';
    },
    isCCAdmin() {
      return isCCAdmin();
    },
    manageBrandsTitle() {
      return isFeatureAvailable('campaigns') ? 'Manage brands & campaigns' : 'Manage brands';
    },
  },

  methods: {
    ...mapActions(['logOut']),

    signOut() {
      //typscript would not recognize the logout action otherwise
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (this as any).logOut();
      this.$router.replace({ path: '/login' });
    },
    closeProfileMenu() {
      if (this.$el.classList.contains('show') && !this.$el.classList.contains('collapsing')) {
        this.$root.$emit('bv::toggle::collapse', 'profile-menu');
      }
    },
    appLink(path) {
      return `/${path}/${this.selectedBrand?.id}`;
    },
  },

  mounted() {
    this.$root.$on('closeProfileFromIframe', () => {
      if (this.$el.classList.contains('show')) {
        this.$root.$emit('bv::toggle::collapse', 'profile-menu');
      }
    });
  },
});

export default ProfileDropdown;
