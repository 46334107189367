

















































import { Component, Vue, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import LeaderboardRankingListItem from '@/audience/components/leaderboard/LeaderboardRankingListItem.vue';
import LeaderboardRankingListItemPlaceholder from '@/audience/components/leaderboard/LeaderboardRankingListItemPlaceholder.vue';

import moment from 'moment-timezone';

import { SocialRankingInterval } from 'content-cloud-types/dist/types/audience/SocialRanking';

import type { LeaderboardRankData, SocialRankingContext } from '@/types/audience';
import type { AudienceLeaderboardContent } from 'content-cloud-types/dist/types/audience/AudienceLeaderboardTypes';

const audienceModule = namespace('AudienceAnalyticsStore');

Component.registerHooks(['mounted']);

@Component({
  name: 'LeaderboardRanking',
  components: {
    LeaderboardRankingListItem,
    LeaderboardRankingListItemPlaceholder,
  },
})
export default class LeaderboardRanking extends Vue {
  @Prop({ type: Array, required: true }) rankings!: LeaderboardRankData[];
  @Prop({ type: Number, default: NaN }) selectedUserId!: number;
  @Prop({ type: String, required: true }) context!: SocialRankingContext;
  @Prop({ type: Array, required: true }) contextOptions!: SocialRankingContext[];
  @Prop({ type: String, required: true }) rankingInterval!: SocialRankingInterval;
  @Prop({ type: Array, required: true }) rankingIntervalOptions!: { label: string; value: string }[];

  @audienceModule.Getter isProfileOptedIntoLeaderboard!: boolean;
  @audienceModule.Getter timeZone!: string;
  @audienceModule.Getter leaderboards?: AudienceLeaderboardContent;

  onContextChange($event: Event) {
    const { value } = $event.target as HTMLInputElement;
    this.$emit('onContextChange', value);
  }

  onRakingIntervalChange($event: InputEvent) {
    const { value } = $event.target as HTMLInputElement;
    this.$emit('onRankingIntervalChange', value);
  }

  get displayedDateRange() {
    const asOfDate = this.leaderboards?.rankings[this.rankingInterval]?.asOfDate;

    const emptyState = { previous: '', current: '' };
    if (!asOfDate) return emptyState;

    const current = moment(asOfDate).tz(this.timeZone);
    const previous = current.clone().subtract(1, this.rankingInterval as moment.unitOfTime.DurationConstructor);

    switch (this.rankingInterval) {
      case SocialRankingInterval.day:
      case SocialRankingInterval.week:
        return {
          previous: previous.format('MMM DD'),
          current: current.format('MMM DD'),
        };
      case SocialRankingInterval.month:
        return {
          previous: previous.format('MMM DD[,] YYYY'),
          current: current.format('MMM DD[,] YYYY'),
        };
      default:
        return emptyState;
    }
  }

  onShowUserDetails(id: number) {
    const rankIndex = this.rankings.findIndex((rankData) => rankData.profile?.brandId === id);
    if (rankIndex !== -1) {
      this.$emit('onShowUserDetails', this.rankings[rankIndex]);
    }
  }

  get condensedRankingData() {
    const topTenRankings = this.rankings.length > 10 ? this.rankings.slice(0, 10) : this.rankings;
    return topTenRankings.map((ranking) => {
      if (!ranking.profile) return null;
      if (!ranking.value) return null;

      const { profileName, image, brandId, activeSocialAccounts } = ranking.profile;
      const rank = ranking.rank.current ?? NaN;
      let percentChange = NaN;
      if (this.context === 'growth_rate') {
        percentChange = ranking.value.current ?? NaN;
      } else if (this.context === 'total_reach') {
        percentChange = ranking.value.percentChange ?? NaN;
      }

      return {
        id: brandId,
        name: profileName,
        image: image ?? '',
        numLinkedAccounts: activeSocialAccounts?.length ?? 0,
        rank,
        percentChange,
        isSelf: brandId === Number(this.$route.params.brandId),
      };
    });
  }
}
