































import { Component, Prop, Vue } from 'vue-property-decorator';
import Button from '@/components/common/buttons/Button.vue';
import ErrorMessage from '@/components/common/form/ErrorMessage.vue';

@Component({
  components: {
    Button,
    ErrorMessage,
  },
  data: () => {
    return {
      searchValue: [],
      showClear: false,
    };
  },
})
export default class SearchBar extends Vue {
  @Prop({ required: false }) classes?: string;
  @Prop({ required: false }) placeholder?: string;
  @Prop({ required: false }) value?: string;
  @Prop({ required: false }) delaySetBlurProp;
  @Prop({ required: false, default: true }) showButton?: boolean;
  @Prop({ required: false }) error?: string;
  @Prop({ required: false, default: false }) reactive?: boolean; // submits the form on key input
  @Prop({ required: false, default: false }) disabled?: boolean;
  @Prop({ required: false, default: false }) loading?: boolean;

  handleInput(e) {
    this.$emit('input', e.target.value);

    if (this.reactive) {
      this.handleSubmit(e);
    }
  }

  handleSubmit(e) {
    this.$emit('submit', e.target.value);
  }

  clearEventHandler() {
    this.$emit('clear');
  }

  onBlurEvent(e) {
    if (this.$props.delaySetBlurProp) {
      e.stopPropagation();
      e.preventDefault();
    }
  }
}
