import { handleAjax } from '@/createandpublish/core/utils/handleAjax';

import ChargifyServiceModule from '@/services/ChargifyService';

import type { Module } from 'vuex';
import type { RootState } from '@/types/store';

import type {
  Invoice,
  InvoiceResponse,
  PricesResponse,
  PlansData,
  PaymentProfile,
  PaymentProfileResponse,
  UpdateSubscriptionRequest,
} from '@/types/ecomm';
import type { Subscription } from '@/types/account/AccountData';

const ChargifyService = new ChargifyServiceModule();

export const initialState: EcommStore = {
  plans: null,
  invoices: [],
  invoicePageData: null,
  paymentProfiles: [],
};

export default {
  namespaced: true,

  state: initialState,

  getters: {
    plans(state) {
      return state.plans;
    },

    invoices(state) {
      return state.invoices;
    },

    mostRecentInvoice(state) {
      return state.invoices?.[0] ?? null;
    },

    paymentProfiles(state) {
      return state.paymentProfiles;
    },
  },

  mutations: {
    SET_PLANS(state, plans: PlansData) {
      state.plans = plans;
    },

    CLEAR_PLANS(state) {
      state.plans = null;
    },

    SET_INVOICES(state, invoiceResponse: InvoiceResponse) {
      state.invoices = invoiceResponse.content;
      state.invoicePageData = {
        currentPage: invoiceResponse.currentPage,
        totalPages: invoiceResponse.totalPages,
        totalItems: invoiceResponse.totalItems,
      };
    },

    CLEAR_INVOICES(state) {
      state.invoices = [];
      state.invoicePageData = null;
    },

    SET_PAYMENT_PROFILES(state, paymentProfiles: PaymentProfile[]) {
      state.paymentProfiles = paymentProfiles;
    },

    CLEAR_PAYMENT_PROFILES(state) {
      state.paymentProfiles = [];
    },

    ADD_PAYMENT_PROFILE(state, paymentProfile: PaymentProfile) {
      state.paymentProfiles.push(paymentProfile);
    },

    REMOVE_PAYMENT_PROFILE(state, id: PaymentProfile['id']) {
      const index = state.paymentProfiles.findIndex((profile) => profile.id === id);
      if (index !== -1) {
        state.paymentProfiles.splice(index, 1);
      }
    },
  },

  actions: {
    getPlans({ dispatch, commit }) {
      return handleAjax({
        request: ChargifyService.getPrices(),
        dispatch,
        commit,
        mutation: 'SET_PLANS',
        modify(data: PricesResponse) {
          return data.plans;
        },
        callback(err) {
          if (err) {
            throw err;
          }
        },
      });
    },

    updateSubscription(
      { dispatch, commit },
      { subscriptionId, subscriptionData }: { subscriptionId: number; subscriptionData: UpdateSubscriptionRequest }
    ) {
      return handleAjax({
        request: ChargifyService.updateSubscription(subscriptionId, subscriptionData),
        dispatch,
        commit,
        callback(err) {
          if (err) {
            throw err;
          }
        },
      });
    },

    getInvoices({ dispatch, commit }, { page = 1, size = 20, sortBy = 'issue_date', order = '-' }: PageData) {
      return handleAjax({
        request: ChargifyService.getInvoices(page, size, sortBy, order),
        dispatch,
        commit,
        mutation: 'SET_INVOICES',
      });
    },

    getInvoiceById({ dispatch, commit }, id: number) {
      return handleAjax({
        request: ChargifyService.getInvoicesById(id),
        dispatch,
        commit,
      }) as Promise<Invoice>;
    },

    getPaymentProfiles({ dispatch, commit }) {
      return handleAjax({
        request: ChargifyService.getPaymentProfiles(),
        dispatch,
        commit,
        mutation: 'SET_PAYMENT_PROFILES',
        modify(data: PaymentProfileResponse[]) {
          return data.map(({ payment_profile }) => payment_profile);
        },
      });
    },

    createPaymentProfile(
      { dispatch, commit, rootGetters },
      payload: { chargifyToken: string; useCardAsDefault: boolean }
    ) {
      return handleAjax({
        request: ChargifyService.createPaymentProfile(payload),
        dispatch,
        commit,
        mutation: 'ADD_PAYMENT_PROFILE',
        callback(err, _, paymentProfile: PaymentProfile) {
          if (err) {
            throw err;
          }
          if (payload.useCardAsDefault) {
            const { subscriptionId } = rootGetters.rawSubscription as Subscription;
            const { id: paymentProfileId } = paymentProfile;
            commit('setDefaultPaymentProfile', { subscriptionId, paymentProfileId }, { root: true });
          }
        },
      });
    },

    updateSubscriptionDefaultPaymentProfile({ dispatch, commit }, { subscriptionId, paymentProfileId }) {
      return handleAjax({
        request: ChargifyService.updateSubscriptionDefaultPaymentProfile(subscriptionId, paymentProfileId),
        dispatch,
        commit,
        callback(err) {
          if (err) {
            throw err;
          }
          commit('setDefaultPaymentProfile', { subscriptionId, paymentProfileId }, { root: true });
        },
      });
    },

    deletePaymentProfile({ dispatch, commit }, id: PaymentProfile['id']) {
      return handleAjax({
        request: ChargifyService.deletePaymentProfile(id),
        dispatch,
        commit,
        mutation: 'REMOVE_PAYMENT_PROFILE',
        modify() {
          return id;
        },
        callback(err) {
          if (err) {
            throw err;
          }
        },
      });
    },
  },
} as Module<EcommStore, RootState>;

export interface EcommStore {
  plans: PlansData | null;
  invoices: Invoice[];
  invoicePageData: Omit<InvoiceResponse, 'content'> | null;
  paymentProfiles: PaymentProfile[];
}

export interface PageData {
  page: number;
  size: number;
  sortBy?: 'created_at' | 'due_date' | 'issue_date' | 'updated_at';
  order?: '+' | '-';
}
