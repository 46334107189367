


















































































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';
import moment from 'moment';

import Button from '@/components/common/buttons/Button.vue';
import Select from '@/components/common/form/Select.vue';
import SearchBar from '@/components/common/form/SearchBar.vue';
import TrendingCardGrid from '@/components/discover/TrendingCardGrid.vue';
import TrendingCardList from '@/components/discover/TrendingCardList.vue';
import TrendingDrawer from '@/components/discover/drawer/TrendingDrawer.vue';
import DiscoverCategoryService from '@/services/DiscoverCategoryService';
import DiscoverStoryService from '@/services/DiscoverStoryService';
import Loading from '@/components/common/Loading.vue';
import EventHub from '@/components/discover/EventHub.vue';
import NewStoriesToast from '@/components/discover/newStoriesToast/index.vue';
import EmptyTrendingStories from '@/components/discover/EmptyTrendingStories.vue';
import DiscoveryStorySearch from '@/models/DiscoverStorySearchModel';
import DiscoverStoryManager, {
  DiscoverStoryFeedType,
  DiscoverStoryManagerUpdateEventData,
} from '@/services/DiscoverStoryManager';

@Component({
  components: {
    Button,
    Loading,
    NewStoriesToast,
    SearchBar,
    Select,
    TrendingCardGrid,
    TrendingCardList,
    TrendingDrawer,
    EmptyTrendingStories,
  },

  data: () => {
    return {
      isSearch: false,
      searchResults: [],
      searchResultsNew: [],
      drawerStatus: {
        isOpen: false,
      },
      layout: 'grid',
      loaderVisible: true,
      campaigns: [],
      interval: null,
      lastUpdatedAt: '', // Timestamp of last data refresh
      lastUpdatedText: '', // Text displayed next to view Title
      newStoriesAvailable: false,
      searchTerm: '',
    };
  },
})
export default class Trending extends Vue {
  @Getter selectedBrand;
  @Getter defaultBrandId;
  @Getter trendingStories;
  @Getter updatedStories;
  @Getter shortcuts;
  @Getter category;

  @Getter searchTrendingStoriesOffline;
  @Getter autoSuggestTrendingStoriesOffline;

  @Getter storyManagerSearch;
  @Getter storyManagerHasShortcutId;
  @Getter storyManagerFilterStories;

  @Action fetchShortcuts;
  @Action fetchUpdates;
  @Action fetchStories;
  @Action searchStories;
  @Action applyUpdatedStories;

  @Action setCategory;

  get displayTrendingStories() {
    if (this.trendingStoriesResults?.length) {
      // Use the offline search results
      return this.trendingStoriesResults!;
    }

    return this.trendingStories;
  }

  get hasUpdates() {
    const previousIds = this.trendingStories.map((story) => story.storyId);
    const updatedIds = this.updatedStories.map((story) => story.storyId);
    if (updatedIds.some((id) => !previousIds.includes(id))) return true;
    else return false;
  }

  trendingStoriesSearchTerm?: string;
  trendingStoriesResults: any[] = [];

  async handleSearchInput(term: string) {
    console.info(`Trending: search: term: ${term}`);

    if (term) {
      // const results = await this.searchTrendingStoriesOffline(term);

      const searchResults = await this.storyManagerSearch(term);
      console.log(`Results (searchable model) for search term <<${term}>>: `, searchResults);

      if (!searchResults?.length) {
        console.info(`Trending: no search results from story manager for search term <<${term}>>`);
        return;
      }

      const results = Array.from(DiscoverStoryManager.extractData(searchResults));

      console.log(`Results for search term <<${term}>>: `, results);
      this.trendingStoriesResults = results;
    }
  }

  async search(term: string) {
    console.info(`Trending: search: term: ${term}`);

    if (term) {
      // this.toggleLoader();
      // await this.searchStories(term);
      // this.$data.isSearch = true;

      // this.toggleLoader();
      // this.setLastUpdatedText();

      this.trendingStoriesSearchTerm = term;

      const results = await this.searchTrendingStoriesOffline(term);
      if (results) {
        console.log(`Results for search term <<${term}>>: `, results);
        this.trendingStoriesResults = results;
      }
    }
  }

  async clearSearch() {
    this.$data.searchTerm = '';
    this.toggleLoader();
    await this.fetchStories();
    this.toggleLoader();
    this.setLastUpdatedText();
  }

  async categoryId(selected) {
    console.info(`Trending: categoryId: selected: `, selected);

    if (selected) {
      console.info(`Trending: categoryId: storyManagerHasShortcutId: `, this.storyManagerHasShortcutId);

      if (this.storyManagerHasShortcutId && this.storyManagerHasShortcutId(Number(selected))) {
        console.info(`Trending: categoryId: story manager has data, using filtered results`);

        const shortcutIds = [selected];
        const filtered = await this.storyManagerFilterStories(DiscoverStoryFeedType.trending, { shortcutIds });

        console.info(`Trending: categoryId: filtered: `, filtered);

        this.trendingStoriesResults = filtered;

        return;
      }

      this.toggleLoader();
      this.setCategory(selected);
      await this.fetchStories();
      this.toggleLoader();
      this.setLastUpdatedText();
    }
  }

  @Action storyManagerFetchUpdates;

  async fetchUpdatedStories() {
    // Start the process of fetching updates using story manager
    await this.storyManagerFetchUpdates();

    // Call fetch updates
    // this.fetchUpdates();
  }

  @Getter storyManagerUpdateEventData?: DiscoverStoryManagerUpdateEventData;

  @Watch('storyManagerUpdateEventData')
  onStoryManagerUpdateEvent(updateEvent: DiscoverStoryManagerUpdateEventData) {
    console.info(`Trending: onStoryManagerUpdateEvent: updateEvent: `, updateEvent);
  }

  @Watch('hasUpdates')
  onUpdatedStories() {
    if (this.hasUpdates) this.$data.newStoriesAvailable = true;
    else this.$data.newStoriesAvailable = false;
  }

  async initialLoad() {
    if (!this.shortcuts.length) await this.fetchShortcuts();
    if (!this.trendingStories.length) await this.fetchStories();
    this.$data.loaderVisible = false;

    this.setLastUpdatedText();

    // refresh stories silently on a timer
    this.$data.interval = setInterval(this.fetchUpdates, 60000);
  }

  created() {
    this.initialLoad();
    //a new campaign was created at the story level
    //update tagged campaigns for that story
    EventHub.$on('tagTrendingStory', this.updateStoryCampaigns);
    // change `Last updated` message on an interval
    this.$data.lastUpdatedInterval = setInterval(this.setLastUpdatedText, 30000);
  }

  beforeDestroy() {
    clearInterval(this.$data.interval);
    clearInterval(this.$data.lastUpdatedInterval);
    this.$data.interval;
  }

  toggleLoader() {
    this.$data.loaderVisible = !this.$data.loaderVisible;
  }

  // When clicking the new stories toast
  onRefreshStories() {
    this.applyUpdatedStories();
    this.$data.newStoriesAvailable = false;

    // Stop and restart interval
    clearInterval(this.$data.interval);
    this.$data.interval = setInterval(this.fetchUpdatedStories, 60000);

    // Scroll to top
    const trendingPanelElement = this.$refs.trendingPanel as HTMLElement;
    trendingPanelElement.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }

  showGrid() {
    this.$data.layout = 'grid';
  }
  showList() {
    this.$data.layout = 'list';
  }

  handleTrendingStoryDrawer() {
    this.$root.$emit('bv::toggle::collapse', 'sidebar-trendingStory');
  }

  // Update copy for `Last update ...` shown next to view title
  setLastUpdatedText() {
    this.$data.lastUpdatedAt = moment();
    this.$data.lastUpdatedText = `Last updated ${moment(this.$data.lastUpdatedAt).fromNow()}`;
  }

  async updateStoryCampaigns(value) {
    const storyId = value.storyId;
    this.$data.searchResults.map((story, index) => {
      if (story.storyId === storyId) {
        this.$data.searchResults[index].campaign_ids = [...value.campaign_ids];
      }
    });
  }

  // When sharing post, eclincher
  // @TODO eclincher
  // onSharePost(data) {
  //   this.$data.sharePostModalData = {
  //     ...this.$data.sharePostModalData,
  //     ...data,
  //   };
  //   this.$data.sharePostModalVisible = true;
  // }
}
