import { render, staticRenderFns } from "./ChangeEmailModal.vue?vue&type=template&id=1ef03752&scoped=true&"
import script from "./ChangeEmailModal.vue?vue&type=script&lang=js&"
export * from "./ChangeEmailModal.vue?vue&type=script&lang=js&"
import style0 from "./ChangeEmailModal.vue?vue&type=style&index=0&id=1ef03752&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1ef03752",
  null
  
)

export default component.exports