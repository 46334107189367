

















import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class TableCell extends Vue {
  @Prop({ default: false, required: false }) expandable?: boolean;
  @Prop({
    default() {
      return [];
    },
    required: false,
  })
  expansionItems?: string[];

  expanded = false;

  get contentType() {
    if (this.expandable) {
      return 'a';
    }
    return 'span';
  }

  get expandableChevron() {
    if (this.expanded) {
      return 'arrow_drop_up';
    }
    return 'arrow_drop_down';
  }

  onExpand() {
    if (this.expandable) {
      this.expanded = !this.expanded;
    }
  }
}
