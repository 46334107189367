




import Vue, { PropType } from 'vue';

import { Product } from '@/types/Product';
import { productMap } from '@/constants/seeded/products';
import { removeDisabledProducts } from '@/utils/products/removeDisabledProducts';
import { mapGetters } from 'vuex';

export default Vue.extend({
  name: 'user-products',

  props: {
    products: {
      type: Array as PropType<Product[]>,
      required: true,
    },
  },

  computed: {
    ...mapGetters(['features']),
    productsString(): string {
      let result = '-';

      // Avoid mutation
      const mirroredProducts = [...this.products];

      // Sort products by display priority and filter by enabled feature
      let sortedFilteredProducts = removeDisabledProducts(mirroredProducts, this.features).sort(function (a, b) {
        // Sort by display priority
        const priority1 = productMap.find((e) => e.id === a.id)?.displayPriority || 100;
        const priority2 = productMap.find((e) => e.id === b.id)?.displayPriority || 100;
        return priority1 - priority2;
      });

      // Build final product string
      if (sortedFilteredProducts?.length > 0) {
        sortedFilteredProducts.forEach((product, index) => {
          // If first product
          if (index === 0) {
            result = product.name;
          } else {
            result = result + `, ${product.name}`;
          }
        });
      }
      return result;
    },
  },
});
