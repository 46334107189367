///
/// Don't add any new actions here. If you want to add actions to CreateAndPublishStore, add to ./tsactions.ts instead.
/// This file will be deleted once I have fully converted everything to TypeScript. - Brian
///

import Vue from 'vue';
import VueResource from 'vue-resource';
import { handleAjax, handleError } from './util';
import HttpClient from '@/services/common/HttpClient';
import rootStore from '@/store/index';
const http = HttpClient.getInstance();

Vue.use(VueResource);

Vue.http.interceptors.push((request, next) => {
  if (!request.url.includes('/playlist/rms')) {
    Vue.http.headers.common['X-Api-Key'] = 'uLoN5YggtUywA0hsoN1V21fy8P5VC8Qb';
  }
  const apikeyauth = rootStore.state.CreateAndPublishStore.apikeyauth;
  if (apikeyauth) {
    Vue.http.headers.common.Authorization = apikeyauth;
    Vue.http.headers.common['X-EventSessionId'] = rootStore.state.CreateAndPublishStore.stationSessionId;
  }
  next();
});

function makeAction(type) {
  return ({ commit }, ...args) => {
    commit(type, ...args);
  };
}

// message bar
export const setMessage = makeAction('SET_MESSAGE');
export const dismissMessage = makeAction('DISMISS_MESSAGE');

export const closePreviewPlayer = makeAction('CLOSE_PREVIEW_PLAYER');

export const setStation = ({ commit }) => {
  commit('SET_STATION');
};

export const setGroupMaps = makeAction('SET_GROUP_MAPS');

export const uploadFile = ({ dispatch, commit, getters }, { field, file }) => {
  const stationMedia = `${getters.mediaUrl}/${getters.station}`;

  const urls = {
    pre_bumper: 'event/upload.html',
    post_bumper: 'event/upload.html',
    default_image: `${stationMedia}/api/v1/playlist/upload/image.html`,
    square_image: `${stationMedia}/api/v1/playlist/upload/image.html`,
    rss_image: `${stationMedia}/api/v1/playlist/upload/image.html`,
    rectangle_image: `${stationMedia}/api/v1/playlist/upload/image.html`,
  };
  const formdata = new FormData();
  if (field.indexOf('_bumper') > -1) {
    formdata.append('title', `${field} ${new Date()}`);
  }
  formdata.append('file', file);
  commit('SET_MESSAGE', {
    name: 'Uploading...',
    details: 'Sending data to the server...',
    type: 'success',
  });

  const req = http.post(urls[field], formdata);

  // TODO: Replace this with a progress bar or something like that
  let lastUpdate = Date.now();
  req.progress = (ev) => {
    if (ev.lengthComputable && Date.now() - lastUpdate > 100) {
      lastUpdate = Date.now();
      const percent = Math.floor(ev.loaded / ev.total);
      commit('SET_MESSAGE', {
        name: 'Uploading...',
        details: `Sending data to the server: ${percent}%`,
        type: 'success',
      });
    }
  };

  handleAjax({
    request: req,
    dispatch,
    commit,
    errmsg: 'Unable to upload file to server',
    callback(err, data) {
      if (err) {
        return;
      }

      commit('SET_MESSAGE', {
        name: 'Uploading...',
        details: 'Waiting for server to finish processing...',
        type: 'success',
      });

      // The following will try performing a request periodically
      // until the request is successful. At which point it goes ahead
      // and triggers the mutation to update the field.
      function retry() {
        let checkReq = null;
        if (field.indexOf('image') > -1) {
          const imageUrl = data.location;
          // make sure original image available if rss image uploaded
          if (field.indexOf('rss') > -1) {
            imageUrl.replace('.thumbnail.', '.original.');
          }
          checkReq = http.head(imageUrl);
        } else {
          checkReq = http.get(data.location.replace(/https?:\/\/\w+(\.\w+)*\//, '/'));
        }

        handleAjax({
          request: checkReq,
          dispatch,
          commit,
          mutation: `UPDATE_${field.toUpperCase()}`,
          modify() {
            return data;
          },
          callback(err2) {
            if (!err2) {
              // remove uploading message
              commit('DISMISS_MESSAGE');
            } else {
              // try again soon
              setTimeout(retry, 250);
            }
          },
        });
      }

      setTimeout(retry, 250);
    },
  });
};

/**
 * Crop image.
 * @param dispatch
 * @param commit
 * @param image  path location of image
 * @param croppings  object { left: 0, right: 0, top: 0, bottom: 0 }
 * @param square  boolean (square image or not?)
 * @returns {Promise<Object>} http response
 */
export async function cropImage({ dispatch, commit, state }, { image, croppings, square }) {
  const event = {
    image: image,
    croppings: croppings,
    square: square, // boolean
  };

  commit('SET_MESSAGE', {
    name: 'Processing image...',
    details: 'Sending cropping data to the server...',
    type: 'success',
  });

  return handleAjax({
    request: http.post(`${state.endpoint}/image/crop`, event),
    dispatch,
    commit,
    errmsg: 'Unable to talk to server to modify image',
    callback(err, _data, _resp, { commit }) {
      if (!err) {
        commit('DISMISS_MESSAGE');
      }
    },
  });
}

export const searchAPImages = async ({ dispatch, commit, state }, { search }) => {
  return handleAjax({
    request: http.post(`${state.endpoint}/search-ap-images`, { search }),
    dispatch,
    commit,
    errmsg: 'Unable to talk to server to get licensed images',
  });
};

export const fetchBestPracticesManifest = async ({ dispatch, commit }) => {
  await handleAjax({
    request: Vue.http.get('https://d2vsiwkaihpnfc.cloudfront.net/manifest.json'),
    dispatch,
    commit,
    mutation: 'SET_BEST_PRACTICES_MANIFEST',
    errmsg: 'Unable to retrieve Best Practices manifest',
  });
};

/**
 * Handle server error from request.
 * @param state
 * @param commit
 * @param error
 * @param errmsg
 * @param callback
 * @returns {Promise<String>}
 */
export async function handleServerError({ state, commit }, { error, errmsg, callback }) {
  // Move this method's logic into here so it can be used as a global action (shouldn't have it as a generic function in just this scope)
  await handleError({ error, errmsg, commit, callback });
  return Promise.reject(state.message); // Return error to users
}
