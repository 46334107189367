















































import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import PlatformViewLayout from '@/audience/layouts/PlatformViewLayout.vue';
import ViewHeader from '@/audience/components/ViewHeader.vue';
import PlatformBarChart from '@/audience/components/PlatformBarChart.vue';
import PlatformLineChart from '@/audience/components/PlatformLineChart.vue';
import ContextTotalCard from '@/audience/components/ContextTotalCard.vue';
import { PlatformColors, PlatformLabels } from '@/audience/data/socialPlatformChartConfig';
import { getChartDataByPlatform, getMetricTotalsByContextAndDataIndex } from '@/audience/core/utils/chart';
import { calculatePercentChange } from '@/audience/data/chart-plugins/util';
import moment from 'moment-timezone';
import UserSettingsModule from '@/utils/userSettings';
import type { DateRange, ActiveSocialAccount, SocialAnalytics, PlatformAnalyticsContext } from '@/types/audience';

const UserSettings = new UserSettingsModule();
const audienceModule = namespace('AudienceAnalyticsStore');

@Component({
  name: 'TikTok',
  components: {
    PlatformViewLayout,
    ViewHeader,
    PlatformBarChart,
    PlatformLineChart,
    ContextTotalCard,
  },
})
export default class TikTok extends Vue {
  @audienceModule.Getter('extendedSocialAnalytics') socialAnalytics!: SocialAnalytics;
  @audienceModule.Getter dateRange!: DateRange;
  @audienceModule.Getter timeZone!: string;
  @audienceModule.Getter activeSocialAccounts!: ActiveSocialAccount[];

  context: PlatformAnalyticsContext =
    (UserSettings.get('context') as PlatformAnalyticsContext | undefined) || 'impressions';

  onContextChange(context: PlatformAnalyticsContext) {
    this.context = context;
    UserSettings.save('context', context);
  }

  get contextOptions() {
    return [
      { label: 'Impressions', value: 'impressions' },
      { label: 'Engagement', value: 'engagement' },
    ];
  }

  get platformLabel() {
    return PlatformLabels.TIKTOK;
  }

  get platformColor() {
    return PlatformColors.TIKTOK;
  }

  get isPlatformConnected() {
    return !!this.activeSocialAccounts.find((account) => account.platform === this.platformLabel.toLowerCase());
  }

  get layoutConfig() {
    return {
      isPlatformConnected: this.isPlatformConnected,
      leftContent: {
        heading: 'Snapshot',
        subHeading: this.displayedDateRange,
      },
      rightContent: {
        heading: 'Over time',
      },
    };
  }

  get headerConfig() {
    return {
      heading: `${this.platformLabel} analytics`,
      showSubHeading: this.isPlatformConnected,
      subHeading: 'Social impact',
      platformLabel: this.platformLabel,
    };
  }

  get displayedDateRange() {
    const start = moment.tz(this.dateRange.start, this.timeZone).format('MMM DD[,] YYYY');
    const end = moment.tz(this.dateRange.end, this.timeZone).format('MMM DD[,] YYYY');
    return `${start} vs. ${end}`;
  }

  get platformData() {
    return getChartDataByPlatform(this.platformLabel, this.socialAnalytics);
  }

  get contextTotalCardConfig() {
    const title = `${this.platformLabel} ${this.context}`;
    const len = this.platformData[this.context]?.[0]?.data.length ?? 0;
    const initialValue = getMetricTotalsByContextAndDataIndex(this.platformData, this.context, 0);
    const finalValue = getMetricTotalsByContextAndDataIndex(this.platformData, this.context, len - 1);
    const percentChange = calculatePercentChange(initialValue, finalValue);
    return {
      title,
      value: finalValue,
      percentChange,
    };
  }
}
