
























import { Vue } from 'vue-property-decorator';

const CampaignsColorPicker = Vue.extend({
  name: 'campaigns-color-picker',
  props: {
    defaultColor: {
      type: String,
      default: '#EE4833',
    },
  },

  data() {
    return {
      currentColor: this.defaultColor,
      colorArray: [
        { hex: '#EE4833' },
        { hex: '#F58120' },
        { hex: '#F8E100' },
        { hex: '#9DE673' },
        { hex: '#16C9A5' },
        { hex: '#00ADEF' },
        { hex: '#8E199D' },
        { hex: '#CB2610' },
        { hex: '#73B657' },
        { hex: '#00856A' },
        { hex: '#0174B7' },
        { hex: '#532AA2' },
        { hex: '#30780C' },
      ],
    };
  },
  methods: {
    handleUpdateColor(color) {
      this.currentColor = color;
      this.$emit('updateColor', color);
    },
  },
});

export default CampaignsColorPicker;
