































































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { Brand } from '@/types/Brand';
import Drawer from '@/components/common/drawer/Drawer.vue';
import Button from '@/components/common/buttons/Button.vue';
import CampaignCircle from '@/components/common/campaigns/CampaignCircle.vue';
import CampaignMenu from '@/components/common/campaigns/CampaignMenu.vue';
import EventHub from '@/components/discover/EventHub.vue';

@Component({
  components: {
    Drawer,
    Button,
    CampaignCircle,
    CampaignMenu,
  },

  data: () => {
    return {
      story: {},
      type: '',
      campaignIds: [],
      passToCardCampaign: [],
      campaigns: [],
      // eclincher
      selectedCampaigns: [],
    };
  },

  computed: {},
})
export default class RSSStoryDrawer extends Vue {
  @Getter selectedBrand;
  @Prop() drawerStatus!: Record<string, unknown>;
  @Prop() visible!: boolean;

  reset() {
    this.$data.story.storyId = null;
    this.$data.story.feedName = null;
    this.$data.campaignIds = [];
    this.$data.passToCardCampaign = [];
  }

  async loadRssStory(story, index, feedName, campaigns) {
    if (story) {
      this.$data.story = story.rssItem;
      this.$data.story.storyId = story.id;
      this.$data.story.feedName = feedName;
      //campaign ids from feed
      // this.$data.story.campaignIds = story.campaign_ids;
      //campaign ids in drawer tagged state
      this.$data.campaignIds = story.campaign_ids;
      this.$data.campaigns = campaigns;
      this.getSelectedCampaigns();
    }
  }

  formatPubDate(date) {
    const newDate = new Date(date);
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    return `${months[newDate.getMonth()]} ${newDate.getDate()}, ${newDate.getFullYear()}`;
  }

  async tagCampaign(value) {
    if (value.length !== this.$data.selectedCampaigns.length) {
      this.updateCampaigns(value);
    }
  }

  async updateCampaigns(value) {
    const taggedCampaignIds = value;
    const legacyCampaignIds = this.$data.selectedCampaigns.map((_) => _.id);
    const addCampaignIds = taggedCampaignIds.filter((id) => !legacyCampaignIds.includes(id));
    const removeCampaignIds = legacyCampaignIds.filter((id) => !taggedCampaignIds.includes(id));

    if (removeCampaignIds.length) {
      this.$data.selectedCampaigns = this.$data.selectedCampaigns.filter(
        (campaign) => removeCampaignIds[0] !== campaign.id
      );
      this.campaignData();
    }

    if (addCampaignIds.length) {
      this.$data.selectedCampaigns.push(
        this.selectedBrand.campaigns.filter((campaign) => addCampaignIds[0] === campaign.id)[0]
      );
      this.campaignData();
    }
  }

  onAddNewCampaign() {
    //close single story drawer
    this.$root.$emit('bv::toggle::collapse', 'sidebar-rssStory');

    //pass story data to add to campaign drawer
    EventHub.$emit('addCampaignDrawerData', {
      storyId: this.$data.story.storyId,
      campaigns: this.$data.selectedCampaigns,
      campaignIds: this.$data.selectedCampaigns.map((_) => _.id),
      availableCampaigns: this.selectedBrand.campaigns,
      dataType: 'rss',
    });
  }

  getSelectedCampaigns() {
    this.$data.selectedCampaigns = (this.selectedBrand as Brand).campaigns.filter(
      (campaign) => campaign.id && this.$data.campaignIds.includes(campaign.id)
    );
    console.log('this.$data.selectedCampaigns', this.$data.selectedCampaigns);
  }

  //maintain tagged state until data refreshes
  campaignData() {
    const refreshPayload = {
      storyId: this.$data.story.storyId,
      campaign_ids: this.$data.selectedCampaigns.map((_) => _.id),
    };
    this.$emit('refreshData', refreshPayload);
  }

  // parseIds(campaigns) {
  //   return campaigns.map((_) => _.id);
  // }

  //compensate for possible different rss formats for images
  getThumbnail(obj) {
    if (obj.enclosure) {
      return obj.enclosure.url;
    } else if (obj.media) {
      return obj.media.url;
    }
  }

  //uses campaign ids array to keep campaign component fresh
  randomizeStringifyArrayKey(ids) {
    return ids && ids.length ? JSON.parse(JSON.stringify(ids)) : null;
  }
}
