import store from '@/store/index';
import { UserRoleId } from '@/constants/enums/userRoles';
import type { Feature } from '@/types/features/FEFeature';
import type { AuthenticatedUser } from '@/models/UserModel';
import { mapFeatures } from '@/utils/features/mapFeatures';
import { getPrioritySortedProducts } from '@/utils/products';
import { removeDisabledProducts } from '@/utils/products/removeDisabledProducts';
import type { RootState as RootStoreState } from '@/types/store';
import { Module } from 'vuex';

export interface RootState {
  adminEmail: string;
  allFeatures: Feature[];
  authToken?: string;
  authenticatedUser?: AuthenticatedUser | null;
  features: Feature[];
  loginAppUrl: string;
}

export const initialState: RootState = {
  adminEmail: '',
  allFeatures: ['discover', 'create-and-publish', 'audience', 'ecomm', 'campaigns'],
  authToken: '',
  authenticatedUser: null,
  features: [],
  loginAppUrl: process.env.VUE_LOGIN_APP_URL || 'http://localhost:3000',
};

const getters = {
  authToken: (state) => state.authToken,
  authenticatedUser: (state) => state.authenticatedUser,
  getAdminEmail: (state) => state.adminEmail,
  // If user has a seat in Audience only
  hasAudienceOnly: (state) =>
    state.authenticatedUser?.products?.length === 1 && state.authenticatedUser?.products[0].id === 3, // ID in `seeded/products` constant
  isAdmin: (state) => [UserRoleId['ADMIN'], UserRoleId['SUPER_ADMIN']].includes(state.authenticatedUser?.roleId),
  isLoggedIn: (state) => state.authToken,
  loginAppUrl: (state) => state.loginAppUrl,
  // Brands
  defaultBrandId: (state) => state.authenticatedUser?.defaultBrandId || null,
  userBrands: (state) => state.authenticatedUser?.brands || [],
  // User products
  userProducts: (state) => state.authenticatedUser?.products || [],
  sortedPriorityUserProducts: (state) => getPrioritySortedProducts(state.authenticatedUser?.products || []),
  // Features
  allFeatures: (state) => state.allFeatures,
  features: (state) => state.features,
};

const mutations = {
  setAuthToken: (state, authToken) => {
    state.authToken = authToken;
  },
  setAuthenticatedUser: (state, user) => {
    const selectedBrand = store.getters.selectedBrand;
    state.authenticatedUser = user;

    // If the user has no brands,
    // set selected brand to empty object.
    if (user.brands.length === 0) {
      store.commit('setSelectedBrand', {});
      return;
    }

    // If no brand is selected,
    // set selected brand to the first brand in the user's list of brands.
    if (!selectedBrand || !selectedBrand.id) {
      store.commit('setSelectedBrand', user.brands[0]);
      return;
    }

    // If the user no longer has access to the selected brand,
    // set selected brand to the empty object.
    if (!user.brands.some((brand) => brand.id === selectedBrand.id)) {
      store.commit('setSelectedBrand', {});
      return;
    }

    // Set the selected brand to the selected brand
    store.commit('setSelectedBrand', selectedBrand);
  },
  setAdminEmail: (state, email) => {
    state.adminEmail = email;
  },
  setFeatures: (state, features) => {
    const mappedFeatures = mapFeatures(features) as Feature[];
    state.features = mappedFeatures;
    // Filter products that intersect with disabled features
    if (state.authenticatedUser?.products?.length > 0) {
      state.authenticatedUser.products = removeDisabledProducts(state.authenticatedUser.products, mappedFeatures);
    }
  },
};

const actions = {
  logIn: ({ commit }, payload) => {
    const { authToken, currentUser, features } = payload;
    commit('setAuthToken', authToken);
    commit('setAuthenticatedUser', currentUser);
    commit('setAdminEmail', currentUser.adminEmail);
    commit('setFeatures', features);
  },
  logOut: ({ commit }) => {
    commit('CLEAR_STORE');
    commit('setAuthToken', '');
    localStorage.clear(); // Clear local storage on logout
  },
  setFeatures: ({ commit }, features) => {
    commit('setFeatures', features);
  },
};

const module: Module<RootState, RootStoreState> = {
  actions,
  getters,
  mutations,
  state: { ...initialState },
};

export default module;
