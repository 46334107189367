<template>
  <div class="no-results">No stories match your search criteria.</div>
</template>

<script>
import { Vue, Component } from 'vue-property-decorator';
@Component({
  name: 'EmptyTrendingStories',
})
export default class EmptyTrendingStories extends Vue {}
</script>

<style scoped lang="scss">
.no-results {
  padding: 24px 12px;
}
</style>
