import store from '@/store';
import AudienceAnalyticsRouter from '@/audience/core/router';
import AudienceRoute from '@/views/AudienceRoute.vue';
import type { RouteConfig } from 'vue-router';

export default [
  {
    path: '/audience/:brandId?',
    component: AudienceRoute,
    children: AudienceAnalyticsRouter,
    async beforeEnter(_to, _from, next) {
      await store.dispatch('AudienceAnalyticsStore/verifyAndUpdateStoreForCurrentBrand');
      next();
    },
  },
] as RouteConfig[];
