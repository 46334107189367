















import { Component, Vue } from 'vue-property-decorator';
import LeaderboardCardHeaderPlaceholder from '@/audience/components/leaderboard/LeaderboardCardHeaderPlaceholder.vue';

@Component({
  name: 'LeaderboardCompareCard',
  components: {
    LeaderboardCardHeaderPlaceholder,
  },
})
export default class LeaderboardCompareCard extends Vue {}
