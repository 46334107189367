export enum PlatformLabels {
  YOUTUBE = 'YouTube',
  INSTAGRAM = 'Instagram',
  FACEBOOK = 'Facebook',
  TWITTER = 'Twitter',
  TIKTOK = 'TikTok',
  LINKEDIN = 'LinkedIn',
  PINTEREST = 'Pinterest',
}

export enum PlatformColors {
  YOUTUBE = '#FF0000',
  INSTAGRAM = '#BB00AD',
  FACEBOOK = '#1877F2',
  TWITTER = '#1DA1F2',
  TIKTOK = '#25F3ED',
  LINKEDIN = '#0A66C2',
  PINTEREST = '#FF5A44',
}

export enum PlatformChannelUrlPrefixes {
  YOUTUBE = 'https://www.youtube.com/c',
  INSTAGRAM = 'https://www.instagram.com',
  FACEBOOK = 'https://www.facebook.com',
  TWITTER = 'https://twitter.com',
  TIKTOK = 'https://www.tiktok.com',
  LINKEDIN = 'https://www.linkedin.com/in',
  PINTEREST = 'https://www.instagram.com',
}

export const DatasetBackgroundColors = [
  '#0054a9',
  '#00adef',
  '#5b68de',
  '#532aa2',
  '#8e199d',
  '#ff81ba',
  '#ec4c8a',
  '#b5005d',
  '#ff7c52',
  '#d73909',
];
