<template>
  <div>
    <!-- Drawer -->
    <EditTrackingURLsDrawer v-if="editDrawerOpen" :onCancel="() => setEditDrawerOpen(false)" :show="showProp" />
    <!-- Tracking URLs -->
    <div id="tracking_URLs__container">
      <fm-heading>
        <h1 class="global__title">3rd party Tracking URLs</h1>
      </fm-heading>
      <div class="global__subtitle">
        Tracking URLs are used by some services such as Chartable and PodTrac to provide additional listener download
        statistics and insights. Manage the URLs in use for your account here.
      </div>
      <div class="global__separator" />
      <div id="tracking_URLs__card">
        <SettingsCard :data="genericShowsData" :onEdit="onEdit" :onRemove="onRemove" connectTerm="URL" name="shows" />
      </div>
    </div>
    <confirm-modal-v-3
      v-if="confirmModal.isOpen"
      @close="onCloseConfirmModal"
      @confirm="deleteConfirmed"
      :contentText="`Clicking confirm will remove all tracking URLs configured for this show. Are you sure?`"
    />
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
const { mapActions, mapGetters } = createNamespacedHelpers('CreateAndPublishStore');

import SettingsCard from '../../components/common/settings/SettingsCard.vue';
import EditTrackingURLsDrawer from '../../components/trackingURLs/EditTrackingURLsDrawer.vue';
import ConfirmModalV3 from '../../components/ConfirmModalV3.vue';

export default {
  name: 'trackingURLs',
  components: {
    EditTrackingURLsDrawer,
    SettingsCard,
    ConfirmModalV3,
  },
  data() {
    return {
      showId: null,
      editDrawerOpen: false,
      fetchingShow: false,
      showProp: {},
      confirmModal: {
        isOpen: false,
        showId: undefined,
      },
    };
  },
  computed: {
    ...mapGetters(['unfilteredShows', 'stationTrackingPrefixList']),
    show() {
      if (this.showId) {
        return this.unfilteredShows.find((show) => show.id === this.showId);
      }
      return null;
    },
    // This is needed to keep data in SettingsCard generic for re-use -- GK
    genericShowsData() {
      if (this.unfilteredShows) {
        const result = this.unfilteredShows.map((show) => {
          return {
            id: show.id,
            name: show.title,
            rawData: show,
            tracking_prefixes: show.tracking_prefixes,
          };
        });
        result.unshift({ id: 0, tracking_prefixes: this.stationTrackingPrefixList });
        return result;
      }
      return [{ id: 0 }];
    },
  },
  methods: {
    ...mapActions(['setStationTrackingPrefixList', 'setShowTrackingPrefixList']),
    async onEdit(showId) {
      this.showProp = await this.unfilteredShows.find((show) => show.id === showId);
      this.setEditDrawerOpen(true);
    },
    onRemove(showId) {
      this.confirmModal.isOpen = true;
      this.confirmModal.showId = showId;
    },
    deleteConfirmed() {
      this.savePrefixList(this.confirmModal.showId, []);
      this.onCloseConfirmModal();
    },
    onCloseConfirmModal() {
      this.confirmModal.isOpen = false;
      this.confirmModal.showId = undefined;
    },
    setEditDrawerOpen(newState) {
      this.editDrawerOpen = newState;
    },
    async savePrefixList(showId, urls) {
      this.loading = true;

      try {
        if (!showId) {
          await this.setStationTrackingPrefixList({ prefix: urls });
        } else {
          await this.setShowTrackingPrefixList({ showId: showId, prefixObj: { prefix: urls } });
        }
      } catch (err) {
        console.log(err, 'error');
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss">
#tracking_URLs__container {
  display: flex;
  flex-direction: column;
  width: 100%;
  #tracking_URLs__card {
    width: 50%;
    @media only screen and (max-width: 1080px) {
      width: 100%;
    }
  }

  .settings_card_header__connect {
    display: none;
  }
}
</style>
