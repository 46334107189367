<template>
  <section class="analytics-no-results">
    <fm-text tag="h2" class="header"> No results </fm-text>
    <fm-text tag="p" variant="large" class="description">
      The current filters generated no results. Try adjusting the date range, or decreasing the number of applied
      filters.
    </fm-text>
  </section>
</template>

<script>
export default {
  name: 'no-results',
};
</script>

<style lang="scss" scoped>
.analytics-no-results {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 64px 0;

  .header {
    margin-bottom: 32px;
  }

  .description {
    text-align: center;
  }
}
</style>
