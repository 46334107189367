




















































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import RoundedCard from '@/components/common/cards/RoundedCard.vue';
import Button from '@/components/common/buttons/Button.vue';
import DiscoverFollowingService from '@/services/DiscoverFollowingService';
import SharePostButton from '@/components/discover/SharePostButton.vue';
import { Brand } from '@/types/Brand';
import CampaignCircle from '@/components/common/campaigns/CampaignCircle.vue';
import CampaignMenu from '@/components/common/campaigns/CampaignMenu.vue';
import EventHub from '@/components/discover/EventHub.vue';
@Component({
  components: {
    RoundedCard,
    Button,
    SharePostButton,
    CampaignCircle,
    CampaignMenu,
  },

  data() {
    return {
      passToCardCampaign: [],
      windowWidth: window.innerWidth,
      selectedCampaigns: [],
    };
  },

  computed: {
    sharePostModalData() {
      const _campaigns = this.$store.getters.authenticatedUser.campaigns; // campaigns from authenticatedUser
      const _content = `${this.$data.title}`; // build content for eclincher
      const _tags =
        _campaigns.filter((cp) => this.$data.story.campaign_ids?.includes(cp.id))?.map((cp2) => cp2.name) || []; // build tags for eclincher
      const _picture = this.$props.thumbnail;
      return {
        tags: _tags.join(','),
        content: _content,
        picture: _picture,
      };
    },
  },

  methods: {
    // eclincher on share
    onShare() {
      // this.$emit('onSharePost', this.$data.sharePostModalData);
      console.log('onShare');
    },
  },
})
export default class RSSSingleFeedCard extends Vue {
  @Getter selectedBrand;
  @Prop({ required: false }) private title?: string;
  @Prop({ required: false }) private thumbnail?: string;
  @Prop({ required: false }) private feedName?: string;
  @Prop({ required: false }) private pubDate?: string;
  @Prop({ required: false }) private feedId?: string;
  @Prop({ required: false }) private clickEvent;
  @Prop({ required: false }) private storyId;
  @Prop({ required: false }) private story!: Record<string, unknown>;
  @Prop({ required: false }) private cardIndex?: number;
  @Prop({ required: false }) private campaignIds!: [];

  openDrawer() {
    return this.$emit(this.$props.clickEvent);
  }

  isRightColumn(index) {
    const divisor = this.$data.windowWidth > 1580 ? 3 : 2;
    const isRight = (index + 1) % divisor === 0;
    return isRight;
  }

  onAddNewCampaign() {
    //pass story data to add to campaign drawer
    EventHub.$emit('addCampaignDrawerData', {
      storyId: this.$props.storyId,
      campaigns: this.$data.selectedCampaigns,
      campaignIds: this.$data.selectedCampaigns.map((_) => _.id),
      availableCampaigns: this.selectedBrand.campaigns,
      dataType: 'rss',
    });
  }

  async tagCampaign(value) {
    if (value.length !== this.$data.selectedCampaigns.length) {
      this.updateStory(value);
    }
  }

  async updateStory(value) {
    const storyId = this.$props.storyId;
    const taggedCampaignIds = value;
    const legacyCampaignIds = this.$data.selectedCampaigns.map((_) => _.id);
    const addCampaignIds = taggedCampaignIds.filter((id) => !legacyCampaignIds.includes(id)) || [];
    const removeCampaignIds = legacyCampaignIds.filter((id) => !taggedCampaignIds.includes(id)) || [];

    if (removeCampaignIds.length > 0) {
      await new DiscoverFollowingService().untagCampaignFromRSSItem(
        this.selectedBrand.id,
        removeCampaignIds[0],
        storyId
      );
      this.$data.selectedCampaigns = this.$data.selectedCampaigns.filter(
        (campaign) => removeCampaignIds[0] !== campaign.id
      );
    }

    if (addCampaignIds.length > 0) {
      await new DiscoverFollowingService().tagRSSItemToCampaign(this.selectedBrand.id, addCampaignIds[0], storyId);
      const newCampaign = this.selectedBrand.campaigns.filter((campaign) => addCampaignIds[0] === campaign.id)[0];

      if (newCampaign) {
        this.$data.selectedCampaigns.push(
          this.selectedBrand.campaigns.filter((campaign) => addCampaignIds[0] === campaign.id)[0]
        );
      }
    }
  }

  getSelectedCampaigns() {
    this.$data.selectedCampaigns = (this.selectedBrand as Brand).campaigns.filter(
      (campaign) => campaign.id && this.$props.campaignIds.includes(campaign.id)
    );
  }

  created() {
    this.getSelectedCampaigns();
  }
}
