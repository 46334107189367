












import { Component, Vue, Prop } from 'vue-property-decorator';
import { Doughnut } from 'vue-chartjs/legacy';
import { Chart as ChartJS, Title, Tooltip, Legend, ArcElement, CategoryScale, PointElement } from 'chart.js';
import type { ChartData, ChartOptions } from 'chart.js';
import type { ChartConfig } from '@/types/audience';

ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale, PointElement);

@Component({
  name: 'BaseDoughnutChart',
  components: {
    Doughnut,
  },
})
export default class BaseDoughnutChart extends Vue {
  @Prop({ type: Object, required: true }) chartConfig!: ChartConfig;
  @Prop({ type: Object, required: true }) chartOptions!: ChartOptions;
  @Prop({ type: Object, required: true }) chartData!: ChartData;
}
