<template>
  <!-- Container -->
  <table-row>
    <table-cell min-width="80px" :padding-y="8">
      <img class="shows-list__list-card-image" v-if="show.square_image.length > 0" :src="showImage" />
      <img class="shows-list__list-card-image" v-else src="@/assets/post-logo.svg" />
    </table-cell>
    <table-cell :padding-y="8">
      <span class="shows-list__list-card-title" @click="onCardClick">{{ show.title }}</span>
    </table-cell>
    <table-cell :padding-y="8">
      {{ lastPublished }}
    </table-cell>
    <table-cell :padding-y="8">
      <fm-inline-menu :menuOptions.prop="menuOptions"></fm-inline-menu>
      <ConfirmationModal
        :Status="deleteShowStatus"
        :loading="deletingShow"
        @close="onDeleteClose"
        @confirm="onDeleteConfirm"
        v-if="deleteModalIsOpen"
      />
    </table-cell>
  </table-row>
</template>
<script>
import moment from 'moment';

import ConfirmationModal from '@/createandpublish/ConfirmationModal.vue';

import { createNamespacedHelpers, mapGetters as mapRootGetters } from 'vuex';
const { mapGetters, mapActions } = createNamespacedHelpers('CreateAndPublishStore');
import TableRow from '../../common/table/TableRow.vue';
import TableCell from '../../common/table/TableCell.vue';

export default {
  name: 'ShowsListListCard',

  components: {
    ConfirmationModal,
    TableRow,
    TableCell,
  },

  data() {
    return {
      menuOptions: [
        {
          label: 'Delete show',
          onClick: () => this.onDeleteClick(),
        },
      ],
      // Delete show
      deleteModalIsOpen: false,
      deleteShowStatus: false,
      deletingShow: false,
    };
  },

  computed: {
    ...mapGetters({
      station: 'station_name',
    }),
    ...mapRootGetters(['selectedBrand']),
    lastPublished() {
      const _date = this.show.last_playlist_date;
      if (!_date) return 'NA';
      return moment(_date).format('MM/DD/YYYY');
    },
    // Route that links to show details
    showDetailsRoute() {
      return `/create/${this.selectedBrand?.id}/podcasts/shows/${this.show.id}/details`;
    },
    showImage() {
      const location = 'https://post.ccdev.futurimedia.com';

      if (!this.show.square_image.includes(location)) {
        return `${location}${this.show.square_image}`;
      }

      return this.show.square_image;
    },
  },

  methods: {
    ...mapActions(['deleteShow', 'fetchAllShowsData']),

    // onClick events for cards
    onCardClick() {
      this.$router.push({
        path: this.showDetailsRoute,
        query: {
          tab: 'published',
        },
      });
    },
    onDeleteClick() {
      this.deleteModalIsOpen = true;
    },
    onDeleteClose() {
      this.deleteModalIsOpen = false;
    },
    async onDeleteConfirm() {
      this.deletingShow = true;
      const title = this.show.title;
      this.$emit('show-delete', title);
      await this.deleteShow(this.show.id);
      this.deletingShow = false;

      // Might need error handling
      this.deleteShowStatus = true;

      this.fetchAllShowsData();
      // Close drawer when done
      this.onDeleteClose();
    },
  },

  props: {
    show: Object,
  },
};
</script>

<style lang="scss" scoped>
div.shows-list__list-card-layout {
  border-bottom: 1px solid var(--neutral-grey-3-borders);
  display: flex;
  flex-direction: row;
  font-family: 'Roboto'; // Matches mocks but let me know -- GK
  height: 109px;
  justify-content: flex-start;
  max-height: 109px;
  padding: 15px 24px;
}
div.shows-list__list-card-layout:first-of-type {
  border-top: 1px solid var(--neutral-grey-3-borders);
}
img.shows-list__list-card-image {
  height: 80px;
  width: 80px;
  min-width: 80px;
}
div.shows-list__list-card-title,
div.shows-list__list-card-lastUpdated {
  align-items: center;
  color: var(--neutral-grey-1-text);
  font-size: 16px;
  letter-spacing: 0px;
  margin-left: 16px;
  text-align: left;
}
.shows-list__list-card-title {
  cursor: pointer;
}
div.shows-list__list-card-title span {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  align-items: center;
  display: -webkit-box;
  overflow: hidden;
  width: 400px;
}
div.shows-list__list-card-lastUpdated {
  display: flex;
  margin-left: 30px;
  width: 250px;
}
div.shows-list__context-menu {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
</style>
