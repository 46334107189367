<template>
  <div>
    <add-campaign-drawer
      id="discover-add-campaign"
      :campaignData="campaignData"
      @cancel="handleCampaignDrawerCancel"
      :data-qa="$getComponent('Discover', 'Component', 'discoverDrawer')"
      :storyData="storyData"
    ></add-campaign-drawer>
    <div class="discover-view">
      <WelcomeBanner
        v-if="isWelcomeBannerActive"
        :data-qa="$getComponent('Discover', 'Component', 'discoverWelcomeBanner')"
      />
      <div class="mobile-switch">
        <Select
          name="mobile-switch"
          :options="[
            { id: 'trending', name: 'Trending Stories' },
            { id: 'ideas', name: 'IdeaStarters' },
          ]"
          optionKey="id"
          optionValue="name"
          :inputEventHandler="(e) => mobileSwitch(e.target.value)"
          placeholder=""
          v-if="isMobile"
        />
      </div>
      <div v-if="toggleTrending && this.selectedBrand" class="section-body">
        <Trending
          v-if="(isMobile && showTrending) || !isMobile"
          :data-qa="$getComponent('Discover', 'Component', 'discoverTrending')"
        />
        <IdeaStarters
          v-if="(isMobile && showIdeas) || !isMobile"
          :data-qa="$getComponent('Discover', 'Component', 'discoverIdeaStarters')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { Component, Vue } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import WelcomeBanner from '@/components/discover/WelcomeBanner.vue';
import Trending from '@/components/discover/Trending.vue';
import IdeaStarters from '@/components/discover/IdeaStarters.vue';
// import AddDiscoverCampaignDrawer from '@/components/discover/drawer/AddDiscoverCampaignDrawer.vue';
import AddCampaignDrawer from '@/components/common/campaigns/AddCampaignDrawer.vue';
import EventHub from '@/components/discover/EventHub.vue';
import Select from '@/components/common/form/Select.vue';
// import { getterNames, moduleName as currentUserModuleName } from '@/store/modules/CurrentUserStore';
// const currentUserModule = namespace(currentUserModuleName);

@Component({
  components: {
    AddCampaignDrawer,
    IdeaStarters,
    Select,
    Trending,
    WelcomeBanner,
  },
  data() {
    return {
      toggleTrending: true,
      toggleFollowing: true,
      toggleSettings: true,
      brands: [],
      currentBrand: {},
      campaignData: {},
      allBrandsData: [{}],
      storyData: {},
      activeTabIndex: 0,
      showTrending: true,
      showIdeas: false,
      screedWidth: 0,
    };
  },
})
export default class Discover extends Vue {
  @Action toggleWelcomeBanner;
  @Getter isWelcomeBannerActive;
  @Getter selectedBrand;
  @Getter getAccount;
  @Getter authenticatedUser;
  @Action changeSelectedBrand;

  async fetchBrandData() {
    //resync selected brand to capture any campaign updates
    // This is duplicate functionality
    // this.authenticatedUser.brands.forEach((brand) => {
    //   if (brand.id === this.selectedBrand.id) {
    //     console.log('5');
    //     this.changeSelectedBrand(brand);
    //   }
    // });

    this.campaignData = this.getCampaignData();
  }

  get isMobile() {
    //based on horizontal width of ipad pro
    return this.screenWidth <= 1366;
  }

  created() {
    this.screenWidth = window.innerWidth;
    this.fetchBrandData();
    EventHub.$on('addCampaignDrawerData', this.passDataToCampaignDrawer);
  }

  getCampaignData() {
    if (this.selectedBrand) {
      return {
        brandId: this.selectedBrand.id,
        active: true,
        color: '#EE4833',
        name: '',
      };
    } else {
      return {
        brandId: 0,
        active: true,
        color: '#EE4833',
        name: '',
      };
    }
  }

  passDataToCampaignDrawer(value) {
    this.storyData = value;
  }

  handleCampaignDrawerCancel() {
    return {
      brandId: 0,
      active: true,
      color: '#EE4833',
      name: '',
    };
  }

  mobileSwitch() {
    this.showTrending = !this.showTrending;
    this.showIdeas = !this.showIdeas;
  }
}
</script>

<style scoped>
template {
  width: 100%;
}
.section-content {
  width: 100%;
}
.discover-body {
  min-width: none;
  max-width: 1920px;
}
.section-body {
  display: flex;
  flex-direction: row;
  margin: 1.5em 1.5em 0 0;
  min-width: 100%;
}
.discover-view {
  width: 100%;
}

.mobile-switch {
  background: var(--neutral-grey-5-lightest-bg);
  padding: 1.5em;
}

@media (max-width: 1366px) {
  .section-body {
    margin: 0;
  }
}
</style>
