
































































import { Component, Vue } from 'vue-property-decorator';
import { Getter, namespace } from 'vuex-class';

import Pagination from '@/createandpublish/components/common/Pagination.vue';

import moment from 'moment-timezone';

import type { Brand } from '@/types/Brand';
import type { Campaign } from '@/types/Campaign';
import type { SocialStoryDetails as SocialStory } from 'content-cloud-types/dist/types/createandpublish/SqlModelInterfaces';

const SocialStoriesStore = namespace('CreateAndPublishStore/socialStories');

Component.registerHooks(['beforeRouteLeave']);

@Component({
  name: 'SocialStoryLibrary',
  components: {
    Pagination,
  },
})
export default class SocialStoryLibrary extends Vue {
  @Getter selectedBrand?: Brand;

  @SocialStoriesStore.Action getStories!: () => void;
  @SocialStoriesStore.Getter stories!: SocialStory[];

  isLoading = false;
  form = {
    searchTerm: '',
    campaignFilterValue: '',
  };
  lastUpdated: { timerId: number | null; date: Date | null; text: string } = {
    timerId: null, // ID returned by setInterval
    date: null, // Date of most recent update.
    text: '',
  };

  get filteredSocialStories(): SocialStory[] {
    return this.stories;
  }

  get activeCampaigns(): Campaign[] {
    return this.selectedBrand?.campaigns?.filter((campaign) => campaign.active) || [];
  }

  get campaignOptions() {
    return this.activeCampaigns.map((campaign) => ({ label: campaign.name, value: String(campaign.id) }));
  }

  onAddNewCampaign() {
    console.log("Oh noes. You can't add campaigns");
  }

  get appliedFilterPillData() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const filterPills: any[] = [];
    const isAppliedFilter = (value) => {
      if ((!Array.isArray(value) && (value === 0 || value)) || (Array.isArray(value) && value.length > 0)) {
        return true;
      }
      return false;
    };

    for (const [key, value] of Object.entries(this.form)) {
      if (isAppliedFilter(value)) {
        if (key === 'searchTerm') {
          filterPills.push({ title: 'Search', key, value });
        }
        if (key === 'campaignFilterValue') {
          const campaignLabel = this.campaignOptions.find((campaign) => campaign.value === value)?.label ?? '';
          filterPills.push({ title: 'Campaign', key, value: campaignLabel });
        }
      }
    }
    return filterPills;
  }

  clearFilter(key: string) {
    this.form[key] = '';
  }

  clearAllFilters() {
    this.form = {
      searchTerm: '',
      campaignFilterValue: '',
    };
  }

  setLastUpdatedTimer(date: Date = new Date()) {
    if (this.lastUpdated.timerId) {
      clearInterval(this.lastUpdated.timerId);
    }
    const getUpdatedText = (date: Date): string => {
      return `Last updated ${moment(date).fromNow()}`;
    };
    this.$set(this.lastUpdated, 'date', date);
    this.$set(this.lastUpdated, 'text', getUpdatedText(date));
    this.lastUpdated.timerId = window.setInterval(() => {
      this.$set(this.lastUpdated, 'text', getUpdatedText(date));
    }, 10000);
  }

  get pageSizeOptions() {
    return [
      { label: '10', value: '10' },
      { label: '25', value: '25' },
      { label: '50', value: '50' },
    ];
  }

  async fetchData() {
    this.isLoading = true;
    // await this.getStories();
    this.setLastUpdatedTimer();
    this.isLoading = false;
  }

  created() {
    this.fetchData();
  }

  beforeRouteLeave(_to, _from, next) {
    const { timerId } = this.lastUpdated;
    if (timerId) {
      clearInterval(timerId);
    }
    next();
  }
}
