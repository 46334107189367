



























































































import { Component, Vue, Prop } from 'vue-property-decorator';
import { required } from 'vuelidate/lib/validators';
import Button from '@/components/common/buttons/Button.vue';
import Select from '@/components/common/form/Select.vue';
import errorMessages from '@/utils/errorMessages.json';
import { ComponentHandle, PricePointHandle, ProductHandle, ProductKey } from '@/types/ecomm';
import { ProductSelectOption } from '@/types/ecomm/ProductSelectOption';
import { getAvailableProducts } from '@/utils/products/getAvailableProducts';
import { getPricePointByHandles, getPricePointHandle } from '@/utils/ecomm';

import type { PlansData } from '@/types/ecomm/PricesResponse';
import type { DataProp } from '@/types/ecomm/DataProp';

Component.registerHooks(['beforeMount']);

@Component({
  name: 'SubscriptionSummary',
  components: {
    Button,
    Select,
  },
})
export default class SubscriptionSummary extends Vue {
  @Prop({ type: Object, required: true }) plans!: PlansData;
  @Prop({ type: Object, required: true }) data!: DataProp;

  apps: ProductSelectOption[] = [];
  editable = false;
  form = {
    appSelections: {
      [ProductKey.AUDIENCE]: true,
      [ProductKey.DISCOVER]: false,
      [ProductKey.CREATE_AND_PUBLISH]: false,
    },
    discover: 1,
    create: 1,
    brands: 1,
  };
  formIsLoading = false; // is form submitting?
  errorMessages = errorMessages; // Input error messages
  multipleBrands = false;

  validations() {
    return {
      form: {
        discover: { required },
        create: { required },
        brands: { required },
      },
    };
  }

  get selectedAppKey() {
    const { [ProductKey.DISCOVER]: discover, [ProductKey.CREATE_AND_PUBLISH]: createAndPublish } =
      this.form.appSelections;
    if (discover && createAndPublish) {
      return ProductKey.SUITE;
    }
    if (discover) {
      return ProductKey.DISCOVER;
    }
    if (createAndPublish) {
      return ProductKey.CREATE_AND_PUBLISH;
    }
    return ProductKey.AUDIENCE;
  }

  get currentAppLabel(): ProductSelectOption['label'] | undefined {
    return this.apps.find((app: ProductSelectOption) => app.id === this.data.app)?.label;
  }
  get createSeats(): ProductSelectOption[] {
    return this.getSeatsOptions(ComponentHandle.CREATE_SEATS);
  }
  get discoverSeats(): ProductSelectOption[] {
    return this.getSeatsOptions(ComponentHandle.DISCOVER_SEATS);
  }
  get audienceSeatCount() {
    const discoverSeatCount = this.form.appSelections[ProductKey.DISCOVER] ? this.data.discover : 0;
    const createSeatCount = this.form.appSelections[ProductKey.CREATE_AND_PUBLISH] ? this.data.create : 0;

    return Math.max(3, discoverSeatCount, createSeatCount);
  }
  get brands(): ProductSelectOption[] {
    // set brands max to 30
    const brands: ProductSelectOption[] = [];
    for (let i = 1; i <= 30; i++) {
      const option: ProductSelectOption = {
        id: i,
        label: i,
      };
      brands.push(option);
    }
    return brands;
  }
  get discoverEnabled() {
    const apps: ProductSelectOption[] = this.apps;
    return !!apps.find((_) => _.id === ProductKey.DISCOVER);
  }
  get createEnabled() {
    const apps: ProductSelectOption[] = this.apps;
    return !!apps.find((_) => _.id === ProductKey.CREATE_AND_PUBLISH);
  }

  handleInputChange(e) {
    this.form[e.target.name] = e.target.value;
  }
  handleInputChangeInteger(e) {
    this.form[e.target.name] = parseInt(e.target.value, 10);
  }
  handleInputChangeBrands(e): void {
    const value = parseInt(e.target.value, 10);
    this.updateBrands(value);
  }
  updateBrands(value: number): void {
    this.multipleBrands = value > 1;
    this.form.brands = value;
  }
  onSubmit() {
    // Touch all fields
    this.$v.form.$touch();

    // if form's validated
    if (!this.$v.form.$invalid) {
      // go to the next step
      this.editable = false;
      const { discover, create, brands } = this.form;
      const app = this.selectedAppKey;
      this.$emit('update', { app, discover, create, brands });
      return;
    }
  }
  getSeatsOptions(componentHandle: ComponentHandle) {
    const isSuite = this.data.app === ProductKey.SUITE;
    let productKey: ProductKey | undefined;
    let productHandle: ProductHandle | undefined;
    switch (componentHandle) {
      case ComponentHandle.DISCOVER_SEATS:
        productKey = ProductKey.DISCOVER;
        productHandle = isSuite ? ProductHandle.SUITE_MONTHLY : ProductHandle.DISCOVER_MONTHLY;
        break;
      case ComponentHandle.CREATE_SEATS:
        productKey = ProductKey.CREATE_AND_PUBLISH;
        productHandle = isSuite ? ProductHandle.SUITE_MONTHLY : ProductHandle.CREATE_MONTHLY;
        break;
      default:
        break;
    }

    if (!productHandle || !productKey) {
      return [];
    }

    const pricePointHandle = getPricePointHandle(productHandle) as PricePointHandle;
    const prices = getPricePointByHandles(this.plans, productKey, componentHandle, pricePointHandle)?.prices || [];

    const data: ProductSelectOption[] =
      prices.map((price) => ({
        id: price.endingQuantity ?? 1,
        label: price.endingQuantity ?? 1,
      })) ?? [];

    return data;
  }

  async getApps() {
    const availableApps = await getAvailableProducts();
    const addApp = ({ id, label }: { id: string; label: string }) => {
      this.apps.push({ id, label });
    };
    availableApps.forEach((app) => {
      const label = app.label as string;
      switch (app.id) {
        case 'audience':
          addApp({ id: ProductKey.AUDIENCE, label });
          return;
        case 'discover':
          addApp({ id: ProductKey.DISCOVER, label });
          return;
        case 'create-and-publish':
          addApp({ id: ProductKey.CREATE_AND_PUBLISH, label });
          return;
        default:
          return;
      }
    });
  }

  getAppSelectionValuesByProductKey(productKey: ProductKey): SubscriptionSummary['form']['appSelections'] {
    const baseSelection = {
      [ProductKey.AUDIENCE]: true,
      [ProductKey.DISCOVER]: false,
      [ProductKey.CREATE_AND_PUBLISH]: false,
    };

    switch (productKey) {
      case ProductKey.SUITE:
        return {
          ...baseSelection,
          [ProductKey.DISCOVER]: true,
          [ProductKey.CREATE_AND_PUBLISH]: true,
        };
      case ProductKey.DISCOVER:
        return {
          ...baseSelection,
          [ProductKey.DISCOVER]: true,
        };
      case ProductKey.CREATE_AND_PUBLISH:
        return {
          ...baseSelection,
          [ProductKey.CREATE_AND_PUBLISH]: true,
        };
      default:
        return {
          ...baseSelection,
        };
    }
  }

  beforeMount() {
    // populate form values from data prop
    const { app, brands, create, discover } = this.$props.data;

    const appSelections = this.getAppSelectionValuesByProductKey(app);

    this.form = { brands, create, discover, appSelections };

    // populate fields from query params
    const queryParams = this.$route.query;

    // only update if param is available and one of suite, discover or create
    if (queryParams?.app) {
      const app = queryParams?.app as ProductKey | null;
      if (app && [ProductKey.SUITE, ProductKey.DISCOVER, ProductKey.CREATE_AND_PUBLISH].includes(app)) {
        this.form.appSelections = this.getAppSelectionValuesByProductKey(app);
      }
    }

    if (queryParams.brands) {
      const brands = queryParams.brands as string | null;
      const brandsInt = parseInt(brands || '0', 10);
      this.updateBrands(brandsInt || 1);
    }

    if (queryParams.discover) {
      const discoverSeats = queryParams.discover as string | null;
      this.form.discover = parseInt(discoverSeats || '1', 10) || 1;
    }

    if (queryParams.create) {
      const createSeats = queryParams.create as string | null;
      this.form.create = parseInt(createSeats || '1', 10) || 1;
    }

    this.getApps();
  }

  created() {
    //keep track of editable state to show/hide totals
    this.$watch('$data.editable', () => {
      this.$emit('editState', !this.editable);
    });
  }
}
