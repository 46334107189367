































































































































































import Vue from 'vue';
import AlertDisplay from '@/components/common/AlertDisplay.vue';
import { AlertDisplay as AlertDisplayData } from '@/types/AlertMessage';
import Button from '@/components/common/buttons/Button.vue';
import DiscoverCategoryService from '@/services/DiscoverCategoryService';
import Select from '@/components/common/form/Select.vue';
import YourShortcutsCard from '@/components/discover/cards/YourShortcutsCard.vue';
import DiscoverSettingsService from '@/services/DiscoverSettingsService';
import { mapGetters } from 'vuex';

type AgeRange = '18+' | '25+' | '35+' | '45+' | '18-34' | '25-44' | '35-54' | '45-64' | null;
type Category = { id: number; name: string };
type GenderKey = 'F' | 'M' | null;

async function fetchCategories() {
  try {
    return await new DiscoverCategoryService().getCategories('content');
  } catch (err) {
    console.error(err);
  }
}

async function fetchDesignatedMarketAreas() {
  try {
    return await new DiscoverCategoryService().getCategories('designated-market-area');
  } catch (err) {
    console.error(err);
  }
}

async function updateShortcuts(
  brandId: number,
  shortcutSelections: { categoryIds: number[] | null[]; dma?: number; age?: string; gender?: string }
) {
  try {
    return await new DiscoverSettingsService().saveShortcuts(brandId, shortcutSelections);
  } catch (err) {
    console.error(err);
  }
}

export default Vue.extend({
  components: {
    AlertDisplay,
    Button,
    Select,
    YourShortcutsCard,
  },

  data() {
    const age: AgeRange = null;
    const ageTarget: { key: AgeRange | null; value: string }[] = [
      { key: null, value: 'Any' },
      { key: '18+', value: '18+' },
      { key: '25+', value: '25+' },
      { key: '35+', value: '35+' },
      { key: '45+', value: '45+' },
      { key: '18-34', value: '18-34' },
      { key: '25-44', value: '25-44' },
      { key: '35-54', value: '35-54' },
      { key: '45-64', value: '45-64' },
    ];

    const alertMessageFailure: AlertDisplayData = {
      header: 'Oops! Something went wrong.',
      type: 'critical',
      message: 'Please try again in a few minutes.',
    };
    const alertMessageSuccess: AlertDisplayData = {
      header: 'Feed settings updated',
      type: 'success',
      links: [{ route: '/discover', message: 'View your updated Trending Feed' }],
    };
    const categories: Category[] = [];
    const designatedMarketAreas: Category[] = [];
    const displayAlertMessageFailure = false;
    const displayAlertMessageSuccess = false;
    const gender: GenderKey = null;
    const genderTarget: { key: GenderKey; value: 'Any' | 'Female' | 'Male' }[] = [
      { key: null, value: 'Any' },
      { key: 'M', value: 'Male' },
      { key: 'F', value: 'Female' },
    ];
    const isSaving = false;
    const selectedCategory1: number | null = null;
    const selectedCategory2: number | null = null;
    const selectedCategory3: number | null = null;
    const selectedDesignatedMarketArea: number | null = null;

    return {
      age,
      ageTarget,
      alertMessageFailure,
      alertMessageSuccess,
      categories,
      designatedMarketAreas,
      displayAlertMessageFailure,
      displayAlertMessageSuccess,
      gender,
      genderTarget,
      isSaving,
      selectedCategory1,
      selectedCategory2,
      selectedCategory3,
      selectedDesignatedMarketArea,
    };
  },

  computed: {
    ...mapGetters(['getAdminEmail', 'features']),
  },

  methods: {
    // On upgrade CTA
    onUpgrade(): void {
      const ecommFeatureIsEnabled = this.features.includes('ecomm');
      if (ecommFeatureIsEnabled) {
        this.$router.push('/subscriptions');
      } else {
        window.open('https://content.futuricontentcloud.com/contact', '_blank');
      }
    },

    getCategories: async function () {
      const categories = await fetchCategories();

      if (!categories) {
        return;
      }

      this.categories = categories;
    },

    getDesignatedMarketAreas: async function () {
      const dmas = await fetchDesignatedMarketAreas();

      if (!dmas) {
        return;
      }

      this.designatedMarketAreas = dmas;
    },

    getDisabledCategories(categoriesToDisable: number[]) {
      const nonNullCategoriesToDisable = categoriesToDisable.filter((cat) => cat);
      const disabled = this.categories.filter(({ id }) => nonNullCategoriesToDisable.includes(id)).map(({ id }) => id);

      return disabled;
    },

    prependNoCategorySelectOption(categories: Category[]) {
      return [{ id: null, name: 'None' }, ...categories];
    },

    async saveSettings() {
      this.isSaving = true;
      const age = this.age ?? undefined;
      const gender = this.gender ?? undefined;
      const dma = this.selectedDesignatedMarketArea ?? undefined;

      try {
        await updateShortcuts(this.brandId, {
          categoryIds: [this.selectedCategory1, this.selectedCategory2, this.selectedCategory3].filter((_) => _),
          dma,
          age,
          gender,
        });

        this.displayAlertMessageSuccess = true;
      } catch (err) {
        this.displayAlertMessageFailure = true;
      } finally {
        this.isSaving = false;

        setTimeout(() => {
          this.displayAlertMessageFailure = false;
          this.displayAlertMessageSuccess = false;
        }, 10000);
      }
    },

    async fetchBrandGroupSettings() {
      if (this.brandId) {
        const groupSettings: any = await new DiscoverCategoryService().getDiscoverSettings(this.brandId);

        if (groupSettings) {
          const { categoryIds, ageOver, ageUnder, marketAreaGenderPreference } = groupSettings;
          const categories = categoryIds ? categoryIds.split(',') : null;
          this.selectedCategory1 = categories[0] ? categories[0].toString() : null;
          this.selectedCategory2 = categories[1] ? categories[1].toString() : null;
          this.selectedCategory3 = categories[2] ? categories[2].toString() : null;

          const demographics = marketAreaGenderPreference ? marketAreaGenderPreference.split(',') : null;
          if (demographics) {
            this.selectedDesignatedMarketArea = demographics[0] ? demographics[0].toString() : '';
            this.gender = demographics[1] ? demographics[1].toString() : '';
          }

          const targetAges = ageOver ? `${ageOver}${ageUnder ? '-' : '+'}${ageUnder ? ageUnder : ''}` : '';
          this.$data.age = targetAges;
        }
      }
    },
  },

  mounted: async function () {
    await Promise.all([this.getCategories(), this.getDesignatedMarketAreas(), this.fetchBrandGroupSettings()]);
  },

  props: ['brandId'],

  watch: {
    selectedDesignatedMarketArea(newDma) {
      if (!newDma) {
        this.age = null;
        this.gender = null;
      }
    },
  },
});
