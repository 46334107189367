








































































import { Vue, Component, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import PlatformViewLayout from '@/audience/layouts/PlatformViewLayout.vue';
import ViewHeader from '@/audience/components/ViewHeader.vue';
import DashboardDoughnutChart from '@/audience/components/dashboard/DashboardDoughnutChart.vue';
import DashboardLineChart from '@/audience/components/dashboard/DashboardLineChart.vue';
import GrowthTips from '@/audience/components/dashboard/GrowthTips.vue';
import ContextTotalCard from '@/audience/components/ContextTotalCard.vue';
import MetricList from '@/audience/components/MetricList.vue';

import { getChartDataByPlatform, getMetricTotalsByContextAndDataIndex } from '@/audience/core/utils/chart';
import { calculatePercentChange } from '@/audience/data/chart-plugins/util';
import { PlatformLabels } from '@/audience/data/socialPlatformChartConfig';

import moment from 'moment-timezone';

import UserSettingsModule from '@/utils/userSettings';

import type {
  DateRange,
  PlatformAnalyticsContext,
  SocialAnalytics,
  ApiPlatformLabel,
  ActiveSocialAccount,
  ChartDataByPlatform,
} from '@/types/audience';

const audienceModule = namespace('AudienceAnalyticsStore');
const UserSettings = new UserSettingsModule();

@Component({
  name: 'Dashboard',
  components: {
    PlatformViewLayout,
    ViewHeader,
    DashboardDoughnutChart,
    DashboardLineChart,
    GrowthTips,
    ContextTotalCard,
    MetricList,
  },
})
export default class AudienceDashboard extends Vue {
  @audienceModule.Getter dateRange!: DateRange;
  @audienceModule.Getter timeZone!: string;
  @audienceModule.Getter activeSocialAccounts!: ActiveSocialAccount[];
  @audienceModule.Getter('extendedSocialAnalytics') socialAnalytics!: SocialAnalytics;
  @audienceModule.Action getAyrShareConnectUrl;

  context: PlatformAnalyticsContext =
    (UserSettings.get('context') as PlatformAnalyticsContext | undefined) ?? 'audience';

  onContextChange(context: PlatformAnalyticsContext) {
    this.context = context;
    UserSettings.save('context', context);
  }

  get contextOptions() {
    return [
      { label: 'Audience', value: 'audience' },
      { label: 'Impressions', value: 'impressions' },
      { label: 'Engagement', value: 'engagement' },
    ];
  }

  @Watch('context', { immediate: true })
  onContextValueChange() {
    const newPlatformLabel: PlatformLabels | undefined = PlatformLabels[this.platform.toUpperCase()];
    if (newPlatformLabel && this.excludedPlatformLabels.includes(newPlatformLabel)) {
      this.platform = 'all';
    }
  }

  platform: ApiPlatformLabel | 'all' = (UserSettings.get('platform') as ApiPlatformLabel | undefined) ?? 'all';

  onPlatformInput($event: Event) {
    const { value } = $event.target as HTMLInputElement;
    this.platform = value as ApiPlatformLabel;
    UserSettings.save('platform', value);
  }

  get excludedPlatformLabels() {
    return this.context === 'audience' ? [PlatformLabels.TIKTOK, PlatformLabels.PINTEREST] : [];
  }

  get platformOptions() {
    const activePlatformLabels = this.activeSocialAccounts.map(({ platform }) => platform);
    const allOption = [{ label: 'All', value: 'all' }];

    const activePlatformOptions = Object.values(PlatformLabels)
      .map((label) => ({ label, value: label.toLowerCase() }))
      .filter(({ value }) => activePlatformLabels.includes(value as ApiPlatformLabel));

    const displayedPlatformOptions = activePlatformOptions.filter(
      (option) => !this.excludedPlatformLabels.includes(option.label)
    );

    return [...allOption, ...displayedPlatformOptions];
  }

  get layoutConfig() {
    return {
      isDashboard: true,
      userHasNoConnectedPlatforms: this.activeSocialAccounts.length === 0,
      leftContent: {
        heading: 'Snapshot',
        subHeading: this.displayedDateRange,
      },
      rightContent: {
        heading: 'Over time',
      },
    };
  }

  get headerConfig() {
    return {
      heading: 'Social impact by channel',
      showSubHeading: true,
      subHeading: '',
      platformLabel: '',
    };
  }

  get displayedDateRange() {
    const start = moment.tz(this.dateRange.start, this.timeZone).format('MMM DD[,] YYYY');
    const end = moment.tz(this.dateRange.end, this.timeZone).format('MMM DD[,] YYYY');
    return `${start} vs. ${end}`;
  }

  get platformsData(): Record<string, ChartDataByPlatform> {
    const activePlatforms = this.activeSocialAccounts.map(
      ({ platform }) => PlatformLabels[platform.toUpperCase()]
    ) as string[];
    const platformsData = {};
    activePlatforms.forEach((platformLabel) => {
      platformsData[platformLabel] = getChartDataByPlatform(platformLabel, this.socialAnalytics);
    });
    return platformsData;
  }

  get contextTotalCardConfig() {
    const title = `Total ${this.context}`;

    if (this.platform === 'all') {
      const initialAccumulatedTotal = this.metricTotals.reduce((acc, metricTotal) => {
        acc += metricTotal.initialValue || 0;
        return acc;
      }, 0);
      const finalAccumulatedTotal = this.metricTotals.reduce((acc, metricTotal) => {
        acc += metricTotal.value || 0;
        return acc;
      }, 0);

      const percentChange = calculatePercentChange(initialAccumulatedTotal, finalAccumulatedTotal);

      return {
        title,
        value: finalAccumulatedTotal,
        percentChange,
      };
    }

    const platform = PlatformLabels[this.platform.toUpperCase()];
    const len = this.platformsData[platform]?.[this.context]?.[0]?.data.length;
    if (!len) {
      return {
        title,
        value: NaN,
        percentChange: NaN,
      };
    }

    const initialValue = getMetricTotalsByContextAndDataIndex(this.platformsData[platform], this.context, 0);
    const finalValue = getMetricTotalsByContextAndDataIndex(this.platformsData[platform], this.context, len - 1);
    const percentChange = calculatePercentChange(initialValue, finalValue);
    return {
      title,
      value: finalValue,
      percentChange,
    };
  }

  get metricTotalsTitle(): string {
    return this.platform === 'all' ? 'All channels' : PlatformLabels[this.platform.toUpperCase()];
  }

  get metricTotals(): { label: string; initialValue?: number; value: number; percentChange: number }[] {
    if (this.platform === 'all') {
      const filteredPlatformsDataEntries = Object.entries(this.platformsData).filter(
        ([key]) => !this.excludedPlatformLabels.includes(key as PlatformLabels)
      );

      return filteredPlatformsDataEntries.map(([platformLabel, platformMetrics]) => {
        const metricsByContext = platformMetrics?.[this.context] ?? [];
        const totals = {
          initial: 0,
          final: 0,
        };
        const finalAccumulatedTotalByContext = metricsByContext.reduce((acc, dataset) => {
          const initialValue = dataset.data[0] || 0;
          const finalValue = dataset.data[dataset.data.length - 1] || 0;
          acc.initial += initialValue;
          acc.final += finalValue;
          return acc;
        }, totals);

        const percentChange = calculatePercentChange(
          finalAccumulatedTotalByContext.initial,
          finalAccumulatedTotalByContext.final
        );

        return {
          label: platformLabel,
          // initialValue only used when this.platform is set to 'all'
          initialValue: finalAccumulatedTotalByContext.initial,
          value: finalAccumulatedTotalByContext.final,
          percentChange,
        };
      });
    }
    const platform = PlatformLabels[this.platform.toUpperCase()];
    return (
      this.platformsData[platform]?.[this.context]?.map((metric) => {
        const initialValue = metric.data[0];
        const finalValue = metric.data[metric.data.length - 1];
        const percentChange = calculatePercentChange(initialValue, finalValue);
        return {
          label: metric.label,
          value: finalValue,
          percentChange,
        };
      }) ?? []
    );
  }

  async onConnectClick() {
    try {
      const url = await this.getAyrShareConnectUrl();
      const anchorEl = document.createElement('A') as HTMLAnchorElement;
      anchorEl.href = url;
      anchorEl.target = '_blank';
      const body = document.getElementsByTagName('BODY')[0];
      body.appendChild(anchorEl);
      anchorEl.click();
      body.removeChild(anchorEl);
    } catch (e) {
      console.error(e);
    }
  }

  get showFacebookDisclaimer() {
    // Facebook Analytics data is only available on Pages with 100 or more likes (aka followers).
    if (this.activeSocialAccounts.length === 0) return false;

    const isFacebookAccountLinked = !!this.activeSocialAccounts.find(
      ({ platform }) => platform === PlatformLabels.FACEBOOK.toLowerCase()
    );
    if (!isFacebookAccountLinked) return false;

    const facebookDataset = Object.values(this.socialAnalytics)
      .filter((analyticsByDate) => analyticsByDate?.[PlatformLabels.FACEBOOK]?.followers)
      .map((analyticsByDate) => analyticsByDate?.[PlatformLabels.FACEBOOK]?.followers ?? 0);

    const datasetLen = facebookDataset.length;
    if (!datasetLen) return false; // No data, can't make determination

    return facebookDataset.every((val) => val === 0);
  }

  get showTikTokDisclaimer() {
    // Twitter does not provide follower analytics at this time. - BDP 10/09/22
    if (this.activeSocialAccounts.length === 0) return false;

    const isTikTokAccountLinked = !!this.activeSocialAccounts.find(
      ({ platform }) => platform === PlatformLabels.TIKTOK.toLowerCase()
    );
    const isAudienceContext = this.context === 'audience';
    return isTikTokAccountLinked && isAudienceContext;
  }

  get showPinterestDisclaimer() {
    if (this.activeSocialAccounts.length === 0) return false;

    // Pinterest does not provide follower analytics at this time. - BDP 10/09/22
    const isTikTokAccountLinked = !!this.activeSocialAccounts.find(
      ({ platform }) => platform === PlatformLabels.PINTEREST.toLowerCase()
    );
    const isAudienceContext = this.context === 'audience';
    return isTikTokAccountLinked && isAudienceContext;
  }
}
