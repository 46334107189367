













import { Vue } from 'vue-property-decorator';
import Button from '@/components/common/buttons/Button.vue';
export default Vue.extend({
  components: {
    Button,
  },
  props: {
    redirectOnLoginLink: String,
  },
  computed: {
    loginRoute() {
      const query = this.redirectOnLoginLink ? `?redirect=${this.redirectOnLoginLink}` : '';
      return `/login${query}`;
    },
  },
});
