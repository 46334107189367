





















































































































































import { Component, Vue } from 'vue-property-decorator';
import { Getter, namespace } from 'vuex-class';

import Breadcrumb from '@/components/common/navigation/breadcrumb/Breadcrumb.vue';
import AlertDisplay from '@/components/common/AlertDisplay.vue';
import Select from '@/components/common/form/Select.vue';
import Input from '@/components/common/form/Input.vue';

import errorMessages from '@/utils/errorMessages.json';
import Label from '@/components/common/form/Label.vue';
import { required } from 'vuelidate/lib/validators';

import { countriesStatesAndProvinces, validateZipByCountry } from '@/components/ecomm/countryData';

import type { AlertDisplay as AlertDisplayData } from '@/types/AlertMessage';
import type { ChargifyInstance } from '@/types/ecomm/ChargifyJs';
import type { AuthenticatedUser } from '@/models/UserModel';

const EcommStore = namespace('EcommStore');

const validateZip = (value, vm) => {
  const country = vm.country;
  return validateZipByCountry(value, country);
};

Component.registerHooks(['validations']);

@Component({
  name: 'AddPaymentMethod',
  components: {
    Breadcrumb,
    AlertDisplay,
    Label,
    Select,
    Input,
  },
})
export default class AddPaymentMethod extends Vue {
  @Getter('authenticatedUser') user!: AuthenticatedUser;
  @EcommStore.Action createPaymentProfile!: ({ chargifyToken: string, useCardAsDefault: boolean }) => void;

  alertMessage: AlertDisplayData | null = null;
  chargify: ChargifyInstance | null = null;
  displayAlert = false;
  cardError = false;
  countryData = countriesStatesAndProvinces() || [];
  form = {
    firstName: '',
    lastName: '',
    address: '',
    address2: '',
    chargifyJsToken: '',
    city: '',
    country: '',
    state: '',
    zip: '',
    useCardAsDefault: [] as string[],
  };
  formIsLoading = false; // is form submitting?
  selectedCountryIndex = 0;
  showAddress2 = false;
  stateProvinceLabel: 'State' | 'Province' = 'State';
  zipPostalCodeLabel: 'Zip code' | 'Postal code' = 'Zip code';

  breadcrumbItems = [
    { text: 'Account Overview', to: { name: 'Dashboard' } },
    { text: 'Subscriptions & billing', to: { path: '/subscriptions', query: { tab: 'billing' } } },
    { text: 'Add payment profile', active: true },
  ];

  validations() {
    return {
      form: {
        firstName: { required },
        lastName: { required },
        address: { required },
        city: { required },
        state: { required },
        zip: {
          required,
          validateZip,
        },
      },
    };
  }

  get defaultCardOptions() {
    return [{ label: 'Use as default payment method', value: 'yes' }];
  }

  onCancel() {
    this.routeBackToBilling();
  }

  routeBackToBilling(showNewCardAlert = false) {
    this.$router.push({
      path: '/subscriptions',
      query: {
        tab: 'billing',
        ...(showNewCardAlert ? { showNewCardAlert: '1' } : {}),
      },
    });
  }

  onSubmit() {
    this.cardError = false;

    // Touch all fields
    this.$v.form.$touch();

    // if form's validated
    if (!this.$v.form.$invalid) {
      this.getChargifyJsToken();
    }
  }

  handleInputChange(e) {
    this.form[e.target.name] = e.target.value;
  }

  handleCountryChange(e) {
    this.selectedCountryIndex = this.countryData.findIndex((country) => country.abbreviation === e.target.value);
    if (e.target.value === 'CA') {
      this.stateProvinceLabel = 'Province';
      this.zipPostalCodeLabel = 'Postal code';
    } else {
      this.stateProvinceLabel = 'State';
      this.zipPostalCodeLabel = 'Zip code';
    }
    this.form.country = e.target.value;
    this.handleInputChange(e);
  }

  handleStateChange(e) {
    this.form.state = e.target.value;
    this.handleInputChange(e);
  }

  loadChargifyJs() {
    this.chargify?.load({
      selector: '#chargify-form', // selector where the iframe will be rendered
      publicKey: process.env.VUE_APP_CHARGIFY_JS_PUBLIC_KEY ?? '',
      type: 'card',
      hideCardImage: true,
      serverHost: process.env.VUE_APP_CHARGIFY_SERVER_HOST ?? '',
      style: {
        input: {
          backgroundColor: 'transparent',
          color: '#222046',
          fontSize: '17px',
          letterSpacing: '0',
          opacity: '1',
          '::placeholder': {
            color: '#6E6E7A',
          },
          '::after': {
            paddingTop: '16px !important',
          },
        },
      },
      fields: {
        number: {
          style: {
            field: {
              maxWidth: '65%',
            },
            input: {
              background: 'url(https://js.chargify.com/latest/images/default.svg) no-repeat 16px 16px',
              padding: '14px 16px 14px 80px',
            },
          },
        },
        month: {
          required: true,
        },
        year: {
          required: true,
        },
        cvv: {
          required: true,
        },
      },
    });
  }

  getChargifyJsToken() {
    const form = this.$refs.form as HTMLFormElement;
    this.formIsLoading = true;
    this.chargify?.token(
      form,
      // success
      (token) => {
        this.form.chargifyJsToken = token;
        this.displayAlert = false;
        this.submitRequest();
      },
      // error
      (err) => {
        this.alertMessage = {
          type: 'critical',
          header: `We couldn't authorize your card.`,
          message: this.parseCardErrors(err),
        };
        this.formIsLoading = false;
        this.displayAlert = true;
      }
    );
  }

  async submitRequest() {
    const { chargifyJsToken, useCardAsDefault } = this.form;
    const _useCardAsDefault = useCardAsDefault.includes('yes');
    try {
      await this.createPaymentProfile({
        chargifyToken: chargifyJsToken,
        useCardAsDefault: _useCardAsDefault,
      });
      this.routeBackToBilling(true);
    } catch (err) {
      this.alertMessage = {
        type: 'critical',
        header: `We couldn't authorize your card.`,
        message: this.parseCardErrors(err),
      };
      this.displayAlert = true;
    } finally {
      this.formIsLoading = false;
    }
  }

  get errorMessages() {
    return errorMessages;
  }

  parseCardErrors(errors) {
    if (errors?.errors) {
      //set card error to true to mimic the chargify card error style
      this.cardError = true;
      return errors.errors;
    } else if (errors.invalidFields && errors.invalidFields.includes('number')) {
      return 'The card number you provided is incorrect. Please try again.';
    } else if (
      (errors.invalidFields && errors.invalidFields.includes('month')) ||
      errors.invalidFields.includes('year')
    ) {
      return 'The expiration date you provided is incorrect. Please try again.';
    } else if (errors.invalidFields && errors.invalidFields.includes('cvv')) {
      return 'The CVV code you provided is incorrect. Please try again.';
    } else {
      return errors.message ? errors.message : '';
    }
  }

  mounted() {
    const { Chargify } = window;
    this.chargify = new Chargify();
    this.loadChargifyJs();
  }
}
