import store from '@//store';
import type { SSE } from '@/types/SSE';
import type {
  AudienceNotificationSocialAnalyticsRefreshedData as SocialAnalyticsData,
  AudienceNotificationSocialChannelConnectedData as ConnectedChannelData,
  AudienceNotificationSocialChannelDisconnectedData as DisconnectedChannelData,
  AudienceNotificationSocialChannelRefreshedData as ChannelRefreshedData,
} from 'content-cloud-types/dist/types/audience/Notification';

export const socialAnalyticsRefreshed = (msgServer: SSE) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  msgServer.subscribe('social_analytics_refreshed', (_data: SocialAnalyticsData) => {
    store.dispatch('AudienceAnalyticsStore/getSocialAnalytics');
  });
};

export const socialChannelConnected = (msgServer: SSE) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  msgServer.subscribe('social_channel_connected', (data: ConnectedChannelData) => {
    store.dispatch('AudienceAnalyticsStore/getProfile', true);
  });
};

export const socialChannelDisconnected = (msgServer: SSE) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  msgServer.subscribe('social_channel_disconnected', (data: DisconnectedChannelData) => {
    store.dispatch('AudienceAnalyticsStore/getProfile', true);
  });
};

export const socialChannelRefreshed = (msgServer: SSE) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  msgServer.subscribe('social_channel_refreshed', (data: ChannelRefreshedData) => {
    store.dispatch('AudienceAnalyticsStore/getProfile', true);
  });
};
