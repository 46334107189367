<template>
  <div class="import_rss_feed__container">
    <fm-heading>
      <h1 class="global__title">Import RSS feed</h1>
    </fm-heading>
    <div class="global__subtitle">
      You can import content from other podcasting platforms directly into a show in POST. For RSS feeds with a large
      amount of content, this process may take several hours to complete. You may close POST once the process has been
      initiated.
    </div>
    <div class="global__separator" />
    <!-- Source -->
    <div class="import_rss_feed__form-section">
      <div class="import_rss_feed__form-section-title">
        <h3>Source RSS feed</h3>
        <fm-body variant="MEDIUM"> Copy the URL for the RSS feed from your old podcasting platform. </fm-body>
      </div>
      <div class="import_rss_feed__form-section-inputs">
        <fm-input :error="existingRSSUrlError" label="Old RSS feed URL" v-model-wc="$v.existingRSSUrl.$model" />
      </div>
    </div>
    <div class="global__separator" />
    <!-- Destination -->
    <div class="import_rss_feed__form-section">
      <div class="import_rss_feed__form-section-title">
        <h3>Destination in POST</h3>
        <fm-body variant="MEDIUM">
          Select which show and RSS feed to import your content into. You’ll need to have these set up in POST before
          importing your content.
        </fm-body>
      </div>
      <div class="import_rss_feed__form-section-inputs">
        <fm-select
          :error="selectedShowError"
          :options.prop="showOptions"
          @input="fetchSelectedShow"
          label="Destination show"
          placeholder=""
          v-model-wc="$v.selectedShow.$model"
          :link.prop="{
            label: 'Set up a new show',
            onClick: () => {
              this.$router.push(showSettingsRoute);
            },
          }"
        />
        <div v-if="selectedShowData && selectedShowData.id" class="import_rss_feed__destination-feed">
          <span class="material-icons"> subdirectory_arrow_right </span>
          <fm-multiselect
            :error="selectedTargetRSSFeedsError"
            :options.prop="selectedShowRSSFeedsOptions"
            label="Destination RSS feed"
            placeholder="Select one or more feeds"
            style="width: 100%"
            v-model-wc="$v.selectedTargetRSSFeeds.$model"
            :link.prop="{
              label: 'Set up a new feed for this show',
              onClick: () => {
                this.$router.push(showSettingsRoute);
              },
            }"
          />
        </div>
      </div>
    </div>
    <div class="global__separator" />
    <div class="import_rss_feed__submit">
      <fm-button-primary @click="onSubmitClicked" :disabled="!canSubmit || isBusy"> Begin import </fm-button-primary>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers, mapGetters as mapRootGetters } from 'vuex';
const { mapActions, mapGetters } = createNamespacedHelpers('CreateAndPublishStore');
import { url, required } from 'vuelidate/lib/validators';

export default {
  name: 'ImportRSSFeed',

  data() {
    return {
      existingRSSUrl: '',
      isBusy: false,
      selectedShow: null,
      selectedTargetRSSFeeds: [],
    };
  },

  validations: {
    existingRSSUrl: { required, url },
    selectedShow: { required },
    selectedTargetRSSFeeds: { required },
  },

  computed: {
    ...mapGetters({
      selectedShowData: 'activeShow',
      shows: 'unfilteredShows',
      smcs: 'allSocialMediaConnections',
    }),

    ...mapRootGetters(['selectedBrand']),
    ...mapGetters(['settings']),

    // Validation error
    existingRSSUrlError() {
      if (this.$v.existingRSSUrl.$dirty && !!this.$v.existingRSSUrl.$error) {
        if (!this.$v.existingRSSUrl.url) return 'Invalid URL';
        if (!this.$v.existingRSSUrl.required) return 'Required';
        return '';
      }
      return '';
    },

    // Validation error
    selectedTargetRSSFeedsError() {
      return (this.$v.selectedTargetRSSFeeds.$dirty && !!this.$v.selectedTargetRSSFeeds.$error && 'Required') || '';
    },

    // Validation error
    selectedShowError() {
      return (this.$v.selectedShow.$dirty && !!this.$v.selectedShow.$error && 'Required') || '';
    },

    showOptions() {
      return this.shows.map(({ id, title }) => ({ value: id, label: title }));
    },
    // Check if show has SMC, get full SMC object by ID, build dropdown options - GK
    selectedShowRSSFeedsOptions() {
      let result = [];
      const fullSMCs = [];
      const _hasSMCs =
        this.selectedShowData &&
        this.selectedShowData.social_media_connections &&
        this.selectedShowData.social_media_connections.length > 0;
      // If show has SMCs, get full objects
      if (_hasSMCs) {
        this.selectedShowData.social_media_connections.forEach((smc) => {
          const _smcToAdd = this.smcs.find((e) => e.id === smc.id);
          if (_smcToAdd) fullSMCs.push(_smcToAdd);
        });
      }
      // Build options
      if (fullSMCs) {
        result = fullSMCs
          .filter((smc) => smc.type === 'rss')
          .map(({ id, title }) => ({
            value: (id && id.toString()) || '',
            label: title,
          }));
      }
      return result;
    },

    canSubmit() {
      return !this.$v.$invalid;
    },

    showSettingsRoute() {
      return `/create/${this.selectedBrand?.id}/podcasts/shows`;
    },
  },

  methods: {
    ...mapActions(['fetchShowData', 'clearActiveShow', 'importExternalRSSFeed']),

    async fetchSelectedShow(e) {
      await this.resetFormOnShowChange();

      if (!e.target.value) return;

      this.isBusy = true;
      await this.fetchShowData(e.target.value);
      this.isBusy = false;
    },

    async resetFormOnShowChange() {
      await this.clearActiveShow(); // causes this.selectedShowRSSFeedsOptions to compute new value
      this.selectedTargetRSSFeeds = [];
      this.$nextTick(() => {
        this.$v.selectedTargetRSSFeeds.$reset();
      });
    },

    async resetFormOnSubmit() {
      await this.clearActiveShow();
      this.existingRSSUrl = '';
      this.selectedShow = null;
      this.selectedTargetRSSFeeds = [];
      this.$nextTick(() => {
        this.$v.$reset();
      });
    },

    async onSubmitClicked() {
      this.$v.$touch();
      if (!this.canSubmit) return;

      try {
        this.isBusy = true;

        // Convert feeds to numbers
        const _selectedTargetRSSFeedsAsNumbers =
          (this.selectedTargetRSSFeeds && this.selectedTargetRSSFeeds.map((feed) => Number(feed))) || [];

        const payload = {
          url: this.existingRSSUrl,
          show_id: Number(this.selectedShow),
          social_media_connections: _selectedTargetRSSFeedsAsNumbers,
          // bitrate: this.$store.getters.settings.bitrate,
          // station: this.$store.getters.settings.station_name.toLowerCase(),
          bitrate: this.settings.bitrate,
          station: this.settings.station_name.toLowerCase(),
          job_type: 'podcast_import',
        };
        await this.importExternalRSSFeed(payload);
        this.setSuccessMessage();
        this.resetFormOnSubmit();
      } catch (err) {
        console.error(err);
        this.setFailureMessage(err);
      } finally {
        this.isBusy = false;
      }
    },

    setSuccessMessage() {
      this.$store.commit('SET_MESSAGE', {
        name: 'Import RSS',
        details: 'Feed successfully submitted for import',
        type: 'success',
      });
      setTimeout(() => {
        this.$store.commit('DISMISS_MESSAGE');
      }, 3500);
    },

    setFailureMessage(err) {
      console.log('this.$store', this.$store);
      if (err && typeof err.message === 'string' && err.message.includes('400')) {
        this.$store.commit('SET_MESSAGE', {
          name: 'Import RSS',
          details: 'Error: Feed was rejected for import',
          type: 'error',
        });
      } else {
        this.$store.commit('SET_MESSAGE', {
          name: 'Import RSS',
          details: 'Error: Unable to talk to server to import feed',
          type: 'error',
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.import_rss_feed {
  &__container {
    padding: 4rem 3rem 0 3rem;
  }

  &__submit {
    display: flex;
    justify-content: center;
    margin-top: 50px;
    width: 100%;
  }

  &__form-section {
    display: flex;
    max-width: 80%;
    @media only screen and (max-width: 1400px) {
      max-width: 100%;
    }
  }

  &__form-section-title {
    display: flex;
    flex-direction: column;
    max-width: 451px;
    width: 100%;
    h3 {
      color: var(--neutral-grey-1-text);
      font-size: 24px;
      margin-bottom: 8px;
    }
  }

  &__form-section-inputs {
    width: 100%;
    padding-left: 116px;
    @media only screen and (max-width: 1080px) {
      padding-left: 32px;
    }
  }

  &__destination-feed {
    align-items: center;
    display: flex;
    span {
      margin-right: 18px;
    }
  }
}
</style>
