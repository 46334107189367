







import { Component, Vue } from 'vue-property-decorator';

@Component({
  name: 'ConnectPlatformsInfoBanner',
})
export default class ConnectPlatformsInfoBanner extends Vue {
  onGetStartedClick() {
    this.$emit('on-connect-platforms-click');
  }
}
