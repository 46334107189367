import { RouteConfig } from 'vue-router';
import Unauthorized from '@/views/Unauthorized.vue';
import { allowAnonymousMeta } from '@/constants/router/permissions';

export default [
  {
    path: '/401',
    name: '401 Unauthorized',
    component: Unauthorized,
    meta: { ...allowAnonymousMeta, title: '401 Unauthorized' },
  },
  {
    path: '/unauthorized',
    name: '403 Unauthorized',
    component: Unauthorized,
    meta: { ...allowAnonymousMeta, title: '403 Unauthorized' },
  },
  {
    path: '/404',
    name: '404 Page Not Found',
    component: Unauthorized,
    meta: { ...allowAnonymousMeta, title: '404 Page Not Found' },
  },
] as RouteConfig[];
