<template>
  <fm-drawer :visible="true">
    <div slot="content" class="add-connection__wrapper">
      <close-button class="close-button" @click="$emit('onClose')" />

      <div class="add-connection__heading">
        <fm-text tag="h1">
          {{ headerText }}
        </fm-text>
      </div>

      <div class="add-connection__steps">
        <step-bubbles :currentStep="currentStep" :totalSteps="2" />
      </div>

      <div class="add-connection__content">
        <!-- Step 1 Add account information -->
        <div v-if="currentStep === 1">
          <fm-text tag="h2" style="margin-bottom: 32px"> Add widget nickname </fm-text>

          <fm-input
            label="Widget title"
            assistiveText="This is what you’ll see in the publishing destinations list when you create new shows and episodes."
            v-model-wc="$v.form.title.$model"
            :error="$v.form.title.$error ? 'Required' : ''"
          />
        </div>

        <!-- Step 2 Adjust Settings-->
        <div v-if="currentStep === 2">
          <fm-text tag="h2" style="margin-bottom: 32px"> Adjust settings </fm-text>
          <!-- Choose iHeart connection -->
          <fm-select
            v-if="iHeartOptions.length > 1"
            assistiveText="If selected, posts made to this destination will direct listeners to your iHeart page."
            label="Link this destination to an iHeart page (optional)"
            :options.prop="iHeartOptions"
            v-model-wc="form.iHeartValue"
          />
          <!-- Default on -->
          <fm-checkbox
            label="Publish to this destination by default"
            :options.prop="defaultOptions"
            v-model-wc="defaultValue"
          />
        </div>
      </div>

      <div class="add-connection__actions">
        <fm-button-primary
          class="next-button"
          :success="status === 'success'"
          :disabled="!canContinue"
          @click="onSubmit"
        >
          {{ currentStep === 1 ? 'Continue' : 'Save publishing destination' }}
        </fm-button-primary>

        <fm-button-secondary v-if="currentStep > 1" class="back-button" @click="onBackClicked">
          Back
        </fm-button-secondary>

        <!-- Cancel Button -->
        <fm-button-secondary class="cancel-button" @click="$emit('onClose')"> Cancel </fm-button-secondary>
      </div>
    </div>
  </fm-drawer>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
const { mapGetters } = createNamespacedHelpers('CreateAndPublishStore');
const { mapActions } = createNamespacedHelpers('CreateAndPublishStore/showSetup');
import { required } from 'vuelidate/lib/validators';
import StepBubbles from '@/createandpublish/components/integrations/StepBubbles';
import CloseButton from '@/createandpublish/components/CloseButton';

export default {
  name: 'AddWidgetConnectionDrawer',

  components: {
    StepBubbles,
    CloseButton,
  },

  props: {
    editingSMC: { type: Object, default: null },
  },

  data() {
    return {
      defaultValue: [],
      isLoading: false,
      status: null,
      form: {
        title: '',
        iHeartValue: '',
      },
    };
  },

  validations: {
    form: {
      title: { required },
    },
  },

  computed: {
    ...mapGetters({
      station: 'station_name',
    }),
    ...mapGetters(['allSocialMediaConnections', 'stationUrl']),

    isEditing() {
      return this.editingSMC !== null;
    },

    headerText() {
      return this.isEditing ? 'Edit web widget' : 'Create a new web widget';
    },

    // iHeart Options
    iHeartOptions() {
      const smcs = this.allSocialMediaConnections;
      // Options
      const _options = smcs.filter((smc) => smc.type === 'rss' && smc.iheart_show_id);
      // Default empty option
      const result = [
        {
          value: '',
          label: 'No iHeart page',
        },
      ];
      _options.forEach((option) => {
        result.push({
          value: option.iheart_show_id,
          label: option.title,
        });
      });
      return result;
    },

    // Submit/Next is disabled
    canContinue() {
      if (this.$v.form.title.$invalid) {
        return false;
      }

      // API call is pending
      if (this.isLoading) {
        return false;
      }

      // Anything else
      return true;
    },

    currentStep() {
      const _stepQuery = (this.$route.query && this.$route.query.step) || undefined;
      if (_stepQuery) {
        return Number(_stepQuery);
      }
      return undefined;
    },

    // Default options
    defaultOptions() {
      return [
        {
          label: 'Publish to this destination by default',
          value: 'post',
        },
      ];
    },
  },

  methods: {
    ...mapActions(['addSocialMediaConnection', 'editSocialMediaConnection']),

    onBackClicked() {
      if (this.currentStep === 2) {
        this.$router.replace({
          query: {
            newConnection: 'webwidget',
            step: 1,
          },
        });
      }
    },

    async onSubmit() {
      if (this.currentStep === 2) {
        await this.onSaveClicked();
      }
      if (this.currentStep === 1) {
        // Go step 2
        this.$router.replace({
          query: {
            newConnection: 'webwidget',
            step: 2,
          },
        });
      }
    },

    async onSaveClicked() {
      this.isLoading = true;
      const _default_on_post = this.defaultValue.includes('post');
      const { title, iHeartValue } = this.form;
      const payloadFromForm = {
        default_on_post: _default_on_post,
        iheart_show_id: iHeartValue,
        iHeartMedia: !!iHeartValue,
        title,
      };

      if (!this.isEditing) {
        await this.addSocialMediaConnection({
          type: 'webwidget',
          ...payloadFromForm,
        });
      } else {
        await this.editSocialMediaConnection({
          id: this.editingSMC.id,
          ...payloadFromForm,
        });
      }

      this.isLoading = false;
      this.$emit('onClose');
    },
  },

  mounted() {
    if (this.isEditing) {
      const { default_on_post, iheart_show_id, title } = this.editingSMC;
      if (default_on_post) {
        this.defaultValue.push('post');
      }
      this.form = {
        title,
        iHeartValue: iheart_show_id,
      };
    }
  },
};
</script>

<style lang="scss" scoped>
// Styles for this component in /src/styles/integrations/integrations.scss
// and imported in /src/views/Integrations.vue
</style>
