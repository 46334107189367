import HttpClient from '@/services/common/HttpClient';
import store from '@/store';
import type { SocialStoryDetails as SocialStory } from 'content-cloud-types/dist/types/createandpublish/SqlModelInterfaces';

export default class CreateAndPublishSocialStoriesService {
  private http: HttpClient = HttpClient.getInstance();

  private get brandId() {
    const brandId = store.getters.selectedBrand?.id as number | undefined;
    if (!brandId) {
      // Fallback to localStorage
      try {
        const _store = JSON.parse(JSON.stringify(localStorage.getItem('vuex')));
        const brand = JSON.parse(_store)?.BrandStore.selectedBrand;
        return brand.id;
      } catch {
        console.warn('[CreateAndPublishSocialStoriesService] Unable to get brandId from localStorage');
      }
    }
    return brandId;
  }

  // Endpoint prefix definitions.
  private get storiesPrefix() {
    return `/createandpublish/${this.brandId}/stories`;
  }

  public getStories(): Promise<SocialStory[]> {
    return this.http.get(`${this.storiesPrefix}`);
  }
}
