












import { Component, Vue, Watch } from 'vue-property-decorator';
import { namespace, Getter } from 'vuex-class';
import WelcomeTile from '@/audience/components/WelcomeTile.vue';
import AudienceMobileNav from '@/audience/components/AudienceMobileNav.vue';

import UserSettingsModule from '@/utils/userSettings';
import type { Brand } from '@/types/Brand';

const UserSettings = new UserSettingsModule();
const audienceAnalytics = namespace('AudienceAnalyticsStore');

Component.registerHooks(['created']);

@Component({
  name: 'AudienceRoute',
  components: {
    WelcomeTile,
    AudienceMobileNav,
  },
  metaInfo: {
    meta: [{ name: 'viewport', content: 'width=device-width,initial-scale=1.0' }],
  },
})
export default class AudienceRoute extends Vue {
  @Getter('selectedBrand') brand!: Brand;
  @audienceAnalytics.Getter dateRange;
  @audienceAnalytics.Getter socialAnalytics;
  @audienceAnalytics.Action getSocialAnalytics;
  @audienceAnalytics.Action verifyAndUpdateStoreForCurrentBrand;

  get brandId() {
    return this.brand?.id ?? NaN;
  }

  @Watch('brandId')
  onBrandIdChange() {
    this.verifyAndUpdateStoreForCurrentBrand();
  }

  welcomeTile = {
    isOpen: true,
  };
  onCloseWelcomeTile() {
    this.welcomeTile.isOpen = false;
    UserSettings.save('welcomeTile.isOpen', false);
  }

  get showWelcomeTile() {
    return this.$route.name === 'Audience.Dashboard' && this.welcomeTile.isOpen === true;
  }

  isLoading = false;

  async fetchData() {
    this.isLoading = true;
    await this.getSocialAnalytics();
    this.isLoading = false;
  }

  @Watch('dateRange')
  onDateRangeChange() {
    this.fetchData();
  }

  created() {
    this.fetchData();

    const savedUserSetting = UserSettings.get('welcomeTile.isOpen') as boolean | undefined;
    if (typeof savedUserSetting === 'boolean') {
      this.welcomeTile.isOpen = savedUserSetting;
    }
  }
}
