<template>
  <div
    class="audience-sidebar"
    :class="{ checkout: checkout }"
    role="img"
    aria-label="image of cellphone, photos and social media icons"
  >
    <div class="mobile-form-header">
      <router-link to="/">
        <img alt="logo" src="@/assets/FuturiCC.svg" height="61" />
      </router-link>
    </div>
    <div class="mobile-form-title" v-if="checkout">
      <h2>Grow your social audience faster</h2>
      <div class="bullets">
        <ul>
          <li>All of your social, podcast, and video audiences in one place</li>
          <li>Grow faster by seeing what’s engaging your audience</li>
          <li>Get alerts when your audience spikes</li>
          <li>Uncover hidden opportunities</li>
        </ul>
      </div>
    </div>
    <div class="mobile-sidebar-image">
      <img src="@/assets/audience/auth-image_m.jpg" aria-label="image of cellphone, photos and social media icons" />
    </div>
  </div>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  name: 'audience-sidebar',
  props: {
    checkout: {
      type: Boolean,
      defalt: false,
    },
  },
});
</script>

<style lang="scss" scoped>
.audience-sidebar {
  background: url('@/assets/audience/auth-image_d.jpg') no-repeat;
  background-position: top center;
  background-size: contain;
  height: 100vh;
  order: 1;
  width: 100%;

  .mobile-form-header,
  .mobile-sidebar-image {
    display: none;
  }

  .mobile-form-title {
    display: none;
    margin: 32px 0;
    padding: 0 24px;

    h2 {
      font-size: var(--fm-font-size-24);
      line-height: var(--fm-line-spacing-32);
      margin-bottom: 16px;
    }
  }

  .bullets {
    margin-bottom: 64px;

    ul {
      margin: 0;
      padding: 0;
    }

    li {
      background: url('@/assets/audience/arrow.svg') no-repeat;
      background-position: left top;
      font-size: var(--fm-font-size-14);
      line-height: var(--fm-line-spacing-21);
      list-style: none;
      margin-top: 7px;
      padding-left: 36px;
    }
  }

  @media (max-width: 768px) {
    background-image: none;
    height: auto;
    padding-bottom: 50px;

    .mobile-form-header {
      display: flex;
      justify-content: center;
      margin-top: 32px;
    }

    .mobile-form-title {
      display: block;
      margin: 32px auto 0;
      max-width: 500px;
    }

    .mobile-sidebar-image {
      display: block;
      margin-top: 32px;

      img {
        height: auto;
        max-width: 100vw;
      }
    }

    .bullets {
      margin-bottom: 32px;
    }
  }

  // @media (max-width: 1024px) {
  //   background: url('@/assets/audience/auth-image_m.jpg') no-repeat;
  //   background-position: center 80px;
  //   background-size: contain;
  //   min-height: 95vh;
  //   order: 0;

  //   &.checkout {
  //     background-position: center 300px;
  //     min-height: calc(100% + 280px);

  //     // @media (orientation: portrait) {
  //     //   background-size: contain;
  //     // }
  //   }

  //   .mobile-form-header {
  //     display: flex;
  //     justify-content: center;
  //     margin-top: 32px;
  //   }

  //   .mobile-form-title {
  //     display: block;
  //   }

  //   .mobile-sidebar-image {
  //     display: block;

  //     img {
  //       height: auto;
  //       max-width: 100vw;
  //     }
  //   }
  // }

  // @media (max-width: 767px) {
  //   order: 0;
  //   min-height: 590px;

  //   &.checkout {
  //     background-position: center 300px;
  //     min-height: 890px;
  //   }
  // }

  // @media (min-width: 540px) and (max-width: 720px) {
  //   order: 0;
  //   min-height: 740px;

  //   &.checkout {
  //     background-position: center 300px;
  //     min-height: 1040px;
  //   }
  // }

  // // @media (min-width: 411px) and (max-width: 731px) {
  // //   order: 0;
  // //   min-height: 740px;

  // //   &.checkout {
  // //     background-position: center 320px;
  // //     min-height: 800px;

  // //     @media (orientation: landscape) {
  // //       background-position: center 300px;
  // //       background-size: 60%;
  // //     }
  // //   }
  // // }

  // @media (min-width: 360px) and (max-width: 640px) {
  //   order: 0;
  //   min-height: 740px;

  //   &.checkout {
  //     background-position: center 330px;
  //     min-height: 760px;

  //     @media (orientation: landscape) {
  //       background-position: center 300px;
  //       background-size: 60%;
  //     }
  //   }
  // }

  // @media (max-width: 320px) {
  //   order: 0;
  //   min-height: 510px;

  //   &.checkout {
  //     background-position: center 300px;
  //     min-height: 890px;
  //   }
  // }
}
</style>
