import HttpClient from '@/services/common/HttpClient';
import DiscoverRSSFeedsModel from '@/models/DiscoverRSSFeedsModel';
import DiscoverTagStoriesModel from '@/models/DiscoverTagStoriesModel';
import { RSSFeedPayload } from '@/types/RSSFeed';

export default class DiscoverFollowingService {
  private http: HttpClient = HttpClient.getInstance();
  private endpoint = `${process.env.VUE_APP_API_URL}/discover`;

  /*
   * Discover Settings
   */
  public async getRssStories({
    brandId,
    offset,
    limit,
    query,
    feedId,
    campaignId,
    publishedAfter,
  }: RSSFeedPayload): Promise<DiscoverRSSFeedsModel> {
    const searchQuery = query ? `&searchQuery=${query}` : '';
    const feedQuery = feedId && feedId > -1 ? `&feedId=${feedId}` : '';
    const campaignQuery = campaignId && campaignId > -1 ? `&campaignId=${campaignId}` : '';
    const publishedAfterQuery = publishedAfter && publishedAfter > -1 ? `&publishedAfter=${publishedAfter}` : '';
    const querySting = `?offset=${offset}&limit=${limit}${searchQuery}${feedQuery}${campaignQuery}${publishedAfterQuery}`;

    return this.http.get<DiscoverRSSFeedsModel>(
      `${process.env.VUE_APP_API_URL}/brands/${brandId}/rss-stories${querySting}`
    );
  }

  public async tagRSSItemToCampaign(
    brandId: number,
    campaign: number,
    storyId: number
  ): Promise<DiscoverTagStoriesModel> {
    return this.http.post<DiscoverTagStoriesModel>(
      `${process.env.VUE_APP_API_URL}/brands/${brandId}/campaigns/${campaign}/rss-stories/${storyId}`,
      ''
    );
  }

  public async untagCampaignFromRSSItem(
    brandId: number,
    campaign: number,
    storyId: number
  ): Promise<DiscoverTagStoriesModel> {
    return this.http.delete<DiscoverTagStoriesModel>(
      `${process.env.VUE_APP_API_URL}/brands/${brandId}/campaigns/${campaign}/rss-stories/${storyId}`
    );
  }
}
