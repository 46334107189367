<template>
  <div class="show-details-published">
    <fm-search-bar :value="searchTerm" @input="onSearchTermUpdate" placeholder="Search this page by keyword or title" />
    <div class="grid-header">
      <div class="clear mb-2"></div>

      <template v-if="hasEpisodes">
        <!-- TableInfo -->
        <TableInfo
          :currentSort="sort"
          :sortOptions="sortOptions"
          @onSortChange="(value) => updateSort(value)"
          :currentOrder="order"
          :orderOptions="orderOptions"
          @onOrderChange="updateOrder"
          :numberOfElements="totalPlaylistsOfActiveType"
          :currentLayout="currentLayout"
          :layoutOptions="layoutOptions"
          @onLayoutChange="onLayoutChange"
        />
        <!-- Grid here -->
        <div class="show-details__grid-layout" v-if="currentLayout === 'grid'">
          <ShowDetailsGridCard :episode="episode" :key="index" v-for="(episode, index) in filteredEpisodes" />
        </div>
        <!-- Table here -->
        <table style="width: 100%; margin: 0" v-if="currentLayout === 'list'">
          <tbody>
            <TableRow>
              <TableHeaderCell> Episode title </TableHeaderCell>
              <TableHeaderCell> Runtime </TableHeaderCell>
              <TableHeaderCell> Date </TableHeaderCell>
              <TableHeaderCell> Description </TableHeaderCell>
              <TableHeaderCell> Tags </TableHeaderCell>
              <TableHeaderCell> Video </TableHeaderCell>
              <TableHeaderCell> </TableHeaderCell>
            </TableRow>
            <TableRow v-for="(episode, index) in filteredEpisodes" :key="index">
              <TableCell minWidth="230px">
                <div class="d-flex flex-column align-items-start">
                  <span class="show-details__id"> ID: {{ episode.playlist_id }} </span>
                  <span class="show-details__episodeName">
                    {{ episode.name }}
                  </span>
                </div>
              </TableCell>
              <TableCell minWidth="100px">
                <span class="show-details__runTime">
                  {{ episode.time | formatDuration }}
                </span>
              </TableCell>
              <TableCell minWidth="95px">
                <span v-if="!isScheduledTab" class="show-details__schedule-date">{{
                  formatDateTime(episode.published)
                }}</span>
                <span v-else class="show-details__schedule-date">{{ formatDateTime(episode.execute_at) }}</span>
              </TableCell>
              <TableCell minWidth="250px">
                <span class="show-details__description">
                  {{ episode.description }}
                </span>
              </TableCell>
              <TableCell maxWidth="120px">
                <div class="show-details__tags">
                  <span class="material-icons" aria-label="Tags"> local_offer </span>
                  <span>&nbsp;{{ getNumberOfTags(episode.playlist_categories) }}</span>
                </div>
              </TableCell>
              <TableCell maxWidth="100px">
                <download-video-modal :episode="episode" />
                <i-heart-button :playlist="episode" />
              </TableCell>
              <TableCell maxWidth="100px">
                <div class="d-flex w-100 justify-content-end" style="position: relative">
                  <show-details-burger-menu :episode="episode"> </show-details-burger-menu>
                </div>
              </TableCell>
            </TableRow>
          </tbody>
        </table>

        <Pagination
          :currentPage="currentPage"
          :pageSize="pageSize"
          :pageSizeOptions="pageSizeOptions"
          :totalItems="totalPlaylistsOfActiveType"
          :onPageSizeUpdate="onPageSizeChange"
          :onPageUpdate="changePage"
        />
      </template>
      <template v-else>
        <div class="empty">
          <h2 class="empty__heading">Nothing to see here</h2>
          <p class="empty__body">We couldn't find anything!</p>
          <Button class="empty__button" button-type="secondary" @click="goToCreateEpisode">Create an episode</Button>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import moment from 'moment-timezone';
import { createNamespacedHelpers } from 'vuex';
const { mapGetters } = createNamespacedHelpers('CreateAndPublishStore/getPlaylists');
const { mapActions, mapGetters: mapGettersCore } = createNamespacedHelpers('CreateAndPublishStore');
const { mapGetters: mapGettersDraftEpisodes } = createNamespacedHelpers('CreateAndPublishStore/draftEpisodes');

import DownloadVideoModal from './DownloadVideoModal';
import IHeartButton from '@/createandpublish/components/IHeartButton';
import Pagination from '@/components/common/table/Pagination.vue';
import ShowDetailsBurgerMenu from './ShowDetailsBurgerMenu';
import ShowDetailsGridCard from './showDetails/ShowDetailsGridCard.vue';
import TableCell from './common/table/TableCell';
import TableInfo from './tables/TableInfo';
import TableRow from './common/table/TableRow';
import TableHeaderCell from './common/table/TableHeaderCell.vue';
import Button from '@/components/common/buttons/Button.vue';

export default {
  name: 'show-details-published',

  components: {
    DownloadVideoModal,
    IHeartButton,
    Pagination,
    ShowDetailsBurgerMenu,
    ShowDetailsGridCard,
    TableCell,
    TableInfo,
    TableRow,
    TableHeaderCell,
    Button,
  },

  props: {
    show: Object,
    episodes: Array,
    dateType: String,
    isScheduledTab: { type: Boolean, default: false, required: false },
  },

  data() {
    return {
      isDownloadVideoModalOpen: false,
      currentLayout: 'list',
      layoutOptions: ['grid', 'list'],
      sortOptions: [
        {
          value: 'id',
          label: 'Playlist ID',
        },
        {
          value: 'name',
          label: 'Episode title',
        },
        {
          value: 'duration',
          label: 'Runtime',
        },
        {
          value: 'description',
          label: 'Description',
        },
      ],
      orderOptions: [
        {
          value: 'ASC',
          label: 'Asc',
        },
        {
          value: 'DESC',
          label: 'Desc',
        },
      ],
      pageSizeOptions: [
        {
          size: 10,
        },
        {
          size: 25,
        },
        {
          size: 50,
        },
      ],
    };
  },

  computed: {
    ...mapGettersCore(['settings']),
    ...mapGetters(['currentPage', 'pageSize', 'sort', 'order', 'searchTerm', 'totalPlaylistsOfActiveType']),
    ...mapGettersDraftEpisodes(['currentDraftEpisode']),

    layoutIsGrid() {
      return this.filteredEpisodes.length > 0 && this.currentLayout === 'grid';
    },
    layoutIsList() {
      return this.filteredEpisodes.length > 0 && this.currentLayout === 'list';
    },

    timeZone() {
      // return POSTStore.getters.settings.timezone;
      return this.settings.timezone;
    },

    filteredEpisodes() {
      const searchFilter = this.searchTerm.toLowerCase();
      return this.episodes.filter((episode) => {
        return (
          episode.name.toLowerCase().includes(searchFilter) ||
          episode.description.toLowerCase().includes(searchFilter) ||
          (episode.tags && episode.tags.toLowerCase().includes(searchFilter))
        );

        // These don't exist in the `episode` object anymore (endpoint response changed) and
        // they aren't represented in the table -- replaced them with `tags` - GK
        // ||
        // String(episode.playlist_id).includes(searchFilter) ||
        // episode.playlist_categories.findIndex(category => category.name.toLowerCase().includes(searchFilter)) !== -1
      });
    },

    hasEpisodes() {
      return this.filteredEpisodes.length;
    },
  },

  filters: {
    formatDuration(seconds) {
      // so episode length doesn't show 0 minutes unless it actually is
      const totalMins = (seconds / 60).toFixed() === '0' ? (seconds === 0 ? '0' : '1') : (seconds / 60).toFixed();
      if (totalMins > 59) {
        const hours = Math.floor(totalMins / 60);
        const minsRemaining = (totalMins % 60).toFixed();
        return `${hours} hr ${minsRemaining} min`;
      }
      return `${totalMins} min`;
    },
  },

  methods: {
    ...mapActions(['openPreviewPlayer', 'setPlaylistType']),

    // Get number of tags
    getNumberOfTags(categories) {
      if (categories) {
        return categories.length || 0;
      }
      return 0;
    },

    // On layout change
    onLayoutChange() {
      if (this.currentLayout === 'grid') {
        this.currentLayout = 'list';
        localStorage.setItem('episode-layout', 'list');
      } else {
        this.currentLayout = 'grid';
        localStorage.setItem('episode-layout', 'grid');
      }
    },

    // On pageSize change
    async onPageSizeChange(e) {
      if (e.target.value) {
        this.$store.commit('CreateAndPublishStore/getPlaylists/SET_PAGE_SIZE', Number(e.target.value));
      }
      return 10;
    },

    // On page change
    changePage(e) {
      if (!isNaN(e) && e > 0) {
        this.$store.commit('CreateAndPublishStore/getPlaylists/SET_PAGE', Number(e));
      }
    },

    formatDateTime(time) {
      return time
        ? moment
            .unix(time)
            // .tz(this.timeZone)
            .format('MM/DD/YYYY')
        : '';
    },

    playEpisode(episode) {
      const { playlist_id: playlistId, name } = episode;
      const endpoint = `playlist/${playlistId}.m3u8`;
      const data = { title: name, artist: '' };
      if (this.show && this.show.title) {
        data.show = { title: this.show.title };
      }
      this.openPreviewPlayer({ endpoint, data });
    },

    updateExplicit(episode) {
      const epData = [
        {
          playlist_id: episode.playlist_id,
          explicit: !episode.explicit,
        },
      ];

      this.setPlaylistType({ settings: epData });
    },

    updateSort(val) {
      this.$store.commit('CreateAndPublishStore/getPlaylists/SET_SORT', val);
    },
    updateOrder(val) {
      this.$store.commit('CreateAndPublishStore/getPlaylists/SET_ORDER', val);
    },
    onSearchTermUpdate($e) {
      const trimmedResults = $e.target.value.replace(/\s{2,}/g, ' ');
      this.$store.commit('CreateAndPublishStore/getPlaylists/SET_SEARCH_TERM', trimmedResults);
    },

    goToCreateEpisode() {
      this.$store.commit('CreateAndPublishStore/draftEpisodes/SET_MAX_STEP_COMPLETED', 0);
      this.$router.push({ path: 'episodes/0/edit-audio/info' });
    },
  },

  watch: {
    currentPage() {
      this.$emit('request-playlists');
    },
    pageSize() {
      if (this.currentPage === 1) {
        this.$emit('request-playlists');
      } else {
        this.$store.commit('CreateAndPublishStore/getPlaylists/SET_PAGE', 1); // this will trigger a request-playlists event
      }
    },
    sort() {
      this.$emit('request-playlists');
    },
    order() {
      this.$emit('request-playlists');
    },
    $route: {
      immediate: true,
      handler() {
        if (this.show && this.show.title) {
          document.title =
            `${this.show.title} | Podcasts | Create&Publish | Futuri Content Cloud` || 'Futuri Content Cloud';
        }
      },
    },
  },

  created() {
    this.currentLayout = localStorage.getItem('episode-layout') ? localStorage.getItem('episode-layout') : 'list';
  },
};
</script>

<style lang="scss" scoped>
.empty {
  padding: 6rem 0 8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: var(--neutral-grey-1-text);
  &__heading {
    margin-bottom: 2.125rem;
  }
  &__body {
    margin-bottom: 2.125rem;
  }
  &__button {
    margin: 0 auto;
  }
}
</style>
