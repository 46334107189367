














































































import { Component } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { mixins } from 'vue-class-component';
import { get, debounce, reject } from 'lodash';
import EditorMetadataCard from '@/createandpublish/components/AudioEditor/EditorMetadataCard.vue';
import EventUploadProgressBar from '@/createandpublish/components/EventUploadProgressBar.vue';
import UploadProgressMixin from '@/createandpublish/mixins/uploadProgressMixin.vue';
import { MediaUploadMetadata } from '@/types/createandpublish/mediaLibraries/Common';
import {
  TwistedWave,
  SegmentObserverState,
  TwistedWaveSegment,
  SegmentObserverData,
  SegmentObserverOutputResult,
  TwistedWaveSessionData,
} from '@/types/createandpublish/mediaLibraries/TwistedWave';
import { eventBus, busEvents } from '@/createandpublish/core/eventBus/audioWizardEventBus';
import moment from 'moment-timezone';
import { escapeRegExp } from '@/createandpublish/core/util';

interface DurationWithFormat extends moment.Duration {
  format: (fmt: string, options?: Record<string, unknown>) => string;
}

const createAndPublishStore = namespace('CreateAndPublishStore');
const audioWizardModule = namespace('CreateAndPublishStore/audioWizard');

Component.registerHooks(['mounted', 'beforeDestroy']);

@Component({
  name: 'AudioWizardEditor',
  components: {
    EditorMetadataCard,
    EventUploadProgressBar,
  },
})
export default class AudioWizardEditor extends mixins(UploadProgressMixin) {
  @createAndPublishStore.Getter('station_name') readonly stationName!: string;
  @createAndPublishStore.Getter readonly twistedWaveHost!: string;
  @audioWizardModule.Getter readonly twSegmentObserverData!: SegmentObserverData;
  @audioWizardModule.Getter readonly twSessionData!: TwistedWaveSessionData;
  @audioWizardModule.Getter readonly audioMixerResult!: SegmentObserverOutputResult;
  @audioWizardModule.Mutation('SET_OBSERVER_EVENTS') setObserverEvents;
  @audioWizardModule.Action updateSegmentObserver;
  @audioWizardModule.Action syncSegmentsAndEvents;
  @audioWizardModule.Action submitAudioMixerOutput;

  twEditor: null | TwistedWave = null;
  isDirty = false;
  isUploading = false;
  duration = 0; // set by mixin, reset locally
  scrollPosition = 0;
  tooltip = {
    isVisible: false,
    offset: '0',
    title: '',
    type: '',
    duration: '',
  };
  lastKeyDown = '';
  originalAdStack = [];

  get completedProgresses() {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return this.progresses ? this.progresses.filter((pro: MediaUploadMetadata) => pro.encode_progress! >= 0).length : 0;
  }
  get pendingProgresses() {
    return this.progresses
      ? reject(this.progresses, (pro: MediaUploadMetadata) => pro.encode_progress && pro.encode_progress === 100).slice(
          0,
          1
        )
      : [];
  }
  get isLoading() {
    const offset = this.getSegOffset(0);
    return offset === 0;
  }
  get displayEvents() {
    return this.twSegmentObserverData.events.filter((e) => !e.deleted);
  }
  get showSelectedTooltip() {
    return this.twSegmentObserverData.selectionIndex !== null;
  }
  get selectedTooltip() {
    const selectedIndex = this.twSegmentObserverData.selectionIndex;
    if (this.showSelectedTooltip && selectedIndex !== null) {
      const event = this.twSegmentObserverData.events[selectedIndex];
      const width = this.getSegWidth(selectedIndex);
      const offset = this.getSegOffset(selectedIndex);

      const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
      const buffer = 75; // Num of px from edge of screen to prevent partial overflow of tooltip.
      if (width - buffer + offset < 1 || offset + buffer > vw) {
        // Event metadata card is completely outside viewport, set tooltip off-screen.
        return {
          offset: vw + 100 + 'px',
          title: null,
          type: null,
          duration: null,
        };
      }

      let isTtOffScreen = false;
      if (
        (offset < 0 && (width - buffer + offset) / 2 < 0) || // tool tip off screen left
        (offset > 0 && offset + (width + buffer) / 2 > vw)
      ) {
        // tool tip off screen right
        isTtOffScreen = true;
      }

      let ttOffset = NaN;
      if (offset < 1) {
        ttOffset = isTtOffScreen ? 130 : (width + offset) / 2;
      } else {
        ttOffset = isTtOffScreen ? vw - 130 : offset + width / 2;
      }
      const floatSeconds = event.duration > 1 ? event.duration : 1;
      return {
        offset: ttOffset + 'px',
        title: event.title,
        type: event.cart?.category?.name || 'None',
        duration: (moment.duration(floatSeconds, 'seconds') as DurationWithFormat).format('mm:ss', { trim: false }),
      };
    }
    return {
      offset: null,
      title: null,
      type: null,
      duration: null,
    };
  }

  categoryName(item) {
    return item.cart && item.cart.category ? item.cart.category.name : null;
  }
  getSegWidth(i) {
    const segment = get(this, ['twSegmentObserverData', 'tw_segment_positions', i]);
    const xmin = get(segment, '0');
    const xmax = get(segment, '1');

    if (typeof xmin !== 'number' || typeof xmax !== 'number') {
      return 0;
    }

    return xmax - xmin;
  }
  getSegOffset(i) {
    const segmentOffset = get(this, ['twSegmentObserverData', 'tw_segment_positions', i]);
    const xmin = get(segmentOffset, '0');

    if (typeof xmin !== 'number') {
      return 0;
    }

    return xmin;
  }
  getSegRightOffset(i) {
    const segmentOffset = get(this, ['twSegmentObserverData', 'tw_segment_positions', i]);
    const xmax = get(segmentOffset, '1');

    if (typeof xmax !== 'number') {
      return 0;
    }

    return xmax;
  }
  eventCategoryName(event) {
    return event.cart.category ? event.cart.category.name : null;
  }

  /**
   * Connect to TwistedWave editor and load in the selected event
   */
  async twConnect() {
    if (!this.twEditor) throw new Error('[AudioWizardEditor] [twConnect] TwistedWave instance is not defined.');

    if (this.twSessionData) {
      this.twEditor.connect(this.twSessionData.token);
      this.twEditor.ondirty((_canUndo, isDirty) => {
        this.isDirty = isDirty;
      });
      this.twEditor.onvisible(() => {
        this.twEditor?.futuri_seek_to(this.scrollPosition);
      });
    }
  }

  seekToEvent(index) {
    if (!this.twEditor) throw new Error('[AudioWizardEditor] [seekToEvent] TwistedWave instance is not defined.');

    let start = 0.01;
    for (let i = 0; i < index; i++) {
      start += this.displayEvents[i].duration;
    }
    this.twEditor.futuri_seek_to(start);
    if (this.lastKeyDown.includes('Shift')) {
      this.twEditor.select_segments([this.twSegmentObserverData.selectionIndex, index]);
    } else {
      if (this.twSegmentObserverData.selectionIndex === index) {
        this.twEditor.select_segments([]);
      } else {
        this.twEditor.select_segments([index]);
      }
    }
    this.scrollPosition = start;
  }

  setAndShowTooltip(width, offset, event) {
    if (offset < 0) {
      this.tooltip.offset = (width + offset) / 2 + 'px';
    } else {
      this.tooltip.offset = offset + width / 2 + 'px';
    }
    this.tooltip.title = event.title;
    this.tooltip.type = event.cart?.category?.name || 'None';
    const floatSeconds = parseFloat(event.duration) > 1 ? parseFloat(event.duration) : 1;
    this.tooltip.duration = (moment.duration(floatSeconds, 'seconds') as DurationWithFormat).format('mm:ss', {
      trim: false,
    });
    this.tooltip.isVisible = true;
  }

  /**
   * When user is done editing, this checks whether or not any edits have been made (if it's dirty).
   *   If so, it hits the encodeprogress endpoint to get the status of the upload (which comes from TW once the TW session is closed)
   *   If not, skips the progress check and closes the TW session
   */
  async saveAndClose() {
    if (!this.twEditor) throw new Error('[AudioWizardEditor] [saveAndClose] TwistedWave instance is not defined.');

    this.twEditor.close();
    this.isUploading = true;

    // Commit finalized events
    this.setObserverEvents(this.displayEvents);

    await this.submitAudioMixerOutput(this.twSegmentObserverData);
    await this.$nextTick();

    const uuidList = this.uuidsFromAudioMixerResult;
    this.checkUploadProgressMulti(uuidList, this.handleUploadSuccess, this.handleUploadFailure);
  }

  get uuidsFromAudioMixerResult(): string[] {
    if (!this.audioMixerResult) return [];
    const { events } = this.audioMixerResult;
    const uuidList = events.map((e) => {
      return `${this.stationName}-${e}`;
    });
    return uuidList;
  }

  /**
   * Closes without specifying a replacement event. Discards user edits.
   */
  closeWithoutSave() {
    this.purgeEditor();
    this.reset();
    this.resetStationEvents();
    this.$store.dispatch('CreateAndPublishStore/audioEditor/setAdStack', this.originalAdStack);
    this.$emit('toggleTwModal');
  }

  setProgressFailedMessage() {
    this.$store.commit('CreateAndPublishStore/SET_MESSAGE', {
      name: 'Upload Failed',
      details: `Could not upload this audio file. Please try again.`,
      type: 'error',
    });
  }

  /**
   * Reset component state for next editing session
   */
  reset() {
    this.isDirty = false;
    this.progress = null;
    this.duration = 0;
    this.isUploading = false;
  }

  /**
   * Reset stationEvents module state
   */
  resetStationEvents() {
    this.$store.dispatch('CreateAndPublishStore/stationEvents/clearOriginalEvent');
    this.$store.dispatch('CreateAndPublishStore/stationEvents/clearDropdownValues');
    this.$store.dispatch('CreateAndPublishStore/stationEvents/resetSegCounts');
  }

  /**
   * TwistedWave's close method fails (and doesn't complain) if TW:
   *   1. somehow received bad data
   *   2. received audio with an encoding error
   * The result is that TW fails to close not only on that session, but every subsequent session; and therefore doesn't
   *   send us audio when an editing session is ended by the user
   * This method notices when that happens and deletes the TW iframe from the DOM so it can be reinitialized
   *   at the beginning of the next session
   */
  purgeEditor() {
    if (this.twEditor && !this.twEditor.closed()) {
      this.$el.querySelector('#tweditor')?.firstElementChild?.remove();
    }
  }

  handleUploadSuccess() {
    eventBus.$emit(busEvents.TW_PROCESSING_COMPLETE);
  }

  /**
   * Stuff to do when it looks like an upload from TW has failed
   */
  handleUploadFailure() {
    this.setProgressFailedMessage();
    this.closeWithoutSave();
  }
  handleKeydown(e: KeyboardEvent) {
    if (!this.twEditor) return;

    if (!e.repeat) {
      if (e.code === 'Delete') {
        const segments = [...this.twSegmentObserverData.tw_segment_list];
        const selectionIndex = this.twSegmentObserverData.selectionIndex;
        if (selectionIndex === null) return;
        segments.splice(selectionIndex, 1);
        this.twEditor.set_segments(segments, 'delete', true);
      } else if (e.code === 'Space') {
        this.twEditor.send_command('play');
      }
      this.lastKeyDown = e.code;
    }
  }
  handleKeyup() {
    this.lastKeyDown = '';
  }
  segment_observer(observerState: SegmentObserverState) {
    // This is pretty weird looking
    // This is how we enumerate segment names
    // If you delete a segment or split it we end up with duplicated names
    // This turns them into enumerated names as simply as possible
    // Ex: ABC NEWS gets split and then this makes it ABC NEWS (A) and ABC NEWS (B)
    let namesUpdated = false;
    const updatedSegmentList = [...observerState.segment_list];
    updatedSegmentList.forEach((seg) => {
      const sameNames = updatedSegmentList.filter((e) => e.name === seg.name);
      if (sameNames.length > 1) {
        namesUpdated = true;
        const matchName = escapeRegExp(seg.name.replace(/\s\([A-Z]\)/, ''));
        const trimmedName = seg.name.replace(/\s\([A-Z]\)/, '');
        const extendedSameNames = updatedSegmentList.filter((e) => e.name.match(matchName));
        extendedSameNames.forEach((sameEvent, index) => {
          sameEvent.name = `${trimmedName} (${String.fromCharCode(65 + index)})`;
        });
      }
    });
    if (namesUpdated) {
      if (!this.twEditor)
        throw new Error('[AudioWizardEditor] [segment_observer] TwistedWave instance is not defined.');

      this.twEditor.set_segments(updatedSegmentList);
      namesUpdated = false;
    } else {
      this.updateSegmentObserver({
        // Updating only the properties we need.
        tw_segment_list: observerState.segment_list,
        tw_segment_positions: observerState.segment_positions,
        selectionIndex: observerState.selected_segment_index,
        selectionRange: observerState.selection,
        tw_sample_rate: observerState.sample_rate,
      });
      this.syncSegmentsAndEvents();
    }
  }

  setupEventBusListeners() {
    eventBus.$on(busEvents.EXPORT_EVENT, this.handleExportEvent);
  }

  removeEventBusListeners() {
    eventBus.$off(busEvents.EXPORT_EVENT, this.handleExportEvent);
  }

  handleExportEvent() {
    this.saveAndClose();
  }

  mounted() {
    if (this.twSessionData && !this.$el.querySelector('#tweditor')?.firstElementChild) {
      this.setupEventBusListeners();
      const twDiv = this.$el.querySelector('#tweditor') as HTMLElement;
      const segments: TwistedWaveSegment[] = [];
      let current_spot = -1;
      this.twSegmentObserverData.events.forEach((e) => {
        current_spot += 1;
        segments.push({ begin: current_spot, end: current_spot + e.duration * 44100, name: e.title });
        current_spot += e.duration * 44100;
      });

      const options = {
        segments_observer: debounce((e) => {
          this.segment_observer(e);
        }, 34), // Approximately two animation frames; prevents processing from blocking main thread
        init_segments: segments,
        origin: `https://${this.twistedWaveHost}`,
        futuri: true,
      };
      const twEditor = window.TW.newDocumentIframe(twDiv, options);
      this.twEditor = twEditor;
      const iframe = twDiv.firstElementChild as HTMLIFrameElement;
      iframe.style.height = '440px';
      this.twEditor?.onclose(() => {
        console.log('TwistedWave has been successfully closed.');
      });

      this.twConnect();
    }
  }

  beforeDestroy() {
    this.removeEventBusListeners();
  }
}
