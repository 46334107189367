<template>
  <div>
    <breadcrumb :qa="$getComponent('MyProfile', 'Breadcrumb', 'breadcrumb')" :items="breadcrumbItems" />
    <div class="section-body">
      <alert-display v-if="successBannerMessage.header" :alertMessage="successBannerMessage" />
      <alert-display v-if="errorBannerMessage.header" :alertMessage="errorBannerMessage" />
      <view-title title="My profile" :qa="$getComponent('MyProfile', 'ViewTitle', 'MyProfileViewTitle')" />
      <div class="body">
        <!-- Main form for first name, last name, phone, and default brand -->
        <split-form>
          <div slot="left" class="personal-information-text">
            <h2 class="h3">Personal information</h2>
            <p class="p">Edit your profile and settings.</p>
          </div>
          <div slot="right" class="personal-information-form">
            <div class="name-inputs">
              <div id="first-name">
                <Input
                  :value="profileData.firstName"
                  :maxLength="20"
                  name="firstName"
                  :inputHandler="handleInputChange"
                  label="First name"
                  type="text"
                  key="firstName"
                  :error="
                    $v.profileForm.firstName.$invalid
                      ? !profileData.firstName
                        ? 'First name' + errorMessages.required
                        : errorMessages.invalidFirstName
                      : ''
                  "
                />
              </div>
              <div id="last-name">
                <Input
                  :value="profileData.lastName"
                  :maxLength="20"
                  :inputHandler="handleInputChange"
                  label="Last name"
                  name="lastName"
                  type="text"
                  :error="
                    $v.profileForm.lastName.$invalid
                      ? !profileData.lastName
                        ? 'Last name' + errorMessages.required
                        : errorMessages.invalidLastName
                      : ''
                  "
                />
              </div>
            </div>
            <Input
              id="phone-input"
              :value="profileData.phone"
              :inputHandler="handleInputChange"
              name="phone"
              label="Mobile phone number (optional)"
              v-mask="'###-###-####'"
              v-maska="'###-###-####'"
              :error="profileData.phone ? (profileData.phone.length !== 12 ? 'Phone number is invalid' : '') : ''"
            />
            <div class="default-brand" v-if="profileData.brands.length > 1">
              <Select
                label="Default brand (optional)"
                :options="profileData.brands"
                :inputEventHandler="handleBrandChange"
                :selectedValue="profileData.defaultBrandId"
                :optionKey="'id'"
                :optionValue="'name'"
                placeholder="Select default brand"
                :disabled="false"
              />
              <p class="default-brand-text">
                Set a default here to be automatically taken to this view when you visit one of your apps.
              </p>
            </div>
            <div class="save-changes">
              <Button
                :loading="buttonLoadingStatus"
                onClickEventHandler="onClick"
                @onClick="saveChanges"
                id="save-changes-button"
                buttonType="primary"
                >Save changes</Button
              >
            </div>
          </div>
        </split-form>
      </div>

      <!-- links for email modal and password reset -->
      <div class="bottom-section">
        <h3 class="h3">Need to update your login info?</h3>
        <div class="update-links">
          <div>
            <router-link class="update-link" to="#" v-b-modal.modal-center
              >Update your email address<i class="material-icons">chevron_right</i></router-link
            >
          </div>
          <div @click="resetPasswordLink">
            <div v-if="passwordResetLoadingStatus">
              <Loading :isLoading="passwordResetLoadingStatus" />
            </div>
            <router-link id="reset-password-link" v-else @click="resetPasswordLink" class="update-link" to="#"
              >Reset your password<i class="material-icons">chevron_right</i></router-link
            >
          </div>
        </div>
      </div>
      <change-email-modal :profileData="profileData" />
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { required } from 'vuelidate/lib/validators';
import Select from '@/components/common/form/Select.vue';
import Input from '@/components/common/form/Input.vue';
import Loading from '@/components/common/Loading.vue';
import Button from '@/components/common/buttons/Button.vue';
import Breadcrumb from '@/components/common/navigation/breadcrumb/Breadcrumb.vue';
import ViewTitle from '@/components/common/view/viewTitle/ViewTitle.vue';
import SplitForm from '@/components/common/form/SplitForm.vue';
import ChangeEmailModal from '@/components/myprofile/ChangeEmailModal.vue';
import { phone, validateName, noSpecialCharacters } from '@/plugins/Validations';
import AlertDisplay from '@/components/common/AlertDisplay.vue';
import { mapGetters } from 'vuex';
import errorMessages from '@/utils/errorMessages.json';
import { maska } from 'maska';
import UserService from '@/services/UserService';

export default Vue.extend({
  name: 'my-profile',
  components: { Select, Input, Button, Breadcrumb, ViewTitle, SplitForm, AlertDisplay, Loading, ChangeEmailModal },
  directives: {
    maska,
  },
  computed: {
    ...mapGetters(['currentUser/user']),
    profilePhoneFormat() {
      const userPhone = this['currentUser/user'].phone;
      if (userPhone) {
        const formattedPhone =
          userPhone.substring(0, 3) + '-' + userPhone.substring(3, 6) + '-' + userPhone.substring(6, 10);
        return formattedPhone;
      }
      return '';
    },
    profileData: {
      get() {
        return this.profileForm;
      },
      set() {
        this.profileForm = {
          firstName: this['currentUser/user'].firstName,
          lastName: this['currentUser/user'].lastName,
          phone: this.profilePhoneFormat,
          email: this['currentUser/user'].email,
          defaultBrandId: this['currentUser/user'].defaultBrandId,
          brands: this.getFilteredBrands(),
        };
      },
    },
  },
  data() {
    return {
      buttonLoadingStatus: false,

      passwordResetLoadingStatus: false,

      breadcrumbItems: [
        { text: 'Account overview', to: { name: 'Dashboard' } },
        { text: 'My profile', active: true },
      ],

      errorBannerMessage: {
        type: 'critical',
        header: '',
        message: '',
        links: [{ message: '' }],
      },

      successBannerMessage: {
        type: 'success',
        header: '',
        message: '',
      },

      errorMessages: errorMessages,

      profileForm: {
        brands: [],
        defaultBrandId: '',
        email: '',
        firstName: '',
        lastName: '',
        phone: '',
      },
    };
  },
  validations: {
    profileForm: {
      firstName: {
        required,
        validateName,
        noSpecialCharacters,
      },
      lastName: {
        required,
        validateName,
        noSpecialCharacters,
      },
      phone: {
        phone,
      },
    },
  },
  methods: {
    getFilteredBrands() {
      const filteredBrands = this['currentUser/user'].brands.filter((brand) => brand.active);
      return filteredBrands;
    },

    handleInputChange(e) {
      this.profileData[e.target.name] = e.target.value;
    },

    handleBrandChange(e) {
      this.profileData.defaultBrandId = Number(e.target.value);
    },

    setInitialBannerState() {
      this.errorBannerMessage = {
        type: 'critical',
        links: [],
        message: '',
        header: '',
      };

      this.successBannerMessage = {
        type: 'success',
        links: [],
        message: '',
        header: '',
      };
    },

    async saveChanges() {
      // preset both banners to initial states
      this.setInitialBannerState();

      // touch the form to check validity
      this.$v.profileForm.$touch();
      if (!this.$v.profileForm.$invalid) {
        this.buttonLoadingStatus = true;
        this.$store
          .dispatch('currentUser/updateUser', this.profileForm)
          .then(async () => {
            await this.$store.dispatch('fetchAccount');
            await this.$store.dispatch('currentUser/requestUser');

            // success banner
            this.successBannerMessage.header = 'Profile updated';
            this.successBannerMessage.message = 'Your profile was successfully updated.';
          })
          .catch((err) => {
            this.errorBannerMessage.header = "We couldn't save your changes.";
            this.errorBannerMessage.message = 'Correct the errors below to continue.';
            this.errorBannerMessage.links = err.errors;
          })
          .finally(() => {
            this.buttonLoadingStatus = false;
          });
      }
    },
    async resetPasswordLink() {
      const payload = { email: this['currentUser/user'].email };

      // set initial states of banners
      this.setInitialBannerState();

      this.passwordResetLoadingStatus = true;
      await new UserService()
        .sendResetPasswordLink(payload)
        .then(() => {
          this.successBannerMessage.header = 'Reset password link sent';
          this.successBannerMessage.message = `Please check your inbox. We've sent a reset password link to ${
            this['currentUser/user'] ? this['currentUser/user'].email : 'your email'
          }.`;
        })
        .catch((err) => {
          this.errorBannerMessage.header = 'Please try again';
          this.errorBannerMessage.message = '';
          this.errorBannerMessage.links = err.errors;
        })
        .finally(() => {
          this.passwordResetLoadingStatus = false;
        });
    },
  },

  mounted() {
    this.profileData = this['currentUser/user'];
  },
});
</script>

<style scoped lang="scss">
.body {
  padding-top: 2rem;
}
.personal-information {
  display: flex;
  height: 100%;
}
.personal-information-text {
  width: 50%;
}
.personal-information-form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.name-inputs {
  display: flex;
}
#first-name {
  width: 50%;
  margin-right: 1.5rem;
  margin-bottom: 2rem;
}
#last-name {
  width: 50%;
}
#phone-input {
  margin-bottom: 2rem;
}
.default-brand-text {
  margin-bottom: 2rem;
  color: var(--neutral-grey-2-lighter-text);
}
.save-changes {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  margin-top: 1rem;
}
#save-changes-button {
  width: 152px;
  margin: 0;
}
.icon {
  color: red;
  margin-top: 8px;
}
.bottom-section {
  height: 136px;
  padding-top: 2rem;
  border-top: 1px solid var(--neutral-grey-3-borders);
  margin-top: 2rem;
}
.update-links {
  margin-top: 36px;
  display: flex;
}
.update-link {
  color: var(--accent-links);
  margin-right: 40px;
  &:hover {
    color: var(--accent-links-hover);
  }
}
#email-form-input {
  margin-bottom: 2rem;
}
.loading {
  position: relative;
}
</style>
