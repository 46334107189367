<template>
  <div class="billing">
    <AlertDisplay :alertMessage="alertMessage" class="alertMessage" v-if="displayAlert" />
    <!-- Subtitle -->
    <h3 class="form-subtitle">
      <span>Billing information</span>
      <button v-if="!firstRun && !editable" class="editable-toggle" type="button" @click="showForm">Edit</button>
    </h3>
    <form v-if="editable" v-on:submit.prevent="onSubmit" novalidate ref="form">
      <input type="hidden" name="firstName" :value="firstName" data-chargify="firstName" />
      <input type="hidden" name="lastName" :value="lastName" data-chargify="lastName" />
      <Input
        :value="form.companyName"
        :inputHandler="handleInputChange"
        :maxLength="32"
        label="Company name"
        name="companyName"
        :error="$v.form.companyName.$error ? 'Company name' + errorMessages.required : ''"
      />
      <Input
        :value="form.address"
        :inputHandler="handleInputChange"
        :maxLength="32"
        label="Billing address"
        name="address"
        :error="$v.form.address.$error ? 'Billing address' + errorMessages.required : ''"
        data-chargify="address"
      />
      <div class="address2" :class="{ active: showAddress2 }">
        <button @click="showAddress2 = true" :class="{ 'd-none': showAddress2 }" type="button" class="address2-toggle">
          + Add address line 2 (suite, apartment, etc)
        </button>
        <div :class="{ 'd-none': !showAddress2 }">
          <Input
            :value="form.address2"
            :inputHandler="handleInputChange"
            :maxLength="32"
            label="Billing address 2 (suite, apartment, etc)"
            name="address2"
            data-chargify="address2"
          />
        </div>
      </div>
      <Input
        :value="form.city"
        :inputHandler="handleInputChange"
        :maxLength="32"
        label="City"
        name="city"
        :error="$v.form.city.$error ? 'City' + errorMessages.required : ''"
        data-chargify="city"
      />
      <div class="row">
        <div class="col-sm">
          <Label :label="stateProvinceLabel"></Label>
          <Select
            name="billing_state"
            placeholder="State"
            :options="countryData[selectedCountryIndex].states"
            optionKey="abbreviation"
            optionValue="name"
            :selectedValue="form.state"
            :inputEventHandler="handleStateChange"
            :error="$v.form.state.$error ? stateProvinceLabel + ' ' + errorMessages.required : ''"
            v-if="countryData"
          />
          <Input
            :value="form.state"
            :inputHandler="handleInputChange"
            label="State"
            name="state"
            data-chargify="state"
            class="hidden"
          />
        </div>
        <div class="col-sm">
          <Input
            :value="form.zip"
            :inputHandler="handleInputChange"
            :label="zipPostalCodeLabel"
            name="zip"
            :error="$v.form.zip.$error ? zipPostalCodeLabel + ' ' + errorMessages.required : ''"
            data-chargify="zip"
          />
        </div>
      </div>
      <Label label="Country"></Label>
      <Select
        name="billing_country"
        placeholder="Country"
        :options="countryData"
        optionKey="abbreviation"
        optionValue="name"
        :selectedValue="form.country"
        :inputEventHandler="handleCountryChange"
        v-if="countryData"
      />
      <Input
        :value="form.country"
        :inputHandler="handleInputChange"
        label="Country"
        name="country"
        data-chargify="country"
        class="hidden"
      />
      <div class="form-input">
        <Label label="Card information"></Label>
        <div id="chargify-form" :class="{ 'card-error': cardError }"></div>
      </div>
      <Button
        :loading="formIsLoading"
        buttonType="primary"
        class="form-submit"
        type="submit"
        :disabled="checkoutDisabled"
      >
        {{ firstRun ? 'Continue to review' : 'Update' }}
      </Button>
    </form>
    <div v-else>
      <div class="billing-info">
        <div v-text="form.companyName"></div>
        <div v-text="form.address"></div>
        <div v-text="form.address2"></div>
        <div>
          <span v-text="form.city"></span>,&nbsp; <span v-text="form.state"></span>&nbsp;
          <span v-text="form.zip"></span>
        </div>
        <div v-text="form.country"></div>
        <!-- <div v-text="obfuscatedCardDetails"></div> -->
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { required } from 'vuelidate/lib/validators';
import Select from '@/components/common/form/Select.vue';
import Button from '@/components/common/buttons/Button.vue';
import Input from '@/components/common/form/Input.vue';
import AlertDisplay from '@/components/common/AlertDisplay.vue';
import errorMessages from '@/utils/errorMessages.json';
// import ErrorMessage from '@/components/common/form/ErrorMessage.vue';
import Label from '@/components/common/form/Label.vue';
import { countriesStatesAndProvinces, validateZipByCountry } from '@/components/ecomm/countryData.ts';
const validateZip = (value, vm) => {
  const country = vm.country;
  return validateZipByCountry(value, country);
};

export default Vue.extend({
  name: 'billing',

  props: {
    firstName: String,
    lastName: String,
    checkoutDisabled: { type: Boolean, default: false },
  },

  components: {
    Button,
    Input,
    Label,
    Select,
    AlertDisplay,
  },

  data() {
    return {
      alertMessage: {},
      chargify: null,
      displayAlert: false,
      editable: true,
      errorMessages, // Input error messages
      firstRun: true,
      cardError: '',
      countryData: countriesStatesAndProvinces() || [],
      form: {
        address: '',
        address2: '',
        chargifyJsToken: '',
        city: '',
        companyName: '',
        country: '',
        state: '',
        zip: '',
      },
      formIsLoading: false, // is form submitting?
      selectedCountryIndex: 0,
      showAddress2: false,
      stateProvinceLabel: 'State',
      zipPostalCodeLabel: 'Zip code',
    };
  },

  validations: {
    form: {
      address: { required },
      city: { required },
      companyName: { required },
      state: { required },
      zip: {
        required,
        validateZip,
      },
    },
  },

  methods: {
    handleInputChange(e) {
      this.form[e.target.name] = e.target.value;
    },
    handleCountryChange(e) {
      this.selectedCountryIndex = this.countryData.findIndex((country) => country.abbreviation === e.target.value);
      if (e.target.value === 'CA') {
        this.stateProvinceLabel = 'Province';
        this.zipPostalCodeLabel = 'Postal code';
      } else {
        this.stateProvinceLabel = 'State';
        this.zipPostalCodeLabel = 'Zip code';
      }
      this.form.country = e.target.value;
      this.handleInputChange(e);
    },
    handleStateChange(e) {
      this.form.state = e.target.value;
      this.handleInputChange(e);
    },
    onSubmit() {
      this.cardError = '';

      // Touch all fields
      this.$v.form.$touch();

      // if form's validated
      if (!this.$v.form.$invalid) {
        this.getChargifyJsToken();
      }
    },
    showForm() {
      this.editable = true;
      this.loadChargifyJs();

      // clear token when reopening the form because the iframe gets re-rendered and we lose its details
      // FYI - cvv is also cleared as soon as we get the token
      this.form.chargifyJsToken = '';
    },
    loadChargifyJs() {
      this.chargify.load({
        selector: '#chargify-form', // selector where the iframe will be rendered
        publicKey: process.env.VUE_APP_CHARGIFY_JS_PUBLIC_KEY,
        type: 'card',
        hideCardImage: true,
        serverHost: process.env.VUE_APP_CHARGIFY_SERVER_HOST,
        style: {
          input: {
            backgroundColor: 'transparent',
            color: '#222046',
            fontSize: '17px',
            letterSpacing: '0',
            opacity: '1',
            '::placeholder': {
              color: '#6E6E7A',
            },
            '::after': {
              paddingTop: '16px !important',
            },
          },
        },
        fields: {
          number: {
            style: {
              field: {
                maxWidth: '65%',
              },
              input: {
                background: 'url(https://js.chargify.com/latest/images/default.svg) no-repeat 16px 16px',
                padding: '14px 16px 14px 80px',
              },
            },
          },
          month: {
            required: true,
          },
          year: {
            required: true,
          },
          cvv: {
            required: true,
          },
        },
      });
    },
    getChargifyJsToken() {
      const form = this.$refs.form;
      this.chargify.token(
        form,
        // success
        (token) => {
          this.form.chargifyJsToken = token;

          // hide form and clear cvv field
          this.editable = false;
          this.firstRun = false;
          this.form.cvv = '';

          // send data to Checkout.vue component
          this.$emit('success', { ...this.form });
          this.displayAlert = false;
        },
        // error
        (err) => {
          this.alertMessage = {
            type: 'critical',
            header: `We couldn't authorize your card.`,
            message: this.parseCardErrors(err),
          };
          this.displayAlert = true;
        }
      );
    },

    parseCardErrors(errors) {
      if (errors?.errors) {
        //set card error to true to mimic the chargify card error style
        this.cardError = true;
        return errors.errors;
      } else if (errors.invalidFields && errors.invalidFields.includes('number')) {
        return 'The card number you provided is incorrect. Please try again.';
      } else if (
        (errors.invalidFields && errors.invalidFields.includes('month')) ||
        errors.invalidFields.includes('year')
      ) {
        return 'The expiration date you provided is incorrect. Please try again.';
      } else if (errors.invalidFields && errors.invalidFields.includes('cvv')) {
        return 'The CVV code you provided is incorrect. Please try again.';
      } else {
        return errors.message ? errors.message : '';
      }
    },
  },

  mounted() {
    const { Chargify } = window;
    this.chargify = new Chargify();
    this.loadChargifyJs();
  },
});
</script>

<style lang="scss" scoped>
.form-subtitle {
  align-items: center;
  display: flex;
  font-size: 24px;
  justify-content: space-between;
  margin-bottom: 24px;
}

.form-input {
  margin-bottom: 32px;
}

.fm-select {
  display: block;
  margin-bottom: 32px;
}

.address2 {
  margin-bottom: 32px;

  &:not(.active) {
    margin-top: -12px;
  }
}

.address2-toggle {
  background: none transparent;
  border: 0;
  color: var(--accent-links);
  font-weight: 500;
  overflow: visible;
  padding: 0;

  &:hover {
    color: var(--accent-links-hover);
  }
}

.editable-toggle {
  background: none transparent;
  border: 0;
  color: var(--accent-links);
  font-size: 1rem;
  overflow: visible;
  padding: 0;
  text-decoration: underline;

  &:hover {
    color: var(--accent-links-hover);
    text-decoration: none;
  }
}

.button-complete {
  margin-top: 64px;
  margin-bottom: 32px;
}

.hidden {
  visibility: hidden;
}

.form-submit {
  @media (max-width: 768px) {
    // display: none;
    bottom: 0;
    left: 15px;
    margin: 0;
    position: absolute;
    width: calc(100% - 30px);
  }
}

.confirmation {
  @media (max-width: 768px) {
    color: var(--neutral-grey-2-lighter-text);
    margin-top: 32px;
  }
}

.hidden {
  display: none !important;
}

.form-cvv {
  width: 25%;
}

.billing-info {
  font-size: 20px;
  line-height: 28px;
}

#chargify-form {
  width: 622px;

  &.card-error {
    border: 1px solid var(--chargify--error);
    max-height: 56px;
    overflow: hidden;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
}
</style>

<style lang="scss">
.billing #chargify-form iframe {
  width: 590px !important;

  @media (max-width: 768px) {
    width: 100% !important;
  }
}
</style>
