import HttpClient from '@/services/common/HttpClient';
import { AccountData } from '@/types/account/AccountData';

export default class AccountService {
  private http: HttpClient = HttpClient.getInstance();
  private endpoint = '/account';

  // Get account data
  public getAccount(): Promise<AccountData> {
    return this.http.get<AccountData>(this.endpoint);
  }
}
