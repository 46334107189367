<template>
  <ModalLayout :heading="modalLayoutHeading" @on:close="closeModal" :expanding="true">
    <template slot="content">
      <div v-if="imageLoading" class="alert alert-warning">Image is loading...</div>
      <vue-cropper
        ref="cropper"
        :src="imageUrl"
        alt="Uploaded Image"
        :containerStyle="{ overflow: 'hidden', padding: '0' }"
        :imgStyle="{ padding: '0' }"
        :aspect-ratio="aspectRatio"
        :view-mode="viewMode"
        :rotatable="false"
        :autoCropArea="0.75"
        :ready="onImageLoaded"
        :checkCrossOrigin="true"
        :checkOrientation="false"
      >
      </vue-cropper>
    </template>
    <template slot="footer">
      <div class="crop-image-modal-v2-actions">
        <fm-button-primary class="action-button" @click="confirm"> Save </fm-button-primary>
        <fm-button-secondary @click="closeModal"> Cancel </fm-button-secondary>
      </div>
    </template>
  </ModalLayout>
</template>

<script>
import VueCropper from 'vue-cropperjs';
import 'cropperjs/dist/cropper.css';
import ModalLayout from '../layouts/ModalLayout';

export default {
  name: 'CropImageUpload',

  props: {
    imageUrl: {
      type: String,
      required: true,
    },
    aspectRatio: {
      type: Number,
      default: 1,
    },
    viewMode: {
      type: Number,
      default: 1,
    },
  },

  data() {
    return {
      imageLoading: false,
    };
  },

  computed: {
    modalLayoutHeading() {
      return 'Crop Image';
    },
    cropDataUrl() {
      if (this.$refs.cropper && this.$refs.cropper.getCroppedCanvas()) {
        return this.$refs.cropper.getCroppedCanvas().toDataURL();
      }
      return undefined;
    },
  },

  watch: {
    imageUrl() {
      this.imageLoading = true;
      // Every time image url changes, let's rebuild it
      this.$refs.cropper.replace(this.imageUrl);
    },
  },

  methods: {
    closeModal() {
      this.$emit('close');
    },

    onImageLoaded() {
      this.imageLoading = false;
    },

    confirm() {
      const croppings = {
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
      };

      // Now do percentage processing
      const imagewidth = this.$refs.cropper.$refs.img.naturalWidth;
      const imageheight = this.$refs.cropper.$refs.img.naturalHeight;
      const cropData = this.$refs.cropper.getData();

      if (imagewidth > 0 && imageheight > 0) {
        croppings.left = (100.0 * cropData.x) / imagewidth;
        croppings.top = (100.0 * cropData.y) / imageheight;
        if (imagewidth - cropData.x > cropData.width) {
          croppings.right = (100.0 * (imagewidth - (cropData.x + cropData.width))) / imagewidth;
        }
        if (imageheight - cropData.y > cropData.height) {
          croppings.bottom = (100.0 * (imageheight - (cropData.y + cropData.height))) / imageheight;
        }
      }

      this.$emit('close', {
        originalImage: this.$refs.cropper.$refs.img,
        cropData: cropData,
        cropPercentages: croppings,
        cropDataUrl: this.cropDataUrl,
      });
    },
  },

  components: {
    VueCropper,
    ModalLayout,
  },
};
</script>

<style scoped lang="scss">
.crop-image-modal-v2-actions {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;

  .action-button {
    margin-right: 1rem;
  }
}
</style>
