






















































import { Component, Vue } from 'vue-property-decorator';
import { Getter, namespace } from 'vuex-class';

import BillingCard from '@/components/dashboard/BillingCard.vue';
import BrandsCampaignsCard from '@/components/dashboard/BrandsCampaignsCard.vue';
import ProductCard from '@/components/ProductCard.vue';
import UsersAccountCard from '@/components/dashboard/usersAccountCard/UsersAccountCard.vue';
import WelcomeBanner from '@/components/DashboardWelcomeBanner.vue';
import AlertDisplay from '@/components/common/AlertDisplay.vue';
import Button from '@/components/common/buttons/Button.vue';

import { isCCAdmin, isActiveUser, isMultiBrand } from '@/utils';
import { getProductUrlById, getProductLogoById } from '@/utils/products';

import { AuthenticatedUser } from '@/models/UserModel';

import type { AccountState } from '@/types/account/AccountState';
import type { ProductMap } from '@/types/ProductMap';
import type { Product } from '@/types/Product';
import type { Invoice } from '@/types/ecomm';

const EcommStore = namespace('EcommStore');

@Component({
  components: {
    BillingCard,
    BrandsCampaignsCard,
    ProductCard,
    UsersAccountCard,
    WelcomeBanner,
    AlertDisplay,
    Button,
  },
})
export default class Dashboard extends Vue {
  @Getter getAccount!: AccountState;
  @Getter authenticatedUser!: AuthenticatedUser;
  @Getter sortedPriorityAccountProducts!: Product[];

  @EcommStore.Action getInvoices;
  @EcommStore.Getter mostRecentInvoice!: Invoice | null;

  mounted() {
    if (!this.mostRecentInvoice) {
      const pageData = { page: 1, size: 10, sortBy: 'issue_date', order: '-' };
      this.getInvoices(pageData);
    }
  }

  newUser = true;

  welcomeBanner = {
    isOpen: true,
  };

  brandsAlertMessage = {
    type: 'warning',
    header: 'You need access to at least one brand to use your apps.',
    message: 'Please contact your administrator to update your account.',
  };

  get isCCAdmin() {
    return isCCAdmin();
  }

  get isActiveUser() {
    return isActiveUser();
  }

  get isMultiBrand() {
    return isMultiBrand();
  }

  get account() {
    return this.getAccount.data;
  }

  get shouldShowBrandsAlert() {
    const hasNoBrands = this.authenticatedUser.brands.length === 0;
    return hasNoBrands;
  }

  get userLastLogin() {
    return this.authenticatedUser.lastLogin || '';
  }

  // Get product logo path
  getProductLogo(id: ProductMap['id']): ProductMap['logoAppCard'] {
    return getProductLogoById(id);
  }

  // Get product route path
  getProductRoute(id: ProductMap['id']): ProductMap['url'] | '' {
    return getProductUrlById(id);
  }
}
