<template>
  <div :class="['message-bar', { collapsed: !isShowing }, messageType]" ref="messageBar">
    <div class="message-bar-content">
      <p ref="messageContent">
        <span class="message-details" v-show="messageDetails">{{ messageDetails }}</span>
      </p>
    </div>
    <button class="message-bar-close" @click="onDismissMessage" aria-label="Dismiss message">
      <i class="material-icons"> close </i>
    </button>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
const { mapActions, mapGetters } = createNamespacedHelpers('CreateAndPublishStore');
import VueScrollTo from 'vue-scrollto';
const scrollOptions = {
  container: '.view__content',
  easing: 'ease-in',
  offset: -60,
  force: true,
  cancelable: true,
  onStart: function () {
    // scrolling started
  },
  onDone: function () {
    // scrolling is done
  },
  onCancel: function () {
    // scrolling has been interrupted
  },
  x: false,
  y: true,
};

export default {
  name: 'MessageBar',

  data() {
    return {
      timer: null,
    };
  },

  computed: {
    ...mapGetters(['message']),

    isShowing() {
      return !!this.message;
    },
    messageName() {
      return this.message ? this.message.name : '';
    },
    messageType() {
      return this.message ? this.message.type : '';
    },
    messageDetails() {
      return this.message ? this.message.details : '';
    },
  },
  mounted() {
    // const bar = this.$refs.messageBar;
    // VueScrollTo.scrollTo(bar, 10, scrollOptions);
  },
  watch: {
    message: {
      handler(data) {
        const bar = this.$refs.messageBar;

        if (data) {
          bar.classList.remove('collapsed');
          VueScrollTo.scrollTo(bar, 10, scrollOptions);
          if (this.timer) {
            clearTimeout(this.timer);
          }
          this.timer = setTimeout(() => {
            this.dismissMessage();
          }, 120000);
        } else {
          bar.classList.add('collapsed');
        }
      },
      deep: true,
    },
  },

  methods: {
    ...mapActions(['dismissMessage']),

    onDismissMessage() {
      clearTimeout(this.timer);
      this.dismissMessage();
    },
  },

  beforeDestroy() {
    clearTimeout(this.timer);
  },
};
</script>

<style lang="scss" scoped>
.message-bar {
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-content: center;
  transition: all 0.25s;
  overflow: hidden;
  opacity: 0.9;
  color: var(--neutral-white);
  z-index: 999999;
  margin: 48px 32px 0 32px;

  &.collapsed {
    height: 0 !important;
    opacity: 0;
    margin: 0;
    visibility: hidden;
  }

  &.success {
    background-color: var(--neutral-grey-1-text);
  }

  &.error {
    background-color: var(--notification--error);
  }
}

.message-bar-content {
  padding: 8px 16px;
  // width: 90%;
  margin: 0;

  p {
    color: var(--neutral-white);
    font-family: var(--fm-font-family-roboto);
    font-size: 16px;
    line-height: 24px;
    margin: 0;
    padding: 0;
    text-align: center;
  }
}

.message-bar-close {
  color: var(--neutral-white);
  background: transparent;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 8px;
  transition: all 0.25s;
  border-radius: 36px;
  box-shadow: none;
  height: 30px;
  width: 30px;
  margin-right: 16px;
  align-self: center;
}
</style>
