s
<template>
  <div class="wrapper">
    <div class="showing">
      {{ fromTo }}
    </div>
    <div class="pagination">
      <PaginationBase
        :totalItems="totalItems"
        :onPageUpdate="onPageUpdate"
        :perPage="pageSize"
        :defaultPage="currentPage"
        ref="pagination"
        v-if="totalItems > 0"
        :disabled="disabled"
      />
    </div>
    <div class="items-per-page">
      <div class="container">
        <div class="itemsPerPage">Items per page</div>
        <Select
          class="dropdown"
          :selectedValue="pageSize"
          :inputEventHandler="onPageSizeUpdate"
          :optionKey="'size'"
          :optionValue="'size'"
          :options="pageSizeOptions"
          :placeholder="'page size'"
          :disabled="disabled"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Select from '@/components/common/form/Select.vue';
import PaginationBase from '@/components/common/table/PaginationBase.vue';
export default {
  name: 'pagination',
  components: {
    Select,
    PaginationBase,
  },
  props: {
    onPageSizeUpdate: Function,
    onPageUpdate: Function,
    pageSize: Number,
    pageSizeOptions: Array,
    totalItems: Number,
    currentPage: Number,
    disabled: { default: false, type: Boolean },
  },

  computed: {
    fromTo() {
      const _from = this.getPaginationFrom(this.currentPage, this.pageSize, this.totalItems);
      const _to = this.getPaginationTo(this.currentPage, this.pageSize, this.totalItems);
      return `Viewing ${_from}-${_to} of ${this.totalItems} items`;
    },
  },

  methods: {
    // Get pagination `from` number
    getPaginationFrom(currentPage, pageSize, totalElements) {
      if (currentPage === 1) {
        if (totalElements === 0) return 0;
        return 1;
      }
      return (currentPage - 1) * pageSize + 1;
    },

    // Get pagination `from` number
    getPaginationTo(currentPage, pageSize, totalElements) {
      return Math.min(currentPage * pageSize, totalElements);
    },
  },
  watch: {
    pageSize: function () {
      this.$refs.pagination._setPage(1);
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  align-items: center;
  margin: 48px 0;
  width: 100%;
  display: table;
  table-layout: fixed;

  div.showing,
  div.pagination,
  div.items-per-page {
    display: table-cell;

    color: var(--neutral-grey-1-text);

    align-items: center;
    font-size: 16px;
    font-weight: 500;
  }
  .showing {
    vertical-align: middle;
    text-align: left;
  }
  .pagination {
    vertical-align: middle;
    width: 50%;
    text-align: center;
    display: flex;
    justify-content: center;
  }
  .items-per-page {
    vertical-align: middle;
    text-align: center;

    .container {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;

      .fm-select {
        width: 100px;
        display: flex;
        justify-content: flex-end;
        margin-left: 15px;
        margin-right: -15px;
      }
    }
  }

  .dropdown {
    margin-left: 15px;
  }
}

.items-per-page {
  font-weight: 500;
}
</style>
