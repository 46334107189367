















import { Component, Vue, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import CloseButton from '@/createandpublish/components/CloseButton.vue';
import PlayButton from '@/createandpublish/components/common/PlayButton.vue';
import { AudioEvent } from '@/types/createandpublish/mediaLibraries/AudioLibrary';
import { formatDate, formatTime, formatDurationBrief } from '@/createandpublish/core/filters';

const createAndPublishStore = namespace('CreateAndPublishStore');

@Component({
  name: 'AudioCard',
  components: {
    CloseButton,
    PlayButton,
  },
  filters: {
    formatDate,
    formatTime,
    formatDurationBrief,
  },
})
export default class AudioCard extends Vue {
  @Prop({ type: Object, required: true }) readonly event!: AudioEvent;
  @Prop({ type: Boolean, required: true }) readonly isPlaying!: boolean;

  @createAndPublishStore.Getter timeZone;

  onRemoveEventClick() {
    this.$emit('onRemove', this.event);
  }

  get categoryColorMap() {
    const baseClass = { 'audio-card': true };
    if (!this.event.category) {
      return { ...baseClass };
    }
    return {
      ...baseClass,
      'audio-card__teal': this.event.category.includes('Promo'),
      'audio-card__blue': this.event.category.includes('Commercial'),
      'audio-card__green': this.event.category.includes('Talk'),
      'audio-card__purple': this.event.category.includes('Music'),
    };
  }
}
