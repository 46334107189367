<template>
  <div role="group" :class="classes">
    <Label :label="label" class="radio-label" />

    <div class="fm-radio__form-item" v-for="(option, i) in options" :key="i">
      <input
        :key="i"
        type="radio"
        class="fm-radio__form-item__input"
        :id="'field_' + i"
        :name="'fm-input' + option.label"
        tabindex="-1"
        v-model="currentValue"
        :disabled="disabled"
        :value="option.value"
        @input="handleRadio"
      />
      <div class="label">{{ option.label }}</div>
      <div class="side-text" v-if="option.sidetext">{{ option.sidetext }}</div>
    </div>
    <error-message :error="error" v-if="error" />
    <fm-assistive :assistiveText="assistiveText" v-if="assistiveText" />
  </div>
</template>
<script>
import Vue from 'vue';

import Label from '@/components/common/form/Label.vue';
import ErrorMessage from '@/components/common/form/ErrorMessage.vue';

export default Vue.extend({
  name: 'radio',
  components: {
    Label,
    ErrorMessage,
  },

  methods: {
    handleRadio(e) {
      this.handleInput(e.target.value);
    },
    isChecked: function (value) {
      return this.defaultValue === value;
    },
  },
  props: {
    handleInput: Function,
    defaultValue: Number || String,
    disabled: Boolean,
    label: String,
    options: { type: Array, default: () => [{}] },
    assistiveText: String,
    error: String,
  },
  computed: {
    classes() {
      return {
        'fm-radio': true,
        'fm-radio-error': typeof this.error === 'string' && this.error.length > 0,
        'fm-radio-disabled': this.disabled,
      };
    },
  },
  data() {
    return {
      currentValue: this.defaultValue,
    };
  },
  watch: {
    defaultValue: function (newDefaultValue) {
      this.currentValue = newDefaultValue;
    },
  },
});
</script>
<style lang="scss" scoped>
:host {
  display: block;
}

.fm-radio {
  all: initial;
  display: flex;
  flex-direction: column;
  width: 100%;

  &__form-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 6px 0;
    box-sizing: border-box;

    &:focus {
      outline: 4px solid #00c6c680;
    }

    &:focus:not(.focus-visible) {
      outline: none;
    }

    &:focus-visible {
      outline: 4px solid #00c6c680;
    }

    &__input {
      -webkit-appearance: none;
      appearance: none;
      display: inline-block;
      width: 24px;
      height: 24px;
      box-sizing: border-box;
      background: var(--neutral-white) 0% 0% no-repeat padding-box;
      border: 2px solid var(--neutral-grey-3-borders);
      border-radius: 50%;
      padding: 2px;
      margin: 0 8px 0 0;
      outline: none;

      &::before {
        display: block;
        content: ' ';
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background-color: var(--brand-dark);
        transform: translate(3px, 3px) scale(0);
        transform-origin: center;
        transition: all 250ms ease-in;
      }

      &:checked::before {
        transform: translate(0, 0) scale(1);
        transition: all 250ms cubic-bezier(0.25, 0.25, 0.56, 2);
      }
    }

    &__label {
      display: inline-block;
      font: var(--fm-font-style-normal) normal var(--fm-font-weight-normal) var(--fm-font-size-16) /
        var(--fm-line-spacing-24) var(--fm-font-family-roboto);

      color: var(--neutral-grey-1-text);
      text-align: left;
    }

    .label {
      font-weight: 400;
      letter-spacing: var(--fm-character-spacing-0);
      color: var(--neutral-grey-1-text);
      font: var(--fm-font-style-normal) normal var(--fm-font-weight-normal) var(--fm-font-size-16) /
        var(--fm-line-spacing-24) var(--fm-font-family-roboto);
    }
  }

  &-disabled {
    & .fm-radio__form-item {
      &__input {
        border: 2px solid var(--neutral-grey-4-light-bg);
      }
      &__label {
        color: var(--neutral-grey-2-lighter-text);
        border: solid red 2px;
      }
    }
  }

  &-error {
    & .fm-radio__form-item {
      &__input {
        border: 2px solid var(--notification--error);
      }
    }
  }
}

.side-text {
  flex-grow: 1;
  text-align: right;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: var(--neutral-grey-2-lighter-text);
}

.radio-label {
  margin-bottom: 2px;
}
</style>
