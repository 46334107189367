import CreateAndPublishMediaService from '@/services/CreateAndPublishMediaService';
import { handleAjax } from '@/createandpublish/core/util';
import { Module } from 'vuex';
import { VideoAsset } from '@/types/createandpublish/mediaLibraries';

const MediaService = new CreateAndPublishMediaService();

export default {
  namespaced: true,

  state: {
    videoAssets: [],
  },

  getters: {
    videoAssets(state) {
      return state.videoAssets;
    },
  },

  mutations: {
    SET_VIDEO_ASSETS(state, videoAssets: VideoAsset[]) {
      state.videoAssets = videoAssets;
    },

    CLEAR_VIDEO_ASSETS(state) {
      state.videoAssets = [];
    },

    UPDATE_VIDEO_ASSET(state, { assetId, metadata }) {
      const index = state.videoAssets.findIndex((asset) => asset.id === assetId);
      if (index !== -1) {
        const updatedAsset = {
          ...state.videoAssets[index],
          ...metadata,
        };
        state.videoAssets.splice(index, 1, updatedAsset);
      }
    },

    DELETE_VIDEO_ASSET(state, assetId: number) {
      const index = state.videoAssets.findIndex((asset) => asset.id === assetId);
      if (index !== -1) {
        state.videoAssets.splice(index, 1);
      }
    },
  },

  actions: {
    async getVideoAssets({ dispatch, commit }) {
      const mediaType = 'video';
      await handleAjax({
        request: MediaService.getMediaLibraryItems(mediaType),
        dispatch,
        commit,
        mutation: 'SET_VIDEO_ASSETS',
      });
    },

    async updateVideoAsset({ dispatch, commit }, videoAsset: VideoAsset) {
      const mediaType = 'video';
      await handleAjax({
        request: MediaService.updateMediaLibraryItem(mediaType, videoAsset),
        dispatch,
        commit,
        mutation: 'UPDATE_VIDEO_ASSET',
      });
    },

    async deleteVideoAsset({ dispatch, commit }, assetId: number) {
      const mediaType = 'video';
      await handleAjax({
        request: MediaService.deleteMediaLibraryItem(mediaType, assetId),
        dispatch,
        commit,
        mutation: 'DELETE_VIDEO_ASSET',
        modify() {
          return assetId;
        },
      });
    },
  },
} as Module<S, R>;

/**
 * Module state
 */
interface S {
  videoAssets: VideoAsset[];
}
/**
 * Vuex store rootState
 */
interface R {
  // Define rootState properties here, as necessary.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}
