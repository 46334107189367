

















import { Component, Vue, Prop } from 'vue-property-decorator';
import StatIncreaseIcon from '@/assets/audience/arrow-up-icon.svg';
import StatDecreaseIcon from '@/assets/audience/arrow-down-icon.svg';
import { formatNumber, formatPercentChange } from '@/audience/filters';

@Component({
  name: 'ContextTotalCard',
  filters: {
    formatNumber,
    formatPercentChange,
  },
})
export default class ContextTotalCard extends Vue {
  @Prop({ type: String, required: true }) title!: string;
  @Prop({ type: Number, required: true }) value!: number;
  @Prop({ type: Number, required: true }) percentChange!: number;

  get isPercentChangePositive() {
    return Math.sign(this.percentChange) >= 0;
  }

  get statChangeIcon() {
    return this.isPercentChangePositive ? StatIncreaseIcon : StatDecreaseIcon;
  }

  get statChangeIconAltText() {
    const changeDirText = this.isPercentChangePositive ? 'an increase' : 'a decrease';
    return `An arrow indicating ${changeDirText} of the ${this.title} metric.`;
  }

  get showPercentChange() {
    return !isNaN(this.percentChange) && this.value > 0;
  }
}
