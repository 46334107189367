






import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class ViewSubtitle extends Vue {
  @Prop({ default: 'NA', required: true }) title!: string;
  @Prop({ default: 'NA', required: true }) qa!: string;
}
