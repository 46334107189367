














































































































import { Component, Vue } from 'vue-property-decorator';
import { namespace, Getter } from 'vuex-class';

import LeaderboardIcon from '@/audience/components/nav-icons/Leaderboard.vue';
import LeaderboardRanking from '@/audience/components/leaderboard/LeaderboardRanking.vue';
import LeaderboardSelfCard from '@/audience/components/leaderboard/LeaderboardSelfCard.vue';
import LeaderboardCompareCard from '@/audience/components/leaderboard/LeaderboardCompareCard.vue';
import LeaderboardCompareCardPlaceholder from '@/audience/components/leaderboard/LeaderboardCompareCardPlaceholder.vue';
import LeaderboardOptInBanner from '@/audience/components/leaderboard/LeaderboardOptInBanner.vue';
import CloseButton from '@/createandpublish/components/CloseButton.vue';

import moment from 'moment-timezone';

import UserSettingsModule from '@/utils/userSettings';
import GenericAvatarImage from '@/assets/audience/generic-avatar.svg';

import { SocialRankingContext } from '@/types/audience';
import { SocialRankingInterval } from 'content-cloud-types/dist/types/audience/SocialRanking';

import type { Brand } from '@/types/Brand';
import type { LeaderboardsByInterval, LeaderboardRankData, SocialAnalytics, AyrshareProfile } from '@/types/audience';
import type { AudienceLeaderboardContent } from 'content-cloud-types/dist/types/audience/AudienceLeaderboardTypes';

const audienceModule = namespace('AudienceAnalyticsStore');

const UserSettings = new UserSettingsModule();

Component.registerHooks(['mounted']);

@Component({
  name: 'Leaderboard',
  components: {
    LeaderboardIcon,
    LeaderboardRanking,
    LeaderboardSelfCard,
    LeaderboardCompareCard,
    LeaderboardCompareCardPlaceholder,
    LeaderboardOptInBanner,
    CloseButton,
  },
})
export default class Leaderboard extends Vue {
  @Getter selectedBrand!: Brand;
  @audienceModule.Getter timeZone!: string;
  @audienceModule.Getter profile!: AyrshareProfile;
  @audienceModule.Getter isProfileOptedIntoLeaderboard!: boolean;
  @audienceModule.Action getLeaderboardRankings;
  @audienceModule.Getter leaderboards?: AudienceLeaderboardContent;
  @audienceModule.Getter leaderboardRankings!: LeaderboardsByInterval;
  @audienceModule.Action getLeaderboardSelfAnalytics;
  @audienceModule.Getter leaderboardSelfAnalytics!: SocialAnalytics;
  @audienceModule.Action toggleLeaderboardOptIn;

  isLoading = false;

  isUserInLeaderboard = false;
  onIsUserInLeaderboardChange($event: Event) {
    this.compareCard = {
      isVisible: false,
      profileData: null,
    };
    const { value } = $event.target as HTMLInputElement;
    this.isUserInLeaderboard = value as unknown as boolean;
    if (value) {
      this.fetchLeaderboardData();
    }
    try {
      this.toggleLeaderboardOptIn();
    } catch {
      this.isUserInLeaderboard = !value as unknown as boolean;
    }
  }

  context = (UserSettings.get('context') as SocialRankingContext | undefined) ?? SocialRankingContext.growth_rate;
  async onContextChange(value: SocialRankingContext) {
    this.context = value;
    UserSettings.save('context', value);
    this.onCompareCardClose();
  }
  get contextOptions() {
    return Object.values(SocialRankingContext);
  }

  rankingInterval =
    (UserSettings.get('rankingInterval') as SocialRankingInterval | undefined) ?? SocialRankingInterval.day;
  onRankingIntervalChange(value: SocialRankingInterval) {
    this.rankingInterval = value;
    UserSettings.save('rankingInterval', value);
  }
  get rankingIntervalOptions() {
    return [
      { label: 'Day', value: SocialRankingInterval.day },
      { label: 'Week', value: SocialRankingInterval.week },
      // { label: 'Month', value: SocialRankingInterval.month },
    ];
  }

  get userProfileImage(): string {
    return this.profile.image ?? GenericAvatarImage;
  }
  onImgError($event: Event) {
    const imageEl = $event.target as HTMLImageElement;
    imageEl.src = GenericAvatarImage;
  }

  get selectedLeaderboardRankings(): LeaderboardRankData[] {
    if (this.isLoading || !this.isProfileOptedIntoLeaderboard) return [];
    const { context, rankingInterval } = this;
    return this.leaderboardRankings[rankingInterval]?.[context] ?? [];
  }

  compareCard: CompareCard = {
    isVisible: false,
    profileData: null,
  };
  onCompareCardClose() {
    this.compareCard = {
      isVisible: false,
      profileData: null,
    };
  }
  onShowUserDetails(profileData) {
    this.compareCard = {
      isVisible: true,
      profileData,
    };
  }
  get selectedUserBrandId(): number {
    if (!this.compareCard.profileData) return NaN;
    return this.compareCard.profileData?.profile?.brandId ?? NaN;
  }

  selfCardModal = {
    isVisible: false,
  };

  fetchSelfAnalytics() {
    const asOfDate = this.leaderboards?.rankings[this.rankingInterval]?.asOfDate;
    if (!asOfDate) {
      console.warn(
        '[Leaderboard: fetchSelfAnalytics] Leaderboard data not available. Cannot fetch analytics for selected interval.'
      );
      return;
    }

    const mostRecentResultDate = moment.tz(asOfDate, this.timeZone);
    const payload = {
      start: mostRecentResultDate.clone().subtract(1, 'month').unix(),
      end: mostRecentResultDate.unix(),
      platforms: 'all',
    };
    this.getLeaderboardSelfAnalytics(payload);
  }

  get selfLeaderboardRankData() {
    return this.leaderboardRankings[this.rankingInterval]?.[this.context].find(
      (rankData) => rankData.profile?.brandId === this.selectedBrand.id
    );
  }

  get selfAnalyticsByRankingInterval(): SocialAnalytics {
    const asOfDate = this.leaderboards?.rankings[this.rankingInterval]?.asOfDate;

    if (!asOfDate) return {};

    const numDaysInMonth = moment(asOfDate).daysInMonth();

    let numDaysToDisplay = NaN;
    switch (this.rankingInterval) {
      case SocialRankingInterval.day:
        numDaysToDisplay = 1;
        break;
      case SocialRankingInterval.week:
        numDaysToDisplay = 7;
        break;
      case SocialRankingInterval.month:
        numDaysToDisplay = numDaysInMonth;
        break;
      default:
    }
    const analyticsEntriesLength = Object.keys(this.leaderboardSelfAnalytics).length;
    const filteredAnalytics = Object.entries(this.leaderboardSelfAnalytics).splice(
      analyticsEntriesLength - numDaysToDisplay - 1
    );
    return Object.fromEntries(filteredAnalytics);
  }

  async fetchLeaderboardData() {
    this.isLoading = true;
    await this.getLeaderboardRankings();
    await this.$nextTick();
    this.fetchSelfAnalytics();
    this.isLoading = false;
  }

  mounted() {
    if (this.isProfileOptedIntoLeaderboard) {
      this.fetchLeaderboardData();
    }
    this.isUserInLeaderboard = this.isProfileOptedIntoLeaderboard;
  }
}

interface CompareCard {
  isVisible: boolean;
  profileData: LeaderboardRankData | null;
}
