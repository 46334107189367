
















































































/* eslint-disable prettier/prettier */
import { Component, Vue, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import TableRow from '@/createandpublish/components/common/table/TableRow.vue';
import TableHeaderCell from '@/createandpublish/components/common/table/TableHeaderCell.vue';
import TableCell from '@/createandpublish/components/common/table/TableCell.vue';
import BaseCheckbox from '@/createandpublish/components/BaseCheckbox.vue';
import PlayButton from '@/createandpublish/components/common/PlayButton.vue';
import CampaignCircle from '@/components/brands/CampaignCircle.vue';
import TableInlineMenu from '@/createandpublish/components/AudioLibrary/TableInlineMenu.vue';
import MiniHLSAudioPlayer from '@/createandpublish/components/AudioLibrary/MiniHLSAudioPlayer.vue';
import { formatDateTime, formatDuration } from '@/createandpublish/core/filters';
import { AudioLibraryMode, AudioEvent } from '@/types/createandpublish/mediaLibraries/AudioLibrary';
import { eventBus, busEvents, eventSources } from '@/createandpublish/core/eventBus/audioWizardEventBus';

const createAndPublishStore = namespace('CreateAndPublishStore');
const audioWizardModule = namespace('CreateAndPublishStore/audioWizard');

const KEYBOARD_KEYS = {
  UP: 'ArrowUp',
  DOWN: 'ArrowDown',
  LEFT: 'ArrowLeft',
  RIGHT: 'ArrowRight',
  HOME: 'Home',
  END: 'End',
  ENTER: 'Enter',
  ESCAPE: 'Escape',
  SPACE: ' ',
};

Component.registerHooks(['created']);

@Component({
  name: 'AudioLibraryTable',
  components: {
    TableRow,
    TableHeaderCell,
    TableCell,
    BaseCheckbox,
    PlayButton,
    CampaignCircle,
    TableInlineMenu,
    MiniHLSAudioPlayer,
  },
  filters: {
    formatDateTime,
    formatDuration,
  },
})
export default class AudioLibraryTable extends Vue {
  @Prop({ type: Array, default: () => [] }) readonly audioData!: AudioEvent[];
  @Prop({ type: String, required: true }) readonly libraryMode!: AudioLibraryMode;

  @createAndPublishStore.Getter timeZone;
  @createAndPublishStore.Getter('station_name') stationName;
  @audioWizardModule.Getter('wizardModes') readonly modes!: Record<string, AudioLibraryMode>;

  sort = 'date';
  order = 1;

  // Used to model the checkboxes.
  selectedEvents: string[] = [];

  onInputValueCheckedChange($event: InputEvent, audioEvent: AudioEvent) {
    const { checked } = $event.target as HTMLInputElement;
    checked
      ? eventBus.$emit(busEvents.ADD_STACK_EVENT, { source: eventSources.LIBRARY_TABLE, event: audioEvent })
      : eventBus.$emit(busEvents.REMOVE_STACK_EVENT, { source: eventSources.LIBRARY_TABLE, event: audioEvent });
  }

  selectAllValue: string[] = [];
  onSelectAllInput(value) {
    const isSelected = value.includes('checked');
    if (isSelected) {
      this.audioData.forEach((event) => {
        eventBus.$emit(busEvents.ADD_STACK_EVENT, { event });
      });
    } else {
      this.audioData.forEach((event) => {
        eventBus.$emit(busEvents.REMOVE_STACK_EVENT, { event });
      });
    }
  }

  get tableHeaders() {
    const dateHeader = {
      key: 'date',
      label: 'Date',
      isActive: this.sort === 'date',
      arrowUp: this.order === -1,
      onClick: () => this.sortHandler('date'),
    };

    return [
      {
        key: 'name',
        label: 'Name',
        isActive: this.sort === 'name',
        arrowUp: this.order === -1,
        onClick: () => this.sortHandler('downloads'),
      },
      ...(this.libraryMode === this.modes.LIBRARY ? [dateHeader] : []),
      {
        key: 'duration',
        label: 'Duration',
        isActive: this.sort === 'duration',
        arrowUp: this.order === -1,
        onClick: () => this.sortHandler('duration'),
      },
      {
        key: 'campaigns',
        label: 'campaigns',
        isActive: this.sort === 'campaigns',
        arrowUp: this.order === -1,
        onClick: () => this.sortHandler('campaigns'),
      },
    ];
  }

  sortHandler(field: string): void {
    if (field === this.sort) {
      this.order *= -1;
    } else {
      this.sort = field;
    }
  }

  onRowKeydown(rowIndex: number, $event: KeyboardEvent): void {
    $event.preventDefault();
    $event.stopPropagation();
    const { key } = $event;
    const currentFocusType = this._getFocusedElType();
    const tableRows = (this.$refs?.tableRows || []) as Vue[];
    const prevIndex = rowIndex === 0 ? tableRows.length - 1 : rowIndex - 1;
    const nextIndex = rowIndex === tableRows.length - 1 ? 0 : rowIndex + 1;
    const { leftFocusType, rightFocusType } = this._getLeftRightFocusType(currentFocusType);

    switch(key) {
      case KEYBOARD_KEYS.UP:
        this._tableFocusHelper(tableRows[prevIndex], currentFocusType);
        break;
      case KEYBOARD_KEYS.DOWN:
        this._tableFocusHelper(tableRows[nextIndex], currentFocusType);
        break;
      case KEYBOARD_KEYS.LEFT:
        this._tableFocusHelper(tableRows[rowIndex], leftFocusType);
        break;
      case KEYBOARD_KEYS.RIGHT:
        this._tableFocusHelper(tableRows[rowIndex], rightFocusType);
        break;
      default:
        break;
    }
  }

  // Helper for onRowKeydown
  get _focusTypes() {
    return {
      CHECKBOX: 'checkbox',
      BUTTON: 'play-button',
      MENU: 'menu',
    };
  }

  // Helper for onRowKeydown
  _getFocusedElType(): string {
    const activeEl = document.activeElement as HTMLElement;
    return activeEl?.dataset.focus || '';
  }

  // Helper for onRowKeydown
  _tableFocusHelper(tableRow: Vue, focusType: string): void {
    const checkbox = tableRow.$el.querySelector('div.base-checkbox[data-focus="checkbox"]') as HTMLElement;
    const playButton = tableRow.$el.querySelector('button[data-focus="play-button"]') as HTMLElement;
    const menu = tableRow
      .$el
      .querySelector('fm-inline-menu[data-focus="menu"]')
      ?.shadowRoot
      ?.querySelector('button.activator') as HTMLElement;

    const { CHECKBOX, BUTTON, MENU } = this._focusTypes;
    switch (focusType) {
      case CHECKBOX:
        checkbox && checkbox.focus();
        break;
      case BUTTON:
        playButton && playButton.focus();
        break;
      case MENU:
        menu && menu.focus();
        break;
    }
  }

  // Helper for onRowKeydown
  _getLeftRightFocusType(currentFocusType: string) {
    const { CHECKBOX, BUTTON, MENU } = this._focusTypes;
    switch (currentFocusType) {
      case CHECKBOX:
        return { leftFocusType: MENU, rightFocusType: BUTTON };
      case BUTTON:
        return { leftFocusType: CHECKBOX, rightFocusType: MENU };
      case MENU:
        return { leftFocusType: BUTTON, rightFocusType: CHECKBOX };
      default:
        return { leftFocusType: '', rightFocusType: '' };
    }
  }

  get dummyCampaign() {
    return {
      eClincherId: '4c3749c8-eabf-498c-9eb0-786a9357cf0e',
      name: '1',
      color: 'hotpink',
      active: true,
      brandId: 1,
      createdBy: null,
      updatedBy: null,
      id: 1,
      createdAt: '2022-02-14T18:47:46.000Z',
      updatedAt: '2022-02-14T18:47:46.000Z',
    };
  }

  currentlyPlayingEventId = '';

  onPlayButtonClick(eventId: string): void {
    if (this.currentlyPlayingEventId === eventId) {
      // Currently playing button was click, so stop it.
      this.currentlyPlayingEventId = '';
      return;
    }
    // Lets other players know to stop playing.
    eventBus.$emit(busEvents.AUDIO_PLAY_EVENT, eventSources.LIBRARY_TABLE);
    this.currentlyPlayingEventId = eventId;
  }

  get nowPlayingSrc() {
    if (!this.currentlyPlayingEventId) return undefined;

    const prefix = `//${process.env?.VUE_APP_API_URL}/${this.stationName}`;
    const endpoint = `/event/${this.currentlyPlayingEventId}.m3u8`;
    const query = '?version=3&Please=SirCanIHaveSomeMore';
    return `${prefix}${endpoint}${query}`;
  }

  created() {
    this.setupEventBusListeners();
  }

  setupEventBusListeners() {
    eventBus.$on(busEvents.ADD_STACK_EVENT, this.onEventAddedToStack);
    eventBus.$on(busEvents.REMOVE_STACK_EVENT, this.onEventRemovedFromStack);
    eventBus.$on(busEvents.AUDIO_PLAY_EVENT, this.onAudioPlayEvent);
  }

  onEventAddedToStack({ source, event }) {
    if (source === eventSources.LIBRARY_TABLE) return

    const index = this.selectedEvents.indexOf(event.id);
    if (index === -1) this.selectedEvents.push(event.id);
  }

  onEventRemovedFromStack({ source, event }) {
    if (source === eventSources.LIBRARY_TABLE) return

    const index = this.selectedEvents.indexOf(event.id);
    if (index !== -1) this.selectedEvents.splice(index, 1);
  }

  onAudioPlayEvent(source) {
    if (source !== eventSources.LIBRARY_TABLE) {
      // Pause this player.
      this.currentlyPlayingEventId = '';
    }
  }
}
