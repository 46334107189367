






import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: {},

  data() {
    return {};
  },

  methods: {},
})
export default class NewStoriesToast extends Vue {}
