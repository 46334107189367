


































import { Component, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';

import FooterLinkColumn from './footer/linkColumn.vue';
import Button from '@/components/common/buttons/Button.vue';

import type { Feature } from '@/types/features/FEFeature';

Component.registerHooks(['mounted']);

@Component({
  components: {
    FooterLinkColumn,
    Button,
  },
})
export default class PortalFooter extends Vue {
  @Getter features!: Feature[];

  get HelpSection() {
    return {
      header: 'HELP',
      links: [
        { route: '/contact', name: 'Contact us' },
        { route: 'http://support.futuricontentcloud.com/support/home', name: 'Knowledge Base', isExternal: true },
        { route: '/terms-and-conditions', name: 'Terms & Conditions' },
        { route: 'https://futurimedia.com/futuri-privacy-policy/', name: 'Privacy Policy', isExternal: true },
      ],
    };
  }
  get UserPortalLinks() {
    return [
      { route: '/profile', name: 'Profile' },
      { route: '/', name: 'Account Overview' },
      ...(this.isEcommAvailable ? [{ route: '/subscriptions', name: 'Subscription & billing' }] : []),
      { route: '/users', name: 'Manage users' },
      { route: '/brands', name: `Manage brands ${this.isCampaignsAvailable ? '& campaigns' : ''}` },
    ];
  }

  get mainFooterDetails() {
    return [
      {
        header: 'ABOUT',
        links: [
          { route: '/tools/#audience', name: 'About Audience' },
          { route: '/about/', name: 'About Discover' },
          { route: '/tools/#createPublish', name: 'About Create&Publish' },
          { route: '/about', name: 'About Futuri' },
        ],
      },
      {
        header: 'ACCOUNT',
        links: this.UserPortalLinks,
      },
      this.HelpSection,
    ];
  }

  get audienceFooterDetails() {
    return [
      {
        header: 'QUICK LINKS',
        links: [],
      },
      {
        header: 'AUDIENCE',
        links: this.UserPortalLinks,
      },
      this.HelpSection,
    ];
  }

  get discoverFooterDetails() {
    return [
      {
        header: 'QUICK LINKS',
        links: [
          { route: '/discover', name: 'Trending' },
          { route: '/discover/following', name: 'Following' },
          { route: '/discover/settings', name: 'Settings' },
        ],
      },
      {
        header: 'DISCOVER',
        links: this.UserPortalLinks,
      },
      this.HelpSection,
    ];
  }

  get createFooterDetails() {
    return [
      {
        header: 'QUICK LINKS',
        links: [
          { route: '/create', name: 'Dashboard' },
          { route: '/planner', name: 'Planner' },
          { route: '/podcasts', name: 'Podcasts' },
          { route: '/create', name: 'Audio' },
          { route: '/create', name: 'Video' },
          { route: '/create', name: 'Images' },
          { route: '/analytics', name: 'Analytics' },
          { route: '/tools', name: 'Tools' },
          { route: '/create-settings', name: 'Settings' },
        ],
      },
      {
        header: 'ACCOUNT',
        links: this.UserPortalLinks,
      },
      this.HelpSection,
    ];
  }

  footerHiddenForRoutes = [''];

  get isEcommAvailable() {
    return this.features.includes('ecomm');
  }
  get isCampaignsAvailable() {
    return this.features.includes('campaigns');
  }

  get showFooter() {
    return !this.footerHiddenForRoutes.includes(this.$route.path);
  }
  get isDiscover() {
    return this.$route.path.search('discover') === 1;
  }
  get isCreate() {
    return this.$route.path.search('create') === 1;
  }
  get isAudience() {
    return this.$route.path.search('audience') === 1;
  }

  get logoUrl() {
    return require('@/assets/FuturiCC.svg');
  }
  get footerLinks() {
    if (this.isDiscover) {
      return this.discoverFooterDetails;
    } else if (this.isCreate) {
      return this.createFooterDetails;
    } else if (this.isAudience) {
      return this.audienceFooterDetails;
    } else {
      return this.mainFooterDetails;
    }
  }

  freshChatMessageCount = 0;

  setupFreshChatListener() {
    const addListener = () => {
      window.fcWidget.on('unreadCount:notify', ({ count }) => {
        this.freshChatMessageCount = count;
      });
    };
    let checker = setInterval(() => {
      if (window.fcWidget && window.fcWidget.isLoaded()) {
        addListener();
        clearInterval(checker);
      }
    }, 1000);
  }

  toggleFreshChat() {
    const widget = window.fcWidget;
    if (!widget) return;

    if (widget.isOpen()) {
      widget.close();
    } else {
      widget.open();
    }
  }

  mounted() {
    this.setupFreshChatListener();
  }
}
