import { PlatformLabels } from '@/audience/data/socialPlatformChartConfig';
import type { Moment } from 'moment-timezone';
import type { ChartDataset } from 'chart.js';
import type {
  ChartDataByPlatform,
  SocialAnalytics,
  Dataset,
  ApiPlatformLabel,
  ActiveSocialAccount,
  PlatformAnalyticsContext,
} from '@/types/audience';

/**
 * Transforms social platform analytics data into an easy-to-consume format for use in the various charts
 * @param {String} label the name of one of the audience module platforms
 * @param socialAnalytics "extendedSocialAnalytics" getter from the audience module
 * @returns social analytics data transformed for chart
 */
export function getChartDataByPlatform(label: string, socialAnalytics: SocialAnalytics): ChartDataByPlatform {
  const socialAnalyticsValues = Object.values(socialAnalytics);

  const audienceValues = socialAnalyticsValues.map((dataByDate) => {
    const platform = dataByDate[label];
    if (!platform) return NaN;
    return platform.followers;
  });
  const audienceData = [{ label: 'Followers', data: audienceValues }];

  const breakdownsByType: Dataset[][] | null = ['impressionsBreakdown', 'engagementBreakdown'].map((metricType) => {
    // Find an object that has the breakdown data we need so we can construct a model
    const dateDataWithBreakdown = socialAnalyticsValues.find((dataByDate) => {
      const platform = dataByDate[label];
      if (!platform) return false;
      return Object.prototype.hasOwnProperty.call(platform, metricType);
    });
    if (!dateDataWithBreakdown) return null;

    // Model the data
    const metricsByBreakdownType =
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      dateDataWithBreakdown[label]![metricType].map((metric) => ({ label: metric.label, data: [] })) || [];

    // Fill the model with data
    metricsByBreakdownType.forEach((_, i, self) => {
      self[i].data = socialAnalyticsValues.map((dataByDate) => {
        const value = dataByDate[label]?.[metricType][i]?.value;
        return value !== undefined ? value : NaN;
      });
    });
    return metricsByBreakdownType;
  });

  return {
    audience: audienceData,
    impressions: breakdownsByType[0],
    engagement: breakdownsByType[1],
  };
}

/**
 * Provides the summed value of all metric data for a given context and date.
 * @param platformData as produced by the chart util getChartDataByPlatform
 * @param context metric context
 * @param dataIndex index in a chart dataset representing the date you want data for
 * @returns summed value of all metrics for a given context and dataIndex
 */
export function getMetricTotalsByContextAndDataIndex(
  platformData: ChartDataByPlatform,
  context: PlatformAnalyticsContext,
  dataIndex: number
): number {
  const hasNoValidData = platformData[context]?.every((metric) => isNaN(metric.data[dataIndex]));
  if (hasNoValidData) {
    // Every value in all datasets are NaN.
    return NaN;
  }

  return (
    platformData[context]?.reduce((acc: number, metric) => {
      const { data } = metric;
      const dataIndexValue = data[dataIndex];
      acc += isNaN(dataIndexValue) ? 0 : dataIndexValue;
      return acc;
    }, 0) ?? 0
  );
}

export function metricGroupKeyByContext(context: PlatformAnalyticsContext): string {
  let metricGroupKey = '';
  switch (context) {
    case 'audience':
      metricGroupKey = 'followers';
      break;
    case 'impressions':
      metricGroupKey = 'impressionsBreakdown';
      break;
    case 'engagement':
      metricGroupKey = 'engagementBreakdown';
      break;
  }
  return metricGroupKey;
}

/**
 * Takes API data and creates objects for every date in the selected date range.
 * Also remaps the platform labels to proper casing.
 */
export function extendSocialAnalytics(
  startDate: Moment,
  endDate: Moment,
  socialAnalytics: SocialAnalytics,
  activeSocialAccounts: ActiveSocialAccount[]
): SocialAnalytics {
  const numDays = endDate.clone().startOf('day').diff(startDate.clone().startOf('day'), 'days') + 1;

  const dateLabels = new Array(numDays).fill(undefined).map((_, i) => {
    return startDate.clone().startOf('day').add(i, 'day').format('YYYY-MM-DD');
  });

  const platformKeyMapping: Record<string, PlatformLabels> = Object.values(PlatformLabels).reduce((acc, value) => {
    acc[value.toLowerCase()] = value; // Backend returns lower-case
    return acc;
  }, {});

  const activeSocialAccountsLabels: ApiPlatformLabel[] = activeSocialAccounts.map(({ platform }) => platform) || [];

  const getPlatformDataByDateLabel = (dateLabel: string) => {
    const socialAnalyticsByDate = socialAnalytics[dateLabel];

    if (!socialAnalyticsByDate) {
      return activeSocialAccountsLabels.reduce((acc, label) => {
        acc[platformKeyMapping[label]] = null;
        return acc;
      }, {});
    }

    return activeSocialAccountsLabels.reduce((acc, label) => {
      const platformData = socialAnalyticsByDate[label];
      acc[platformKeyMapping[label]] = platformData ?? null;
      return acc;
    }, {});
  };

  return dateLabels.reduce((acc, dateLabel) => {
    acc[dateLabel] = getPlatformDataByDateLabel(dateLabel);
    return acc;
  }, {});
}

/**
 * Determines whether or not a group of datasets' data properties contain
 * any values other than 0 or NaN.
 * @param datasets An array of Chart.js datasets
 * @returns true if datasets data has any valid non-zero values
 */
export function chartHasNonZeroData(datasets: ChartDataset[]) {
  return datasets.some(({ data }) => {
    const result = data.reduce((acc: number, value) => {
      if (typeof value !== 'number') return acc;
      acc += isNaN(value) ? 0 : value;
      return acc;
    }, 0);
    return result > 0;
  });
}

/**
 * Converts a date string into a Date object
 * @param {String} dateStr a string in the format YYYY-MM-DD
 * @returns {Date} Date object
 */
export const makeDate = (dateStr: string): Date => {
  const [year, month, day] = dateStr.split('-');
  return new Date(`${year}-${month}-${day}T00:00:00`);
};
