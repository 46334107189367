<template>
  <fm-card :expandWidth.prop="true">
    <!-- Container -->
    <card-layout>
      <div slot="image">
        <img v-if="episode.rss_image.length" class="center" height="166" :src="episodeImage" width="166" />
        <img v-else src="@/assets/post-logo.svg" class="card-image" />
      </div>
      <!-- Meta -->
      <div slot="meta">
        <!-- SMC list modal, move this into its own component if necessary - GK -->
        <fm-modal :visible.prop="isShowingSMCs" class="more-smcs-modal">
          <div slot="controlRow" class="modal-control-row">
            <fm-text tag="h3">{{ episode.name }}</fm-text>
          </div>
          <div slot="content" class="smc-modal-content">
            <div class="d-flex align-items-center" v-for="smc in smcs" :key="smc.id">
              <img :src="smc.icon" width="20" height="20" />
              &nbsp;
              <!-- Why is text not centered in fm-text, it's 2 pixels off -GK -->
              <fm-text tag="p" class="d-flex align-items-center" style="margin-top: 2px">
                {{ getSMCTitleById(smc.id) }}
              </fm-text>
            </div>
          </div>
          <div slot="actionRow">
            <fm-button-secondary @click="onCloseMoreSMC">Close</fm-button-secondary>
          </div>
        </fm-modal>
        <!-- Tags-->
        <div id="tags-and-smcs" class="d-flex align-items-center justify-space-between w-100">
          <!-- we can hide the parent div but cards will not be same height - GK -->
          <tag-list :tags="tags" :showId="showId" />
          <div id="smcs">
            <img
              v-for="smc in smcs.slice(0, 5)"
              :src="smc.icon"
              :key="smc.id"
              width="20"
              height="20"
              @click="onOpenMoreSMC"
            />
            <template v-if="smcs.length > 5">
              <button @click="onOpenMoreSMC">
                <div class="more-text" ref="moreText">
                  <fm-text variant="disclaimer"> +{{ smcs.length - 5 }} more </fm-text>
                </div>
              </button>
            </template>
          </div>
        </div>
      </div>
      <div slot="content" class="episode-list-card-content">
        <fm-text :tag.prop="'h2'" :as.prop="'h3'" class="show-title nowrap"> {{ episode.name }}</fm-text>
        <!-- Content -->
        <div class="truncate-one-line">
          {{ lastPublished }}
        </div>
        <div class="truncate-one-line">
          {{ episode.time | formatDuration }}
        </div>
      </div>
      <fm-card-action-bar slot="actionBar" :firstAction.prop="actions.firstAction" :menuOptions.prop="menuOptions" />
    </card-layout>
    <confirm-modal-v-3
      v-if="isConfirmModalOpen"
      contentText="Are you sure you want to delete this episode?"
      confirmText="Delete"
      cancelText="Cancel"
      @close="isConfirmModalOpen = false"
      @confirm="deletePlaylist"
    />
    <confirm-modal-v-3
      v-if="isUnpublishModalOpen"
      :contentText="
        isScheduled
          ? 'Note: If unscheduled, this will be moved to the Unpublished tab.'
          : 'Note: Posts to Facebook and Twitter need to be removed on those platforms directly.'
      "
      :confirmText="isScheduled ? 'Unschedule' : 'Unpublish'"
      cancelText="Cancel"
      @close="isUnpublishModalOpen = false"
      @confirm="unpublish"
    />
    <download-notice-modal v-if="isDownloadNoticeModalOpen" @on:cancel="handleDownloadNoticeModalCancel" />
    <embed-audio-modal v-if="isEmbedAudioModalOpen" :episodeId="episode.playlist_id" @on:cancel="toggleEmbedModal" />
    <share-url-modal v-if="isShareUrlModalOpen" :url="episode.short_url" @on:cancel="toggleSharingUrlModal" />
  </fm-card>
</template>
<script>
import moment from 'moment';
import { createNamespacedHelpers } from 'vuex';
const { mapGetters } = createNamespacedHelpers('CreateAndPublishStore');
const { mapState: mapStateDraftEpisodes } = createNamespacedHelpers('CreateAndPublishStore/draftEpisodes');
import CardLayout from '../common/card/CardLayout.vue';
import DownloadNoticeModal from '../DownloadNoticeModal.vue';
import EmbedAudioModal from '../EmbedAudioModal.vue';
import ShareUrlModal from '../ShareUrlModal.vue';
import TagList from '../common/card/TagList.vue';
import ConfirmModalV3 from '../ConfirmModalV3.vue';

import { episodeMoreMenuMixin } from '@/createandpublish/core/mixins/episodeMoreMenuMixin';

export default {
  name: 'ShowDetailsGridCard',

  components: {
    CardLayout,
    DownloadNoticeModal,
    EmbedAudioModal,
    ShareUrlModal,
    TagList,
    ConfirmModalV3,
  },

  mixins: [episodeMoreMenuMixin],

  data() {
    return {
      isShowingSMCs: false,
      listOfSMCs: [],
      actions: {
        firstAction: {
          icon: 'edit',
          hoverText: 'Edit',
          onClick: () => {
            this.$router.push({
              path: `/create/${this.selectedBrand?.id}/podcasts/shows/${this.showId}/episodes/${this.episode.playlist_id}/edit/info?editingEpisode=${this.episode.playlist_id}`,
            });
          },
        },
      },
    };
  },
  computed: {
    // List of all SMCs
    ...mapStateDraftEpisodes(['currentDraftEpisodeId']),
    ...mapGetters(['allSocialMediaConnections', 'isAvess', 'selectedBrand']),

    // Transformed list of SMCs
    smcs() {
      if (this.episode.smc_data) {
        return this.episode.smc_data.map((smc) => {
          return {
            ...smc,
            icon: this.getSMCIconByType(smc),
          };
        });
      }
      return []; // Fallback
    },
    // Tags text
    tagsText() {
      let result = '';
      if (this.episode.playlist_categories && this.episode.playlist_categories.length > 0) {
        this.episode.playlist_categories.forEach((category, index) => {
          const _separator = index + 1 !== this.episode.playlist_categories.length ? ', ' : '';
          // Show first 3 tags only
          if (index <= 2) {
            result += `${category.name}${_separator}`;
          }
        });
        // If there are more than 3 tags
        if (this.episode.playlist_categories.length > 3) {
          const _numberOfTagsRemaining = this.episode.playlist_categories.length - 3;
          result += `+${_numberOfTagsRemaining} more`;
        }
      }
      return result;
    },
    // Timezone
    timeZone() {
      return this.$store.getters.settings.timezone;
    },
    // Format `published` string
    lastPublished() {
      const _time = this.episode.published;
      if (!_time) return '';
      return `Published ${moment
        .unix(_time)
        // .tz(this.timeZone)
        .format('MM/DD/YYYY')} at ${moment
        .unix(_time)
        // .tz(this.timeZone)
        .format('hh:mma')}`;
      // return _time
    },
    duration() {
      if (this.episode.time) {
        return `${this.episode.time} minutes`;
      }
      return '';
    },
    // Route that links to show details
    showDetailsRoute() {
      return `/shows/${this.show.id}/details`;
    },
    tags() {
      if (!this.episode.playlist_categories) return [];
      return this.episode.playlist_categories.map((category) => category.name);
    },
    showId() {
      return Number(this.$route.params.showId);
    },
    episodeImage() {
      const location = 'https://post.ccdev.futurimedia.com';

      if (!this.episode.rss_image.includes(location)) {
        return `${location}${this.episode.rss_image}`;
      }

      return this.episode.rss_image;
    },
  },
  methods: {
    getSMCTitleById(id) {
      const foundSMC = this.allSocialMediaConnections.filter((e) => Number(e.id) === Number(id));
      if (foundSMC && foundSMC[0] && foundSMC[0].id) {
        return foundSMC[0].title;
      }
      return 'NA';
    },
    onOpenMoreSMC() {
      this.isShowingSMCs = true;
    },
    onCloseMoreSMC() {
      this.isShowingSMCs = false;
    },
    getSMCIconByType(smc) {
      switch (smc.type) {
        case 'v_youtube':
          return require('@/assets/createandpublish-assets/episodes/youtube-red.png');
        case 'twitter':
          return require('@/assets/createandpublish-assets/episodes/rss-subtypes/twitter-blue.svg');
        case 'facebook':
          return require('@/assets/createandpublish-assets/episodes/rss-subtypes/facebook.svg');
        case 'v_download':
        case 'v_advanced':
          return require('@/assets/createandpublish-assets/create-episode/video-icon-green.svg');
        case 'v_linkedin':
          return require('@/assets/createandpublish-assets/episodes/rss-subtypes/linked-in.svg');
        case 'rss':
          return require('@/assets/createandpublish-assets/episodes/rss-subtypes/custom-rss.svg');
        case 'megaphone':
          return require('@/assets/createandpublish-assets/episodes/megaphone-logo.png');
        case 'webwidget':
        case 'instagram':
        case 'ftp_m4a':
        case 'temp':
        default:
          return require('@/assets/createandpublish-assets/episodes/grey-world.svg');
      }
    },
  },
  filters: {
    formatDuration(seconds) {
      // so episode length doesn't show 0 minutes unless it actually is
      const totalMins = (seconds / 60).toFixed() === '0' ? (seconds === 0 ? '0' : '1') : (seconds / 60).toFixed();
      if (totalMins > 59) {
        const hours = Math.floor(totalMins / 60);
        const minsRemaining = (totalMins % 60).toFixed();
        return `${hours} hr ${minsRemaining} min`;
      }
      return `${totalMins} min`;
    },
  },
  props: {
    episode: Object,
  },
};
</script>

<style lang="scss" scoped>
// SMCs modal
.more-smcs-modal {
  width: 100px;
  height: 100px;

  .modal-control-row {
    margin-bottom: 24px;
  }

  .smc-modal-content {
    margin-bottom: 24px;
    padding: 8px 4px;
  }

  p {
    margin-top: 3px;
  }
}

.episode-list-card-content {
  padding: 16px;
  height: 100%;
}

// Action bar

div#smcs {
  width: 35%;
  padding-right: 14px;
  display: flex;
  justify-content: flex-end;
  font-family: 'Roboto';

  img {
    margin-right: 6px;
    object-fit: contain;
  }

  button {
    color: var(--accent-links);
    text-decoration: underline;
  }
}
div#episode-details-tags {
  align-items: center;
  display: flex;
  height: 10px;
  justify-content: space-between;
  padding: 19px;
  width: 100%;

  div#episode-details-tags-container {
    align-items: center;
    color: var(--accent-links);
    display: flex;
    white-space: pre-wrap;
    width: 65%;
  }
  span.material-icons {
    color: var(--neutral-grey-2-lighter-text);
    margin-right: 10px;
  }
}

// Meta
div.episode-list-card-meta h4 {
  color: var(--neutral-grey-1-text);
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0px;
  text-align: left;
  white-space: pre-wrap;
  // Clamp
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  overflow: hidden;
}
// Content
.episode-list-card-content {
  color: var(--neutral-grey-2-lighter-text);
  font: normal normal normal 14px/21px Roboto;
  letter-spacing: 0px;
  text-align: left;
}

.show-title {
  margin-bottom: 0.5rem;
  max-height: 58px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.truncate-one-line {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.card-image {
  height: 166px;
}

@media screen and (max-width: 991px) {
  #tags-and-smcs {
    display: none !important;
  }
  .card-image {
    height: 77px;
  }
}
</style>
