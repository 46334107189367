




























































































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { Campaign } from '@/types/Campaign';
import { Brand } from '@/types/Brand';
import CampaignCircle from '@/components/brands/CampaignCircle.vue';
import Button from '@/components/common/buttons/Button.vue';

import { byName } from '@/utils';

const KEYBOARD_KEYS = {
  UP: 'ArrowUp',
  DOWN: 'ArrowDown',
  HOME: 'Home',
  END: 'End',
  ENTER: 'Enter',
  ESCAPE: 'Escape',
  SPACE: ' ',
};

@Component({
  name: 'CampaignMenu',
  components: {
    CampaignCircle,
    Button,
  },
})
export default class CampaignMenu extends Vue {
  @Prop({ required: true, default: () => [] }) value!: number[];
  @Prop({ required: false, default: false }) useLargeButton!: boolean;
  // Set openToLeft=true only if using the icon button and a design calls for it.
  @Prop({ required: false, default: false }) openToLeft!: boolean;
  @Getter selectedBrand;
  @Getter isAdmin;

  // This is used so element IDs don't conflict.
  componentIdSuffix = '🏴‍☠️'
    .repeat(7)
    .split('🏴‍☠️')
    .reduce((acc) => (acc += Math.floor(Math.random() * (9 - 1) + 1).toString()), '');

  get activatorButtonId(): string {
    return `activator-button-${this.componentIdSuffix}`;
  }
  get menuId(): string {
    return `menu-${this.componentIdSuffix}`;
  }

  get menuActivator(): HTMLElement {
    if (this.useLargeButton) {
      return (this.$refs.menuActivator as Vue).$el as HTMLElement;
    }
    return this.$refs.menuActivator as HTMLElement;
  }

  isMenuOpen = false;

  toggleMenu(preventActivatorFocus = false) {
    if (this.isMenuOpen) {
      // Close and return focus.
      this.isMenuOpen = false;
      this.removeCloseEvents();
      !preventActivatorFocus && this.menuActivator.focus();
    } else {
      this.isMenuOpen = true;
      this.addCloseEvents();
      // Open and set focus to first menu options.
      this.$nextTick().then(() => {
        if (this.$refs.menuItems) {
          const firstEl = this.$refs.menuItems[0] as HTMLElement;
          this.focusMenuItem(firstEl);
        }
      });
    }
  }

  addCloseEvents() {
    ['focusin', 'click'].forEach((event) => {
      window.addEventListener(event, this.closeEventHandler);
    });
  }

  removeCloseEvents() {
    ['focusin', 'click'].forEach((event) => {
      window.removeEventListener(event, this.closeEventHandler);
    });
  }

  closeEventHandler(e: Event) {
    const path = e.composedPath();
    const campaignMenu = this.$refs.campaignMenu as HTMLElement;
    if (!path.includes(campaignMenu)) {
      const preventRefocus = e.type === 'focusin';
      this.toggleMenu(preventRefocus);
    }
  }

  get activeCampaigns(): Campaign[] {
    const campaigns = this.sortAvailableCampaigns;
    return campaigns || [];
  }

  get sortAvailableCampaigns() {
    const campaigns = (this.selectedBrand as Brand)?.campaigns?.filter((campaign) => campaign.active);
    return campaigns.sort((a, b) => {
      const aTagged = this.selectedValues.includes(a.id);
      const bTagged = this.selectedValues.includes(b.id);
      if (aTagged === bTagged) {
        return byName(a, b);
      }
      if (aTagged) {
        return -1;
      }
      if (bTagged) {
        return 1;
      }

      return 0;
    });
  }

  onAddNewCampaignClick() {
    this.$emit('add-new-campaign');
  }

  get menuOptions(): Array<HTMLElement> {
    const menuItems = (this.$refs.menuItems as Array<HTMLElement>) || [];
    const addNewLink = [this.$refs.addNewLink as HTMLElement] || [];
    return [...menuItems, ...addNewLink];
  }

  selectedValues: number[] = [];

  toggleFocusedOption() {
    console.log('toggleFocusedOption');
    if (!this.focusedOption) return;
    if (this.focusedOption === this.$refs.addNewLink) {
      this.onAddNewCampaignClick();
      this.toggleMenu();
      return;
    }
    const checkbox = this.focusedOption.lastElementChild as HTMLInputElement;
    const value = parseInt(checkbox.value);
    const index = this.selectedValues.findIndex((v) => v === value);
    if (index === -1) {
      this.selectedValues.push(value);
    } else {
      this.selectedValues.splice(index, 1);
    }
    this.$emit('input', this.selectedValues);
  }

  focusedOption: HTMLElement | null = null;

  focusMenuItem(el: HTMLElement, scroll = false) {
    this.focusedOption = el;
    el.focus();
    scroll &&
      el.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'start',
      });
  }

  onMouseEnterMenuItem(e: Event) {
    const el = e.target as HTMLElement;
    this.focusMenuItem(el);
  }

  onKeyDownHandler(e: KeyboardEvent): void {
    const optionsList = this.menuOptions;
    const optionsLength = optionsList.length;
    const focusedOptionIndex = optionsList.findIndex((option) => option.dataset.focused === 'true');
    const nextOption = (): HTMLElement => {
      const nextIndex = focusedOptionIndex + 1 > optionsLength - 1 ? 0 : focusedOptionIndex + 1;
      return optionsList[nextIndex];
    };
    const previousOption = (): HTMLElement => {
      const previousIndex = focusedOptionIndex - 1 > -1 ? focusedOptionIndex - 1 : optionsLength - 1;
      return optionsList[previousIndex];
    };
    const firstOption = optionsList[0];
    const lastOption = optionsList[optionsLength - 1];
    const scrollToOption = true;

    const key = e.key;
    switch (key) {
      case KEYBOARD_KEYS.UP:
        e.preventDefault();
        optionsLength && this.focusMenuItem(previousOption(), scrollToOption);
        break;
      case KEYBOARD_KEYS.DOWN:
        e.preventDefault();
        optionsLength && this.focusMenuItem(nextOption(), scrollToOption);
        break;
      case KEYBOARD_KEYS.HOME:
        e.preventDefault();
        optionsLength && this.focusMenuItem(firstOption, scrollToOption);
        break;
      case KEYBOARD_KEYS.END:
        e.preventDefault();
        optionsLength && this.focusMenuItem(lastOption, scrollToOption);
        break;
      case KEYBOARD_KEYS.ESCAPE:
        if (this.isMenuOpen) {
          e.stopPropagation();
          this.toggleMenu();
        }
        break;
      case KEYBOARD_KEYS.ENTER:
      case KEYBOARD_KEYS.SPACE:
        e.preventDefault();
        this.toggleFocusedOption();
        break;
      default:
        break;
    }
  }

  @Watch('value', { immediate: true, deep: true })
  onValuePropChange() {
    this.selectedValues = this.value;
    this.$emit('tag-campaign', this.value);
  }
}
