<template>
  <blank-card id="twitter-accounts-card">
    <div class="card-header" slot="head">
      <div class="header-title-section">
        <img style="margin-right: 1rem" :src="require('@/assets/twitter.svg')" />
        <h5 class="twitter-accounts-title">TWITTER ACCOUNTS</h5>
      </div>
      <div class="follow-new-account">
        <img class="plus-icon-image" :src="require('@/assets/plus-icon.svg')" />
        <a class="follow-new-account-link">Follow new account</a>
      </div>
    </div>
    <div slot="list-wrapper">
      <div class="content-wrapper" slot="list" v-bind:key="account.key" v-for="account in accounts">
        <div class="content" slot="list-item">
          <div class="left-side">
            <img class="twitter-account-image" />
            <h6 class="accounts-text">{{ account.name }}</h6>
          </div>
          <div class="right-side">
            <p class="twitter-handle">{{ account.handle }}</p>
            <b-button class="remove-button"><img :src="require('@/assets/close-icon.svg')" /></b-button>
          </div>
        </div>
      </div>
    </div>
  </blank-card>
</template>

<script>
import BlankCard from '@/components/common/cards/BlankCard.vue';
export default {
  components: { BlankCard },
  name: 'twitter-accounts-card',
  data() {
    return {
      accounts: [
        { key: 1, name: 'Twitter account name', handle: '@twitterhandle' },
        { key: 2, name: 'Twitter account name', handle: '@twitterhandle' },
        { key: 3, name: 'Twitter account name', handle: '@twitterhandle' },
        { key: 4, name: 'Twitter account name', handle: '@twitterhandle' },
        { key: 5, name: 'Twitter account name', handle: '@twitterhandle' },
      ],
    };
  },
};
</script>

<style scoped lang="scss">
.template {
  font-family: Roboto;
  width: 39%;
  height: 25.7%;
}
#twitter-accounts-card {
  display: flex;
  flex-direction: column;
  padding: 0px;
  background: var(--neutral-white);
  box-shadow: 0px 1px 7px rgba(34, 32, 70, 0.2);
}
.card-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  background: var(--neutral-white);
  border-bottom: 1px solid var(--neutral-grey-3-borders);
  height: 66px;
  justify-content: space-between;
}
.header-title-section {
  display: flex;
}
.twitter-accounts-title {
  color: var(--neutral-grey-2-lighter-text);
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 8%;
  font-family: Roboto;
  position: relative;
  margin: 0 0 0 1rem;
}
.contact-link {
  margin-left: 4px;
  letter-spacing: 0.38px;
  font-weight: 500;
  font-family: Roboto;

  &:hover {
    cursor: pointer;
  }
}
.follow-new-account-link {
  color: var(--accent-links);
  font-weight: 500;
  letter-spacing: 0.384px;
  font-size: 16px;
}
.contact-bubble {
  margin-right: 4px;

  &:hover {
    cursor: pointer;
  }
}
.follow-new-account {
  &:hover {
    cursor: pointer;
  }
}
.content-wrapper {
  display: flex;
  justify-content: center;
}
.plus-icon-image {
  position: relative;
  right: 8px;
  bottom: 2px;
}
.content {
  display: flex;
  align-self: center;
  justify-content: space-between;
  width: 95%;
  height: 68px;
  align-items: center;
  background: var(--neutral-white);
  border-bottom: 1px solid var(--neutral-grey-3-borders);
}
.shortcut-number {
  font-weight: 700;
  font-family: Roboto;
  font-size: 20px;
  color: var(--neutral-grey-1-text);
  position: relative;
  top: 0.2rem;
}
.accounts-text {
  font-weight: 400;
  font-family: Roboto;
  font-size: 16px;
  color: var(--neutral-grey-1-text);
  position: relative;
  top: 0.2rem;
  margin-left: 16px;
}
.remove-button {
  box-shadow: 0px 1px 3px 0px var(--neutral-grey-1-text);
  width: 36px;
  height: 36px;
  background: var(--neutral-white);
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;

  &:hover {
    background: var(--neutral-white);
  }
}
.left-side {
  display: flex;
}
.right-side {
  display: flex;
}
.twitter-handle {
  font-weight: 400;
  font-size: 14px;
  color: var(--neutral-grey-2-lighter-text);
  position: relative;
  top: 8px;
  right: 16px;
}
</style>
