








import { Component, Vue, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import BaseLineChart from '@/audience/components/base-charts/BaseLineChart.vue';
import ChartTooltip from '@/audience/components/base-charts/ChartTooltip.vue';
import NoChartDataBanner from '@/audience/components/base-charts/NoChartDataBanner.vue';

import { getChartDataByPlatform, chartHasNonZeroData, makeDate } from '@/audience/core/utils/chart';
import { PlatformLabels, PlatformColors } from '@/audience/data/socialPlatformChartConfig';

import moment from 'moment-timezone';

import YouTubeIcon from '@/assets/audience/leaderboard-bar-chart/logo-youtube.svg';
import InstagramIcon from '@/assets/audience/leaderboard-bar-chart/logo-instagram.svg';
import FacebookIcon from '@/assets/audience/leaderboard-bar-chart/logo-facebook.svg';
import TwitterIcon from '@/assets/audience/leaderboard-bar-chart/logo-twitter.svg';
import TikTokIcon from '@/assets/audience/leaderboard-bar-chart/logo-tiktok.svg';
import LinkedInIcon from '@/assets/audience/leaderboard-bar-chart/logo-linkedin.svg';
import PinterestIcon from '@/assets/audience/leaderboard-bar-chart/logo-pinterest.svg';

import type { ChartData, ChartOptions, ChartDataset } from 'chart.js';
import type {
  SocialAnalytics,
  ActiveSocialAccount,
  DateRange,
  TooltipConfig,
  ChartDataByPlatform,
} from '@/types/audience';

const audienceModule = namespace('AudienceAnalyticsStore');

@Component({
  name: 'DashboardLineChart',
  components: {
    BaseLineChart,
    ChartTooltip,
    NoChartDataBanner,
  },
})
export default class DashboardLineChart extends Vue {
  @Prop({ type: String, required: true }) context!: 'audience' | 'impressions' | 'engagement';

  @audienceModule.Getter('extendedSocialAnalytics') socialAnalytics!: SocialAnalytics;
  @audienceModule.Getter activeSocialAccounts!: ActiveSocialAccount[];
  @audienceModule.Getter dateRange!: DateRange;
  @audienceModule.Getter timeZone!: string;

  get chartConfig() {
    return {
      chartId: 'dashboard-line-chart',
      datasetIdKey: 'dashboard-line-chart',
      plugins: [],
      cssClasses: '',
      styles: {},
    };
  }

  get chartOptions(): ChartOptions {
    const min = Math.min(...this.chartDatasets[0].data) - 1;
    return {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        y: {
          stacked: true,
          suggestedMin: min < 0 ? 0 : min,
          suggestedMax: Math.max(...this.chartDatasets[0].data) + 1,
          ticks: {
            stepSize: 1,
          },
        },
      },
      plugins: {
        filler: {
          propagate: false,
        },
        legend: {
          labels: {
            boxWidth: 5,
            usePointStyle: true,
            pointStyle: 'circle',
          },
          title: {
            font: {
              family: 'Roboto',
              size: 12,
              weight: '400',
            },
            color: '#222046',
          },
          onClick: (evt) => {
            evt.native?.stopPropagation();
          },
        },
        tooltip: {
          enabled: false,
          external: async (context) => {
            const tooltipEl = (this.$refs.tooltip as Vue).$el;

            const tooltipModel = context.tooltip;

            const style: Partial<CSSStyleDeclaration> = {};

            // Hide if no tooltip
            if (tooltipModel.opacity === 0) {
              style.opacity = '0';
              style.display = 'none';
              this.tooltipConfig.style = style;
              return;
            }

            // Set data
            const chartData = context.chart.data as ChartData<'line'>;
            const {
              dataIndex,
              datasetIndex,
              label: xAxisLabel,
              dataset: { backgroundColor, borderColor, label: platformLabel },
            } = tooltipModel.dataPoints[0];

            const headerLabel = this.chartDates[dataIndex].format('MMM DD[,] YYYY').toUpperCase();

            const primaryValueLabel = this.context.charAt(0).toUpperCase() + this.context.substring(1);
            const primaryValueText = chartData.datasets[datasetIndex].data[dataIndex]?.toLocaleString() as string;
            const percentOfTotalText = (
              ((chartData.datasets[datasetIndex].data[dataIndex] as number) / this.chartTotalsByDate[xAxisLabel]) *
              100
            ).toFixed(0);

            // Display, position, other styles
            this.tooltipConfig.style.display = 'block';
            await this.$nextTick();
            const { width, height } = tooltipEl.getBoundingClientRect();

            style.opacity = '1';
            style.position = 'absolute';
            const leftPos = window.pageXOffset + tooltipModel.caretX - width / 2;
            const topPos = window.pageYOffset + tooltipModel.caretY - height;
            style.left = leftPos + 'px';
            style.top = topPos + 'px';
            style.pointerEvents = 'none';

            const tooltipConfig = {
              header: {
                label: headerLabel,
                usePlatformIcon: !!platformLabel,
                iconSrc: this.getIconSrcByPlatform(platformLabel ?? ''),
              },
              topRow: {
                primary: {
                  label: primaryValueLabel,
                  value: primaryValueText,
                  backgroundColor: backgroundColor as string,
                  borderColor: borderColor as string,
                },
                secondary: {
                  label: '% of total',
                  value: percentOfTotalText === 'NaN' ? '0' : percentOfTotalText,
                },
              },
              style,
            };
            this.tooltipConfig = tooltipConfig;

            // Figure out if tooltip is overflowing to right of viewport
            // and push left if so.
            await this.$nextTick();
            const maxRightOffset = document.body.clientWidth - 16; // 16px of padding
            const { right: newRight } = tooltipEl.getBoundingClientRect();
            const diff = maxRightOffset - newRight;
            const shiftLeftPx = diff < 0 ? Math.abs(diff) : 0;
            style.left = leftPos - shiftLeftPx + 'px';
          },
        },
      },
      elements: {
        line: {
          tension: 0.000001,
        },
        point: {
          hitRadius: 10,
          pointStyle: 'line',
        },
      },
    };
  }

  get platformsData(): Record<string, ChartDataByPlatform> {
    const activePlatforms = this.activeSocialAccounts.map(
      ({ platform }) => PlatformLabels[platform.toUpperCase()]
    ) as string[];
    const platformsData = {};
    activePlatforms.forEach((platformLabel) => {
      platformsData[platformLabel] = getChartDataByPlatform(platformLabel, this.socialAnalytics);
    });
    return platformsData;
  }

  get chartDates() {
    return Object.keys(this.socialAnalytics).map((date) => moment.tz(makeDate(date), this.timeZone));
  }

  get chartLabels() {
    return this.chartDates.map((date) => date.format('M[/]DD'));
  }

  get chartDatasets() {
    switch (this.context) {
      case 'audience':
        return Object.keys(this.platformsData).map((platformLabel) => {
          return {
            label: platformLabel,
            backgroundColor: PlatformColors[platformLabel.toUpperCase()],
            borderColor: '#fff',
            data: Object.values(this.socialAnalytics).map(
              (analyticsForDate) => analyticsForDate[platformLabel]?.followers ?? NaN
            ),
            fill: 'origin',
            spanGaps: true,
          };
        });
      case 'impressions':
        return Object.keys(this.platformsData).map((platformLabel) => {
          return {
            label: platformLabel,
            backgroundColor: PlatformColors[platformLabel.toUpperCase()],
            borderColor: '#fff',
            data: Object.values(this.socialAnalytics).map(
              (analyticsForDate) => analyticsForDate[platformLabel]?.totalImpressions ?? NaN
            ),
            fill: 'origin',
            spanGaps: true,
          };
        });
      case 'engagement':
        return Object.keys(this.platformsData).map((platformLabel) => {
          return {
            label: platformLabel,
            backgroundColor: PlatformColors[platformLabel.toUpperCase()],
            borderColor: '#fff',
            data: Object.values(this.socialAnalytics).map(
              (analyticsForDate) => analyticsForDate[platformLabel]?.totalEngagement ?? NaN
            ),
            fill: 'origin',
            spanGaps: true,
          };
        });
      default:
        return Object.keys(this.platformsData).map(() => {
          return {
            label: 'Unknown',
            backgroundColor: '#fff',
            borderColor: '#fff',
            data: [],
            fill: 'origin',
            spanGaps: true,
          };
        });
    }
  }

  get chartHasData() {
    return chartHasNonZeroData(this.chartDatasets as ChartDataset[]);
  }

  get chartData(): ChartData {
    return {
      labels: this.chartLabels,
      datasets: this.chartDatasets,
    };
  }

  get chartTotalsByDate() {
    return this.chartLabels.reduce((acc, dateLabel, labelIndex) => {
      acc[dateLabel] = 0;
      this.chartDatasets.forEach(({ data }) => {
        acc[dateLabel] += data[labelIndex] || 0;
      });
      return acc;
    }, {});
  }

  tooltipConfig: TooltipConfig = {
    topRow: {
      primary: {
        label: '',
        value: '',
      },
    },
    style: {
      display: 'none',
      opacity: '0',
    },
  };

  getIconSrcByPlatform(platformLabel: string) {
    switch (platformLabel) {
      case PlatformLabels.YOUTUBE:
        return YouTubeIcon;
      case PlatformLabels.INSTAGRAM:
        return InstagramIcon;
      case PlatformLabels.FACEBOOK:
        return FacebookIcon;
      case PlatformLabels.TWITTER:
        return TwitterIcon;
      case PlatformLabels.TIKTOK:
        return TikTokIcon;
      case PlatformLabels.LINKEDIN:
        return LinkedInIcon;
      case PlatformLabels.PINTEREST:
        return PinterestIcon;
      default:
        return '';
    }
  }
}
