









import { Component, Vue } from 'vue-property-decorator';
import WelcomeBanner from '@/createandpublish/WelcomeBanner.vue';
import CreateSection from '@/createandpublish/CreateSection.vue';
import PublishingPlanner from '@/createandpublish/PublishingPlanner.vue';
import ContentLibrary from '@/createandpublish/ContentLibrary.vue';

@Component({
  components: {
    WelcomeBanner,
    CreateSection,
    PublishingPlanner,
    ContentLibrary,
  },
})
export default class CreateDashboard extends Vue {}
