<template>
  <fm-modal :visible.prop="true" class="modal-wrapper">
    <header slot="controlRow" class="control-row">
      <close-button class="close-button" @click="close" />
      <fm-tabs v-if="allowLicensedImages" class="tab-container">
        <fm-tab-item :selected="isStandardTabSelected" @click.prevent="changeTab('standard')">
          Choose from library
        </fm-tab-item>
        <fm-tab-item :selected="isLicensedTabSelected" @click.prevent="changeTab('licensed')">
          Select a licensed image
        </fm-tab-item>
      </fm-tabs>
    </header>

    <div slot="content" class="content">
      <standard-image-selector-panel
        v-if="isStandardTabSelected"
        @image:selected="handleStandardImageSelected"
        :minWidth="minWidth"
        :minHeight="minHeight"
        :maxWidth="maxWidth"
        :maxHeight="maxHeight"
        :use-min-max-recommended-size="!!(minWidth && minHeight && maxWidth && maxHeight)"
      />

      <licensed-image-selector-panel v-if="isLicensedTabSelected" @image:selected="handleLicensedImageSelected" />
    </div>
  </fm-modal>
</template>

<script>
import CloseButton from '@/createandpublish/components/CloseButton';
import StandardImageSelectorPanel from '@/createandpublish/components/StandardImageSelectorPanel';
import LicensedImageSelectorPanel from '@/createandpublish/components/LicensedImageSelectorPanel';

export default {
  name: 'combo-image-selector-modal',

  props: {
    allowLicensedImages: { type: Boolean, default: false },
    minWidth: Number,
    maxWidth: Number,
    minHeight: Number,
    maxHeight: Number,
  },

  data() {
    return {
      tabSelected: 'standard',
    };
  },

  computed: {
    isStandardTabSelected() {
      return this.tabSelected === 'standard';
    },

    isLicensedTabSelected() {
      return this.tabSelected === 'licensed';
    },
  },

  methods: {
    close() {
      this.$emit('close');
    },

    changeTab(tabName) {
      this.tabSelected = tabName;
    },

    handleStandardImageSelected(url) {
      this.$emit('standard-image-selected', url);
    },

    handleLicensedImageSelected(data) {
      this.$emit('licensed-image-selected', data);
    },
  },

  components: {
    StandardImageSelectorPanel,
    LicensedImageSelectorPanel,
    CloseButton,
  },
};
</script>

<style lang="scss" scoped>
.control-row {
  position: relative;

  .tab-container {
    margin: 0 auto 24px auto;
    width: fit-content;
  }

  .close-button {
    position: absolute;
    right: 0;
    top: 0;
  }
}

.modal-wrapper::part(modal) {
  max-width: none;
}

.content {
  width: 80vw;
  height: 70vh;
}
</style>
