<template>
  <div class="card">
    <div id="header">
      <slot class="slot-header" name="head"></slot>
    </div>
    <div>
      <slot name="list-wrapper">
        <slot name="list">
          <slot name="list-item"></slot>
        </slot>
      </slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'blank-card',
};
</script>

<style>
.card {
  width: 100%;
  background: var(--neutral-white);
  box-shadow: 0px 1px 7px 0px var(--neutral-grey-3-borders);
}
#header {
  width: 100%;
}
.slot-header {
  width: 100%;
}
</style>
