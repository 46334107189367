<template>
  <div class="form">
    <AlertDisplay :alertMessage="alertMessage" class="alertMessage" v-if="displayAlert" />
    <div class="form-content">
      <div class="form-title">
        <h2>{{ formTitleText }}</h2>
      </div>
      <!-- Subtitle -->
      <h3 class="form-subtitle" v-if="!trial">Your information</h3>
      <form v-on:submit.prevent="onSubmit" novalidate>
        <Input
          :value="form.emailAddress"
          :inputHandler="handleInputChange"
          maxLength="100"
          class="form-input"
          label="Email address"
          name="emailAddress"
          type="email"
          :error="
            $v.form.emailAddress.$error
              ? !$v.form.emailAddress.email
                ? errorMessages.invalidEmail
                : 'Email address' + errorMessages.required
              : ''
          "
        />
        <Button :loading="formIsLoading" buttonType="primary" class="form-submit" type="submit"> Next </Button>
      </form>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { required } from 'vuelidate/lib/validators';
import { validateEmail as $valEmail } from '@/plugins/Validations';
import ChargifyService from '@/services/ChargifyService';
import AlertDisplay from '@/components/common/AlertDisplay.vue';
import Button from '@/components/common/buttons/Button.vue';
import Input from '@/components/common/form/Input.vue';
import errorMessages from '@/utils/errorMessages.json';

export default Vue.extend({
  name: 'get-started',

  components: {
    AlertDisplay,
    Button,
    Input,
  },

  props: {
    data: {
      type: Object,
      default() {
        return {
          emailAddress: '',
        };
      },
    },
    trial: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      displayAlert: false,
      errorMessages, // Input error messages
      form: {
        emailAddress: this.data.emailAddress || '',
      },
      formIsLoading: false, // is form submitting?
    };
  },

  computed: {
    // Used in 2 components, can be pulled out if we reach 3
    formTitleText() {
      const baseText = 'Let’s set up your ';
      const flowType = this.trial ? 'free trial' : 'subscription'; // Change if more than 2
      return baseText + flowType;
    },
  },

  validations: {
    form: {
      emailAddress: { required, $valEmail },
    },
  },

  methods: {
    handleInputChange(e) {
      this.form[e.target.name] = e.target.value;
    },
    async onSubmit() {
      // Touch all fields
      this.$v.form.$touch();

      // if form's validated
      if (!this.$v.form.$invalid) {
        // check if email exists and if not -- go to the next step
        this.formIsLoading = true;

        const { emailAddress } = this.form;

        const emailExists = await this.checkEmail(emailAddress);

        if (typeof emailExists !== 'boolean' || emailExists?.errors) {
          this.alertMessage = {
            type: 'critical',
            header: `We couldn't set up your subscription`,
            message: 'There was a server error while processing your request. Please try again in a few minutes.',
          };
          this.displayAlert = true;
          return;
        } else if (emailExists) {
          //redirect
          await this.$router.push({
            name: 'Subscription Login',
            params: {
              email: emailAddress,
              state: 'emailExists',
            },
          });
        } else {
          this.$emit('success', { emailAddress });
        }
        this.formIsLoading = false; // unnecessary but adding it as fallback in case the logic above changes
      }
    },
    async checkEmail(emailAddress) {
      const service = new ChargifyService();
      return await service.getCustomerByEmail(emailAddress);
    },
  },
});
</script>

<style lang="scss" scoped>
.form {
  // min-width: 536px;
  flex: 1;
  margin: auto;
  max-width: 592px;
  width: 100%;
}

.form-content {
  padding: 48px 24px;
}

.form-title {
  margin-bottom: 32px;

  h2 {
    font-size: 36px;
    line-height: 44px;
    margin-bottom: 0;
  }
}

.form-subtitle {
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 24px;
}

.form-input {
  margin-bottom: 32px;
}

.form-submit {
  @media (max-width: 768px) {
    width: 100%;
  }
}
</style>
