







import { Component, Prop, Vue } from 'vue-property-decorator';

import AssistiveLink from './AssistiveLink.vue';

@Component({
  components: {
    AssistiveLink,
  },
})
export default class Label extends Vue {
  @Prop({ default: '', required: true }) label!: string;
  @Prop({ default: '', required: false }) link?: {
    label: string;
    onClick: () => undefined;
  };
}
