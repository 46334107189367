




























































































import moment from 'moment';
import { Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { Getter, namespace } from 'vuex-class';
import { Campaign } from '@/types/Campaign';
import { ImageAsset } from '@/types/createandpublish/mediaLibraries/ImageLibrary';
import ImageCard from '@/createandpublish/components/imageLibrary/ImageCard.vue';
import Pagination from '@/createandpublish/components/common/Pagination.vue';
import EditImageDrawer from '@/createandpublish/components/imageLibrary/EditImageDrawer.vue';
import UploadMediaMixin from '@/createandpublish/mixins/UploadMediaMixin.vue';

const imageLibraryModule = namespace('CreateAndPublishStore/imageLibrary');

Component.registerHooks(['created', 'beforeRouteLeave']);

@Component({
  name: 'ImageLibrary',
  components: {
    ImageCard,
    Pagination,
    EditImageDrawer,
  },
})
export default class ImageLibrary extends mixins(UploadMediaMixin) {
  @Getter selectedBrand;

  @imageLibraryModule.Getter readonly imageAssets!: ImageAsset[];
  @imageLibraryModule.Action getImageAssets;
  @imageLibraryModule.Action updateImageAsset;

  isLoading = false;
  form = {
    searchTerm: '',
    campaignFilterValue: '',
  };
  lastUpdated: { timerId: number | null; date: Date | null; text: string } = {
    timerId: null, // ID returned by setInterval
    date: null, // Date of most recent update.
    text: '',
  };
  imageDrawer: { imageData: ImageAsset | null; file: File | null } = {
    imageData: null,
    file: null,
  };

  get activeCampaigns(): Campaign[] {
    return this.selectedBrand?.campaigns?.filter((campaign) => campaign.active) || [];
  }

  get campaignOptions() {
    return this.activeCampaigns.map((campaign) => ({ label: campaign.name, value: String(campaign.id) }));
  }

  onAddNewCampaign() {
    alert("Oh noes. You can't add campaigns.");
  }

  get appliedFilterPillData() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const filterPills: any[] = [];
    const isAppliedFilter = (value) => {
      if ((!Array.isArray(value) && (value === 0 || value)) || (Array.isArray(value) && value.length > 0)) {
        return true;
      }
      return false;
    };

    for (const [key, value] of Object.entries(this.form)) {
      if (isAppliedFilter(value)) {
        if (key === 'searchTerm') {
          filterPills.push({ title: 'Search', key, value });
        }
        if (key === 'campaignFilterValue') {
          filterPills.push({ title: 'Campaign', key, value });
        }
      }
    }
    return filterPills;
  }

  get imageItems() {
    return this.imageAssets;
  }

  get filteredImageItems() {
    let results = this.imageItems;
    const searchTerm = this.form.searchTerm.trim().toLowerCase();
    if (this.form.campaignFilterValue) {
      results = results.filter((item) => item.campaigns.includes(parseInt(this.form.campaignFilterValue)));
    }
    return results.filter(
      (item) =>
        item.title.toLowerCase().includes(searchTerm) ||
        item.description.toLowerCase().includes(searchTerm) ||
        item.filename.toLowerCase().includes(searchTerm)
    );
  }

  clearFilter(key: string) {
    this.form[key] = '';
  }

  clearAllFilters() {
    this.form = {
      searchTerm: '',
      campaignFilterValue: '',
    };
  }

  onUploadNewClicked() {
    const input = this.$refs.fileInput as HTMLButtonElement | undefined;
    input && input.click();
  }

  onFileInputChange(event: Event) {
    const target = event.target as HTMLInputElement;
    const file = (target.files as FileList).item(0);
    if (!file) return;
    this.imageDrawer.file = file;
    this.onOpenImageDrawer();
  }

  submitImageUpload({ title, description, campaignIds }) {
    const uploadType = 'image';
    const file = this.imageDrawer.file;

    this.initMediaUploadService(uploadType, this.onUploadComplete);

    const metaData = {
      title,
      description,
      campaignIds,
    };

    this.startUploadWithFile(file, metaData);
  }

  onUploadComplete() {
    this.fetchImageData();
  }

  onOpenImageDrawer(imageData: ImageAsset | null = null) {
    this.imageDrawer.imageData = imageData;
    this.$root.$emit('bv::toggle::collapse', 'edit-image-drawer');
  }

  onCloseImageDrawer() {
    this.imageDrawer.imageData = null;
    this.imageDrawer.file = null;
  }

  onImageDrawerSave({ createNew, id, title, description, campaignIds }) {
    if (createNew) {
      this.submitImageUpload({ title, description, campaignIds });
    } else {
      this.updateImageAsset({ id, title, description, campaigns: campaignIds });
    }
  }

  onUpdateImageCampaigns({ id, campaignIds }) {
    this.updateImageAsset({ id, campaigns: campaignIds });
  }

  onSocialShare() {
    alert('Social sharing is not implemented.');
  }

  setLastUpdatedTimer(date: Date = new Date()) {
    if (this.lastUpdated.timerId) {
      clearInterval(this.lastUpdated.timerId);
    }
    const getUpdatedText = (date: Date): string => {
      return `Last updated ${moment(date).fromNow()}`;
    };
    this.$set(this.lastUpdated, 'date', date);
    this.$set(this.lastUpdated, 'text', getUpdatedText(date));
    this.lastUpdated.timerId = window.setInterval(() => {
      this.$set(this.lastUpdated, 'text', getUpdatedText(date));
    }, 10000);
  }

  get pageSizeOptions() {
    return [
      { label: '10', value: '10' },
      { label: '25', value: '25' },
      { label: '50', value: '50' },
    ];
  }

  async fetchImageData() {
    this.isLoading = true;
    await this.getImageAssets();
    this.setLastUpdatedTimer();
    this.isLoading = false;
  }

  created() {
    this.fetchImageData();
  }

  beforeRouteLeave(_to, _from, next) {
    const { timerId } = this.lastUpdated;
    if (timerId) {
      clearInterval(timerId);
    }
    next();
  }
}
