

























































import { Component, Vue, Prop } from 'vue-property-decorator';
import NoProfileConnected from '@/audience/components/NoProfileConnected.vue';
import NewUserNoPlatformsPlaceholderMobile from '@/audience/components/dashboard/NewUserNoPlatformsPlaceholderMobile.vue';
import NewUserNoPlatformsPlaceholderDesktop from '@/audience/components/dashboard/NewUserNoPlatformsPlaceholderDesktop.vue';
import ConnectPlatformsInfoBanner from '@/audience/components/dashboard/ConnectPlatformsInfoBanner.vue';
@Component({
  name: 'PlatformViewLayout',
  components: {
    NoProfileConnected,
    NewUserNoPlatformsPlaceholderMobile,
    NewUserNoPlatformsPlaceholderDesktop,
    ConnectPlatformsInfoBanner,
  },
})
export default class PlatformViewLayout extends Vue {
  @Prop({ type: String, required: true }) platformLabel!: string;
  @Prop({ type: Boolean, required: false, default: false }) isDashboard!: boolean;
  // isPlatformConnected is used only by individual platform views (e.g. Twitter, Facebook)
  @Prop({ type: Boolean, required: false, default: false }) isPlatformConnected!: boolean;
  // userHasNoConnectedPlatforms is used only by Dashboard view
  @Prop({ type: Boolean, required: false, default: false }) userHasNoConnectedPlatforms!: boolean;
  @Prop({ type: Object, required: true }) leftContent!: LeftContent;
  @Prop({ type: Object, required: true }) rightContent!: RightContent;

  onConnectPlatform() {
    this.$emit('on-connect-platforms-click');
  }
}

interface LeftContent {
  heading: string;
  subHeading?: string;
}

interface RightContent {
  heading: string;
}
