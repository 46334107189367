















































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { eventBus, busEvents, eventSources } from '@/createandpublish/core/eventBus/audioWizardEventBus';
import Hls from 'hls.js';
import moment from 'moment';
import 'moment-duration-format';

interface DurationWithFormat extends moment.Duration {
  format: (fmt: string, options?: Record<string, unknown>) => string;
}

Component.registerHooks(['mounted']);

@Component({
  name: 'AudioPlayerBar',
  filters: {
    formatDuration(secs) {
      return (moment.duration(secs, 'seconds') as DurationWithFormat).format('h:m:ss', {
        stopTrim: 'm',
        forceLength: true,
      });
    },
  },
})
export default class AudioPlayerBar extends Vue {
  @Prop({ type: Array, default: () => [] }) readonly audioSrcList!: Record<string, string>[];

  isPlaying = false;
  nowPlayingIndex = 0;
  canPlay = false;
  seekPos = 0;
  currentTime = 0;
  duration = 0;
  audioElement: HTMLMediaElement | null = null;
  hlsPlayer: Hls | null = null;

  // Next two properties are only used by the onPreviousClick method.
  clickTimer: number | null = null;
  clicks = 0;

  @Watch('isPlaying')
  onIsPlayingUpdate() {
    if (this.isPlaying) {
      eventBus.$emit(busEvents.AUDIO_PLAY_EVENT, eventSources.AUDIO_PLAYER_BAR);
    }
  }

  get playButtonIcon() {
    return this.isPlaying ? 'pause_circle' : 'play_circle';
  }

  get playButtonAriaLabel() {
    return this.isPlaying
      ? `Pause ${this.audioSrcList[this.nowPlayingIndex].title}`
      : `Play ${this.audioSrcList[this.nowPlayingIndex].title}`;
  }

  get numItemsSelected() {
    const size = this.audioSrcList.length;
    return `${size} item${size === 1 ? '' : 's'} selected`;
  }

  get rangeValueText() {
    return (
      this.$options.filters?.formatDuration(this.currentTime) +
      '/' +
      this.$options.filters?.formatDuration(this.duration)
    );
  }

  onPlayButtonClick() {
    const player = this.audioElement;
    this.isPlaying ? player?.pause() : player?.play();
  }

  goBack10() {
    if (this.audioElement) this.audioElement.currentTime = this.currentTime - 10;
  }

  goForward10() {
    if (this.audioElement) this.audioElement.currentTime = this.currentTime + 10;
  }

  onPreviousClick() {
    const forceSingleClick = this.nowPlayingIndex === 0;
    this.clicks = forceSingleClick ? 1 : this.clicks + 1;

    switch (this.clicks) {
      case 1:
        // Single click case
        if (forceSingleClick) {
          // Set the currentTime immediately.
          if (this.audioElement) this.audioElement.currentTime = 0;
          break;
        }
        // Delay in case of double click.
        this.clickTimer = window.setTimeout(() => {
          if (this.audioElement) this.audioElement.currentTime = 0;
          this.clicks = 0;
        }, 500);
        break;
      case 2:
        // Double click
        clearTimeout(this.clickTimer as number);
        this.clicks = 0;
        if (this.audioElement && this.nowPlayingIndex > 0) {
          const autoPlay = this.isPlaying;
          this.setupHLSAndPlay(this.nowPlayingIndex - 1, autoPlay);
        }
        break;
      default:
      // Do nothing. Should be unreachable.
    }
  }

  playNext($ev: Event) {
    let autoPlay = this.isPlaying; // default setting is to keep it whatever it was.
    if ($ev.type === 'ended') {
      // In the case that a segment ends, playback is automatically stopped,
      // so we want to force it to start again for the next clip.
      autoPlay = true;
    }
    const npIndex = this.nowPlayingIndex;
    const len = this.audioSrcList.length;
    if (this.audioElement && npIndex < len - 1) {
      this.setupHLSAndPlay(npIndex + 1, autoPlay);
    }
  }

  onSeek() {
    if (this.audioElement) this.audioElement.currentTime = (this.seekPos / 100) * this.duration;
  }

  onTimeUpdate($ev) {
    this.seekPos = ($ev.target.currentTime / this.duration) * 100;
    this.currentTime = $ev.target.currentTime;
  }

  onCanPlay($ev) {
    this.duration = $ev.target.duration;
    this.canPlay = true;
  }

  setupHLSAndPlay(nowPlayingIndex: number, autoPlay = false) {
    this.canPlay = false;
    this.audioElement = this.$refs.player as HTMLMediaElement;
    if (Hls.isSupported()) {
      if (!this.hlsPlayer) {
        this.hlsPlayer = new Hls();
      }
      if (this.hlsPlayer?.media) {
        // Detach media from HLS if it's currently bound to any.
        this.hlsPlayer.detachMedia();
      }
      // bind them together
      this.hlsPlayer.attachMedia(this.audioElement);
      // MEDIA_ATTACHED event is fired by hls object once MediaSource is ready
      this.hlsPlayer.on(Hls.Events.MEDIA_ATTACHED, () => {
        if (this.audioSrcList.length) {
          this.hlsPlayer?.loadSource(this.audioSrcList[nowPlayingIndex].src);
          this.nowPlayingIndex = nowPlayingIndex;
          autoPlay && this.audioElement?.play();
        }
      });
    }
  }

  setupEventBusHandler() {
    eventBus.$on(busEvents.AUDIO_PLAY_EVENT, (source) => {
      if (source !== eventSources.AUDIO_PLAYER_BAR) {
        this.audioElement?.pause();
      }
    });
  }

  mounted() {
    this.setupEventBusHandler();
    this.setupHLSAndPlay(0);
  }
}
