






import Vue from 'vue';
export default Vue.extend({
  name: 'form-section-text',
  props: {
    label: String,
  },
});
