/**
 * @overview For use with the audioWizard related views and their children, found in
 * @/src/createandpublish/views/audio-library
 * @author Brian Pennington
 */

import Vue from 'vue';

/**
 * Event bus for the audio wizard.
 *
 * Usage:
 * @property {Function} $on register event listener; takes event type, handler params.
 * @property {Function} $off unregister event listener; takes event type, handler params.
 * @property {Function} $emit emit an event; takes event type, payload object params.
 */
export const eventBus = new Vue();

/**
 * @enum busEvents is an enum for all event types that may be published to this event bus.
 *
 * @property {String} SET_STACK_EVENTS handler should receive a ({ source, events }) parameter.
 * @property {String} ADD_STACK_EVENT handler should receive a ({ source, event }) parameter.
 * @property {String} REMOVE_STACK_EVENT handler should receive a ({ source, event }) parameter.
 *
 * @property {String} AUDIO_PLAY_EVENT handler should receive a (source) parameter.
 *
 * @property {string} EXPORT_EVENT handler should receive no params
 * @property {string} TW_PROCESSING_COMPLETE handler should receive no params
 */
export const busEvents = {
  // Stack events
  SET_STACK_EVENTS: 'setStackEvents',
  ADD_STACK_EVENT: 'addStackEvent',
  REMOVE_STACK_EVENT: 'removeStackEvent',
  // Player events
  AUDIO_PLAY_EVENT: 'audioPlayEvent',
  // TwistedWave events
  EXPORT_EVENT: 'exportEvent',
  TW_PROCESSING_COMPLETE: 'twProcessingComplete',
};

/**
 * @enum eventSources is an enum that refers to components an event can be published from.
 *
 * @property {String} LIBRARY_TABLE refers to Table.vue in AudioLibrary.vue
 * @property {String} AUDIO_LIBRARY refers to AudioLibrary.vue
 * @property {String} AUDIO_PLAYER_BAR refers to AudioPlayerBar.vue in AudioWizardRoute.vue
 * @property {String} AUDIO_STACK refers to AudioStack.vue in AudioWizardLibrary.vue
 * @property {String} AUDIO_WIZARD_ROUTE refers to AudioWizardRoute.vue
 * @property {String} AUDIO_WIZARD_EDITOR refers to AudioWizardEditor.vue
 */
export const eventSources = {
  LIBRARY_TABLE: 'libraryTable',
  AUDIO_LIBRARY: 'audioLibrary',
  AUDIO_PLAYER_BAR: 'audioPlayerBar',
  AUDIO_STACK: 'audioStack',
  AUDIO_WIZARD_ROUTE: 'audioWizardRoute',
  AUDIO_WIZARD_EDITOR: 'audioWizardEditor',
};
