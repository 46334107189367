<template>
  <fm-drawer :visible.prop="glossaryIsOpen">
    <div slot="content">
      <div class="glossary-wrapper">
        <div class="glossary-header">
          <div>
            <fm-heading><h3>Analytics glossary</h3></fm-heading>
          </div>
          <i class="material-icons close-glossary" @click="closeGlossary">close</i>
        </div>
        <div class="glossary-body">
          <glossary-section>
            <template #term>Country</template>
            <template #definition>
              <span>This represents the listener's country of origin.</span>
            </template>
          </glossary-section>
          <glossary-section>
            <template #term>Downloads</template>
            <template #definition>
              <div>
                <p>
                  This represents the total number of downloads for an episode within the selected time period (based on
                  UTC).
                </p>
                <p>
                  A download is counted when a listener has consumed <b>60 seconds or more</b> of an episode (by
                  streaming or downloading to a device) within a 24-hour period.
                </p>
                <p>
                  Multiple playbacks of the same episode by a unique listener within a 24 hour period will be counted as
                  one download.
                </p>
              </div>
            </template>
          </glossary-section>
          <glossary-section>
            <template #term>Listen through rate (LTR)</template>
            <template #definition>
              <div>
                <p>
                  Listen Through Rate is the <b>average percentage</b> of a show and/or episodes' total duration that
                  listeners have consumed during the selected time period (based on UTC).
                </p>
                <p><b>Note: </b> This is an experimental metric and does not impact IAB guidelines.</p>
              </div>
            </template>
          </glossary-section>
          <glossary-section>
            <template #term>Percentage of total (% Total)</template>
            <template #definition>
              <div>
                <p>
                  This represents a <b>percentage of the total number of downloads</b> within your selected data set.
                </p>
                <p>
                  For example, if you've filtered your data set to three shows and they all have the same number of
                  downloads during the selected date range, each show would display 33.3% of total.
                </p>
              </div>
            </template>
          </glossary-section>
          <glossary-section>
            <template #term>Reach</template>
            <template #definition>
              <span>We don't have a definition of this in the mocks. Need to get it though. </span>
            </template>
          </glossary-section>
          <glossary-section>
            <template #term>Unique users</template>
            <template #definition>
              <span
                >This represents the <b>number of unique listeners</b> determined by a combination of User Agent (e.g.
                browser or app used to play content) and IP Address within a 24-hour period (based on UTC).</span
              >
            </template>
          </glossary-section>
        </div>
        <div class="glossary-footer">
          <fm-button-secondary @click="closeGlossary">Close</fm-button-secondary>
        </div>
      </div>
    </div>
  </fm-drawer>
</template>

<script>
import GlossarySection from '@/createandpublish/components/analytics/podcasts/GlossarySection';

export default {
  components: {
    GlossarySection,
  },
  data: () => ({
    glossaryIsOpen: false,
  }),
  methods: {
    toggleGlossary() {
      this.glossaryIsOpen = !this.glossaryIsOpen;
    },
    closeGlossary() {
      this.glossaryIsOpen = false;
    },
  },
};
</script>

<style scoped lang="scss">
.glossary-wrapper {
  .glossary-header {
    padding: 2em;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid var(--neutral-grey-3-borders);
    align-items: center;

    .close-glossary {
      cursor: pointer;
      font-size: 36px;
    }
  }

  .glossary-footer {
    padding: 1em;
    display: flex;
    align-content: stretch;

    background-color: var(--neutral-grey-4-light-bg);
  }
}
</style>
