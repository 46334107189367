



















import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({
  name: 'AnalyticsIcon',
})
export default class AnalyticsIcon extends Vue {
  @Prop({ type: String, required: false, default: '#222046' }) fill!: string;
  @Prop({ type: Number, required: false, default: 20 }) height!: number;
  @Prop({ type: Number, required: false, default: 21 }) width!: number;
  @Prop({ type: String, required: false, default: 'Analytics' }) title!: string;
}
