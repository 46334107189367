<template>
  <div class="glossary-section">
    <div class="term" @click="toggleExpansion">
      <fm-body
        ><p>
          <b><slot name="term"></slot></b></p
      ></fm-body>
      <i class="material-icons">{{ isExpanded ? 'expand_less' : 'expand_more' }}</i>
    </div>
    <div class="definition" v-if="isExpanded">
      <slot name="definition"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'GlossarySection',
  data: () => ({
    isExpanded: false,
  }),
  methods: {
    toggleExpansion() {
      this.isExpanded = !this.isExpanded;
    },
  },
};
</script>

<style scoped lang="scss">
.glossary-section {
  margin: 1.5em;
  border-bottom: 1px solid var(--neutral-grey-3-borders);
  padding: 1em 0;

  .term {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    & > * {
      cursor: pointer;
    }
  }

  .definition {
    padding: 1em 0;
  }
}
</style>
