/* eslint-disable @typescript-eslint/no-explicit-any */
import { isValidErrorFormat } from '@/utils/typeGuards';
import type { Dispatch, Commit } from 'vuex';
import type { HttpResponse } from 'vue-resource/types/vue_resource';

export const handleError = ({ error, errmsg, commit, callback }: HandleErrorParams) => {
  if (error instanceof Error) {
    // JavaScript error
    throw error;
  }

  // redirect to login on unauthorized errors
  if (error?.status === 401) {
    commit('CLEAR_STORE', undefined, { root: true });
    if (
      !['/login'].includes(window.location.pathname) &&
      !['/checkout/login'].includes(window.location.pathname) &&
      !['/audience/login'].includes(window.location.pathname)
    ) {
      window.location.href = '/login';
    }
    return;
  }

  let message = '';

  if (error?.data?.errors && error?.status) {
    // Check for expected error type first
    const errors: unknown = error.data.errors;
    const isExpectedType = isValidErrorFormat(errors);
    message = isExpectedType ? errors[0] : 'Unknown error';
  } else if (error?.data && error.status) {
    // Then try to parse anything relevant out of the data.
    if (typeof error.data === 'string') {
      try {
        const parsedData = JSON.parse(error.data);
        message = 'Unknown error';
        console.error('Received unknown error:', parsedData);
      } catch (e) {
        // not a JSON object
        if (error.data.length < 40) {
          message = error.data;
        }
      }
    }
  } else {
    // Otherwise just provide status
    const statusText = error?.statusText || 'Unknown error';
    const statusCode = error?.status;
    message = statusCode ? statusCode + ' - ' + statusText : statusText;
  }

  if (errmsg) {
    commit(
      'CreateAndPublishStore/SET_MESSAGE',
      {
        name: errmsg,
        details: `Error: ${message}`,
        type: 'error',
      },
      { root: true }
    );
  }

  if (callback) {
    callback(error);
  }
};

export const handleAjax = async ({
  request,
  dispatch,
  commit,
  mutation,
  errmsg,
  modify,
  callback,
  root,
}: HandleAjaxParams) => {
  return request
    .then(async (resp) => {
      let data: unknown = null;
      // The following checks are shaky as all hell, but because developers have
      // used two different http libraries, which are configured to behave in
      // different manners, we have to try to figure out which is which until
      // we can remove all calls using the vue-resource library, in favor
      // of the axios library.
      if (isAxiosError(resp)) throw resp._axiosError.response;

      if (isVueResourceResponse(resp)) {
        data = resp.data;
      } else {
        // resp is probably from axios library, which only returns the data property from a response.
        data = resp;
      }

      if (mutation) {
        commit(mutation, modify ? modify(data) : data, root ? { root: true } : undefined);
      }

      if (callback) {
        await callback(undefined, data, resp, { dispatch, commit });
      }
      return data;
    })
    .catch((error: HttpResponse | Error) => {
      handleError({ error, errmsg, commit, callback });
    });
};

function isAxiosError(resp: any) {
  // /src/services/common/HttpClient.ts doesn't throw, and instead returns resp.data
  if (typeof resp === 'object' && '_axiosError' in resp) {
    return true;
  }
  return false;
}

function isVueResourceResponse(resp: any) {
  if (
    Object.prototype.hasOwnProperty.call(resp, 'headers') &&
    Object.prototype.hasOwnProperty.call(resp, 'status') &&
    Object.prototype.hasOwnProperty.call(resp, 'data')
  ) {
    // resp is probably from vue-resource http library, which returns a response object.
    return true;
  }
  return false;
}

interface HandleErrorParams {
  error?: HttpResponse | Error;
  errmsg?: string;
  commit: Commit;
  callback?: handleAjaxCallback;
}

interface HandleAjaxParams {
  request: Promise<HttpResponse | any>; // vue-resource returns the response object, axios service helper returns the response body, hence the any.
  dispatch: Dispatch;
  commit: Commit;
  mutation?: string; // Mutation name
  errmsg?: string; // Message used to display on error
  modify?: (apiRespData?: any) => any; // Modify resp.json before passing it to a mutation
  callback?: handleAjaxCallback;
  root?: boolean; // Use root store for mutation
}

type handleAjaxCallback = (
  err?: HttpResponse | Error,
  apiResponse?: any,
  respData?: any,
  vuexCtx?: CallbackVuexCtx
) => void;

interface CallbackVuexCtx {
  dispatch: Dispatch;
  commit: Commit;
}
