<template>
  <Drawer
    :title="isCreateMode ? 'Add brand' : 'Edit brand'"
    :qa="$getComponent('AddEditCampaigns', 'Drawer', 'addEditCampaigns')"
    id="brands-and-campaigns-drawer"
    @cancel="onCancel"
    :submit="submit"
    :vh100="true"
    :loading="isLoading"
  >
    <div slot="content">
      <div class="drawer-div">
        <AlertDisplay :alertMessage="alertMessage" class="alertMessage" v-if="displayAlert" :drawer="true" />
        <b-form-group class="drawer-group">
          <Input
            label="Brand name"
            :inputHandler="handleBrandInput"
            :error="brandNameError"
            :value="currentBrandName"
            maxLength="32"
          />
        </b-form-group>
        <b-form-group v-if="drawerStatus.data.id" class="drawer-group">
          <fm-radio
            :data-qa="$getComponent('AddEditBrands', 'Radio', 'status')"
            label="Status"
            :class="isDisabled ? 'disabledRadio' : 'radio'"
            :options.prop="statusOptions"
            :value="currentBrandActive"
            @input="handleStatusChange"
            :disabled="isDisabled"
          />

          <div
            v-show="isDisabled"
            class="disabledInfo"
            :data-qa="$getComponent('AddEditBrands', 'Text', 'disabledInfo')"
          >
            You’re using all {{ maxBrands }} of your available active brands. To reactivate this brand, disable another
            brand or <a href="#">upgrade your subscription </a> to add more.
          </div>
        </b-form-group>
        <!-- Campaigns -->
        <div class="campaign-list" v-feature="'campaigns'">
          <div class="header">CAMPAIGNS</div>
          <!-- Existing campaigns -->
          <div
            v-for="(campaign, index) in campaigns"
            v-bind:key="`${campaign.id}`"
            :class="editing === index ? 'campaignBlock editMode' : 'campaignBlock'"
          >
            <!-- Transition needs to be worked on to be smoother -->
            <transition name="component-fade" mode="out-in">
              <!-- Edit Campaign -->
              <div v-if="editing === index" class="editCampaign" key="editCampaign" id="editingExistingCampaign">
                <div class="campaignForm">
                  <div
                    v-bind:style="{ backgroundColor: campaign.color }"
                    class="color-icon"
                    :data-qa="$getComponent('AddEditBrands', 'Icon', 'currentColor')"
                  ></div>

                  <Input
                    :data-qa="$getComponent('AddEditBrands', 'TextInput', 'campaignName')"
                    :inputHandler="handleCampaignInput"
                    :value="currentEditingCampaign.name"
                    maxLength="32"
                    :error="editing === inlineErrorMessage.index && inlineErrorMessage.message"
                    :showWordCount="true"
                  />

                  <i
                    class="material-icons"
                    type="button"
                    @click="onCreateOrUpdateCampaign()"
                    :data-qa="$getComponent('AddEditBrands', 'Button', 'confirm')"
                    isDisabled="true"
                  >
                    check
                  </i>
                </div>

                <div class="colorPicker">
                  <CampaignsColorPicker
                    :defaultColor="campaign.color"
                    @updateColor="handleUpdateColorInDrawer"
                    :data-qa="$getComponent('AddEditBrands', 'Select', 'colorInput')"
                  />
                </div>
              </div>
              <!-- Default Campaign view -->
              <div v-else class="defaultCampaign" key="viewCampaign">
                <div class="data">
                  <div
                    v-bind:style="{ backgroundColor: campaign.color }"
                    :data-qa="$getComponent('AddEditBrands', 'Icon', 'color')"
                    class="color-icon"
                  ></div>
                  <div class="campaign-name" :data-qa="$getComponent('AddEditBrands', 'Text', 'campaignName')">
                    {{ campaign.name }}
                  </div>
                </div>
                <transition name="slide-fade" mode="out-in">
                  <!-- Delete confirmation view -->
                  <div v-if="removing === index" class="deleteConfirmation" key="confirmation">
                    <div class="confirmation-text">Remove campaign?</div>
                    <button
                      @click="removing = -1"
                      class="cancel-button"
                      :data-qa="$getComponent('AddEditBrands', 'Button', 'cancelConfirmation')"
                    >
                      Cancel
                    </button>
                    <button
                      class="confirm-button"
                      @click="handleDelete(index)"
                      :data-qa="$getComponent('AddEditBrands', 'Button', 'confirmConfirmation')"
                    >
                      Remove
                    </button>
                  </div>
                  <!-- Default view -->
                  <div class="buttons" v-else key="default">
                    <i
                      id="brand-drawer-create-new-campaign-button"
                      class="material-icons"
                      @click="() => handleEditCampaign(index)"
                      :data-qa="$getComponent('AddEditBrands', 'Icon', 'editBrand')"
                    >
                      create
                    </i>
                    <i
                      class="material-icons"
                      @click="() => handleRemoveCampaign(index)"
                      :data-qa="$getComponent('AddEditBrands', 'Icon', 'deleteBrand')"
                    >
                      close
                    </i>
                  </div>
                </transition>
              </div>
            </transition>
          </div>
          <!-- New Campaign -->
          <div :class="editing === campaigns.length ? 'campaignBlock editMode newBlock' : 'newCampaign'">
            <div v-if="editing === campaigns.length" class="editCampaign" id="newCampaignEditBlock" key="editCampaign">
              <div class="campaignForm">
                <Input
                  label="Campaign name"
                  :data-qa="$getComponent('AddEditBrands', 'TextInput', 'campaignName')"
                  :inputHandler="handleCampaignInput"
                  :value="currentEditingCampaign.name"
                  maxLength="32"
                  :showWordCount="true"
                  :error="editing === inlineErrorMessage.index && inlineErrorMessage.message"
                />

                <i
                  class="material-icons"
                  @click="() => closeCampaignForm()"
                  :data-qa="$getComponent('AddEditBrands', 'Button', 'cancelNewCampaign')"
                >
                  close
                </i>
                <i
                  class="material-icons"
                  @click="handleCreateCampaign()"
                  :data-qa="$getComponent('AddEditBrands', 'Button', 'confirmNewCampaign')"
                >
                  check
                </i>
              </div>
              <div class="label">Campaign Color</div>
              <div class="colorPicker">
                <CampaignsColorPicker
                  :data-qa="$getComponent('AddEditBrands', 'Select', 'colorInput')"
                  :defaultColor="currentEditingCampaign.color"
                  @updateColor="handleUpdateColorInDrawer"
                />
              </div>
            </div>
            <div v-else @click="openNewCampaign">Create New Campaign</div>
          </div>
        </div>
      </div>
    </div>
  </Drawer>
</template>

<script>
import { Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import { required } from 'vuelidate/lib/validators';
import AlertDisplay from '../common/AlertDisplay.vue';
import BrandService from '@/services/BrandService';
import CampaignsColorPicker from '../common/form/CampaignsColorPicker.vue';
import Drawer from '../common/drawer/Drawer.vue';
import Input from '@/components/common/form/Input.vue';
import { byName } from '../../utils/sort';
import errorMessages from '@/utils/errorMessages.json';
import { validateBrandName, validateCampaignName } from '@/plugins/Validations';

const BrandsAndCampaignsDrawer = Vue.extend({
  name: 'brands-and-campaigns-drawer',
  components: {
    AlertDisplay,
    Drawer,
    CampaignsColorPicker,
    Input,
  },

  validations: {
    currentBrandName: {
      required,
      validateBrandName,
    },
  },

  data() {
    return {
      alertMessage: {},
      isLoading: false,
      campaignNameErrorExists: false,
      campaignNameErrorMessage: { message: '' },
      creating: false,
      editing: -1,
      removing: -1,
      status: '',
      currentBrandName: this.drawerStatus.data.name,
      currentBrandActive: this.drawerStatus.data.id ? (this.drawerStatus.data.active ? 1 : -1) : 1,
      campaigns: this.drawerStatus.data.campaigns
        .map((cp) => {
          const _id = cp.id;
          const _active = cp.active || true;
          const _brandId = this.drawerStatus.data.id;
          const result = {
            active: _active,
            brandId: _brandId,
            color: cp.color,
            id: _id,
            name: cp.name,
          };
          return result;
        })
        .sort(byName),
      campaignPayload: [],
      currentEditingCampaign: {
        active: true,
        brandId: this.drawerStatus.data.id,
        color: '#EE4833',
        name: '',
        id: 0,
      },
      // For validation
      brandNameErrorMessage: { display: false, message: '' },
      inlineErrorMessage: { index: -1, message: '' },
      statusOptions: [
        {
          value: 1,
          label: 'Active',
        },
        {
          value: -1,
          label: 'Disabled',
        },
      ],
      startingCampaigns: this.drawerStatus.data.campaigns,
    };
  },

  computed: {
    ...mapGetters(['getAccount']),

    isCreateMode() {
      return !this.drawerStatus.data.id;
    },

    displayAlert() {
      return !!this.alertMessage.type;
    },

    displayDrawer() {
      return this.visible;
    },

    maxBrands() {
      return this.getAccount?.data?.maxBrands || 0;
    },

    brandNameError() {
      if (this.$v.currentBrandName.$error) {
        if (!this.$v.currentBrandName.required) {
          return 'Brand name' + errorMessages.required;
        } else if (!this.$v.currentBrandName.validateBrandName) {
          return errorMessages.invalidBrandName;
        }
      }
      return '';
    },

    // Campaign name error
    campaignNameError() {
      if (this.editing === this.inlineErrorMessage.index) {
        return this.inlineErrorMessage.message;
      }
      return '';
    },

    submit() {
      return {
        qa: Vue.prototype.$getComponent('AddEditBrands', 'Button', 'addEditBrands&SaveChanges'),
        buttonText: this.isCreateMode ? 'Create brand' : 'Save changes',
        onClick: (hide) => this.saveBrandData(hide),
      };
    },
  },
  methods: {
    clearValidation() {
      this.brandNameErrorMessage = { display: false, message: '' };
      this.inlineErrorMessage = { index: -1, message: '' };
    },
    // Validate brand name
    validateBrandName(value) {
      if (!value || !value?.trim()) {
        return `Brand name${errorMessages.required}`;
      } else if (!validateBrandName(value)) {
        return errorMessages.invalidBrandName;
      }
      return '';
    },
    // Validate campaign name
    validateCampaignName(value) {
      if (!value || !value?.trim()) {
        return `Campaign name${errorMessages.required}`;
      } else if (!validateCampaignName(value)) {
        return errorMessages.invalidCampaignName;
      }
      return '';
    },

    setSelectedColor(color) {
      this.currentCampaign.color = color;
      this.onCreateOrUpdateCampaign();
    },

    openNewCampaign() {
      this.creating = true;
      this.currentEditingCampaign = {
        active: true,
        brandId: this.drawerStatus.data.id,
        color: '#EE4833',
        name: '',
        id: 0,
      };

      this.editing = this.campaigns.length;
    },

    handleUpdateColorInDrawer(color) {
      this.currentEditingCampaign.color = color;
    },

    closeCampaignForm() {
      this.editing = -1;
      this.currentEditingCampaign = {
        active: true,
        brandId: this.drawerStatus.data.id,
        color: '#EE4833',
        name: '',
        id: 0,
      };
      this.inlineErrorMessage = { index: -1, message: '' };
    },

    uniqueCheck() {
      if (
        this.campaigns
          .map((c) => c.name)
          .filter((c, i) => i !== this.editing)
          .includes(this.currentEditingCampaign.name.trim())
      ) {
        this.campaignNameErrorExists = true;
        this.inlineErrorMessage = {
          index: this.creating ? this.campaigns.length : this.editing,
          message: Vue.prototype.$errorPrettified('Duplicate campaign name exists'),
        };
        return;
      }
    },
    onCreateOrUpdateCampaign() {
      this.campaignNameErrorExists = false;
      this.uniqueCheck();
      const _campaignNameError = this.validateCampaignName(this.currentEditingCampaign?.name);

      if (_campaignNameError) {
        this.campaignNameErrorExists = true;
        this.inlineErrorMessage = { index: this.editing, message: _campaignNameError };
        return;
      }

      this.campaigns[this.editing] = {
        ...this.currentEditingCampaign,
        name: this.currentEditingCampaign?.name.trim(),
      };

      if (!this.campaignNameErrorExists) {
        this.closeCampaignForm();
      }
    },

    // Handle brand name input
    handleBrandInput(e) {
      this.currentBrandName = e.target.value;
    },

    // Handle brand name input
    handleCampaignInput(e) {
      this.inlineErrorMessage = { index: -1, message: '' };
      this.currentEditingCampaign = {
        ...this.currentEditingCampaign,
        name: e.target.value,
      };
    },

    handleEditCampaign(index) {
      this.currentEditingCampaign = Object.assign(this.currentEditingCampaign, this.campaigns[index]);
      this.editing = index;
      this.removing = -1;
      this.creating = false;
    },

    handleCreateCampaign() {
      this.onCreateOrUpdateCampaign();
      this.creating = false;
      this.removing = -1;
    },

    handleRemoveCampaign(index) {
      this.currentEditingCampaign = Object.assign(this.currentEditingCampaign, this.campaigns[index]);
      this.editing = -1;
      this.creating = false;
      this.removing = index;
    },

    handleDelete(index) {
      this.campaigns.splice(index, 1);
      this.removing = -1;
    },

    // Form methods
    handleStatusChange(e) {
      this.currentBrandActive = parseInt(e.target.value);
    },

    async saveBrandData() {
      if (this.editing !== -1 || this.creating) {
        this.onCreateOrUpdateCampaign();
      }

      if (this.campaignNameErrorExists) {
        return;
      }
      this.$v.currentBrandName.$touch();

      // If form errors
      if (this.$v.$anyError) {
        return;
      }

      // Build payload
      const payload = {
        name: this.currentBrandName?.trim() || '',
      };

      // Transform `active` (refactor this)
      if (this.currentBrandActive === -1) {
        payload['active'] = false;
      } else {
        payload['active'] = true;
      }

      // If editing a brand, add the ID (refactor this)
      if (this.drawerStatus.data?.id) {
        payload['id'] = this.drawerStatus.data.id;
      }

      // 09/2/21: Will eventually need to fix this - WC
      // eslint-disable-next-line
      let payloadCampaigns = [];

      // Campaigns
      if (this.campaigns) {
        payloadCampaigns = this.campaigns.map((cp) => {
          const _id = cp.id || undefined;
          const _active = cp.active || true;
          const _brandId = cp.brandId || undefined;
          const result = {
            active: _active,
            brandId: _brandId,
            color: cp.color,
            id: _id,
            name: cp.name?.trim() || '',
          };

          // Clean undefined
          Object.keys(result).forEach((key) => (result[key] === undefined ? delete result[key] : {}));

          return result;
        });
      }

      // Clear Validation errors here
      this.clearValidation();

      payload['campaigns'] = payloadCampaigns || [];

      this.isLoading = true;

      // eslint-disable-next-line
      let response = {};
      if (this.drawerStatus.data.id) {
        response = await new BrandService()
          .edit(payload)
          .then((res) => res)
          .catch((err) => err.response);
      } else {
        // Create
        response = await new BrandService()
          .create(payload)
          .then((res) => res)
          .catch((err) => err.response);
      }

      // parses errors from the BE:
      if (!response.id) {
        const errors = response.errors;
        this.alertMessage = {
          type: 'critical',
          header: "We couldn't save your changes.",
          message: 'Please correct the errors below and submit the form again.',
          links: errors.map((err) => ({ message: Vue.prototype.$errorPrettified(err) })),
        };

        setTimeout(() => {
          this.isLoading = false;
          this.status = 'error';
        }, 500);
        return;
      }

      setTimeout(() => {
        this.isLoading = false;
        this.status = 'success';
        this.$store.dispatch('currentUser/requestUser');
        this.fetchBrands();
        this.fetchAccount();
        this.$emit('cancel');
      }, 500);
    },

    onCancel(hide) {
      this.$emit('cancel');
      hide();
    },

    randomKey(id) {
      return Math.floor(Math.random() * id + 1);
    },
  },

  props: {
    drawerStatus: {
      type: Object,
    },
    visisble: {
      type: Boolean,
      default: false,
    },
    createBrand: {
      type: Function,
      required: false,
    },
    editBrand: {
      type: Function,
      required: false,
    },
    fetchBrands: {
      type: Function,
      required: false,
    },
    isDisabled: Boolean,
    fetchAccount: {
      type: Function,
      required: false,
    },
  },
  // watch: {
  //   campaignData: {
  //     handler() {
  //       this.currentCampaign = {
  //         active: this.campaignData.active,
  //         brandId: this.campaignData.brandId,
  //         color: this.campaignData.color,
  //         id: this.campaignData.id,
  //         name: this.campaignData.name,
  //       };

  //       this.campaignNameErrorMessage = { message: '' };
  //       this.alertMessage = {};
  //     },
  //   },
  // },
});
export default BrandsAndCampaignsDrawer;
</script>

<style scoped lang="scss">
#add-campaign-input {
  border: 2px solid var(--neutral-grey-3-borders);
  border-radius: 8px;
  height: 48px;
}
#editingExistingCampaign {
  padding-top: 8px;
  .material-icons {
    margin-top: 10px;
  }
}
#editingExistingCampaign.campaign-error {
  padding-top: 12px;
}
.campaigns-header {
  flex-direction: row;
  display: flex;

  background-color: var(--neutral-grey-6-dark-bg);
  padding: 1em;
  align-items: center;
}
.drawer-group {
  .radio {
    --brand-dark: black;
    cursor: pointer;
  }
  .disabledRadio {
    --brand-dark: var(--neutral-grey-3-borders);
  }
  .disabledInfo {
    margin-top: -20px;
    color: var(--neutral-grey-2-lighter-text);
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    a {
      text-decoration: underline;
    }
  }
}
.newCampaign {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-decoration-line: underline;
  cursor: pointer;
  color: var(--accent-links);
  margin-top: 16px;
}
#add-campaign-drawer-button {
  background: var(--accent-links);
  width: 100%;
  border-radius: 8px;
  font-weight: 500;
  letter-spacing: 0.38px;
  height: 48px;
}
#cancel-link {
  text-decoration: underline;
  align-self: center;
  margin-top: 2rem;
  color: var(--accent-links);
}
.ellipse-styles {
  width: 24px;
  height: 24px;
  margin: 0 3.5px 0 0;
}
.ellipse-styles:hover {
  cursor: pointer;
}
#footer-container {
  background: var(--neutral-white);
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
#close-button {
  width: 35px;
  height: 35px;
  color: var(--neutral-grey-1-text);
  position: absolute;
  top: 0;
  right: 0;

  &:hover {
    cursor: pointer;
  }
}

.campaign-list .header {
  color: var(--neutral-grey-2-lighter-text);
  border-bottom: solid 1px var(--neutral-grey-3-borders);
}

.campaign-name {
  text-overflow: ellipsis;
  min-width: 0;
  white-space: nowrap;
  overflow: hidden;
}

.color-icon {
  flex-shrink: 0;
  margin: auto 16px;
  height: 16px;
  width: 16px;
  border-radius: 10px;
}
.material-icons {
  margin: auto;
  height: 36px;
  width: 36px;
  align-items: center;
  display: flex;
  justify-content: center;
  margin-left: 16px;
  color: var(--accent-links);
  box-shadow: 0px 1px 3px rgba(34, 32, 70, 0.2);
  border-radius: 8px;
  cursor: pointer;
}

.campaignBlock {
  border-bottom: solid 1px var(--neutral-grey-3-borders);
  width: 100%;
  align-content: center;
  min-height: 68px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: var(--neutral-grey-1-text);

  .editCampaign {
    display: flex;
    flex-direction: column;
    justify-content: center;
    .colorPicker {
      margin-top: -24px;
      margin-bottom: -6px;
    }

    .campaignForm {
      display: flex;
      align-items: center;
      height: 97px;
      .campaignInput {
        margin-bottom: 0px;
      }
      .color-icon {
        margin-top: 24px;
        height: 16px;
        width: 16px;
      }
      .material-icons {
        width: 60px;
        height: 46px;
        margin-top: -12px;
        box-shadow: 0px 1px 3px rgba(34, 32, 70, 0.2);
      }
    }
    .campaignForm.campaign-error {
      padding-top: 44px;
    }
  }

  .defaultCampaign {
    height: 100%;
    display: flex;
    justify-content: space-between;
    .data {
      display: flex;
      align-items: center;
      flex: 0 1 auto;
      overflow: hidden;
    }
    .deleteConfirmation {
      display: flex;
      flex: 1 0 325px;
      word-wrap: none;
      justify-content: flex-end;
      align-items: center;

      .confirmation-text {
        font-weight: bold;
        font-size: 14px;
        line-height: 21px;
        color: var(--neutral-grey-1-text);
      }
      button {
        box-shadow: 0px 1px 3px rgba(34, 32, 70, 0.2);
        border-radius: 8px;
        width: 83px;
        height: 36px;
        padding: 0px;
        margin-left: 16px;
        font-weight: 500;
        border: none;
      }
      .cancel-button {
        background-color: white;
        color: var(--accent-links);
      }
      .confirm-button {
        color: var(--neutral-white);
        background: var(--brand-topicpulse---dark);
      }
    }

    .buttons {
      // border: solid red 2px;
      display: flex;
    }
  }
}

#newCampaignEditBlock {
  padding: 16px;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  color: var(--neutral-grey-1-text);
  .label {
    margin-top: 16px;
  }

  .top {
    margin-top: -10px;
  }
  .colorPicker {
    margin-left: -50px;
    margin-top: -12px;
    margin-bottom: -12px;
  }

  .material-icons {
    margin-top: 20px;
  }
}

.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);

  opacity: 0;
}

.alertMessage {
  margin-top: 32px;
}
</style>
