<template>
  <div class="show-details-container">
    <show-details :show="activeShow" :episodes="playlists" :is-loading="isLoading" @request-playlists="fetchData" />
  </div>
</template>

<script>
import ShowDetails from '../components/ShowDetails';
import { createNamespacedHelpers } from 'vuex';
const { mapActions, mapGetters } = createNamespacedHelpers('CreateAndPublishStore');
const { mapActions: mapActionsPlaylists } = createNamespacedHelpers('CreateAndPublishStore/getPlaylists');
const { mapGetters: mapGettersPlaylists } = createNamespacedHelpers('CreateAndPublishStore/getPlaylists');

export default {
  name: 'ShowsRouteDetails',

  components: {
    ShowDetails,
  },

  data() {
    return {
      isLoading: false,
    };
  },

  computed: {
    ...mapGetters(['activeShow', 'unfilteredShows']),
    ...mapGettersPlaylists(['playlists']),

    showId() {
      return this.$route.params.showId;
    },
  },

  methods: {
    ...mapActionsPlaylists(['fetchPlaylists']),
    ...mapActions(['fetchAllShowsData', 'setActiveShow']),

    async fetchData() {
      this.isLoading = true;
      await this.fetchPlaylists();
      this.isLoading = false;
    },
  },

  async created() {
    const tab = this.$route.query.tab;
    this.$store.commit('CreateAndPublishStore/getPlaylists/SET_TYPE', tab || 'published');
    this.$store.commit('CreateAndPublishStore/getPlaylists/CLEAR_PLAYLISTS');
    this.$store.commit('CreateAndPublishStore/getPlaylists/SET_PAGE', 1);
    this.$store.commit('CreateAndPublishStore/getPlaylists/SET_ORDER', 'DESC');
    this.$store.commit('CreateAndPublishStore/getPlaylists/SET_SORT', 'id');
    this.$store.commit('CreateAndPublishStore/getPlaylists/SET_SEARCH_TERM', '');
    this.isLoading = true;
    await Promise.all([this.fetchAllShowsData(), this.fetchData()]);
  },

  beforeRouteLeave(to, from, next) {
    this.$store.commit('CreateAndPublishStore/SET_SHOULD_SHOW_ALERT', false);
    next();
  },
};
</script>

<style lang="scss" scoped>
.show-details-container {
  padding: 3rem;
}
</style>
