<template>
  <div class="new-show__basic-info" role="tabpanel" aria-label="1. Add basic info">
    <section aria-labelledby="show_title_desc">
      <div>
        <fm-text ref="firstHeader" tabindex="-1" id="show_title_desc" class="section-title no-outline" tag="h2" as="h3">
          Title &amp; description
        </fm-text>
        <fm-text>
          These will help new listeners find your show. These will also be the default value for episodes of this show,
          but they can be overridden when you create a new episode.
        </fm-text>
      </div>
      <div>
        <div class="form-group">
          <fm-input
            :error.prop="$v.title.$error ? 'Required' : ''"
            label="Title"
            v-model-wc="$v.title.$model"
            assistiveText="To increase discoverability, make sure your show name is unique and not in use by anyone else."
          />
        </div>
        <div class="form-group">
          <fm-textarea
            :error.prop="$v.description.$error ? 'Required' : ''"
            rows="5"
            label="Description"
            v-model-wc="$v.description.$model"
            :assistiveText="`${description.length} of 255 characters. Write a concise but descriptive overview of this show and what your audience can expect when they listen.`"
          />
        </div>
      </div>
    </section>

    <section aria-labelledby="show_properties">
      <div>
        <fm-text id="show_properties" class="section-title" tag="h2" as="h3"> Properties </fm-text>
        <fm-text> This information will help determine how your show is displayed. </fm-text>
      </div>
      <div>
        <div class="form-group">
          <fm-select label="Episode order" v-model-wc="episodeOrder" :options.prop="episodeOrderOptions" />
        </div>
        <div class="form-group">
          <fm-select label="Language" v-model-wc="language" :options.prop="languageOptions" />
        </div>
      </div>
    </section>

    <section aria-labelledby="show_contact">
      <div>
        <fm-text id="show_contact" class="section-title" tag="h2" as="h3"> Contact info </fm-text>
        <fm-text> This information will be published in your RSS feeds. </fm-text>
      </div>
      <div>
        <div class="form-group">
          <fm-input
            :error.prop="$v.author.$error ? 'Required' : ''"
            label="Author"
            assistiveText="This can be a company or an individual"
            v-model-wc="$v.author.$model"
          />
        </div>
        <div class="form-group">
          <fm-input
            label="Primary contact email"
            :error.prop="emailError"
            assistiveText="This will be published in your RSS feeds. It may be used to verify your account, and will be visible to podcast aggregators and possibly other users."
            v-model-wc="$v.primaryContactEmail.$model"
          />
        </div>
        <div class="form-group">
          <fm-input
            :error.prop="urlError"
            label="Website (optional)"
            assistiveText="To increase discoverability, make sure your show name is unique and not in use by anyone else."
            v-model-wc="$v.website.$model"
          />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
const { mapActions, mapState } = createNamespacedHelpers('CreateAndPublishStore/showSetup');
import { required, email, url } from 'vuelidate/lib/validators';
import languages from '@/createandpublish/core/data/ISO_639';

export default {
  data: () => ({
    title: '',
    description: '',
    episodeOrder: 'serial',
    language: 'en',
    author: '',
    primaryContactEmail: '',
    website: '',
  }),

  validations: {
    title: { required },
    description: { required },
    author: { required },
    primaryContactEmail: { required, email },
    website: { url },
  },

  computed: {
    ...mapState(['draftShow', 'draftMasterRssFeed']),

    episodeOrderOptions() {
      return [
        {
          value: 'serial',
          label: 'Serial (oldest episode listed first)',
        },
        {
          value: 'episodic',
          label: 'Episodic (newest episode listed first)',
        },
      ];
    },

    languageOptions: () => {
      return Object.values(languages).map((lang) => ({
        value: lang['639-1'],
        // done to prevent things like "English, English"
        label: `${lang.name}${lang.nativeName && lang.name !== lang.nativeName ? `, ${lang.nativeName}` : ''}`,
      }));
    },

    emailError() {
      if (this.$v.primaryContactEmail.$error) {
        if (!this.$v.primaryContactEmail.required) {
          return 'The primary contact email is required';
        }
        if (!this.$v.primaryContactEmail.email) {
          return 'This must be a valid email address';
        }
      }
      return '';
    },
    urlError() {
      if (this.$v.website.$error) {
        return 'Please enter a valid URL';
      }
      return '';
    },
  },

  methods: {
    ...mapActions(['updateDraftShow']),
  },

  watch: {
    title(title) {
      this.updateDraftShow({ title });
    },
    description(description) {
      this.updateDraftShow({ description });
    },
    episodeOrder(episodeOrder) {
      this.updateDraftShow({ show_format: episodeOrder });
    },
    language(language) {
      this.updateDraftShow({ language_override: language });
    },
    author(author) {
      this.updateDraftShow({ author });
    },
    primaryContactEmail(email) {
      this.updateDraftShow({ email });
    },
    website(website) {
      this.updateDraftShow({ podcast_link: website });
    },
    '$v.$invalid'(newVal) {
      if (newVal) {
        this.$emit('preventNext');
      } else {
        this.$emit('allowNext');
      }
    },
  },

  async mounted() {
    await window.customElements.whenDefined('fm-text');
    await this.$nextTick();
    this.$refs.firstHeader.focus();
    this.title = this.draftShow.title;

    const pageTitle = this.title ? `Edit ${this.title}` : 'New Show';
    document.title = `${pageTitle} | Podcasts | Create&Publish | Futuri Content Cloud` || 'Futuri Content Cloud';

    this.description = this.draftShow.description;
    this.episodeOrder = this.draftShow.show_format || 'serial';
    this.language = this.draftShow.language_override || 'en';
    this.author = this.draftShow.author;
    this.primaryContactEmail = this.draftShow.email;
    this.website = this.draftShow.podcast_link;
    if (this.$v.$invalid || this.$v.$error) {
      this.$emit('preventNext');
    }
  },
};
</script>

<style lang="scss" scoped>
.new-show {
  &__basic-info {
    section {
      display: flex;
      justify-content: space-between;
      border-bottom: 2px solid var(--neutral-grey-3-borders);
      padding: 24px 0 0 0;

      .section-title {
        margin-bottom: 8px;

        &::part(tag) {
          font-weight: 500;
        }
      }

      & > div {
        width: 45%;
        padding: 0 1em;
      }
    }
  }
}

.no-outline {
  outline: 0;
}
</style>
