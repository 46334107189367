<template>
  <!-- Container -->
  <fm-card :expandWidth.prop="true">
    <!-- Delete Show modal -->
    <ConfirmationModal
      :Status="deleteShowStatus"
      :loading="deletingShow"
      @close="onDeleteClose"
      @confirm="onDeleteConfirm"
      v-if="deleteModalIsOpen"
    />
    <!-- Layout -->
    <card-layout>
      <!-- Image -->
      <div slot="image" class="shows-list-card-image">
        <router-link v-if="!show.placeholder" class="center" :to="showDetailsRoute">
          <img v-if="show.square_image.length > 0" :src="showImage" />
          <img v-else src="@/assets/post-logo.svg" />
        </router-link>
      </div>
      <!-- Meta -->
      <div slot="meta" class="shows-list-card-meta" @click="onCardClick">
        <div class="tags-wrapper">
          <tag-list :tags="categories" :show-id="show.id" />
        </div>
      </div>
      <!-- Content -->
      <div slot="content" class="shows-list-card-content">
        <fm-text :tag.prop="'h2'" :as.prop="'h3'" class="show-title nowrap">
          {{ show.title }}
        </fm-text>

        <div class="d-flex flex-column mb-3 pr-3 pl-3">
          <router-link :to="{ path: showDetailsRoute, query: { tab: 'published' } }" v-slot="{ href, navigate }" custom>
            <fm-link :href="href" external @click.stop="navigate" class="nowrap mb-1" :title="publishedEpisodesText">
              {{ publishedEpisodesText }}
            </fm-link>
          </router-link>

          <fm-text variant="disclaimer" class="nowrap">{{ lastPublished }}</fm-text>
        </div>

        <div class="episodeStateBreakdown">
          <router-link :to="{ path: showDetailsRoute, query: { tab: 'scheduled' } }" v-slot="{ href, navigate }" custom>
            <fm-link
              :href="href"
              external
              @click.stop="navigate"
              class="nowrap breakdown-link"
              :title="`${show.counters.scheduled} scheduled`"
            >
              {{ show.counters.scheduled }} scheduled
            </fm-link>
          </router-link>

          <router-link :to="{ path: showDetailsRoute, query: { tab: 'draft' } }" v-slot="{ href, navigate }" custom>
            <fm-link
              :href="href"
              external
              @click.stop="navigate"
              class="nowrap breakdown-link"
              :title="`${show.counters.draft} drafts`"
            >
              {{ show.counters.draft }} drafts
            </fm-link>
          </router-link>

          <router-link
            :to="{ path: showDetailsRoute, query: { tab: 'unpublished' } }"
            v-slot="{ href, navigate }"
            custom
          >
            <fm-link
              :href="href"
              external
              @click.stop="navigate"
              class="nowrap breakdown-link"
              :title="`${show.counters.unpublished} unpublished`"
            >
              {{ show.counters.unpublished }} unpublished
            </fm-link>
          </router-link>
        </div>
      </div>
      <!-- Action Bar -->
      <fm-card-action-bar
        slot="actionBar"
        :firstAction.prop="actionBar.firstAction"
        :secondAction.prop="actionBar.secondAction"
        :menuOptions.prop="actionBar.menuOptions"
      />
    </card-layout>
  </fm-card>
</template>
<script>
import moment from 'moment';

import ConfirmationModal from '@/createandpublish/ConfirmationModal.vue';
import CardLayout from '@/createandpublish/components/common/card/CardLayout';
import TagList from '@/createandpublish/components/common/card/TagList';

import { mapGetters as mapRootGetters } from 'vuex';
import { createNamespacedHelpers } from 'vuex';
const { mapGetters, mapActions } = createNamespacedHelpers('CreateAndPublishStore');

export default {
  name: 'ShowsListGridCard',

  components: {
    ConfirmationModal,
    CardLayout,
    TagList,
  },

  data() {
    return {
      actionBar: {
        firstAction: {
          icon: 'add',
          hoverText: 'New Episode',
          onClick: () => {
            this.$store.commit('CreateAndPublishStore/draftEpisodes/SET_MAX_STEP_COMPLETED', 0);
            this.$router.push({ path: `podcasts/shows/${this.show.id}/episodes/0/edit/info` });
          },
        },
        secondAction: {
          icon: 'settings',
          hoverText: 'Show Settings',
          onClick: () => {
            this.$router.push({ path: `podcasts/shows/edit/${this.show.id}` });
          },
        },
        menuOptions: [
          {
            label: 'Delete show',
            onClick: () => this.onDeleteClick(),
          },
        ],
      },
      // Delete show
      deleteModalIsOpen: false,
      deleteShowStatus: '',
      deletingShow: false,
    };
  },

  computed: {
    ...mapGetters({
      station: 'station_name',
    }),
    ...mapGetters(['isAvess']),
    ...mapRootGetters(['selectedBrand']),
    // Transformed list of categories
    categories() {
      if (this.show.categories && this.show.categories.length > 0) {
        return this.show.categories;
      }
      return [];
    },
    // Format published episodes
    publishedEpisodesText() {
      return `${this.show.counters && this.show.counters.published} published episodes`;
    },
    // Format `last published` string
    lastPublished() {
      const _date = this.show.last_playlist_date;
      if (!_date) return '';
      return `Last published ${moment(_date).fromNow()}`;
    },
    // Route that links to show details
    showDetailsRoute() {
      return `/create/${this.selectedBrand?.id}/podcasts/shows/${this.show.id}/details`;
    },
    showImage() {
      const location = 'https://post.ccdev.futurimedia.com';

      if (!this.show.square_image.includes(location)) {
        return `${location}${this.show.square_image}`;
      }

      return this.show.square_image;
    },
  },

  methods: {
    ...mapActions(['deleteShow', 'fetchAllShowsData']),

    // onClick events for cards
    onCardClick() {
      this.$router.push({
        path: this.showDetailsRoute,
      });
    },

    onDeleteClick() {
      this.deleteModalIsOpen = true;
    },

    onDeleteClose() {
      this.deleteModalIsOpen = false;
    },

    async onDeleteConfirm() {
      this.deletingShow = true;
      const title = this.show.title;
      this.$emit('show-delete', title);
      await this.deleteShow(this.show.id);
      this.deletingShow = false;

      // Might need error handling
      this.deleteShowStatus = true;

      this.fetchAllShowsData();

      // Close drawer when done
      this.onDeleteClose();
    },
  },

  props: {
    show: Object,
  },
};
</script>
<style lang="scss" scoped>
// Meta
.shows-list-card-meta {
  .tags-wrapper {
    width: 50%;
  }
}

// Image
.shows-list-card-image {
  width: 100%;
  height: 100%;

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

// Content
.shows-list-card-content {
  height: 100%;
  padding: 16px 0;

  .show-title {
    margin-bottom: 16px;
    padding: 0 16px;
  }

  .episodeStateBreakdown {
    box-sizing: border-box;
    border-top: 1px solid var(--neutral-grey-3-borders);
    display: flex;
    padding: 12px 16px;

    .breakdown-link {
      margin-right: 6px;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.nowrap,
.nowrap::part(tag),
.nowrap::part(anchor) {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.nowrap::part(anchor) {
  display: block;
}

@media screen and (max-width: 991px) {
  .shows-list-card-meta {
    .tags-wrapper {
      display: none;
    }
  }

  .episodeStateBreakdown {
    display: none;
  }
}
</style>
