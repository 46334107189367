






import Vue, { PropType } from 'vue';

export default Vue.extend({
  name: 'rounded-card',

  props: {
    expandHeight: {
      type: Boolean as PropType<boolean>,
    },
    expandWidth: {
      type: Boolean as PropType<boolean>,
    },
  },
});
