











































import { Component, Vue, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { AudioLibraryMode, AudioEvent } from '@/types/createandpublish/mediaLibraries/AudioLibrary';
import { SegmentObserverOutputResult } from '@/types/createandpublish/mediaLibraries/TwistedWave';
import { Playlist } from '@/types/createandpublish/podcasts/Playlist';
import { eventBus, busEvents } from '@/createandpublish/core/eventBus/audioWizardEventBus';

const createAndPublishStore = namespace('CreateAndPublishStore');
const audioWizardModule = namespace('CreateAndPublishStore/audioWizard');
const draftEpisodesModule = namespace('CreateAndPublishStore/draftEpisodes');

Component.registerHooks(['created', 'beforeDestroy']);

@Component({
  name: 'AudioWizardRoute',
})
export default class AudioWizardRoute extends Vue {
  @Prop({ type: String, required: true }) readonly wizardMode!: AudioLibraryMode;

  @createAndPublishStore.Getter('station_name') readonly stationName!: string;

  @audioWizardModule.Mutation('CLEAR_STACK_EVENTS') clearStackEvents;
  @audioWizardModule.Getter('wizardModes') readonly modes!: Record<string, AudioLibraryMode>;
  @audioWizardModule.Getter readonly stackEvents!: AudioEvent[];
  @audioWizardModule.Getter readonly stackEventResourceUris!: string[];
  @audioWizardModule.Action initAudioMixer;
  @audioWizardModule.Action submitAudioMixerOutput;
  @audioWizardModule.Getter readonly audioMixerResult!: SegmentObserverOutputResult;

  @draftEpisodesModule.Getter readonly currentDraftEpisode!: Playlist;
  @draftEpisodesModule.Getter readonly editingEpisode!: null | Playlist;
  @draftEpisodesModule.Action saveDraftEvents;
  @draftEpisodesModule.Action updateEpisode;
  @draftEpisodesModule.Action getEpisodeById;

  /**
   * Routing properties
   */

  get currentView() {
    return this.$route.fullPath.split('/').pop();
  }

  get goBackRoute() {
    const { brandId, showId, episodeId } = this.$route.params;
    const rootPath = `/create/${brandId}`;
    switch (this.currentView) {
      case 'library':
        return this.wizardMode === this.modes.TRACK_WIZARD
          ? 'description'
          : `${rootPath}/podcasts/shows/${showId}/episodes/${episodeId}/edit/audio`;
      case 'editor':
        return 'library';
      case 'description':
        return `${rootPath}/audio-library`;
      default:
        return '';
    }
  }

  get toNextRoute() {
    switch (this.currentView) {
      case 'description':
        return 'library';
      case 'library':
        return 'editor';
      default:
        return '';
    }
  }

  navigateAfterExport() {
    const { brandId, showId, episodeId } = this.$route.params;
    const rootPath = `/create/${brandId}`;

    switch (this.wizardMode) {
      case this.modes.TRACK_WIZARD:
        this.$router.push({
          path: `${rootPath}/audio-library`,
        });
        break;
      case this.modes.EPISODE_WIZARD:
        this.$router.push({
          path: `${rootPath}/podcasts/shows/${showId}/episodes/${episodeId}/edit/audio`,
          query: this.$route.query,
        });
        break;
      default:
        throw new Error('[AudioWizardRoute] [navigateAfterExport] Unable to determine where to route user.');
    }
  }

  /**
   * Header properties
   */

  get showTrackTitle() {
    return this.wizardMode === this.modes.EPISODE_WIZARD;
  }

  get trackTitle() {
    if (!this.workingEpisode) return '';
    return this.workingEpisode.name || '';
  }

  /**
   * Button properties
   */

  get backButtonText() {
    switch (this.currentView) {
      case 'library':
        return this.wizardMode === this.modes.TRACK_WIZARD ? 'Back to track details' : `Back`;
      case 'editor':
        return 'Back to track preview';
      case 'description':
        return `Back to Audio Library`;
      default:
        return 'Back';
    }
  }

  get showSaveAsDraftButton() {
    return this.wizardMode === this.modes.EPISODE_WIZARD && this.currentView !== 'editor';
  }

  isAudioMixerButtonLoading = false;

  get showAudioMixerButton() {
    return this.currentView === 'library';
  }

  async onAudioMixerClick() {
    this.isAudioMixerButtonLoading = true;
    await this.initAudioMixer(this.stackEventResourceUris);
    this.isAudioMixerButtonLoading = false;
    this.$router.push('editor');
  }

  get showNextButton() {
    return this.currentView === 'description';
  }

  isExportButtonLoading = false;

  get showExportButton(): boolean {
    return this.currentView === 'library' || this.currentView === 'editor';
  }

  get exportButtonText(): string {
    switch (this.wizardMode) {
      case this.modes.TRACK_WIZARD:
        return 'Export to library';
      case this.modes.EPISODE_WIZARD:
        return 'Export track for episode';
      default:
        return '';
    }
  }

  async onExportButtonClick() {
    // TODO: Check if editor is dirty.
    // TODO: Validate that one of stackEvents/audioMixerResult is not empty.
    // TODO: If editor not dirty, use stack events.
    switch (this.wizardMode) {
      case this.modes.TRACK_WIZARD:
        // do checks
        break;
      case this.modes.EPISODE_WIZARD:
        // do checks
        break;
      default:
        throw new Error('[AudioWizardRoute] [onExportButtonClick] Export clicked in unsupported wizardMode');
    }
    this.isExportButtonLoading = true;
    if (this.currentView === 'editor') {
      eventBus.$emit(busEvents.EXPORT_EVENT);
    } else if (this.currentView === 'library') {
      this.wizardMode === this.modes.TRACK_WIZARD && (await this.saveAudioToLibrary());
      this.wizardMode === this.modes.EPISODE_WIZARD && (await this.saveEpisode());
      this.navigateAfterExport();
    }
  }

  /**
   * Track Wizard properties
   */

  saveAudioToLibrary() {
    alert('API does not yet support this feature.');
  }

  /**
   * Episode properties
   */

  get workingEpisode(): Playlist | undefined {
    if (this.wizardMode !== this.modes.EPISODE_WIZARD) return undefined;

    return this.editingEpisode ? this.editingEpisode : this.currentDraftEpisode;
  }

  get workingEpisodeId(): string | undefined {
    if (this.wizardMode !== this.modes.EPISODE_WIZARD) return undefined;

    return this.editingEpisode ? (this.$route.query.editingEpisode as string) : this.$route.params.episodeId;
  }

  async saveEpisode() {
    if (!this.workingEpisode) {
      throw new Error('[AudioWizardRoute] [saveEpisode] no working episode to save');
    }

    let eventResourceUris: string[] = [];
    if (this.currentView === 'library') {
      eventResourceUris = this.stackEvents.map((event) => `/${this.stationName}/api/v1/event/${event.id}/`);
    } else if (this.currentView === 'editor' && this.audioMixerResult?.events?.length) {
      eventResourceUris = this.audioMixerResult.events.map(
        (eventId) => `/${this.stationName}/api/v1/event/${eventId}/`
      );
    }

    await this.updateEpisode({
      id: this.workingEpisodeId,
      data: {
        events: eventResourceUris,
      },
    });
    const episodeData = await this.getEpisodeById(this.workingEpisodeId);
    const { events } = episodeData;
    this.saveDraftEvents(events);
  }

  /**
   * Event bus properties
   */

  setupEventBusListeners() {
    eventBus.$on(busEvents.TW_PROCESSING_COMPLETE, this.twProcessingCompleteHandler);
  }

  destroyEventBusListeners() {
    eventBus.$off(busEvents.TW_PROCESSING_COMPLETE, this.twProcessingCompleteHandler);
  }

  async twProcessingCompleteHandler() {
    switch (this.wizardMode) {
      case this.modes.TRACK_WIZARD:
        await this.saveAudioToLibrary();
        break;
      case this.modes.EPISODE_WIZARD:
        await this.saveEpisode();
        break;
      default:
        this.isExportButtonLoading = false;
        throw new Error('[AudioWizardRoute] [twProcessingCompleteHandler] Export clicked in unsupported wizardMode');
    }
    this.isExportButtonLoading = false;
    this.navigateAfterExport();
  }

  /**
   * Lifecycle properties
   */

  created() {
    this.setupEventBusListeners();
    if (this.wizardMode === this.modes.TRACK_WIZARD) {
      this.clearStackEvents();
    }
  }

  beforeDestroy() {
    this.destroyEventBusListeners();
  }
}
