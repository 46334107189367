<template>
  <fm-modal class="embed-audio-modal" :visible="true">
    <template>
      <header slot="controlRow" class="header">
        <fm-text tag="h1" as="h3"> Embed audio </fm-text>
        <close-button class="header__close-button" @click="close" />
      </header>
    </template>

    <template>
      <section slot="content">
        <widget-config-form
          :smc="smc"
          :episode-id="episodeId"
          variant="embed-audio"
          @close="close"
        ></widget-config-form>
      </section>
    </template>
  </fm-modal>
</template>

<script>
import CloseButton from '@/createandpublish/components/CloseButton';
import WidgetConfigForm from './Forms/WidgetConfig.vue';

export default {
  name: 'embed-audio-modal',

  props: {
    episodeId: { type: Number, required: true },
  },

  data() {
    return {
      theme: 'light',
      embedInFrame: 'withoutIframe',
      isCopiedToClipboard: false,
      smc: {},
    };
  },

  computed: {
    iFrameOptions() {
      return [
        { label: 'No', value: 'withoutIframe' },
        { label: 'Yes', value: 'withIframe' },
      ];
    },

    themeOptions() {
      return [
        { label: 'Light', value: 'light' },
        { label: 'Dark', value: 'dark' },
      ];
    },

    embedCode() {
      if (this.embedInFrame === 'withoutIframe') {
        return `<div class="futuri-widget" data-config="station=${this.$store.getters.settings.station_name}&limit=1&theme=${this.theme}&id=${this.episodeId}&mods=nocategorieslist"></div>\n\n<script type="text/javascript" src="//post.futurimedia.com/futuri-post-widget.js" defer><\/script>`; // eslint-disable-line no-useless-escape
      } else {
        return `<iframe src="https://post.futurimedia.com/futuri-post-widget.html?station=${this.$store.getters.settings.station_name}&limit=1&theme=${this.theme}&id=${this.episodeId}&mods=nocategorieslist" width="700" height="467"></iframe>`;
      }
    },
  },

  methods: {
    close() {
      this.$emit('on:cancel');
    },

    handleCopyEmbedCodeToClipboard() {
      this.$refs.textArea.shadowRoot.querySelector('.fm-textarea__input').select();
      navigator.clipboard.writeText(this.embedCode);
      this.isCopiedToClipboard = true;
    },
  },

  components: {
    CloseButton,
    WidgetConfigForm,
  },
};
</script>

<style lang="scss" scoped>
.embed-audio-modal {
  &::part(modal) {
    max-width: 600px;
  }

  .header {
    position: relative;
    margin-bottom: 36px;

    &__close-button {
      position: absolute;
      top: 0;
      right: 0;
    }
  }

  .content {
    margin-bottom: 36px;

    &__instructions {
      margin-bottom: 24px;

      &--noiframe {
        margin-top: 16px;
      }
    }
  }

  .actions {
    display: flex;
    justify-content: center;
    align-items: center;

    .copy-button {
      margin-right: 16px;
    }
  }
}
</style>
