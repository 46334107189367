

















































import { Component, Vue, Prop } from 'vue-property-decorator';

import RoundedCard from '@/components/common/cards/RoundedCard.vue';

import AudienceIcon from '@/assets/audience/profile-dropdown/audience.svg';
import DiscoverIcon from '@/assets/discover.svg';
import CreateAndPublishIcon from '@/assets/createpublish.svg';

import { ProductIds } from '@/types/account/AccountData';

import type { Product } from '@/types/Product';

@Component({
  name: 'SubscriptionProduct',
  components: {
    RoundedCard,
  },
})
export default class SubscriptionProduct extends Vue {
  @Prop({ type: Object, required: true }) product!: Product;
  @Prop({ type: Boolean, default: false }) locked!: boolean;

  get iconType() {
    if (this.locked) {
      return 'warning';
    } else if (this.product.active) {
      return 'check_circle';
    }
    return 'cancel';
  }

  get productIcon() {
    const id = this.product.id;
    switch (id) {
      case ProductIds.AUDIENCE:
        return AudienceIcon;
      case ProductIds.DISCOVER:
        return DiscoverIcon;
      case ProductIds.CREATE_AND_PUBLISH:
        return CreateAndPublishIcon;
      default:
        return undefined;
    }
  }
}
