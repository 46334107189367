



































import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import AccountCard from '@/components/dashboard/AccountCard.vue';

import { ProductIds } from '@/types/account/AccountData';
import { Invoice, ProductHandle } from '@/types/ecomm';
import { currencyFormatOptions } from '@/utils/ecomm/chargify';
import type { ISO8601DateTime } from '@/types/Common';
import type { Product } from '@/types/Product';
import { SubscriptionData } from '@/utils/ecomm';

const EcommStore = namespace('EcommStore');

@Component({
  components: {
    AccountCard,
  },
})
export default class BillingCard extends Vue {
  @Prop({ type: Boolean, default: false }) newUser?: boolean;
  @Prop({ type: Object, default: null }) mostRecentInvoice!: Invoice | null;
  @Prop({ type: Array, required: true, default: [] }) products!: Product[];

  @EcommStore.Getter currentSubscriptionData!: SubscriptionData;

  get isAudienceOnly() {
    return this.products.length === 1 && this.products[0].id === ProductIds.AUDIENCE;
  }

  get paidDate() {
    if (!this.mostRecentInvoice) return undefined;
    const { transaction_time }: { transaction_time: ISO8601DateTime } = this.mostRecentInvoice.payments[0] ?? {};
    if (transaction_time) {
      return new Date(transaction_time).toLocaleDateString(undefined, {
        month: 'short',
        day: '2-digit',
        year: 'numeric',
      });
    }
    const [year, month, day] = this.mostRecentInvoice.paid_date.split('-');
    return new Date(Number(year), Number(month) - 1, Number(day)).toLocaleDateString(undefined, {
      month: 'short',
      day: '2-digit',
      year: 'numeric',
    });
  }

  get paidAmount() {
    if (!this.mostRecentInvoice) return '';
    const { paid_amount } = this.mostRecentInvoice;
    return new Intl.NumberFormat(undefined, currencyFormatOptions).format(Number(paid_amount));
  }

  get creditCardUsed() {
    if (!this.mostRecentInvoice?.payments?.length) return '';

    const { card_brand, masked_card_number } = this.mostRecentInvoice.payments?.[0].payment_method;
    const splitCardNum = masked_card_number?.split('-') ?? [];
    const lastFour = splitCardNum[splitCardNum.length - 1] ?? '';
    if (!lastFour) return '';

    return `paid with ${this.capitalizeCardBrandName(card_brand)} x${lastFour}`;
  }

  capitalizeCardBrandName(cardBrand: string) {
    if (!cardBrand) return '';
    return cardBrand.charAt(0).toUpperCase() + cardBrand.substring(1);
  }

  get isFreeTrial() {
    let result = false;
    switch (this.currentSubscriptionData?.productHandle) {
      case ProductHandle.FULL_SUITE_FREE_TRIAL:
        result = true;
        break;
    }
    return result;
  }
}
