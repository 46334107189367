




import Vue from 'vue';
import { mapGetters } from 'vuex';

import { Product } from '@/types/account/AccountData';

export default Vue.extend({
  name: 'seat-info',

  props: {
    id: {
      type: Number as () => Product['id'],
      default: 0,
    },
  },

  computed: {
    ...mapGetters(['sortedPriorityAccountProducts']),
  },

  methods: {
    // Build copy with available products and seats data
    getSeatsCopy() {
      const prefix = ''; // To keep flexible
      const suffix = ' assigned.';
      let seatsString = '';
      if (this.sortedPriorityAccountProducts?.length > 0) {
        const productStrings = this.sortedPriorityAccountProducts.map((product) => {
          return `<b>${product.currentSeats} of ${product.maxSeats}</b> ${product.name} seats`;
        });
        seatsString = productStrings.join(', ').replace(/,(?!.*,)/gim, ' and');
      }
      return prefix + seatsString + suffix;
    },
  },
});
