
















import { Vue } from 'vue-property-decorator';
import Button from '@/components/common/buttons/Button.vue';
export default Vue.extend({
  components: {
    Button,
  },
  props: {
    appRoute: String,
  },
});
