















import { Component, Vue } from 'vue-property-decorator';
import { Getter, namespace } from 'vuex-class';

import { getTotalPrice } from '@/utils/ecomm';

import type { PlansData } from '@/types/ecomm';
import type { SubscriptionData } from '@/utils/ecomm';
import { AccountState } from '@/types/account/AccountState';

const EcommStore = namespace('EcommStore');

@Component({
  name: 'CurrentSubscriptionPriceCard',
})
export default class CurrentSubscriptionPriceCard extends Vue {
  @Getter currentSubscriptionData!: SubscriptionData;
  @Getter('getAccount') account!: AccountState;
  @EcommStore.Getter plans!: PlansData;

  get totalCost() {
    if (!this.plans) return '';
    return getTotalPrice(this.plans, this.currentSubscriptionData);
  }

  get renewalDate() {
    const currentPeriodEndsAt = this.account.data?.subscription.currentPeriodEndsAt;
    const expiresAt = this.account.data?.subscription.expiresAt;

    if (expiresAt) {
      const date = new Date(expiresAt).toLocaleDateString(undefined, {
        month: 'long',
        day: 'numeric',
      });
      return `Contract expires on ${date}`;
    }
    if (currentPeriodEndsAt) {
      const date = new Date(currentPeriodEndsAt).toLocaleDateString(undefined, {
        month: 'long',
        day: 'numeric',
      });
      return `Contract renews monthly on ${date}`;
    }
    return 'Unknown Date';
  }
}
