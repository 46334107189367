<template>
  <div class="create-welcome-banner" v-if="isWelcomeBannerVisible">
    <div class="close-welcome-banner">
      <i class="material-icons" @click="onClose" :data-qa="$getComponent('WelcomeBanner', 'Icon', 'closeButton')"
        >close</i
      >
    </div>
    <div class="welcome-box">
      <div class="welcome-text">
        <h1 class="heading-h1 welcome-text">Welcome to Create&Publish!</h1>
      </div>
      <div class="welcome-content">
        <div class="settings-wrapper">
          <SettingsCard
            title="Publishing Settings"
            icon="settings"
            iconType="md"
            :data-qa="$getComponent('WelcomeBanner', 'Card', 'settings')"
          />
        </div>
        <div class="knowledgebase-wrapper">
          <KnowledgeBase
            title="Knowledgebase"
            icon="info"
            iconType="md"
            :data-qa="$getComponent('WelcomeBanner', 'Card', 'knowledgebase')"
          >
            <div class="card-content" slot="content">
              <h6>Create&Publish 101</h6>
              <div class="text">
                Visit our knowledgebase to get answers to all your questions. Need more help?
                <router-link to="#" class="link" :data-qa="$getComponent('WelcomeBanner', 'Link', 'ContactUs')">
                  Contact us</router-link
                >
              </div>
              <Button :disabled="false" buttonType="secondary">
                <router-link to="/create-faqs" :data-qa="$getComponent('WelcomeBanner', 'Link', 'viewFAQ')"
                  >View FAQs</router-link
                >
              </Button>
            </div>
          </KnowledgeBase>
        </div>
        <div class="knowledgebase-wrapper">
          <KnowledgeBase
            title="Your Account"
            icon="person"
            iconType="md"
            :data-qa="$getComponent('WelcomeBanner', 'Card', 'yourAccount')"
          >
            <div class="card-content" slot="content">
              <h6>Manage your account</h6>
              <div class="text">
                Set up brand campaigns, invite more users, and manage your billing & subscription info from our secure
                account portal.
              </div>
              <Button :disabled="false" buttonType="secondary">
                <router-link to="/" :data-qa="$getComponent('WelcomeBanner', 'Link', 'yourAccount')"
                  >Visit your account overview</router-link
                >
              </Button>
            </div>
          </KnowledgeBase>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import SettingsCard from '@/createandpublish/SettingsCard.vue';
import KnowledgeBase from '@/createandpublish/KnowledgeBaseCard.vue';
import Button from '@/components/common/buttons/Button.vue';

export default {
  name: 'WelcomeBanner',

  components: {
    SettingsCard,
    KnowledgeBase,
    Button,
  },

  computed: {
    ...mapGetters('CreateAndPublishStore', ['isWelcomeBannerVisible']),
  },

  methods: {
    onClose() {
      this.$store.commit('CreateAndPublishStore/TOGGLE_WELCOME_BANNER', false);
    },
  },

  props: {
    isVisible: {
      type: Boolean,
    },
  },
};
</script>

<style scoped lang="scss">
template {
  width: 100%;
}

.create-welcome-banner {
  background-color: var(--neutral-grey-4-light-bg);
  display: flex;
  padding: 3em;
  position: relative;
  width: 100%;

  .knowledgebase-wrapper {
    .content {
      height: 310px;
    }
  }
}
.welcome-text {
  margin-bottom: 2rem;
}
.welcome-banner-closed {
  display: none;
}

.close-welcome-banner {
  cursor: pointer;
  font-size: 16px;
  position: absolute;
  right: 48px;
  top: 20px;
}

.welcome-content {
  align-items: stretch;
  display: flex;
  justify-content: space-evenly;

  h6 {
    color: var(--neutral-grey-1-text);
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 38px;
    margin-bottom: 15px;
  }

  .text {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 24px;

    a {
      color: var(--accent-links);
      text-decoration: underline;
    }
  }

  .custom-button a {
    color: var(--accent-links);
    line-height: normal;
  }

  .knowledgebase-wrapper {
    display: flex;
    margin-right: 25px;
    max-width: 25%;
  }

  .settings-wrapper {
    align-items: stretch;
    display: flex;
    margin-right: 25px;
    max-width: 50%;
  }

  .knowledgebase-wrapper:last-child,
  .settings-wrapper:last-child {
    margin-right: 0;
  }
}
.card-content {
  margin-bottom: 1.5rem;
}
.content {
  margin-bottom: 2rem;
}
</style>
