






























import { Component, Vue } from 'vue-property-decorator';

import YouTubeIcon from '@/audience/components/social/YouTubeIcon.vue';
import InstagramIcon from '@/audience/components/social/InstagramIcon.vue';
import FacebookIcon from '@/audience/components/social/FacebookIcon.vue';
import TwitterIcon from '@/audience/components/social/TwitterIcon.vue';
import TikTokIcon from '@/audience/components/social/TikTokIcon.vue';
import LinkedInIcon from '@/audience/components/social/LinkedInIcon.vue';
import PinterestIcon from '@/audience/components/social/PinterestIcon.vue';

import DashboardIcon from '@/audience/components/nav-icons/Dashboard.vue';
import LeaderboardIcon from '@/audience/components/nav-icons/Leaderboard.vue';
import CommunityIcon from '@/audience/components/nav-icons/Community.vue';
import AnalyticsIcon from '@/audience/components/nav-icons/Analytics.vue';
import SettingsIcon from '@/audience/components/nav-icons/Settings.vue';

import { PlatformLabels } from '@/audience/data/socialPlatformChartConfig';

const nonPlatformPages = {
  DASHBOARD: 'Dashboard',
  CONNECT: 'Connect social account',
  LEADERBOARD: 'Leaderboard',
  COMMUNITY: 'Creator Community',
  SETTINGS: 'Settings',
};

@Component({
  name: 'AudienceMobileNav',
})
export default class AudienceMobileNav extends Vue {
  get platformLabels() {
    return Object.values(PlatformLabels);
  }

  getPlatformComponent(platform: string) {
    switch (platform) {
      case PlatformLabels.FACEBOOK:
        return FacebookIcon;
      case PlatformLabels.INSTAGRAM:
        return InstagramIcon;
      case PlatformLabels.LINKEDIN:
        return LinkedInIcon;
      case PlatformLabels.PINTEREST:
        return PinterestIcon;
      case PlatformLabels.TIKTOK:
        return TikTokIcon;
      case PlatformLabels.TWITTER:
        return TwitterIcon;
      case PlatformLabels.YOUTUBE:
        return YouTubeIcon;
      default:
        return null;
    }
  }

  get iconMeta() {
    const path = this.$route.path;
    return {
      [PlatformLabels.FACEBOOK]: {
        width: 12.1,
        height: 22,
        isActive: path.includes(PlatformLabels.FACEBOOK.toLowerCase()),
      },
      [PlatformLabels.INSTAGRAM]: {
        width: 19.23,
        height: 20,
        isActive: path.includes(PlatformLabels.INSTAGRAM.toLowerCase()),
      },
      [PlatformLabels.LINKEDIN]: {
        width: 20,
        height: 20,
        isActive: path.includes(PlatformLabels.LINKEDIN.toLowerCase()),
      },
      [PlatformLabels.PINTEREST]: {
        width: 20,
        height: 20,
        isActive: path.includes(PlatformLabels.PINTEREST.toLowerCase()),
      },
      [PlatformLabels.TIKTOK]: {
        width: 17.38,
        height: 20,
        isActive: path.includes(PlatformLabels.TIKTOK.toLowerCase()),
      },
      [PlatformLabels.TWITTER]: {
        width: 20,
        height: 16.26,
        isActive: path.includes(PlatformLabels.TWITTER.toLowerCase()),
      },
      [PlatformLabels.YOUTUBE]: {
        width: 20,
        height: 14,
        isActive: path.includes(PlatformLabels.YOUTUBE.toLowerCase()),
      },
    };
  }

  get nonPlatformPages() {
    return Object.values(nonPlatformPages).filter((page) => {
      // Removed pages not currently used
      return ![nonPlatformPages.CONNECT, nonPlatformPages.SETTINGS].includes(page);
    });
  }

  getPageIcon(page: string) {
    switch (page) {
      case nonPlatformPages.DASHBOARD:
        return DashboardIcon;
      case nonPlatformPages.CONNECT:
        return AnalyticsIcon;
      case nonPlatformPages.LEADERBOARD:
        return LeaderboardIcon;
      case nonPlatformPages.COMMUNITY:
        return CommunityIcon;
      case nonPlatformPages.SETTINGS:
        return SettingsIcon;
      default:
        return null;
    }
  }

  get nonPlatformPagePaths() {
    const pathPrefix = `/audience/${this.$route.params.brandId}`;
    return {
      [nonPlatformPages.DASHBOARD]: `${pathPrefix}`,
      [nonPlatformPages.CONNECT]: '#!',
      [nonPlatformPages.LEADERBOARD]: `${pathPrefix}/leaderboard`,
      [nonPlatformPages.COMMUNITY]: `${pathPrefix}/community`,
      [nonPlatformPages.SETTINGS]: `${pathPrefix}/settings`,
    };
  }
}
