












import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class TableInfo extends Vue {
  @Prop({ required: false }) private itemCountString?: string;
  @Prop({ required: false }) private actionLink?: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onClick?: (e: PointerEvent) => void;
    text?: string;
  };
}
