<template>
  <div class="publishing-destinations" role="tabpanel" aria-labelledby="show_publish">
    <header>
      <fm-text
        ref="firstHeader"
        tabindex="-1"
        id="show_publish"
        class="section-title no-outline sr-only"
        tag="h2"
        as="h3"
      >
        Publishing Destinations
      </fm-text>
      <fm-text>
        Enable publishing destinations here to make them available when you create new episodes of this show.
      </fm-text>
    </header>

    <div class="publishing-destinations__container">
      <section class="publishing-destinations__card">
        <div class="publishing-destinations__card__header">
          <header>
            <fm-text tag="h3" as="h5">Publish to RSS Feed</fm-text>
            <fm-link class="header-link" @click="addRSSFeed">
              <i class="material-icons link-icon" aria-hidden="true">add</i>
              <span class="link-text">Create custom feed</span>
            </fm-link>
          </header>
          <fm-text variant="disclaimer">
            Publish to RSS feeds preconfigured for popular services, or create your own custom RSS feed.
          </fm-text>
        </div>
        <div class="publishing-destinations__card__content">
          <div class="publishing-destinations__rss-feed" v-for="feed in rssFeeds" :key="feed.id">
            <base-checkbox
              :id="generateCheckId(feed.id)"
              :label="feed.label"
              v-model="selectedIntegrations"
              :input-value="`${feed.id}`"
            >
              <template #label>
                <label class="rss-feed__label" :for="generateCheckId(feed.id)">
                  <img class="publishing-destinations__rss-feed__icon" :src="feed.icon" />
                  <fm-text class="rss-feed__label-text">{{ feed.label }}</fm-text>
                </label>
              </template>
            </base-checkbox>
            <span class="card-item-actions">
              <button class="manage-smc-button" aria-label="Get RSS URL" title="Get RSS URL" @click="onGetRssUrl(feed)">
                <i class="material-icons">link</i>
              </button>
              <button class="manage-smc-button" aria-label="Edit RSS" title="Edit RSS" @click="editRSSFeed(feed)">
                <i class="material-icons">edit</i>
              </button>
              <button
                class="manage-smc-button"
                aria-label="Get RSS URL"
                title="Get RSS URL"
                @click="toggleDeleteSmc('rss', feed)"
              >
                <i class="material-icons">close</i>
              </button>
            </span>
          </div>
        </div>
      </section>

      <section class="publishing-destinations__card">
        <div class="publishing-destinations__card__header">
          <header>
            <fm-text tag="h3" as="h5">Publish To Web Widget</fm-text>
            <fm-link class="header-link" @click="addWebWidget()">
              <i class="material-icons link-icon" aria-hidden="true">add</i>
              <span class="link-text">Create new widget</span>
            </fm-link>
          </header>
          <fm-text variant="disclaimer">
            Create and customize embeddable player widgets so your audience can listen to your content directly from
            your website. <router-link to="#">Learn More</router-link>
          </fm-text>
        </div>

        <div class="publishing-destinations__card__content">
          <div class="publishing-destinations__item" v-for="widget in getSmcsByType('webwidget')" :key="widget.id">
            <base-checkbox
              :id="generateCheckId(widget.id)"
              :label="widget.title"
              v-model="selectedIntegrations"
              :input-value="`${widget.id}`"
            >
            </base-checkbox>
            <span class="card-item-actions">
              <button
                class="manage-smc-button"
                aria-label="Get embed code"
                title="Get embed code"
                @click="onGetEmbedCode(widget)"
              >
                <i class="material-icons">code</i>
              </button>
              <button
                class="manage-smc-button"
                aria-label="Edit web widget"
                title="Edit web widget"
                @click="onEditWebWidget(widget)"
              >
                <i class="material-icons">edit</i>
              </button>
              <button
                class="manage-smc-button"
                aria-label="Delete web widget"
                title="Delete web widget"
                @click="toggleDeleteSmc('webwidget', widget)"
              >
                <i class="material-icons">close</i>
              </button>
            </span>
          </div>
        </div>
      </section>

      <section class="publishing-destinations__card">
        <div class="publishing-destinations__card__header">
          <header>
            <fm-text tag="h3" as="h5">Publish to YouTube</fm-text>

            <router-link :to="{ path: '/integrations' }" v-slot="{ href }" custom>
              <fm-link
                class="header-link"
                :href="href"
                external
                @click="confirmLeaveRoute(`/create/${selectedBrand.id}/settings`, $event)"
              >
                <i class="material-icons link-icon" aria-hidden="true">settings</i>
                <span class="link-text">Manage Integrations</span>
              </fm-link>
            </router-link>
          </header>

          <fm-text variant="disclaimer">
            Create and customize embeddable player widgets so your audience can listen to your content directly from
            your website. <router-link to="#">Learn More</router-link>
          </fm-text>
        </div>

        <div class="publishing-destinations__card__content">
          <div class="publishing-destinations__item" v-for="smc in getSmcsByType('v_youtube')" :key="smc.id">
            <base-checkbox
              :id="generateCheckId(smc.id)"
              :label="smc.title"
              v-model="selectedIntegrations"
              :input-value="`${smc.id}`"
            >
            </base-checkbox>
            <span class="card-item-actions">
              <button
                class="manage-smc-button"
                aria-label="Edit YouTube connection"
                title="Edit YouTube connection"
                @click="onEditYouTubeConnection(smc)"
              >
                <i class="material-icons">edit</i>
              </button>
              <button
                class="manage-smc-button"
                aria-label="Delete YouTube connection"
                title="Delete YouTube connection"
                @click="toggleDeleteSmc('v_youtube', smc)"
              >
                <i class="material-icons">close</i>
              </button>
            </span>
          </div>
        </div>
      </section>
    </div>

    <AddRSSFeedConnectionDrawer v-if="isRSSDrawerOpen" :editingSMC="editingSMC" @onClose="onCloseNewConnectionDrawer" />
    <RssUrlModal v-if="rssUrlModal.isOpen" :smc="rssUrlModal.smc" @close="onCloseRssUrlModal" />

    <AddWidgetConnectionDrawer
      v-if="addingWebWidgetConnection"
      :editingSMC="editingSMC"
      @onClose="onCloseNewConnectionDrawer"
    />
    <WidgetConfigPanel v-if="widgetConfigModal.isOpen" :smc="widgetConfigModal.smc" @close="onCloseWidgetConfigModal" />

    <AddYoutubeConnectionDrawer
      v-if="addingYoutubeConnection"
      :editingSMC="editingSMC"
      @onClose="onCloseNewConnectionDrawer"
    />

    <ConfirmationModal
      v-if="isDeleteModalActive"
      :loading="isLoading"
      :contentText="`Are you sure you want to delete ${editingSMC.title}?`"
      @close="isDeleteModalActive = false"
      @confirm="confirmDeleteSmc"
    />
    <confirm-modal-v3
      v-if="confirmModal.isOpen"
      :confirmText="confirmModal.confirmText"
      :contentText="confirmModal.contentText"
      @close="onCancelConfirmModal"
      @confirm="onConfirmConfirmModal"
    />
  </div>
</template>

<script>
import { createNamespacedHelpers, mapGetters as mapRootGetters } from 'vuex';
const { mapGetters, mapState } = createNamespacedHelpers('CreateAndPublishStore');
const {
  mapActions: mapActionsShowSetup,
  mapGetters: mapGettersShowSetup,
  mapState: mapStateShowSetup,
} = createNamespacedHelpers('CreateAndPublishStore/showSetup');
// import facebookSVG from '@/assets/createandpublish-assets/episodes/rss-subtypes/facebook.svg';
// import twitterSVG from '@/assets/createandpublish-assets/episodes/rss-subtypes/twitter-blue.svg';
// import youtubeSVG from '@/assets/createandpublish-assets/episodes/rss-subtypes/youtube.svg';
import iTunesSVG from '@/assets/createandpublish-assets/episodes/rss-subtypes/itunes.svg';
import googlePlaySVG from '@/assets/createandpublish-assets/episodes/rss-subtypes/google-play.svg';
import spotifySVG from '@/assets/createandpublish-assets/episodes/rss-subtypes/spotify.svg';
import iHeartSVG from '@/assets/createandpublish-assets/episodes/rss-subtypes/iheart.svg';
import stitcherSVG from '@/assets/createandpublish-assets/episodes/rss-subtypes/stitcher.svg';
import tuneInSVG from '@/assets/createandpublish-assets/episodes/rss-subtypes/tune-in.svg';
import radioComSVG from '@/assets/createandpublish-assets/episodes/rss-subtypes/radio-com.svg';
import pocketcastsSVG from '@/assets/createandpublish-assets/episodes/rss-subtypes/pocketcasts.svg';
import pandoraSVG from '@/assets/createandpublish-assets/episodes/rss-subtypes/pandora.svg';
import amazonEchoSVG from '@/assets/createandpublish-assets/episodes/rss-subtypes/amazon-echo.svg';
import googleActionsSVG from '@/assets/createandpublish-assets/episodes/rss-subtypes/google-actions.svg';
import wordpressSVG from '@/assets/createandpublish-assets/episodes/rss-subtypes/wordpress.svg';
import deezerSVG from '@/assets/createandpublish-assets/episodes/rss-subtypes/deezer.svg';
import radioPublicSVG from '@/assets/createandpublish-assets/episodes/rss-subtypes/radio-public.svg';
import customRssSVG from '@/assets/createandpublish-assets/episodes/rss-subtypes/custom-rss.svg';
// import megaphoneSVG from '@/assets/createandpublish-assets/episodes/megaphone-logo.png';
import BaseCheckbox from '../../components/BaseCheckbox';
import RssUrlModal from '@/createandpublish/components/integrations/RssUrlModal';
import WidgetConfigPanel from '@/createandpublish/components/integrations/WidgetConfigPanel';
import ConfirmModalV3 from '@/createandpublish/components/ConfirmModalV3';
import AddRSSFeedConnectionDrawer from '@/createandpublish/components/integrations/AddRSSFeedConnectionDrawer.vue';
import AddWidgetConnectionDrawer from '@/createandpublish/components/integrations/AddWidgetConnectionDrawer.vue';
import AddYoutubeConnectionDrawer from '../../components/integrations/AddYoutubeConnectionDrawer.vue';
import ConfirmationModal from '@/createandpublish/components/common/ConfirmationModal.vue';

export default {
  name: 'new-show-publishing-destinations',
  data: () => ({
    selectedIntegrations: [],
    isDeleteModalActive: false,
    isLoading: false,
    rssUrlModal: {
      isOpen: false,
      smc: null,
    },
    widgetConfigModal: {
      isOpen: false,
      smc: null,
    },
    confirmModal: {
      isOpen: false,
      confirmText: 'Save and go',
      contentText:
        'Creating a new integration will navigate you away from this page. Do you want to save your progress before continuing?',
      navigate: '',
    },
    editingSMC: null,
  }),
  computed: {
    ...mapGettersShowSetup(['showSMCs']),
    ...mapStateShowSetup(['draftShow']),
    ...mapState({ stateSocialMediaConnections: 'socialMediaConnections' }),
    ...mapGetters(['features', 'getShowData']),
    ...mapRootGetters(['selectedBrand']),
    socialMediaConnections() {
      return (
        this.stateSocialMediaConnections
          .filter((x) => x.type !== 'rss' || (x.rss_subtype && x.rss_subtype.short_name === 'iheart'))
          .reduce((result, item) => {
            result[item.type] = result[item.type] || [];
            result[item.type].push(item);
            return result;
          }, {}) || {}
      );
    },
    groupedMediaConnections() {
      const res =
        (this.stateSocialMediaConnections &&
          this.stateSocialMediaConnections.reduce((result, item) => {
            result[item.type] = result[item.type] || [];
            result[item.type].push(item);
            return result;
          }, {})) ||
        {};
      res.twitter = (res.twitter && res.twitter.map((x) => ({ ...x, title: `@${x.title}` }))) || [];
      return res;
    },
    rssFeeds() {
      const rssAccounts = this.groupedMediaConnections['rss'];
      return (
        rssAccounts &&
        rssAccounts.map((x) => ({
          label: x.title,
          value: x.id,
          icon: this.getIconForRssFeed(x),
          rssSubtype: x.rss_subtype,
          id: x.id,
        }))
      );
    },
    webWidgets() {
      return this.stateSocialMediaConnections.filter((smc) => smc.type === 'webwidget');
    },
    socialIntegrations() {
      // return [{
      //   key: 'facebook',
      //   icon: facebookSVG,
      //   accounts: this.socialMediaConnections['facebook'] || [],
      //   name: 'Facebook pages'
      // }, {
      //   key: 'twitter',
      //   icon: twitterSVG,
      //   accounts: (this.socialMediaConnections['twitter'] || []).map(x => ({ ...x, title: `@${x.title}` })),
      //   name: 'Twitter accounts'
      // }, ...(this.features.enable_youtube === true ? [{
      //   key: 'v_youtube',
      //   icon: youtubeSVG,
      //   accounts: this.socialMediaConnections['v_youtube'] || [],
      //   name: 'YouTube channels'
      // }] : []), ...(this.features.allow_megaphone === true ? [{
      //   key: 'megaphone',
      //   icon: megaphoneSVG,
      //   accounts: this.socialMediaConnections['megaphone'] || [],
      //   name: 'Megaphone accounts'
      // }] : [])]
      return [];
    },
    isRSSDrawerOpen() {
      return this.$route.query && this.$route.query.newConnection === 'rss';
    },
    addingWebWidgetConnection() {
      if (this.$route.query && this.$route.query.newConnection === 'webwidget') {
        return true;
      }
      return false;
    },
    addingYoutubeConnection() {
      if (this.$route.query && this.$route.query.newConnection === 'v_youtube') {
        return true;
      }
      return false;
    },
  },
  methods: {
    ...mapActionsShowSetup(['updateDraftShow', 'deleteSocialMediaConnection']),
    generateCheckId(id) {
      return `chk_${id}`;
    },

    addRSSFeed() {
      this.$router.replace({ query: { newConnection: 'rss', step: 1 } });
    },

    addWebWidget() {
      this.editingSMC = null;
      this.$router.replace({ query: { newConnection: 'webwidget', step: 1 } });
    },

    getIconForRssFeed({ rss_subtype: { short_name: shortName } = {} }) {
      switch (shortName) {
        case 'itunes':
          return iTunesSVG;
        case 'google_play':
          return googlePlaySVG;
        case 'spotify':
          return spotifySVG;
        case 'iheart':
          return iHeartSVG;
        case 'stitcher':
          return stitcherSVG;
        case 'tunein':
          return tuneInSVG;
        case 'radio_com':
          return radioComSVG;
        case 'pocketcasts':
          return pocketcastsSVG;
        case 'pandora':
          return pandoraSVG;
        case 'alexa_skills':
          return amazonEchoSVG;
        case 'google_actions':
          return googleActionsSVG;
        case 'wp':
          return wordpressSVG;
        case 'deezer':
          return deezerSVG;
        case 'radio_public':
          return radioPublicSVG;
        case 'custom':
        case 'master':
        default:
          return customRssSVG;
      }
    },

    onGetRssUrl(smc) {
      this.rssUrlModal.smc = smc;
      this.rssUrlModal.isOpen = true;
    },

    onCloseRssUrlModal() {
      this.rssUrlModal.isOpen = false;
      this.rssUrlModal.smc = null;
    },

    onGetEmbedCode(smc) {
      this.widgetConfigModal.smc = smc;
      this.widgetConfigModal.isOpen = true;
    },

    onEditWebWidget(smc) {
      this.editingSMC = smc;
      this.$router.replace({ query: { newConnection: 'webwidget', step: 1 } });
    },

    onEditYouTubeConnection(smc) {
      this.editingSMC = smc;
      this.$router.replace({ query: { newConnection: 'v_youtube', step: 2 } });
    },

    onCloseWidgetConfigModal() {
      this.widgetConfigModal.isOpen = false;
      this.widgetConfigModal.smc = null;
    },

    confirmLeaveRoute(navigate, e) {
      e.preventDefault(); // Stop navigation
      this.confirmModal.navigate = navigate;
      this.confirmModal.isOpen = true;
    },

    onCancelConfirmModal() {
      this.confirmModal.isOpen = false;
      this.confirmModal.navigate = '';
    },

    onConfirmConfirmModal() {
      this.confirmModal.isOpen = false;
      this.$emit('saveAndNav', this.confirmModal.navigate);
    },

    onCloseNewConnectionDrawer() {
      this.editingSMC = null;
      this.$router.replace({ query: null });
    },

    getSmc(type, id) {
      return this.groupedMediaConnections[type].find((connection) => connection.id === id);
    },

    getSmcsByType(type) {
      return this.groupedMediaConnections[type];
    },

    editRSSFeed(feed) {
      const smc = this.getSmc('rss', feed.id);
      this.editingSMC = smc;
      this.addRSSFeed();
    },

    toggleDeleteSmc(type, smc) {
      const editingSmc = this.getSmc(type, smc.id);
      this.editingSMC = editingSmc;
      this.isDeleteModalActive = true;
    },

    async confirmDeleteSmc() {
      this.isLoading = true;
      await this.deleteSocialMediaConnection(this.editingSMC);
      this.isLoading = false;
      this.isDeleteModalActive = false;
    },
  },
  watch: {
    selectedIntegrations(newSelectedIntegrations) {
      const draftShowSMCs = this.stateSocialMediaConnections.filter((smc) =>
        newSelectedIntegrations.includes(smc.id.toString())
      );
      this.updateDraftShow({
        social_media_connections: draftShowSMCs,
      });
    },
  },
  async mounted() {
    await window.customElements.whenDefined('fm-text');
    await this.$nextTick();
    this.$refs.firstHeader.focus();

    this.$emit('allowNext');
    this.selectedIntegrations = this.showSMCs && this.showSMCs.map((x) => `${x.id}`);
    if (this.draftShow && this.draftShow.title) {
      document.title =
        `Edit ${this.draftShow.title} | Podcasts | Create&Publish | Futuri Content Cloud` || 'Futuri Content Cloud';
    }
  },
  components: {
    BaseCheckbox,
    RssUrlModal,
    WidgetConfigPanel,
    ConfirmModalV3,
    AddRSSFeedConnectionDrawer,
    AddWidgetConnectionDrawer,
    ConfirmationModal,
    AddYoutubeConnectionDrawer,
  },
};
</script>

<style lang="scss">
@import '../../styles/integrations/integrations.scss';
</style>

<style scoped lang="scss">
.publishing-destinations {
  padding: 16px 0 16px 0;
  border-bottom: 2px solid var(--neutral-grey-3-borders);
  box-sizing: border-box;

  &__container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 2rem;
    @media (min-width: 1200px) {
      flex-wrap: nowrap;
    }
  }

  > header {
    padding-bottom: 24px;
  }

  &__card {
    box-shadow: 0 1px 7px #22204633;
    padding: 22px 0 0 0;
    margin-bottom: 24px;
    flex: 0 1 100%;

    &__header {
      color: var(--neutral-grey-2-lighter-text);
      border-bottom: 1px solid var(--neutral-grey-3-borders);
      padding: 0 16px 16px 16px;
      box-sizing: border-box;

      > header {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        padding: 0 0 16px 0;

        .header-link {
          &::part(anchor) {
            display: flex;
            text-decoration: none;
          }

          .link-icon {
            display: inline-block;
            font-size: 16px;
            text-decoration: none;
            margin-right: 4px;
          }

          .link-text {
            display: inline-block;
            text-decoration: none;
          }
        }
      }
    }

    &__content {
      padding: 16px;
    }

    .form-group.checkbox-group {
      padding-left: 32px;
      margin-bottom: 0;
    }
  }

  &__social-integrations-list__item {
    margin-bottom: 32px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  &__social-integrations-list__item__header {
    display: flex;
    align-items: center;
    margin-bottom: 14px;

    &__icon {
      width: 24px;
      height: 24px;
      margin: 0 16px 0 0;
    }

    .bold-text::part(tag) {
      font-weight: 500;
    }
  }

  &__rss-feed {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 0 8px 0;
    border-bottom: 1px solid var(--neutral-grey-3-borders);

    .base-checkbox {
      width: calc(100% - 144px);
    }

    .rss-feed__label {
      display: flex;
      align-items: center;
      overflow: hidden;

      &-text,
      &-text::part(tag) {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    &__icon {
      width: 18px;
      height: 18px;
      margin: 0 10px 0 0;
      cursor: default;
      user-select: none;
    }

    .base-checkbox {
      flex-shrink: 0;
    }

    &:last-of-type {
      border-bottom: none;
    }

    &:first-of-type {
      padding-top: 0;
    }
  }

  &__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 0 8px 0;
    border-bottom: 1px solid var(--neutral-grey-3-borders);

    .base-checkbox {
      flex-shrink: 0;
      width: calc(100% - 144px);
    }

    &:last-of-type {
      border-bottom: none;
    }

    &:first-of-type {
      padding-top: 0;
    }
  }
}

.card-item-actions {
  display: flex;
  flex: 1 0 auto;
  justify-content: flex-end;

  .manage-smc-button {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    color: var(--accent-links);
    width: 32px;
    height: 32px;
    box-shadow: 0 1px 3px #22204633 !important;
    border-radius: 8px;
    margin: 0 8px;
    user-select: none;
    background: #fff;
    border: 0;

    &:hover {
      box-shadow: 0px 1px 8px #22204633 !important;
    }

    &:focus {
      box-shadow: none;
      outline: 4px solid #00c6c680 !important;
    }

    &:focus:not(.focus-visible) {
      outline: none;
    }

    &:focus-visible {
      box-shadow: none;
      outline: 4px solid #00c6c680 !important;
    }
  }
}

.checkbox-group {
  display: flex;
  flex-direction: column;
}
</style>

<style lang="scss">
.base-checkbox {
  align-items: center;
  label {
    margin: 0;
  }
}
</style>
