<template>
  <div class="new-show__display-details" role="tabpanel" aria-label="2. Add display details">
    <section aria-labelledby="show_images">
      <div>
        <fm-text ref="firstHeader" tabindex="-1" id="show_images" class="section-title no-outline" tag="h2" as="h3">
          Images
        </fm-text>
        <fm-text style="margin-bottom: 18px"
          >These images will be displayed in podcast search listings. Choosing an eye-catching and memorable show logo
          will increase discoverability.</fm-text
        >
        <fm-text tag="p" as="h5">Tip:</fm-text>
        <fm-text
          >Include your show name in the logo. Keep lower third of your image empty for a better mobile
          experience.</fm-text
        >
      </div>
      <div>
        <image-upload-and-preview
          v-model="squareCoverArt"
          title="Square cover art"
          assistive-text="Minimum size 1400 x 1400px. Maximum size 3000 x 3000px."
          width="261px"
          height="261px"
          :acceptNewUploadsOnly="true"
          image-type="square_image"
        />

        <image-upload-and-preview
          v-model="socialShareImage"
          title="Rectangular social share image"
          assistive-text="Recommended size 700 x 366px"
          width="516px"
          height="283px"
          :crop-aspect-ratio="700 / 366"
          :acceptNewUploadsOnly="true"
          image-type="rectangle_image"
        />
      </div>
    </section>

    <section aria-labelledby="show_categories">
      <div>
        <fm-text id="show_categories" tag="h2" as="h3" class="section-title"> Categories </fm-text>
        <fm-text style="margin-bottom: 18px"
          >Choose at least one category to describe the contents of your show. (Three is recommended and subcategories
          are encouraged.) Selecting more categories increases the discoverability of your show.</fm-text
        >
        <fm-text
          >While these categories come from Apple Podcasts, other podcast aggregators use these categories as
          well.</fm-text
        >
      </div>
      <div>
        <div v-for="(categorySelect, i) in categorySelects" :key="categorySelect.key" class="form-group">
          <fm-select
            :options.prop="categoryOptions"
            placeholder="Choose a category"
            :value.prop="categorySelect.category"
            :label="categorySelect.label"
            @input="(e) => updateCategoryByKey(categorySelect.key, e)"
            :error.prop="getErrorMessageForCategorySelect(i)"
          />
          <div
            style="display: flex; align-items: center"
            v-if="categorySelect.category && getSubCategoriesForCategory(categorySelect.category).length"
          >
            <i class="material-icons">subdirectory_arrow_right</i>
            <div class="form-group" style="flex: 1">
              <fm-select
                :options.prop="getSubCategoriesForCategory(categorySelect.category)"
                placeholder="Category name"
                :value.prop="categorySelect.subCategory"
                :label="categorySelect.subCategoryLabel"
                @input="(e) => updateSubCategoryByKey(categorySelect.key, e)"
              />
            </div>
          </div>
        </div>
      </div>
    </section>

    <section ref="tags" aria-labelledby="show_tags" style="padding-bottom: 24px">
      <div>
        <fm-text id="show_tags" tag="h2" as="h3" class="section-title"> Tags </fm-text>
        <fm-text
          >Help listeners find your content on social media faster by adding tags to your show. These will be be applied
          to each episode of this show, but they can be overridden when you create a new episodes.
        </fm-text>
      </div>
      <div>
        <div class="form-group">
          <fm-multiselect :options.prop="tagOptions" v-model-wc="tags" label="Tags (optional)" />
        </div>
      </div>
    </section>

    <section aria-labelledby="show_rating" style="padding-bottom: 24px">
      <div>
        <fm-text id="show_rating" tag="h2" as="h3" class="section-title"> Content Rating </fm-text>
        <fm-text
          >Indicate whether or not your show contains explicit content. This will also be the default value for episodes
          of this show, but it can be overridden when you create a new episode.</fm-text
        >
      </div>
      <div>
        <div class="form-group">
          <fm-select :options.prop="explicitSelectOptions" v-model-wc="isExplicit" label="Rating" />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import categories from '@/createandpublish/core/data/iTunesCategories';
import ImageUploadAndPreview from '@/createandpublish/components/ImageUploadAndPreview';
import { createNamespacedHelpers } from 'vuex';
const { mapGetters, mapActions } = createNamespacedHelpers('CreateAndPublishStore');
const { mapActions: mapActionsShowSetup, mapState: mapStateShowSetup } = createNamespacedHelpers(
  'CreateAndPublishStore/showSetup'
);
import { required } from 'vuelidate/lib/validators';

export default {
  data: () => ({
    squareCoverArt: {
      url: '',
    },
    socialShareImage: {
      url: '',
    },
    isExplicit: false,
    tags: [],
    categorySelects: [
      {
        key: 'primaryCategory',
        label: 'Main category',
        category: null,
        subCategoryLabel: 'Main subcategory (optional)',
        subCategory: null,
        required: true,
      },
      {
        key: 'secondaryCategory',
        label: 'Category 2 (optional)',
        category: null,
        subCategoryLabel: 'Subcategory 2 (optional)',
        subCategory: null,
      },
      {
        key: 'tertiaryCategory',
        label: 'Category 3 (optional)',
        category: null,
        subCategoryLabel: 'Subcategory 3 (optional)',
        subCategory: null,
      },
    ],
    explicitSelectOptions: [
      { value: false, label: 'Clean' },
      { value: true, label: 'Explicit' },
    ],
    validationError: '',
    loading: false,
    status: undefined,
    isTagModalActive: false,
    newTagName: '',
    tag: {},
  }),

  validations: {
    squareCoverArt: {
      url: { required },
    },
    socialShareImage: {
      url: { required },
    },
    categorySelects: {
      0: {
        category: { required },
      },
    },
  },

  computed: {
    ...mapStateShowSetup(['draftShow']),
    ...mapGetters(['playlistCategories']),

    categoryOptions() {
      return Object.keys(categories).map((category) => ({
        value: category,
        label: category,
      }));
    },

    tagOptions() {
      return this.playlistCategories.map((tag) => ({
        label: tag.name,
        value: tag.resource_uri,
      }));
    },
  },

  methods: {
    ...mapActions(['getPlaylistCategories']),
    ...mapActionsShowSetup(['updateDraftShow']),

    updateCategoryByKey(key, { target: { value } }) {
      const target = this.categorySelects.find((x) => x.key === key);
      target.category = value;

      // Reset the subCategory on update
      target.subCategory = null;

      this.updateDraftShow({ itunes_categories: this.processCategorySelections() });
    },

    updateSubCategoryByKey(key, { target: { value } }) {
      const target = this.categorySelects.find((x) => x.key === key);
      target.subCategory = value;
      this.updateDraftShow({ itunes_categories: this.processCategorySelections() });
    },

    getSubCategoriesForCategory(category) {
      return categories[category].map((subCategory) => ({
        value: subCategory,
        label: subCategory,
      }));
    },

    processCategorySelections() {
      return this.categorySelects
        .filter(({ category }) => category)
        .map(({ category, subCategory }) => category && `${category}${(subCategory && `:${subCategory}`) || ''}`)
        .join(', ');
    },

    getErrorMessageForCategorySelect(index) {
      const target = this.$v.categorySelects[index];
      if (!target || !target.category.$error) return '';
      if (!target.category.required) return 'You must select this category';
    },

    onClose() {
      this.tag = {};
      this.isTagModalActive = false;
      this.validationError = '';
      this.status = '';
      this.loading = false;
      this.newTagName = '';
      this.status = undefined;
    },

    async onConfirm() {
      // Validate
      if (!this.newTagName) {
        this.validationError = 'Tag name cannot be empty';
      } else {
        // Add
        const _newTag = {
          name: this.newTagName,
        };
        this.loading = true;
        const response = await this.createPlaylistCategory(_newTag);
        await this.getPlaylistCategories();
        this.tags = [...this.tags, response.resource_uri]; // Add to selected tags on the fly
        this.loading = false;
        this.status = 'success';
        setTimeout(() => {
          this.onClose();
        }, 1000);
      }
    },
  },

  watch: {
    'squareCoverArt.url'(newUrl) {
      this.updateDraftShow({ square_image: newUrl });
    },

    'socialShareImage.url'(newUrl) {
      this.updateDraftShow({ rectangle_image: newUrl });
    },

    isExplicit(isExplicit) {
      this.updateDraftShow({ rss_explicit: isExplicit });
    },

    tags(tags) {
      this.updateDraftShow({ playlist_categories: [...tags] });
    },

    '$v.$invalid'(newVal) {
      if (newVal) {
        this.$emit('preventNext');
      } else {
        this.$emit('allowNext');
      }
    },
  },

  async mounted() {
    await window.customElements.whenDefined('fm-text');
    await this.$nextTick();
    this.$refs.firstHeader.focus();

    const { itunes_categories: itunesCategories, rss_explicit: isExplicit, playlist_categories: tags } = this.draftShow;
    itunesCategories
      .split(', ')
      .slice(0, 3)
      .forEach((category, i) => {
        const [main, subCategory] = category.split(':');
        this.categorySelects[i].category = main;
        this.categorySelects[i].subCategory = subCategory;
      });
    this.squareCoverArt.url = this.draftShow.square_image;
    this.socialShareImage.url = this.draftShow.rectangle_image;
    this.isExplicit = isExplicit;
    this.tags = [...tags];

    if (this.draftShow && this.draftShow.title) {
      document.title =
        `Edit ${this.draftShow.title} | Podcasts | Create&Publish | Futuri Content Cloud` || 'Futuri Content Cloud';
    }

    if (this.$v.$invalid || this.$v.$error) {
      this.$emit('preventNext');
    }

    if (this.$route.query.anchor) {
      setTimeout(() => {
        this.$refs[this.$route.query.anchor].scrollIntoView({ behavior: 'smooth' });
      }, 1500);
    }
  },

  components: {
    ImageUploadAndPreview,
  },
};
</script>

<style scoped lang="scss">
.new-show {
  &__display-details {
    > section {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid var(--neutral-grey-3-borders);
      padding: 24px 0 0 0;

      .section-title {
        margin-bottom: 8px;

        &::part(tag) {
          font-weight: 500;
        }
      }

      > div {
        width: 45%;
      }
    }
  }
}

h1,
h2,
h3,
h4,
h5 {
  font-weight: bold;
}

.tip-title {
  letter-spacing: 2px;
  font-family: Roboto;
  font-weight: bold;
  text-transform: uppercase;
  color: #6e6e7a;
  font-size: 1em;
}

.mt-4 {
  margin-top: 1rem;
}
</style>
