






import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
  name: 'PlayButton',
})
export default class PlayButton extends Vue {
  @Prop({ type: Boolean, required: true }) readonly isPlaying!: boolean;
  @Prop({ type: String, default: '' }) readonly mediaTitle!: string;

  get buttonIcon() {
    return this.isPlaying ? 'pause_circle' : 'play_circle';
  }
  get ariaLabelText() {
    const title = this.mediaTitle || '';
    return this.isPlaying ? `Pause ${title}` : `Play ${title}`;
  }
}
