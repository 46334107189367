<template>
  <section class="standard-image-selector-panel" role="tabpanel" aria-labelledby="standard-image-header">
    <div>
      <!-- Content: Header -->
      <fm-text tag="h1" as="h2" id="standard-image-header" style="margin-bottom: 24px" ref="firstHeader" tabindex="-1">
        Upload file or choose from library
      </fm-text>
      <div class="header">
        <fm-file
          class="file-input"
          accept="image/*"
          @input="handleImageFileInputChanged"
          placeholder="Upload an image"
        />

        <fm-text v-if="!isLoadingExistingImages && images.length > 0" class="choose-other-text" variant="large">
          ... Or select a previously uploaded image below
        </fm-text>
      </div>
      <!-- ./ Content: Header -->

      <!-- Content: Uploading Screen / Image Grid -->
      <template v-if="isUploading">
        <div class="uploading-screen">
          <div>
            <fm-text tag="h2">Uploading {{ getFileName(uploading.file) }}...</fm-text>
            <fm-text tag="h3">Progress: {{ uploadProgressPercentage }}%</fm-text>
          </div>
        </div>
      </template>
      <template v-else-if="isLoadingExistingImages">
        <div class="loading-screen">
          <fm-text tag="h2">Loading existing images...</fm-text>
        </div>
      </template>
      <template v-else>
        <div v-if="images.length > 0" class="image-grid sb-thin">
          <template v-for="image in images">
            <div class="item" :key="image" :class="getImageItemClassList(image)" @click="handleImageItemClick(image)">
              <div class="item-container">
                <image-item class="image" :source="getImageUrl(image)" />
                <div
                  class="delete-item__container"
                  v-show="deleteMode.active"
                  @click.stop="toggleSelectionForDeletion(image)"
                >
                  <div class="delete-item">
                    <base-checkbox
                      class="delete-checkbox"
                      :id="image"
                      ref="delete-checkboxes"
                      :inputValue="image"
                      v-model="deleteMode.selectedImages"
                    />
                  </div>
                </div>
              </div>
            </div>
          </template>
        </div>
        <div v-else class="image-grid--no-images">
          <fm-text tag="h2"> No existing images. </fm-text>
        </div> </template
      ><!-- ./ Content: Uploading Screen / Image Grid -->
    </div>

    <div class="panel-actions">
      <div>
        <div v-if="!deleteMode.active">
          <fm-button-secondary @click="deleteMode.active = true"> Delete Images </fm-button-secondary>
        </div>
        <div v-if="deleteMode.active">
          <fm-button-tertiary style="margin-right: 16px" @click="selectAllForDeletion"> Select All </fm-button-tertiary>
          <fm-button-tertiary style="margin-right: 16px" @click="exitDeleteMode"> Cancel </fm-button-tertiary>
          <fm-button-secondary
            style="margin-left: 16px"
            @click="deleteMode.confirmModal.isOpen = true"
            :disabled="deleteMode.selectedImages.length < 1"
          >
            Delete Selected Images
          </fm-button-secondary>
        </div>
      </div>

      <div>
        <fm-text variant="disclaimer" v-if="!useMinMaxRecommendedSize">Recommended Image Size: 700 x 366</fm-text>
        <fm-text variant="disclaimer" v-else
          >Recommended Image Size: {{ minWidth }} x {{ minHeight }} to {{ maxWidth }} x {{ maxHeight }}</fm-text
        >
      </div>

      <div>
        <fm-button-primary :disabled="isSelectButtonDisabled" @click="handleApplyButton"> Select </fm-button-primary>
      </div>
    </div>

    <confirm-modal-v3
      v-if="deleteMode.confirmModal.isOpen"
      :confirmText="deleteMode.confirmModal.confirmText"
      :contentText="deleteMode.confirmModal.contentText"
      :useContentSlot="true"
      @close="exitDeleteMode"
      @confirm="onDeleteConfirmClicked"
    >
      <div class="confirm-modal-content">
        <fm-text><strong>Deleting the selected images will remove them permanently.</strong></fm-text>
        <fm-text>Note that images used as default show images will not be deleted.</fm-text>
        <fm-text>
          Additionally, any episodes that reference a deleted image will be updated to reference the owning show's
          default image.
        </fm-text>
      </div>
    </confirm-modal-v3>
  </section>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
const { mapActions, mapGetters } = createNamespacedHelpers('CreateAndPublishStore');
import CreateAndPublishService from '@/services/CreateAndPublishService';

import ImageItem from './ImageItem';
import ConfirmModalV3 from './ConfirmModalV3';
import BaseCheckbox from '@/createandpublish/components/BaseCheckbox';

export default {
  name: 'standard-image-selector-panel',

  components: {
    ImageItem,
    ConfirmModalV3,
    BaseCheckbox,
  },

  props: {
    titleOverride: String,
    useMinMaxRecommendedSize: Boolean,
    minWidth: Number,
    minHeight: Number,
    maxWidth: Number,
    maxHeight: Number,
  },

  data() {
    return {
      isLoadingExistingImages: true,
      images: [],

      uploading: {
        isInProgress: false,
        file: null,
        event: null,
      },

      selectedImage: null,

      deleteMode: {
        active: false,
        selectedImages: [],
        confirmModal: {
          isOpen: false,
          confirmText: 'Confirm Delete',
        },
      },
    };
  },

  computed: {
    ...mapGetters({
      existingImageFilenames: 'existing_images',
      currentStation: 'station',
      mediaUrl: 'mediaUrl',
      features: 'features',
    }),

    isUploading() {
      return this.uploading.isInProgress;
    },

    uploadProgressPercentage() {
      const { event } = this.uploading;
      if (event && event.lengthComputable) {
        return parseInt((event.loaded / event.total) * 100);
      }
      return undefined;
    },

    isSelectButtonDisabled() {
      if (this.deleteMode.active) return true;
      if (!this.selectedImage) return true;
      return false;
    },
  },

  methods: {
    ...mapActions(['getExistingImages', 'deleteImages']),

    /**
     * Event handlers
     */

    handleApplyButton() {
      this.applySelectedImage();
    },

    handleImageItemClick(imageFilename) {
      this.setSelectedImage(imageFilename);
    },

    handleImageFileInputChanged({ target }) {
      this.uploading.isInProgress = true;
      const { value: files } = target;
      this.uploadImageFile(files[0]);
    },

    /**
     * Local actions
     */

    setSelectedImage(selectedImageFilename) {
      this.selectedImage = selectedImageFilename;
    },

    applySelectedImage() {
      if (!this.selectedImage) {
        return alert('Please select an image.');
      }
      this.$emit('image:selected', this.getImageUrl(this.selectedImage));
    },

    updateImageList(images) {
      this.images = images;
      this.isLoadingExistingImages = false;
    },

    async uploadImageFile(file) {
      this.uploading.file = file;

      const formData = new FormData();
      formData.append('file', file);

      try {
        const response = await new CreateAndPublishService().uploadImage(formData, 'rectangle_image');
        const url = response.location; // This is a URL of the image
        this.$emit('image:selected', url);
      } catch (e) {
        console.error(e);
        this.$store.commit('SET_MESSAGE', {
          name: 'Image upload',
          details: 'Unable to upload image',
          type: 'error',
        });
      } finally {
        this.resetUploadingProperties();
      }
    },

    resetUploadingProperties() {
      this.uploading.isInProgress = false;
      this.uploading.file = null;
      this.uploading.event = null;
    },

    /**
     * Delete Image Related
     */

    exitDeleteMode() {
      this.deleteMode.confirmModal.isOpen = false;
      this.deleteMode.active = false;
      this.deleteMode.selectedImages = [];
    },

    selectAllForDeletion() {
      const els = this.$refs['delete-checkboxes'];
      els.forEach((el) => {
        if (!this.deleteMode.selectedImages.includes(el.inputValue)) {
          this.deleteMode.selectedImages.push(el.inputValue);
        }
      });
    },

    toggleSelectionForDeletion(fileName) {
      const index = this.deleteMode.selectedImages.findIndex((image) => image === fileName);
      if (index === -1) {
        this.deleteMode.selectedImages.push(fileName);
      } else {
        this.deleteMode.selectedImages.splice(index, 1);
      }
    },

    async onDeleteConfirmClicked() {
      try {
        await this.deleteImages(this.deleteMode.selectedImages);

        this.$store.commit('SET_MESSAGE', {
          name: 'Delete Images',
          details: 'Successfully deleted selected images',
          type: 'success',
        });
        setTimeout(() => {
          this.$store.commit('DISMISS_MESSAGE');
        }, 3000);
        this.exitDeleteMode();
      } catch (err) {
        console.log(err);
      }
    },

    /**
     * Other
     */

    getFileName(file) {
      return file.name;
    },

    getImageUrl(imageFilename) {
      return '/playlist/streamon-perm/' + imageFilename;
    },

    getImageItemClassList(imageFilename) {
      return {
        selected: this.selectedImage === imageFilename,
      };
    },
  },

  /**
   * Watchers
   */

  watch: {
    existingImageFilenames(newValue) {
      if (newValue && this.isLoadingExistingImages) {
        this.updateImageList(newValue);
      }
    },
  },

  /**
   * Lifecycle
   */

  async mounted() {
    await this.$nextTick();
    this.$refs.firstHeader && this.$refs.firstHeader.focus();
    this.getExistingImages();
  },
};
</script>

<style lang="scss" scoped>
.standard-image-selector-panel {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  .header {
    display: flex;
    align-items: center;

    .file-input {
      width: 500px;
      margin-right: 48px;
    }

    .choose-other-text {
      transform: translateY(-8px);
    }
  }

  .loading-screen,
  .uploading-screen,
  .image-grid--no-images {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-direction: column;
  }

  .image-grid {
    display: flex;
    flex-wrap: wrap;
    height: 350px;
    overflow-y: auto;
    padding-right: 16px;

    .item {
      flex-basis: 25%;
      box-sizing: border-box;

      &:hover {
        .item-container {
          .image {
            filter: grayscale(50%);
          }
        }
      }

      &.selected {
        .item-container {
          .image {
            box-shadow: 0 0 0 5px var(--brand-light);
          }
        }
      }

      .item-container {
        margin: 10px;
        position: relative;

        .delete-item__container {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          background-color: transparent;
          z-index: 5;

          .delete-item {
            position: absolute;
            right: 12px;
            top: 12px;
            z-index: 10;

            .delete-checkbox::v-deep {
              padding: 0;

              .base-checkbox__input {
                margin-right: 0;
              }

              .base-checkbox__label {
                display: none;
              }
            }
          }
        }

        .image {
          margin: auto;
          width: 100%;
          height: 100%;
          border-radius: 15px;

          &:hover {
            cursor: pointer;
          }
        }
      }
    }
  }

  .panel-actions {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  .confirm-modal-content {
    max-width: 500px;

    > fm-text:not(:last-child) {
      margin-bottom: 16px;
    }
  }
}
</style>
