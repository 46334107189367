









import { Vue } from 'vue-property-decorator';
const TextLink = Vue.extend({
  props: {
    to: String,
    isExternalLink: Boolean,
  },
});
export default TextLink;
