




















import { Component, Vue } from 'vue-property-decorator';

@Component({
  name: 'LeaderboardRankingListItemPlaceholder',
})
export default class LeaderboardRankingListItemPlaceholder extends Vue {}
