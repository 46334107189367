import MurmurHash3 from 'imurmurhash';
import MiniSearch, { AsPlainObject } from 'minisearch';

import DiscoverStorySearch from '@/models/DiscoverStorySearchModel';
import { stopListEn } from '@/utils/stoplist';
import { isNotNullOrUndefined, isValidErrorFormat } from '@/utils/typeGuards';

import store from '@/store';
import DiscoverStoryService from './DiscoverStoryService';
import DiscoverShortcutModel from '@/models/DiscoverShortcutModel';

export type SavedIndex = { [k in string]: any };

export enum DiscoverStoryFeedType {
  idea_starters = 'idea_starters',
  trending = 'trending',
}

// const fields = ['title', 'shortcutName'];

// Just title only for now, adding shortcutName gives mixed results (but more of them)
const fields = ['title'];

export type DiscoverStorySearchable = {
  id: string;
  feedType: DiscoverStoryFeedType;

  // Fields to be searched
  title: string;
  shortcutName?: string;

  shortcutId?: number;
  // ideastarter id?

  shortcuts?: DiscoverShortcutData[];

  primaryShortcut?: DiscoverShortcutData;

  data: DiscoverStorySearch;
};

export type DiscoverStorySearchableMeta = Pick<DiscoverStorySearchable, 'shortcutId' | 'shortcuts' | 'primaryShortcut'>;

export type DiscoverStorySearchableFilters = DiscoverStorySearchableMeta & {
  shortcutIds?: number[];
};

// This is to allow adding fields later without changing other users
// such as story counts, updated at, etc.
export type DiscoverShortcutData = DiscoverShortcutModel;

export type DiscoverStoryManagerUpdateEventData = {
  latestTimestamp: number;
  shortcutIds?: number[];
};

const nonCrpytoHash = (s: string): string => new MurmurHash3(s).result().toString(16);

export const createSearchableStory = (
  data: DiscoverStorySearch,
  feedType: DiscoverStoryFeedType,
  meta?: DiscoverStorySearchableMeta
): DiscoverStorySearchable => {
  const { storyId, title } = data;

  // Create a searchable id
  const id = `${storyId}-${nonCrpytoHash(title)}`;

  const shortcuts = meta?.shortcuts ?? [];
  const primaryShortcut = meta?.primaryShortcut ?? shortcuts?.[0];
  const shortcutName = primaryShortcut?.name;

  const searchable: DiscoverStorySearchable = {
    id,
    feedType,

    title,
    shortcutName,

    ...meta,
    shortcuts,
    primaryShortcut,

    data,
  };

  return searchable;
};

export const filterSearchableStory =
  (feedType: DiscoverStoryFeedType, filters?: DiscoverStorySearchableFilters) => (story: DiscoverStorySearchable) => {
    console.info(`filterSearchableStory: filters: `, filters);

    if (story.feedType !== feedType) {
      return false;
    }

    if (filters) {
      if (filters.shortcuts?.length) {
        const storyShortcutIds = new Set(story.shortcuts?.map(({ id }) => id));
        if (!filters.shortcuts.some(({ id }) => storyShortcutIds.has(id))) {
          return false;
        }
      }

      // if (meta.shortcutId !== story.shortcutId) {
      //   return false;
      // }
    }

    return true;
  };

export type DiscoverStoryManagerData = {
  _prepared?: boolean;

  searchEngine?: MiniSearch;
  searchIndex?: SavedIndex;

  storyMap?: DiscoverStoryMap;
  shortcutMap?: DiscoverShortcutMap;
};

function newSearchEngine<D = any>() {
  const minisearch = new MiniSearch<D>({
    fields,
    processTerm: (term) => (stopListEn.has(term) ? null : term.toLowerCase()), // index term processing
    searchOptions: {
      processTerm: (term) => term.toLowerCase(), // search query processing
    },
  });

  return minisearch;
}

export type DiscoverStoryMap = Map<string, DiscoverStorySearchable>;
export type DiscoverStoryMapEntry = [string, DiscoverStorySearchable];

export type DiscoverShortcutMap = Map<number, DiscoverShortcutData>;
export type DiscoverShortcutMapEntry = [number, DiscoverShortcutData];

/// Store the data object, like in a store
export type SaveData = (data: DiscoverStoryManagerData) => Promise<void>;

export default class DiscoverStoryManager {
  static async fetchStories(
    data: DiscoverStoryManagerData,
    feedType: DiscoverStoryFeedType,
    meta?: DiscoverStorySearchableMeta,
    saveData?: SaveData
  ) {
    const brandId = store.getters.selectedBrand?.id;
    if (!brandId) {
      console.info('DiscoverStoryManager: fetchStories: cannot fetch stories with invalid brand id');
    }

    // TODO: Support ideastarters

    if (feedType !== DiscoverStoryFeedType.trending) {
      console.info('DiscoverStoryManager: fetchStories: cannot fetch stories with invalid shortcut id');
      return;
    }

    const shortcutId = meta?.shortcutId;
    if (!shortcutId) {
      console.info('DiscoverStoryManager: fetchStories: cannot fetch stories with invalid shortcut id');
      return;
    }

    const storiesResult = await new DiscoverStoryService().getStories(shortcutId, brandId);
    if (!Array.isArray(storiesResult)) {
      console.info('DiscoverStoryManager: fetchStories: error fetching stories');
      return;
    }

    // It's definitely an array now, ts even agrees.
    const stories = storiesResult;

    await this.loadStories(data, stories, feedType, meta, saveData);
  }

  private static async prepareSearchEngine(data: DiscoverStoryManagerData) {
    const fields = ['title'];

    if (!data.searchEngine) {
      if (data.searchIndex) {
        data.searchEngine = MiniSearch.loadJS(data.searchIndex as AsPlainObject, { fields });
      } else {
        data.searchEngine = newSearchEngine();
      }
    }

    data._prepared = true;
  }

  private static async mapStories(
    stories: DiscoverStorySearch[],
    feedType: DiscoverStoryFeedType,
    meta?: DiscoverStorySearchableMeta
  ) {
    const mappedStories: [string, DiscoverStorySearchable][] = stories.map((story) => {
      const searchable = createSearchableStory(story, feedType, meta);
      const id = searchable.id;

      return [id, searchable];
    });

    return mappedStories;
  }

  // static async clearStories(data: DiscoverStoryManagerData) {
  //   if (!data._prepared || !data.searchEngine) {
  //     return;
  //   }

  //   data.searchEngine!.removeAll();
  // }

  static async loadStories(
    data: DiscoverStoryManagerData | undefined,
    stories: DiscoverStorySearch[],
    feedType: DiscoverStoryFeedType,
    meta?: DiscoverStorySearchableMeta,
    saveData?: SaveData
  ) {
    data = data ?? {};

    if (!data.searchEngine) {
      await this.prepareSearchEngine(data);
    }

    const mappedStories = await this.mapStories(stories, feedType, meta);

    // Hack: filter ids already in index
    const indexedIds = new Set((data.searchEngine! as any)._documentIds.values());

    const unindexedStories = mappedStories.filter((s) => !indexedIds.has(s[0]));

    // Load stories into story map
    // TODO: replace this with an lru implementation

    if (!data.storyMap) {
      data.storyMap = new Map<string, DiscoverStorySearchable>(unindexedStories);
    } else {
      for (const [id, searchable] of unindexedStories) {
        data.storyMap!.set(id, searchable);
      }
    }

    // Add shortcuts to shortcut map
    const mappedShortcuts: DiscoverShortcutMapEntry[] = (meta?.shortcuts ?? []).map(({ id, ...rest }) => [
      id,
      { id, ...rest },
    ]);

    if (!data.shortcutMap) {
      // const shortcutIds = new Set(meta?.shortcuts?.map(({ id }) => id));
      data.shortcutMap = new Map(mappedShortcuts);
    } else {
      for (const [id, shortcut] of mappedShortcuts) {
        data.shortcutMap!.set(id, shortcut);
      }
    }

    console.info(`DiscoverStoryManager: loadStories: adding ${unindexedStories?.length ?? 0} documents to index`);

    // Add to search engine
    await data.searchEngine!.addAllAsync(unindexedStories.map((s) => s[1]));

    if (saveData) {
      await saveData(data);
    }

    if (unindexedStories?.length) {
      setTimeout(async () => {
        const shortcutIds = new Set(unindexedStories.map((s) => s[1].shortcutId).filter(isNotNullOrUndefined));

        // TODO: This should be based on the stories, but this will work for now
        const latestTimestamp = new Date().getTime();

        const updateEvent: DiscoverStoryManagerUpdateEventData = {
          latestTimestamp,
          shortcutIds: Array.from(shortcutIds),
        };

        console.info(
          `DiscoverStoryManager: (in setTimeout): calling setStoryManagerUpdateEventData with update event: `,
          updateEvent
        );
        await store.dispatch('setDiscoverStoryManagerUpdateEventData', updateEvent);
      }, 0);
    }
  }

  static async searchStories(data: DiscoverStoryManagerData, query: string) {
    if (!data.searchEngine || !data.storyMap) {
      return [];
    }

    const searchResults = data.searchEngine!.search(query);

    const mappedResults = searchResults.map((sr) => data.storyMap?.get(sr.id)).filter(isNotNullOrUndefined);

    return mappedResults;
  }

  static filterStories(
    data: DiscoverStoryManagerData,
    feedType: DiscoverStoryFeedType,
    filters?: DiscoverStorySearchableFilters
  ): Array<DiscoverStorySearchable> {
    if (!data.searchEngine || !data.storyMap) {
      return [];
    }

    const stories = Array.from(data.storyMap.values());

    const filtered = stories.filter(filterSearchableStory(feedType, filters));

    return filtered;
  }

  static hasShortcutId(data: DiscoverStoryManagerData | undefined, shortcutId: number) {
    return data?.shortcutMap?.has(shortcutId);
  }

  static extractData(searchableIter: IterableIterator<DiscoverStorySearchable>): IterableIterator<DiscoverStorySearch> {
    function* gen() {
      for (const { data } of searchableIter) {
        yield data;
      }
    }

    return gen();
  }
}
