<template>
  <section class="licensed-image-selector-panel" role="tabpanel" aria-labelledby="licensed-image-header">
    <fm-text tag="h1" as="h2" id="licensed-image-header" style="margin-bottom: 24px" ref="firstHeader" tabindex="-1">
      Select a licensed image
    </fm-text>
    <div class="panel-content">
      <div v-if="isLoading" class="loading-content">
        <fm-text tag="h2">Loading...</fm-text>
      </div>
      <template v-else>
        <div v-if="!images.length > 0" class="no-content">
          <fm-text tag="h2"> No results </fm-text>
        </div>
        <template v-else>
          <div class="image-grid sb-thin">
            <template v-for="(image, index) in visibleImages">
              <div
                class="image-grid-item"
                :key="index"
                :class="getImageItemClassList(resolveImageId(image))"
                @click="handleImageItemClick(image)"
              >
                <div class="image-grid-item-container">
                  <image-item class="image-grid-item__image" :source="resolveImageSrcUrl(image)" :alt="image.title" />
                </div>
              </div>
            </template>
          </div>
        </template>
      </template>
    </div>

    <div class="panel-footer">
      <div class="left-side">
        <fm-search-bar class="search-box" placeholder="Search licensed images" @input="handleSearchInput" />
      </div>

      <div class="right-side">
        <fm-button-primary :disabled="isApplyButtonDisabled" class="post-button-green" @click="handleApplyButton">
          Apply
        </fm-button-primary>
      </div>
    </div>
  </section>
</template>

<script>
import ImageItem from './ImageItem';

export default {
  name: 'licensed-image-selector-panel',
  components: {
    ImageItem,
  },

  data() {
    return {
      isLoading: true,
      images: [],
      lastSearch: '',
      selectedImage: {
        id: undefined,
        previewUrl: undefined,
        mainUrl: undefined,
      },
    };
  },

  computed: {
    /**
     * Returns AP Images API key for use when displaying image previews
     */
    apApiKey() {
      return this.$store.getters.settings.ap_images_key;
    },

    visibleImages() {
      return this.images.map(({ id, contentLinks, title }) => {
        return {
          id,
          title: title || '',
          imageSrc: {
            main: contentLinks[0].href,
            preview: `${contentLinks[1].href}&apikey=${this.apApiKey}`,
          },
        };
      });
    },

    isApplyButtonDisabled() {
      return !this.selectedImage.id;
    },
  },

  methods: {
    /**
     * Event handlers
     */

    handleApplyButton() {
      this.applySelectedImage();
    },

    handleSearchInput({ target }) {
      const searchValue = target.value === '' ? 'entertainment' : target.value;
      this.searchLicensedImages(searchValue);
    },

    searchLicensedImages(searchValue) {
      this.isLoading = true;
      this.lastSearch = searchValue;

      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      // This function will be called alot because of all the @input events and
      // debounce prevents too many API requests from going through at once.
      this.timeoutId = setTimeout(async () => {
        try {
          const resp = await this.$store.dispatch('searchAPImages', { search: `${searchValue}` });
          if (this.lastSearch === searchValue) {
            // Only update images if no new search has been initiated
            this.updateImageList(resp.entries);
          }
        } catch (e) {
          console.error('LicensedImageSelectorModal [handleSearchInput]', e);
        }
      }, 1000);
    },

    handleImageItemClick(image) {
      this.setSelectedImage(image);
    },

    /**
     * Actions
     */

    setSelectedImage(image) {
      this.selectedImage.id = this.resolveImageId(image);
      this.selectedImage.previewUrl = image.imageSrc.preview;
      this.selectedImage.mainUrl = image.imageSrc.main;
    },

    applySelectedImage() {
      if (!this.selectedImage.id || !this.selectedImage.previewUrl) {
        this.$store.commit('SET_MESSAGE', {
          name: 'No Image Selected',
          details: `Please select an image.`,
          type: 'error',
        });
      } else {
        const { id, previewUrl, mainUrl } = this.selectedImage;
        this.$emit('image:selected', { id, previewUrl, mainUrl });
      }
    },

    updateImageList(images) {
      this.images = images || [];
      this.isLoading = false;
    },

    resolveImageId(imageObject) {
      return imageObject.id;
    },

    resolveImageSrcUrl(imageObject) {
      return imageObject.imageSrc.preview;
    },

    getImageItemClassList(imageId) {
      return {
        selected: this.selectedImage.id === imageId,
      };
    },

    getInitialImages() {
      this.searchLicensedImages('entertainment');
    },
  },

  /**
   * Lifecycle
   */

  async mounted() {
    await this.$nextTick();
    this.$refs.firstHeader && this.$refs.firstHeader.focus();
    this.getInitialImages();
  },
};
</script>

<style lang="scss" scoped>
.licensed-image-selector-panel {
  display: flex;
  flex-direction: column;
  height: 100%;

  .panel-content {
    height: 100%;
    display: flex;
    flex-shrink: 1;
    overflow: hidden;

    .loading-content,
    .no-content {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
    }

    .image-grid {
      overflow-y: auto;
      display: flex;
      flex-wrap: wrap;
      padding-right: 16px;

      &-item {
        flex-basis: 25%;
        box-sizing: border-box;

        &:hover {
          .image-grid-item-container {
            .image-grid-item__image {
              filter: grayscale(50%);
            }
          }
        }

        &.selected {
          .image-grid-item-container {
            .image-grid-item__image {
              box-shadow: 0 0 0 5px var(--brand-light);
            }
          }
        }

        &-container {
          margin: 10px;

          .image-grid-item__image {
            margin: auto;
            width: 100%;
            height: 100%;
            border-radius: 15px;

            &:hover {
              cursor: pointer;
            }
          }
        }
      }
    }
  }

  .panel-footer {
    flex-grow: 1;
    flex-shrink: 0;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: 36px;

    .left-side {
      min-width: 400px;
    }

    .right-side {
      justify-content: flex-end;
    }
  }
}
</style>
